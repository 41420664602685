.switcher__slider {
  background-color: #a2a9af;
  border-radius: 34px;
  position: relative;
  /*width: 110px;
  height: 28px;*/
  width: 130px;
  height: 35px;
  cursor: pointer;
&::after {
   content: 'INACTIVE';
   color: #FFFFFF;
   font-size: 12px;
   font-weight: 500;
   position: absolute;
   right: 15px;
   top: 9px;
   z-index: 0;
 }
}

.switcher__sliderChecked {
  background-color: #8bc34a;
  border-radius: 34px;
  position: relative;
  /*width: 110px;
  height: 28px;*/
  width: 130px;
  height: 35px;
  cursor: pointer;
&::before {
   content: 'ACTIVE';
   color: #FFFFFF;
   /*font-size: 12px;
   font-weight: 500;
   left: 10px;
   top: 9px;*/
   position: absolute;
   z-index: 0;
   font-size: 14px;
   font-weight: 700;
   left: 16px;
   top: 10px;
 }
}

.switcher__sliderButton {
  background-color: #FFFFFF;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  height: 10px;
  left: 13px;
  position: absolute;
  top: 9px;
  transition: all 0.4s ease;
  width: 10px;
  z-index: 1;
}

.switcher__sliderButtonChecked {
  background-color: #FFFFFF;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  height: 10px;
  right: 13px;
  position: absolute;
  /*top: 9px;*/
  top: 12px;
  transition: all 0.4s ease;
  width: 10px;
}

.small-switcher {
.switcher__sliderChecked::before {
  content: '';
}
.switcher__slider {
  width: 50px;
  height: 25px;
  border-radius: 25px;
&::after {
   content: '';
 }
}
.switcher__sliderChecked {
  width: 50px;
  height: 25px;
  border-radius: 25px;
}
.switcher__slider {
  background-color: #f44336;
}
.switcher__sliderButton {
  top: 7px;
  left: 7px;
}
.switcher__sliderButtonChecked {
  top: 7px;
  right: 7px;
}
}

.small-switcherForAdmin {
  .switcher__sliderChecked::before {
    content: '';
  }
  .switcher__slider {
    width: 50px;
    height: 25px;
    border-radius: 25px;
  &::after {
     content: '';
   }
  }
  .switcher__sliderChecked {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    cursor: not-allowed;
  }
  .switcher__slider {
    background-color: #a2a9af;
    cursor: not-allowed;
  }
  .switcher__sliderButton {
    top: 7px;
    left: 7px;
    cursor: not-allowed;
  }
  .switcher__sliderButtonChecked {
    top: 7px;
    right: 7px;
    cursor: not-allowed;
  }
  }