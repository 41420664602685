.disclosure-workflow-page {
   //display: flex;
   width: 100%;
   font-family: Roboto;
   font-style: normal;
   font-stretch: normal;
   &__header{
     .name-disclosure {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
     }
   }
   &__steps {
      margin-top: 40px;
      .steps-disclosure {
         margin-bottom: 10px !important;
      }
   }

   &__components {
      margin-top: 10px;

      .packing-disclosure {
          border: 1px solid #382e34;
         display: flex;
         flex-direction: row;
         width: 100%;
         justify-content: space-between;
         //align-items: center;
         &__include {
            width: 30%;
            border-right: 1px solid #382e34;
            .include-header-noactive {
               background-color: #D4D7DA !important;
            }
           .include-header {
              //background-color: #d4d7da;
              background-color: #ffc457;
              padding: 10px;
              height: 40px;
              border-bottom: 1px solid #382e34;
              .include-name {
                 font-size: 14px;
                 font-weight: 700;
                 line-height: 20px;
                 letter-spacing: 0.2px;
                 color: #382e34;
              }
           }
            .include-content {
               padding: 20px;
               height: 450px;
               overflow: auto;
               max-height: 450px;
              .content-checkbox {
                 margin-bottom: 10px;
                 .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
                    border-color: #eda41e;
                 }
                 .ant-checkbox-wrapper .ant-checkbox-inner {
                    border-radius: 0px;
                    border: 1px solid #637882;
                    border-width: 1px;
                 }
                 .ant-checkbox + span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #382e34;
                 }
                 .ant-checkbox-checked .ant-checkbox-inner::after {
                    position: absolute !important;
                    display: table;
                    border: none !important;
                    border-top: 0;
                    border-left: 0;
                    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    opacity: 1;
                    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                    content: "\e8ce" !important;
                    color: #FFFFFF;
                    top: 7px !important;
                    left: 7px !important;
                    font-size: 12px !important;
                    font-family: smartbox_icons_v19;
                 }

                 .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #eda41e !important;
                    border-width: 1px;
                    border-color: #d4d7da;
                 }
              }
              .content-all-options-box-data {
                 .ant-radio-wrapper {
                    margin-bottom: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #6e7782;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    vertical-align: middle;
                    line-height: 20px;
                 }
                 .ant-radio:hover .ant-radio-inner {
                    border-color: #d4d7da;
                 }
                 .ant-radio .ant-radio-inner::after {
                    //content: "\f00c" !important;
                    content: " " !important;
                    color: #ffffff !important;
                    font-size: 9px !important;
                    font-family: 'FontAwesome' !important;
                    top: 2.7px;
                    left: 3px;
                    border: 4px solid #FFFFFF;
                    border-radius: 50%;
                    background-color: #FFFFFF;
                    transition: none;
                 }
                 .ant-radio-checked .ant-radio-inner {
                    border-color: #d4d7da;
                    background-color: #eda41e;
                    border-radius: 50%;
                 }
                 .content-files-option {
                    display: flex;
                    margin-bottom: 30px;
                    height: 60px;
                    padding-top: 5px;
                    .title-option {
                       width: 50px;
                       margin-right: 10px;
                       font-size: 14px;
                       font-weight: 700;
                       color: #382E34;
                       vertical-align: middle;
                    }
                    .content-functionality-files-option {
                       //width: 216px;
                       //margin-right: 30px;
                       .ant-radio:hover .ant-radio-inner {
                          border-color: #eda41e;
                       }
                       .text-option {
                          span.ant-radio + * {
                             padding-top: 2px;
                          }
                       }
                    }
                    .content-option-tag {
                       display: flex;
                       align-items: flex-end;
                       //width: 216px;
                       .ant-select-selection-selected-value {
                          width: 170px;
                          display: flex !important;
                          align-items: center;
                          span {
                             display: flex;
                             justify-content: flex-start;
                             align-items: center;
                             margin-right: 3px;
                          }
                       }
                       .item-options-status-new {
                          color: #000000 !important;
                          font-weight: 500 !important;
                          opacity: 0.8;
                          padding: 5px 6px !important;
                          display: flex !important;
                          align-items: center !important;
                          .anticon {
                             margin-right: 4px;
                             padding-left: 2px;
                          }
                       }
                       .ant-select-selection--single {
                          height: 30px;
                          padding-left: 6px;
                          padding-right: 10px;
                       }
                       .ant-select .ant-select-selection .ant-select-arrow {
                          margin-right: 2px;
                       }
                       .ant-select {
                          width: 163px;
                          font-family: Roboto;
                          font-weight: 400;
                          font-size: 14px !important;
                          color: #6e7782 !important;
                          cursor: pointer;
                       }
                       .ant-select-selection {
                          border: 1px solid #6e7882;
                          border-radius: 3px;
                          &:hover {
                             border: 1px solid #eda41e;
                          }
                          &:focus {
                             border: 1px solid #eda41e;
                          }
                          &:active {
                             border: 1px solid #eda41e;
                             box-shadow: none;
                          }
                       }
                       .ant-select-disabled {
                          cursor: not-allowed;
                          .ant-select-selection {
                             &:hover {
                                border: 1px solid #6e7882;
                             }
                             &:focus {
                                border: 1px solid #6e7882;
                             }
                             &:active {
                                border: 1px solid #6e7882;
                             }

                          }
                       }
                       .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
                          color: #6e7782;
                          font-size: 14px;
                       }
                       .ant-select-selection__rendered {
                          margin-left: 0px;
                          margin-right: 0px;
                       }
                    }
                 }
                 .content-files-types {
                    height: 90px !important;
                    .content-functionality-files-option {
                       .text-option-redact {
                          margin-top: 2px;
                       }
                    }
                 }
                 .content-format-files {
                    height: 115px !important;
                    .content-format-options {
                       .format-options-download {
                          .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
                             border-color: #eda41e;
                          }
                          .ant-checkbox-wrapper {
                             font-size: 14px;
                             font-weight: 400;
                             color: #6e7782;
                             align-items: center;
                             vertical-align: middle;
                             line-height: 20px;
                             margin-bottom: 10px;
                          }
                       }
                          .ant-checkbox-wrapper .ant-checkbox-inner {
                             border-radius: 0% !important;
                             border: 1px solid #6e7882;
                          }
                          .ant-checkbox-checked .ant-checkbox-inner {
                             //background-color: var(--generalIconFontActive) !important;
                             background-color: #eda41e !important;
                          }
                          .ant-checkbox-inner:after {
                             border-color: #ffffff !important;
                          }
                          .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                             margin-left: 0px;
                          }
                          .ant-checkbox-group {
                             margin-left: 10px;
                             display: flex;
                             flex-direction: column;
                          }
                    }
                    .content-functionality-files-option {
                       .text-option-file {
                          margin-top: 2px;
                       }
                    }
                 }
              }
            }
            .include-button {
               width: 100%;
               display: flex;
               justify-content: flex-end;
               height: 45px;
               align-items: center;
               padding: 10px;
               border-top: 1px solid #382E34;
               .update-include-button {
                  background-color: #FFFFFF;
                  border: 1px solid #6e7882;
                  border-radius: 20px;
                  box-shadow: none;
                  color: #6e7882;
                  cursor: pointer;
                  font-family: Roboto;
                  font-weight: 400;
                  font-size: 16px;
                  padding: 3px !important;
                  text-transform: none;
                  transition: none;
                  line-height: 18.75px;
                  width: 180px;
                  margin-left: 40px;
                  height: 26px;

                  &:hover {
                     background-color: #FFFFFF;
                     border: 1px solid #FFC457;
                     color: #FFC457;
                  }

                  &:active {
                     background-color: #FFFFFF;
                     border: 1px solid #eda41e;
                     color: #eda41e;
                  }

                  &:disabled {
                     cursor: default;
                     color: #d4d7da;
                     border: 1px solid #d4d7da !important;
                  }
               }
            }
         }

         &__wrap-it-up {
            width: 70%;
            .wrap-it-up-header-active {
               background-color: #eda41e !important;
            }
           .wrap-it-up-header {
              background-color: #D4D7DA;
              padding: 10px;
              height: 40px;
              justify-content: space-between;
              display: flex;
              border-bottom: 1px solid #382e34;
              .disabled-download-1 {
                 opacity: 0.3;
                 cursor: not-allowed;
              }
              .wrap-it-up-name {
                 font-size: 14px;
                 font-weight: 700;
                 line-height: 20px;
                 letter-spacing: 0.2px;
                 color: #382e34;
              }
              .icon-v22-download-1 {
                 color: #382e34;
                 font-size: 16px;
              }
           }
            .content-loading-disclosure {
               padding: 20px;
               height: 450px;
               overflow: auto;
               max-height: 450px;
               align-items: center;
               display: flex;
               justify-content: center;
               .ant-spin {
                  font-size: 18px;
                  .ant-spin-dot {
                     font-size: 24px;
                  }
                  .ant-spin-dot-item {
                     position: absolute;
                     display: block;
                     width: 11px;
                     height: 11px;
                  }
                  .ant-spin-text {
                     font-weight: 700;
                  }
               }
            }
            .wrap-it-up-content-no-data {
               display: flex;
               align-items: center;
               justify-content: center;
               .ant-empty {

               }
            }
            .wrap-it-up-content {
               padding: 20px;
               height: 450px;
               overflow: auto;
               max-height: 450px;
               .wrap-it-up-time {
                  display: flex;
                  justify-content: flex-end;
                  border-bottom: 1px solid #382e34;
                  padding-bottom: 5px;
                 .time-name {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #382e34;
                 }
               }
               .wrap-it-up-list-ropa {
                 .content-list {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    border-bottom: 1px solid #382e34;
                   .content-file-name {
                      .icon-v22-extension-ai {
                         font-size: 14px;
                      }
                      .file-name {
                         font-size: 14px;
                         font-weight: 400;
                         line-height: 20px;
                         letter-spacing: 0.2px;
                         color: #382e34;
                         margin-left: 10px;
                      }
                   }
                    .file-size {
                       font-size: 14px;
                       font-weight: 400;
                       line-height: 20px;
                       letter-spacing: 0.2px;
                       color: #382e34;
                       padding-right: 10px;
                    }
                 }
               }
               .wrap-it-up-list-files {
                  .content-list {
                     display: flex;
                     justify-content: space-between;
                     padding-bottom: 5px;
                     padding-top: 5px;
                     border-bottom: 1px solid #382e34;
                     .content-file-name {
                        .icon-v22-extension-ai {
                           font-size: 14px;
                        }
                        .file-name {
                           font-size: 14px;
                           font-weight: 400;
                           line-height: 20px;
                           letter-spacing: 0.2px;
                           color: #382e34;
                           margin-left: 10px;
                        }
                     }
                     .file-size {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        color: #382e34;
                        padding-right: 10px;
                     }
                  }
               }
            }
            .wrap-it-up-button {
               width: 100%;
               display: flex;
               justify-content: space-between;
               height: 45px;
               align-items: center;
               padding: 10px;
               border-top: 1px solid #382E34;
               .info-total-files {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #382e34;
               }
               .pack-button {
                  background-color: #EDA41E;
                  border: 1px solid #6e7882;
                  border-radius: 20px;
                  box-shadow: none;
                  color: #FFFFFF;
                  cursor: pointer;
                  font-family: Roboto;
                  font-weight: 400;
                  font-size: 16px;
                  padding: 3px !important;
                  text-transform: none;
                  transition: none;
                  line-height: 18.75px;
                  width: 180px;
                  margin-left: 40px;
                  height: 26px;

                  &:hover {
                     background-color: #FFC457;
                     border: 1px solid #FFC457;
                     color: #FFFFFF;
                  }

                  &:active {
                     background-color: #EDA41E;
                     border: 1px solid #EDA41E;
                     color: #FFFFFF;
                  }

                  &:disabled {
                     cursor: default;
                     background: #D4D7DA !important;
                  }
               }
            }
         }
      }

      .send-disclosure {
         border: 1px solid #382e34;
         display: flex;
         flex-direction: row;
         width: 100%;
         justify-content: space-between;
         .email-disclosure-content {
            width: 60%;
            border-right: 1px solid #382e34;
            .form-group {
               //margin: 1% 0;
               margin-bottom: 0px;
               display: flex;
               background-color: #FFC457;
               display: flex;
               align-items: center;
               padding-right: 10px;
               border-left: 1px solid #382e34;
               border-right: 1px solid #382e34;
               border-bottom: 1px solid #382e34;
               height: 40px;
               label {
                  margin-top: 0%;
                  font-family: Roboto;
                  font-weight: 700;
                  font-style:normal;
                  color: #000000;
                  font-size: 14px;
                  margin-bottom: 0px;
               }
               input {
                  height: 35px;
                  border: 1px solid #000000;
                  border-radius: 0;
                  color: #000000;
                  font-size: 16px;
                  font-weight: 400;
                  padding: 10px;
                  &:focus {
                     border:none;
                     outline:2px solid #382e34;
                  }
               }
            }
            .email-disclosure-header-noActive {
               background-color: #D4D7DA !important;
            }
            .email-disclosure-header {
               background-color: #ffc457;
               padding: 10px;
               height: 40px;
               border-bottom: 1px solid #382e34;
               .email-subject {
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #382e34;
                  margin-right: 5px;
               }
               .sar-disclosure {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #382e34;
               }
            }
            .email-subject-content {
               display: flex;
               height: 450px;
               overflow: auto;
               max-height: 450px;
               width: 100%;
               flex-direction: column;
               .form-content {
                  margin-top: 0;
                  padding-left:0;
                  padding-right: 0;
               }
               .quill-write {
                 // border: 1px solid #382e34;
                  .ql-container.ql-snow {
                     border: none;
                  }
                  .ql-editor {
                     height: 400px;
                     span {
                        background-color: #FFFFFF !important;
                        color: rgba(0, 0, 0, 0.65) !important;
                     }
                  }
               }
            }
            .email-buttons-update {
               justify-content: flex-end !important;
            }
            .email-buttons {
               width: 100%;
               display: flex;
               justify-content: space-between;
               height: 45px;
               align-items: center;
               padding: 10px;
               border-top: 1px solid #382E34;
               .email-buttons-reset {
                  .icon-v14-refresh-1 {
                     font-size: 20px;
                     color: #382e34;
                     &:hover {
                        color: #eda41e;
                     }
                  }
               }
               .reset-button {
                  background-color: #FFFFFF;
                  border: 1px solid #6e7882;
                  border-radius: 20px;
                  box-shadow: none;
                  color: #6e7882;
                  cursor: pointer;
                  font-family: Roboto;
                  font-weight: 400;
                  font-size: 16px;
                  padding: 3px !important;
                  text-transform: none;
                  transition: none;
                  line-height: 18.75px;
                  width: 180px;
                  margin-left: 20px;
                  height: 26px;

                  &:hover {
                     background-color: #FFFFFF;
                     border: 1px solid #FFC457;
                     color: #FFC457;
                  }

                  &:active {
                     background-color: #FFFFFF;
                     border: 1px solid #eda41e;
                     color: #eda41e;
                  }

                  &:disabled {
                     cursor: default;
                     color: #d4d7da;
                     border: 1px solid #d4d7da !important;
                  }
               }
               .send-button {
                  background-color: #EDA41E;
                  border: 1px solid #6e7882;
                  border-radius: 20px;
                  box-shadow: none;
                  color: #FFFFFF;
                  cursor: pointer;
                  font-family: Roboto;
                  font-weight: 400;
                  font-size: 16px;
                  padding: 3px !important;
                  text-transform: none;
                  transition: none;
                  line-height: 18.75px;
                  width: 180px;
                  margin-left: 40px;
                  height: 26px;

                  &:hover {
                     background-color: #FFC457;
                     border: 1px solid #FFC457;
                     color: #FFFFFF;
                  }

                  &:active {
                     background-color: #EDA41E;
                     border: 1px solid #EDA41E;
                     color: #FFFFFF;
                  }

                  &:disabled {
                     cursor: default;
                     background: #D4D7DA !important;
                  }
               }
            }
         }

         .tracking-progress-content {
            width: 40%;
            .tracking-progress-header-noactive {
               background-color: #D4D7DA !important;
            }
            .tracking-progress-header {
               background-color: #ffc457;
               padding: 10px;
               height: 40px;
               border-bottom: 1px solid #382e34;
               .tracking-name {
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #382e34;
               }
            }
            .tracking-progress-steps {
               display: flex;
               height: 450px;
               overflow: auto;
               max-height: 450px;
               width: 100%;
               padding: 20px;
               .progress-step {
                  .ant-steps-item-content {
                     width: 300px;
                  }
                  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
                     background-color: #1890ff;
                  }
                  .ant-steps-item-description {
                     display: flex;
                     flex-direction: column;
                  }
                  .ant-steps-item-title {
                     width: 300px;
                  }
                  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
                     font-size: 14px;
                     font-weight: 700;
                     line-height: 20px;
                     letter-spacing: 0.2px;
                     color: #d4d7da;
                  }
                  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
                     font-size: 14px;
                     font-weight: 500;
                     line-height: 20px;
                     letter-spacing: 0.2px;
                     color: #d4d7da;
                  }
               }
            }
         }
      }

      .delivered-disclosure {
         border: 1px solid #382e34;
         display: flex;
         width: 100%;
         justify-content: space-between;
         flex-direction: column;
         .delivered-disclosure-header {
            background-color: #ffc457;
            padding: 10px;
            height: 40px;
            justify-content: space-between;
            display: flex;
            border-bottom: 1px solid #382e34;
            width: 100%;
            .disabled-download-1 {
               opacity: 0.3;
               cursor: not-allowed;
            }
            .delivered-disclosure-name {
               font-size: 14px;
               font-weight: 700;
               line-height: 20px;
               letter-spacing: 0.2px;
               color: #382e34;
            }
            .icon-v22-download-1 {
               color: #382e34;
               font-size: 16px;
            }
         }

         .delivered-disclosure-content {
            display: flex;
            width: 100%;
            flex-direction: column;
            padding: 20px;
            .delivered-disclosure-contain {
               display: flex;
               width: 100%;
               flex-direction: column;
               margin-bottom: 20px;
              .record-header {
                 font-size: 14px;
                 font-weight: 700;
                 line-height: 20px;
                 letter-spacing: 0.2px;
                 color: #382e34;
                 border-bottom: 1px solid #382e34;
                 width: 100%;
              }
               .record-header-contain {
                    .delivered-record-example {
                       display: flex;
                       justify-content: space-between;
                       align-items: center;
                       height: 25px;
                       .title-record {
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          letter-spacing: 0.2px;
                          color: #382e34;
                       }
                       .description-record {
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          letter-spacing: 0.2px;
                          color: #382e34;
                       }
                    }
               }
            }
         }
      }
   }
}
.dropdown-select-tag {
   border: 1px solid #eda41e !important;
   .ant-select-dropdown-menu {
      overflow-x: hidden !important;
   }
   .item-options-status-new > span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-wrap: nowrap;

   }
}