.commonShell-page {
  background-color: #fafafa;
  display: flex;
  min-height: 100%;
  min-width: 1336px;
}

.commonShell-main {
  width: 100%;
  background-color: #FFFFFF !important;
}

.commonShell-content {
  padding-top: 0px;
}

.commonShell-loading {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 80px auto;
}
@media screen and (max-width: 1124px) {
  .commonShell-page {
    min-width: 1024px;
  }
}
@media screen and (min-width: 1200px) and ( max-height: 514px ) {
  .commonShell-content {
    padding-top: 0;
  }
}
@media screen and ( min-width: 995px ) and ( max-height: 1200px ) {
  .commonShell-content {
    padding-top: 0;
  }
}

@media screen and (max-width: 995px) {
  .commonShell-content {
    padding-top: 0px !important;
  }
}