.questionnaireDashboard__loading {
  display: flex;
  justify-content: center;
}

.questionnaireDashboard__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 120px 20px 0;
}

.questionnaireDashboard__header {
  display: flex;
  flex-direction: column-reverse;
}

.questionnaireDashboard__newButtonWrapper {
  text-align: center;
  margin-bottom: 20px;
  button {
    height: 42px;
    padding: 8px 15px;
  }
  .button-update {
    background-color: var(--generalButton);
    border: 1px solid #6e7882;
    width: 180px;
    height: 25px;
    font-family: "Roboto";
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--generalButtonFont);
    padding: 0px;
    box-shadow: none;
    text-transform: none;
    &:hover {
      background-color: var(--generalButtonHover);
      border: 1px solid var(--generalButtonHover);
    }
    &:active {
      background-color: var(--generalButtonActive);
      border: 1px solid var(--generalButtonActive);
    }
  }
}
.questionnaireFilter__select select {
  line-height: 20px;
  width: 200px;
 }

 .questionnaireFilter__select select {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .questionnaireDashboard__header {
    justify-content: space-between;
    flex-direction: row;
  }
  .questionnaireDashboard__newButtonWrapper {
    text-align: right;
    display: flex;
    align-self: center;
    margin-top: 16px;
  }
}
@media screen and (max-width: 1454px)  and (min-width: 1000px) { 
  #questionnaireFilter select {
    width: 176px;
  }
}
