.reportDetail-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.reportDetail-link {
  color: #3DD8DB;
  display: block;
  font-size: 14px;
  margin-bottom: 40px;
  text-decoration: underline;
  &:hover {
    font-style: italic;
  }
}

.reportDetail-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 40px;
  position: relative;
}

.reportDetail-logoContainer {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 20px;
}

.reportDetail-logo {
  height: 86px;
  width: 200px;
}

.reportDetail-printButton {
  margin-bottom: 20px;
  button {
    align-items: center;
    background: url(../../../assets/icon-print.png) no-repeat 20px center #3DD8DB;
    background-size: 20px;
    display: flex;
    height: 34px;
    padding: 0 20px 0 50px;
    &:hover {
      background: url(../../../assets/icon-print-green.png) no-repeat 20px center #FFFFFF;
      background-size: 20px;
    }
  }
  img {
    margin-right: 5px;
    width: 25px;
  }
}

.reportDetail-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 74px;
  justify-content: center;
}

.reportDetail-title {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.reportDetail-section {
  border-top: 1px solid #000000;
  position: relative;
}

.reportDetail-areasBox {
  background-color: #FFFFFF;
  border-bottom: 1px solid #000000;
  position: relative;
}

.reportDetail-sectionHeader {
  border-bottom: 1px solid #000000;
  padding: 20px 0;
}

.reportDetail-sectionTitle {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.reportDetail-sectionSubtitle {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.reportDetail-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0;
}

.reportDetail-block {
  width: 100%;
  &:first-of-type {
    margin-bottom: 40px;
  }
}

.reportDetail-question {
  border-bottom: 1px solid #000000;
  padding: 20px 0;
  &:last-of-type {
    border-bottom: none;
  }
  h1 {
    margin-bottom: 10px;
  }
  h2 {
    font-weight: 300;
    margin-bottom: 20px;
  }
}

.reportDetail-answerYN {
  font-weight: 500;
  padding-left: 25px;
  position: relative;
  &::before {
    background-color: #000000;
    border-radius: 50%;
    color: #FFFFFF;
    content: '✓';
    font-size: 14px;
    height: 20px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 20px;
  }
}

.reportDetail-answerAlternative {
  margin: 15px 0;
}

.reportDetail-answerAlternativeSelected {
  font-weight: 500;
  margin: 15px 0;
  padding-left: 25px;
  position: relative;
  &::before {
    background-color: #000000;
    border-radius: 50%;
    color: #FFFFFF;
    content: '✓';
    font-size: 14px;
    height: 20px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 20px;
  }
}

.reportDetail-otherOptionText {
  margin-left: 25px;
}

.reportDetail-group {
  display: flex;
  font-weight: 300;
  margin: 20px 0 10px;
  &::before {
    content: "";
    border-bottom: 1px solid #000000;
    margin-right: 10px;
    transform: translateY(-50%);
    width: 20px;
  }
  &::after {
    border-bottom: 1px solid #000000;
    content: "";
    flex: 1 0 auto;
    margin-left: 10px;
    transform: translateY(-50%);
  }
}

.reportDetail__loading {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

@media screen and (min-width: 768px) {
  .reportDetail-page {
    padding: 40px 40px 0;
  }
  .reportDetail-logoContainer {
    flex-direction: row;
  }
  .reportDetail-printButton {
    margin-bottom: 0;
  }
  .reportDetail-header {
    flex-direction: row;
    justify-content: space-between;
    height: 74px;
  }
  .reportDetail-title {
    margin-bottom: 0;
  }
  .reportDetail-content {
    flex-direction: row;
  }
  .reportDetail-block {
    width: 46%;
    &:first-of-type {
      margin-bottom: 0;
    }
  }
}

@media print {
  .reportDetail-page {
    padding: 0;
  }
  .reportDetail-link {
    display: none;
  }
  .reportDetail-box {
    padding: 0 40px 40px;
  }
  .reportDetail-logoContainer {
    align-items: flex-start;
  }
  .reportDetail-printButton {
    display: none;
  }
  .reportDetail-header {
    display: table;
  }
}