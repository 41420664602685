.newOption-periodGroup {
  align-items: flex-start;
  display: flex;
  margin: 20px 0;
  flex-direction: column;
}

.newOption-period {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-right: 20px;
}

.newOption-periodDays {
  display: inline-block;
  margin-left: 15px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
}

.newOption-radio.checked + .newOption-periodDays {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  color: #3DD8DB;
}

.newOption-periodLabel {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.newOption-periodLabelDisabled {
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.newOption-periodLabelChecked {
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 27px;
  left: 10px;
  position: absolute;
  transition: all .5s ease;
  top: 10px;
  width: 27px;
  z-index: 1;
  padding-top: 5px;
  padding-left: 5px;
}

.newOption-periodLabelCheckedDisabled {
  background-color: #ffffff;
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 27px;
  left: 10px;
  position: absolute;
  transition: all .5s ease;
  top: 10px;
  width: 27px;
  z-index: 1;
  padding-top: 5px;
  padding-left: 5px;
}

.newOption-radio {
  background-color: #FFFFFF;
  border: 1px solid #3DD8DB;
  border-radius: 45px;
  display: inline-block;
  height: 50px;
  margin-right: 0;
  position: relative;
  width: 50px;
&.checked {
   background-color: #3DD8DB;
 }
&.checkedDisabled {
   background-color: #EEEEEE;
   border: 1px solid #EEEEEE;
 }
&.disabled {
   border: 1px solid #EEEEEE;
 }
}

.newOption-periodLabelChecked:before, .newOption-periodLabelCheckedDisabled:before {
  font-family: 'FontAwesome';
  content: "\f00c";
  color: #3DD8DB;
  font-size: 16px;
}

.newOption-option {
  margin-top: 20px;
}

.newOption-text {
  margin-bottom: 10px;
  font-size: 18px;
}

.newOption-text-small {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 30px;
  color: #737e89;
}

@media screen and (min-width: 768px) {
  .newOption-periodGroup {
    flex-direction: row;
  }
  .newOption-period {
    margin-bottom: 0;
  }
}