.page404__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
}

.page404__imgWalker {
  width: 150px;
}

.page404__wrapper {
  margin-top: 40px;
}

.page404__title {
  color: #162737;
  font-size: 24px;
  font-weight: 300;
  margin: 20px 0;
}

.page404__text {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.page404__link {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .page404 {
    flex-direction: row;
    max-width: 1200px;
    padding: 80px 20px;
  }
  .page404__imgWalker {
    width: auto;
  }
  .page404__wrapper {
    margin: 0 0 0 40px;
  }
  .page404__title {
    font-size: 32px;
  }
}