@font-face {
  font-family: 'smartbox_icons_v3';
  src: url('./font_v12/smartbox_icons_v3.eot?61514682');
  src: url('./font_v12/smartbox_icons_v3.eot?61514682#iefix') format('embedded-opentype'),
       url('./font_v12/smartbox_icons_v3.woff2?61514682') format('woff2'),
       url('./font_v12/smartbox_icons_v3.woff?61514682') format('woff'),
       url('./font_v12/smartbox_icons_v3.ttf?61514682') format('truetype'),
       url('./font_v12/smartbox_icons_v3.svg?61514682#smartbox_icons_v3') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'smartbox_icons_v3';
    src: url('../font/smartbox_icons_v3.svg?61514682#smartbox_icons_v3') format('svg');
  }
}
*/
 
 [class^="icon-v12"]:before, [class*=" icon-v12"]:before {
  font-family: "smartbox_icons_v3";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-v12-show-20:before { content: '\0e81'; } /* 'ກ' */
.icon-v12-edit-active:before { content: '\e800'; } /* '' */
.icon-v12-edit:before { content: '\e801'; } /* '' */
.icon-v12-exclude-active:before { content: '\e802'; } /* '' */
.icon-v12-checkid:before { content: '\e803'; } /* '' */
.icon-v12-dragpad:before { content: '\e804'; } /* '' */
.icon-v12-request-1:before { content: '\e805'; } /* '' */
.icon-v12-email:before { content: '\e806'; } /* '' */
.icon-v12-exclude:before { content: '\e807'; } /* '' */
.icon-v12-3-white:before { content: '\e808'; } /* '' */
.icon-v12-select-all:before { content: '\e809'; } /* '' */
.icon-v12-filter-dropdown-active:before { content: '\e80a'; } /* '' */
.icon-v12-spell-checker-1:before { content: '\e80b'; } /* '' */
.icon-v12-images:before { content: '\e80c'; } /* '' */
.icon-v12-enter:before { content: '\e80d'; } /* '' */
.icon-v12-metadata-1:before { content: '\e80e'; } /* '' */
.icon-v12-annotation:before { content: '\e80f'; } /* '' */
.icon-v12-tick-button:before { content: '\e810'; } /* '' */
.icon-v12-tick-button-off:before { content: '\e811'; } /* '' */
.icon-v12-radio-button:before { content: '\e812'; } /* '' */
.icon-v12-radio-button-off:before { content: '\e813'; } /* '' */
.icon-v12-radio-button-tick:before { content: '\e814'; } /* '' */
.icon-v12-preview:before { content: '\e815'; } /* '' */
.icon-v12-folder-open-1:before { content: '\e816'; } /* '' */
.icon-v12-show-50:before { content: '\e817'; } /* '' */
.icon-v12-folder-empty:before { content: '\e818'; } /* '' */
.icon-v12-box-lined:before { content: '\e819'; } /* '' */
.icon-v12-colour-palette:before { content: '\e81a'; } /* '' */
.icon-v12-grid-view-1:before { content: '\e81b'; } /* '' */
.icon-v12-list-view-1:before { content: '\e81c'; } /* '' */
.icon-v12-hamburger:before { content: '\e81d'; } /* '' */
.icon-v12-minus-b:before { content: '\e81e'; } /* '' */
.icon-v12-annotate-selected:before { content: '\e81f'; } /* '' */
.icon-v12-annotate-1:before { content: '\e820'; } /* '' */
.icon-v12-clear-page-selected:before { content: '\e821'; } /* '' */
.icon-v12-clear-page:before { content: '\e822'; } /* '' */
.icon-v12-show-100-active:before { content: '\e823'; } /* '' */
.icon-v12-clear-selected-selected:before { content: '\e825'; } /* '' */
.icon-v12-clear-selected:before { content: '\e826'; } /* '' */
.icon-v12-draw-redaction-selected:before { content: '\e827'; } /* '' */
.icon-v12-draw-redaction:before { content: '\e828'; } /* '' */
.icon-v12-home-icon-neg2:before { content: '\e829'; } /* '' */
.icon-v12-word-search-selected:before { content: '\e82c'; } /* '' */
.icon-v12-word-search:before { content: '\e82d'; } /* '' */
.icon-v12-move-selected:before { content: '\e82e'; } /* '' */
.icon-v12-text-selection-selected:before { content: '\e82f'; } /* '' */
.icon-v12-text-selection:before { content: '\e830'; } /* '' */
.icon-v12-redo-selected:before { content: '\e831'; } /* '' */
.icon-v12-redo:before { content: '\e832'; } /* '' */
.icon-v12-process-analysing:before { content: '\e833'; } /* '' */
.icon-v12-user-b:before { content: '\e834'; } /* '' */
.icon-v12-users:before { content: '\e835'; } /* '' */
.icon-v12-user-circle-b:before { content: '\e836'; } /* '' */
.icon-v12-undo:before { content: '\e837'; } /* '' */
.icon-v12-delete:before { content: '\e838'; } /* '' */
.icon-v12-pages-1:before { content: '\e839'; } /* '' */
.icon-v12-move:before { content: '\e83a'; } /* '' */
.icon-v12-editing:before { content: '\e83b'; } /* '' */
.icon-v12-download:before { content: '\e83c'; } /* '' */
.icon-v12-monitor-b:before { content: '\e83d'; } /* '' */
.icon-v12-access-asset-b:before { content: '\e83e'; } /* '' */
.icon-v12-redaction-b:before { content: '\e83f'; } /* '' */
.icon-v12-ai-search:before { content: '\e840'; } /* '' */
.icon-v12-undo-selected:before { content: '\e841'; } /* '' */
.icon-v12-add-b:before { content: '\e842'; } /* '' */
.icon-v12-support-ticket-b:before { content: '\e843'; } /* '' */
.icon-v12-file-sent:before { content: '\e844'; } /* '' */
.icon-v12-ok-b:before { content: '\e845'; } /* '' */
.icon-v12-cloud-b:before { content: '\e846'; } /* '' */
.icon-v12-preview-selected-1:before { content: '\e847'; } /* '' */
.icon-v12-date:before { content: '\e84a'; } /* '' */
.icon-v12-status-ok:before { content: '\e84b'; } /* '' */
.icon-v12-search-b:before { content: '\e84c'; } /* '' */
.icon-v12-export-b:before { content: '\e84d'; } /* '' */
.icon-v12-question:before { content: '\e850'; } /* '' */
.icon-v12-upload:before { content: '\e852'; } /* '' */
.icon-v12-analysis:before { content: '\e853'; } /* '' */
.icon-v12-extension-aep:before { content: '\e854'; } /* '' */
.icon-v12-extension-ai:before { content: '\e855'; } /* '' */
.icon-v12-extension-aif:before { content: '\e856'; } /* '' */
.icon-v12-extension-avi:before { content: '\e857'; } /* '' */
.icon-v12-extension-bmp:before { content: '\e858'; } /* '' */
.icon-v12-extension-cda:before { content: '\e859'; } /* '' */
.icon-v12-extension-css:before { content: '\e85a'; } /* '' */
.icon-v12-extension-csv:before { content: '\e85b'; } /* '' */
.icon-v12-extension-doc:before { content: '\e85c'; } /* '' */
.icon-v12-extension-docx:before { content: '\e85d'; } /* '' */
.icon-v12-extension-eps:before { content: '\e85e'; } /* '' */
.icon-v12-extension-fnt:before { content: '\e85f'; } /* '' */
.icon-v12-extension-fon:before { content: '\e860'; } /* '' */
.icon-v12-extension-gif:before { content: '\e861'; } /* '' */
.icon-v12-extension-htm:before { content: '\e862'; } /* '' */
.icon-v12-extension-html:before { content: '\e863'; } /* '' */
.icon-v12-extension-jfif:before { content: '\e864'; } /* '' */
.icon-v12-extension-jp2:before { content: '\e865'; } /* '' */
.icon-v12-extension-jpeg:before { content: '\e866'; } /* '' */
.icon-v12-extension-jpg:before { content: '\e867'; } /* '' */
.icon-v12-extension-mid:before { content: '\e868'; } /* '' */
.icon-v12-extension-midi:before { content: '\e869'; } /* '' */
.icon-v12-extension-mo4:before { content: '\e86a'; } /* '' */
.icon-v12-extension-key:before { content: '\e86b'; } /* '' */
.icon-v12-extension-mov:before { content: '\e86c'; } /* '' */
.icon-v12-extension-mp3:before { content: '\e86d'; } /* '' */
.icon-v12-extension-mpa:before { content: '\e86e'; } /* '' */
.icon-v12-extension-mpeg:before { content: '\e86f'; } /* '' */
.icon-v12-extension-mpg:before { content: '\e870'; } /* '' */
.icon-v12-extension-odp:before { content: '\e871'; } /* '' */
.icon-v12-extension-odt:before { content: '\e872'; } /* '' */
.icon-v12-extension-ods:before { content: '\e873'; } /* '' */
.icon-v12-extension-ogg:before { content: '\e874'; } /* '' */
.icon-v12-extension-otf:before { content: '\e875'; } /* '' */
.icon-v12-extension-pdf:before { content: '\e876'; } /* '' */
.icon-v12-extension-php:before { content: '\e877'; } /* '' */
.icon-v12-extension-png:before { content: '\e878'; } /* '' */
.icon-v12-extension-pps:before { content: '\e879'; } /* '' */
.icon-v12-extension-ppt:before { content: '\e87a'; } /* '' */
.icon-v12-extension-pptx:before { content: '\e87b'; } /* '' */
.icon-v12-extension-ps:before { content: '\e87c'; } /* '' */
.icon-v12-extension-psd:before { content: '\e87d'; } /* '' */
.icon-v12-extension-pst:before { content: '\e87e'; } /* '' */
.icon-v12-extension-rar:before { content: '\e87f'; } /* '' */
.icon-v12-extension-rtf:before { content: '\e880'; } /* '' */
.icon-v12-extension-svg:before { content: '\e881'; } /* '' */
.icon-v12-extension-tex:before { content: '\e882'; } /* '' */
.icon-v12-extension-tif:before { content: '\e883'; } /* '' */
.icon-v12-extension-tiff:before { content: '\e884'; } /* '' */
.icon-v12-extension-ttf:before { content: '\e885'; } /* '' */
.icon-v12-extension-txt:before { content: '\e886'; } /* '' */
.icon-v12-extension-wav:before { content: '\e887'; } /* '' */
.icon-v12-extension-wma:before { content: '\e888'; } /* '' */
.icon-v12-extension-wpl:before { content: '\e889'; } /* '' */
.icon-v12-extension-xhtml:before { content: '\e88a'; } /* '' */
.icon-v12-extension-xlr:before { content: '\e88b'; } /* '' */
.icon-v12-extension-xlsx:before { content: '\e88c'; } /* '' */
.icon-v12-extension-xls:before { content: '\e88d'; } /* '' */
.icon-v12-extension-xml:before { content: '\e88e'; } /* '' */
.icon-v12-extension-zip:before { content: '\e88f'; } /* '' */
.icon-v12-nav-notifications:before { content: '\e890'; } /* '' */
.icon-v12-nav-help:before { content: '\e891'; } /* '' */
.icon-v12-nav-settings:before { content: '\e892'; } /* '' */
.icon-v12-acknowledged:before { content: '\e893'; } /* '' */
.icon-v12-arrow-bold:before { content: '\e895'; } /* '' */
.icon-v12-arrow-medium:before { content: '\e896'; } /* '' */
.icon-v12-attachment-1:before { content: '\e897'; } /* '' */
.icon-v12-back-1:before { content: '\e898'; } /* '' */
.icon-v12-completed:before { content: '\e899'; } /* '' */
.icon-v12-end-1:before { content: '\e89c'; } /* '' */
.icon-v12-ended:before { content: '\e89d'; } /* '' */
.icon-v12-filter-1:before { content: '\e89e'; } /* '' */
.icon-v12-forth-1:before { content: '\e89f'; } /* '' */
.icon-v12-db-gear:before { content: '\e8a0'; } /* '' */
.icon-v12-refresh-1:before { content: '\e8a1'; } /* '' */
.icon-v12-db-question:before { content: '\e8a2'; } /* '' */
.icon-v12-save-1:before { content: '\e8a4'; } /* '' */
.icon-v12-share-1:before { content: '\e8a5'; } /* '' */
.icon-v12-start-1:before { content: '\e8a6'; } /* '' */
.icon-v12-db-time:before { content: '\e8a7'; } /* '' */
.icon-v12-db-papers:before { content: '\e8a8'; } /* '' */
.icon-v12-data:before { content: '\e8ab'; } /* '' */
.icon-v12-rights-1:before { content: '\e8b6'; } /* '' */
.icon-v12-db-speed:before { content: '\e8bb'; } /* '' */
.icon-v12-delete-active:before { content: '\e8bf'; } /* '' */
.icon-v12-filter-active:before { content: '\e8c2'; } /* '' */
.icon-v12-grid-view-active:before { content: '\e8c4'; } /* '' */
.icon-v12-list-view-avtive:before { content: '\e8c5'; } /* '' */
.icon-v12-refresh-active:before { content: '\e8c7'; } /* '' */
.icon-v12-upload-active:before { content: '\e8ca'; } /* '' */
.icon-v12-download-active:before { content: '\e8cc'; } /* '' */
.icon-v12-attachment-active:before { content: '\e8cd'; } /* '' */
.icon-v12-share-active:before { content: '\e8cf'; } /* '' */
.icon-v12-date-active:before { content: '\e8d1'; } /* '' */
.icon-v12-save-active:before { content: '\e8d3'; } /* '' */
.icon-v12-document-note-active-1:before { content: '\e8d5'; } /* '' */
.icon-v12-document-note-1:before { content: '\e8d6'; } /* '' */
.icon-v12-redaction-active-1:before { content: '\e8d7'; } /* '' */
.icon-v12-redaction:before { content: '\e8d8'; } /* '' */
.icon-v12-remove-active:before { content: '\e8d9'; } /* '' */
.icon-v12-remove:before { content: '\e8da'; } /* '' */
.icon-v12-information-active:before { content: '\e8db'; } /* '' */
.icon-v12-information:before { content: '\e8dc'; } /* '' */
.icon-v12-export-active:before { content: '\e8dd'; } /* '' */
.icon-v12-export:before { content: '\e8de'; } /* '' */
.icon-v12-import-active:before { content: '\e8df'; } /* '' */
.icon-v12-import:before { content: '\e8e0'; } /* '' */
.icon-v12-question-active:before { content: '\e8e2'; } /* '' */
.icon-v12-start-active:before { content: '\e8e3'; } /* '' */
.icon-v12-end-active:before { content: '\e8e5'; } /* '' */
.icon-v12-up:before { content: '\e8e9'; } /* '' */
.icon-v12-down:before { content: '\e8ea'; } /* '' */
.icon-v12-table-sorting:before { content: '\e8eb'; } /* '' */
.icon-v12-table-sorting-bold:before { content: '\e8ec'; } /* '' */
.icon-v12-1-white:before { content: '\e8ed'; } /* '' */
.icon-v12-2-white:before { content: '\e8ee'; } /* '' */
.icon-v12-file-approved:before { content: '\e8ef'; } /* '' */
.icon-v12-file-redacted:before { content: '\e8f0'; } /* '' */
.icon-v12-select-all-active:before { content: '\e8f1'; } /* '' */
.icon-v12-select-all-selected:before { content: '\e8f2'; } /* '' */
.icon-v12-process-error:before { content: '\e8f3'; } /* '' */
.icon-v12-filter-dropdown:before { content: '\e8f4'; } /* '' */
.icon-v12-box-design:before { content: '\e8f5'; } /* '' */
.icon-v12-show-20-active:before { content: '\e8f6'; } /* '' */
.icon-v12-show-50-active:before { content: '\e8f7'; } /* '' */
.icon-v12-pst-empty:before { content: '\e8f8'; } /* '' */
.icon-v12-pst-open:before { content: '\e8f9'; } /* '' */
.icon-v12-show-100:before { content: '\e8fa'; } /* '' */
.icon-v12-custom-search:before { content: '\e901'; } /* '' */
.icon-v12-pii:before { content: '\e903'; } /* '' */
.icon-v12-search-history:before { content: '\e904'; } /* '' */
.icon-v12-business-term:before { content: '\e905'; } /* '' */
.icon-v12-regular-expressions:before { content: '\e906'; } /* '' */
.icon-v12-options:before { content: '\e908'; } /* '' */
.icon-v12-exclusion:before { content: '\e90a'; } /* '' */
.icon-v12-mail-open:before { content: '\e90b'; } /* '' */
.icon-v12-mail:before { content: '\e90c'; } /* '' */
.icon-v12-workflow:before { content: '\e90d'; } /* '' */
.icon-v12-dashoboard:before { content: '\e90e'; } /* '' */
.icon-v12-glossary:before { content: '\e90f'; } /* '' */
.icon-v12-results:before { content: '\e910'; } /* '' */
.icon-v12-securtiy:before { content: '\e911'; } /* '' */
.icon-v12-home-active:before { content: '\e915'; } /* '' */
.icon-v12-home:before { content: '\e916'; } /* '' */
.icon-v12-category-active:before { content: '\e917'; } /* '' */
.icon-v12-category:before { content: '\e918'; } /* '' */
.icon-v12-options-small-active:before { content: '\e919'; } /* '' */
.icon-v12-options-small:before { content: '\e91a'; } /* '' */
.icon-v12-colour-palette-active:before { content: '\e91b'; } /* '' */
.icon-v12-move-file-active-1:before { content: '\e91d'; } /* '' */
.icon-v12-add-to-list-active:before { content: '\e91f'; } /* '' */
.icon-v12-add-to-list:before { content: '\e920'; } /* '' */
.icon-v12-unvisible-active:before { content: '\e921'; } /* '' */
.icon-v12-unvisible:before { content: '\e922'; } /* '' */
.icon-v12-visible:before { content: '\e923'; } /* '' */
.icon-v12-visible-active:before { content: '\e924'; } /* '' */
.icon-v12-add-box-small:before { content: '\e928'; } /* '' */
.icon-v12-add-box:before { content: '\e929'; } /* '' */
.icon-v12-add-folder:before { content: '\e92a'; } /* '' */
.icon-v12-add-folder-small:before { content: '\e92b'; } /* '' */
.icon-v12-redacted-annotated-copy:before { content: '\e92c'; } /* '' */
.icon-v12-redacted-annotated:before { content: '\e92d'; } /* '' */
.icon-v12-page-redaction:before { content: '\e932'; } /* '' */
.icon-v12-page-no-redaction:before { content: '\e933'; } /* '' */
.icon-v12-move-file-1:before { content: '\e935'; } /* '' */
.icon-v12-db-documents:before { content: '\e936'; } /* '' */
.icon-v12-db-file-move:before { content: '\e937'; } /* '' */
.icon-v12-db-storage:before { content: '\e939'; } /* '' */
.icon-v12-db-user-group:before { content: '\e93a'; } /* '' */
.icon-v12-db-user:before { content: '\e93b'; } /* '' */
.icon-v12-db-clock:before { content: '\e93c'; } /* '' */