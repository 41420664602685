.business-process-page {
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  //width: 100%;
  background: #FFFFFF;
  //margin-top: 100px;
  //padding:30px 100px 0 100px;
  margin: 100px 82px;
  padding: 30px 0px 0;
  &__content {
    .business-process-header {
      display: flex;
      flex-direction: row;
      color: #382e34;
      align-items: center;

      .title-header {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
      }
    }

    .content-business-process-page {
      margin-top: 40px;
      .business-process-builder {
        .business-process-builder-header {
          display: flex;
          align-items: center;
          align-content: center;
          height: 30px;
          .icon-v21-back-active {
            color: var(--generalIconFontHover);
            font-size: 18px;
            margin-right: 10px;
            cursor: pointer;
          }
          .icon-v21-back-1 {
            color: #382e34;
            font-size: 18px;
            margin-right: 10px;
          }
          .first-name {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.2px;
            margin-right: 10px;
          }
          .last-name {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
          }
        }
        .buttons-action-business-process {
          display: flex;
          justify-content: flex-end;
          height: 25px;
          align-items: center;
          margin-top: 40px;
          margin-bottom: 30px;
          .action-cancel-business-process {
            width: 180px;
            height: 25px;
            border: 1px solid #6e7882;
            background-color: #FFFFFF;
            color: #6e7882;
            box-shadow: none;
            cursor: pointer;
            padding: 0px 30px;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.5px;
            alignment: center;
            vertical-align: middle;
            line-height: 18.75px;
            margin-right: 30px;

            &:hover {
              background-color: #FFFFFF;
              border-color: var(--generalButtonHover);
              color: var(--generalButtonHover);
            }

            &:focus {
              border: 1px solid #6e7882;
              background-color: #FFFFFF;
              color: #6e7882;
              -webkit-box-shadow: none;
              box-shadow:none;
            }

            &:active {
              border: 1px solid var(--generalButtonActive);
              background-color: #FFFFFF;
              color: var(--generalButtonActive);
            }
          }
          .action-save-business-process {
            width: 180px;
            height: 25px;
            border: 1px solid #6e7882;
            background-color: var(--generalButton);
            color: var(--generalButtonFont);
            box-shadow: none;
            cursor: pointer;
            padding: 0px 30px;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;
            alignment: center;
            vertical-align: middle;
            line-height: 18.75px;

            &:hover {
              background-color: var(--generalButtonHover);
              border-color: var(--generalButtonHover);
              //color: #FFFFFF;
            }

            &:focus {
              border: 1px solid #6e7882;
              //background-color: #EDA41E;
              color: #FFFFFF;
              -webkit-box-shadow: none;
              box-shadow: none;
            }

            &:active {
              border: 1px solid var(--generalButtonActive);
              background-color: var(--generalButtonActive);
              //color: #FFFFFF;
            }
          }
          .action-save-business-process:disabled {
            cursor: default;
            //opacity: 0.6;
            background: #D4D7DA;
            &:hover {
              background: #D4D7DA;
              border-color: #D4D7DA;
              color: #FFFFFF;
            }

            &:focus {
              border: 1px solid #00D3D8;
              background: #D4D7DA;
              color: #FFFFFF;
            }
          }
        }

        .content-business-builder {
          .component-builder {
            .component-header {
              display: flex;
              justify-content: space-between;
              margin-top: 60px;
              height: 30px;
              border-bottom: 1px solid #382e34;
              margin-bottom: 10px;
              .name-component {
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.2px;
                color: #382e34;
              }
              .icon-v17-add {
                font-size: 16px;
                color: var(--generalButton);
              }
              .icon-v17-add-active {
                font-size: 16px;
                color: var(--generalIconFontHover);
                cursor: pointer;
              }
            }
            .content-header-components {
              .dragging {
                border: 1px solid #eda41e;
              }
              .component-form-header {
                display: flex;
                font-family: Roboto;
                height: auto;
                //max-height: 50px;
                align-items: center;
                //margin-left: 10px;
                background-color: #F5F5F5;
                margin-bottom: 10px;
                padding-bottom: 10px;
                padding-top: 10px;
                border: 1px solid #6e7882;

                .component-delete {
                  width: 4%;
                  display: flex;

                  .icon-v19-delete {
                    font-size: 18px;
                    color: #382e34;
                  }

                  .icon-v19-delete-active {
                    font-size: 18px;
                    color: var(--generalIconFontHover);
                    cursor: pointer;
                  }
                }
                .component-content {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  .header-component {
                    flex-direction: column;
                    //justify-content: space-between;
                    margin-bottom: 5px;
                    color:#000;
                    font-size: 18px;
                    margin-top: 5px;
                    .header-content {
                      width: 100%;
                      display: flex;
                      .contain-header {
                        width: 100%;
                        display: flex;
                        align-items: center;
                      }
                      .header-title.header-title-update {
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 10px;
                        letter-spacing: 0.2px;
                        width: 96%;
                        display: flex;
                      }
                      .icon-v21-edit-active {
                        color: var(--generalIconFontHover);
                        cursor: pointer;
                      }
                      .input-other-option {
                        width: 96%;
                        color: #000000;
                        font-family: Roboto;
                        font-weight: 700;
                        &:hover {
                          border: 1px solid var(--generalIconFontHover);
                          box-shadow: none;
                        }
                        &:focus {
                          border: 1px solid #EDA41E;
                          box-shadow: none;
                        }
                        &:active {
                          border: 1px solid #EDA41E;
                          box-shadow: none;
                        }
                      }
                    }
                  }
                  .input-activities-content {
                    margin-bottom: 10px;
                    .header-form-name {
                      border: 1px solid #D4D7DA;
                      color: #6e7882;
                      background-color:#FFFFFF;
                      padding: 0 10px;
                      font-size: 14px;
                      font-weight: 400;
                      border-radius: 0px;
                      min-height: 30px;
                      width: 99%;
                      cursor: text;
                      &:hover {
                        border: 1px solid var(--generalIconFontHover);
                        box-shadow: none;
                      }
                      &:focus {
                        border: 1px solid #D4D7DA;
                        box-shadow: none;
                      }
                      &:active {
                        border: 1px solid #D4D7DA;
                        box-shadow: none;
                      }
                    }
                    .label-input {
                      font-size: 12px;
                      font-family: Roboto;
                      color: #6e7882;
                      font-weight: 400;
                      margin-top: 5px;
                      margin-bottom: 5px;
                      display: flex;
                    }
                  }
                  .component-move-header {
                    width: 3%;
                    display: flex;
                    justify-content: center;
                    .icon-v211-grid-view-1:hover {
                      font-size: 14px;
                      color: #EDA41E;
                    }
                    .icon-v211-grid-view-1 {
                      font-size: 14px;
                      color: #382e34;
                    }
                  }
                }
              }
            }
            .content-data-source {
              .content-select-data-source {
                .ant-select {
                  width: 97%;
                  margin: 10px 15px 10px 0;
                }
                .ant-select-selection:hover {
                  border-color: var(--generalIconFontHover);
                }
                .ant-select-selection:active {
                  border-color: #eda41e;
                }
                .ant-select-selection:focus {
                  border-color: #eda41e;
                }
                .icon-v16-minus-active {
                  cursor: pointer;
                }
              }
              .content-connectors-location {
                display: flex;
                flex-direction: column;
                .name-connectors {
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0.2px;
                  color: #6e7882;
                  line-height: 20px;
                  margin-left: 5px;
                  height: 30px;
                }
              }
              .content-result-select-data-source {
                .content-one {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .content-title {
                    .title1 {
                      font-size: 14px;
                      font-weight: 700;
                      letter-spacing: 0.2px;
                      color: #382e34;
                    }
                    .title2 {
                      font-size: 14px;
                      font-weight: 500;
                      letter-spacing: 0.2px;
                      color: #DAD7DA;
                    }
                  }
                  .content-option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                    .icon-v17-add {
                      font-size: 16px;
                      color: #EDA41E;
                    }
                    .ant-input {
                      width: 100%;
                      margin-right: 10px;
                    }
                  }
                }
                .content-two {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 20px;
                  .content-title {
                    .title1 {
                      font-size: 14px;
                      font-weight: 700;
                      letter-spacing: 0.2px;
                      color: #382e34;
                    }
                    .title2 {
                      font-size: 14px;
                      font-weight: 500;
                      letter-spacing: 0.2px;
                      color: #DAD7DA;
                    }
                  }
                  .content-option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                    .ant-calendar-picker {
                      width: 50%;
                    }
                    .date-picker {
                      border: 1px solid lightgray;
                      border-radius: 4px;
                    }
                    .ant-calendar-picker .ant-calendar-picker-input {
                      border: none;
                      border-bottom: none;
                      border-radius: 0;
                      padding-left: 0;
                      color: #495057;
                      outline: none;
                      box-shadow: none !important;
                    }
                    .ant-select {
                      width: 50%;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }

            .content-proposed-categories {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              align-content: center;
              .input-proposed {
                width: 97%;
                margin-top: 10px;
                &:hover {
                  border-color:var(--generalIconFontHover);
                }
                &:focus {
                  //border-color:#eda41e;
                  box-shadow: none;
                }
              }
              .icon-v16-minus-active {
                cursor: pointer;
              }
            }

          }
        }
      }
      .business-process-table {
         .content-buttons-actions {
           display: flex;
           justify-content: flex-end;
           align-items: center;
           font-size: 20px;
           .action-edit {
             margin-right: 25px;
           }
           .icon-v17-edit-active {
             color: var(--generalButton);
             cursor: pointer;
           }
           .action-delete {
             margin-right: 25px;
           }
           .icon-v17-delete-active {
             color: var(--generalButton);
             cursor: pointer;
           }
           .icon-v17-add {
             color: var(--generalButton);
           }
           .icon-v17-add-active {
             color: var(--generalButton);
             cursor: pointer;
           }
         }

        .content-table-business-process {
          margin-top: 40px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
          .list-process {
            display: flex;
            font-family: Roboto;
            font-style: normal;
            width: 100%;
            align-items: center;
            flex-direction: column;
            .row-list-process:hover {
              background-color: rgba(154,224,252,0.3);
              cursor: pointer;
            }
            .row-list-process {
              display: flex;
              justify-content: space-between;
              width: 100%;
              background-color: rgba(102,83,96,0.13);
              padding-left: 10px;
              height: 30px;
              align-items: center;
              margin-bottom: 20px;
              .content-checkbox {
                .ant-checkbox-wrapper {
                  .ant-checkbox-inner {
                    border-radius: 0!important;
                    border:1px solid #6e7882;
                    &:after {
                      border-color:#eda41e;
                      background-color: transparent;
                    }
                    &:hover {
                      border-color:var(--generalIconFontHover);
                    }
                  }
                }
                .ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: var(--generalIconFontActive) !important;
                    &:after {
                      position: absolute !important;
                      display: table;
                      color:#FFFFFF;
                      border:none !important;
                      transform: rotate(0deg) scale(1) translate(-50%, -50%);
                      opacity: 1;
                      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                      content: "\e8ce" !important;
                      top: 8px !important;
                      left: 7px !important;
                      font-size: 12px !important;
                      font-family: "smartbox_icons_v19" !important;
                    }
                  }
                }
              }
              .content-name {
                width: 100%;
                .name-first {
                  font-weight: 700;
                  font-size: 14px;
                  color: #382e34;
                  line-height: 20px;
                  margin-left: 10px;
                }
                .name-last {
                  font-weight: 400;
                  font-size: 14px;
                  color: #6e7882;
                  line-height: 16px;
                  margin-left: 10px;
                }
                .ant-checkbox-wrapper {
                  .ant-checkbox-inner {
                    border-radius: 0!important;
                    border:1px solid #6e7882;
                    &:after {
                      border-color:#eda41e;
                      background-color: transparent;
                    }
                    &:hover {
                      border-color:var(--generalIconFontHover);
                    }
                  }
                }
                .ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: var(--generalIconFontActive) !important;
                    &:after {
                      position: absolute !important;
                      display: table;
                      color:#FFFFFF;
                      border:none !important;
                      transform: rotate(0deg) scale(1) translate(-50%, -50%);
                      opacity: 1;
                      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                      content: "\e8ce" !important;
                      top: 8px !important;
                      left: 7px !important;
                      font-size: 12px !important;
                      font-family: "smartbox_icons_v19" !important;
                    }
                  }
                }
              }
              .icon-v14-arrow-bold {
                -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
                font-size: 18px;
                padding-right: 3px;
                padding-left: 10px;
                //position: absolute;
                //right: 0;
              }
            }
          }
          .content-empty {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .anticon {
              font-size: 60px;
              color: #D4D7DA;
            }
            .no-data {
              color: #D4D7DA;
              font-size: 14px;
              font-weight: 300;
            }
          }
        }
        .new-process-section {
          font-family: Roboto;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    }
  }
}

.new-process-modal {
  width: 790px !important;
  height: 255px;
  box-shadow: 2px 14px 30px rgba(21,0,16,0.3);
  color: #382e34 !important;
 .content-header-modal {
   display: flex;
   justify-content: space-between;
   padding: 10px;
   background-color: var(--subNavigationBackground);
   .icon-v21-close-black {
     font-size: 16px;
     color: #FFFFFF;
   }
 }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-content {
    box-shadow: none !important;
    border-radius: 0px !important;
    border: none !important;
  }
  .ant-modal-header {
    background: #66525f;
    height: 40px;
    padding: 0px;
    position: relative;
    border-radius: 0px;
    border-bottom: none;
    color: transparent;

    .ant-modal-title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 20px;
    }
  }
  .new-process-section {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    .new-process-form {
       .process-modal-line {
         label {
           margin: 10px 0;
           font-size: 14px;
           font-weight: 600;
           color: #382e34;
         }
         .ant-input:hover {
           border-color: #eda41e;
         }
         .ant-input:focus {
           border-color: #eda41e;
           box-shadow: none;
         }
       }
      .process-detail-line {
        margin-top: 10px;
      }
      .content-buttons-modal {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;
        .cancel-action {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: #FFFFFF;
          color: #6e7882;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color: #FFFFFF;
            border-color: var(--generalButtonHover);
            color: var(--generalButtonHover);
          }

          &:focus {
            border: 1px solid #6e7882;
            background-color: #FFFFFF;
            color: #6e7882;
            -webkit-box-shadow: none;
            box-shadow:none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: #FFFFFF;
            color: var(--generalButtonActive);
          }
        }
        .create-process {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: var(--generalButton);
          color:var(--generalButtonFont);
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color: var(--generalButtonHover);
            border:1px solid var(--generalButtonHover);
            //color: #FFFFFF;
          }

          &:focus {
            border: 1px solid #6e7882;
            background-color: #EDA41E;
            color: #FFFFFF;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
            //color: #FFFFFF;
          }
        }
        .create-process:disabled {
          background-color: #D4D7DA;
          color: #FFFFFF;
        }
      }
    }
  }
}
.select-data-source-dropdown {
  border: 1px solid var(--generalIconFontHover);
  z-index: 1 !important;
}