.dpiaNew-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 120px 20px 0;
}

.dpiaNew-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
}

.dpiaNew-exceedBox {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  position: relative;
}

.dpiaNew-exceedText {
  line-height: 1.5;
  margin-bottom: 20px;
a {
  color: #3DD8DB;
  font-size: 14px;
  text-decoration: underline;
&:hover {
   font-style: italic;
 }
}
}

.dpiaNew__radio-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #3DD8DB;
  border-radius: 45px;
  display: inline-block;
  height: 50px;
  margin-right: 0;
  position: relative;
  width: 50px;
&.checked {
   background-color: #3DD8DB;
 }
&.checkedDisabled {
   background-color: #EEEEEE;
   border: 1px solid #EEEEEE;
 }
&.disabled {
   border: 1px solid #EEEEEE;
 }
}

.dpiaNew__radio-label {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  top: 4px;
  transition: all .5s ease;
  width: 30px;
  z-index: 1;
}

.dpiaNew__radio-label--disabled {
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  top: 4px;
  transition: all .5s ease;
  width: 30px;
  z-index: 1;
}

.dpiaNew__radio-label--checked {
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 27px;
  left: 10px;
  position: absolute;
  top: 10px;
  transition: all .5s ease;
  width: 27px;
  z-index: 1;
  padding-top: 6px;
  padding-left: 6px;
&:before {
   font-family: 'FontAwesome';
   content: "\f00c";
   color: #3DD8DB;
   font-size: 16px;
 }
}

.dpiaNew__radio-label--checkedDisabled {
  background-color: #ffffff;
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 27px;
  left: 10px;
  position: absolute;
  top: 10px;
  transition: all .5s ease;
  width: 27px;
  z-index: 1;
  padding-top: 6px;
  padding-left: 6px;
&:before {
   font-family: 'FontAwesome';
   content: "\f00c";
   color: #3DD8DB;
   font-size: 16px;
 }
}

.dpiaNew__radio-info {
  display: inline-block;
  margin-left: 20px;
h4 {
  margin-bottom: 5px;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
}
div {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #737e89;
span {
  font-weight: 700;
}
}
}

.dpiaNew-radioGroup:nth-child(2) {
  margin: 20px 0;
}

.dpiaNew-section {
  border-top: 1px solid #E6E6E6;
  padding: 20px 20px 0;
  position: relative;
}

.dpiaNew-sectionSideBySide {
  display: flex;
}

.dpiaNew-sectionTitle {
  font-size: 18px;
  font-family: 'Ubuntu';
  font-weight: 700;
  margin-bottom: 5px;
}

.dpiaNew-sectionSubtitle {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  font-family: 'Roboto';
  color: #737e89;
}

.dpiaNew-formGroup, .dpiaNew-formGroupSideBySide {
  margin-bottom: 20px;
  width: 100%;
}

.dpiaNew-formGroup label {
  color: #000;
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: 'Roboto';
  font-weight: 400;
}

.dpiaNew-formGroupSideBySide label {
  color: #000;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}

.dpiaNew-formGroup {
input {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  color: #000;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
  font-family: 'Roboto';
  font-weight: 400;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
}

.dpiaNew-formGroupSideBySide select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  color: #000;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}

.dpiaNew-formGroup textarea {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  height: 100px;
  padding: 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}

.dpiaNew-text {
  margin-bottom: 10px;
  font-family: 'Roboto';
  font-weight: 400;
}

.dpiaNew-option {
  margin-top: 20px;
}

.dpiaNew-radioGroup {
  align-items: center;
  display: flex;
}

.dpiaNew-periodGroup {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.dpiaNew-radio {
  background-color: #FFFFFF;
  border-radius: 45px;
  border: 1px solid #E0E8E8;
  display: inline-block;
  height: 40px;
  margin-right: 0;
  position: relative;
  width: 40px;
}

.dpiaNew-yes, .dpiaNew-no {
  background-color: #FFFFFF;
  border-radius: 45px;
  border: 1px solid #E0E8E8;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 100px;
}

.dpiaNew-yesLabel {
  border-radius: 45px;
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: all .5s ease;
  width: 100px;
  z-index: 1;
&::before {
   background-color: #3DD8DB;
   border-radius: 50%;
   color: #FFFFFF;
   content: '✓';
   height: 25px;
   left: 10px;
   line-height: 25px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.dpiaNew-yesLabelChecked {
  background-color: #3DD8DB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: all .5s ease;
  width: 100px;
  z-index: 1;
&::before {
   background-color: #FFFFFF;
   border-radius: 50%;
   color: #3DD8DB;
   content: '✓';
   height: 25px;
   left: 10px;
   line-height: 25px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.dpiaNew-noLabel {
  border-radius: 45px;
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: all .5s ease;
  width: 100px;
  z-index: 1;
&::before {
   background-color: #3DD8DB;
   border-radius: 50%;
   color: #FFFFFF;
   content: '☓';
   height: 25px;
   left: 10px;
   line-height: 25px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.dpiaNew-noLabelChecked {
  background-color: #3DD8DB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: all .5s ease;
  width: 100px;
  z-index: 1;
&::before {
   background-color: #FFFFFF;
   border-radius: 50%;
   color: #3DD8DB;
   content: '☓';
   height: 25px;
   left: 10px;
   line-height: 25px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.dpiaNew-period {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-right: 20px;
}

.dpiaNew-periodDays {
  display: inline-block;
  margin-left: 5px;
}

.dpiaNew-periodLabel {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.dpiaNew-periodLabelChecked {
  background-color: #3DD8DB;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.dpiaNew-footer {
  border-top: 1px solid #E6E6E6;
  padding: 20px 50px 20px 20px;
}

.dpiaNew-footerWrapper {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
button {
  margin-top: 20px;
  padding: 8px 0;
  width: 310px;
}
}

.dpiaNew-btn-top {
  margin: -50px 50px 20px 0;
}

.dpiaNew-page .pageTitle-title {
  padding-top: 20px;
}

.dpiaNew-first-btn {
  display: inline-block;
  margin-right: 20px;
}

select#user {
  line-height: 20px;
}

.css-vj8t7z {
  height: 43px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50%,linear-gradient(0deg, rgba(215, 215, 215, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%);
}

@media screen and (min-width: 768px) {
  .dpiaNew-page {
    padding: 120px 40px 0;
  }
  .dpiaNew-formGroup {
    width: 600px;
  }
  .dpiaNew-formGroupSideBySide {
    width: 280px;
&:last-of-type {
   margin-left: 20px;
 }
}
.dpiaNew-periodGroup {
  flex-direction: row;
}
.dpiaNew-period {
  margin-bottom: 0;
}
.dpiaNew-footer {
  justify-content: flex-start;
}
.dpiaNew-footerSave {
  width: 100%;
  text-align: right;
}
.dpiaNew-footerWrapper {
  justify-content: space-between;
  flex-direction: row;
button {
  margin-top: 0;
}
}
}

@media screen and (max-width: 670px) {
  .dpiaNew-btn-top {
    margin: 10px 25px 20px 20px;
    display: block;
  }
  .dpiaNew__radio-wrapper {
    padding-right: 46px;
  }
  .dpiaNew-formGroupSideBySide {
    padding-right: 10px;
  }
}