.update-business-terms-page {
  .business-terms-page {
    &__dictionaries {
      margin-bottom: 60px;
    }
  }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #FFFFFF;
    background-color: #FFFFFF;
    color: #FFFFFF;
    border: none !important;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    transform: rotate(0deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
    content: '\e8ce' !important;
    top: 7px !important;
    left: 7px !important;
    font-size: 12px !important;
    font-family: 'smartbox_icons_v19' !important;
  }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--generalIconFontActive) !important;
    border: 1px solid #6e7882;
  }
  .line-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-checkbox {
    top: 0px;
  }
  .ant-checkbox + span {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .ant-checkbox-wrapper .ant-checkbox-inner {
    border-radius: 0% !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1px solid #fff;
    border-top: 0;
    border-left: 0;
    top: 46%;
    left: 19%;
  }
  .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
    border-color: var(--generalIconFontHover);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--generalIconFontHover);
  }
  .selected-all-list-active {
    .ant-checkbox-inner {
      background-color: #EDA41E;
    }
    .ant-checkbox-inner::before {
      border-color: #FFFFFF;
      position: absolute !important;
      display: table;
      color: #FFFFFF !important;
      border: none !important;
      background-color: var(--generalIconFontActive);
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      top: 7px !important;
      left: 7px !important;
      font-size: 12px !important;
      font-family: 'smartbox_icons_v19' !important;
    }
    button:disabled {
      background-color: #E4C801;
    }
  }
  .selected-children-list {
    .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: #FFFFFF;
      position: absolute !important;
      display: table;
      color: #FFFFFF !important;
      border: none !important;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      top: 7px !important;
      left: 7px !important;
      font-size: 12px !important;
      font-family: 'smartbox_icons_v19' !important;
    }
    .ant-checkbox-inner::before {
      border-color: #FFFFFF;
      position: absolute !important;
      display: table;
      color: #6e7882 !important;
      border: none !important;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      top: 7px !important;
      left: 7px !important;
      font-size: 12px !important;
      font-family: 'smartbox_icons_v19' !important;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner::before {
        color: transparent !important;
      }
    }
  }
  .selected-all-list {
    background-color: #01E493;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-top: 8px;
    //padding-bottom: 2px;
    .icon-v10-edit-b {
      color: #382e34;
      font-size: 16px;
      cursor: pointer;
      color:red;
    }
    .icon-v10-edit-b:before {
       margin-right: 10px;
    }
    .icon-v17-edit {
      color: #382e34;
      font-size: 20px;
      cursor: pointer;
    }
    .icon-v17-edit-active {
      color: #382e34;
      font-size: 20px;
      cursor:pointer;
    }
  }
}
.business-terms-page {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /*border: 2px solid rgba(0, 0, 0, 0.1);*/
  .ant-checkbox {
    padding: 7px 10px 7px 10px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: #6e7882;
  }
  .ant-checkbox-wrapper {
    //padding: 7px -1px;
  }
  .ant-checkbox-inner {
    border: 1px solid #6e7882;
  }
  .ant-checkbox-wrapper .ant-checkbox-inner {
    border-radius: 50%;
  }
  .ant-checkbox+span {
    padding-right: 0px;
    padding-left: 10px;
  }
  & > div > div > .ant-checkbox-wrapper {
    height: 30px;
    display: flex;
    align-items: center;

    span:not(.ant-checkbox) {
      font-size: 14px;
      font-weight: 700;
      color: #382e34;
    }
  }
  &__list-container {
    margin-bottom: 20px;

    &__title {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &__name {
        font-size: 16px;
        font-weight: bold;
        color: #6e7882;
      }

      &__expandable-icon {
        font-size: 30px;
        color: #6e7882;
      }
    }

    &__content {
      .ant-checkbox-group {
        width: 100%;
      }
      .content-name-dictionary {
        display: flex;
        align-items: center;
        .business-terms-item {
          font-weight: 400;
          font-size: 14px;
          color: #6e7882;
          cursor: default;
          &:hover {
            color: #382e34;
            cursor: pointer;
          }
        }
      }
      &__term {
        font-size: 16px;
        font-weight: 300;
        color: black;
        padding: 0px 0px 0px 0px !important;

      }

      .radio-style {
        display: block;
        height: 30px;
        line-height: 30px;
        color: #000000;
        font-weight: 300;
        font-size: 16px;
      }

      &__buttons-section {
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        padding-top: 20px;
        width: 100%;
        cursor: pointer;

        .text-add {
          font-size: 16px;
          font-weight: 300;
          color: #000000;
          padding-left: 10px;
          padding-top: 8px;
        }

        button {
          margin-left: 10px;
          cursor: pointer;
          font-size: 31px;
        }
        .icon-minus-b {
          color: red;
        }
      }
    }
  }

  &__builder-container {
    &__title {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &__name {
        font-size: 16px;
        font-weight: bold;
        color: #6e7882;
      }

      &__expandable-icon {
        font-size: 30px;
        color: #6e7882;
      }
    }

    &__content {
      &__buttons-section {
        /*display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        padding-top: 20px;
        width: 100%;*/
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 10px;

        .text-add {
          font-size: 16px;
          font-weight: 300;
          color: #000000;
          padding-left: 10px;
          padding-top: 8px;
        }

        button {
          margin-left: 10px;
          cursor: pointer;
          font-size: 20px;
        }

        .icon-add-b {
          color: #00d3d8;
        }
      }

      &__input-section {
        width: 100%;
        position: relative;
        /*padding-top: 20px;*/

        input {
          width: 100%;
          height: 40px;
          border-radius: 3px;
          border: solid 1px #6e7882;
          background-color: white;
          color: #000000;
          font-weight: 400;
          font-size: 14px;
          /*opacity: 0.5;*/

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  &__dictionaries {
    margin-bottom: 50px;
    .ant-collapse {
      /*padding: 30px 20px 40px 20px;*/
      background-color: white;
    }
    &__list-container {
      margin-bottom: 20px;

      &__title {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &__name {
          font-size: 16px;
          font-weight: bold;
          color: #6e7882;
        }

        &__expandable-icon {
          font-size: 30px;
          color: #6e7882;
        }
      }

      &__content {
        .ant-checkbox-group {
          width: 100%;
        }
        &__term {
          font-size: 16px;
          font-weight: 300;
          color: black;
          padding: 5px 15px;

        }

        .radio-style {
          display: block;
          height: 30px;
          lineHeight: 30px;
          color: #000000;
          font-weight: 300;
          font-size: 16px;
        }

        &__buttons-section {
          display: flex;
          justify-content: flex-end;
          margin-right: 15px;
          padding-top: 20px;
          width: 100%;
          cursor: pointer;

          .text-add {
            font-size: 16px;
            font-weight: 300;
            color: #000000;
            padding-left: 10px;
            padding-top: 8px;
          }

          button {
            margin-left: 10px;
            cursor: pointer;
            font-size: 31px;
          }
          .icon-minus-b {
            color: red;
          }
        }
      }
    }
    .dictionaries-collapse {
      .ant-collapse-item {
        border-bottom: none;
        .ant-collapse-header {
          border-bottom: 1px solid #aaaaaa;
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          padding-left: 0 !important;
          .anticon {
            font-size: 20px !important;
            color: #6e7782;
          }
        }
        &-active {
          .ant-collapse-header {
            color: #00d3d8;
            border-color: #00d3d8;
          }
        }
      }
      .row {
        justify-content: space-between;
        border-bottom: 1px solid #aaaaaa;
        margin: 0;
        padding: 10px 0;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        span {
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
        }
        .icon-delete-b {
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .tree {
        .ant-tree {
          .ant-tree-treenode-switcher-open {
            > .ant-tree-node-content-wrapper-open {
              .ant-tree-title {
                color: #3DD8DB;
              }
            }
            > .ant-tree-switcher_open {
              .anticon {
                color: #3DD8DB;
              }
            }
            > .ant-tree-node-content-wrapper-open {
              &:before {
                border-color: #3DD8DB;
              }
            }
          }
          .ant-tree-title {
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            color: #6e7782;
          }
          .ant-tree-node-content-wrapper {
            //width: 100%;
            &:before {
              border-bottom: 1px solid #aaaaaa;
              padding-bottom: 30px !important;
              background-color: transparent;
            }
          }
          .ant-tree-treenode-switcher-close, .ant-tree-treenode-switcher-open {
            padding-top: 17px;
          }
          .ant-tree-child-tree {
            .ant-tree-treenode-switcher-close, .ant-tree-treenode-switcher-open {
              padding-top: 20px;
              .ant-tree-node-content-wrapper {
                width: 100%;
                &:before {
                  border-bottom: 1px solid #aaaaaa;
                  padding-bottom: 30px !important;
                  background-color: transparent;
                }
              }

              .ant-tree-node-selected {
                background-color: transparent;
              }
            }
          }
          .ant-tree-switcher {
            float: right;
            .anticon {
              font-family: Roboto;
              font-size: 20px !important;
              font-weight: bold;
              color: #6e7782;
            }
          }
          .ant-tree-checkbox {
            &-inner {
              border-radius: 50%;
              border: solid 1px #6e7882;
              border-color: #6e7782;

              &:after {
                color: #3DD8DB !important;
                font-size: 8px !important;
                font-family: 'FontAwesome' !important;
                top: 0.6px;
                left: 0;
                background-color: #FFFFFF;
                border-radius: 50%;
                -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
              }
            }
            &-checked {
              .ant-tree-checkbox-inner {
                background-color: #04D3D8;
                border: solid 3px #3DD8DB;

                &:after {
                  content: "\f00c";
                  color: #3DD8DB;
                  font-size: 8px;
                  font-family: 'FontAwesome';
                  top: 0;
                  left: 0;
                  background-color: #FFFFFF;
                  border-radius: 50%;
                  border-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
  &__terms {
    .row {
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
      display:block;
      background-color: #A186FF;
      padding-top: 2px;
    }
    .ant-collapse {
      /*padding: 30px 20px 40px 20px;*/
      background-color: white;
    }
    .terms-collapse {
      .ant-collapse-content {
        &-box {
          padding-left: 0;
        }
        >.ant-collapse-content-box{
          padding-left: 0;
        }
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 0 !important;
      }
      .ant-collapse-item {
        border-bottom: none;
        .ant-collapse-header {
          border-bottom: 1px solid #aaaaaa;
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          padding-left: 0 !important;
          .anticon {
            font-size: 16px !important;
          }
        }
        &-active {
          .ant-collapse-header {
            color: #00d3d8;
            border-color: #00d3d8;
          }
        }
      }
      .row {
        justify-content: space-between;
        border-bottom: 1px solid #aaaaaa;
        margin: 0;
        padding: 10px 0;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        span {
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
        }
        .icon-delete-b {
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .list {
        .ant-checkbox-group {
          .ant-checkbox-wrapper {
            padding: 10px 0;
          }
        }
        .business-terms-page__builder-container {
          &__content {
            display: flex;
            justify-content: space-between;
            &__input-section {
              width: 155%
            }
            &__buttons-section {
              width: 40%;
              padding-top: 18px;
            }
          }
        }
      }
    }
  }
  .ant-tree.ant-tree-directory {
    .ant-tree-child-tree {
      > li.ant-tree-treenode-selected {
        > span.ant-tree-checkbox {
          .ant-tree-checkbox-inner {
            border-color: #6e7782;
          }
        }
      }
    }
  }
  .ant-tree.ant-tree-directory {
    .ant-tree-child-tree {
      > li.ant-tree-treenode-selected {
        > span.ant-tree-checkbox.ant-tree-checkbox-checked {
          .ant-tree-checkbox-inner::after {
            border-color: transparent;
          }
        }
      }
    }
    > li.ant-tree-treenode-selected {
      > span.ant-tree-checkbox.ant-tree-checkbox-checked {
        .ant-tree-checkbox-inner::after {
          border-color: transparent;
        }
      }
      > span.ant-tree-checkbox {
        .ant-tree-checkbox-inner {
          border-color: #6e7782;
        }
      }
    }
  }
  .analysis-custom-color-picker {
    position: absolute;
  }
}
.new-business-terms-page {
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #FFFFFF;
    background-color: transparent !important;
  }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #EDA41E !important;
    border: 1px solid #6e7882;
  }
  .ant-checkbox-wrapper .ant-checkbox-inner {
    border-radius: 0% !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 3px solid #fff;
    border-top: 0;
    border-left: 0;
  }
}
.business-terms-page {
  .ant-tree.ant-tree-directory {
    > li.ant-tree-treenode-selected {
      > span.ant-tree-checkbox {
        .ant-tree-checkbox-inner {
          border-color: #3dd8db;
        }
      }
    }
    .ant-tree-child-tree {
      > li.ant-tree-treenode-selected {
        > span.ant-tree-checkbox {
          .ant-tree-checkbox-inner {
            border-color: #3dd8db;
          }
        }
      }
    }
  }

  .business-term-scan {
    text-align: right;
    padding-top: 20px;
    .btn-grey {
      background-color: #ccc;
      border-color: #555;
      color: #555;
      cursor: not-allowed;
      &:hover:not([disabled]) {
        background-color: #ccc;
        color: #555;
      }
    }
    .btn-green {
      background-color: #FFF;
      border-color: #3DD8DB;
      color: #3DD8DB;
      cursor: pointer;
      &:hover:not([disabled]) {
        background-color: #3DD8DB;
        color: #FFF;
      }
    }
    .btn-green-fill {
      background-color: #3DD8DB;
      border-color: #3DD8DB;
      color: #FFF;
      cursor: pointer;
      &:hover:not([disabled]) {
        background-color: #FFF;
        color: #3DD8DB;
      }
    }
  }

  .manual-term-scan {
    text-align: right;
    padding-top: 20px;
    .btn-grey {
      background-color: #ccc;
      border-color: #555;
      color: #555;
      cursor: not-allowed;
      &:hover {
        background-color: #ccc;
        color: #555;
      }
    }
    .btn-green {
      background-color: #FFF;
      border-color: #3DD8DB;
      color: #3DD8DB;
      cursor: pointer;
      &:hover {
        background-color: #3DD8DB;
        color: #FFF;
      }
    }
    .btn-green-fill {
      background-color: #3DD8DB;
      border-color: #3DD8DB;
      color: #FFF;
      cursor: pointer;
      &:hover {
        background-color: #FFF;
        color: #3DD8DB;
      }
    }
  }
  .button-green {
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 14px;
    padding: 8px 30px;
    text-transform: uppercase;
    -webkit-transition: all .2s;
    transition: all .2s;
    &:hover {
      background-color: #FFFFFF;
      color: #3DD8DB;
    }
  }
  .button-green-empty {
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #3DD8DB;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 14px;
    padding: 8px 30px;
    text-transform: uppercase;
    -webkit-transition: all .2s;
    transition: all .2s;
    &:hover {
      background-color: #3DD8DB;
      color: #FFFFFF;
    }
  }
  .btn-disabled {
    background-color: #aaaaaa;
    border: 1px solid #aaaaaa;
    color: #ffffff;
    cursor: not-allowed;
    &:hover {
      background-color: #aaaaaa;
      border: 1px solid #aaaaaa;
      color: #ffffff;
    }
  }
  .icon-change-color {
    position: relative;
    float: right;
    width: 16px;
    height: 16px;
    margin-top: 0px;
    cursor: pointer;
  }
  .icon-change-color.disabled {
    cursor: not-allowed;
  }
  &__dictionaries {
    .radio-style {
      display: inline;
      height: 30px;
      lineHeight: 30px;
      color: #000000;
      font-weight: 300;
      font-size: 16px;
    }
  }
  .overflow {
    /*overflow: auto;
    height: 170px;*/
  }
}
.row-icons {
  float:right;
  .manual-selected-color {
    position: relative;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    margin: 4px;
    float: left;
    cursor: pointer;
  }
  .manual-selected-color.disabled {
    cursor: not-allowed;
  }
}

.manual-row-icons {
  display: flex;
  justify-content: space-between;
  height: 32px;
  .input-name{
    width: 80%;
    height: 27px;
    margin-top: 5px;
  }
  .anticon {
    font-size: 16px;
    padding-top: 8px;
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox {
    ant-checkbox-inner {
      border-color: #3DD8DB;
      &:hover {
        border-color: #EDA41E;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: var(--generalIconFontHover);
      }
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #FFF !important;
      border: 3px solid #3DD8DB;
      &:after {
        border-color: #3DD8DB;
        background-color: transparent !important;
      }
    }
    &:after {
      border: none;
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #3DD8DB;
      }
    }
  }
  .ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner {
      border-color: #D9D9D9 !important;
      background-color: #FFF !important;
      &:after {
        border-color: #D9D9D9;
      }
    }
  }
  .ant-checkbox-inner {
    border-radius: 50%;
  }
  &:hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: var(--generalIconFontHover);
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: #3DD8DB;
      }
    }
  }
}
.select-all {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  &__label {
    font-size: 16px;
    font-weight: 400;
    margin-left: 17px;
  }
  &__delete {
    float: right;
    margin-right: 23px;
  }
}