.questionnaireFilter__wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  label, span {
    color: #6E7882;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
}

.questionnaireFilter__button {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.questionnaire-filter__input-find {
  background: url(../../../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  padding-left: 35px;
  height: 42px;
  width: 100%;
  &:focus {
    outline: 2px solid #3DD8DB;
    width: 100%;
  }
}
.questionnaire-filter__input-find-update {
  border: solid 1px;
  border-radius: 0px;
  box-shadow: none;
}

.questionnaireFilter__select {
  align-items: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  select {
    appearance: none;
    background: url(../../../assets/down-caret.png) no-repeat 97% 50% #FFFFFF;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    font-size: 16px;
    height: 42px;
    padding: 10px 25px 10px 10px;
    outline: none;
    width: 100%;
  }
}
.questionnaireFilter__select-update {
  select {
    border: solid 1pt;
    border-radius: 0px;
    box-shadow: none;
  }
}
.questionnaire-filter__input-find {
  width: 0;
  margin-right: 10px;
}
.questionnaireFilter__button {
  margin: 0 10px 0 0;
  label {
    text-align: center;
  }
}
.questionnaireFilter__wrapper {
  flex-direction: row;
  justify-content: space-around;
}
.questionnaireFilter__select {
  margin: 0 20px 0 0;
  max-width: 300px;
  width: 210px;
}
//@media screen and (min-width: 768px) {
  @media screen and (min-width: 768px) { 
  .questionnaire-filter__input-find {
    width: 0;
    margin-right: 10px;
  }
  .questionnaireFilter__button {
    margin: 0 10px 0 0;
    label {
      text-align: left;
    }
  }
  .questionnaireFilter__wrapper {
    flex-direction: row;
    justify-content: space-around;
  }
  .questionnaireFilter__select {
    margin: 0 20px 0 0;
    max-width: 300px;
    width: 210px;
  }
}
@media screen and (max-width: 1497px) and (min-width: 1200px) { 
  #filterStyle {
    width: 135px;
}
.questionnaire-filter__input-find {
  width: 0;
  margin-right: 10px;
}
.questionnaireFilter__button {
  margin: 0 10px 0 0;
  label {
    text-align: center;
  }
}
.questionnaireFilter__wrapper {
  flex-direction: row;
  justify-content: space-around;
}
.questionnaireFilter__select {
  margin: 0 20px 0 0;
  max-width: 300px;
  width: 153px;
}

}