.filterCollection__wrapper {
  align-items: initial;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.filterCollection__wrapper label {
  color: #6E7882;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.filterCollectionItem-labelCentered {
  text-align: left;
}

.filterCollectionItem-button {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.filterCollectionItem-viewButton {
  align-items: center;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  display: flex;
  height: 41px;
  justify-content: center;
  width: 41px;
}

.filterCollectionItem-inputFind {
  background: url(../../../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  padding-left: 35px;
  width: 100%;
}

.filterCollectionItem-inputFind:focus {
  outline: 2px solid #3DD8DB;
  width: 100%;
}

.filterCollectionItem-title {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .filterCollection__wrapper {
    align-items: flex-end;
    flex-direction: row;
  }

  .filterCollectionItem-labelCentered {
    text-align: center;
  }

  .filterCollectionItem-button {
    margin: 0 10px 0 0;
  }

  .filterCollectionItem-inputFind {
    width: 0;
  }
}