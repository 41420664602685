.processList__wrapper {
  display: flex;
  flex-direction: column;
}
.processList__wrapperTable {
  margin-bottom: 20px;
  overflow-y: auto;
  width: 100%;
}

.processList__wrapperTable table {
  width: 100%;
}

.processList__wrapperTable th {
  background-color: #FFFFFF;
  border-bottom: 3px solid #E6E8EA;
  color: #6E7882;
  font-size: 16px;
  font-weight: 400;
  padding: 8px;
  text-transform: uppercase;
}

.processList__wrapperTable td {
  border-bottom: 1px solid #E6E8EA;
  color: #111C26;
  font-size: 16px;
  padding: 8px;
}

.processList__wrapperTable tr:last-of-type td {
  border-bottom: none;
  color: #111C26;
  font-size: 16px;
  padding: 8px;
}

.processList__wrapperTable tr:nth-child(even) td {
  background-color: #FFFFFF;
  font-size: 16px;
}

.processList__wrapperTable tr:nth-child(odd) td {
  background-color: #FBFBFB;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  .processList__wrapper {
    flex-direction: row;
  }
}
