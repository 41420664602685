.header-navigation-item.active * {
  color: var(--subNavigationBodyFontActive);
}

.active-manage-insights {
  color: #EDA41E;
}
.header-navigation-contain {
  height: 45px;
  background-color: var(--subNavigationBackground);
  /*display: flex;*/
  justify-content: space-between;
  position: fixed;
  height: 40px;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border-bottom: 1pt solid #A6ACB2;
  width: calc(100% - 0px);
  float: right;
  top: 60px;
  right: 0;
  z-index: 3;
  color: var(--subNavigationBodyFont);
  font-size: 14px;
  font-weight: 400;

  .header-navigation-item.active {
    //background-color: #584753;
  }
  &__content-box-name {
    align-items: center;
    display: flex;
    flex-grow: 2;
    padding: 10px 10px 10px 30px;
    float:left;
    min-width: 234px;
    &.active {
      color: var(--subNavigationBodyFontActive);
    }
    &:hover {
      color: var(--subNavigationBodyFontHover);
    }
    .icon-v11-box-lined {
      font-size: 20px;

      &:before {
        margin-left: 0;
        margin-right: 0;
      }
    }
    img {
      width: 20px;
    }
    .name-box {
      padding-left: 10px;
      cursor: pointer;
    }
    .name-box-disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  &__content-cull {
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-left: 1px solid var(--subNavigationBodyFont);
    padding: 5px 0 4px;
    margin-top: 7px;
    float: left;
    //width: 180px; TASK 14 GRAV 2583
    width:100px;
    &:hover {
      color: var(--subNavigationBodyFontHover);
    }
    .disabled-generating {
      cursor: not-allowed !important;
      color: #a6acb2;
    }
    .data-cull {
      cursor: pointer;
      margin-left: 20px;
    }
    .enumeration-header-elements {
      //border: 1px solid #FFFFFF;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding-left: 4px;
      padding-top: -1px !important;
      margin-left: 20px;
      margin-right: 15px;
      margin-bottom: 3px;

    }
    .icon-v12-1-white {
      font-size: 20px;
    }
    .icon-v16-gear {
      font-size: 20px;
      cursor: pointer;
    }
    .box-settings {
      cursor: pointer;
    }
    &:hover {
      .enumeration-header-elements {
        color: var(--subNavigationBodyFontHover);
        //border: 1px solid #EDA41E;
      }
      .box-settings {
        color: #EDA41E;
      }
    }
  }

  &__content-data-mapping {
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-left: 1px solid var(--subNavigationBodyFont);
    padding: 5px 0 4px;
    margin-top: 7px;
    float: left;
    width:115px;
    &:hover {
      color: var(--subNavigationBodyFontHover);
    }
    .data-mapping {
      cursor: pointer;
      margin-left: 20px;
    }
    .disabled-generating {
      cursor: not-allowed !important;
      color: #a6acb2;
      margin-left: 20px;
    }
  }
  &__content-manage-data-mapping {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 5px 15px 5px 0px;
    margin-top: 3px;
    float: left;
    width: 50px;
    justify-content: flex-end;
    .icon-v19-share-1 {
      cursor: pointer;
      color: var(--subNavigationBodyFont);
      font-size: 20px;
    }
    &:hover {
      .icon-v19-share-1 {
        color: var(--subNavigationBodyFontHover);
        cursor: pointer;
        font-size: 20px;
      }
    }
    .disabled-generating {
      cursor: not-allowed !important;
      color: #a6acb2;
    }
  }

  &__content-box-settings {
    display: flex;
    align-items: center;
    flex-grow: 1;
    //border-left: 1px solid rgba(255,255,255, 0.2);
    padding: 8px 0;
    float:left;
    width: 65px;
    .disabled-generating {
      cursor: not-allowed !important;
      color: #a6acb2;
    }
    .enumeration-header-elements {
      //border: 1px solid #FFFFFF;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding-left: 4px;
      padding-top: -1px !important;
      margin-left: 20px;
      margin-right: 15px;
      margin-bottom: 3px;

    }
    .icon-v12-1-white {
      font-size: 20px;
    }
    .icon-v16-gear {
      font-size: 20px;
      cursor: pointer;
    }
    .box-settings {
      cursor: pointer;
    }
    &:hover {
      .enumeration-header-elements {
        color: var(--subNavigationBodyFontHover);
        //border: 1px solid #EDA41E;
      }
      .box-settings {
        color: #EDA41E;
      }
    }
  }
  .header-navigation-item {
    .icon-v21-quick-sweep-2:hover {
      color: var(--subNavigationBodyFontHover);
      cursor: pointer;
      font-size: 20px;
    }
    .icon-v22-request-detail-active {
      color: #eda41e;
    }
    &:hover {
      .enumeration-header-elements {
        color: var(--subNavigationBodyFontHover);
        //border: 1px solid #EDA41E;
      }
      .box-settings {
        color: #EDA41E;
      }
    }
  }
  &__content-refine-data {
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-left: 1px solid var(--subNavigationBodyFont);
    padding: 5px 0 4px;
    margin-top: 7px;
    float:left;
    width: 180px;
    .content-report {
      display: flex;
      .dots-report {
        width: 10px;
        height: 10px;
        background-color: #EDA41E;
        display: flex;
        border-radius: 50%;
        margin-left: 3px;
      }
    }
    .enumeration-header-elements {
      //border: 1px solid #FFFFFF;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding-left: 4px;
      padding-top: -1px !important;
      margin-left: 20px;
      margin-right: 15px;
      margin-bottom: 3px;
    }
    .icon-v12-2-white {
      font-size: 20px;
    }
    .disabled-generating {
      cursor: not-allowed !important;
      color: #a6acb2;
    }
    .refine-data {
      cursor: pointer;
      margin-left: 20px;
    }
    &:hover {
      .enumeration-header-elements {
        color: var(--subNavigationBodyFontHover);
        //border: 1px solid #EDA41E;
      }
      .refine-data {
        color: var(--subNavigationBodyFontHover);
      }
    }
  }
  &__content-manage-culling {
    display: flex;
    align-items: center;
    flex-grow: 1;
    //border-left: 1px solid rgba(255,255,255, 0.2); TASK 13 GRAV 2583
    padding: 5px 15px 5px 0px;
    margin-top: 3px;
    float: left;
    width: 50px;
    justify-content: flex-end;
    .disabled-generating {
      cursor: not-allowed !important;
      color: #a6acb2;
    }
    .icon-v21-quick-sweep {
      cursor: pointer;
      font-size: 20px;
    }
    &:hover {
      .icon-v21-quick-sweep {
        color: var(--subNavigationBodyFontHover);
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
  &__content-manage-insights {
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-left: 1px solid var(--subNavigationBodyFont);
    padding: 5px 0 4px;
    margin-top: 7px;
    float:left;
    //width: 180px; TASK 15 GRAV 2583
    width:150px;
    .enumeration-header-elements {
      //border: 1px solid #FFFFFF;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding-left: 4px;
      padding-top: -1px !important;
      margin-left: 20px;
      margin-right: 15px;
      margin-bottom: 3px;

    }
    .icon-v12-3-white {
      font-size: 20px;
    }
    .disabled-generating {
      cursor: not-allowed !important;
      color: #a6acb2;
      &:hover {
        cursor: not-allowed !important;
        color: #a6acb2;
      }
    }
    .manage-insights {
      cursor: pointer;
      margin-left: 20px;
    }
    &:hover {
      .enumeration-header-elements {
        color: var(--subNavigationBodyFontHover);
        //border: 1px solid #EDA41E;
      }
      .manage-insights {
        color: var(--subNavigationBodyFontHover);
      }
    }
  }
  &__content-analysis-progress {
    align-items: center;
    display: flex;
    flex-grow: 1;
    max-width: 300px;
    float:right;
    margin-top: 10px;
    margin-right: 35px;
    .progress-analysis-striped {
      @keyframes move {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 50px 50px;
        }
      }
      .ant-progress-bg {
        background-image: linear-gradient(
                        -45deg,
                        #00B8C4 25%,
                        transparent 25%,
                        transparent 50%,
                        #00B8C4 50%,
                        #00B8C4 75%,
                        transparent 75%,
                        transparent
        );
        z-index: 1;
        background-size: 10px 10px;
        animation: move 4s linear infinite;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        overflow: hidden;
      }
    }
    .reprocessing-files {
      width: 160px;
      display: flex;
      height: 20px;
      background-color: #FFFFFF;
      color: #000000;
      padding: 5px 5px 5px 3px;
      align-items: center;
      .reprocessing-files-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .custom-spin-reprocessing {
        margin-left: 10px;
        .ant-spin-dot {
          width: 10px;
          height: 10px;
        }
        .ant-spin-dot-item {
          width: 5px;
          height: 5px;
          background: #3dd8db;
        }
      }
    }
    .progress-analysis {
      width: 300px;
      display: flex;
      height: 20px;
      background-color: #FFFFFF;
      color: #6e7882;
      padding: 5px 5px 5px 3px;
      align-items: center;
      .analysis-progress {
        font-size: 14px;
        font-weight: 400;
      }
      .ant-progress-status-success .ant-progress-bg {
        background-color: #00d4d8;
      }
      .ant-progress-line {
        /*width: 100px;*/
        width: 150px;
        margin-left: 20px;
      }
      .ant-progress-inner {
        border: 1px solid #6e7882;
        margin-left: 5px;
        width: 100px;
        height: 10px;
      }
      .ant-progress-bg {
        background-color: #00d4d8;
      }
      .ant-progress-outer {
        padding-right: 0px;
        margin-right:0;
        width: 110px;
        float: left;
      }
      .ant-progress-text {
        float: left;
        color: #6e7882;
        padding-top: 4px;
        padding-right: 5px;
        font-size: 14px;
        font-weight: 400;
        .anticon {
          font-size: 14px;
        }
      }
    }
  }
  .close-header-bar {
    font-size: 20px;
    //padding-right: 7px;
    //padding-top: 9px;
    margin-top: 9px;
    margin-right: 5px;
    cursor: pointer;
    float:right;
    &:hover {
      color: var(--subNavigationBodyFontHover);
    }
  }
  .navigation-one-inner-width {
    display: inline-flex;
    top: 19px;
    .options-small-active {
      color: #EDA41E;
    }
    button {
      color: #FFFFFF !important;
      padding: 0 !important;
      background-color: transparent !important;
      border: none !important;
      font-size: 20px;
      line-height: 22px;
      outline: none;
      margin-top: -15px;
      margin-left: 20px;
      &:focus {
        box-shadow: none;
      }
    }
    .dropdown-item {
      color: #6e7882;
      letter-spacing: 0.2px;
    }
    .dropdown a:hover {
      background-color: #9AE0FC;
      color: #6e7882;
    }
    //.dropdown-menu {
    //  top: 0px !important;
    //  left: -59px !important;
    //  width: 200px;
    //  border: 1px solid #CCCCCC !important;
    //  box-shadow: none;
    //}
    .dropdown-menu {
      top: 0px !important;
      left: -40px!important;
      border: 1px solid #CCCCCC !important;
      box-shadow: none;
      color: #000000;
    }
    .dropdown .dropdown-menu {
      background-color: #FFFFFF;
      box-shadow: none;
      position: absolute;
      top: 3px !important;
      left: -40px !important;
      width: 180px;
      border: 1px solid #6e7882;
    }
  }
}

@media screen and (max-width: 1600px) {
  .header-navigation-contain__content-analysis-progress {
    margin-right: 20px;

    .progress-analysis {
      width: 300px;

      .ant-progress-line {
        margin-left:0;
      }
    }
  }
}