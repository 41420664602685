.manageUsers-page .modal--content {
  padding: 30px;
}
.manageUsers-page .modal-title {
  font-size: 24px;
  font-weight: 300;
  width: 94%;
}
.manageUsers-page .modal-title b {
  font-weight: 500;
}
.manageUsers-page .modal--content header:not(.no-brd-bot) {
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}
.manageUsers-page .modal--content input[type="text"] {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 40px;
  padding: 10px;
  width: 100%;
  margin: 30px 0;
}
.teamUsers__form table th {
  color: #6E7882;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.teamUsers__form table td, .teamUsers__form table th {
  border-bottom: 1px solid lightgray;
  padding: 15px 0;
}

.teamUsers__form table tr:last-child td {
  border-bottom: none;
}

.teamUsers__form #user-footer {
    padding-top: 31%;
  }

  #modal-User {
    overflow-y: hidden;
  }

.teamUsers__form .checkbox-area {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  display: inline-block;
  height: 26px;
  position: relative;
  width: 26px;
  margin-top: 0;
  margin-bottom: 0;
}

.teamUsers__form {
  .assign-team-to-user {
    height: 300px;
    max-height: 240px;
    overflow: auto;
  }
  .assign-team-to-user-footer {
    margin-top: 20px;
  }
  .checkboxLabel {
    border-radius: 5px;
    cursor: pointer;
    display: block;
    height: 18px;
    left: 3px;
    position: absolute;
    top: 3px;
    transition: all .5s ease;
    width: 18px;
    z-index: 1;
  }
  // .css-1hwfws3 {
  //   line-height: 20px;
  //   height: 41px;
  // }
  .styleTeamSelect .css-vj8t7z .css-1hwfws3 {
    height: 3rem;
  }
  .styleTeamSelect .css-2o5izw .css-vj8t7z2__control .css-vj8t7z2__control--menu-is-open .css-vj8t7z2__control--is-focused {
    height: 3rem;
  }
  .styleTeamSelect:focus {
    width: 2rem;
  }
  // .css-vj8t7z2 .css-vj8t7z {
  //   height: 20px;
  // }

  .checkboxLabelChecked { 
    @extend .checkboxLabel;
    background-color: #3DD8DB;
  }

}

select#team {
  line-height: 20px;
}
#modalContent {

}
//sjdksjdkjaskj
[class*="fontawesome-"]:before {
  font-family: 'fontawesome', sans-serif;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.select-search-box {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50%,linear-gradient(0deg, rgba(215, 215, 215, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%);
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  height: 43px;
}

  .select-search-box--multiple {
      border-radius: 4px;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
      overflow: hidden;
  }

  .select-search-box::after {
      font-family: fontawesome;
      content:"\f078";
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      text-align: center;
      line-height: 50px;
      color: #222f3e;
      z-index: 1;
  }

  .select-search-box--multiple::after, .select-search-box--focus::after {
      content:"\f002";
  }
  
  .select-search-box__icon--disabled::after {
      content: none !important;
  }

  .select-search-box--input::after {
      display: none !important;
  }

  .select-search-box__out {
      display: none;
  }

  .select-search-box__search {
      display: block;
      width: 100%;
      height: 41px;
      border: none;
      background: none;
      outline: none;
      font-size: 16px;
      padding: 0 20px;
      color: #222f3e;
      -webkit-appearance: none;
      box-sizing: border-box;
      position: relative;
      z-index: 2;
      cursor: pointer;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 50px;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  }

  input.select-search-box__search {
      line-height: 1;
  }

  .select-search-box--multiple .select-search-box__search {
      box-shadow: none;
  }

  .select-search-box--input .select-search-box__search {
      cursor: text;
  }

      .select-search-box__search:focus {
          cursor: text;
      }

      .select-search-box__search--placeholder {
          //font-style: italic;
          font-weight: normal;
      }

  .select-search-box input::-webkit-input-placeholder {
      color: #000;
      //font-style: italic;
      font-weight: normal;
      font-size: 16px;
  }

  .select-search-box input::-moz-placeholder {
      color: #ccc;
      //font-style: italic;
      font-weight: normal;
  }

  .select-search-box input:-moz-placeholder {
      color: #ccc;
      //font-style: italic;
      font-weight: normal;
      font-size: 16px;
  }

  .select-search-box input:-ms-input-placeholder {
      color: #ccc;
      //font-style: italic;
      font-size: 16px;
      font-weight: normal;
  }

  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
  }

  .select-search-box__select {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: #fff;
      border-radius: 4px;
      overflow: auto;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
      z-index: 100;
      min-height: 49px;
  }

  .select-search-box--multiple .select-search-box__select {
      display: block;
      position: static;
      border-top: 1px solid #eee;
      border-radius: 0;
      box-shadow: none;
  }

      .select-search-box__select--display {
          display: block;
      }

  .select-search-box__option {
      font-size: 13px;
      font-weight: 400;
      color: #616b74;
      padding: 11px 11px;
      border-top: 1px solid #eee;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      user-select: none;
  }

      .select-search-box__option:first-child {
          border-top: none;
      }

      .select-search-box__option--hover, .select-search-box__option:hover {
          background: #f4f7fa;
      }

      .select-search-box__option--selected {
          background: #54A0FF;
          color: #fff;
          border-top-color: #2184ff;
      }

          .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
              background: #2184ff;
              color: #fff;
              border-top-color: #2184ff;
          }

      .select-search-box__group {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #eee;
          position: relative;
      }

      .select-search-box__group-header {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          padding: 0 10px;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
      }
      ul.select-search-box__options {
        max-height: 188px;
    }

.modalUser {
  left: 37%;
}

.modal-containerUser .dpiaNew-formGroupSideBySide {
  width: 100%;
}

.modal-containerUser .select-search-box::after {
  line-height: 42px;
}

.teamUsers__form #user-footer {
  padding-bottom: 0;
}

.user-team-radioGroup {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  .dpiaNew__radio-info {
    display: inline-block;
    margin-left: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .modal-containerUser {
    width: 30%;
  }
}

@media screen and (max-width: 1200px) {
  .modal-containerUser {
    width: 40%;
    overflow: auto;
    top:15%;
  }
  .modalUser {
    left: 25%;
  }
}
@media screen and (min-width: 1200px) {
  .modal-containerUser {
    //height: 60vh;
    //overflow: auto;
  }
}

@media screen and (max-width: 900px) {
  .modal-containerUser {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .modal-containerUser {
    width: 50%;
    top: 15%;
  }
  .manageUsers-page .modal--content {
    padding: 3px;
  }
}

@media screen and (min-width: 2000px) {
  .modal-containerUser {
    width: 30%;
    //height: auto;
    max-height: 100%;
  }
}

@media screen and (min-height: 400px) {
  .modal-containerUser {
    //height: auto;
    max-height: 100%;
  }
}
@media screen and (max-width: 1705px) {
  .modal-container {
    height: 80vh;
    left: 42%;
    width: 60%;
    top: 44%;
  }
}