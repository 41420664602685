.collectionItem-wrapper {
  border-bottom: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  padding: 20px;
  width: 100%;
}

.collectionItem-title {
  color: #162737;
  font-size: 18px;
  margin-bottom: 10px;
}

.collectionItem__loading {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.collectionItem-text {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.collectionItem-high {
  color: #162737;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 25px;
  position: relative;
}

.collectionItem-high::before {
  background-color: #F44336;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 15px;
}

.collectionItem-medium {
  color: #162737;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 25px;
  position: relative;
}

.collectionItem-medium::before {
  background-color: #F0B400;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 15px;
}

.collectionItem-low {
  color: #162737;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-left: 25px;
  position: relative;
}

.collectionItem-low::before {
  background-color: #92C654;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 15px;
}

.collectionItem-total {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
}

.collectionItem-total strong {
  margin-right: 5px;
}

.collectionItem-link {
  color: #1DCBCF;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
}

.collectionItem-link::after {
  border-color: transparent transparent transparent #1DCBCF;
  border-style: solid;
  border-width: 3px 0 3px 6px;
  content: '';
  height: 0;
  position: absolute;
  right: -12px;
  top: 5px;
  width: 0;
}