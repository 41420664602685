.backofficeProcesses__wrapper {
    margin: 0 auto;
    padding: 40px 40px 0;
  }
  
  .backofficeProcesses__box {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    margin-bottom: 20px;
    position: relative;
  }
  
  .backofficeProcesses__boxHeader {
    align-items: center;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }
  
  .backofficeProcesses__boxTitle {
    border-bottom: 1px solid #162737;
    color: #162737;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .backofficeProcesses__boxTitleTotal {
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    padding-left: 10px;
    position: relative;
  }

  .backofficeProcesses__boxText {
    font-size: 18px;
  }