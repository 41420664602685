.post-title {
  color: #3DD8DB;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px
}

.post-date {
  color: #737d87;
  font-size: 14px
}

.post-link {
  display: block;
  padding: 20px;
  transition: all .5s;
}

.post-link:hover {
  background-color: #F5F5F5;
}