.file-folderList-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .file-folderList-wrapper {
    flex-direction: row;
  }

  .file-folderList-wrapper .fileItem-wrapper {
    border-bottom: none;
    width: 33.333%;
  }
}