.privacyAlertItem__header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  min-height: 70px;
  position: relative;
}

.privacyAlertItem__headerOpened {
  background-color: #FFFFFF;
  border-bottom: 2px solid #D6D3D3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  min-height: 70px;
  position: relative;
}

.privacyAlertItem__content {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #DCDCDC;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 57px;
}

.privacyAlertItem__headerOpened .privacyAlertItem__content {
  border-bottom-left-radius: 0;
}

.privacyAlertItem__contentHigh {
  border-left: 7px solid #F44336;
}

.privacyAlertItem__contentMedium {
  border-left: 7px solid #F0B400;
}

.privacyAlertItem__contentLow {
  border-left: 7px solid #92C654;
}

.privacyAlertItem__loading {
  display: flex;
  justify-content: center;
  min-width: 100px;
}

.privacyAlertItem__info {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  min-width: 100px;
}

.privacyAlertItem__info strong {
  font-size: 16px;
}

.privacyAlertItem__contentTitle {
  color: #6E7882;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.privacyAlertItem__contentText {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.privacyAlertItem__contentSmallItem {
  border-bottom: 1px solid #DCDCDC;
  padding: 10px 20px;
}

.privacyAlertItem__contentBigItem {
  border-bottom: 1px solid #DCDCDC;
  flex: 1;
  padding: 10px 20px;
}

.privacyAlertItem__toggleHeader {
  cursor: pointer;
  margin-top: -6px;
  position: absolute;
  right: 43px;
  top: 50%;
  transition: all .2s;
}

.privacyAlertItem__toggleHeader:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 15px solid #B0B0B0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.privacyAlertItem__toggleHeader:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 0;
  border-top: 13px solid #FFFFFF;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}

.privacyAlertItem__toggleHeaderOpened {
  cursor: pointer;
  margin-top: 6px;
  position: absolute;
  right: 13px;
  top: 50%;
  transition: all .2s;
  transform: rotate(180deg);
}

.privacyAlertItem__toggleHeaderOpened:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 15px solid #B0B0B0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.privacyAlertItem__toggleHeaderOpened:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 0;
  border-top: 13px solid #fff;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}

.privacyAlertItem__button {
  align-self: flex-start;
  background: url('../../../assets/icon-view-green.png') no-repeat 5px center #FFFFFF;
  color: #1DCBCF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  padding: 20px 20px 20px 30px;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .privacyAlertItem__content {
    align-items: center;
    flex-direction: row;
    padding: 15px 5px;
  }

  .privacyAlertItem__contentSmallItem {
    border-bottom: none;
    border-right: 1px solid #DCDCDC;
    padding: 5px 20px;
  }

  .privacyAlertItem__contentBigItem {
    border-bottom: none;
    padding: 5px 20px;
  }

  .privacyAlertItem__button {
    align-self: center;
    margin-left: auto;
    padding: 10px 10px 10px 30px;
  }
}