.privacyFileContent__wrapper {
  background-color: #FFFFFF;
  margin-bottom: 20px;
  position: relative;
}

.privacyFileContent__loading {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  height: 70px;
  justify-content: center;
  margin-bottom: 20px;
}

.privacyFileContent-list {
  border-bottom: 1px solid #D6D3D3;
}

.privacyFileContent__noFiles {
  padding: 15px;
  text-align: center;
}

.privacyFileContent-fileItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  padding: 5px;
}

.privacyFileContent-fileItem:nth-child(even) {
  background-color: #F9F9FA;
}

.privacyFileContent__info--modified {
  color: #111C26;
  font-size: 14px;
  padding: 5px 20px 5px 27px;
  width: 140px;
}

.privacyFileContent__info--dir {
  color: #111C26;
  font-size: 14px;
}

.privacyFileContent__info--view {
  background: url('../../../assets/icon-view-green.png') 5px center no-repeat;
  color: #1DCBCF;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 700;
  margin-top: 20px;
  padding: 5px 0 5px 30px;
  text-transform: uppercase;
}

.privacyFileContent__fileItem--wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px 5px 20px;
}

.privacyFileContent__action {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15px;
}

@media screen and (min-width: 768px) {
  .privacyFileContent-fileItem {
    align-items: center;
    flex-direction: row;
    line-height: initial;
  }

  .privacyFileContent__fileItem--wrapper {
    flex-direction: row;
  }

  .privacyFileContent__info--view {
    margin-top: 0;
  }
}