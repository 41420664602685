.button_big_green--custom {
  background-color: white;
  border: 1px solid #268a3d;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #268a3d;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 0.9em;
  padding: 15px 30px;
  text-transform: uppercase;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin: 16px 4px;

  &:hover {
    background-color: #268a3d;
    color: white;
  }
}
.upload-container {
  display: flex;
  justify-content: space-between;
}
.annotation-table {
  table {
    border-radius: 0 !important;
  }
  .ant-input:hover {
    border-color: #EDA41E;
    box-shadow: none;
  }
  .ant-input:active {
    border-color: #EDA41E;
    box-shadow: none;
  }
  .ant-input:focus {
    border-color: #EDA41E;
    box-shadow: none;
  }
  .ant-table-body {
    border-radius: 0;
    overflow: hidden;
    font-size: 16px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    font-family: 'Roboto';
    font-weight: 400;

    .ant-table-thead {
      tr {
        background: white;

        th {
          text-transform: uppercase;
          font-family: 'Roboto';
          color: #7f7f7f;
          font-size: 14px;
          font-weight: 400;
        }
      }

      tr:first-child > th:last-child {
        border-radius: 0 !important;
      }

      tr:first-child > th:first-child {
        border-radius: 0 !important;
      }

    }


    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background: white;
    }

    .ant-table-tbody {
      background-color: white;

      tr {
        td {
          border-bottom: 1px solid #d6d3d3;
        }
      }
    }

  }
   .icon-v21-edit {
     font-size: 20px;
     color: #382e34;
   }
  .icon-v21-edit-active {
    font-size: 20px;
    color: var(--generalIconFontHover);
    cursor: pointer;
  }
  .edit-button {
    color: #3dd8db;
    text-decoration: underline;
    font-size: 15px;

    &:hover {
      color: #196164;
    }

    &:disabled {
      border: none;
    }
  }
   .content-edit-annotate {
     display: flex;
     flex-direction: column;
     .icon-v19-delete {
       font-size: 20px;
       color: #382e34;
       width: 20px;
       height: 20px;
     }
     .icon-v19-delete-active {
       font-size: 20px;
       color: var(--generalIconFontHover);
       cursor:pointer;
       width: 20px;
       height: 20px;
     }
   }
  .save-button {
    color: #382e34;
    text-decoration: underline;
    font-size: 15px;

    &:hover {
      color: #EDA41E;
      cursor: pointer;
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      cursor: default;
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        cursor: default;
      }
    }

  }

  .cancel-button {
    color: #db170d;
    text-decoration: underline;
    font-size: 15px;

    &:hover {
      color: rgba(250, 20, 12, 0.4);
      cursor: pointer;
    }

  }
}

.annotation-pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background: white;
  padding: 20px !important;
  box-shadow: 0 2px 4px 0 #DCDCDC;

  .ant-pagination-total-text {
    line-height: 21px;
  }

  .ant-pagination-item {
    border: 0;
    min-width: auto;
    height: auto;

    a {
      border-radius: 5px;
      color: #162737 !important;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      padding: 1px 10px;

      &:hover {
        background-color: var(--generalIconFontHover);
        color: #FFFFFF !important;
      }
    }
  }

  .ant-pagination-item-active {
    border: 0;
    min-width: auto;
    height: auto;

    a {
      background-color: var(--generalIconFontHover);
      border-radius: 5px;
      color: #FFFFFF !important;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      padding: 1px 10px;

      &:hover {
        background-color: var(--generalIconFontHover);
        color: #FFFFFF !important;
      }
    }
  }

  .ant-pagination-prev:focus {
    background-color: #EDA41E !important;
    .ant-pagination-item-link {
      border-color: #EDA41E !important;
      color: #EDA41E !important;
    }
  }
  .ant-pagination-next:focus {
    background-color: #EDA41E !important;
    .ant-pagination-item-link {
      border-color: #EDA41E !important;
      color: #EDA41E !important;
    }
  }
  .ant-pagination-prev, .ant-pagination-next {
    min-width: auto;
    height: auto;

    .ant-pagination-item-link {
      border: 1px solid #D2D3D4;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      color: #162737;
      cursor: pointer;
      font-weight: 700;
      margin: 0;
      padding: 3px 11px;
      min-width: auto;
      height: auto;
      font-size: 18px;

      &:hover {
        background-color: #D4D7DA;
        color: #FFFFFF;
        border: 1px solid #D2D3D4;
      }
    }
  }

  .ant-pagination-disabled {
    min-width: auto;
    height: auto;

    .ant-pagination-item-link {
      border: 1px solid #D2D3D4;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      color: #D4D7DA;
      margin: 0;
      padding: 3px 11px;
      min-width: auto;
      height: auto;
      font-size: 18px;

      &:hover {
        background-color: white;
        color: #D4D7DA;
      }
    }
  }


  .total-elements {
    display: flex;
    flex-direction: column;

    .pagination__box__header__total {
    }

    .pagination__box__header__text {
      margin-top: 4px;
    }
  }

  li:first-child {
    width: 100%;
    display: flex;
    align-self: start;
    height: auto;
  }

}

.color_input {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: flex;
  cursor: pointer;
  max-width: 44px;
  &__value {
    width: 100%;
    height: 14px;
    border-radius: 2px;
    background:#fff;
  }
}
.color_picker_popup {
  position: absolute;
  z-index: 2;
  right: 16px;
  &__cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.annotation-color {
  .ant-table-content {
    .ant-table-body {
      background-color: white;
      table {
        margin-bottom: 60px;
      }
    }
  }

  .page-content-annotation-color {
    width: 100%;
    margin: 0 auto;
    .ant-table-row {
      vertical-align: middle;
    }
  }

  .ant-spin-container {
    overflow-y: auto;
  }

  .page-add {
    background-color: #fff;
    &__header {
      font-weight: bold;
      font-size: 14px;
      padding-top: 1px;
      height: 50px;
      background-color: #fafafa;
    }
    &__form {
      height: 55px;
      &__category {
        display: flex;
        align-items: center;
        height: 100%;
        &__input {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          font-variant: tabular-nums;
          list-style: none;
          -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
          position: relative;
          display: inline-block;
          width: 100%;
          height: 32px;
          padding: 4px 11px;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          line-height: 1.5;
          background-color: #fff;
          background-image: none;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
      &__color {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
      }
      &__button {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-end;

        &__new {
          background-color: #ffffff;
          border: 1px solid #3DD8DB;
          border-radius: 30px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          color: #3DD8DB;
          cursor: pointer;
          font-family: 'Roboto';
          font-weight: 700;
          font-size: 14px;
          padding: 8px 15px;
          text-transform: uppercase;
          -webkit-transition: all .2s;
          transition: all .2s;

          &:hover {
            background-color: #3DD8DB;
            color: #ffffff
          }
        }
      }
    }
  }
}
 .page-container-annotation {
   //padding: 134px 100px 0;
   margin: 134px 82px;
   padding:0px;
   .page-filter {
     &__header {
       margin-top: 0px;
       &--button-container {
         display: flex;
         align-items: center;
         .button-newBusiness {
           box-shadow: none;
           margin: 0px;
         }
         .icon-v21-add {
          font-size: 16px;
           color: var(--generalButton);
           margin-left: 25px;
         }
         .icon-v21-add-active {
           font-size: 16px;
           color: var(--generalIconFontHover);
           margin-left: 25px;
           cursor: pointer;
         }
         .icon-v21-upload-active-1 {
           font-size: 16px;
           color: var(--generalIconFontHover);
           margin-left: 25px;
         }
         .icon-v21-upload-1 {
           font-size: 16px;
           color: #382e34;
           margin-left: 25px;
         }
         .icon-v21-download-1 {
           font-size: 16px;
           color: #382e34;
           margin-left: 25px;
         }
         .icon-v21-download-active-1 {
           font-size: 16px;
           color: var(--generalIconFontHover);
           margin-left: 25px;
           cursor: pointer;
         }
         }

           &--filter-container {
             margin-bottom: 30px;
             #questionnaireFilter__teams .ant-select-selection {
               border-radius: 0px;
               font-size: 14px;
               height: 30px;
               padding: 5px 20px 5px 10px;
               border: 1px solid #382e34;
               font-weight: 400;
               box-shadow: none;
             }
           }
       }
     }
 }

.annotation-header {
  padding: 30px 0 40px;
  font-family: Roboto;
  display: flex;
  align-items: center;
  .icon-v14-question {
    margin-left: 10px;
    font-size: 20px;
    color: #382e34;
  }
  h1{
    font-size: 20px;
    font-weight: 700;
    color: #382e34;
    line-height: 20px;
  }
  h3 {
    margin-top: 10px;
  }
}

.select-team-annotation {
  border: 1px solid #EDA41E !important;
  color: #382e34 !important;
  line-height: 30px !important;
  box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3) !important;
  .ant-select-dropdown-menu {
    padding: 0;
  }
  .ant-select-dropdown-menu-item-selected {
    color: #382e34;
    font-weight: 700;
    background-color: #FFFFFF;
  }
  .ant-select-dropdown-menu-item {
    padding: 0px 10px;
    line-height: 30px;
    color: #382e34;
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #9AE0fc50 !important;
  }
  @media (max-width: 1440px) and (min-width: 1200px) {
    .ant-select-dropdown-menu-item {
      font-size: 14px;
      line-height: 30px !important;
    }
  }
}