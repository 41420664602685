.assert-register-page {
  margin: 0 0.5%;
  padding-left: 20px;
  padding-right: 20px;

  &__action-container {
    position: relative;
    padding: 20px;
    margin-top: 60px;

    .custom-asset {
      border: solid rgba(110, 120, 130, 0.3);
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 8px;
      position: absolute;
      bottom: -8.4px;
      background: white;
      transform: rotate(-135deg);
      z-index: 10;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      background-color: #FFFFFF;
      position: relative;
      padding: 20px;
      border-radius: 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

      .title-Asset {
        cursor: pointer;
      }

      .title {
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-left: 16px;
        margin-top: 8px;
        width: 138px;
        height: 21px;
        color: #000000;
      }
      .actions {
        display: contents;
        justify-content: space-between;
        padding: 0 20px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        cursor: pointer;

        .add-asset {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 50px 10px 0;
          .icon-add-b {
            font-size: 32px;
            padding-bottom: 15px;
            cursor: pointer;
          }
          .title-plus-circle {
            cursor: pointer;
            color: #6e7882;
            width: 72px;
            height: 21px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }

        .find-asset {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 50px 10px 0;
          .icon-find-asset-b {
            font-size: 32px;
            padding-bottom: 15px;
            cursor: pointer;
          }
          .title-find-asset {
            cursor: pointer;
            color: #6e7882;
            width: 75px;
            height: 21px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }

        .access-asset {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 50px 10px 0;
          .icon-access-asset-b {
            font-size: 32px;
            padding-bottom: 15px;
            cursor: pointer;
          }
          .title-access-asset {
            cursor: pointer;
            color: #6e7882;
            width: 100px;
            height: 21px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }

        .delete-asset {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 50px 10px 0;
          .title-delete-asset {
            cursor: pointer;
            color: #6e7882;
            width: 100px;
            height: 21px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
          }
          .icon-delete-b {
            font-size: 32px;
            padding-bottom: 15px;
            cursor: pointer;
          }
        }

      }
    }
    &__add-asset {
      width: 100%;
      .add-asset-page {
        width: 100%;
        border-top: 1px solid #E6E6E6;
        background-color: #FFFFFF;
        position: relative;
        padding-top: 20px;
        min-height: 170px;
        height: auto;
        .ant-calendar-picker-icon {
          right: 20px;
        }

        .contain-button {
          margin-top: 60px;
          display: inline;
          .disabledSpan {
            color: #b4babf;
            background-color: #e3e5e7;
            border-color: #b4babf;
            cursor: not-allowed;
            margin-right: 20px;
          }

          .button-blue {
            cursor: pointer;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            padding: 10px 30px;
            text-transform: uppercase;
            border-radius: 25px;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            float: right;
            width: auto;
          }
        }
        .add-asset-content {
          width: 100%;
          display: flex;
        }
        .add-asset-content-r {
          width: 100%;
          display: block;
          .asset-type {
            margin-bottom: 20px;
          }
        }

        .asset-type {
          width: 100%;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 15px;
        }
        .ant-select {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          font-variant: tabular-nums;
          line-height: 1.5;
          list-style: none;
          -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
          position: relative;
          display: inline-block;
          outline: 0;
          width: 50%;
        }

        .ant-select-selection {
          width: 100%;
          display: block;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          background-color: #fff;
          border: none;
          border-top-width: 1.02px;
          border-radius: 0;
          outline: none;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          border-bottom: 1px solid #00d3d8;

        }

        .ant-select-selection__placeholder {
          width: 300px;
          height: 21px;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
        }

        .anticon {
          color: #6e7882;
          width: 4px;
          height: 4px;
        }

        .add-asset-title {
          width: 118px;
          height: 21px;
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
          margin-right: 120px;
          margin-top: 11px;
        }
        .ant-select-selection__rendered {
          position: relative;
          display: block;
          margin-right: 11px;
          margin-left: 11px;
          line-height: 32px;
        }

        .ant-input {
          top: 30px;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #00d3d8;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
          width: 96%;
          position: initial;
        }
        .ant-input:focus {
          outline: none;
          box-shadow: none;
        }
        .select-search {
          width: 29%;
          margin-left: 40px;
        }
        .input-search {
          width: 100%;
        }

        .asset-contain-button {
          margin-top: 30px;
          .button-blue-light {
            box-shadow: none;
            cursor: pointer;
            font-family: Roboto;
            font-size: 20px;
            padding: 10px 30px;
            text-transform: uppercase;
            margin-right: 40px;
            opacity: 0.4;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #6e7882;
            background-color: #f2f2f2;
            width: 128px;
            height: 50px;
            border-radius: 25px;
            border: solid 1px rgba(110, 120, 130, 0.4);
            background-color: rgba(110, 120, 130, 0.2);
          }

          .button-blue-light:hover {
            background-color: #3DD8DB;
            color: #FFFFFF;
          }
        }
        .search-button-asset {
          margin-top: 30px;
          .button-blue-light {
            border: 1px solid #3DD8DB;
            background-color: #FFFFFF;
            color: #3DD8DB;
            width: auto;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            font-family: Roboto;
            font-weight: 900;
            font-size: 20px;
            height: 50px;
            padding: 10px 30px;
            text-transform: uppercase;
            margin-top: 12px;
            margin-right: 50px;
            margin-bottom: 20px;
          }

          .button-disabled-light {
            border: 1px solid #b4babf;
            background-color: #e3e5e7;
            color: #b4babf;
            width: auto;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: not-allowed;
            font-family: Roboto;
            font-weight: 900;
            font-size: 20px;
            height: 50px;
            padding: 10px 30px;
            text-transform: uppercase;
            border-radius: 30px;
            margin-top: 12px;
            margin-right: 50px;
            margin-bottom: 20px;
          }

          .button-blue-light:hover {
            background-color: #3DD8DB;
            color: #FFFFFF;
          }
        }
        .results-number {
          margin-top: 110px;
          .view-result {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

.accordion-table-register {
  position: relative;
  border-radius: 3px;

  .style-accordion-table {
    width: 100%;

    .unlocked-register-wrapper {
      .accordion-wrapper {
        margin-bottom: 0;
      }
      .unlocked-register-header {
        align-items: flex-start;
        background-color: #FFFFFF;
        border-bottom: 1px solid #CCCCCC;
        display: flex;
        flex-direction: column;
        min-height: 70px;
        position: relative;

        .register-info-container {
          -webkit-align-items: center;
          align-items: center;
          border-right: 1px solid #DCDCDC;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          margin: 0 70px 0 15px;
          min-height: 70px;
          padding-right: 20px;
          width: 94%;
          cursor: pointer;

          .register-image {
            font-size: 13px;
            font-family: 'Ubuntu';
            font-weight: 300;
            line-height: 18px;
            width: 100%;
            margin-right: 10px;
          }

          .dates-table {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          }
        }
      }
    }
  }

}

.list-info-register {
  padding: 0;
  position: relative;
  margin: 0;
  &__columns {
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 1px solid #D6D3D3;
    font-size: 14px;
    -webkit-justify-content: space-between;
    padding: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    .ant-form-item-label > label {
      font-size: 1em;
      font-weight: 300;
      color: #6e7882
    }

    .column {
      margin-top: 20px;
      margin-bottom: 20px;
      flex: 0 0 20%;
      max-width: 20%;
      padding: 20px 20px 20px 20px;
      .type-number {
        height: 32px;
        width: 100%;
        border: 1px solid #3dd8d8;
        border-radius: 0;
        &:focus {
          box-shadow: none;
          outline: none;
          outline-offset: unset;
        }
      }
      .type-textarea {
        width: 100%;
        height: 120px;
        border-radius: 0;
        border-color: #3DD8DB;
        box-shadow: none;
        background-color: white;
        resize: vertical;
        line-height: 1;
        font-size: 1em;
        &:focus {
          box-shadow: none;
          outline: none;
          outline-offset: unset;
        }
      }
      .type-dropdown {
        .ant-select-selection {
          border: none;
          border-bottom: 1px solid #3DD8DB;
          border-radius: 0;
        }
      }
      .fileContainer {
        .chooseFileButton {
          height: 30px;
        }
        .asset-icon {
          border: none;
          border-bottom: 1px solid #3dd8d8;
          border-radius: unset;
          color: #aaaaaa;
          padding: 1px 0 5px 0;
          margin: 0;
          min-width: 170px;
          text-align: left;
          &:hover {
            background-color: #ffffff;
          }
        }
      }
      .location-group {
        display: grid;
      }
      .date-format-group {
        display: grid;
      }
      .team-group {
        display: grid;
      }
      .ant-radio-wrapper {
        margin-bottom: 5px;
        span {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 300;
          &::after {
            background-color: #3DD8DB;
          }
        }
      }
      .ant-checkbox-group-item {
        margin-bottom: 5px;
        span {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 300;
        }
      }
      &__title {
        ont-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        color: #6e7882;
        margin-bottom: 30px;
      }
      .currency-group {
        display: grid;
        .ant-checkbox-group-item {
          margin-bottom: 5px;
          .ant-checkbox-checked {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            span {
              background-color: #00d3d8;
              border-color: #00d3d8;
            }
          }
        }
      }
    }
    .column-100 {
      margin-left: 0;
      margin-right: 0;
      padding: 20px 20px 20px 20px;
      width: 100%;
      .map-container {
        height: 550px;
        width: 100%;
        margin: 0 auto;
      }
      &__title {
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        color: #6e7882;
        margin-bottom: 30px;
      }
      #google-map {
        margin-top: 20px;
      }
    }
    .type-map {
      position: relative;
      width: 100%;
      &__input {
        height: 30px;
        width: 100%;
        border: 1px solid #3dd8d8;
      }
      &__icon {
        position: absolute;
        right: 10px;
        font-size: 20px;
        color: #3dd8d8;
      }
    }
  }
  .additional {
    border-top: 1px solid #ccc;
    .custom-asset {
      padding: 9px;
      bottom: 149.6px;
      transform: rotate(45deg);
      margin-left: 732px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;
    .asset-register-loading {
      padding-right: 64px;
    }
    .button-blue {
      cursor: pointer;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      padding: 10px 30px;
      text-transform: uppercase;
      color: #ffffff;
      border-radius: 25px;
      border: solid 1px #1DCBCF;
      background-color: #1DCBCF;
      float: right;
      width: auto;
      &:hover {
        color: #1DCBCF;
        background-color: #ffffff;
      }
    }
    .button-blue-light {
      cursor: pointer;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      padding: 10px 30px;
      text-transform: uppercase;
      color: #1DCBCF;
      border-radius: 25px;
      border: solid 1px #1DCBCF;
      background-color: #ffffff;
      float: right;
      &:hover {
        color: #FFFFFF;
        background-color: #1DCBCF;
      }
    }
  }
}

.title-accordion {
  margin-top: 40px;
  height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e7882;
}

.accordion-header-titles {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .asset-header {
    margin-left: 17px;
    width: 19%;
  }

  .location-header {
    width: 15%;
  }

  .issue-header {
    width: 19%;
    margin-left: 70px;
  }

  .owner-header {
    width: 20%;
  }

  .number-header {
    width: 26%;
  }
}

.display-tables-page {
  margin-top: 20px;

  .content-title-of-table {
    width: 183px;
    height: 36px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    .title-of-table {
      height: 26px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      text-transform: capitalize;
      padding-top: 2px;
      padding-left: 6px;
      padding-right: 12px;
      border-top-right-radius: 15px;
    }
  }
  .register-table {
    border-collapse: collapse;
    width: 100%;

    .border-table {
      width: 982px;
      height: 50px;
      border-radius: 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      display: contents;
      align-content: center;

      .register-table-title {
        height: 21px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6e7882;
        align-content: center;
        padding-top: 17px;
        padding-left: 30px;
        padding-bottom: 17px;
        background-color: #e6e6e6;

      }

    }
    .table-content {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      color: #000000;
      border-bottom: solid 1px #6e7882;
      background-color: #FFFFFF;
      position: relative;

      .span-item {
        padding: 25px 30px;
        display: block;
      }

      .image-item {
        position: absolute;
        top: 20%;
        left: 5%
      }
    }
    .table-icon {
      width: 80px;
      border-left: 1px solid #DCDCDC;
      height: 25px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      border-bottom: solid 1px #6e7882;
      background-color: #FFFFFF;
      .fa {
        display: flex;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        cursor: pointer;
      }

      .fa-angle-down {
        height: 100%;
        opacity: 0.4;
        font-size: 50px;
        color: #6e7882;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .fa-angle-up {
        height: 100%;
        opacity: 0.4;
        font-size: 50px;
        color: #6e7882;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .icon-delete-b {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: black;
      }

      .icon-edit-b {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        color: black;
      }
      .icon-edit-b:before {
        content: '\e802';
      }
      .icon-edit-color {
        color: #000000;
      }
    }

    .collapse-row {
      width: 100%;
      .hidden-row {
        padding: 0 4px;
        font-size: 13px;
        .content-collapse {
          width: 100%;
          margin-bottom: 20px;
          background-color: #FFFFFF;
          .spinner-content {
            text-align: center;
            padding-top: 40px;
            padding-bottom: 40px;
          }
        }
      }
    }
    .expand-edit {
      width: 100%;
      .hidden-edit {
        padding: 0 4px;
        font-size: 13px;
        .content-expand-edit {
          width: 100%;
          margin-bottom: 20px;
          background-color: #FFFFFF;
        }
      }
    }
  }
}
.ant-select {
  .ant-select-selection {
    .ant-select-selection__rendered {
      margin-left: 5px;
    }
    .ant-select-arrow {
      margin-right: 10px;
    }
    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &__rendered {
      &:focus {
        outline: none;
      }
    }
  }

}

.asset-table-page {
  width: 100%;
  .ant-table-tbody > tr > td {
    background-color: #ffffff;
    width: 36px;
    height: 25px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding: 25px 17px;
    border-bottom: solid 1px #6e7882;
    background-color: #FFFFFF;
  }

  .ant-table-thead > tr > th {
    height: 21px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e7882;
    align-content: center;
    padding-top: 17px;
    background-color: #e6e6e6;
  }
}
.ar-page-content-table {
  display: flex;
  flex-flow: column nowrap;
  font-size: .8rem;
  flex: 1 1 auto;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background: white;
  .table-header {
    height: 50px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    background-color: rgb(230, 230, 230);
    height: 50px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #6e7882;
    display: flex !important;
    .td {
      border-bottom: none;
      cursor: pointer;
      &:first-child {
        flex-grow: 2;
        justify-content: flex-start !important;
      }
    }
    .title-icon {
      width: 51px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6e7882;
      align-content: center;
      padding-top: 17px;
      padding-bottom: 17px;
      background-color: #e6e6e6;
      .content-icons {
        width: 100%;
        display: flex;
        justify-content: center;

        .content-icons-visible-table {
          cursor: pointer;
          .fa-caret-down {
            font-size: 30px;
          }
          .fa-caret-up {
            font-size: 30px;
          }
        }
        .icon-delete-b {
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding-right: 10px;
          color: #000000;
        }

        .icon-edit-b {
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding-right: 20px;
          color: #000000;
        }
      }
    }

  }
  .table-visible {
    display: none;
  }

  .table-row {
    border-bottom: 1px solid #d0d0d0;
    font-size: 14px;
    font-weight: 300;
    color: black;
    display: block;
    &__line {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      .ar-image {
        height: 84px;
      }
    }
    &__accordion-container {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
    }
    .td {
      line-height: 34px;
      border-bottom: none;
      padding: 23px 20px 23px 22px;
      .fa {
        display:flex;
        align-items: center;
      }
      &:first-child {
        flex-grow: 2;
        justify-content: flex-start !important;
        padding-top: 2px;
        padding-bottom: 2px;
        width: 170px;
        height: 80px;
      }
      .span-item-document {
        display: flex;
        align-items: center;

        .icon-pages-b {
          font-size: 28px;
          margin-right: 8px;
        }
      }
      .span-item-checkbox {
        display: flex;
        flex-direction: column;
        &__child {
          span[class^="icon-"] {
            font-size: 30px;
            margin-right: 10px;
          }
        }
      }
      .span-item-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        &__with-icon {
          span[class^="icon-"] {
            font-size: 17px;
            height: 17px;
            border-radius: 50%;
            margin-left: 5px;
          }
          .date-status-red {
            background: red;
            color: red;
          }
          .date-status-orange {
            background: #ffb300;
            color: #ffb300;
          }
          .date-status-green {
            background: #38e000;
            color: #38e000;
          }
          .date-status-black {
            background: black;
            color: black;
          }
        }
      }
      span {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
    .td.actions {
      border-left: 1px solid #d0d0d0;
      font-size: 20px;
      color: #6e7882;
      opacity: 1;
      cursor: pointer;
      width: 92px;

      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: black;
      }

      i {
        cursor: pointer;
        font-size: 35px;
        color: #6e7882;
        opacity: 0.4;
        width: 32px;
        height: 15px;
      }
    }
  }
  .th {
    font-weight: 700;
  }
  .th > .td {
    white-space: normal;
    justify-content: flex-start;
    padding: 0 20px;
  }

  .tr {
    width: 100%;
    flex-flow: row nowrap;
    display: block;
  }
  .td {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-basis: 0;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    white-space: nowrap;
    border-bottom: 1px solid #d0d0d0;
  }
}

.empty-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: grey;
  margin-right: 5px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {

  .assert-register-page {

    &__action-container {

      &__header {
     .title {
       font-size: 14px;
       font-weight: bold;
      }
        .title-Asset {
          cursor: pointer;
        }
        .actions {
          .add-asset {
            .title-plus-circle {
              font-size: 14px;
            }
          }
          .find-asset {
            .title-find-asset {
              font-size: 14px;
            }
          }
          .access-asset {
            .title-access-asset {
              font-size: 14px;
            }
          }
          .delete-asset {
            .title-delete-asset {
              font-size: 14px;
            }
          }
        }

      }
      &__add-asset {
        width: 100%;

        .add-asset-page {
          width: 100%;
          border-top: 1px solid #E6E6E6;
          background-color: #FFFFFF;
          position: relative;
          padding-top: 20px;
          min-height: 170px;
          height: auto;
          .ant-calendar-picker-icon {
            right: 66px;
          }
          .ant-input {
            font-size: 12px;
            font-weight: 300;
            font-family: Roboto;
          }
          .add-asset-title {
            font-size: 12px;
            color: #495057
          }
          .ant-select-selection__placeholder {
            font-size: 12px;
            width: 81%;
          }

         .search-button-asset {
           .button-blue-light {
             font-size: 20px;
             //height: 43px;
             font-weight: 300;
             padding: 10px;
             width: 128px;
             height: 55px;
             background-color: #3DD8DB;
             border: 1px solid #3DD8DB;
             border-radius: 20px;
             box-shadow: 0 2px 4px 0 #DCDCDC;
             color: #FFFFFF;
             cursor: pointer;
             font-family: 'Ubuntu', sans-serif;
             text-transform: uppercase;
             -webkit-transition: all .2s;
             transition: all .2s;
           }
           .button-blue-light:hover {
             background-color: #FFFFFF;
             color: #3DD8DB;
           }
           .button-disabled-light {
             font-size: 20px;
             font-weight: 300;
             padding: 10px;
             width: 128px;
             height: 55px
           }
         }
        }
      }
      .ar-page-content-table {

        .table-header {
          height: 50px;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: #6e7882;

          .title-icon {

            .content-icons {
              width: 100%;
              display: flex;
              justify-content: center;

              .content-icons-visible-table {
                cursor: pointer;
                .fa-caret-down {
                  font-size: 20px;
                }
                .fa-caret-up {
                  font-size: 20px;
                }
              }

              .icon-delete-b {
                padding-right: 1px;
              }

              .icon-edit-b {
                padding-right: 12px;
              }
            }
          }
        }

        }
    }
  }
  .list-info-register {
    .additional {
      .custom-asset {
        margin-left: 590px;
      }
    }
    &__columns {
      .column {
        margin-top: 20px;
        margin-bottom: 20px;
        flex: 0 0 20%;
        max-width: 25%;
        padding: 20px 20px 20px 20px;
        .custom-textarea {
          width: 80%;
          height: 40px;
        }
        .ant-form-item-label > label {
          color: #6e7882;
          font-weight: 300;
          font-size: 11px;
          font-family: Roboto;
        }
        .fileContainer {
          .chooseFileButton {
            font-size: 11px;
            font-weight: 300;
            font-family: Roboto;
          }
          .asset-icon {
            padding: 1px 0 5px 0;
          }
        }
        .ant-input {
          font-size: 12px;
          width: 70%;
        }
        .type-number {
          height: 32px;
          width: 100%;
          border: 1px solid #3dd8d8;
          border-radius: 0;
          &:focus {
            box-shadow: none;
            outline: none;
            outline-offset: unset;
          }
        }
        .type-textarea {
          width: 100%;
          height: 120px;
          border-radius: 0;
          border-color: #3DD8DB;
          box-shadow: none;
          background-color: white;
          resize: vertical;
          line-height: 1;
          font-size: 1em;
          &:focus {
            box-shadow: none;
            outline: none;
            outline-offset: unset;
          }
        }
        .type-dropdown {
          .ant-select-selection {
            border: none;
            border-bottom: 1px solid #3DD8DB;
            border-radius: 0;
          }
        }
        .ant-radio-wrapper {
          margin-bottom: 5px;
          span {
            font-family: Roboto;
            font-size: 11px;
            font-weight: 300;
            &::after {
              background-color: #3DD8DB;
            }
          }
        }
        .ant-checkbox-group-item {
          margin-bottom: 5px;
          span {
            font-family: Roboto;
            font-size: 11px;
            font-weight: 300;
          }
        }
        &__title {
          font-size: 11px;
          font-weight: 300;
          margin-bottom: 10px;
          .custom-textarea {
            width: 70%;
            height: 40px;
          }
        }
      }
      .column-100 {
        margin-left: 0;
        margin-right: 0;
        padding: 20px 20px 20px 20px;
        width: 100%;
        .ant-form-item-label > label {
          color: #6e7882;
          font-weight: 300;
          font-size: 11px;
          font-family: Roboto;
        }
        .map-container {
          height: 550px;
          width: 100%;
          margin: 0 auto;
        }
        &__title {
          color: #6e7882;
          font-weight: 300;
          font-size: 11px;
          font-family: Roboto;
          margin-bottom: 30px;
        }
        .type-map__input {
          font-size: 12px;
        }

      }
      .ant-calendar-picker-icon {
        right: 55px;
      }
      .column-100__title {
        font-size: 11px;
        font-weight: 300;
      }
    }
    .button-blue {
      cursor: pointer;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 800;
      width: 100px;
      height: 40px;
    }
  }
  .statistic-wrapper {
    .custom-caret {
      margin-left: 355px !important;
    }

    .custom-value {
      margin-right: 650px !important;
    }

    .custom-map {
      margin-right: 120px;
    }
  }
}

.card-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .card {
    width: 24%;
    margin: 0;
    border: none;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    color: #222B38;
    cursor: pointer;
    &__title {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding-left: 10px;
      padding-right: 20px;
      &__icon {
        font-size: 40px;
        font-weight: bold;
        opacity: 0.5;
      }
      .value {
        font-size: 40px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        &__prefix {
          font-size: 20px;
        }
        &__suffix {
          font-size: 20px;
        }
      }
    }
    &--red {
      background-image: linear-gradient(90deg, #b44962, #db2660, #b2324f);
    }
    &--purple {
      background-image: linear-gradient(90deg, #8460d9, #4d32b2);
    }
    &--green {
      background-image: linear-gradient(90deg, #4dd66d, #139c2a);
    }
    &--yellow {
      background-image: linear-gradient(90deg, #fac669, #e69300);
    }
  }
}
.statistic-wrapper {
  width: 100%;
  z-index: 300;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  padding: 20px;
  display: inline-grid;
  flex-direction: column;
  margin-top: 20px;
  .custom-caret {
    border: none;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 21px;
    position: static;
    bottom: 11.6px;
    margin-top: -27px;
    margin-left: 555px;
    background: white;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    z-index: 3333;
    top: 41px;
  }
  .custom-value {
     border: none;
     border-width: 0 1px 1px 0;
     display: inline-block;
     padding: 21px;
     position: static;
     bottom: 11.6px;
     margin-top: -27px;
     background: white;
     -webkit-transform: rotate(-135deg);
     transform: rotate(-135deg);
     z-index: 3333;
     top: 41px;
     float: right;
     margin-right: 555px;
   }
  .custom-map {
    border: none;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 21px;
    position: static;
    bottom: 11.6px;
    margin-top: -27px;
    background: white;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    z-index: 3333;
    top: 41px;
    float: right;
    margin-right: 120px;
  }
  &__title {
    text-transform: uppercase;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 40px;
  }
  .statistic-line {
    justify-content: space-between;
    overflow: auto;
    display: flex;
    .statistic {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6e7882;
      display: inline-block;
      padding-left: 100px;
      padding-right: 30px;
    }
    .ant-progress {
      padding: 10px;
      display: flex;
      justify-content: center;
      .ant-progress-inner {
        margin-bottom: 20px;
        height: 190px !important;
        font-size: 26.5px !important;
        width: 236px !important;
        line-height: 12;

        .ant-progress-text {
          width: 57px;
          height: 39px;
          font-family: Roboto;
          font-size: 30px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
        }

        .ant-progress-circle-path {
          stroke-width: 18px;
          stroke-linecap: butt;
        }
        .ant-progress-circle-trail {
          stroke: #d8d8d8 !important;
        }

        .ant-progress-circle {
          width: 232px;
          height: 140px;
          overflow: initial !important;
        }
      }
    }
  }
  .statistic-map-content {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;

    .rsm-marker{
      &__info{
        display: none;
      }
      &:hover{
        fill: #6e7882 !important;
        z-index: 200 !important;
        text{
          display: flex !important;
          color: #6e7882;
            width: 100%;
            height: 100%;
            font-family: Roboto;
            font-size: 21px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.05;
            letter-spacing: normal;
            text-align: center;
          z-index: 200 !important;
        }
        circle {
          fill: rgb(250, 198, 105) !important;
        }
      }
    }
  }
  .line-chart-wrapper {
    .ant-tabs-bar {
      border-bottom-color: transparent;
      margin-bottom: 30px;
      .ant-tabs-nav-scroll {
        display: flex;
        justify-content: center;
        .ant-tabs-tab{
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
          &-active {
            font-weight: 500;
            color: #000000;
          }
        }
        .ant-tabs-ink-bar {
          background-color: #000000
        }
      }
    }
  }
}