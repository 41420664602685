.unlockedDashboard-wrapper {
  margin-bottom: 30px;
}

.unlockedDashboard-name {
  color: #111C26;
  cursor: pointer;
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
  font-size: 16px;
  max-width: 50%;
  margin-right: 10px;
  width: 40%;
  span {
    font-weight: 400;
  }
}

.unlockedDashboard__questionGroup {
  font-size: 12px;
  margin-top: 5px;
  font-family: 'Roboto';
  font-weight: 400;
}

.unlockedDashboard-itemHeaderOpened {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 2px solid #D6D3D3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
}

.unlockedDashboard-itemHeader {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
}

.unlockedDashboard-infoContainer {
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: flex;
  justify-content: space-between;
  margin: 0 70px 0 15px;
  min-height: 70px;
  padding-right: 20px;
  width: 100%;
  cursor: pointer;
}

.unlockedDashboard__lastAssessmentInfo {
  font-size: 14px;
  margin-right: 20px;
  font-family: 'Roboto';
  font-weight: 400;
  text-align: right;
  line-height: 18px;
  width: 41%;
}

.unlockedDashboard__icon {
  margin-right: 10px;
}

.unlockedDashboard__lastAssessmentInfo span {
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 400;
}

.unlocked-userIcon {
  -ms-flex-align: center;
  align-items: center;
  background-color: #FDFAFA;
  border: 1px solid #D4D4D4;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  width: 50px;
  position: relative;
  overflow: hidden;
}

.dashboardIconImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
}

@media screen and (min-width: 768px) {
  .unlockedDashboard-itemHeaderOpened, .unlockedDashboard-itemHeader {
    align-items: center;
    flex-direction: row;
  }
  .unlockedDashboard-editContainer {
    margin-left: auto;
    margin-right: 50px;
    width: auto;
  }
}

@media (max-width: 1490px) and (min-width: 1300px) { 
  .unlockedDashboard-name {
    max-width: 37%;
  }
  .unlockedDashboard__lastAssessmentInfo {
    width: 40%;
  }
}

@media (max-width: 1300px) and (min-width: 1200px) {  
  .unlockedDashboard__lastAssessmentInfo { 
    text-align:justify; 
    word-break:break-all;
  }
}

@media (max-width: 1160px) and (min-width: 768px) {  
  .unlockedDashboard__lastAssessmentInfo { 
    text-align:justify; 
    word-break:break-all;
  }
}
@media (max-width: 768px) {  
  .unlockedDashboard__lastAssessmentInfo { 
    text-align:justify; 
    word-break:break-all;
  }
}