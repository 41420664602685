.culling-modal {
  width: 790px !important;
  .ant-modal-content {
    width: 790px !important;
    //height: 400px;
    border-radius: 0 !important;
    font-family: Roboto;
    box-shadow: 2px 14px 30px rgba(21,0,16,0.3) !important;
    .ant-modal-body {
      padding: 30px;
    }
  }
  .ant-modal-header {
    padding: 0px;
    background-color: #665360;
    font-weight: 700;
    border: none;
    border-radius: 0;
    .ant-modal-title {
      margin: 0;
      color: #382e34;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      word-wrap: break-word;
      letter-spacing: 0.5px;
    }
  }
  .header-modal-culling {
    display: flex;
    justify-content: space-between;
    //height: 20px;
    padding: 10px;
    background-color: var(--subNavigationBackground);
    .icon-v18-close-black {
      color: #FFFFFF;
      font-size: 20px;
    }
    .icon-v18-close-black:before {
      margin-left: 0px;
      margin-right: 0px;
    }
    .content-header-modal-culling {
      display: flex;
      align-items: center;
      color: #FFFFFF;
      img {
        width: 20px;
        margin-right: 10px;
      }
      .title-culling {
        font-weight: 700;
        font-family: Roboto;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.5px;
      }
    }
  }

  .culling-modal-content {
   .culling-contain-function {
    .culling-modal-header {
      .title-header {
        font-weight: 700;
        font-family: Roboto;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #382e34;
      }

      .icon-v14-question {
        font-size: 20px;
        color: #382e34;
        margin-left: 10px;
      }
    }

    .culling-contain {
      margin-top: 30px;

      .content-functions-culling {
        margin-bottom: 30px;
        width: 715px;
        //align-items: flex-start;
        height: auto;
        max-height: 480px;
        display: flex;
        align-items: center;

        .title-functions-culling {
          width: 247px;
          line-height: 20px;
          color: #382e34;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: #382e34;
        }

        .functions-culling-contain {
          width: 483px;
          margin-left: 0px;
          .row {
            display: flex;
            justify-content: space-between;
          }
           .options-keep-file {
             display: flex;
             align-items: flex-end;
             width: 340px;
             .content-select-keep {
               display: flex;
               justify-content: space-between;
               align-items: center;
               .icon-v16-arrow-medium {
                 font-size: 20px;
               }
             }
             .ant-select-no-arrow .ant-select-selection__rendered {
               margin-right: 0px;
             }
             .ant-select-selection-selected-value {
               padding-right: 0;
               width: 100%;
             }
             .ant-select-disabled .ant-select-selection {
               background: #FFFFFF;
               border: 1px solid #D4D7DA !important;
               cursor: default;
               color: #D4D7DA;
               .ant-select-arrow {
                 color: #D4D7DA !important;
               }
               &:hover {
                 .ant-select-selection {
                   border: 1px solid #6e7882 !important;
                   border-radius: 3px;
                 }
                 .ant-select-arrow {
                   color: #D4D7DA !important;
                 }
               }
             }
             .select-keep-file {
               width: 340px;
               height: 30px;
               //padding: 10px 7px 10px 7px;
               .options-status-keep {
                 font-family: Roboto;
                 font-weight: 400;
                 font-size: 14px;
                 color: #382e34;
                 line-height: 20px;
                 letter-spacing: 0.2px;
                 padding-left: 10px;
               }
             }
             .ant-select-selection--single {
               height: 30px;
               padding-left: 10px;
               padding-right: 10px;
             }
             .ant-select .ant-select-selection .ant-select-arrow {
               margin-right: 0px;
             }
             .ant-select {
               width: 340px;
               font-family: Roboto;
               font-weight: 400;
               font-size: 14px !important;
               color: #382e34 !important;
               cursor: pointer;
               .ant-select-arrow {
                 color: #382e34;
               }
               &:hover {
                 .ant-select-selection {
                   border: 1px solid #EDA41E;
                   border-radius: 0px;
                 }
                 .ant-select-arrow {
                   color: #EDA41E;
                 }
               }
               &:active {
                 .ant-select-selection {
                   border: 1px solid #EDA41E;
                   border-radius: 0px;
                 }
                 .ant-select-arrow {
                   color: #EDA41E;
                 }
               }
             }
             .ant-select-selection {
               border: 1px solid #6e7882;
               border-radius: 0px;
             }
             .ant-select-disabled {
               cursor: not-allowed;
             }
             .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
               color: #6e7782;
               font-size: 14px;
             }
             .ant-select-selection__rendered {
               margin-left: 0px;
             }
           }
          .right-container {
            .switcher-select-configuration {
              padding-left: 0;
              align-items: center;
              background-color: #ffffff;
              display: flex;
              font-size: 14px;
              position: relative;

              .switcher__slider:hover {
                background-color: #FFC457;
                border: 1px solid #EDA41E;
              }

              .switcher__slider:active {
                background-color: #FFC457;
                border: 1px solid #EDA41E;
              }

              .switcher__slider {
                background-color: #ffffff;
                border-radius: 34px;
                position: relative;
                width: 70px;
                height: 25px;
                cursor: pointer;
                border: 1px solid #6e7882;

                &:after {
                  content: 'No';
                  color: #6E7882;
                  font-size: 16px;
                  font-weight: 400;
                  position: absolute;
                  right: 16px;
                  top: 0px;
                  z-index: 0;
                }
              }

              .switcher__slider:hover {
                border: 1px solid var(--generalButtonHover);
                background-color: #FFFFFF;
              }

              .switcher__slider:active {
                border: 1px solid var(--generalButtonActive);
                background-color: #FFFFFF;
              }

              .switcher__sliderChecked:hover {
                background-color: var(--generalButtonHover);
                border: 1px solid var(--generalButtonHover);
              }

              .switcher__sliderChecked:active {
                background-color: var(--generalButtonActive);
                border: 1px solid var(--generalButtonActive);
              }

              .switcher__sliderChecked {
                background-color: var(--generalButton);
                border-radius: 34px;
                position: relative;
                width: 70px;
                height: 25px;
                cursor: pointer;
                border: 1px solid #6e7882;

                &:before {
                  content: 'Yes';
                  color: var(--generalButtonFont);
                  position: absolute;
                  z-index: 0;
                  font-size: 16px;
                  font-weight: 400;
                  left: 14px;
                  top: 0px;
                }
              }

              .switcher__sliderButton {
                background-color: #FFFFFF;
                border-radius: 50px;
                cursor: pointer;
                display: block;
                height: 19px;
                left: 3px;
                position: absolute;
                top: 2px;
                transition: all 0.4s ease;
                width: 19px;
                z-index: 1;
                border: 1px solid #6e7882;
              }

              .switcher__sliderButtonChecked {
                background-color: #FFFFFF;
                border-radius: 50px;
                cursor: pointer;
                display: block;
                height: 19px;
                left: 47px;
                position: absolute;
                top: 2px;
                transition: all 0.4s ease;
                width: 19px;
                border: 1px solid #6e7882;
              }
            }
          }
        }
      }
    }

     .footer-culling {
       display: flex;
       justify-content: space-between;
       height: 25px;
       align-items: center;
       margin-top: 40px;
       //margin-bottom: 30px;
       width: 730px;
       .action-cancel-culling {
         width: 180px;
         height: 25px;
         border: 1px solid #6e7882;
         background-color: #FFFFFF;
         color: #6e7882;
         box-shadow: none;
         cursor: pointer;
         padding: 0px 30px;
         border-radius: 30px;
         font-size: 16px;
         font-weight: 400;
         letter-spacing: 0.5px;
         alignment: center;
         vertical-align: middle;
         line-height: 18.75px;

         &:hover {
           background-color: #FFFFFF;
           border-color:var(--generalButtonHover);
           color: var(--generalButtonHover);
         }

         &:focus {
           border: 1px solid #6e7882;
           background-color: #FFFFFF;
           color: #6e7882;
           -webkit-box-shadow: none;
           box-shadow:none;
         }

         &:active {
           border: 1px solid var(--generalButtonActive);
           background-color: #FFFFFF;
           color: var(--generalButtonActive);
         }
       }
       .action-cancel-culling-loading {
         width: 180px;
         height: 25px;
         border: 1px solid #FFC457;
         background-color: #FFFFFF;
         color: #FFC457;
         box-shadow: none;
         cursor: pointer;
         padding: 0px 30px;
         border-radius: 30px;
         font-size: 16px;
         font-weight: 400;
         letter-spacing: 0.5px;
         alignment: center;
         vertical-align: middle;
         line-height: 18.75px;
         pointer-events: none;
         .anticon-loading {
           margin-right: 15px;
         }
       }
       .action-sweep-culling:disabled {
         width: 180px;
         height: 25px;
         border: 1px solid #6e7882;
         background-color: #D4D7DA;
         color: #FFFFFF;
         box-shadow: none;
         cursor: default;
         padding: 0px 30px;
         border-radius: 30px;
         font-size: 16px;
         font-weight: 500;
         letter-spacing: 0.5px;
         alignment: center;
         vertical-align: middle;
         line-height: 18.75px;

         &:hover {
           background-color: #D4D7DA;
           border-color: #6e7782;
           color: #FFFFFF;
         }

         &:focus {
           border: 1px solid #6e7882;
           background-color: #D4D7DA;
           color: #FFFFFF;
           -webkit-box-shadow: none;
           box-shadow: none;
         }

         &:active {
           border: 1px solid #6e7882;
           background-color: #D4D7DA;
           color: #FFFFFF;
         }
       }
       .ant-btn > span {
         padding-top: 2px;
       }
       .action-sweep-culling {
         width: 180px;
         height: 25px;
         border: 1px solid #6e7882;
         background-color: var(--generalButton);
         color: var(--generalButtonFont);
         box-shadow: none;
         cursor: pointer;
         padding: 0px 30px;
         border-radius: 30px;
         font-size: 16px;
         font-weight: 500;
         letter-spacing: 0.5px;
         alignment: center;
         vertical-align: middle;
         line-height: 18.75px;

         &:hover {
           background-color: var(--generalButtonHover);
           border-color: var(--generalButtonHover);
           //color: #FFFFFF;
         }

         &:focus {
           border: 1px solid #6e7882;
           background-color: #EDA41E;
           color: #FFFFFF;
           -webkit-box-shadow: none;
           box-shadow: none;
         }

         &:active {
           border: 1px solid var(--generalButtonActive);
           background-color: var(--generalButtonActive);
           //color: #FFFFFF;
         }
       }
       .action-sweep-culling-loading {
         width: 180px;
         height: 25px;
         border: 1px solid #eda41e;
         background-color: #FFC457;
         color: #FFFFFF;
         box-shadow: none;
         cursor: pointer;
         padding: 0px 30px;
         border-radius: 30px;
         font-size: 16px;
         font-weight: 500;
         letter-spacing: 0.5px;
         alignment: center;
         vertical-align: middle;
         line-height: 18.75px;
         pointer-events: none;
         .anticon-loading {
           margin-right: 15px;
         }
       }
     }
  }
  }
}

.dropdown-keep-file {
  border: 1px solid #EDA41E !important;
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #9AE0FC !important;
  }
  .ant-select-dropdown-menu-item {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    color: #382e34;
    line-height: 20px;
    letter-spacing: 0.2px;
    padding-left: 10px;
  }
  .ant-select-dropdown-menu {
    padding: 0px !important;
  }
}