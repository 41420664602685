.privacyTopic__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyTopic__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
}

.privacyTopic__boxHeader {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.privacyTopic__boxTitle {
  color: #162737;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacyTopic__boxTitleTotal {
  font-size: 18px;
  font-weight: 300;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
}

.privacyTopic__boxTitleTotal::before {
  content: '-';
  left: 0;
  position: absolute;
  top: 0;
}

.privacyTopic__boxText {
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
}

.privacyTopic__navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.privacyTopic__listItemButton {
  border: 1px solid #737D87;
  border-radius: 5px;
  color: #737D87;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 8px;
}

.privacyTopic__listItem {
  color: #1DCBCF;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 8px;
}

.privacyTopic__listItem--disabled {
  color: #DADCE0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 8px;
}

.privacyTopic__page .topic-topicList-wrapper {
  display: flex;
  flex-direction: column;
}

.privacyTopic__loading {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .privacyTopic__navigation {
    flex-wrap: initial;
    justify-content: space-between;
  }

  .privacyTopic__listItem {
    font-size: 18px;
  }

  .privacyTopic__page .topic-topicList-wrapper {
    flex-direction: row;
  }
}