.addKeywords__params {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.addKeywords__params:last-of-type {
  margin-bottom: 10px;
}

.addKeywords__formGroup input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 40px;
  padding: 10px;
  width: 100%;
}

.addKeywords__formGroup input:focus {
  outline: 2px solid #3DD8DB;
}

.addKeywords__formGroup select {
  appearance: none;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  color: #555555;
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
}

.addKeywords__formGroup select:focus {
  outline: 2px solid #3DD8DB;
}

.addKeywords__select {
  width: 26%;
}

.addKeywords__value {
  width: 70%;
}