.datalinesList__empty {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  text-align: center;
}

.datalinesList__wrapperCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.datalinesList__wrapperTable {
  margin-bottom: 20px;
  width: 100%;
}
