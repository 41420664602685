input.title1 {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
}

.descriptionStyle {
  display: inline-block;
}

input.description1 {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 49%;
}

button.buttonStyle {
  width: 100px;
}

.teamIconImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
}

.styleUploadImage {
  display: inline-block;
}