.email-page {
  background: url(../../assets/background.jpg) no-repeat center center #162737;
  min-height: 100%;
  padding: 100px 0 50px;
}

.email-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  height: auto;
  max-width: 700px;
  margin: 0 auto 50px;
  padding: 20px 10px;
  width: 96%;
}

.email-box img {
  display: block;
  height: auto;
  margin: 0 auto 15px;
  width: 50px;
}

.email-box h1 {
  color: #555555;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.email-box a {
  color: #3DD8DB;
  font-size: 14px;
  text-decoration: underline;
}

.email-box a:hover {
  font-style: italic;
}

@media screen and (min-width: 768px) {
  .email-box {
    padding: 20px;
  }

  .email-box h1 {
    font-size: 22px;
  }
}