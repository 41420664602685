.addObjectTypes__wrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.addObjectTypes__wrapper:last-of-type {
  margin-bottom: 0;
}

.addObjectTypes__wrapper div:first-of-type {
  flex-basis: 26%;
}

.addObjectTypes__wrapper div:nth-child(2) {
  flex-basis: 26%;
}

.addObjectTypes__wrapper div:last-of-type {
  flex-basis: 42%;
}

.addObjectTypes__wrapper input {
  height: 40px;
}