.manageGroups-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.manageGroups-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative
}

.manageGroups-section {
  border-top: 1px solid #E6E6E6;
  padding: 20px 20px 0
}

.manageGroups-sectionTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px
}

.manageGroups-sectionSubtitle {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px
}

.manageGroups-loading {
  display: flex;
  justify-content: center
}

.manageGroups-tableContainer {
  overflow: auto;
  width: 100%
}

.manageGroups-table {
  border-collapse: collapse;
  width: 100%
}
.manageGroups-table tr td:first-child {padding-left:5px;}
.manageGroups-table tr td:last-child {padding-right:5px;}

.manageGroups-tableTitle {
  border-bottom: 1px solid #EEEEEE;
  font-weight: 400;
  padding: 20px 0;
  text-align: left;
  text-transform: uppercase;
  font-family: 'Roboto';
  color: #7f7f7f;
  font-size: 14px;
}

.manageGroups-tableTitle:first-of-type:not(.width-nf) {
  width: 150px;
}

.manageGroups-tableTitle:last-of-type {
  text-align: right;
  width: 300px;
}

.manageGroups-tableContent {
  border-bottom: 1px solid #EEEEEE;
  font-weight: 400;
  padding: 15px 0;
  position: relative;
  text-align: left;
  vertical-align: middle;
  font-family: 'Roboto';
}
.resend-manage-user {
  border-radius: 20px;
  background-color: #EDA41E;
  color: #fff;
  border: 1px solid #eda41e;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  text-transform: initial;
  transition: all .2s;
  height:25px;
  padding:0px;
  letter-spacing: 0.5px;
  width: 80px;
}
.resend-manage-user:hover {
  background-color: #fff;
  border:1px solid #eda41e;
  color:#eda41e;
}
.content-resend {
  margin-top: 0px !important;
}
.manageGroups-tableContentInfo {
  border-bottom: 1px solid #EEEEEE;
  font-size: 18px;
  font-weight: 300;
  padding: 15px 10px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  font-family: 'Roboto';
}

.manageGroups-tableContentAction {
  border-bottom: 1px solid #EEEEEE;
  font-weight: 300;
  padding: 15px 10px;
  position: relative;
  text-align: right;
  vertical-align: middle;
}

.manageGroups-userEmail {
  display: block;
  font-size: 12px;
  padding-top: 4px
}

.manageGroups-role {
  background: url(../../../assets/icon-lock.png) no-repeat 85px 10px #888888;
  border-radius: 34px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  padding: 10px 12px;
  text-transform: uppercase;
  width: 110px;
  margin:0 auto;
}

.manageGroups-iconLogged::after {
  background-color: #7A8B9A;
  border-radius: 50%;
  color: #FFFFFF;
  content: '✓';
  font-style: normal;
  height: 30px;
  line-height: 30px;
  margin-top: -15px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  width: 30px;
}

.manageGroups-iconNotLogged::after {
  background-color: #F44337;
  border-radius: 50%;
  color: #FFFFFF;
  content: '☓';
  font-style: normal;
  height: 30px;
  line-height: 30px;
  margin-top: -15px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  width: 30px;
}

.manageGroups-action {
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-right: 35px;
  text-transform: uppercase;
  width: 140px;
}

.manageGroups-tableLoading {
  display: flex;
  justify-content: flex-end;
  margin: 0 30px 0 auto
}

.manageGroups-tableMessage {
  margin: 0 30px 0 auto
}

.manageGroups-slider {
  background-color: #eeeeee;
  border-radius: 34px;
  height: 34px;
  position: relative;
  width: 110px;
  margin: 0 auto;
  cursor: pointer;
}

.manageGroups-slider::after {
  content: 'USER';
  color: #9999ae;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  right: 15px;
  top: 11px;
  z-index: 0;
  letter-spacing: 0.5px;
}

.manageGroups-sliderChecked {
  background-color: #3DD8DB;
  border-radius: 34px;
  height: 34px;
  position: relative;
  width: 110px;
  margin: 0 auto;
}

.manageGroups-sliderChecked::before {
  content: 'ADMIN';
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
  left: 10px;
  position: absolute;
  top: 11px;
  z-index: 0;
  letter-spacing: 0.5px;
}
.manageGroups-sliderChecked .manageGroups-sliderButton {
  left: auto;
  right: 10px;
}
.manageGroups-sliderCheckedSadmin {
  background-color: rgb(4, 72, 73);
  border-radius: 34px;
  height: 34px;
  position: relative;
  width: 110px;
  margin: 0 auto;
}

.manageGroups-sliderCheckedSadmin::before {
  content: 'S-ADMIN';
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
  left: 10px;
  position: absolute;
  top: 12px;
  z-index: 0;
  letter-spacing: 0.5px;
}
.manageGroups-sliderCheckedSadmin .manageGroups-sliderButton {
  left: auto;
  right: 10px;
}
/* .manageGroups-sliderChecked::after {
  content: 'USER';
  color: #555555;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 0;
} */

.manageGroups-sliderButton {
  background-color: #FFFFFF;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  /*height: 20px;
  left: 7px;*/
  position: absolute;
  /* top: 7px;*/
  transition: all 0.4s ease;
  /* width: 45px; */
  z-index: 1;

  height: 15px;
  left: 10px;
  top: 9px;
  width: 15px;
}

.manageGroups-sliderButtonChecked {
  background-color: #FFFFFF;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  /* height: 20px;
  left: 57px; */
  position: absolute;
  /* top: 7px; */
  transition: all 0.4s ease;
  /* width: 45px; */
  z-index: 1;

  height: 15px;
  left: 83px;
  top: 9px;
  width: 15px;
}

.manageGroups-footer {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.manageGroups-footerLoading {
  margin: 0
}

.manageGroups-footerMessage {
  margin: 0
}

@media screen and (min-width: 768px) {
  .manageGroups-page {
    padding: 40px 0 0;
  }

  .manageGroups-iconLogged::after {
    right: 40px;
  }

  .manageGroups-iconNotLogged::after {
    right: 40px;
  }

  .manageGroups-action {
    padding-right: 85px;
    width: auto;
  }

  .manageGroups-footer {
    justify-content: flex-end;
  }
}