.buttonAddBusinessProcess-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 30px;
  padding: 30px 20px;
  position: relative;
}

.buttonAddBusinessProcess-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.buttonAddBusinessProcess-text {
  color: #555555;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 400;
}

.buttonAddBusinessProcess-formGroup {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
select {
  appearance: none;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  color: #555555;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
}

.buttonAddBusinessProcess-button {
  left: 0;
  bottom: -24px;
  position: absolute;
  text-align: center;
  width: 100%;
button {
  height: 50px;
  padding: 0 20px;
  width: 300px;
  font-size: 16px !important;
}
}

.buttonAddBusinessProcess-alterText {
  color: #555555;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .buttonAddBusinessProcess-box {
    height: 240px;
  }
  .buttonAddBusinessProcess-formGroup select {
    width: 400px;
  }
  .buttonAddBusinessProcess-button button {
    font-size: 18px;
    width: 350px;
  }
}