.questionsHeaderBig__header {
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.questionsHeaderBig__header h1 {
  font-size: 24px;
  font-weight: 500;
}

.questionsHeaderBig__link {
  color: #3DD8DB;
  display: block;
  font-size: 14px;
  margin-bottom: 40px;
  text-decoration: underline;
}

.questionsHeaderBig__link:hover {
  font-style: italic;
}
