.datalineBuilder__wrapper {
  background-color: rgb(212, 212, 212);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32%;
  margin-bottom: 20px;
  padding:3px;
}

.datalineBuilder__header{
  background-color: rgb(255, 255, 255);
}

.datalineBuilder__header {
  border-bottom: 1px solid #E6E6E6;
  padding: 15px 50px 15px 15px;
  position: relative;
}

.datalineBuilder__title {
  color: #162737;
  font-size: 18px;
}

.datalineBuilder__processorProperty {
  padding: 3px;
}

.datalineBuilder__processor {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32%;
  margin-bottom: 20px;
  margin-left:20px;
  margin-right:20px;
  margin-top:20px;
  padding:3px;
}

.datalineBuilder__processorProperty_edited {
  border: 1px solid #adadad;
  padding:2px;
  height: 35px;
}
.datalineBuilder__processorProperty{
  padding:2px;
  height: 35px;
 }

.datalineBuilder__propertyname{
  padding: 3px;
  width: 300px;
  min-width: 300px;
  float:left;
}
.datalineBuilder__propertyvalue{
  padding: 3px;
  float:left;
}

.datalineBuilder__processorRevert{
  padding: 3px;
  padding-left:15px;
  float:left;
  cursor: pointer;
}

.datalineBuilder__processorType{
  padding:5px;
  border-bottom: 1px solid #000000;
  margin-bottom: 5px;

}

.datalineBuilder__propertyempty{
  padding: 6px;
}

.datalineBuilder__addRemoveProcessor{
  float:right;
}

.datalineBuilder__addProcessor{
  display: inline-block;
  padding-right: 10px;
  cursor: pointer;
}

.datalineBuilder__removeProcessor{
  display: inline-block;
  cursor: pointer;
}
