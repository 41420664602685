.setPassword-page {
  min-height: 100%;
  display: flex;
  align-items:center;
  background: #ffffff url(../../assets/Background.svg) no-repeat center;
  background-size: cover;
  flex-direction: column;

  h1 {
    font-family: 'Roboto';
    font-size: 29px;
    letter-spacing: -0.2px;
    line-height: 30px;
    color: #382e34;
  }
}
.new-set-password-page {
  width: 100%;

  .setPassword-header {
    background-color: #150010;
    margin-bottom: 90px;
    height: 90px;
    display: flex;
    padding-left: 150px;

    .logo-osprey-header {
      width: 245px;
    }
  }
  .setPassword-box {
    background-color: #FFFFFF;
    /*border-radius: 5px;*/
    height: 370px;
    max-width: 400px;
    margin: 0 auto 50px;
    padding: 55px 55px 40px 55px;
    -webkit-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
    -moz-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
    box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
    border: 1px solid #382e34;

    .setPassword-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .setPassword-formGroup {
        width: 100%;

        label {
          color: #6e7882;
          display: block;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 10px;
        }

        input {
          border: 1px solid #a6acb2;
          background-color: rgba(154, 224, 248, 0.3);
          border-radius: 0;
          box-sizing: border-box;
          color: #382e34;
          font-size: 16px;
          padding: 10px;
          width: 100%;
          height: 40px;
        }

        input:focus {
          outline: 2px solid #9AE0FC;
          border-radius: 0;
          border: none;
        }
        input:hover {
          border: 2px solid #9AE0FC;
        }
        input:focus:hover {
          border: none;
        }
        span {
          position: relative;
        }
        i {
          position: absolute;
          top: 0px;
          left: 260px;
        }
      }

      .setPassword-footer {
        margin-top: 40px;

        .setPassword-footerForm {
          align-items: center;
          display: flex;

          .setPassword-btn {
            width: 100%;
            margin-bottom: 20px;
            padding: 5px 30px !important;
            text-transform: capitalize !important;
            font-weight: 400 !important;
            font-size: 15px !important;

            &:not(:disabled) {
              border: 1px solid #3DD8DB;
            }
          }
        }
      }


    }
  }
}


.new-set-password {
  height: 420px;
}

.setPassword-form {
  padding: 0 10px;
}

.setPassword-form p {
  color: #555555;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px 0;
}

.setPassword-formGroup:first-of-type {
  margin: 0 20px 20px 0;
}

.setPassword-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.setPassword-message {
  margin-top: 20px
}

@media screen and (min-width: 768px) {
  .setPassword-box {
    padding: 20px;
  }
  .new-set-password {
    height: 420px;
  }

  .setPassword-form {
    padding: 0 20px;
  }
}

.tooltip-over {
  .ant-tooltip-inner {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    .tooltip-wrapper {
      &__item-green {
        color: #1DCBCF;
        i {
          margin-right: 7px;
        }
      }
      &__item-red {
        color: #ff0000;
        i {
          margin-right: 7px;
        }
      }
    }
    .tooltip-wrapper__title {
      font-weight: 500;
      color: #000000;
      font-size: 14px;
    }
  }
}