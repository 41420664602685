.topic-topicList-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media screen and (min-width: 768px) {
  .topic-topicList-wrapper {
    flex-direction: row;
  }

  .topic-topicList-wrapper .topicItem-wrapper {
    border-bottom: none;
    flex-basis: 48%;
    margin-right: 4%;
  }

  .topic-topicList-wrapper .topicItem-wrapper:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .topic-topicList-wrapper .topicItem-wrapper {
    flex-basis: 32%;
    margin-right: 2%;
  }

  .topic-topicList-wrapper .topicItem-wrapper:nth-child(2n) {
    margin-right: 2%;
  }

  .topic-topicList-wrapper .topicItem-wrapper:nth-child(3n) {
    margin-right: 0;
  }
}