
.file-settings-tab {

  .content-button-analytics {
    display: flex;
    padding-top: 30px;
    justify-content: center;
    padding-right: 39px;

    .ant-btn {
      height: 45px;
      padding-top: 3px;
      object-fit: contain;
      font-family: Roboto, serif;
      font-size: 18px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #232B38;
      background-color: white;
      border-radius: 30px;
      border: 1px solid #232B38;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      width: 35%;

      &:hover:not([disabled]) {
        border: 1px solid #232B38;
        color: #ffffff;
        background-color: #232B38;
      }

      &:focus {
        border: 1px solid #232B38;
        color: #ffffff;
        background-color: #232B38;
      }
    }
  }

  .new-analytics-page {
    background-color: #ffffff;
    /*padding: 30px 20px 40px 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);*/
    .ant-tree li .ant-tree-node-content-wrapper {
      height: 30px;
      line-height: 30px;
      border-radius: 0px;
    }
    .ant-tree li .ant-tree-node-content-wrapper:hover {
      background-color: transparent;
    }
    .analytics-tree {
      .ant-tree li .ant-tree-node-content-wrapper {
        height: 30px;
      }
      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: #FFFFFF !important;
        color: #6e7882 !important;
      }
    }
  }
  .ant-tree-node-content-wrapper .category-parent {
    //padding-bottom: 3px;
    //padding-top: 3px;
  }
  .close-arrow {
    font-size: 20px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    display: flex;
    font-size: 20px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    //margin-top: 13px;
  }
  .icon-v17-arrow-medium:before {
    content: '\e895';
    margin-right: 0px;
    line-height: 16px;
    margin-left: 0px;
    font-weight: 500;
  }
  .open-arrow {
    font-size: 20px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
  .analytics-page {
    .analytics-tree {
      .category-parent-tree-node {
        //background-color: #00d4d8;
      }
      .category-child-tree-node {
        //background-color: #382e34;
      }
      .ant-tree-switcher {
        display: none;

        &_close + .ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
          border: solid 1px #6e7882 !important;
        }
      }
      .ant-tree-treenode-switcher-open {
        //position: inherit;
      }
      .ant-tree-treenode-switcher-open {
        width: calc(100% - -17px);
      }
      .update-category-parent-tree-node {
        .ant-tree-checkbox-checked .ant-tree-checkbox-inner:hover {
          border: solid 1px var(--generalIconFontHover) !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
          border: 1px solid #fff;
          top: 46%;
          left: 19%;
        }
        .category-parent__name {
          padding-left: 36px !important;
        }
        .ant-tree-checkbox {
          position: relative;
          //left: 289px;
          //top: 135px !important;
          display: flex;
          left: 10px !important;
          top: 7px !important;
          .ant-tree-checkbox-inner {
            position: absolute;
          }
        }
        .category-child2-tree-node {
          .category-child {
            padding-left: 33px;
          }
        }
        .category-child2-tree-node .ant-tree-checkbox {
          .ant-tree-checkbox-inner {
            //position: inherit;
          }
        }
        .category-child-tree-node {
          .ant-tree-checkbox {
            //background-color: #FFFFFF;
          }
        }
      }
      .dragos111111111 {
        background-color: #150010;
      }
      .category-top-tree-node {
        .ant-tree-checkbox {
          border-bottom: 1px solid #cccccc;
          margin: 0;
        }
        .ant-tree-node-content-wrapper {
          padding: 0 0px;
        }
        .ant-tree-checkbox {
          height: 30px;
        }
        .ant-tree-node-content-wrapper {
          width: 99%;
        }
      }
      .category-parent2-tree-node {
        border-bottom: none !important;
        .category-parent {
          &__name {
            color: #382e34 !important;
            padding: 0 0px;
          }
          &__arrow {
            padding-right: 5px;
            i {
              font-size: 16px !important;
              color: #00d3d8;
            }
          }
        }
        .ant-tree-node-content-wrapper .category-parent {
          //border-bottom: 1px solid #00d3d8 !important;
          //padding-bottom: 0px;
          //padding-top: 3px;
          height: 30px;
        }

        .ant-tree-checkbox {
          //border-bottom: 1px solid #00d3d8 !important;
          height: 30px;
          padding-bottom: 0px;
          margin-right:0;
          padding-right: 10px;
          padding-top: 7px;
        }
        .ant-tree-node-content-wrapper {
          //padding: 0 5px;
          padding: 0 0px !important;
        }
        .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
          background-color:var(--generalIconFontActive);
          //border: solid 1px #6e7882 !important;
          //margin-right: 5px;
        }
        .ant-tree-checkbox-checked .ant-tree-checkbox-inner:hover {
          border: solid 1px var(--generalIconFontHover) !important;
        }
        .ant-tree-checkbox-checked .ant-tree-checkbox-inner:focus {
          border: solid 1px var(--generalIconFontHover) !important;
        }
        .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
          border-color: #EDA41E;
        }
        .category-child2-tree-node {
          padding-bottom: 0;
          .ant-tree li {
            padding: 0px 0;
          }
          .ant-tree-checkbox {
            border-bottom: none !important;
            height: 30px;
          }
          .category-child {
            &__name {
              font-size: 14px;
              font-weight: 400;
              color: #6e7882;
             padding: 0px 0px 0;
            }
          }
        }
      }
      .category-parent-tree-node {
        //border-bottom: 1px solid #000;
        display: flex;
        flex-wrap: wrap;
        /*padding-left: 10px;*/
        margin-top: 20px;
        padding: 0px 0px 0px 0px;
        align-items: center;
        .ant-tree-checkbox {
          top: initial;
          height: 30px;
          margin: 0 0px 0 0px;
          padding: 7px 0px;
        }
        &:first-child {
          margin-top: 0;
          padding-top: 0;
        }
        .category-parent {
          &__arrow {
            i {
              font-size: 16px !important;
              color: #382e34;
            }
          }
          &__arrow.disabled {
            cursor: not-allowed !important;

            .anticon[tabindex] {
              cursor: not-allowed;
            }
          }
        }
        .ant-tree-checkbox {
          border-bottom: none;

        }
        .ant-tree-node-content-wrapper {
          padding: 0 5px;
        }
        .ant-tree-node-content-wrapper {
          width: calc(100% - 17px);
          padding-top: 0px !important;
          padding: 0 0px;
        }
      }
      .ant-tree-node-content-wrapper {
        width: 98%;
        font-family: Roboto;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #382e34;

        &:hover {
          background: transparent;
        }

        &.ant-tree-node-selected {
          background: transparent;
        }

        .category-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #cccccc;
          padding-bottom: 5px;

          &__name {
            font-size: 16px;
            font-weight: bold;
            padding-left: 8px;
          }
        }
        .category-parent-expand {
          color: #382e34;
          .ant-tree-node-content-wrapper {
            .ant-tree-node-selected {
              border-bottom: 1px solid #000 !important;
            }
          }
          &__name {
            color: #00d3d8;
          }
        }
        .category-parent {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;

          &__name {
            font-size: 14px;
            font-weight: 700;
            color: #382e34;
          }
          &__arrow2 {
            i {
              font-size: 14px;
            }
          }
          &__arrow {
            i {
              //font-size: 20px;
              padding-left: 5px;
            }
          }
        }
      }

      .ant-tree-child-tree {
        display: contents;

        .category-child-tree-node {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 249px;
          //background-color: #FFFFFF;
          padding: 0px 0;
          margin-top: 3px;
          /*font-size: 16px;
          padding-left: 5%;*/
        }
      }
      .ant-tree-child-tree > li:first-child {
        padding-top: 0px;
        //background-color: #FFFFFF;
      }
      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        border-color: #6e7882 !important;
        position: absolute !important;
        display: table;
        color: #6e7882 !important;
        background-color: #FFFFFF;
        border: none !important;
        border-top: 0;
        border-left: 0;
        -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        transform: rotate(0deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        content: '\e8ce' !important;
        top: 7px !important;
        left: 7px !important;
        font-size: 12px !important;
        font-family: 'smartbox_icons_v19' !important;
      }

      .ant-tree-checkbox {
        .ant-tree-checkbox-inner {
          border-radius: 0%;
          border: solid 1px #6e7882;
        }

        .ant-tree-checkbox-inner::after {
          border-color: #FFFFFF;
          position: absolute !important;
          display: table;
          color: #FFFFFF;
          border: none !important;
          border-top: 0;
          border-left: 0;
          -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          transform: rotate(0deg) scale(1) translate(-50%, -50%);
          opacity: 1;
          -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          content: '\e8ce' !important;
          top: 7px !important;
          left: 7px !important;
          font-size: 12px !important;
          font-family: 'smartbox_icons_v19' !important;
        }
      }

      .ant-tree-checkbox-checked {
        .ant-tree-checkbox-inner {
          background-color: var(--generalIconFontActive);
          //border: solid 3px #3DD8DB !important
        }

        &:after {
          border: none;
        }
      }

      .ant-tree-checkbox-indeterminate {
        .ant-tree-checkbox-inner::after {
          background-color: #fff;
          left: 2px;
          top: 2px;
        }
      }
    }

    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
      border-color: var(--generalIconFontHover);
    }

    &__header-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .ant-btn {
        width: 180px;
        height: 40px;
        object-fit: contain;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #232B38;
        background-color: white;
        border-radius: 30px;
        border: 1px solid #232B38;
        box-shadow: 0 2px 4px 0 #DCDCDC;

        &:hover {
          border: 1px solid #232B38;
          color: #ffffff;
          background-color: #232B38;
        }

        &:focus {
          border: 1px solid #232B38;
          color: #ffffff;
          background-color: #232B38;
        }
      }
    }

    .analytics-action-buttons {
      margin-bottom: 20px;
      button:disabled {
        border: 1px solid #808080 !important;
        cursor: not-allowed !important;
        color: #808080 !important;
        background-color: #f5f5f5 !important;
        opacity: 0.8 !important;
        box-shadow: 0 2px 4px 0 #dcdcdc !important;
      }
    }

    &__action-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 70px;
      .new-button-confirm-action {
        width: 215px;
      }
      &__update {
        width: 110px;
        height: 45px;
        border: 1px solid #00D3D8;
        background-color: #FFFFFF;
        color: #3DD8DB;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 10px 30px;
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 900;
        &:hover {
          background-color: #00D3D8;
          border-color: #00D3D8;
          color: #FFFFFF;
        }

        &:focus {
          background-color: #FFFFFF;
          border-color: #00D3D8;
          color: #00D3D8;
        }

        &:active {
          background-color: #00D3D8;
          border-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      &__save {
        width: 110px;
        height: 45px;
        border: 1px solid #00D3D8;
        background-color: #00D3D8;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 10px 30px;
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 900;

        &:hover:not([disabled]) {
          background-color: #FFFFFF;
          border-color: #00D3D8;
          color: #00D3D8;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }

      &__cancel {
        width: 110px;
        height: 45px;
        border: 1px solid #3DD8DB;
        background-color: #FFFFFF;
        color: #3DD8DB;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 900;

        &:hover {
          background-color: #3DD8DB;
          color: #FFFFFF;
          border-color: #3DD8DB;
        }

        &:focus {
          border: 1px solid #3DD8DB;
          background-color: #FFFFFF;
          color: #3DD8DB;
        }

        &:active {
          border: 1px solid #3DD8DB;
          background-color: #FFFFFF;
          color: #3DD8DB;
        }
      }
    }
  }
}

.user-team-select-dropdown {
  border-color: rgba(110, 120, 130, 0.4) !important;
  border: 1px solid #EDA41E !important;
  box-shadow: 5px 5px 10px -5px #15001030 !important;
  padding-top: -15px !important;
  //top: 563px !important;
  //left: 613px !important;
  .ant-select-dropdown-menu {
    padding: 0px 0;
  }
  .ant-select-dropdown-menu-item {
    //border-bottom: solid 1px rgba(110, 120, 130, 0.4);
    line-height: 30px !important;
    font-family: Roboto;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    color: #6e7882;
    font-weight: 400;
    background-color: #9ae0fc50 !important;

  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #9AE0FC50 !important;
    //opacity: 0.5;
    color: #6e7882 !important;
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    //background-color: #00d3d8;
    color: white;
    font-weight: 400;

  }

  .ant-select-dropdown-menu-item:first-child {
    border-radius: 0;
  }
}
.child-tabs-security {
  .ant-tabs-content {
    margin-top: 60px !important;
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-scroll {
      .ant-tabs-nav {
        width: 100%;
        > div {
          display: flex;
          justify-content: space-between;

          .ant-tabs-tab {
            border: none;
            background: #fafafa !important;
            font-weight: bold;
            color: #6e7882;
            font-size: 20px;

            &.ant-tabs-tab-active {
              border-bottom: 2px solid #00d3d8;
              transition: none;
            }
          }
        }
      }
    }
  }
}
 .child-tabs-data {
   .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
     background-color: #FAFAFA !important;
     font-size: 500;
     opacity: 0.8;
   }
 }
.child-tabs {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .access-expressions {
    /*border: 2px solid rgba(0, 0, 0, 0.1);*/
    &__content-text-topics {
      display: flex;
      padding-top: 30px;
      justify-content: center;
      padding-bottom: 100px;

      .text-topics {
        width: 410px;
        font-size: 16px;
        font-weight: 300;
        color: #000000;
        text-align: center;
      }
    }

    &__contain-regular-expressions {
      /*display: flex;
      justify-content: space-between;
      padding-bottom: 0;*/
      .style-regular-expression {
        color: #6e7782;
        font-size: 20px;
        font-weight: bold;
      }
      .ant-collapse {
        width: 100%;
        /*padding: 30px 20px 40px 20px;*/
        background-color: white;
        margin-top: 2px;

        .ant-collapse-item {
          border-bottom: none;
          .ant-collapse-header {
            /*border-bottom: 1px solid #cccccc;*/
            color: #6e7782;
            font-size: 14px;
            font-weight: 400;
            padding: 0 25px 0 0;
            .ant-collapse-arrow {
              right: 25px;
              //left: 0px;
              float: left;
              top: 40%;
            }
            .anticon {
              color: #6e7782;
              font-size: 16px;
            }
          }
        }
      }
    }

    &__content-radio {
      .tracker-radio-check {
        .radio-style {
          display: block;
          height: 30px;
          lineHeight: 30px;
          color: #000000;
          font-weight: 300;
          font-size: 16px;
        }
        .text-radio {
          color: #6e7782;
        }

        .ant-checkbox-group {
          .ant-checkbox-wrapper {
            margin-left: 0 !important;
          }

          .ant-checkbox-inner {
            border-radius: 50%;
            border: solid 1px #6e7882;
            top: 0;
          }

          .ant-checkbox-inner::after {
            content: "\f00c" !important;
            color: #3DD8DB !important;
            font-size: 8px !important;
            font-family: 'FontAwesome' !important;
            top: 0.6px;
            left: 0;
            background-color: #FFFFFF;
            border-radius: 50%;
            transform: rotate(-5deg);
          }
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #04D3D8;
            border: solid 3px #3DD8DB !important
          }

          &:after {
            border: none;
          }
        }

        .ant-checkbox-indeterminate {
          .ant-checkbox-inner::after {
            background-color: #04D3D8;
          }
        }
      }
    }

    &__content-regular-builder {
      display: flex;
      padding-top: 20px;
      justify-content: space-between;

      .style-regular-expression {
        color: #6e7782;
        font-size: 20px;
        font-weight: bold;
      }
    }

    &__content-inputs {
      .insert-regular-expression {
        width: 100%;
        padding-bottom: 10px;

        &:last-child {
          position: relative;
          margin-bottom: 80px;
        }

        .input-insert-expression {
          width: 100%;
          height: 40px;
          border-radius: 3px;
          border: solid 1px #6E7882;
          background-color: white;
          color: #000000;
          font-weight: 300;
          font-size: 14px;
          /*opacity: 0.5;*/

          &:focus {
            outline: none;
          }
        }
      }

      .unit-test {
        width: 100%;
        padding-top: 20px;

        .input-unit-test {
          width: 100%;
          height: 100px;
          border-radius: 3px;
          border: solid 1px rgba(110, 120, 130, 0.5);
          background-color: rgba(0, 0, 0, 0.02);
          color: #000000;
          font-weight: 300;
          font-size: 14px;
          opacity: 0.5;
        }
      }

      .add-regular-expression {
        /*display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        padding-top: 20px;
        width: 100%;*/
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;

        .text-add {
          font-size: 16px;
          font-weight: 300;
          color: #000000;
          padding-left: 10px;
          padding-top: 8px;
        }

        button {
          margin-left: 10px;
          cursor: pointer;
          font-size: 20px;
        }

        .icon-add-b {
          color: #00d3d8;
        }

        .colour-disabled {
          color: #CCCCCC !important;
          opacity: 0.6;
        }
      }


      .regex-container {
        margin-top: 10px;
      }

      .regex-container, .backdrop, textarea {
        width: 94%;
        height: 180px;
        font-size: 14px;
      }

      .highlights, textarea {
        padding: 10px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
      }

      .container {
        display: block;
        margin: 0 auto;
        transform: translateZ(0);
        -webkit-text-size-adjust: none;
      }

      .backdrop {
        position: absolute;
        z-index: 1;
        background-color: #fff;
        overflow: auto;
        pointer-events: none;
        transition: transform 1s;
      }

      .highlights {
        white-space: pre-wrap;
        word-wrap: break-word;
        color: transparent;
      }

      textarea {
        display: block;
        position: absolute;
        z-index: 2;
        margin: 0;
        color: rgba(0, 0, 0, 0.5);
        overflow: auto;
        resize: none;
        transition: transform 1s;
        font-size: 14px;
        background-color: transparent;

        &::placeholder {
          color: rgba(0, 0, 0, 0.3);
        }
      }

      mark {
        border-radius: 3px;
        color: transparent;
        background-color: #b1d5e5;
      }

      textarea:focus, button:focus {
        outline: none;
      }

    }

    .design-caret-image {
      color: #6e7782;
      font-size: 22px;
      cursor: pointer;
    }

    &__content-footer {
      padding-top: 100px;
      display: flex;
      justify-content: space-between;

      .button-blue-light {
        width: 110px;
        height: 45px;
        margin-bottom: 20px;
        border: 1px solid #3DD8DB;
        background-color: #3DD8DB;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 10px 30px;
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 900;

        &:hover {
          background-color: #FFFFFF;
          color: #3DD8DB;
        }
      }

      .button-blue-cancel {
        width: 110px;
        height: 45px;
        margin-bottom: 20px;
        border: 1px solid #3DD8DB;
        background-color: #FFFFFF;
        color: #3DD8DB;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 900;

        &:hover {
          background-color: #3DD8DB;
          color: #FFFFFF;
        }
      }
    }
  }

  .ant-tabs-bar {
    border: none;


    .ant-tabs-nav-wrap {
      .ant-tabs-nav-scroll {
        .ant-tabs-nav {
          width: 100%;
          > div {
            display: flex;
            justify-content: space-between;

            .ant-tabs-tab {
              border: none;
              background: white;
              font-weight: bold;
              color: #6e7882;
              font-size: 20px;

              &.ant-tabs-tab-active {
                border-bottom: 2px solid #00d3d8;
                transition: none;
              }
            }
          }
        }
      }
    }
  }

  .ant-tabs-content {
    margin-top: 104px;

    .access-tab {
      /*background-color: #ffffff;
      padding: 50px 30px 50px 30px;
      border: 1px solid #6e7882;*/
      //padding-top: 10px;
      .switcher-select-option-duplicate {
        .switcher__sliderChecked {
          background-color: #FFFFFF;
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:before {
            content: 'On' !important;
            color: #6E7882;
            position: absolute;
            z-index: 0;
            font-size: 16px;
            font-weight: 400;
            left: 32px;
            top: 1px !important;
          }
        }
      }
      .switcher-select-option-deduplication {
        padding-left: 0;
        align-items: center;
        background-color: #ffffff;
        display: flex;
        font-size: 14px;
        position: relative;
        .switcher__slider {
          background-color: #EDA41E;
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:after {
            content: 'On';
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 400;
            position: absolute;
            right: 34px;
            top: 1px;
            z-index: 0;
          }
        }
        .switcher__sliderChecked {
          background-color: #FFFFFF;
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:before {
            content: 'Off';
            color: #6E7882;
            position: absolute;
            z-index: 0;
            font-size: 16px;
            font-weight: 400;
            left: 32px;
            top: 1px;
          }
        }
        .switcher__sliderButton {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 46px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          z-index: 1;
          border: 1px solid #6e7882;
        }
        .switcher__sliderButtonChecked {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 4px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          border: 1px solid #EDA41E;
        }
      }
      .switcher-select-option {
        padding-left: 0;
        align-items: center;
        background-color: #ffffff;
        display: flex;
        font-size: 14px;
        position: relative;
        .switcher__slider:hover {
          border: 1px solid var(--generalButtonHover);
        }
        .switcher__slider:active {
          border: 1px solid var(--generalButtonActive);
        }
        .switcher__slider {
          background-color: #ffffff;
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:after {
            content: 'Off';
            color: #6E7882;
            font-size: 16px;
            font-weight: 400;
            position: absolute;
            right: 16px;
            top: 2px;
            z-index: 0;
          }
        }
        .switcher__sliderChecked:hover {
          background-color: var(--generalButtonHover);
          border: 1px solid var(--generalButtonHover);
        }
        .switcher__sliderChecked:active {
          background-color: var(--generalButtonActive);
          border: 1px solid var(--generalButtonActive);
        }
        .switcher__sliderChecked {
          background-color: var(--generalButton);
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:before {
            content: 'YES';
            color: var( --generalButtonFont);
            position: absolute;
            z-index: 0;
            font-size: 16px;
            font-weight: 400;
            left: 14px;
            top: 2px;
          }
        }
        .switcher__sliderButton {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 3px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          z-index: 1;
          border: 1px solid #6e7882;
        }
        .switcher__sliderButtonChecked:hover {
          border: 1px solid #EDA41E;
        }
        .switcher__sliderButtonChecked:active {
          border: 1px solid #EDA41E;
        }
        .switcher__sliderButtonChecked {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 47px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          border: 1px solid #6e7882;
        }
      }
      .content-share-folder {
        width: 100%;
        margin-left: 15px;
        .left-container {
          width: 247px !important;
          .title-users {
            color: #382e34 !important;
            font-weight: 700;
          }
        }
      }
      .content-visibility-folder {
        margin-top: 40px;
        display: flex;
        width: 100%;
        //align-items: center;
        .title-folder-visibility {
          /*width: 27%;
          color: #6e7882;
          font-size: 16px;
          font-weight: bold;
          padding-top: 6px;*/
          width: 247px;
          font-family: Roboto;
          font-weight: 700;
          font-size: 14px;
          color: #382e34;
          padding-top: 4px;
        }
        .disabled-visibility {
          opacity: 0.6;
        }
        .content-functionality-visibility-folder {
          width: calc(100% - 247px);
          font-size: 14px;
          font-family: Roboto;
          font-weight: 400;
          color: #6e7882;

          .access-tab__share-folder-container:last-child .row {
            margin-bottom: 0;
          }
          .access-tab__share-and-permissions-container .row {
            margin-bottom: 0;
          }
          .ant-radio {
            height: 24px;
            margin: 0 4px 0 2px;
            padding: 4px 0;

            .ant-radio-inner {
              border: 1px solid #6e7882 !important;
            }


          }
          .ant-radio-checked {
            .ant-radio-inner {
              background-color: #04D3D8;

              &::after {
                content: "\f00c" !important;
                font-family: 'FontAwesome' !important;
                border-radius: 50%;
                transform: rotate(-5deg);
                border: none;
                color: white;
                font-size: 10px !important;
                top: 2px;
                left: 2px;
                background-color: transparent;
              }
            }
          }

        }
      }

      .access-tab__buttons-section-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 118px;
        padding-right: 12px;
        .new-button-confirm-action-save {
          width: 25%;
          height: 45px;
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 900;

          &:hover:not([disabled]) {
            background-color: #FFFFFF;
            border-color: #00D3D8;
            color: #00D3D8;
          }

          &:focus {
            border: 1px solid #00D3D8;
            background-color: #00D3D8;
            color: #FFFFFF;
          }

          &:active {
            border: 1px solid #00D3D8;
            background-color: #00D3D8;
            color: #FFFFFF;
          }
        }
      }
      .switcher-double {
        width: 164px;
        height: 37px;

        > div {
          padding: 7px 26px;
          padding: 6px 26px;
          height: 36px;
        }
      }

      .row {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
      }

      .left-container > p {
        /*color: #6e7882;
        font-size: 16px;
        font-weight: bold;*/
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px !important;
        color: #6e7882 !important;
      }
      .left-container {
        width: 247px;
        .title-visible-only-users {
          width: 400px;
        }
        .title-visible-only-users-disabled {
          font-weight: 400;
          color: #D4D7DA !important;
        }
        .title-users {
          /*opacity: 0.5;*/
          color: #6e7882;
          font-size: 14px;
          font-weight: 400;
          width: 300px;
        }
        .title-selected-users-disabled {
          opacity: 0.3;
        }
        .title-selected-users {
          width: 400px;
        }
      }
      .user-email {
        font-family: Roboto;
        color: #6e7882;
        font-size: 14px;
        font-weight: 400;
        /*opacity: 0.5;*/
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      .update-share-folder {
        .switcher-select-option .switcher__slider:after {
          content: 'No';
          color: #6E7882;
          font-size: 16px;
          font-weight: 400;
          position: absolute;
          right: 16px;
          top: 2px;
          z-index: 0;
        }
        h3 {
          margin-bottom: 10px;
        }
        span.ant-radio + * {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6E7882;
          text-transform: uppercase;
          top: 0;
        }
        .ant-radio {
          display: none;
        }
        .ant-radio-wrapper-checked {
          z-index: 2;
          span.ant-radio + * {
            font-family: Roboto;
            font-size: 18px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #FFFFFF;
            text-transform: uppercase;
          }
        }
        .ant-radio-wrapper {
          width: 100px;
          margin-right: 0;
          text-align: center;
          padding: 8px 15px;
        }
        .custom-radio__yes {
          .ant-radio-wrapper {
            margin-right: -32px;
            span.ant-radio + * {
              padding-right: 24px;
              padding-left: 8px;
            }
          }
          .ant-radio-wrapper-checked {
            span.ant-radio + * {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
        .custom-radio-disabled__yes {
          .ant-radio-wrapper-checked {
            z-index: 3;
            opacity: 3;
            background-color: #6E7882;
            span.ant-radio + * {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
        .custom-radio__no {
          .ant-radio-wrapper {
            margin-right: -32px;
            span.ant-radio + * {
              padding-right: 0;
              padding-left: 15px;
            }
          }
          .ant-radio-wrapper-checked {
            span.ant-radio + * {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
        .custom-radio-disabled__yes {
          .ant-radio-wrapper-checked {
            z-index: 3;
            opacity: 3;
            background-color: #6E7882;
            span.ant-radio + * {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
      }
      &__share-and-permissions-container {
        .ant-select-selection__clear {
          width: 2% !important;
          color: #6e7882;
          right: 10px;
          font-size: 14px;
        }
        .ant-select-selection__clear:hover {
          color: #382e34;
        }
        .left-container {
          color: #6e7882;
          font-size: 16px;
          font-weight: bold;
        }
        .right-container-update {
          .ant-select-arrow {
            top: 40%;
            .ant-select-arrow-icon {
              display: none;
            }
            //display: none !important;
          }
          .ant-select-arrow:after {
            //background-color: #b3ff00;
            border-radius: 50%;
            color: #6e7882;
            content: '\e896';
            font-family: 'smartbox_icons_v19' !important;
            display: inline-block;
            width: 17px;
            height: 17px;
            padding-top: 0px;
            padding-left: 6px;
            padding-bottom: 9px;
            font-style: normal;
            font-size: 20px;
          }
          .ant-select-clear-icon:after {
            color: #EDA41E;
          }
          .anticon-close-circle {
            display: none;
          }
          .ant-select-clear-icon {
            display: flex;
          }
          .ant-checkbox-inner {
            width: 16px !important;
          }
        }
        .right-container {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          .ant-checkbox-wrapper .ant-checkbox-inner::after {
             top: 6px !important;
             left: 3px !important;
          }
          .ant-checkbox-checked .ant-checkbox-inner::after {
            position: absolute;
            display: table;
            border: 2px solid #fff;
            border-top: 0;
            border-left: 0;
            -webkit-transform: rotate(42deg) scale(1) translate(-50%, -50%);
            -ms-transform: rotate(42deg) scale(1) translate(-50%, -50%);
            transform: rotate(42deg) scale(1) translate(-50%, -50%);
            opacity: 1;
            -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            content: ' ';
          }
          .ant-checkbox-inner::after {
            width: 5.5px;
            height: 9.5px;
          }
          .ant-checkbox-disabled {
            cursor: default;
            pointer-events: auto !important;
          }
          .ant-select {
            color: #6e7882;
          }
          .select-visible-only-users {
            .arrow-medium-active {
              //color: #6e7882;
            }
            .arrow-visible-only-users {
               position: absolute;
              left: 99%;
              font-size: 20px;
              //color: #535d66;
            }
            .icon-v17-arrow-medium:before {
              content: '\e896';
              margin-right: 5px;
              line-height: 16px;
              margin-left: 3.5px;
            }
          }
          .download-visible-users {
            .arrow-visible-download-users {
              position: absolute;
              left: 99%;
              font-size: 20px;
              //color: #535d66;
            }
            .icon-v17-arrow-medium:before {
              content: '\e896';
              margin-right: 5px;
              line-height: 16px;
              margin-left: 3.5px;
            }
          }
          .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
            //cursor: not-allowed;
            //opacity: 0.3;
          }
          .ant-checkbox-disabled .ant-checkbox-inner {
            background-color: #FFFFFF;
            border-color: #D4D7DA !important;
          }
          .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--generalIconFontActive) !important;
            border: 1px solid #6e7882;
          }
          .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: #FFFFFF !important;
          }
           .ant-checkbox-inner {
             border: 1px solid #6e7882;
           }
          .ant-select-selection:active {
            border-color: #00d4d8;
          }
          .ant-select-selection:focus {
            border-color: #00d4d8;
          }
          .ant-checkbox + span {
            padding-right: 0px;
            padding-left: 10px;
          }
          .ant-checkbox-wrapper {
            display: flex;
            margin-left: 0;
            margin-bottom: 10px;

            .ant-checkbox {
              width: 17px;

              .ant-checkbox-inner {
                border-radius: 0%;
              }
            }
            .ant-checkbox-inner::after {
              top: 46%;
              left: 19%;
            }

            .ant-checkbox-checked {
              &:after {
                border: none;
              }

              .ant-checkbox-inner {
                background: #00d3d8;
              }
            }

            span {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .ant-select-arrow {
              display: inline-block;
              width: unset;
            }

            .ant-select {
              width: inherit;
              margin: 0px;
            }
            .ant-checkbox-disabled + span {
              cursor: default;
              pointer-events: auto !important;
            }
            .ant-select-disabled .ant-select-selection {
              background: #FFFFFF;
              cursor: default;
              pointer-events: auto !important;
              border: 1px solid #D4D7DA !important;
              color: #D4D7DA;
            }
            .ant-select-disabled {
              .ant-select-arrow:after {
                color: #D4D7DA;
              }
            }
            .ant-select-selection {
              box-shadow: none;
              border: 1px solid #6e7882;
              border-top: 1px solid #6e7882;
              border-radius: 0px;

              &:hover {
                border-color: #EDA41E;
              }
            }

            .ant-select-selection--single {
              height: 40px;
            }

            .ant-select-selection__rendered {
              display: flex;
              height: 100%;
              align-items: center;
              margin-left: 10px;
              margin-right: 25px;

              .ant-select-selection-selected-value {
                padding-left: 0px;
                //color: #6e7882;
              }
            }

            &:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
              border-color: var(--generalIconFontHover);
            }
          }

          .ant-select-open .ant-select-selection {
            border-color: rgba(110, 120, 130, 0.4);
          }
          .content-disabled {
            button:disabled {
              cursor: not-allowed;
              color: #CCCCCC;
            }
          }

          .action-buttons-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-right: 0px;
          .icon-v16-minus {
            font-size: 25px;
            color: #6e7882;
          }
            .icon-v16-minus-active {
              font-size: 25px;
              color: #6e7882;
            }
            .icon-v16-add-active {
              font-size: 25px;
              color: #EDA41E;
            }
            .icon-v16-add {
              font-size: 25px;
              color: #EDA41E;
            }
            button:disabled {
              cursor: not-allowed;
              opacity: 0.3;
              color: #6e7882;
            }

            button {
              //margin-left: 10px;
              cursor: pointer;
              font-size: 25px;
              margin-top: 0px;
            }

            .icon-add-b {
              color: #00d3d8;
            }
            .icon-v16-add {
              color: #EDA41E;
            }
          }

          .user-team-select {
            .ant-select-arrow {
            }
          }

          .permissions-select {
            .ant-select-selection {
              background-color: rgba(0, 0, 0, 0.02);
            }
          }
        }
      }
    }
  }
}

.redaction-settings-tab {
  .child-tabs {
    .ant-tabs-content {
      margin-top: 50px;
    }
    .ant-tabs-bar {
      border: none;
      margin-bottom: 0;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-scroll {
          .ant-tabs-nav {
            width: 70%;

            > div {
              justify-content: center;
              .ant-tabs-tab {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }
}
.insight_wrapper {
  //padding: 0px 40px;
  margin-top: 100px;
  width: 100%;
  background-color: #FFFFFF;

  .update-tabs-deduplicate {
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
      cursor: not-allowed;
      color: #D4D7DA !important;
    }
    .duplicate-class {
      //padding: 0px 40px 0px 40px;
      &:hover{
        color: var(--generalIconFontActive);
      }
    }
    .ant-tabs-nav-wrap {
      //height: 25px; TASK 6 GRAV 2583
      height: 20px;
    }
    .ant-tabs-bar {
      height: 34px;
      padding: 4px 40px 4px 5px;
      background-color: #FAFAFA;
      width: calc(100% - 0px);
      position: fixed;
      z-index: 2;

    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
      border-bottom: none;
    }

    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
      margin-top: 3px;
    }
    .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: none;
      //padding: 0px 40px 0px 35px;
      margin-top: 0px;
      border-right: 1px solid #a6acb2;
    }
    &:hover{
      color: #EDA41E;
    }
    .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab.ant-tabs-tab:nth-child(4) {
      border-right: unset !important;
    }
    .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab.ant-tabs-tab:nth-child(3) {
      .content-error-tabs {
        display: flex;
        align-items: center;
      }
    }
    .content-name-tabs {
      .border-tab {
        //border-right: 2px solid #6e7882;
        //margin-right: 20px;
      }
    }
    .content-error-tabs {
      .border-tab-error {
        //border-left: 1px solid #a6acb2;
        //padding: 0px 40px 0px 40px;
        //border-right: 2px solid #6e7882;
        //margin-right: 20px;
      }
      .content-dots-report {
        display: flex;
        .dots-report {
          width: 10px;
          height: 10px;
          background-color: #EDA41E;
          display: flex;
          border-radius: 50%;
          margin-left: 3px;
        }
      }
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
      height: 25px;
    }

    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      line-height: 27px;
      margin: 0px;
      padding: 0 40px 0 35px;
      border-radius: 0px;
      height: 20px;
    }
    .child-tabs-data .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
      opacity: 1;
      &:hover{
        color: var(--generalIconFontActive);
      }
    }
  }
  .update-tabs-deduplicate-new {
    .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab.ant-tabs-tab:nth-child(3) {
      border-right: unset !important;
    }
  }
  .ant-tabs-bar {
    border-bottom: 1px solid #a6acb2;
  }
  .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab.ant-tabs-tab-active {
    //border-bottom: 2px solid #00d3d8;
    transition: none;
    color: var(--generalIconFontActive);
  }
  .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab :first-child.ant-tabs-tab-active{
    padding: unset !important;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    border-bottom: 1px solid #a6acb2;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 40px;
    width: 100%;
  }
  .child-tabs-data {
    z-index: 1;
    .ant-tabs-bar {
      //width: 30%;
      border-right: 1px transparent;
    }
  }
}

.regular-selected-color {
  position: relative;
  width: 17px;
  height: 17px;
  margin-top: 3px;
  margin-right: 12px;
  float: left;
  cursor: pointer;

}
.icon-delete-b {
  font-size: 18px;
  cursor: pointer;
}
.child-tabs {
 .access-expressions {
   &___contain-regular-expressions {
     .row-icons {
       margin-right: 3px;
     }
   }
   &__content-radio {
     .tracker-radio-check {
       .ant-collapse {
         width: 100%;
         border: none;
         .radio-style {
           display: inline;
         }
         .ant-collapse-item {
           border: none;
           .ant-collapse-header {
             border-bottom: 1px solid #cccccc;
             padding-right: 20px;
             .ant-collapse-arrow {
               right: 0;
             }
             .anticon {
               color: #6e7782;
               font-size: 20px;
             }
           }
           .ant-collapse-content {
             .input-unit-test {
               width: 91%;
             }
             .panel-container {
               display: flex;
               justify-content: space-between;
               font-size: 16px;
               font-family: Roboto;
             }
           }
         }
       }

     }
   }
 }
}
.edit_form {
  .input-insert-expression {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px rgba(110, 120, 130, 0.5);
    background-color: rgba(0, 0, 0, 0.02);
    color: #000000;
    font-weight: 300;
    font-size: 14px;
  }
  .input-unit-test {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    height: 120px !important;
    border-radius: 3px;
    border: solid 1px rgba(110, 120, 130, 0.5);
    background-color: rgba(0, 0, 0, 0.02);
    color: #000000;
    font-weight: 300;
    font-size: 14px;
  }
  .button-container {
    text-align: right;
  }
  &__label {
    color: #6e7782;
    font-family: Roboto;
    font-weight: 300 !important;
    font-size: 14px !important;
    margin: 0 !important;
  }
}
.regex-container {
  #text-area-2 {
    width: 91%;
  }
}

@media screen and (max-width: 700px) {
  .access-tab {
    .content-radio-share-folder {
      display: flex;
      width: 30%;
    }
  }
}
@media screen and (min-width: 768px) {
  .access-tab {
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 30%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 70%;
    }
  }
}

.content-radio-configuration {
  height: 130px;

  .ant-radio-wrapper {
    margin-bottom: 10px;
  }
  .descriptions-radio {
    display: flex;
    font-style: italic;
    padding-left: 24px;
    font-weight: 300;
    color: #a6acb2;
    font-size: 14px;
    margin-top: 5px;
  }
  .title-radio {
    font-weight: 400;
    color: #6e7882;
    font-size: 14px;
  }
}

.tabs-analysis-settings {
  .no-action {
    //opacity: 30%;

    .ant-radio, .ant-radio-wrapper, .switcher__slider, button {
      cursor: not-allowed !important;
    }
  }
}
.commonShell-main.box-settings-page {
  background-color: #ffffff !important;
}

.child-tabs.tabs-analysis-settings .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div {
  display: block !important;
}