.inputValidator__invalidWrapper {
input, select, .multiselect dt {
  color: #f44336 !important;
  border: 1px solid #ffaeae !important;
  background-color: #fff3f3;
}
.multiSelectOpened dd span {
  display: inline;
}
span {
  color: #f44336;
  display: block;
  padding-top: 8px;
}
}