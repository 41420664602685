.fields-container {
  width: 100%;
  &__head {
    cursor: pointer;
    border-bottom: 1px solid #6e7882;
    &__input {
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6e7882;
      width: 95%;
      padding-left: 0;
      &::placeholder {
        opacity: 0.6;
        font-style: italic;
      }
      &:focus {
        outline: none;
      }
    }
    &__icon {
      font-size: 12px;
      text-align: right;
    }
  }
  &__popup {
    width: 88%;
    position: absolute;
    z-index: 5;
    border: 2px solid #00d3d8;
    padding: 3px 10px 15px 10px;
    background-color: #ffffff;
    box-shadow: 3px 3px #dddddd;
    .item {
      padding-bottom: 5px;
      padding-top: 5px;
      display : flex;
      justify-content: space-between;
      &:last-of-type {
        padding-bottom: 0;
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        background-color: #d8d8d8;
      }
      &__option-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        cursor: pointer;
        width: 100%;
        display: block;
      }
      input {
        border: none;
        background-color: transparent;
        height: 15px;
        padding-left: 0;
        width: 90%;
        border-bottom: 2px solid #00d3d8;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.81;
        letter-spacing: normal;
        color: #000000;
        &::placeholder {
          font-size: 15px;
          color: #bfbfbf;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .add {
      background-image: url("../../assets/data-source-icons/add.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 15px;
      height: 15px;
      float: right;
      cursor: pointer;
    }
    .minus {
      background-image: url("../../assets/data-source-icons/minus.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 15px;
      height: 15px;
      float: right;
      cursor: pointer;
    }
    .circle-fill {
      background-image: url("../../assets/data-source-icons/circle-fill.svg");
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      width: 15px;
      height: 15px;
    }
  }
}