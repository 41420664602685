.area-wrapper {
  display: flex;
  margin-bottom: 40px;
&:last-of-type {
   margin-bottom: 0;
 }
}

.area-info {
  margin-left: 25px;
  flex: 1;
}

.area-title {
  color: #111C26;
  margin-bottom: 5px;
}

.area-description {
  color: #737E89;
}

.iconAreaCompliance {
  background: url('../../assets/area_sprites.png') -10px -10px;
  height: 42px;
  width: 42px;
}

.iconAreaDataControl {
  background: url('../../assets/area_sprites.png') -72px -10px;
  height: 42px;
  width: 42px;
}

.iconAreaDataSharing {
  background: url('../../assets/area_sprites.png') -10px -72px;
  height: 42px;
  width: 42px;
}

.iconAreaSecurity {
  background: url('../../assets/area_sprites.png') -10px -134px;
  height: 42px;
  width: 42px;
}

.iconAreaSensitivity {
  background: url('../../assets/area_sprites.png') -134px -10px;
  height: 42px;
  width: 42px;
}

.iconAreaTransborder {
  background: url('../../assets/area_sprites.png') -134px -72px;
  height: 42px;
  width: 42px;
}

.iconAreaTransparency {
  background: url('../../assets/area_sprites.png') -72px -72px;
  height: 42px;
  width: 42px;
}