.input_field_edit_user {
    height: 4.5vh;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #cccccc;
    color: #555;
    padding: 0 10px;
}
.input_field:focus {
    outline: 2px solid #3DD8DB;
}
.input_field_email_address {
  width: 49%;
}
#field_email{
  color:green;
}
.select {
  margin-bottom: 20px;
  width: 100%;
}
.select select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  color: #000;
  font-size: 16px;
  height: 38px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
  font-family: 'Roboto';
  font-weight: 400;
}
.select select:focus {
  outline: 2px solid #3DD8DB;
}
.select_summary_frequency select {
    display: block;
    width: 23%;
}
h2.subtitle {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.editUser__title {
  font-family: 'Ubuntu';
  font-size: 24px;
  font-weight: 300;
}
.editUser__title b {
  font-weight: 500;
}
.switcher-option-user-mfa {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  font-size: 14px;
  position: relative;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  button:disabled {
    border: 1px solid #6e7882 !important;
  }
  .ant-switch {
    min-width: 70px;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #6e7882;
  }
  .ant-switch-checked .ant-switch-inner {
    margin-right: 0px;
    margin-left: 23px;
    margin-top: 1px;
    font-size: 15px;
    color: #ffffff;
    font-weight: 400;
  }
  .ant-switch-inner {
    display: block;
    margin-right: 20px;
    margin-left: 0px;
    color: #6e7882;
    font-size: 15px;
    font-weight: 400;
    margin-top: 1px;
  }
  .ant-switch-checked {
    background-color: #3DD8DB;
  }
  .ant-switch::after {
    left: 42px;
    width: 22px;
    height: 22px;
    border: 1px solid #3DD8DB;
    top: 2px;
  }
  .ant-switch-checked::after {
    left: 40%;
    width: 22px;
    height: 22px;
    top: 3px;
    margin-left: -2px;
    border: 1px solid #6e7882;
  }
}
.content-button-reset-MFA {
  //width: 33%;
  margin-left: 50px;
  .button {
    padding: 3px 30px !important;
  }
  button:disabled {
    border: 1px solid #D4D7DA !important;
    color: #D4D7DA !important;
    cursor: default !important;
  }
  .button-blue-reset:disabled {
    border: 1px solid #6e7882;
    background-color: #6e7882;
    color: #FFFFFF;
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      border: 1px solid #6e7882;
      background-color: #6e7882;
      color: #FFFFFF;
      opacity: 0.4;
    }
  }
  .button-blue-reset {
    border: 1px solid #6e7882;
    background-color: #FFFFFF;
    color: #6e7882;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    padding: 2px 30px;
    //text-transform: uppercase;
    border-radius: 25px;
    float: right;
    width: 170px;
    height: 25px;
    &:hover {
      background-color: #FFF;
      color: var(--generalButtonHover);
      border: 1px solid var(--generalButtonHover);
    }
    &:active {
      background-color: #FFF;
      color: var(--generalButtonActive);
      border: 1px solid var(--generalButtonActive);
    }
  }
}
.editUser__form > .cont {
  padding: 30px;
  border-bottom: 1px solid #e6e6e6;
  .manageGroups-sliderChecked {
    background-color: #3DD8DB;
    border-radius: 34px;
    height: 34px;
    position: relative;
    width: 110px;
    margin-left: 0;
    .manageGroups-sliderButtonChecked {
      background-color: #FFFFFF;
      border-radius: 50px;
      cursor: pointer;
      display: block;
      position: absolute;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      z-index: 1;
      height: 15px;
      left: 83px;
      top: 9px;
      width: 15px;
    }
    &:before {
      content: 'ADMIN';
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 700;
      left: 10px;
      position: absolute;
      top: 11px;
      z-index: 0;
      letter-spacing: 0.5px;
    }
  }
  .manageGroups-slider {
    background-color: #eeeeee;
    border-radius: 34px;
    height: 34px;
    position: relative;
    width: 110px;
    margin-left: 0;
    cursor: pointer;
    .manageGroups-sliderButton {
      background-color: #FFFFFF;
      border-radius: 50px;
      cursor: pointer;
      display: block;
      position: absolute;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      z-index: 1;
      height: 15px;
      left: 10px;
      top: 9px;
      width: 15px;
    }
    &:after {
      content: 'USER';
      color: #9999ae;
      font-size: 14px;
      font-weight: 700;
      position: absolute;
      right: 15px;
      top: 11px;
      z-index: 0;
      letter-spacing: 0.5px;
    }
  }
}
.editUser__form label {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  //margin-bottom: 8px !important;
}
.editUser__form .subtitle {
  font-family: 'Ubuntu';
  font-size: 18px; 
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 0;
}
.editUser__page .newQuestionnaire__header {
  padding: 30px;
}
.editUser-update {
  margin: 100px 100px 0 !important;
  padding: 30px 0px !important;
  max-width: 100% !important;
  .newQuestionnaire__section-update {
    .editUser__title {
      font-family: Roboto;
    }
    .input_field_email_address {
        color: #D4D7DA !important;
        border-color: #D4D7DA !important;
      cursor: default;
    }
    .upload-title {
      color: #6e7882 !important;
      margin-bottom: 10px;
      line-height: 20px;
      .styleSpan1 {
        color: #6e7882;
        font-size: 14px;
      }
    }
    .content-option-roles {
      .select-role-user {
        border-radius: 0px;
        height: 40px;
        position: relative;
        width: 300px;
        padding: 7px 10px;
        line-height: 40px;
        border: 1px solid #382e34;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #382e34;
        .ant-select-selection-selected-value {
          color: #382e34;
          font-family: Roboto;
          font-weight: 400;
          font-size: 14px;
          width: 100%;
        }
        .content-value-role {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon-v19-arrow-medium-rotate {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
          }
          .icon-v19-arrow-medium {
            color: #382e34;
            font-size: 20px;
          }
        }
      }
      .select-role-user:hover {
        border: 1px solid #EDA41E;
        color: #382e34;
      }
      .select-role-user:active {
        border: 1px solid #EDA41E;
        color: #382e34;
      }
      .select-role-user:focus {
        border: 1px solid #EDA41E;
        color: #382e34;
      }
      .ant-select-selection {
        border: none;
        width: 300px;
      }
      select {
        //background-color: #FFFFFF;
        border-radius: 0px;
        height: 40px;
        position: relative;
        width: 300px;
        padding: 7px 10px;
        line-height: 40px;
        border: 1px solid #382e34;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #382e34;
      }
      select:hover {
        border: 1px solid #EDA41E;
        color: #382e34;
      }
      select:active {
        border: 1px solid #EDA41E;
        color: #382e34;
      }
      select:focus-visible {
        border: 1px solid #EDA41E;
        color: #382e34;
      }
      select:disabled {
        color: #6e7882;
        cursor: default;
      }
      select {
        background: none !important;
      }
    }
    .content-button-reset-MFA {
      .button {
        background-color: #FFFFFF;
        border: 1px solid #6e7882;
        border-radius: 50px;
        box-shadow:none;
        color: #6e7882;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        padding: 5px 30px;
        width: 170px;
        height: 25px;
        &:hover {
          border: 1px solid #FFC457;
          color: #FFC457;
        }
        &:focus {
          border: 1px solid #EDA41E;
          color: #EDA41E;
        }
      }
    }

    .userSettings-footer {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      margin-bottom: 90px;
      .userSettings-footerLink {
        color: #6e7882;
        border: 1px solid #6e7882;
        height: 25px;
        width: 170px;
        align-items: center;
        line-height: 18.75px;
        text-align: center;
        padding: 3px;
        border-radius: 25px;
        text-decoration: none;
      }
      .userSettings-footerLink:hover {
        color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
      }
      .userSettings-footerLink:active {
        color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
        background-color: #FFFFFF !important;
      }
      .button-editProfile {
        background-color: var(--generalButton);
        color: var(--generalButtonFont);
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: none;
        cursor: pointer;
        font-family: ROBOTO;
        font-weight: 400;
        font-size: 16px;
        text-transform: none;
        transition: all .2s;
        cursor: pointer;
        padding: 3px !important;
        width: 170px;
        height: 25px;
        line-height: 18.75px;
        margin: 0px;
        margin-right: 30px;
      }
      .button-editProfile:hover {
        background-color: var(--generalButtonHover);
        //color: #FFFFFF;
        border: 1px solid var(--generalButtonHover);
      }
      .button-editProfile:active {
        background-color: var(--generalButtonActive);
        //color: #FFFFFF;
        border: 1px solid var(--generalButtonActive);
      }
    }
    .editUser__form > .cont {
      padding: 0px;
      border-bottom: none;
      .col-md-12 {
        padding-left: 0px;
      }
    }
    .row {
      margin-bottom: 30px;
      flex-wrap: nowrap;
      margin-left: 0px;
      margin-right: 0px;
    }
    .col-md-3-update {
      margin-right: 30px;
      //flex: none;
      padding-right: 0px;
      padding-left: 0px;
      width: 100%;
    }
    .col-md-6 {
      padding-left: 0px;
    }
    .editUser__form {
      label {
        font-size: 14px;
        //margin-bottom: 10px !important;
        color: #6e7882;
        margin-bottom: 10px !important;
        line-height: 20px;
      }
    }
    .switcher-option-user-mfa {
      font-size: 16px;
      font-weight: 400;
      .ant-switch {
        width: 70px;
        height: 25px;
        border: 1px solid #6e7882;
        &:hover {
          border: 1px solid var(--generalButtonHover);
        }
      }
      .ant-switch:after {
        left: 3px;
        width: 19px;
        height: 19px;
        border: 1px solid #6e7882;
        border-radius: 50px;
        box-shadow: none;
      }
      .ant-switch-inner {
        margin-left: 32px;
        font-size: 16px;
      }
      .ant-switch:hover {
        .ant-switch:after {
          left: 3px;
          width: 19px;
          height: 19px;
          border: 1px solid #EDA41E;
          border-radius: 50px;
        }
      }
      .ant-switch-checked {
        background-color: var(--generalButton);
        &:hover {
          background-color: var(--generalButtonHover);
          border: 1px solid var(--generalButtonHover);
          color: var(--generalButtonFont);
        }
        .ant-switch-inner {
          margin-left: -9px;
          font-size: 16px;
          letter-spacing: 0.5px;
        }
      }
      .ant-switch-checked::after {
         width: 19px;
        height: 19px;
        top: 2px;
        margin-left: 63px;
      }
    }
    .input_field_edit_user {
      height: 40px;
      border-radius: 0px;
      border: 1px solid #6e7882;
      padding: 0 10px;
      background-color: #FFFFFF;
      color: #6e7882;
      font-size: 16px;
      font-weight: 400;
    }
    .input_field_edit_user:hover {
      border: 1px solid #EDA41E;
      border-radius: 0px;
      box-shadow: none;
    }
    .input_field_edit_user:focus {
      border: 1px solid #EDA41E;
      border-radius: 0px;
      box-shadow: none;
    }
    .input_field_edit_user:active {
      border: 1px solid #EDA41E;
      border-radius: 0px;
      box-shadow: none;
    }
    .input_field_edit_user:focus-visible {
      border: 1px solid #EDA41E !important;
      border-radius: 0px !important;
      box-shadow: none;
    }
    .input_field_email_address {
      width: 100%;
    }
    .editUser__form > .cont .manageGroups-sliderChecked:before {
      content: 'ADMIN';
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 400;
      left: 27px;
      position: absolute;
      top: 10px;
      z-index: 0;
      letter-spacing: 0.5px;
    }
    .editUser__form > .cont .manageGroups-slider:after {
      content: 'USER';
      color: #9999ae;
      font-size: 16px;
      font-weight: 400;
      position: absolute;
      right: 37px;
      top: 9px;
      z-index: 0;
      letter-spacing: 0.5px;
    }
    .label-factor {
      font-family: Roboto;
      font-size: 14px !important;
      font-weight: 700;
      //margin-bottom: 10px !important;
      margin-top: 10px;
      color: #382e34 !important;
      margin-bottom: 20px !important;
    }
    .cont-user-role {
      .row {
        //margin-bottom: 40px;
      }
    }
    .editUser__form .subtitle {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 0;
      color: #382e34;
    }
    .styleUploadImage {
      margin-bottom: 60px;
      .teamIconImage {
        margin-right: 30px;
        border: 1px solid #6e7882;
      }
      .m-3 {
        margin: 0rem !important;
      }
      .fileContainer p {
        font-size: 20px;
        margin: 0px;
        font-family: Roboto;
        font-weight: bold;
      }
      .float-left {
        height: 100px;
        display: flex;
        align-items: center;
      }
      .chooseFileButton {
        background-color: #FFFFFF;
        border: 1px solid #6e7882;
        color: #6e7882;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        height: 25px;
        line-height: 18.75px;
        width: 170px;
        padding: 3px;
        margin: 0px 0;
      }
      .chooseFileButton:hover {
        background-color: #FFFFFF;
        border: 1px solid var(--generalButtonHover);
        color: var(--generalButtonHover);
      }
      .chooseFileButton:active {
        background-color: #FFFFFF;
        border: 1px solid var(--generalButtonActive);
        color: var(--generalButtonActive);
      }
      .styleSpan1 {
        color: #382e34;
        font-weight: 700;
        line-height: 20px;
      }
      .upload-title {
        margin-bottom: 30px;
        margin-top: 10px;
      }
      .m-3 {
        margin: 0px;
      }
    }
  }
 .newQuestionnaire__header {
   padding: 0px;
   align-items: center;
   display: flex;
   height: 20px;
   margin-bottom: 40px;
   border-bottom: none;
   justify-content: flex-start;
   padding: 0px;
   .editUser__title {
     font-size: 20px;
     font-weight: 700;
     line-height: 20px;
     color: #382e34;
     b {
       font-weight: 400;
     }
   }
   .pagination__nav.fa-angle-left:before {
     font-weight: 400;
     font-size: 20px;
   }
   .pagination__nav:hover {
     background-color: var(--generalIconFontHover);
     border: 1px solid var(--generalIconFontHover)!important;
     color: #FFFFFF !important;
     .fa-angle-left {
       color: #FFFFFF;
     }
   }
   .pagination__nav:active {
     background-color: var(--generalIconFontHover);
     border: 1px solid var(--generalIconFontHover) !important;
     color: #FFFFFF !important;
     .fa-angle-left {
       color: #FFFFFF;
     }
   }
   .pagination__nav:focus {
     background-color: var(--generalIconFontHover);
     border: 1px solid var(--generalIconFontHover) !important;
     color: #FFFFFF !important;
     .fa-angle-left {
       color: #FFFFFF;
     }
   }
   div.newQuestionnaire__submitButtons .pagination__nav {
     border-radius: 100%;
     width: 25px;
     height: 25px;
     margin: 0px 10px 0px 0;
     border: 1px solid #382e34;
     color: #382e34;
     padding: 1px 7px;
     font-weight: 400;
     box-shadow: none;
   }
 }
}
.editUser__page {
    margin: 0 auto;
    max-width: 1200px;
    padding: 80px 20px 0;
}
.inline-icon{
  display:inline-block;
  vertical-align: middle;
  margin: 0 10px;

}
.editUser__page .fileContainer .chooseFileButton { margin-top: 0; }
.teams-select select { width: 195px; margin-bottom: 20px;}
.teams-select { display:inline; }
.text-gray {
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto';
  color: #737e89;
}
.goBack button{
  width: 60px;
}
.editProfile__submitButtons {

  display: flex;
  flex-direction: row;

}
div.editProfile__submitButtons .pagination__nav {
  margin: 0 5px;
  border-radius: 30px;
}
.pagination__nav:hover {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .editUser__title {
    font-family: 'Ubuntu';
    font-size: 20px;
    font-weight: 200;
}

.editProfile__submitButtons {

  display: flex;
  flex-direction: row;

}
.pagination__nav {

  margin: 9px 5px;
}
#field-frequency {

  width: 49%;
}

}
.dropdown-role-user {
  border: 1px solid #EDA41E !important;
  color: #382e34 !important;
  box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3) !important;
  .ant-select-dropdown-menu {
    padding: 0px 0 !important;
  }
  .ant-select-dropdown-menu-item {
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 5px 10px !important;
    color: #382e34 !important;
  }
}