:root {
  --navigationBackground: #382E34;
  --navigationBackgroundHover: #150010;
  --navigationBodyFont: #ffffff;
  --navigationBodyFontHover: #EDA41E;
  --subNavigationBackground: #665360;
  --subNavigationBackgroundHover: #4d3e47;
  --subNavigationBodyFont: #ffffff;
  --subNavigationBodyFontHover: #EDA41E;
  --subNavigationBodyFontActive: #EDA41E;
  --generalIconFontHover: #EDA41E;
  --generalIconFontActive: #EDA41E;
  --generalButton: #EDA41E;
  --generalButtonHover: #FFC457;
  --generalButtonActive: #C4812F;
  --generalButtonFont: #ffffff;
}
.input-login-update {
  border: none !important;
  background-color: var(--generalButton) !important;
  color: var(--generalButtonFont) !important;
}
.input-login-update:hover {
  background-color: var(--generalButtonHover) !important;
}
.input-login-update:active {
  background-color: var(--generalButtonActive) !important;
}
.input-login-update:active {
  color: var(--generalButtonFont) !important;
}
.reset-password-button {
  background-color: var(--generalButton) !important;
  color: var(--generalButtonFont) !important;
}
.reset-password-button:hover {
  background-color: var(--generalButtonHover) !important;
}
.reset-password-button:active {
  background-color: var(--generalButtonActive) !important;
}
.reset-password-button:disabled {
  border: 1px solid #CCC;
  background-color: #eee !important;
  cursor: not-allowed;
  color: #CCC !important;
}
.loginPage-footerForm a {
  color: var(--generalButton) !important;
  font-size: 14px;
  text-decoration: none;
  font-weight: 300;
  font-family: Roboto;
}
.recover-button {
  color: var(--generalButton) !important;
}
.help-message-locations {
  .content-help-message-locations {
    .text-information {
      font-size: 14px;
      font-weight: 400;
      margin-right: 3px;
    }
    .more-info-link {
      font-weight: 700;
      font-size: 14px;
      color: #00d4d8;
      cursor: pointer;
      text-decoration: underline;
      width: 65px;
      margin-left: 3px;
    }
  }
}