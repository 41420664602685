.component-form {
   display: flex;
  font-family: Roboto;
  height: auto;
  //max-height: 50px;
  align-items: center;
  margin-left: 10px;
  background-color: #F5F5F5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px solid #6e7882;
  .component-delete {
    width: 4%;
    display: flex;
    button:disabled {
      background-color: #F5F5F5;
      color: #d4d7da;
    }
   .icon-v19-delete {
     font-size: 18px;
     color: #382e34;
   }
    .icon-v19-delete-active {
      font-size: 18px;
      color: var(--generalIconFontHover);
    }
  }

  .component-content {
    width: 94%;
    display: flex;
    flex-direction: column;
    //position: relative;
    .content-input-form-variables {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 0px;
      justify-content: flex-end;
      height: 20px;
      .header-checkbox {
        display: flex;
        justify-content: flex-end;
        width: 46%;
        align-items: center;
        .ant-checkbox + span {
          font-size: 12px;
          font-family: Roboto;
          color: #382e34;
          font-weight: 700;
        }
        .ant-checkbox-wrapper .ant-checkbox-inner {
          border-radius: 0% !important;
          border: 1px solid #6e7882;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
          border: 1px solid #fff;
          border-top: 0;
          border-left: 0;
          top: 46%;
          left: 19%;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
          border-color: #EDA41E;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
          border-color: #FFFFFF;
          background-color: #FFFFFF;
          color: #FFFFFF;
          border: none !important;
          border-top: 0;
          border-left: 0;
          -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          transform: rotate(0deg) scale(1) translate(-50%, -50%);
          opacity: 1;
          -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          content: '\e8ce' !important;
          top: 7px !important;
          left: 7px !important;
          font-size: 12px !important;
          font-family: 'smartbox_icons_v19' !important;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
          background-color: var(--generalIconFontActive) !important;
          border: 1px solid #6e7882;
        }
      }
      .content-variables {
        position: absolute;
        left: -3%;
        display: flex;
        align-items: center;
        min-width: fit-content;
        width: 70%;
        .variables-diaz {
          padding: 10px;
          padding-right: 0px;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 400;
          display: flex;
          font-family: Roboto;
          height: auto;
          align-items: center;
          margin-left: 10px;
        }
        .input-form-variables {
          //width: 200px;
          //border: 1px solid #382e34;
          min-width: 100px !important;
          background-color: #F5F5F5;
          border-radius: 0px;
          margin-left: 2px;
          color: #382e34;
          padding-left: 2px;
          font-weight: 400;
          height: 20px;
          border: 1px solid #F5F5F5;
          &:hover {
            border: 1px solid #F5F5F5;
            box-shadow: none;
            color: #eda41e;
            //border: 1px solid #000000;
            //box-shadow: none;
          }
          &:focus {
            border: 1px solid #F5F5F5;
            box-shadow: none;
            color: #eda41e;
            //border: 1px solid #000000;
            //box-shadow: none;
          }
          &:active {
            border: 1px solid #F5F5F5;
            box-shadow: none;
            color: #eda41e;
            //border: 1px solid #000000;
            //box-shadow: none;
          }
        }
      }
    }
    .paragraph-content {
      margin-bottom: 10px;
      .paragraph-form-name {
        border: 1px solid #D4D7DA;
        color: #6e7882;
        background-color:#FFFFFF40;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 0px;
        min-height: 30px;
        cursor:default;
        &:hover {
          border: 1px solid #D4D7DA;
          box-shadow: none;
        }
        &:focus {
          border: 1px solid #D4D7DA;
          box-shadow: none;
        }
        &:active {
          border: 1px solid #D4D7DA;
          box-shadow: none;
        }
      }
      .label-input {
        font-size: 12px;
        font-family: Roboto;
        color: #6e7882;
        font-weight: 400;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
      }
    }
    .paragraph-content.paragraph-content-update {
      width:49%;
      .paragraph-form-name {
        height: 30px;
      }
      .shortText-character-count {
        display: flex;
        justify-content: flex-end;
        margin-top: 2px;
        color:#d4d7da;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .signature-content {
      height: 100px;
      padding: 10px;
      border: 1px solid #E6E6E6;
      background-color: #efeff0;
      width: 50%;
      margin-bottom: 0px;
      .signature-disabled {
        border: 1px solid #E6E6E6;
        background-color: #ffffff40;
        width: 100%;
        height: 70%;
      }
      button:disabled {
        background-color: #FFFFFF40;
        border: 1px solid #CCCCCC !important;
        cursor: default;
        color: #CCCCCC;
        &:hover {
          background-color: #FFFFFF40;
          border: 1px solid #CCCCCC;
          cursor: default;
          color: #CCCCCC;
        }
      }
      .button-small-blue-empty {
        background-color: #FFFFFF;
        border: 1px solid #3DD8DB;
        color: #3DD8DB;
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        width: auto;
        cursor: pointer;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: 14px;
        padding: 4px 20px;
        text-transform: uppercase;
        &:hover {
          background-color: #3DD8DB;
          color: #FFFFFF;
        }
      }
      .button-add-signature {
        background-color: #FFFFFF;
        border: 1px solid #EDA41E;
        color: #EDA41E;
        border-radius: 20px;
        //box-shadow: 0 2px 4px 0 #DCDCDC;
        width: auto;
        cursor: pointer;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        padding: 4px 20px;
        //text-transform: uppercase;
        //margin-top: 5px;
        //margin-right: 10px;
         margin: 5px 20px;
        &:hover {
          background-color: #EDA41E;
          color: #FFFFFF;
        }
      }
      .button-small-red-empty {
        background-color: #FFFFFF;
        border: 1px solid #bd2130;
        color: #bd2130;
        border-radius: 20px;
        //box-shadow: 0 2px 4px 0 #DCDCDC;
        width: auto;
        cursor: pointer;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        padding: 4px 20px;
        text-transform: none;
        margin: 5px;
        &:hover {
          background-color: #bd2130;
          color: #cccccc !important;
        }
      }
      .signature {
        border: 1px solid #E6E6E6;
        background-color: #ffffff;
        width: 100%;
        height: 80%;
      }
    }
    .header-component-new {
      justify-content: flex-start !important;
      flex-direction: column;
    }
    .header-component {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      .input-other-option {
        width: 96%;
        height: 30px;
        padding: 4px 10px;
        color: #382e34;
        border: 1px solid #6e7882;
        border-radius: 0px;
        font-weight: 400;
        &:hover {
          border: 1px solid var(--generalIconFontHover);
          box-shadow: none;
        }
        &:focus {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:active {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
      }
      .header-content {
        button:disabled {
          background-color: #F5F5F5;
          color: #d4d7da;
        }
        .icon-v21-edit-active {
          color: var(--generalIconFontHover);
          cursor: pointer;
        }

      }
      .subheader-content {
        margin-top: 10px;
        button:disabled {
          background-color: #F5F5F5;
          color: #d4d7da;
        }
        .icon-v21-edit-active {
          color: var(--generalIconFontHover);
          cursor: pointer;
        }
        .header-title {
          font-size: 14px;
          font-family: Roboto;
          color: #382e34;
          font-weight: 400;
          margin-bottom: 10px;
        }
        .icon-v21-edit {
          color: #382e34;
        }
      }
      .header-title {
        font-size: 12px;
        font-family: Roboto;
        color: #382e34;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .header-title.header-title-update {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
        letter-spacing: 0.2px;
      }
    }
    .header-component.header-component-update {
      flex-direction: column;
      //justify-content: space-between;
      margin-bottom: 5px;
      color:#000;
      font-size: 18px;
      margin-top: 5px;
    }
    .radio-content {
      display: flex;
      flex-direction: column;
      .content-options {
        padding: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        //width: 300px;
        //align-items: center;
        .content-info-dynamic {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          .dynamic-form {
            .dynamic {
              font-size: 15px;
              color: #382e34;
              font-weight: 500;
            }
            .icon-v211-file-redacted {
              color: #6e7882;
              font-weight: 400;
              font-size: 14px;
            }
          }
          .info-dynamic {
            margin-top: 10px;
            font-size: 14px;
            color: #382e34;
            font-weight: 500;
            display: flex;
            align-items: center;
            .add {
              width: 34px;
              height: 25px;
              background-color: #FAFAFA;
              border: 1px solid #6e7882;
              display: flex;
              align-items: center;
              padding: 5px 5px 5px 4px;
              border-radius: 5px;
              margin-left: 3px;
              margin-right: 3px;
            }
          }
        }
        .option-add {
          margin-bottom: 20px;
        }
      }
      .icon-v16-minus {
        margin-left: 10px;
      }
      .icon-v16-minus-active {
        margin-left: 10px;
        cursor:pointer;
        color:#382e34;
      }
      .input-other-option {
        width: 200px;
        &:hover {
          border: 1px solid var(--generalIconFontHover);
          box-shadow: none;
        }
        &:focus {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:active {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
      }
      .btn-add-wrapper {
        display: flex;
        flex-direction: column;
        .button-add {
          width:200px;
          height: 32px;
          margin-bottom: 5px;
          border:1px dashed #6e7882;
          border-radius:4px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          color: #382e34;
          margin-bottom: 20px;
          background-color: #fff;
          box-shadow: 0 2px 0 rgba(0,0,0,.02);
        }
      }
      .button-submit {
        width: 75px;
        height: 32px;
        border-radius:6px;
        font-size:14px;
        padding:4px 15px;
        color:#fff;
        background-color:#1677ff;
        &:hover {
          color: #fff;
          background-color: #4096ff;
        }
      }
      .ant-radio .ant-radio-inner {
        border: 1px solid #6E7882;
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #eda41e;
      }
      .ant-radio-checked::after {
        border: 1px solid #eda41e;
      }
      .ant-radio-inner::after {
        background-color: #eda41e;
      }
      .ant-radio:hover .ant-radio-inner {
        border: 1px solid #eda41e !important;
      }
      span.ant-radio + * {
        margin-right: 10px;
        font-size: 14px;
        color: #6e7882;
        font-weight: 400;
      }
      .action-options-add {
        margin-top: 10px;
        display: flex;
        margin-left: 10px;
        .add-option {
          margin-right: 10px;
          font-size: 12px;
          color: #6e7882;
          font-weight: 400;
        }
        .add-option:hover {
          color: #6e7882;
          text-decoration: underline;
          cursor: default;
        }
         .add-other-option {
           font-size: 12px;
           color: #6e7882;
           font-weight: 400;
         }
        .add-other-option:hover {
          color: #6e7882;
          text-decoration: underline;
          cursor: default;
        }
       }
      }
    .btn-add-field-wrapper {
      display: flex;
      flex-direction: column;
      .button-add {
        width:200px;
        height: 32px;
        margin-bottom: 5px;
        border:1px dashed #6e7882;
        border-radius:4px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        color: #382e34;
        margin-bottom: 20px;
        background-color: #fff;
        box-shadow: 0 2px 0 rgba(0,0,0,.02);
      }
    }
    .button-submit {
      width: 75px;
      height: 32px;
      border-radius:6px;
      font-size:14px;
      padding:4px 15px;
      color:#fff;
      background-color:#1677ff;
      &:hover {
        color: #fff;
        background-color: #4096ff;
      }
    }
     .dropdown-content-select {
       display: contents;
       .btn-add-wrapper {
         display: flex;
         flex-direction: column;
         .button-add {
           width:200px;
           height: 32px;
           margin-bottom: 5px;
           border:1px dashed #6e7882;
           border-radius:4px;
           font-family: Roboto;
           font-style: normal;
           font-weight: 400;
           color: #382e34;
           margin-bottom: 20px;
           background-color: #fff;
           box-shadow: 0 2px 0 rgba(0,0,0,.02);
         }
       }
       .select-data-form {
         width: 30%;
         margin-top: 10px;
         margin-bottom: 5px;
         .ant-select-selection {
           border-radius:0px;
           height: 30px;
           border: 1px solid #6e7882;
         }
         .ant-select-selection:hover {
           border-color: #EDA41E;
           border-right-width: 1px !important;
         }
         .ant-select-selection:focus {
           border-color: #EDA41E;
           border-right-width: 1px !important;
         }
       }
     }
    .dropdown-content {
      .icon-v16-minus {
        margin-left: 10px;
      }
      .icon-v16-minus-active {
        margin-left: 10px;
        cursor:pointer;
        color:#382e34;
      }
      .content-options {
        padding: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        //width: 300px;
        //align-items: center;
        .content-info-dynamic {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          .dynamic-form {
            .dynamic {
              font-size: 15px;
              color: #382e34;
              font-weight: 500;
            }
            .icon-v211-file-redacted {
              color: #6e7882;
              font-weight: 400;
              font-size: 14px;
            }
          }
          .info-dynamic {
            margin-top: 10px;
            font-size: 14px;
            color: #382e34;
            font-weight: 500;
            display: flex;
            align-items: center;
            .add {
              width: 34px;
              height: 25px;
              background-color: #FAFAFA;
              border: 1px solid #6e7882;
              display: flex;
              align-items: center;
              padding: 5px 5px 5px 4px;
              border-radius: 5px;
              margin-left: 3px;
              margin-right: 3px;
            }
          }
        }
        .option-add {
          margin-bottom: 20px;
        }
      }
      .input-other-option {
        width: 200px;
        &:hover {
          border: 1px solid var(--generalIconFontHover);
          box-shadow: none;
        }
        &:focus {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:active {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
      }
      .ant-checkbox + span {
        font-size: 14px;
        color: #6e7882;
        font-weight: 400;
      }
      .ant-checkbox-wrapper {
        height: 30px;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #FFFFFF;
        position: absolute !important;
        display: table;
        color: #FFFFFF;
        border: none !important;
        border-top: 0;
        border-left: 0;
        -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        transform: rotate(0deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        content: '\e8ce' !important;
        top: 7px !important;
        left: 7px !important;
        font-size: 12px !important;
        font-family: 'smartbox_icons_v19' !important;
      }
      .ant-checkbox-wrapper .ant-checkbox-inner {
        border-radius: 0% !important;
        border: 1px solid #6e7882;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--generalIconFontActive) !important;
      }
      .ant-checkbox-inner:after {
        border-color: #ffffff !important;
      }
      .action-options-add {
        margin-top: 10px;
        display: flex;
        margin-left: 10px;
        .add-option {
          margin-right: 10px;
          font-size: 12px;
          color: #6e7882;
          font-weight: 400;
        }
        .add-option:hover {
          color: #6e7882;
          text-decoration: underline;
          cursor: default;
        }
        .add-other-option {
          font-size: 12px;
          color: #6e7882;
          font-weight: 400;
        }
        .add-other-option:hover {
          color: #6e7882;
          text-decoration: underline;
          cursor: default;
        }
      }
    }
    .input-form-name {
      border: 1px solid #D4D7DA;
      color: #D4D7DA;
      background-color: #FFFFFF40;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 400;
      height: 30px;
      border-radius: 0px;
      cursor:default;
      &:hover {
        border: 1px solid #D4D7DA;
        box-shadow: none;
      }
      &:focus {
        border: 1px solid #D4D7DA;
        box-shadow: none;
      }
      &:active {
        border: 1px solid #D4D7DA;
        box-shadow: none;
      }
    }
    .label-input {
      font-size: 12px;
      font-family: Roboto;
      color: #D4D7DA;
      font-weight: 400;
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
    }
    .inputs-address {

    }
    .input-header-component {
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5px;
      .input-content {
        width: 49%;
        .input-form-name {
            border: 1px solid #D4D7DA;
            color: #6e7882;
            background-color: #ffffff40;
            padding: 0 10px;
            font-size: 16px;
            font-weight: 400;
            height: 30px;
            border-radius: 0px;
            cursor:default;
            &:hover {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
            &:focus {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
            &:active {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
        }
        .label-input {
          font-size: 12px;
          font-family: Roboto;
          color: #D4D7DA;
          font-weight: 400;
          margin-top: 5px;
          margin-bottom: 5px;
          display: flex;
        }
      }
    }
    .file-upload-content {
      .ant-upload.ant-upload-drag {
        width: 80%;
        background: #ffffff40;
      }
      .ant-upload.ant-upload-disabled {
        cursor:default;
      }
      .ant-upload-list-picture-card-container {
        float: left;
        width: 104px;
        height: auto;
        margin: 5px 8px 8px 0;
      }
      .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
        color: #D4D7DA;
        font-size: 48px;
      }
      .ant-upload.ant-upload-drag p.ant-upload-text {
        margin: 0 0 4px;
        color: #D4D7DA;
        font-size: 16px;
        font-weight: 600;
      }
      .ant-upload.ant-upload-drag p.ant-upload-hint {
        color: #D4D7DA;
        font-size: 14px;
      }
    }
  }

  .component-move {
    width: 3%;
    display: flex;
    justify-content: center;
    .icon-v211-grid-view-1:hover {
      font-size: 14px;
      color: #EDA41E;
    }
    .icon-v211-grid-view-1 {
      font-size: 14px;
      color: #382e34;
    }
  }
}
.component-form.dragging {
 border: 1px solid #eda41e;
}

#mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  text-align: center;
}

#mydivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}
.select-data-form-dropdown {
  z-index:1!important;
  border: 1px solid #eda41e !important;
}