.iconCheck {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -6px -48px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconCheckDisabled {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -48px -48px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconDownload {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -132px -48px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconDownload:hover {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -90px -90px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconDownloadDisabled {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -132px -90px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconEditDisabled {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -90px -6px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconEdit:hover {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -90px -48px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconEdit {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -6px -90px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconEditDisabled {
  background: url(./assets/icon-edit-disabled.png) no-repeat;
  display: block;
  height: 18px;
  width: 18px;
}

.iconError {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -48px -6px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconErrorDisabled {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -132px -6px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconReport {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -174px -90px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconReportDisabled {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -174px -6px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconReport:hover {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -132px -132px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconPlay {
  /*background: url(./assets/dpia-dashboard-sprite-icons.png) -174px -48px;*/
  background: url(./assets/dpia-dashboard-sprite-icons.png) -90px -132px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconPlay:hover {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -90px -132px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconPlayDisabled {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -48px -132px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconView {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -5px -132px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconView:hover {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -47px -90px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconViewDisabled {
  background: url(./assets/dpia-dashboard-sprite-icons.png) -6px -6px;
  display: block;
  height: 32px;
  width: 32px;
}

.iconExpand {
  background: url(./assets/icon-expand.png) no-repeat center;
  display: block;
  width: 22px;
  height: 22px;
}

.iconExpandClose {
  background: url(./assets/icon-expand-close.png) no-repeat center;
  display: block;
  width: 22px;
  height: 22px;
}

.iconCards {
  background: url(./assets/icon-viewCards.png) no-repeat center;
  display: block;
  width: 16px;
  height: 16px;
}

.iconTable {
  background: url(./assets/icon-viewTable.png) no-repeat center;
  display: block;
  width: 16px;
  height: 16px;
}

/*.iconCards {
  background: url(./assets/icon-list.png) no-repeat;
  display: block;
  height: 16px;
  width: 16px;
}*/

.iconList {
  background: url(./assets/icon-grid.png) no-repeat;
  display: block;
  height: 16px;
  width: 16px;
}

.iconWarning {
  background: url(./assets/icon-warning.png) no-repeat;
  display: block;
  height: 22px;
  width: 22px;
}

.iconWarningDisabled {
  background: url(./assets/icon-warning-disabled.png) no-repeat;
  display: block;
  height: 22px;
  width: 22px;
}

.iconLock {
  background: url(./assets/icon-lock.svg) no-repeat;
  background-size: cover;
  display: block;
  height: 18px;
  width: 18px;
}

.iconDownCaret {
  background: url(./assets/down-caret.png) no-repeat;
  display: block;
  height: 18px;
  width: 18px;
}

.iconMove {
  background: url(./assets/icon-grabber.png) no-repeat;
  display: block;
  height: 20px;
  width: 18px;
}

.iconPreview {
  background: url(./assets/icon-preview.png) no-repeat center;
  display: block;
  width: 22px;
  height: 22px;
}
