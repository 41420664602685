.dpiaHelp__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 120px 20px 0;
}

.dpiaHelp__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
}

.dpiaHelp__box-header {
  /*border-bottom: 1px solid #E2E2E2;*/
  padding: 30px 20px 0 20px;
h2 {
  color: #162737;
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  font-family: 'Ubuntu';
  font-weight: 700;
}
}

.dpiaHelp__box-logo {
  max-width: 300px;
}

.dpiaHelp__box-menu--wrapper {
  border-bottom: 1px solid #E2E2E2;
  padding: 20px;
  text-align: center;
h2 {
  color: #162737;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
}
}

.dpiaHelp__box-listitem {
  color: #162737;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  padding: 8px 5px;
}

.dpiaHelp__box-listitem--active {
  color: #1DCBCF;
  font-weight: 700;
}

.dpiaHelp__box-content--item {
  border-bottom: 1px solid #E2E2E2;
  list-style: none;
h3 {
  color: #162737;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  padding: 20px 20px 20px 60px;
}
}

.dpiaHelp__item--opened, .dpiaHelp__item--closed {
  cursor: pointer;
  position: relative;
}

.dpiaHelp__item--opened::before {
  color: #1DCBCF;
  content: '-';
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
  left: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dpiaHelp__item--closed::before {
  color: #1DCBCF;
  content: '+';
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dpiaHelp__box-content--item p {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  padding: 0 20px 20px;
  text-align: center;
}

.dpiaHelp__info {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 30px 10px;
p {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  margin-left: 15px;
}
}

.dpiaHelp__form {
  padding: 20px;
p {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
}
}

.dpiaHelp__form-group {
  margin-bottom: 20px;
label {
  display: block;
  padding-bottom: 5px;
}
input {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
textarea {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 100px;
  padding: 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
}

.dpiaHelp__form button {
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .dpiaHelp__box-logo {
    max-width: 100%;
  }
  .dpiaHelp__box-body {
    display: flex;
  }
  .dpiaHelp__box-menu--wrapper {
    border-bottom: none;
    flex-basis: 300px;
    text-align: left;
  }
  .dpiaHelp__box-content {
    width: 100%;
  }
  .dpiaHelp__box-content--item p {
    padding: 0 20px 20px 60px;
    text-align: left;
  }
  .dpiaHelp__form button {
    margin: 0;
  }
}

.hidden {
  display: none;
}