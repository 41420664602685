.passwordRecovery-page {
  min-height: 100%;
  display: flex;
  align-items:center;
  background: #ffffff url(../../assets/Background.svg) no-repeat center;
  background-size: cover;
  flex-direction: column;
}
.passwordRecovery-page  h1 {
  font-family: 'Roboto';
  font-size: 29px;
  letter-spacing: -0.2px;
  line-height: 30px;
  color: #382e34;
}
.passwordRecovery-page p {
  font-family: 'Roboto';
  font-weight: 300;
  line-height: 20px;
  color: #6e7882;
  margin: 20px 0;
}
.new-content-recovery-page {
  width: 100%;
}
.logo-osprey-header {
  width: 245px;
}
.passwordRecovery-header {
  background-color: #535d66;
  margin-bottom: 60px;
  height: 60px;
  display: flex;
  padding-left: 0px;
}
.passwordRecovery-box {
  background-color: #FFFFFF;
  /*border-radius: 5px;*/
  height: 380px;
  max-width: 400px;
  margin: 0 auto 50px;
  padding: 55px 55px 40px 55px;
  -webkit-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  -moz-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  border: 1px solid #382e34;
}
.passwordRecovery-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.passwordRecovery-formGroup {
  width: 100%;
}

.passwordRecovery-formGroup label {
  color: #6e7882;
  display: block;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
}

.passwordRecovery-formGroup input {
  border: 1px solid #a6acb2;
  background-color: rgba(154, 224, 248, 0.3);
  border-radius: 0;
  box-sizing: border-box;
  color: #382e34;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  height: 40px;
}

.passwordRecovery-formGroup input:focus {
  outline: 2px solid #9AE0FC;
  border-radius: 0;
  border: none;
}
.passwordRecovery-formGroup input:hover {
  border: 2px solid #9AE0FC;
}
.passwordRecovery-formGroup input:focus:hover {
  border: none;
}

.passwordRecovery-footer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passwordRecovery-footerForm {
  align-items: center;
  display: flex;
}

.passwordRecovery-footerForm input {
  margin-left: auto;
}

.passwordRecovery-message {
  margin-top: 20px
}
.passwordRecovery-btn {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 30px !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  border: 1px solid #FFFFFF !important;
  color: #3DD8DB;
  background-color: #FFFFFF !important;
  box-shadow: none !important;
}
.passwordRecovery-btn:hover {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.passwordRecovery-link {
  text-align: center;
}
.passwordRecovery-link a {
  color: var(--generalButton);
  font-size: 14px;
  text-decoration: none;
  font-weight: 300;
  font-family: Roboto;
}
.passwordRecovery-link i {
  font-size: 20px;
  display: inline-block;
  transform: rotate(90deg);
  height: 25px;
}

.passwordRecovery-link a:hover {
  /*text-decoration: underline;*/
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.loading-container {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .passwordRecovery-form {
    padding: 0px;
  }

  .passwordRecovery-container {
    flex-direction: row;
  }
}