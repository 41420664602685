.questionnaireGlobalYN__wrapper {
  background-color: #DDDDDD;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  position: relative
}

.questionnaireGlobalYN__title {
  color: #162737;
  flex: 1;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
}

.questionnaireGlobalYN__group {
  display: flex;
  padding-left: 20px
}

.questionnaireGlobalYN__yes {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 45px;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 100px;
}

.questionnaireGlobalYN__no {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 45px;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 100px;
}

.questionnaireGlobalYN__idontknow {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 45px;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 170px;
}

.questionnaireGlobalYN__labelYesDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #000000;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: -1px;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: -1px;
  width: 100px;
  z-index: 1;
}

.questionnaireGlobalYN__labelYesDisabled::before {
  background-color: #EEEEEE;
  border-radius: 50%;
  color: #000000;
  content: '✓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionnaireGlobalYN__labelNoDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #000000;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: -1px;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: -1px;
  width: 100px;
  z-index: 1;
}

.questionnaireGlobalYN__labelNoDisabled::before {
  background-color: #EEEEEE;
  border-radius: 50%;
  color: #000000;
  content: '☓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionnaireGlobalYN__labelIdontknowDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #000000;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: -1px;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: -1px;
  width: 170px;
  z-index: 1;
}

.questionnaireGlobalYN__labelIdontknowDisabled::before {
  background-color: #EEEEEE;
  border-radius: 50%;
  color: #000000;
  content: '?';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}