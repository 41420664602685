.send-email-update {
  border: 1px solid #382e34;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .email-disclosure-content {
    width: 60%;
    border-right: 1px solid #382e34;
    .form-group {
      //margin: 1% 0;
      margin-bottom: 0px;
      display: flex;
      background-color: #FFC457;
      display: flex;
      align-items: center;
      padding-right: 10px;
      border-left: 1px solid #382e34;
      border-right: 1px solid #382e34;
      border-bottom: 1px solid #382e34;
      height: 40px;
      label {
        margin-top: 0%;
        font-family: Roboto;
        font-weight: 700;
        font-style:normal;
        color: #000000;
        font-size: 14px;
        margin-bottom: 0px;
      }
      input {
        height: 35px;
        border: 1px solid #000000;
        border-radius: 0;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        cursor: default;
        &:focus {
          border:none;
          outline: 1px solid #000000;
        }
      }
    }
    .email-disclosure-header {
      background-color: #ffc457;
      padding: 10px;
      height: 40px;
      border-bottom: 1px solid #382e34;
      .email-subject {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        margin-right: 5px;
      }
      .sar-disclosure {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
    }
    .email-subject-content {
      display: flex;
      height: 450px;
      overflow: auto;
      max-height: 450px;
      width: 100%;
      flex-direction: column;
      .form-content {
        margin-top: 0;
        padding-left:0;
        padding-right: 0;
      }
      .quill-write {
        // border: 1px solid #382e34;
        .ql-container.ql-snow {
          border: none;
        }
        .ql-editor {
          height: 400px;
          span {
            background-color: #FFFFFF !important;
            color: rgba(0, 0, 0, 0.65) !important;
          }
        }
      }
    }
.email-buttons-update {
  justify-content: flex-end !important;
}
    .email-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 45px;
      align-items: center;
      padding: 10px;
      border-top: 1px solid #382E34;
      .email-buttons-reset {
        .icon-v14-refresh-1 {
          font-size: 20px;
          color: #382e34;
          &:hover {
            color: #eda41e;
          }
        }
      }
      .reset-button {
        background-color: #FFFFFF;
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: none;
        color: #6e7882;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        padding: 3px !important;
        text-transform: none;
        transition: none;
        line-height: 18.75px;
        width: 180px;
        margin-left: 20px;
        height: 26px;

        &:hover {
          background-color: #FFFFFF;
          border: 1px solid #FFC457;
          color: #FFC457;
        }

        &:active {
          background-color: #FFFFFF;
          border: 1px solid #eda41e;
          color: #eda41e;
        }

        &:disabled {
          cursor: default;
          color: #d4d7da;
          border: 1px solid #d4d7da !important;
        }
      }
      .send-button {
        background-color: #EDA41E;
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: none;
        color: #FFFFFF;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        padding: 3px !important;
        text-transform: none;
        transition: none;
        line-height: 18.75px;
        width: 180px;
        margin-left: 40px;
        height: 26px;

        &:hover {
          background-color: #FFC457;
          border: 1px solid #FFC457;
          color: #FFFFFF;
        }

        &:active {
          background-color: #EDA41E;
          border: 1px solid #EDA41E;
          color: #FFFFFF;
        }

        &:disabled {
          cursor: default;
          background: #D4D7DA !important;
        }
      }
    }
  }

  .tracking-progress-content {
    width: 40%;
    .tracking-progress-header {
      background-color: #d4d7da;
      padding: 10px;
      height: 40px;
      border-bottom: 1px solid #382e34;
      .tracking-name {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
    }
    .tracking-progress-steps {
      display: flex;
      height: 450px;
      overflow: auto;
      max-height: 450px;
      width: 100%;
      padding: 20px;
      .progress-step {
        .ant-steps-item-content {
          width: 300px;
        }
        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
          background-color: #1890ff;
        }
        .ant-steps-item-description {
          display: flex;
          flex-direction: column;
        }
        .ant-steps-item-title {
          width: 300px;
        }
        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #d4d7da;
        }
        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #d4d7da;
        }
      }
    }
  }
}

.acknowledged-workflow-page {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  &__header {
    display: flex;
    flex-direction: column;
    .name-acknowledged {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
    }
  }
  &__content {
    .tracking-progress-steps-email {
      display: flex;
      height: 200px;
      overflow: auto;
      max-height: 450px;
      width: 100%;
      padding: 20px;
      .progress-step {
        .ant-steps-item-content {
          width: 300px;
        }
        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
          background-color: #1890ff;
        }
        .ant-steps-item-description {
          display: flex;
          flex-direction: column;
        }
        .ant-steps-item-title {
          width: 300px;
        }
        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #d4d7da;
        }
        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #d4d7da;
        }
      }
    }
    .email-acknowledged-info-steps {
      position: relative;
      padding: 30px;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      //border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      height: 110px;
      .status-step-finish {
        .ant-steps-item-tail::after {
          border-top: 3px solid #6D7781;
        }
      }
      .status-link {
        .ant-steps-item-content .ant-steps-item-title {
          padding-top: 20px;
          padding-right: 75px;
          padding-left: 6px;
          font-size: 10px !important;
          position: relative;
          right: 2px;
        }
      }
      .status-link-visited {
        .ant-steps-item-content .ant-steps-item-title {
          padding-left: 8px;
          font-size: 10px !important;
          position: relative;
          right: 18px;
        }
      }
      .status-ended-request {
        .ant-steps-item-content .ant-steps-item-title {
          padding-left: 8px;
          font-size: 10px !important;
          position: relative;
          right: 18px;
        }
      }
      .status-submitting-data {
        .ant-steps-item-content .ant-steps-item-title {
          padding-right: 70px;
          font-size: 10px !important;
          position: relative;
          right: 10px;
        }
      }
      .ant-steps-dot .ant-steps-item-icon {
        width: 8px;
        height: 8px;
        margin-left: 0px;
        padding-right: 0;
        line-height: 8px;
        background: transparent;
        border: 0;
      }
      .ant-steps {
        height: 40px;
        display: flex;
        align-items: normal;
        margin-left: 0px;
        font-family: 'Roboto';
        margin-bottom: 40px;
        width: 38%;
        .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
          cursor: auto;
        }
        .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
          color: #00d3d8;
        }
        .ant-steps-item-finish {
          .ant-steps-item-tail {
            width: 100%;
            margin: 0 0 0 12px;
            opacity: 0.8;
          }
          .ant-steps-item-icon .ant-steps-icon {
            color: #00d3d8;
            font-size: 20px;
          }
          .ant-steps-item-content .ant-steps-item-title {
            color: #00d3d8;
          }
        }
        .ant-steps-item-process {
          .ant-steps-item-content .ant-steps-item-title {
            color: #00d3d8;
          }
        }

        .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
          font-size: 20px;
        }
        .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
          font-size: 20px;
          color: #00d3d8;
        }
        .ant-steps-item {
          overflow: visible;
        }
        .ant-steps-item-content {
          display: block;
          width: 215px;
          margin-top: 8px;
          text-align: start;
          left: -12px;
          .ant-steps-item-title {
            color: #6D7781;
            font-weight: 500;
            font-size: 10px;
            padding-top: 12px;
            padding-right: 24px;
            opacity: 0.8;
          }
          .ant-steps-item-description {
            display: flex;
            font-weight: 500;
            color: #6D7781;
            opacity: 0.6;
            flex-direction: column;
            max-height: 45px;
            height: 69px;
            width: 99px;
            overflow-y: auto;
            position: relative;
            right: 15px;
            .status-pending {
              padding-left: 12px;
              font-size: 10px;
            }
            .status-finish-info {
              padding-left: 4px;
              opacity: 1;
              font-weight: 500;
              color: #6e7882;
              font-size: 10px;
            }
          }
          ant-steps-item-description:hover {
            color: #00d3d8;
          }
        }
        .ant-steps-item-tail {
          top: 10px;
          width: 103%;
          margin: 0 0 0 12px;
          padding: 0;
        }
        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
          background-color: #ffffff;
          border-top: 3px solid #6D7781;
          opacity: 0.6;
        }
        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
          background-color: #ffffff;
          border-top: 3px solid #00d3d8;
          opacity: 0.8;
          border-spacing: 1px;
        }
        .ant-steps-item-wait .ant-steps-item-tail {
          opacity: 0.6;
        }
        .ant-steps-item-wait .ant-steps-item-tail:after {
          background-color: #ffffff;
          border-top: 3px solid #6D7781;
          height: 1px;
          width: calc(105% - 40px);
          margin-left: 11px;
        }
        .ant-steps-label-vertical .ant-steps-item {
          overflow: visible;
        }
      }
    }

    .content-email-acknowledged {
      width: 100%;
      .email-acknowledged {
        display: flex;
          border: 1px solid #382e34;
        width: 100%;
        height: 200px;
      }
    }
    .content-send-email-acknowledged {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 40px;
      .button-send-email-acknowledged {
        background-color: #EDA41E;
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: none;
        color: #FFFFFF;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        padding: 3px !important;
        text-transform: none;
        transition: none;
        line-height: 18.75px;
        width: 180px;
        margin-left: 40px;
        height: 25px;

        &:hover {
          background-color: #FFC457;
          border: 1px solid #FFC457;
          color: #FFFFFF;
        }

        &:active {
          background-color: #EDA41E;
          border: 1px solid #EDA41E;
          color: #FFFFFF;
        }

        &:disabled {
          cursor: default;
          background: #D4D7DA !important;
        }
      }
    }
  }
}