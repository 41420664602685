@font-face {
  font-family: 'smartbox_icons_v23';
  src: url('./font_v23/smartbox_icons_v23.eot?87722700');
  src: url('./font_v23/smartbox_icons_v23.eot?87722700#iefix') format('embedded-opentype'),
       url('./font_v23/smartbox_icons_v23.woff2?87722700') format('woff2'),
       url('./font_v23/smartbox_icons_v23.woff?87722700') format('woff'),
       url('./font_v23/smartbox_icons_v23.ttf?87722700') format('truetype'),
       url('./font_v23/smartbox_icons_v23.svg?87722700#smartbox_icons_v23') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'smartbox_icons_v23';
    src: url('../font/smartbox_icons_v23.svg?87722700#smartbox_icons_v23') format('svg');
  }
}
*/
[class^="icon-v23"]:before, [class*=" icon-v23"]:before {
  font-family: "smartbox_icons_v23";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-v23-edit-active:before { content: '\e800'; } /* '' */
.icon-v23-edit:before { content: '\e801'; } /* '' */
.icon-v23-exclude-active:before { content: '\e802'; } /* '' */
.icon-v23-checkid:before { content: '\e803'; } /* '' */
.icon-v23-dragpad:before { content: '\e804'; } /* '' */
.icon-v23-request-1:before { content: '\e805'; } /* '' */
.icon-v23-email:before { content: '\e806'; } /* '' */
.icon-v23-exclude:before { content: '\e807'; } /* '' */
.icon-v23-3-white:before { content: '\e808'; } /* '' */
.icon-v23-select-all-1:before { content: '\e809'; } /* '' */
.icon-v23-filter-dropdown-active:before { content: '\e80a'; } /* '' */
.icon-v23-spell-checker-1:before { content: '\e80b'; } /* '' */
.icon-v23-images:before { content: '\e80c'; } /* '' */
.icon-v23-enter:before { content: '\e80d'; } /* '' */
.icon-v23-metadata-1:before { content: '\e80e'; } /* '' */
.icon-v23-annotation:before { content: '\e80f'; } /* '' */
.icon-v23-tick-button:before { content: '\e810'; } /* '' */
.icon-v23-minus:before { content: '\e811'; } /* '' */
.icon-v23-radio-button:before { content: '\e812'; } /* '' */
.icon-v23-radio-button-off:before { content: '\e813'; } /* '' */
.icon-v23-radio-button-tick:before { content: '\e814'; } /* '' */
.icon-v23-preview:before { content: '\e815'; } /* '' */
.icon-v23-folder-open-1:before { content: '\e816'; } /* '' */
.icon-v23-show-1:before { content: '\e817'; } /* '' */
.icon-v23-folder-empty:before { content: '\e818'; } /* '' */
.icon-v23-box-lined:before { content: '\e819'; } /* '' */
.icon-v23-colour-palette:before { content: '\e81a'; } /* '' */
.icon-v23-grid-view-1:before { content: '\e81b'; } /* '' */
.icon-v23-list-view-1:before { content: '\e81c'; } /* '' */
.icon-v23-add:before { content: '\e81d'; } /* '' */
.icon-v23-tick-button-off:before { content: '\e81e'; } /* '' */
.icon-v23-annotate-selected:before { content: '\e81f'; } /* '' */
.icon-v23-annotate-1:before { content: '\e820'; } /* '' */
.icon-v23-clear-page-selected:before { content: '\e821'; } /* '' */
.icon-v23-clear-page:before { content: '\e822'; } /* '' */
.icon-v23-show-2:before { content: '\e823'; } /* '' */
.icon-v23-show-100-active-1:before { content: '\e824'; } /* '' */
.icon-v23-clear-selected-selected:before { content: '\e825'; } /* '' */
.icon-v23-clear-selected:before { content: '\e826'; } /* '' */
.icon-v23-draw-redaction-selected:before { content: '\e827'; } /* '' */
.icon-v23-draw-redaction:before { content: '\e828'; } /* '' */
.icon-v23-home-icon-neg2:before { content: '\e829'; } /* '' */
.icon-v23-label-dropdown-active:before { content: '\e82a'; } /* '' */
.icon-v23-label-dropdown:before { content: '\e82b'; } /* '' */
.icon-v23-word-search-selected:before { content: '\e82c'; } /* '' */
.icon-v23-word-search:before { content: '\e82d'; } /* '' */
.icon-v23-move-selected:before { content: '\e82e'; } /* '' */
.icon-v23-text-selection-selected:before { content: '\e82f'; } /* '' */
.icon-v23-text-selection:before { content: '\e830'; } /* '' */
.icon-v23-redo-selected:before { content: '\e831'; } /* '' */
.icon-v23-redo:before { content: '\e832'; } /* '' */
.icon-v23-process-analysing:before { content: '\e833'; } /* '' */
.icon-v23-user-b:before { content: '\e834'; } /* '' */
.icon-v23-users-1:before { content: '\e835'; } /* '' */
.icon-v23-user-circle-b:before { content: '\e836'; } /* '' */
.icon-v23-undo:before { content: '\e837'; } /* '' */
.icon-v23-delete:before { content: '\e838'; } /* '' */
.icon-v23-pages-1:before { content: '\e839'; } /* '' */
.icon-v23-move:before { content: '\e83a'; } /* '' */
.icon-v23-editing:before { content: '\e83b'; } /* '' */
.icon-v23-download-1:before { content: '\e83c'; } /* '' */
.icon-v23-monitor-b:before { content: '\e83d'; } /* '' */
.icon-v23-access-asset-b:before { content: '\e83e'; } /* '' */
.icon-v23-redaction-b:before { content: '\e83f'; } /* '' */
.icon-v23-complete:before { content: '\e840'; } /* '' */
.icon-v23-ai-search:before { content: '\e840'; } /* '' */
.icon-v23-undo-selected:before { content: '\e841'; } /* '' */
.icon-v23-add-to:before { content: '\e842'; } /* '' */
.icon-v23-support-ticket-b:before { content: '\e843'; } /* '' */
.icon-v23-file-sent:before { content: '\e844'; } /* '' */
.icon-v23-ok-b:before { content: '\e845'; } /* '' */
.icon-v23-cloud-b:before { content: '\e846'; } /* '' */
.icon-v23-preview-selected-1:before { content: '\e847'; } /* '' */
.icon-v23-move-file-dropdown-active:before { content: '\e848'; } /* '' */
.icon-v23-searching-active:before { content: '\e849'; } /* '' */
.icon-v23-date:before { content: '\e84a'; } /* '' */
.icon-v23-status-ok:before { content: '\e84b'; } /* '' */
.icon-v23-search-b:before { content: '\e84c'; } /* '' */
.icon-v23-export-b:before { content: '\e84d'; } /* '' */
.icon-v23-clean-active:before { content: '\e84e'; } /* '' */
.icon-v23-clean:before { content: '\e84f'; } /* '' */
.icon-v23-question:before { content: '\e850'; } /* '' */
.icon-v23-print:before { content: '\e851'; } /* '' */
.icon-v23-upload-1:before { content: '\e852'; } /* '' */
.icon-v23-analysis:before { content: '\e853'; } /* '' */
.icon-v23-extension-aep:before { content: '\e854'; } /* '' */
.icon-v23-extension-ai:before { content: '\e855'; } /* '' */
.icon-v23-extension-aif:before { content: '\e856'; } /* '' */
.icon-v23-extension-avi:before { content: '\e857'; } /* '' */
.icon-v23-extension-bmp:before { content: '\e858'; } /* '' */
.icon-v23-extension-cda:before { content: '\e859'; } /* '' */
.icon-v23-extension-css:before { content: '\e85a'; } /* '' */
.icon-v23-extension-csv:before { content: '\e85b'; } /* '' */
.icon-v23-extension-doc:before { content: '\e85c'; } /* '' */
.icon-v23-extension-docx:before { content: '\e85d'; } /* '' */
.icon-v23-extension-eps:before { content: '\e85e'; } /* '' */
.icon-v23-extension-fnt:before { content: '\e85f'; } /* '' */
.icon-v23-extension-fon:before { content: '\e860'; } /* '' */
.icon-v23-extension-gif:before { content: '\e861'; } /* '' */
.icon-v23-extension-htm:before { content: '\e862'; } /* '' */
.icon-v23-extension-html:before { content: '\e863'; } /* '' */
.icon-v23-extension-jfif:before { content: '\e864'; } /* '' */
.icon-v23-extension-jp2:before { content: '\e865'; } /* '' */
.icon-v23-extension-jpeg:before { content: '\e866'; } /* '' */
.icon-v23-extension-jpg:before { content: '\e867'; } /* '' */
.icon-v23-extension-mid:before { content: '\e868'; } /* '' */
.icon-v23-extension-midi:before { content: '\e869'; } /* '' */
.icon-v23-extension-mo4:before { content: '\e86a'; } /* '' */
.icon-v23-extension-key:before { content: '\e86b'; } /* '' */
.icon-v23-extension-mov:before { content: '\e86c'; } /* '' */
.icon-v23-extension-mp3:before { content: '\e86d'; } /* '' */
.icon-v23-extension-mpa:before { content: '\e86e'; } /* '' */
.icon-v23-extension-mpeg:before { content: '\e86f'; } /* '' */
.icon-v23-extension-mpg:before { content: '\e870'; } /* '' */
.icon-v23-extension-odp:before { content: '\e871'; } /* '' */
.icon-v23-extension-odt:before { content: '\e872'; } /* '' */
.icon-v23-extension-ods:before { content: '\e873'; } /* '' */
.icon-v23-extension-ogg:before { content: '\e874'; } /* '' */
.icon-v23-extension-otf:before { content: '\e875'; } /* '' */
.icon-v23-extension-pdf:before { content: '\e876'; } /* '' */
.icon-v23-extension-php:before { content: '\e877'; } /* '' */
.icon-v23-extension-png:before { content: '\e878'; } /* '' */
.icon-v23-extension-pps:before { content: '\e879'; } /* '' */
.icon-v23-extension-ppt:before { content: '\e87a'; } /* '' */
.icon-v23-extension-pptx:before { content: '\e87b'; } /* '' */
.icon-v23-extension-ps:before { content: '\e87c'; } /* '' */
.icon-v23-extension-psd:before { content: '\e87d'; } /* '' */
.icon-v23-extension-pst:before { content: '\e87e'; } /* '' */
.icon-v23-extension-rar:before { content: '\e87f'; } /* '' */
.icon-v23-extension-rtf:before { content: '\e880'; } /* '' */
.icon-v23-extension-svg:before { content: '\e881'; } /* '' */
.icon-v23-extension-tex:before { content: '\e882'; } /* '' */
.icon-v23-extension-tif:before { content: '\e883'; } /* '' */
.icon-v23-extension-tiff:before { content: '\e884'; } /* '' */
.icon-v23-extension-ttf:before { content: '\e885'; } /* '' */
.icon-v23-extension-txt:before { content: '\e886'; } /* '' */
.icon-v23-extension-wav:before { content: '\e887'; } /* '' */
.icon-v23-extension-wma:before { content: '\e888'; } /* '' */
.icon-v23-extension-wpl:before { content: '\e889'; } /* '' */
.icon-v23-extension-xhtml:before { content: '\e88a'; } /* '' */
.icon-v23-extension-xlr:before { content: '\e88b'; } /* '' */
.icon-v23-extension-xlsx:before { content: '\e88c'; } /* '' */
.icon-v23-extension-xls:before { content: '\e88d'; } /* '' */
.icon-v23-extension-xml:before { content: '\e88e'; } /* '' */
.icon-v23-extension-zip:before { content: '\e88f'; } /* '' */
.icon-v23-nav-notifications:before { content: '\e890'; } /* '' */
.icon-v23-nav-help:before { content: '\e891'; } /* '' */
.icon-v23-nav-settings:before { content: '\e892'; } /* '' */
.icon-v23-acknowledged:before { content: '\e893'; } /* '' */
.icon-v23-print-active:before { content: '\e894'; } /* '' */
.icon-v23-arrow-bold:before { content: '\e895'; } /* '' */
.icon-v23-arrow-medium:before { content: '\e896'; } /* '' */
.icon-v23-attachment-1:before { content: '\e897'; } /* '' */
.icon-v23-back-1:before { content: '\e898'; } /* '' */
.icon-v23-completed:before { content: '\e899'; } /* '' */
.icon-v23-copy:before { content: '\e89a'; } /* '' */
.icon-v23-star:before { content: '\e89b'; } /* '' */
.icon-v23-end-1:before { content: '\e89c'; } /* '' */
.icon-v23-ended:before { content: '\e89d'; } /* '' */
.icon-v23-filter-1:before { content: '\e89e'; } /* '' */
.icon-v23-forth-1:before { content: '\e89f'; } /* '' */
.icon-v23-db-gear:before { content: '\e8a0'; } /* '' */
.icon-v23-refresh-1:before { content: '\e8a1'; } /* '' */
.icon-v23-db-question:before { content: '\e8a2'; } /* '' */
.icon-v23-clean-dropdown-active:before { content: '\e8a3'; } /* '' */
.icon-v23-save-1:before { content: '\e8a4'; } /* '' */
.icon-v23-share-1:before { content: '\e8a5'; } /* '' */
.icon-v23-start-1:before { content: '\e8a6'; } /* '' */
.icon-v23-db-time:before { content: '\e8a7'; } /* '' */
.icon-v23-db-papers:before { content: '\e8a8'; } /* '' */
.icon-v23-clean-dropdown:before { content: '\e8a9'; } /* '' */
.icon-v23-on-small:before { content: '\e8aa'; } /* '' */
.icon-v23-data:before { content: '\e8ab'; } /* '' */
.icon-v23-off-small:before { content: '\e8ac'; } /* '' */
.icon-v23-duplicate:before { content: '\e8ad'; } /* '' */
.icon-v23-forwards:before { content: '\e8ae'; } /* '' */
.icon-v23-backwards:before { content: '\e8af'; } /* '' */
.icon-v23-redaction-active:before { content: '\e8b0'; } /* '' */
.icon-v23-process-analysing-1:before { content: '\e8b1'; } /* '' */
.icon-v23-process-finalised:before { content: '\e8b2'; } /* '' */
.icon-v23-process-bulk-redaction:before { content: '\e8b3'; } /* '' */
.icon-v23-alias:before { content: '\e8b4'; } /* '' */
.icon-v23-alias-active:before { content: '\e8b5'; } /* '' */
.icon-v23-rights-1:before { content: '\e8b6'; } /* '' */
.icon-v23-file-status:before { content: '\e8b7'; } /* '' */
.icon-v23-classification:before { content: '\e8b8'; } /* '' */
.icon-v23-add-to-active:before { content: '\e8b9'; } /* '' */
.icon-v23-expand:before { content: '\e8ba'; } /* '' */
.icon-v23-db-speed:before { content: '\e8bb'; } /* '' */
.icon-v23-shrink:before { content: '\e8bc'; } /* '' */
.icon-v23-category-selected:before { content: '\e8bd'; } /* '' */
.icon-v23-exclamation-mark:before { content: '\e8be'; } /* '' */
.icon-v23-delete-active:before { content: '\e8bf'; } /* '' */
.icon-v23-clear:before { content: '\e8c0'; } /* '' */
.icon-v23-clear-active:before { content: '\e8c1'; } /* '' */
.icon-v23-filter-active:before { content: '\e8c2'; } /* '' */
.icon-v23-clear-search:before { content: '\e8c3'; } /* '' */
.icon-v23-grid-view-active:before { content: '\e8c4'; } /* '' */
.icon-v23-list-view-avtive:before { content: '\e8c5'; } /* '' */
.icon-v23-back-active:before { content: '\e8c6'; } /* '' */
.icon-v23-refresh-active:before { content: '\e8c7'; } /* '' */
.icon-v23-go-to-active:before { content: '\e8c8'; } /* '' */
.icon-v23-go-to-1:before { content: '\e8c9'; } /* '' */
.icon-v23-upload-active-1:before { content: '\e8ca'; } /* '' */
.icon-v23-x-active:before { content: '\e8cb'; } /* '' */
.icon-v23-download-active-1:before { content: '\e8cc'; } /* '' */
.icon-v23-attachment-active:before { content: '\e8cd'; } /* '' */
.icon-v23-tick-1:before { content: '\e8ce'; } /* '' */
.icon-v23-share-active:before { content: '\e8cf'; } /* '' */
.icon-v23-lock:before { content: '\e8d0'; } /* '' */
.icon-v23-date-active:before { content: '\e8d1'; } /* '' */
.icon-v23-lock-open:before { content: '\e8d2'; } /* '' */
.icon-v23-save-active:before { content: '\e8d3'; } /* '' */
.icon-v23-lock-open-active:before { content: '\e8d4'; } /* '' */
.icon-v23-document-note-active-1:before { content: '\e8d5'; } /* '' */
.icon-v23-document-note-1:before { content: '\e8d6'; } /* '' */
.icon-v23-redaction-hover:before { content: '\e8d7'; } /* '' */
.icon-v23-redaction:before { content: '\e8d8'; } /* '' */
.icon-v23-remove-active:before { content: '\e8d9'; } /* '' */
.icon-v23-remove:before { content: '\e8da'; } /* '' */
.icon-v23-information-active:before { content: '\e8db'; } /* '' */
.icon-v23-information:before { content: '\e8dc'; } /* '' */
.icon-v23-export-active-1:before { content: '\e8dd'; } /* '' */
.icon-v23-export-1:before { content: '\e8de'; } /* '' */
.icon-v23-import-active-1:before { content: '\e8df'; } /* '' */
.icon-v23-import-1:before { content: '\e8e0'; } /* '' */
.icon-v23-quick-sweep:before { content: '\e8e1'; } /* '' */
.icon-v23-question-active:before { content: '\e8e2'; } /* '' */
.icon-v23-start-active:before { content: '\e8e3'; } /* '' */
.icon-v23-warning-1:before { content: '\e8e4'; } /* '' */
.icon-v23-end-active:before { content: '\e8e5'; } /* '' */
.icon-v23-warning-2:before { content: '\e8e6'; } /* '' */
.icon-v23-up:before { content: '\e8e9'; } /* '' */
.icon-v23-down:before { content: '\e8ea'; } /* '' */
.icon-v23-table-sorting:before { content: '\e8eb'; } /* '' */
.icon-v23-table-sorting-bold:before { content: '\e8ec'; } /* '' */
.icon-v23-1-white:before { content: '\e8ed'; } /* '' */
.icon-v23-2-white:before { content: '\e8ee'; } /* '' */
.icon-v23-file-approved:before { content: '\e8ef'; } /* '' */
.icon-v23-file-redacted:before { content: '\e8f0'; } /* '' */
.icon-v23-select-all-active-1:before { content: '\e8f1'; } /* '' */
.icon-v23-select-all-selected-1:before { content: '\e8f2'; } /* '' */
.icon-v23-process-error:before { content: '\e8f3'; } /* '' */
.icon-v23-filter-dropdown:before { content: '\e8f4'; } /* '' */
.icon-v23-box-design:before { content: '\e8f5'; } /* '' */
.icon-v23-show-20-active-1:before { content: '\e8f6'; } /* '' */
.icon-v23-show-50-active-1:before { content: '\e8f7'; } /* '' */
.icon-v23-pst-empty:before { content: '\e8f8'; } /* '' */
.icon-v23-pst-open:before { content: '\e8f9'; } /* '' */
.icon-v23-show-3:before { content: '\e8fa'; } /* '' */
.icon-v23-move-file-dropdown:before { content: '\e8fb'; } /* '' */
.icon-v23-close-black:before { content: '\e8fc'; } /* '' */
.icon-v23-company-active:before { content: '\e8fd'; } /* '' */
.icon-v23-company:before { content: '\e8fe'; } /* '' */
.icon-v23-personal-active:before { content: '\e8ff'; } /* '' */
.icon-v23-personal:before { content: '\e900'; } /* '' */
.icon-v23-custom-search:before { content: '\e901'; } /* '' */
.icon-v23-searching:before { content: '\e902'; } /* '' */
.icon-v23-pii:before { content: '\e903'; } /* '' */
.icon-v23-search-history:before { content: '\e904'; } /* '' */
.icon-v23-business-term:before { content: '\e905'; } /* '' */
.icon-v23-regular-expressions:before { content: '\e906'; } /* '' */
.icon-v23-copy-active:before { content: '\e907'; } /* '' */
.icon-v23-options:before { content: '\e908'; } /* '' */
.icon-v23-star-active:before { content: '\e909'; } /* '' */
.icon-v23-exclusion:before { content: '\e90a'; } /* '' */
.icon-v23-mail-open:before { content: '\e90b'; } /* '' */
.icon-v23-mail:before { content: '\e90c'; } /* '' */
.icon-v23-workflow:before { content: '\e90d'; } /* '' */
.icon-v23-dashoboard:before { content: '\e90e'; } /* '' */
.icon-v23-glossary:before { content: '\e90f'; } /* '' */
.icon-v23-results:before { content: '\e910'; } /* '' */
.icon-v23-securtiy:before { content: '\e911'; } /* '' */
.icon-v23-tag-confident:before { content: '\e912'; } /* '' */
.icon-v23-tag-relevant:before { content: '\e913'; } /* '' */
.icon-v23-tag-not-relevant:before { content: '\e914'; } /* '' */
.icon-v23-home-active:before { content: '\e915'; } /* '' */
.icon-v23-home:before { content: '\e916'; } /* '' */
.icon-v23-category-active:before { content: '\e917'; } /* '' */
.icon-v23-category:before { content: '\e918'; } /* '' */
.icon-v23-options-small-active:before { content: '\e919'; } /* '' */
.icon-v23-options-small:before { content: '\e91a'; } /* '' */
.icon-v23-colour-palette-active:before { content: '\e91b'; } /* '' */
.icon-v23-file-status-active:before { content: '\e91c'; } /* '' */
.icon-v23-move-file-active-1:before { content: '\e91d'; } /* '' */
.icon-v23-classification-active:before { content: '\e91e'; } /* '' */
.icon-v23-add-to-list-active:before { content: '\e91f'; } /* '' */
.icon-v23-add-to-list:before { content: '\e920'; } /* '' */
.icon-v23-unvisible-active:before { content: '\e921'; } /* '' */
.icon-v23-unvisible:before { content: '\e922'; } /* '' */
.icon-v23-visible:before { content: '\e923'; } /* '' */
.icon-v23-visible-active:before { content: '\e924'; } /* '' */
.icon-v23-show-all:before { content: '\e925'; } /* '' */
.icon-v23-show-all-active:before { content: '\e926'; } /* '' */
.icon-v23-go-to:before { content: '\e927'; } /* '' */
.icon-v23-add-box-small:before { content: '\e928'; } /* '' */
.icon-v23-add-box:before { content: '\e929'; } /* '' */
.icon-v23-add-folder:before { content: '\e92a'; } /* '' */
.icon-v23-add-folder-small:before { content: '\e92b'; } /* '' */
.icon-v23-redacted-annotated-copy:before { content: '\e92c'; } /* '' */
.icon-v23-redacted-annotated:before { content: '\e92d'; } /* '' */
.icon-v23-tag-confident-1:before { content: '\e92e'; } /* '' */
.icon-v23-tag-not-relevant-1:before { content: '\e92f'; } /* '' */
.icon-v23-tag-relevant-1:before { content: '\e930'; } /* '' */
.icon-v23-forth-active:before { content: '\e931'; } /* '' */
.icon-v23-page-redaction:before { content: '\e932'; } /* '' */
.icon-v23-page-no-redaction:before { content: '\e933'; } /* '' */
.icon-v23-avatar:before { content: '\e934'; } /* '' */
.icon-v23-move-file-1:before { content: '\e935'; } /* '' */
.icon-v23-db-documents:before { content: '\e936'; } /* '' */
.icon-v23-db-file-move:before { content: '\e937'; } /* '' */
.icon-v23-lock-active:before { content: '\e938'; } /* '' */
.icon-v23-db-storage:before { content: '\e939'; } /* '' */
.icon-v23-db-user-group:before { content: '\e93a'; } /* '' */
.icon-v23-db-user:before { content: '\e93b'; } /* '' */
.icon-v23-db-clock:before { content: '\e93c'; } /* '' */
.icon-v23-shortcut:before { content: '\e93d'; } /* '' */
.icon-v23-request-tracker:before { content: '\e93e'; } /* '' */
.icon-v23-completed-active:before { content: '\e93f'; } /* '' */
.icon-v23-connector-done:before { content: '\e944'; } /* '' */
.icon-v23-connector-done-active:before { content: '\e945'; } /* '' */
.icon-v23-connector:before { content: '\e946'; } /* '' */
.icon-v23-connector-active:before { content: '\e947'; } /* '' */
.icon-v23-disclosure-done:before { content: '\e948'; } /* '' */
.icon-v23-disclosure-done-active:before { content: '\e949'; } /* '' */
.icon-v23-disclosure:before { content: '\e94a'; } /* '' */
.icon-v23-disclosure-active:before { content: '\e94b'; } /* '' */
.icon-v23-email-sent-done:before { content: '\e94c'; } /* '' */
.icon-v23-email-sent-done-active:before { content: '\e94d'; } /* '' */
.icon-v23-email-1:before { content: '\e94e'; } /* '' */
.icon-v23-email-active:before { content: '\e94f'; } /* '' */
.icon-v23-form-done:before { content: '\e950'; } /* '' */
.icon-v23-form-done-active:before { content: '\e951'; } /* '' */
.icon-v23-form:before { content: '\e952'; } /* '' */
.icon-v23-form-active:before { content: '\e953'; } /* '' */
.icon-v23-request-detail-done:before { content: '\e954'; } /* '' */
.icon-v23-request-detail-done-active:before { content: '\e955'; } /* '' */
.icon-v23-request-detail:before { content: '\e956'; } /* '' */
.icon-v23-request-detail-active:before { content: '\e957'; } /* '' */
.icon-v23-key-visual-active:before { content: '\e958'; } /* '' */
.icon-v23-key-visual:before { content: '\e959'; } /* '' */
.icon-v23-resume:before { content: '\e95a'; } /* '' */
.icon-v23-resume-active:before { content: '\e95b'; } /* '' */
.icon-v23-pause:before { content: '\e95c'; } /* '' */
.icon-v23-pause-active:before { content: '\e95d'; } /* '' */
.icon-v23-stop:before { content: '\e95e'; } /* '' */
.icon-v23-stop-active:before { content: '\e95f'; } /* '' */
.icon-v23-add-active:before { content: '\ea51'; } /* '' */
.icon-v23-minus-active:before { content: '\ea53'; } /* '' */
.icon-v23-gear:before { content: '\ea54'; } /* '' */
