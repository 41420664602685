.token-management {
  padding: 40px 40px 0;
  position: relative;
  &__page {
    .header-token {
      margin-bottom: 10px;
      .icon-v17-add-active {
        color: #eda41e;
      }
    }
    .token-management-table {
      .icon-v21-edit {
        font-size: 20px;
        color: #382e34;
      }
      .icon-v21-edit-active {
        font-size: 20px;
        color: #EDA41E;
      }
      .icon-v19-delete-active {
        font-size: 20px;
        color: #EDA41E;
      }
      .icon-v19-delete {
        font-size: 20px;
        color: #382e34;
      }
    }

    .token-management-editable {
      .header-token {
        margin-top: 20px;
      }
       .header-editable {
         display: flex;
         text-align: center;
         .action-header-token {
           display: flex;
           justify-content: flex-end;
           width: 100%;

           .button-save-token {
             background-color: #EDA41E;
             border: 1px solid #6e7882;
             border-radius: 20px;
             box-shadow: none;
             color: #FFFFFF;
             cursor: pointer;
             font-family: Roboto;
             font-weight: 400;
             font-size: 16px;
             padding: 3px !important;
             text-transform: none;
             transition: none;
             line-height: 18.75px;
             width: 15%;
             margin-left: 40px;
             height: 26px;

             &:hover {
               background-color: #FFC457;
               border: 1px solid #FFC457;
               color: #FFFFFF;
             }

             &:active {
               background-color: #EDA41E;
               border: 1px solid #EDA41E;
               color: #FFFFFF;
             }

             &:disabled {
               cursor: default;
               background: #D4D7DA !important;
             }
           }

           .button-cancel-token {
             background-color: #FFFFFF;
             border: 1px solid #6e7882;
             border-radius: 20px;
             box-shadow: none;
             color: #6e7882;
             cursor: pointer;
             font-family: Roboto;
             font-weight: 400;
             font-size: 16px;
             padding: 3px !important;
             text-transform: none;
             transition: none;
             line-height: 18.75px;
             width: 15%;
             height: 26px;

             &:hover {
               background-color: #FFFFFF;
               border: 1px solid #FFC457;
               color: #FFC457;
             }

             &:active {
               background-color: #FFFFFF;
               border: 1px solid #EDA41E;
               color: #EDA41E;
             }
           }
         }
       }

      .content-editable {
        margin-top: 20px;
        .content-allow-access {
          margin-top: 10px;
          //margin-bottom: 10px;
          .title-allow-access {
            color: #382e34 !important;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 5px;
            display: flex;
          }
          .switcher__slider:hover {
            border: 1px solid #FFC457;
          }
          .switcher__slider:active {
            border: 1px solid #EDA41E;
          }
          .switcher__slider {
            background-color: #ffffff;
            border-radius: 34px;
            position: relative;
            width: 70px;
            height: 25px;
            cursor: pointer;
            border: 1px solid #6e7882;
            &:after {
              content: 'ON';
              color: #6E7882;
              font-size: 16px;
              font-weight: 400;
              position: absolute;
              right: 16px;
              top: 3px;
              z-index: 0;
            }
          }
          .switcher__sliderChecked:hover {
            background-color: #FFC457;
            border: 1px solid #EDA41E;
          }
          .switcher__sliderChecked:active {
            background-color: #EDA41E;
            border: 1px solid #EDA41E;
          }
          .switcher__sliderChecked {
            background-color: #EDA41E;
            border-radius: 34px;
            position: relative;
            width: 70px;
            height: 25px;
            cursor: pointer;
            border: 1px solid #6e7882;
            &:before {
              content: 'YES';
              color: #FFFFFF;
              position: absolute;
              z-index: 0;
              font-size: 16px;
              font-weight: 400;
              left: 14px;
              top: 3px;
            }
          }
          .switcher__sliderButton {
            background-color: #FFFFFF;
            border-radius: 50px;
            cursor: pointer;
            display: block;
            height: 19px;
            left: 3px;
            position: absolute;
            top: 2px;
            transition: all 0.4s ease;
            width: 19px;
            z-index: 1;
            border: 1px solid #6e7882;
          }
          .switcher__sliderButtonChecked:hover {
            border: 1px solid #EDA41E;
          }
          .switcher__sliderButtonChecked:active {
            border: 1px solid #EDA41E;
          }
          .switcher__sliderButtonChecked {
            background-color: #FFFFFF;
            border-radius: 50px;
            cursor: pointer;
            display: block;
            height: 19px;
            left: 47px;
            position: absolute;
            top: 2px;
            transition: all 0.4s ease;
            width: 19px;
            border: 1px solid #6e7882;
          }
        }
        .content-expiration-date {
          margin-top: 10px;
          //margin-bottom: 10px;
          .title-expiration-date {
            color: #382e34 !important;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 5px;
            display: flex;
          }
        }
        .input-email {
          border: 1px solid #D4D7DA;
          color: #382e34;
          background-color: #FFFFFF40;
          padding: 0 10px;
          font-size: 16px;
          font-weight: 400;
          height: 30px;
          border-radius: 0px;
          cursor:default;
          margin-top: 10px;
          &:hover {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }
          &:focus {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }
          &:active {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }
        }
        .select-token {
          color: #6e7882;
          font-weight: 400;
          font-size: 14px;
          width: 100%;
          margin-top: 10px;
        }
        .ant-select-selection {
          border-radius:0;
          &:hover {
            border-color:#eda41e;
          }
          &:focus{
            border-color: #eda41e;
          }

        }
      }

      .content-edit-table {
         margin-top: 10px;
        .icon-v21-edit {
          font-size: 20px;
          color: #382e34;
        }
        .icon-v21-edit-active {
          font-size: 20px;
          color: #EDA41E;
        }
      }
    }
  }
}

.modal-token {
  .content-editable-modal {
    margin-top: 20px;
    .content-option-modal-token {
      .title-option {
        color: #382e34 !important;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;
        display: flex;
      }
    }
    .content-allow-access {
      margin-top: 10px;
      //margin-bottom: 10px;
      .title-allow-access {
        color: #382e34 !important;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;
        display: flex;
      }
      .switcher__slider:hover {
        border: 1px solid #FFC457;
      }
      .switcher__slider:active {
        border: 1px solid #EDA41E;
      }
      .switcher__slider {
        background-color: #ffffff;
        border-radius: 34px;
        position: relative;
        width: 70px;
        height: 25px;
        cursor: pointer;
        border: 1px solid #6e7882;
        margin-bottom: 10px;
        &:after {
          content: 'ON';
          color: #6E7882;
          font-size: 16px;
          font-weight: 400;
          position: absolute;
          right: 16px;
          top: 0px;
          z-index: 0;
        }
      }
      .switcher__sliderChecked:hover {
        background-color: #FFC457;
        border: 1px solid #EDA41E;
      }
      .switcher__sliderChecked:active {
        background-color: #EDA41E;
        border: 1px solid #EDA41E;
      }
      .switcher__sliderChecked {
        background-color: #EDA41E;
        border-radius: 34px;
        position: relative;
        width: 70px;
        height: 25px;
        cursor: pointer;
        border: 1px solid #6e7882;
        margin-bottom: 10px;
        &:before {
          content: 'YES';
          color: #FFFFFF;
          position: absolute;
          z-index: 0;
          font-size: 16px;
          font-weight: 400;
          left: 14px;
          top: 0px;
        }
      }
      .switcher__sliderButton {
        background-color: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        display: block;
        height: 19px;
        left: 3px;
        position: absolute;
        top: 2px;
        transition: all 0.4s ease;
        width: 19px;
        z-index: 1;
        border: 1px solid #6e7882;
      }
      .switcher__sliderButtonChecked:hover {
        border: 1px solid #EDA41E;
      }
      .switcher__sliderButtonChecked:active {
        border: 1px solid #EDA41E;
      }
      .switcher__sliderButtonChecked {
        background-color: #FFFFFF;
        border-radius: 50px;
        cursor: pointer;
        display: block;
        height: 19px;
        left: 47px;
        position: absolute;
        top: 2px;
        transition: all 0.4s ease;
        width: 19px;
        border: 1px solid #6e7882;
      }
    }
    .content-expiration-date {
      margin-top: 10px;
      //margin-bottom: 10px;
      .title-expiration-date {
        color: #382e34 !important;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;
        display: flex;
      }
    }
    .input-email {
      border: 1px solid #D4D7DA;
      color: #382e34;
      background-color: #FFFFFF40;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 400;
      height: 30px;
      border-radius: 0px;
      cursor:default;
      //margin-top: 5px;
      &:hover {
        border: 1px solid #D4D7DA;
        box-shadow: none;
      }
      &:focus {
        border: 1px solid #D4D7DA;
        box-shadow: none;
      }
      &:active {
        border: 1px solid #D4D7DA;
        box-shadow: none;
      }
    }
    .select-token {
      color: #6e7882;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      //margin-top: 5px;
    }
    .ant-select-selection {
      border-radius:0;
      &:hover {
        border-color:#eda41e;
      }
      &:focus{
        border-color: #eda41e;
      }

    }

    .content-action-token {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .action-cancel {
        width: 150px;
        height: 25px;
        border: 1px solid #6e7882;
        background-color: #FFFFFF;
        color: #6e7882;
        box-shadow: none;
        cursor: pointer;
        padding: 0px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        alignment: center;
        vertical-align: middle;
        line-height: 18.75px;

        &:hover {
          background-color: #FFFFFF;
          border-color: #FFC457;
          color: #FFC457;
        }

        &:focus {
          border: 1px solid #6e7882;
          background-color: #FFFFFF;
          color: #6e7882;
          -webkit-box-shadow: none;
          box-shadow:none;
        }

        &:active {
          border: 1px solid #EDA41E;
          background-color: #FFFFFF;
          color: #EDA41E;
        }
      }
      .action-save {
        width: 150px;
        height: 25px;
        border: 1px solid #6e7882;
        background-color: #EDA41E;
        color: #FFFFFF;
        box-shadow: none;
        cursor: pointer;
        padding: 0px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        alignment: center;
        vertical-align: middle;
        line-height: 18.75px;
        &:hover {
          background-color: #FFC457;
          border-color: #EDA41E;
          color: #FFFFFF;
        }
        &:focus {
          background-color: #EDA41E;
          color: #FFFFFF;
        }

        &:active {
          background-color: #EDA41E;
          color: #FFFFFF;
        }
      }
      .action-save:disabled {
        background-color: #D4D7DA;
        color: #FFFFFF;
        cursor: default;
        font-weight: 500;
        alignment: center;
        &:hover {
          background-color: #D4D7DA;
          border-color: #6e7782;
          color: #FFFFFF;
        }
        &:focus {
          background-color: #D4D7DA;
          color: #FFFFFF;
        }
        &:active {
          border: 1px solid #6e7882;
          background-color: #D4D7DA;
          color: #FFFFFF;
        }
      }
    }

  }
}