.new-clutter-page {
  &__content {
     .new-clutter-page-contain {

       .content-back-files {
         color: #382e34;
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         .header-back-file {
           display: flex;
           align-items: center;
         }
         .icon-v21-back-active {
           color: var(--generalIconFontHover);
           font-size: 20px;
           margin-right: 10px;
           cursor: pointer;
         }
         .icon-v21-back-1 {
           color: #382e34;
           font-size: 20px;
           margin-right: 10px;
         }
         .icon-v21-back-active:before {
           line-height: 30px;
         }
         .icon-v21-back-1:before {
           line-height: 30px;
         }
         .icon-v14-back-1 {
           font-size: 20px;
           margin-right: 10px;
           cursor: pointer;
           &:hover {
             color: #EDA41E;
           }
         }
         .icon-v14-back-1:before {
           line-height: 30px;
         }
         .title-files-active {
           font-size: 14px;
           font-weight: 700;
           letter-spacing: 0.2px;
           //color: #EDA41E;
           cursor: pointer;
         }
         .title-files {
           font-size: 14px;
           font-weight: 700;
           letter-spacing: 0.2px;
         }
       }
        .new-clutter-page-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          height: 30px;
          margin-bottom: 10px;

          .content-select-all-new-clutter {
            display: flex;
            align-items: center;
            margin-left: 5px;
            font-family: Roboto;
            .select-deduplicate-checkbox {
              line-height: 30px;
            }
            .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
              border-color: var(--generalIconFontHover);
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
              border-color: #FFFFFF;
              position: absolute !important;
              display: table;
              color: #FFFFFF;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
            .checkbox-auto-disabled {
              cursor: not-allowed;
              opacity: 0.6;
              .ant-checkbox-inner {
                cursor: not-allowed;
              }
              .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
                border-color: #d9d9d9;
                opacity: 0.6;
              }
            }
            .checkbox-auto {
              .option-select-all-disabled {
                opacity: 0.6;
              }
              .ant-checkbox-wrapper .ant-checkbox-inner {
                border-radius: 0% !important;
                border: 1px solid #6e7882;
              }
              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: var(--generalIconFontActive) !important;
              }
              .ant-checkbox-inner:after {
                border-color: #ffffff !important;
              }
            }
            .select-auto {
              margin-left: 10px;
              font-family: Roboto;
              font-size: 14px;
              color: #382e34;
              font-weight: 400;
            }
          }
          .per-page-new-clutter:hover {
            .icon-v19-arrow-medium {
              color: #EDA41E !important;
            }
            .ant-select-arrow:after {
              color: #EDA41E !important;
            }
          }
          .per-page-new-clutter {
            border: 1px solid transparent;
            height: 20px;
            width: 88px;
            font-family: Roboto;
            font-size: 14px;
            color: #382e34;
            font-weight: 400;
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            border: 1px solid transparent;
            border-radius: 3px;
            position: relative;
            .show-duplicate {
              margin-right: 5px;
              cursor: default;
            }
            .ant-select-arrow:after {
              border-radius: 50%;
              color: #382e34;
              content: '\e896';
              font-family: 'smartbox_icons_v19' !important;
              display: inline-block;
              width: 17px;
              height: 17px;
              padding-top: 0px;
              padding-left: 0px;
              padding-bottom: 9px;
              font-style: normal;
              font-size: 20px;
            }
            .icon-v19-arrow-medium {
              font-size: 20px;
              color: #382e34;
              position: absolute;
              left: 59px;
            }
            .icon-v19-arrow-medium:before {
              margin-right: 0px;
              margin-left: 0px;
            }
            .ant-select-arrow .ant-select-arrow-icon {
              display: none !important;
            }
            .ant-select-selection {
              border: none;
              border-radius: 3px;
            }
            .ant-select {
              height: 19px;
              //width: 40px;
            }
            .ant-select-selection .ant-select-selection__rendered {
              margin-left: 0px;
            }
            .ant-select-selection--single .ant-select-selection__rendered {
              margin-right: 24px;
            }
            .ant-select-selection__rendered {
              line-height: 20px;
              letter-spacing: 0px;
              font-kerning: normal;
            }
            .ant-select-selection-selected-value {
              width: 62px;
              line-height: 19px;
            }
            .ant-select-selection--single {
              //border: none;
              height: 20px;
              color: #382e34;
            }
            .ant-select .ant-select-selection .ant-select-arrow {
              margin-right: 0;
              //top: 35%;
              top: 30%;
              //right: 2px;
              right: 12px;
              color: #382e34;
            }
            .ant-select-selection {
              background-color: transparent;
              width: 88px;
            }
            &:hover {
              //height: 30px;
              border: 1px solid #EDA41E;
              //padding-left: 3px;
              border-radius: 3px;
              //padding-bottom: 30px;
              color: #EDA41E;
              //width: 80px;
              //border: 1px solid #EDA41E;
              .ant-select-selection {
                color: #EDA41E !important;
                height: 20px;
              }
              .ant-select-arrow {
                color: #EDA41E !important;
              }
            }
          }

          .buttons-control-pagination {
            float: left;
            display: flex;
            align-items: center;
            font-family: Roboto;
            font-size: 14px;
            color: #382e34;
            font-weight: 400;
            letter-spacing: 0.2px;
            line-height: 20px;
            &__text {
              margin-left: 10px;
              margin-right: 10px;
            }
            .disabled-back {
              color: #D4D7DA;
            }
            .disabled-next {
              color: #D4D7DA;
            }
            .disabled-text {
              color: #D4D7DA;
            }
            .insert-page {
              text-align: center;
              width: 42px;
              //padding: 7px 2px;
              margin-right: 5px;
              padding: 0px;
              font-weight: 400;
              height: 20px;
              border: 1px solid #382e34;
              font-size: 14px;
              letter-spacing: 0.2px;
              line-height: 20px;
              background-color: #FFFFFF;
              border-radius: 2px;
              color: #382e34;
              &:focus {
                border-color: #EDA41E;
                outline: none;
              }
            }
            .insert-page:focus-visible {
              border: 1px solid #EDA41E;
            }
            .insert-page:focus {
              border: 1px solid #EDA41E;
            }
            .insert-page:hover {
              border: 1px solid #EDA41E;
            }
            .icon-v14-start-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .icon-v14-star {
              font-size: 20px;
              color: #EDA41E;
            }
            .back-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .icon-v21-back-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .icon-v21-forth-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .back-active:before {
              background-color: #EDA41E;
              border-radius: 50px;
            }
            .icon-v14-start-1 {
              font-size: 20px;
            }
            .icon-v14-start-1:before {
              //margin-left: 0.2em;
            }
            [class^="icon-v14"]:before {
              margin-left: 0.2em;
            }
            .icon-v14-end-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .forth-1-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .forth-1-active:before {
              background-color: #EDA41E;
              border-radius: 50px;
            }
            .icon-v14-back-1 {
              font-size: 20px;
            }
            .icon-v14-forth-1 {
              font-size: 20px;
            }
            .disabled-pagination {
              cursor: not-allowed;
              //opacity: 0.6;
              font-weight: 400;
              //font-size: 14px;
              color: #D4D7DA;
            }
            .icon-v14-end-1 {
              font-size: 20px;
            }
          }

          .input-search-clutter {
            .search-duplicates-list {
              //padding: 10px 5px 5px 5px;
            }
            .ant-input {
              border: 1px solid #382e34;
              font-size: 14px;
              letter-spacing: 0.2px;
              width: 200px;
              height: 30px;
              padding: 10px 7px 10px 7px;
            }
            .ant-input-affix-wrapper .ant-input-suffix {
              right: 10px;
              z-index: 1;
            }
            .search-duplicates {
              display: flex;
              justify-content: flex-end;
              .ant-input {
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.2px;
              }
              .ant-input:placeholder-shown {
                //width: 150px;
                height: 30px;
                //padding: 10px 5px 5px 5px;
                font-size: 14px;
                color: #a6acb2;
              }
              .ant-input:hover {
                border: 1px solid #EDA41E !important;
              }
              .ant-input:focus {
                border: 1px solid #EDA41E !important;
              }
            }
            input[type=text]:disabled {
              color: #D4D7DA !important;
              background: #FFFFFF;
              border: 1px solid #D4D7DA;
            }
            input[type=text]:disabled:hover {
              color: #D4D7DA !important;
              background: #FFFFFF;
              border: 1px solid #D4D7DA !important;
            }
            .ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
              font-size: 20px;
              border-left: none;
              padding-left: 5px;
              color: #D4D7DA !important;
            }
            .ant-input-search-icon {
              font-size: 20px;
              border-left: 1px solid #382e34;
              padding-left: 5px;
              color: #150010 !important;
            }
            .ant-input:focus {
              box-shadow: none;
            }
            .ant-input-clear-icon + i {
              margin-left: 0px;
            }
            .search-duplicates {
              width: 200px;
              height: 30px;
              .ant-input {
                font-family: Roboto;
                font-weight: 400;
                color: #150010;
                letter-spacing: 0.2px;
                alignment: left !important;
                vertical-align: middle;
                font-size: 14px;
                border-radius: 0px;
              }
              .ant-input:placeholder-shown {
                font-family: Roboto;
                font-weight: 400;
                color: #a6acb2;
                letter-spacing: 0.2px;
                alignment: left;
                vertical-align: middle;
              }
              .ant-input:hover {
                border: 1px solid #00d4d8;
              }
              .ant-input:focus {
                border: 1px solid #00d4d8;
              }
              .ant-input-search-icon {
                font-size: 20px;
                border-left: 1px solid #382e34;
                padding-left: 7px;
                color: #382e34;
              }
              .ant-input-clear-icon {
                color: #382e34;
                font-size: 16px;
                padding-right: 7px;
              }
            }
          }

        }

       .new-clutter-page-header-list {
         .name-files-header {
           color: #382e34;
           display: flex;
           align-items: center;
           .actions-dots-cell-email-threads {
             button {
               background-color: transparent !important;
               border: none !important;
               color: #3DD8DB !important;
               font-size: 30px;
               line-height: 22px;
               outline: none;
               margin-top: 0px;
             }
              .ellipsis-v2-threads {
                writing-mode: horizontal-tb;
                font-size: 24px;
                color: #6e7882;
                margin-right: 15px;
                padding-right: 10px;
                display: flex;
              }
           }
           .header-back-file {
             display: flex;
             align-items: center;
           }
           .icon-v21-back-active {
             color: var(--generalIconFontHover);
             font-size: 20px;
             margin-right: 10px;
             cursor: pointer;
           }
           .icon-v21-back-1 {
             color: #382e34;
             font-size: 20px;
             margin-right: 10px;
           }
           .icon-v21-back-active:before {
             line-height: 30px;
           }
           .icon-v21-back-1:before {
             line-height: 30px;
           }
           .icon-v14-back-1 {
             font-size: 20px;
             margin-right: 10px;
             cursor: pointer;
             &:hover {
               color: #EDA41E;
             }
           }
           .icon-v14-back-1:before {
             line-height: 30px;
           }
           .title-files-active {
             font-size: 14px;
             font-weight: 700;
             letter-spacing: 0.2px;
             //color: #EDA41E;
             cursor: pointer;
           }
           .title-files {
             font-size: 14px;
             font-weight: 700;
             letter-spacing: 0.2px;
           }
         }
         .per-page-duplicates-table {
           position: relative;
           .show-duplicate {
             margin-right: 5px;
             cursor: default;
           }
           .ant-select-arrow:after {
             border-radius: 50%;
             color: #382e34;
             content: '\e896';
             font-family: 'smartbox_icons_v19' !important;
             display: inline-block;
             width: 17px;
             height: 17px;
             padding-top: 0px;
             padding-left: 0px;
             padding-bottom: 9px;
             font-style: normal;
             font-size: 20px;
           }
           .icon-v19-arrow-medium {
             font-size: 20px;
             color: #382e34;
             position: absolute;
             left: 59px;
           }
           .icon-v19-arrow-medium:before {
             margin-right: 0px;
             margin-left: 0px;
           }
           .ant-select-arrow .ant-select-arrow-icon {
             display: none !important;
           }
         }
         .per-page-duplicates-table:hover {
           .icon-v19-arrow-medium {
             color: #EDA41E !important;
           }
           .ant-select-arrow:after {
             color: #EDA41E !important;
           }
         }
         .per-page-duplicates-list {
           display: flex;
           align-items: center;
           .show-duplicate {
             margin-right: 5px;
             cursor: default;
           }
           .ant-select-arrow:after {
             border-radius: 50%;
             color: #382e34;
             content: '\e896';
             font-family: 'smartbox_icons_v19' !important;
             display: inline-block;
             width: 17px;
             height: 17px;
             padding-top: 0px;
             padding-left: 0px;
             padding-bottom: 9px;
             font-style: normal;
             font-size: 20px;
           }
           .ant-select-selection--single .ant-select-selection__rendered {
             margin-right: -15px;
           }
           .ant-select .ant-select-selection .ant-select-arrow {
             //display: none;
           }
           .ant-select-arrow .ant-select-arrow-icon {
             display: none;
           }
           .icon-v19-arrow-medium:before {
             margin-right: 0px;
             margin-left: 0px;
           }
           .icon-v19-arrow-medium {
             font-size: 20px;
             color: #382e34;
           }
           .ant-select-selection-selected-value {
             width: 88px !important;
             line-height: 19px;
           }
         }
         .per-page-duplicates-list:hover {
           .icon-v19-arrow-medium {
             color: #EDA41E !important;
           }
           .ant-select-arrow:after {
             color: #EDA41E !important;
           }
         }
         .per-page-duplicates {
           border: 1px solid transparent;
           height: 20px;
           width: 88px;
           font-family: Roboto;
           font-size: 14px;
           color: #382e34;
           font-weight: 400;
           display: flex;
           align-items: center;
           padding: 5px;
           cursor: pointer;
           border: 1px solid transparent;
           border-radius: 3px;
           .ant-select-selection {
             border: none;
             border-radius: 3px;
           }
           .ant-select {
             height: 19px;
             //width: 40px;
           }
           .ant-select-selection .ant-select-selection__rendered {
             margin-left: 0px;
           }
           .ant-select-selection--single .ant-select-selection__rendered {
             margin-right: 24px;
           }
           .ant-select-selection__rendered {
             line-height: 20px;
             letter-spacing: 0px;
             font-kerning: normal;
           }
           .ant-select-selection-selected-value {
             width: 62px;
             line-height: 19px;
           }
           .ant-select-selection--single {
             //border: none;
             height: 20px;
             color: #382e34;
           }
           .ant-select .ant-select-selection .ant-select-arrow {
             margin-right: 0;
             //top: 35%;
             top: 30%;
             //right: 2px;
             right: 12px;
             color: #382e34;
           }
           .ant-select-selection {
             background-color: transparent;
             width: 88px;
           }
           &:hover {
             //height: 30px;
             border: 1px solid #EDA41E;
             //padding-left: 3px;
             border-radius: 3px;
             //padding-bottom: 30px;
             color: #EDA41E;
             //width: 80px;
             //border: 1px solid #EDA41E;
             .ant-select-selection {
               color: #EDA41E !important;
               height: 20px;
             }
             .ant-select-arrow {
               color: #EDA41E !important;
             }
           }
         }
         .buttons-control-pagination {
           float: left;
           display: flex;
           align-items: center;
           font-family: Roboto;
           font-size: 14px;
           color: #382e34;
           font-weight: 400;
           letter-spacing: 0.2px;
           line-height: 20px;
           &__text {
             margin-left: 10px;
             margin-right: 10px;
           }
           .disabled-back {
             color: #D4D7DA;
           }
           .disabled-next {
             color: #D4D7DA;
           }
           .disabled-text {
             color: #D4D7DA;
           }
           .insert-page {
             text-align: center;
             width: 42px;
             //padding: 7px 2px;
             margin-right: 5px;
             padding: 0px;
             font-weight: 400;
             height: 20px;
             border: 1px solid #382e34;
             font-size: 14px;
             letter-spacing: 0.2px;
             line-height: 20px;
             background-color: #FFFFFF;
             border-radius: 2px;
             color: #382e34;
             &:focus {
               border-color: #EDA41E;
               outline: none;
             }
           }
           .insert-page:focus-visible {
             border: 1px solid #EDA41E;
           }
           .insert-page:focus {
             border: 1px solid #EDA41E;
           }
           .insert-page:hover {
             border: 1px solid #EDA41E;
           }
           .icon-v14-start-active {
             font-size: 20px;
             color: #EDA41E;
           }
           .icon-v14-star {
             font-size: 20px;
             color: #EDA41E;
           }
           .back-active {
             font-size: 20px;
             color: #EDA41E;
           }
           .icon-v21-back-active {
             font-size: 20px;
             color: #EDA41E;
           }
           .icon-v21-forth-active {
             font-size: 20px;
             color: #EDA41E;
           }
           .back-active:before {
             background-color: #EDA41E;
             border-radius: 50px;
           }
           .icon-v14-start-1 {
             font-size: 20px;
           }
           .icon-v14-start-1:before {
             //margin-left: 0.2em;
           }
           [class^="icon-v14"]:before {
             margin-left: 0.2em;
           }
           .icon-v14-end-active {
             font-size: 20px;
             color: #EDA41E;
           }
           .forth-1-active {
             font-size: 20px;
             color: #EDA41E;
           }
           .forth-1-active:before {
             background-color: #EDA41E;
             border-radius: 50px;
           }
           .icon-v14-back-1 {
             font-size: 20px;
           }
           .icon-v14-forth-1 {
             font-size: 20px;
           }
           .disabled-pagination {
             cursor: not-allowed;
             //opacity: 0.6;
             font-weight: 400;
             //font-size: 14px;
             color: #D4D7DA;
           }
           .icon-v14-end-1 {
             font-size: 20px;
           }
         }
         .input-search-duplicates-update {
           .ant-input {
             border: 1px solid #382e34;
             font-size: 14px;
             letter-spacing: 0.2px;
             width: 200px;
             height: 30px;
             padding: 10px 7px 10px 7px;
           }
           .ant-input-affix-wrapper .ant-input-suffix {
             right: 10px;
             z-index: 1;
           }
           .search-duplicates {
             display: flex;
             justify-content: flex-end;
             .ant-input {
               font-weight: 400;
               line-height: 20px;
               letter-spacing: 0.2px;
             }
             .ant-input:placeholder-shown {
               //width: 150px;
               height: 30px;
               //padding: 10px 5px 5px 5px;
               font-size: 14px;
               color: #a6acb2;
             }
             .ant-input:hover {
               border: 1px solid #EDA41E !important;
             }
             .ant-input:focus {
               border: 1px solid #EDA41E !important;
             }
           }
           input[type=text]:disabled {
             color: #D4D7DA !important;
             background: #FFFFFF;
             border: 1px solid #D4D7DA;
           }
           input[type=text]:disabled:hover {
             color: #D4D7DA !important;
             background: #FFFFFF;
             border: 1px solid #D4D7DA !important;
           }
           .ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
             font-size: 20px;
             border-left: none;
             padding-left: 5px;
             color: #D4D7DA !important;
           }
           .ant-input-search-icon {
             font-size: 20px;
             border-left: 1px solid #382e34;
             padding-left: 5px;
             color: #150010 !important;
           }
           .ant-input:focus {
             box-shadow: none;
           }
           .ant-input-clear-icon + i {
             margin-left: 0px;
           }
         }
         .input-search-duplicates {
           .search-duplicates-list {
             //padding: 10px 5px 5px 5px;
           }
           .search-duplicates {
             width: 200px;
             height: 30px;
             .ant-input {
               font-family: Roboto;
               font-weight: 400;
               color: #150010;
               letter-spacing: 0.2px;
               alignment: left !important;
               vertical-align: middle;
               font-size: 14px;
               border-radius: 0px;
             }
             .ant-input:placeholder-shown {
               font-family: Roboto;
               font-weight: 400;
               color: #a6acb2;
               letter-spacing: 0.2px;
               alignment: left;
               vertical-align: middle;
             }
             .ant-input:hover {
               border: 1px solid #00d4d8;
             }
             .ant-input:focus {
               border: 1px solid #00d4d8;
             }
             .ant-input-search-icon {
               font-size: 20px;
               border-left: 1px solid #382e34;
               padding-left: 7px;
               color: #382e34;
             }
             .ant-input-clear-icon {
               color: #382e34;
               font-size: 16px;
               padding-right: 7px;
             }
           }
         }
         .search-list-update {
           .ant-input {
             border: 1px solid #382e34;
             font-size: 14px;
             letter-spacing: 0.2px;
           }
           .search-duplicates {
             .ant-input:placeholder-shown {
               height: 30px;
               padding: 10px 5px 8px 5px;
               font-size: 14px;
             }
             .ant-input:hover {
               border: 1px solid #EDA41E !important;
             }
             .ant-input:focus {
               border: 1px solid #EDA41E !important;
             }
           }
         }
         .total-files-header {
           .total-files-content {
             display: flex;
             float: right;
             align-items: center;
             .number-duplicate {
               border: 1px solid #491bc2;
               background: #491bc2;
               color: #ffffff;
               padding: 5px;
               border-radius: 25px;
               height: 20px;
               width: 100%;
               margin-right: 5px;
               font-size: 14px;
               .nr-dupl {
                 display: flex;
                 position: relative;
                 bottom: 5px;
               }
             }
             .title-duplicate {
               color: #481bc1;
               alignment: left;
               vertical-align: middle;
               letter-spacing: 0.2px;
               font-weight: 400;
             }
           }
         }
         .clean-header-duplicates {
           .icon-v12-question {
             font-size: 18px;
           }
           .disabled-icon-v14-deleted {
             opacity: 0.6;
             cursor: not-allowed;
           }
           .icon-v14-delete {
             font-size: 20px;
           }
           .icon-v14-delete-active {
             font-size: 20px;
             color: var(--generalIconFontHover);
             cursor: pointer;
           }
         }
         .button-control {
           float: left;
           button {
             height: 20px;
             width: 40px;
             padding: 0;
             border-radius: 0;
             font-weight: 100;
             &:hover {
               background-color: #EDA41E;
               border: solid 1px #EDA41E;
               color: #fff;
             }
           }

           &__left {
             border-bottom-left-radius: 18.5px !important;
             border-top-left-radius: 18.5px !important;
             background: #FFFFFF;
             border: solid 1px #382e34 !important;
             color: #382e34;
             border-right:solid  0 !important;
             &:disabled{
               background: #fff;
               border: solid 1px #D4D7DA !important;
               color: #D4D7DA;
               &:hover {
                 background: #fff;
                 border: solid 1px #D4D7DA !important;
                 color: #D4D7DA;
               }
             }
           }

           &__right {
             border-bottom-right-radius: 18.5px !important;
             border-top-right-radius: 18.5px !important;
             background-color: #FFFFFF;
             border: solid 1px #382e34;
             color: #382e34;
             &:disabled{
               background: #fff;
               border: solid 1px #D4D7DA !important;
               color: #D4D7DA;
               &:hover {
                 background: #fff;
                 border: solid 1px #D4D7DA !important;
                 color: #D4D7DA;
               }
             }
           }

           &__text {
             padding-right: 10px;
           }

           &__redact {

           }
         }
         .clean-header-duplicates {
           .dropdown-menu {
             top: 0px !important;
             left: -59px !important;
             width: 200px;
             border: 1px solid #CCCCCC !important;
             box-shadow: none;
             padding: 0px;
           }
           .dropdown-toggle::after {
             display: none;
           }
           .dropdown-item {
             font-weight: 400;
             font-size: 14px;
             color: #6e7882;
             letter-spacing: 0.2px;
           }
           .dots-menu-delete-duplicate {
             .icon-v14-clean-dropdown {
               font-size: 20px;
               color: #6e7882;
             }
             .icon-v14-clean-dropdown-active {
               font-size: 20px;
               color: #00d4d8;
             }
             button {
               color: black !important;
               padding: 0 !important;
               background-color: transparent !important;
               border: none !important;
               font-size: 20px;
               line-height: 22px;
               outline: none;
               margin-top: -15px;

               &:focus {
                 box-shadow: none;
               }
             }
             .dropdown-menu {
               top: 0px !important;
               left: -59px !important;
               width: 200px;
               border: 1px solid #CCCCCC !important;
               box-shadow: none;
             }
             .dropdown-menu {
               width: 190px;
               border: 1px solid #000000;
             }
             .btn-primary.focus, .btn-primary:focus {
               box-shadow: 0 0 0 0.2rem transparent;
             }
           }
         }
       }

       .table-new-clutter-content {
         margin-top: 10px;
         border-top: 1px solid #382e34;
         tbody {
           vertical-align: middle !important;
         }
         .ant-table colgroup > col.ant-table-selection-col {
           width: 30px;
         }
         .table-duplicates-files {
           .ant-checkbox-checked:focus {
             .ant-checkbox-inner {
               border: 1px solid #EDA41E;
             }
           }
           .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
             border-color: var(--generalIconFontHover);
           }
           .ant-table-tbody {
             vertical-align: middle !important;
           }
           .ant-checkbox-checked .ant-checkbox-inner {
             background-color:var(--generalIconFontActive) !important;
             border: 1px solid #6e7882;
           }
           .ant-table-tbody > tr > td {
             //border-bottom: 1px solid #6e7882;
             background: #FFFFFF;
           }
           .ant-table-tbody > tr > td.ant-table-selection-column {
             text-align: left;
             padding-left: 5px;
             width: 31px;
           }
           .ant-checkbox-checked .ant-checkbox-inner::after {
             border-color: #FFFFFF;
             position: absolute !important;
             display: table;
             color: #FFFFFF !important;
             border: none !important;
             border-top: 0;
             border-left: 0;
             -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
             -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
             transform: rotate(0deg) scale(1) translate(-50%, -50%);
             opacity: 1;
             -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
             transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
             content: '\e8ce' !important;
             top: 7px !important;
             left: 7px !important;
             font-size: 12px !important;
             font-family: 'smartbox_icons_v19' !important;
           }
           .ant-checkbox-inner:after {
             border-color: #ffffff !important;
           }
           .ant-checkbox-wrapper .ant-checkbox-inner {
             border-radius: 0% !important;
             border: 1px solid #6e7882;
             //border: 1px solid #EDA41E;
           }
           .ant-table-tbody > tr {
             vertical-align: initial !important;
           }
           .ant-checkbox-checked:hover {
             .ant-checkbox-inner {
               background-color: var(--generalIconFontActive) !important;
               border: 1px solid var(--generalIconFontHover);
             }
           }
           .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
             background: #9ae0fc50;
             cursor: pointer;
           }
           .ant-table-row:hover {
             //background: #e6f7ff;
           }
           .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
             height: 30px;
             padding: 0px;
             vertical-align: middle;
           }
           .rotate-arrow-medium {
             -webkit-transform: rotate(270deg);
             writing-mode: vertical-rl;
           }
           .icon-v16-arrow-medium {
             font-size: 20px !important;
             //height: 20px;
           }
           .text-style {
             font-family: Roboto;
             font-weight: 400;
             font-size: 14px;
             color: #6e7882;
             alignment: left;
             vertical-align: middle;
           }
           .location-files {
             padding-top: 0px !important;
             display: flex;
             justify-content: flex-start;
           }
           .total-files {
             padding-top: 0px !important;
             display: flex;
             justify-content: flex-end;
             .total-files-content {
               display: flex;
               float: right;
               align-items: center;
               .number-duplicate {
                 border: 1px solid #491bc2;
                 background: #491bc2;
                 color: #ffffff;
                 padding: 5px;
                 border-radius: 25px;
                 height: 20px;
                 width: 100%;
                 margin-right: 20px;
                 //margin-top: 5px;
                 .nr-dupl {
                   display: flex;
                   position: relative;
                   bottom: 5px;
                 }
               }
             }
           }
           .column-name-duplicate {
             //display: flex;
             //align-items: center;
             width: 40%;
             margin-left: 20px;
             padding-left: 20px !important;
             span {
               vertical-align: middle !important;
             }
             .name-duplicate-content {
               vertical-align: middle !important;
               span {
                 vertical-align: middle !important;
               }
               .icon-v14-star-active {
                 color: #EDA41E;
                 font-size: 20px;
                 margin-right: 10px;
               }
               .icon-v14-star {
                 color: #A6ACB2;
                 font-size: 20px;
                 margin-right: 10px;
               }
             }
           }
         }
       }

       .table-new-clutter-content-list {
         .content-date {
           display: flex;
           align-items: center;
           .content-buttons-sorter {
             display: flex;
             flex-direction: column;
             align-items: center;
             height: 20px;
             line-height: 12px;

             .icon-v16-arrow-bold:before {
               line-height: 9px;
             }

             .active-sort {
               color: #EDA41E;
             }

             .rotate-arrow {
               -webkit-transform: rotate(180deg);
               display: flex;
             }
           }
         }
         .ant-spin-nested-loading {
           padding-top: 20px;
         }
         .table-duplicates-list {
           vertical-align: middle;
           .ant-table-column-title {
             margin-right: 0px !important;
             span {
               margin-right: 0px !important;
             }
           }
           .ant-tooltip-inner {
             display: none;
           }
           .update-list-name {
             display: flex;
             align-items: center;
             .icon-v14-delete {
               &::before {
                 margin-left: -2px !important;
               }
             }
             .icon-v14-delete:before {
               margin-right: 8px;
               margin-left: 8px !important;
             }
             .ant-checkbox-wrapper {
               //margin-right: 10px;
               margin-top: 5px;
               margin-right: 0px;
               margin-bottom: 4px;
             }
             .ant-checkbox-checked .ant-checkbox-inner {
               background-color: #EDA41E !important;
               border: 1px solid #6e7882;
             }
             .ant-checkbox-inner:after {
               border-color: #ffffff !important;
             }
             .ant-checkbox-wrapper .ant-checkbox-inner {
               border-radius: 0% !important;
               margin-right: 10px;
               margin-left: 10px;
             }
             .ant-checkbox {
               left: 0px;
             }
           }
           .ant-checkbox {
             left: 12px;
           }
           .ant-checkbox-inner {
             top: 0px;
           }
           .ant-table-tbody > tr.ant-table-row-selected td {
             .title-row {
               font-weight: 700;
             }
             .duplicate-list-name {
               .icon-v14-delete {
                 font-size: 16px;
               }
               .icon-v14-delete:before {
                 margin-left: -2px !important;
               }
               .title-row {
                 font-weight: 700;
                 width: 100%;
               }
             }
             .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
               background: #9ae0fc50;
             }
             .column-duplicate-list {
               .title-row {
                 font-weight: 700;
                 width: 100%;
               }
               .star-gold {
                 color: #EDA41E !important;
               }
             }
           }
           .ant-table-tbody > tr > td {
             border-bottom: none;
           }
           .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
             background: #9ae0fc50 !important;
           }
           .ant-table-thead > tr > th {
             border-bottom: none;
           }
           .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
             background-color: var(--generalIconFontActive) !important;
             border: 1px solid #6e7882;
           }
           .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
             border-color: #FFFFFF;
             background-color: transparent !important;
           }
           .ant-checkbox-indeterminate .ant-checkbox-inner::after {
             background-color: #3DD8DB
           }
           .ant-table-row-level-0 {
             .ant-table-selection-column {
               .ant-checkbox-wrapper {
                 padding-top: 5px;
                 .ant-checkbox {
                   left: 12px;
                 }
               }
             }
           }
           .selected-row-active {
             background-color: transparent;
             cursor: pointer;
             .column-duplicate-list .title-row {
               font-size: 14px;
               font-weight: 500;
               color: #6e7882;
               width: 100%;
             }
           }
           .ant-table-tbody > tr {
             cursor: pointer;
           }
           .row-selected-table {
             background-color: #DCF1F9;
             border-bottom: 1px solid #9AE0FC;
             .ant-table-tbody > tr > td {
               border: 1px solid #9AE0FC;
             }
           }
           .column-duplicate-list {
             letter-spacing: 0.2px;
             alignment: left;
             .duplicate-list-name-header {
               .ant-checkbox-wrapper {
                 margin-right: 0px;
               }
               .ant-checkbox-checked .ant-checkbox-inner {
                 background-color: #3dd8db !important;
                 border: 1px solid #6e7882;
               }
               .ant-checkbox-inner:after {
                 border-color: #ffffff !important;
               }
               .ant-checkbox-wrapper .ant-checkbox-inner {
                 border-radius: 0% !important;
               }
               .ant-checkbox {
                 left: 0px;
               }
               .ant-checkbox-inner {
                 //left: -11px;
               }
               .name-header {
                 margin-left: 26px;
               }
               .star-empty {
                 color: #a6acb2;
               }
               .star-gold {
                 color: #EDA41E;
               }
               .icon-v14-star-active {
                 font-size: 20px;
                 position: absolute;
                 left: 5px;
               }
               .icon-v14-star-active:before {
                 top: -5px;
                 position: relative;
               }
               .icon-v14-star {
                 font-size: 20px;
                 position: absolute;
                 left: 5px;
               }
               .icon-v14-star:before {
                 top: -5px;
                 position: relative;
               }
             }
             .duplicate-list-name {
               .icon-v14-delete {
                 font-size: 20px;
               }
               .icon-v14-star {
                 font-size: 20px;
                 position: absolute;
                 left: 5px;
               }
               .icon-v14-star:before {
                 top: -6px;
                 position: relative;
               }
               .icon-v14-star-active {
                 font-size: 20px;
                 position: absolute;
                 left: 5px;
               }
               .icon-v14-star-active:before {
                 top: -6px;
                 position: relative;
               }
               .star-gold {
                 color: #EDA41E;
               }
               .star-silver {
                 color: #a6acb2;
               }
             }
             .menu-duplicate {
               .dropdown-item {
                 color: #6e7882;
                 letter-spacing: 0.2px;
               }
               .dropdown a:hover {
                 background-color: #9AE0FC;
                 color: #6e7882;
               }
               .dropdown .dropdown-menu {
                 background-color: #FFFFFF;
                 box-shadow: none;
                 position: absolute;
                 top: 3px !important;
                 left: -40px !important;
                 width: 180px;
                 height: 40px;
                 border: 1px solid #6e7882;
               }
             }
             .ellipsis-v2 {
               bottom: 0px;
               .icon-v14-options-small {
                 font-size: 20px;
               }
               .icon-v14-options-small-active {
                 font-size: 20px;
               }
             }
             .title-row {
               font-size: 14px;
               font-weight: 400;
               color: #6e7882;
               width: 100%;
               display: flex;
             }
             .ant-table-header-column {
               font-size: 14px;
               font-weight: 400;
               color: #382e34;
             }
           }
           .ant-table-thead {
             background-color: #FFFFFF;
             height: 30px;
           }
           .ant-table-thead > tr > th {
             background-color: #FFFFFF;
             height: 30px;
           }
           .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
             padding: 0px 0px 0px 0px;
             overflow-wrap: break-word;
             width: 40%;
           }
         }
       }
     }
  }
}