.options-container {
  width: 100%;
  max-width: 250px;
  .item {
    padding-bottom: 10px;
    &:last-of-type {
      padding-bottom: 0;
    }
    &__option-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding-left: 28px;
    }
  }

  .minus {
    background-image: url("../../assets/data-source-icons/minus.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    float: right;
    cursor: pointer;
  }
  .circle-fill {
    background-image: url("../../assets/data-source-icons/circle-fill.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    width: 15px;
    height: 15px;
  }
}
.options-input {
  width: 100%;
  border-bottom: 1px solid #00d3d8;

  input {
    border: none;
    background-color: transparent;
    height: 16px;
    padding-left: 0;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .add {
    background-image: url("../../assets/data-source-icons/add.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    float: right;
    cursor: pointer;
  }
}