.privacyDashboard__topicItem-wrapper {
  border-bottom: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  padding: 20px;
  width: 100%;
}

.privacyDashboard__topicItem-title {
  color: #162737;
  font-size: 18px;
  margin-bottom: 10px;
}

.privacyDashboard__topicItem-listItem {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.privacyDashboard__topicItem-listItem-title {
  color: #111C26;
  font-size: 14px;
  flex: 1;
}

.privacyDashboard__topicItem-listItem-valueWrapper {
  position: relative;
  width: 60px;
}

.privacyDashboard__topicItem-listItem-value {
  background-color: #DADCE0;
  height: 10px;
  position: absolute;
  right: 0;
  top: -5px;
}