.questionnaireOptions__formWrapper {
  padding: 15px;
  border-bottom: 1px solid #E6E6E6;
  cursor: move;
}

.questionnaireOptions__formWrapperError {
  padding: 15px;
  border-style: solid;
  border-color: red;
  border-width: 0 0 1px 5px;
}

.questionnaireOptions__form {
  align-items: center;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.questionnaireOptions__formMove {
  cursor: move;
}

.questionnaireOptions__formWrapper:last-of-type {
  border-bottom: none;
  padding-bottom: 20px;
}

.questionnaireOptions__formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 5px 20px 5px 20px;
}

.questionnaireOptions__messageButton {
  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin-left: 5px;
  width: 20px;
}

.questionnaireOptions__messageLabel {
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 20px;
  position: absolute;
  width: 20px;
  z-index: 1;
}

.questionnaireOptions__messageLabelSelected {
  background-color: #3DD8DB;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 14px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 14px;
  z-index: 1;
}

.questionnaireOptions__form {
input {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #555555;
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
&:disabled {
   color: #AAAAAA;
   border: 1px solid #E6E6E6;
   cursor: not-allowed;
 }
}
select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  color: #555555;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
}

.questionnaireOptions__formTitle {
  padding-left: 15px;
  margin-right: 17px;
  flex: 1;
  min-width: 280px;
}

.questionnaireOptions__formDivider {
  padding-left: 15px;
  width: 100%;
}

.questionnaireOptions__formMessage {
  margin-right: 20px;
}

.questionnaireOptions__formScore {
  display: flex;
  width: 60px;
  justify-content: space-between;
  margin-right: 20px;
input {
  text-align: center;
}
}

.questionnaireOptions__formJump {
  min-width: 240px;
select {
  width: 100%;
}
}

.questionnaireOptions__formScore input {
  width: 60px;
}

.questionnaireOptions__removeButton {
  border-radius: 12px;
  border: 2px solid;
  color: #DCDCDC;
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  text-align: center;
  width: 20px;
  position: relative;
  margin-left: 10px;
&::after {
   content: 'x';
   font-size: 14px;
   position: absolute;
   left: 4px;
 }
}

.questionnaireOptions__removeButtonDisabled {
  border-radius: 12px;
  border: 2px solid;
  color: #EEEEEE;
  cursor: not-allowed;
  font-weight: bold;
  height: 20px;
  text-align: center;
  width: 20px;
  position: relative;
  margin-left: 10px;
&:after {
   content: 'x';
   font-size: 14px;
   position: absolute;
   left: 4px;
 }
}

.questionnaireOptions__justification {
  padding-left: 33px;
  padding-right: 30px;
input {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #555555;
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
}

@media screen and (min-width: 768px) {
  .questionnaireOptions__formWrapper {
    border-top: none;
    justify-content: flex-start;
  }
}