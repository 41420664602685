.privacyFolderItem-header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  min-height: 70px;
  position: relative;
}

.privacyFolderItem-headerOpened {
  background-color: #FFFFFF;
  border-bottom: 2px solid #D6D3D3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  min-height: 70px;
  position: relative;
}

.privacyFolderItem-content {
  border-right: 1px solid #DCDCDC;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-right: 57px;
}

.privacyFolderItem-headerOpened .privacyFolderItem-content {
  border-bottom-left-radius: 0;
}

.privacyFolderItem-contentHigh {
  border-left: 7px solid #F44336;
}

.privacyFolderItem-contentMedium {
  border-left: 7px solid #F0B400;
}

.privacyFolderItem-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.privacyFolderItem-info strong {
  font-size: 16px;
}

.privacyFolderItem-contentTitle {
  color: #6E7882;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.privacyFolderItem-contentText {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.privacyFolderItem-contentSmallItem {
  border-bottom: 1px solid #DCDCDC;
  padding: 10px 20px;
}

.privacyFolderItem-contentBigItem {
  flex: 1;
  padding: 10px 20px;
}

.privacyFolderItem-toggleHeader {
  cursor: pointer;
  margin-top: -6px;
  position: absolute;
  right: 43px;
  top: 50%;
  transition: all .2s;
}

.privacyFolderItem-toggleHeader:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 15px solid #B0B0B0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.privacyFolderItem-toggleHeader:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 0;
  border-top: 13px solid #FFFFFF;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}

.privacyFolderItem-toggleHeaderOpened {
  cursor: pointer;
  margin-top: 6px;
  position: absolute;
  right: 13px;
  top: 50%;
  transition: all .2s;
  transform: rotate(180deg);
}

.privacyFolderItem-toggleHeaderOpened:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 15px solid #B0B0B0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.privacyFolderItem-toggleHeaderOpened:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 0;
  border-top: 13px solid #fff;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}

.privacyFolderItem-buttonWrapper {
  border-top: 1px solid #DCDCDC;
  color: #1DCBCF;
  font-size: 14px;
  font-weight: 700;
  padding: 20px;
  text-transform: uppercase;
}

.privacyFolderItem__loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* .privacyFolderItem__loading--dateTitle {
  height: 12px;
  margin-bottom: 5px;
  width: 100%;
}

.privacyFolderItem__loading--dateText {
  height: 14px;
  width: 100%;
} */

@media screen and (min-width: 768px) {
  .privacyFolderItem-content {
    align-items: center;
    flex-direction: row;
    padding: 15px 5px;
  }

  .privacyFolderItem-info {
    align-items: center;
    flex-direction: row;
  }
  
  .privacyFolderItem__lastModified {
    border-right: 1px solid #DCDCDC;
    width: 130px;
  }

  .privacyFolderItem-contentSmallItem {
    border-bottom: none;
    min-width: 140px;
    padding: 5px 20px;
  }

  .privacyFolderItem-contentBigItem {
    padding: 5px 20px;
  }

  .privacyFolderItem-buttonWrapper {
    border-top: none;
    padding: 0 20px;
  }
}