.invite-user-page {
  margin: 0 auto;
  max-width: 612px;
  padding: 120px 20px 0;
}
.invite-user-page-update {
  .invite-user-box-update{
    .pageTitle-header {
      padding: 0px;
      margin-top: 0px;
    }
    .invite-user-section {
      border-top:none;
      padding:0;
      .new-invite-user-form-group {
        //display: flex;
        .invite-user-form-group-update {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          input {
            width: 50%;
            height:40px;
            border-radius:0;
            border:1px solid #6e7882;
            padding:0 10px;
            background-color: #fff;
            color:#6e7882;
            font-size:16px;
            font-weight: 400;
          }
          input:focus{
            outline:none;
            color:#A6ACB2;
            border:1px solid #EDA41E;
          }
          input:hover {
            color:#A6ACB2;
            border:1px solid #EDA41E;
          }
          input:active {
            color:#A6ACB2;
            border:1px solid #EDA41E;
          }
          label {
            font-family: 'Roboto';
            font-weight: 400;
            color:#6e7882;
            margin-bottom: 10px !important;
            line-height: 20px;
            display: initial;
          }
        }



      }
    }
    .send-invite-btn-wrapper {
      .btn-loading-state {
        background-color: #FFC457;
        border: 1px solid #FFC457;
        pointer-events: none;
      }
      .send-invite-spinner {
        position: relative;
        right: 154px;
        color: #FFFFFF;
      }
    }
.content-factor-authentication-invite-update
    .switcher-option-check-invite {
  .ant-switch-inner{
    margin-left: 24px;
    font-size: 16px;
  }
  .ant-switch::after {
    left: 4px;
    width: 19px;
    height: 19px;
    border:1px solid #6E7882;
  }
  .ant-switch-checked{
    background-color: var(--generalButton);
    .ant-switch-inner{
      margin-right: 20px;
      margin-left: 7px;
      margin-top: 1px;
      font-size: 16px;
    }
  }
  .ant-switch-checked::after{
    left:100%;
    width: 19px;
    height: 19px;
    top:2px;
    margin-left: -3px;
  }
  .ant-switch {
    height:25px;
  }
}

  }
  input[type=submit] {
    //border: 1px solid #6E7882; TASK 20 GRAV-2558
    //background-color: #EDA41E;
    border-radius: 20px;
    background-color: var(--generalButton);
    color: var(--generalButtonFont);
    border: 1px solid #6e7882;
    //box-shadow: 0 2px 4px 0 #dcdcdc;
    //cursor: pointer;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    //text-transform: uppercase;
    text-transform: initial;
    transition: all .2s;
    height:25px;
    padding:0px;
    letter-spacing: 0.5px;
    margin-top:60px;
    margin-bottom: 90px;
    width: 170px;
    box-shadow: none;
  }
  input[type=submit]:disabled {
    border:1px solid #D4D7DA;
    background-color: #D4D7DA;
    cursor: default;
    color:#fff;
    &:active {
      border:1px solid #D4D7DA;
      background-color: #D4D7DA;
      cursor: default;
      color:#fff;
    }
  }
  input[type=submit]:hover:enabled{
    background-color: var(--generalButtonHover);
    color: var(--generalButtonFont);
    border: 1px solid var(--generalButtonHover);
  }

  input[type=submit]:active{
    background-color: var(--generalButtonActive);
    border:1px solid var(--generalButtonActive);
    //color:#eda41e;
  }

}

.invite-tenant-page-update {
  max-width: unset;
  .invite-tenant-box-update {
    .invite-user-form-update {
      flex-direction: column !important;
      .invite-address {
        //margin-top: 10px;
      }
      .checked-invite {
        margin-bottom: 5px !important;
      }
      .company-name {
        margin-bottom: 10px !important;
      }
      .ant-checkbox + span {
        font-size: 12px;
        font-family: Roboto;
        color: #382e34;
        font-weight: 700;
      }
      .ant-checkbox-wrapper .ant-checkbox-inner {
        border-radius: 0% !important;
        border: 1px solid #6e7882;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border: 1px solid #fff;
        border-top: 0;
        border-left: 0;
        top: 46%;
        left: 19%;
      }
      .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
        border-color: #EDA41E;
      }
      .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: #FFFFFF;
        background-color: #FFFFFF;
        color: #FFFFFF;
        border: none !important;
        border-top: 0;
        border-left: 0;
        -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        transform: rotate(0deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        content: '\e8ce' !important;
        top: 7px !important;
        left: 7px !important;
        font-size: 12px !important;
        font-family: 'smartbox_icons_v19' !important;
      }
      .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--generalIconFontActive) !important;
        border: 1px solid #6e7882;
      }
    }
    .pageTitle-header {
      padding: 0px;
      margin-top: 30px;
    }
    .invite-user-section {
      padding: 0;
      border:none;
      .invite-user-form-group {
        //display:flex;
        margin-bottom:30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        label {
          font-family: 'Roboto';
          font-weight: 400;
          color:#6e7882;
          margin-bottom: 10px !important;
          line-height: 20px;
          display: initial;
        }
        input {
          height:40px;
          border-radius:0;
          border:1px solid #6e7882;
          padding:0 10px;
          background-color: #fff;
          color:#6e7882;
          font-size:16px;
          font-weight: 400;
          width:50%;
        }
        input:focus{
          outline:none;
          color:#A6ACB2;
          border:1px solid #EDA41E;
        }
        input:hover {
          color:#A6ACB2;
          border:1px solid #EDA41E;
        }

      }

    }
    .send-invite-btn-wrapper {
      .btn-loading-state {
        background-color: #FFC457;
        border: 1px solid #FFC457;
        pointer-events: none;
      }
      .send-invite-spinner {
        position: relative;
        right: 154px;
        color: #FFFFFF;
      }
    }

  }
  input[type=submit] {
    //border: 1px solid #6E7882; TASK 20 GRAV-2558
    //background-color: #EDA41E;
    border-radius: 20px;
    background-color: var(--generalButton);
    color: var(--generalButtonFont);
    border: 1px solid #6e7882;
    //box-shadow: 0 2px 4px 0 #dcdcdc;
    //cursor: pointer;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    //text-transform: uppercase;
    text-transform: initial;
    transition: all .2s;
    height:25px;
    padding:0px;
    letter-spacing: 0.5px;
    margin-top:60px;
    margin-bottom: 90px;
    width: 170px;
  }
  input[type=submit]:disabled {
    border:1px solid #D4D7DA;
    background-color: #D4D7DA;
    cursor: default;
    color:#fff;
  }
  input[type=submit]:hover:enabled{
    background-color: var(--generalButtonHover);
    color: var(--generalButtonFont);
    border: 1px solid var(--generalButtonHover);
  }

  input[type=submit]:active{
    background-color: var(--generalButtonActive);
    border:1px solid var(--generalButtonActive);
    //color:#eda41e;
  }
}
.invite-user-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative
}

.invite-user-section {
  border-top: 1px solid #E6E6E6;
  padding: 20px
}

.invite-user-section-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px
}

.invite-user-section-subtitle {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px
}
.invite-user-form {
  display: flex;
  flex-direction: column;
}

.invite-user-form-group {
  margin-bottom: 20px;
  width: 100%;
}

.invite-user-form-group label {
  color: #AAAAAA;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}

.invite-user-form-group input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
}

.invite-user-form-group input:focus {
  outline: 2px solid #3DD8DB;
}

.invite-user-submit {
  width: 145px;
}

.invite-user-message {
  margin-top: 20px
}

.invite-user-loading {
  display: flex;
  justify-content: center;
  padding-top: 22px;
  padding-left: 20px;
}
.new-invite-user-form {
  flex-direction: column !important;
  align-items: flex-start !important;
}

.new-invite-user-form-group {
  //display: flex;
  .invite-user-form-group {
    width: 330px;
  }
  .user-submit-mfa {
    margin-top: 22px;
  }
}
.content-factor-authentication-invite {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  .title-factor-invite {
    margin-bottom: 10px !important;
    line-height: 20px;
    font-family: 'Roboto';
    font-size:14px!important;
    font-weight: 700;
    color:#382e34!important;
  }
  .switcher-option-check-invite {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    font-size: 14px;
    position: relative;
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    button:disabled {
      border: 1px solid #6e7882 !important;
    }
    .ant-switch {
      min-width: 70px;
      height: 30px;
      background-color: #ffffff;
      border: 1px solid #6e7882;
    }
    .ant-switch-checked .ant-switch-inner {
      margin-right: 0px;
      margin-left: 23px;
      margin-top: 1px;
      font-size: 15px;
      color: #ffffff;
      font-weight: 400;
    }
    .ant-switch-inner {
      display: block;
      margin-right: 20px;
      margin-left: 0px;
      color: #6e7882;
      font-size: 15px;
      font-weight: 400;
      margin-top: 1px;
    }
    .ant-switch-checked {
      background-color: #3DD8DB;
    }
    .ant-switch::after {
      left: 42px;
      width: 22px;
      height: 22px;
      border: 1px solid #3DD8DB;
      top: 2px;
    }
    .ant-switch-checked::after {
      left: 40%;
      width: 22px;
      height: 22px;
      top: 3px;
      margin-left: -2px;
      border: 1px solid #6e7882;
    }
  }
}
@media screen and (min-width: 768px) {
  .invite-user-page {
    padding: 120px 40px 0;
  }
  .invite-user-page-update{
    padding:30px 0px;
    margin: 100px 82px;
  }
  .invite-tenant-page-update {
    padding:100px 82px 0;
  }

  .invite-user-form {
    align-items: flex-end;
    flex-direction: row;
  }

  .invite-user-form-group {
    margin-bottom: 0;
    //margin-right: 20px;
    width: 410px;
  }
  .invite-user-form-group-update {
    margin-right: 0px;
  }
  .invite-user-submit {
    height: 43px;
  }
}
.canvas-area{
  position: absolute;
}
.react-pdf__Document{
  //position:absolute !important;
}
// #divToPrint {
// }
.download{
  font-size: 20px;
  font-weight: bold;
}
.canvas-area{
  position: absolute;
}
.react-pdf__Document{
  //position:absolute !important;
}
// #divToPrint {
// }
.download{
  font-size: 20px;
  font-weight: bold;
}
.user-invite-tenant-company {
  .ant-modal-content {
    width: 950px;
  }
  .ant-modal-header {
    .ant-modal-title {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      color: #382e34;
    }
  }
  .invite-tenant-company-content {
    .client-list-data {
      height: 600px;
      max-height: 600px;
      overflow: auto;
      width: 900px;
      max-width: 900px;
      .ant-table table {
        width: 880px;
      }
    }
    .row-selected-table {
      background-color: #DCF1F9;
      border-bottom: 1px solid #9AE0FC;
      cursor: pointer;
      .ant-table-tbody > tr > td {
        border: 1px solid #9AE0FC;
      }
    }
    .row-selected-table:hover {
      background-color: #DCF1F9;
      border-bottom: 1px solid #9AE0FC;
      .ant-table-tbody > tr > td {
        border: 1px solid #9AE0FC;
      }
    }
    .ant-table-tbody .ant-table-row {
      cursor: pointer;
    }
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #e6f7ff;
    }
    .footer-buttons-action-invite {
      display: flex;
      justify-content: space-between;
      height: 25px;
      margin-top: 30px;
      .action-cancel-invite {
        width: 216px;
        height: 25px;
        border: 1px solid #00D3D8;
        background-color: #FFFFFF;
        color: #00D3D8;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 2px 30px;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          background-color: #00D3D8;
          border-color: #00D3D8;
          color: #FFFFFF;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      .action-invite {
        width: 216px;
        height: 25px;
        border: 1px solid #00D3D8;
        background-color: #00D3D8;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 2px 30px;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          background-color: #FFFFFF;
          border-color: #00D3D8;
          color: #00D3D8;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      .action-invite:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        &:hover {
          background-color: #00D3D8;
          border-color: #00D3D8;
          color: #FFFFFF;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
    }
  }
}