.backOffice-documentList-pagination-next{
  cursor: pointer;
  float: right;
  padding-right: 10px;
}

.backOffice-documentList-pagination-previous{
  cursor: pointer;
  float: right;
  padding-right: 10px;
}

.backOffice-documentList-pagination{
  padding: 5px;
}

.backOffice-documentList-pagination span{

}