.taxonomyItem__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32%;
  margin-bottom: 20px;
}

.taxonomyItem__header {
  border-bottom: 1px solid #E6E6E6;
  padding: 15px 50px 15px 15px;
  position: relative;
}

.taxonomyItem__title {
  color: #162737;
  font-size: 18px;
}

.taxonomyItem__content {
  padding: 20px;
}