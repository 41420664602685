.dropdown {
  position: relative;
  .dropdown-menu {
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 #CCCCCC;
    position: absolute;
    //top: 0 !important;
    //left: -70px !important;
    width: 180px;
  }

  a {
    border-bottom: 1px solid #CCCCCC;
    position: relative;
    //padding: 15px;
    padding:5px 10px;
    &:hover {
      background-color: rgba(154,224,252,.3137254902);
      //color:  white;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.manageUsers-page {
    //margin: 0 auto;
    //margin: 0 1rem;
    //padding: 120px 0 0;
    padding: 30px 82px 0;
    margin: 100px 0;
    //position: relative;
    .no_user_wrapper {
      width: 100%;
      text-align: center;
      background-color: #fff;
      padding: 50px;
      box-shadow: 0 2px 4px 0 #dcdcdc;
      h1 {
        color: #7f7f7f;
      }
    }
}
.manageGroups-tableContainerSecond {
    overflow: none;
    width: 100%;
}

.manageUsers-box {
    //box-shadow: 0 2px 4px 0 #DCDCDC;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
}
.manageUsers-box-update {
  box-shadow: none;
  background-color: transparent;
  border-radius: 0px;
  margin-bottom: 0px;
}
.manageUsers-table {
  //position: relative;
}
.manageUsers-table table {
  border-radius: 5px;
}
.manageUsers-table table td, .manageUsers-table table th {
  background-color: #FFFFFF;
}
.manageUsers-section {
    //padding: 0 20px 20px 20px;
}

.manageUsers-sectionTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px
}

.manageUsers-sectionSubtitle {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-bottom: 5px;
    color: #737e89;
}
.text-lightgray { color: #7f7f7f; }
.text-blue { color: #00babe;}
.mu_filter__header {
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
}
.filterDpia__wrapper .mu_filter__header {
  margin-top: 80px;
}
.icon_logged:after {
    background-color: #7A8B9A;
    border-radius: 50%;
    color: #FFFFFF;
    content: '\f00c';
    font-family: 'FontAwesome';
    display: inline-block;
    width: 25px;
    height: 25px;
    padding-top: 4px;
    padding-left: 5px;
    font-style:normal;

}
.icon_logged_x:after {
    background-color: #FF0000;
    border-radius: 50%;
    color: #FFFFFF;
    content: '\f00d';
    font-family: 'FontAwesome';
    display: inline-block;
    width: 25px;
    height: 25px;
    padding-top: 4px;
    padding-left: 7px;
    font-style:normal;
}
.mto-5 { margin-top: 5px;}
#dropdown-undefined:focus {
    outline: none;
    box-shadow: none;
}
.ellipsis-v {
  writing-mode: vertical-rl;
  font-size: 26px;
  font-weight: 700;
  float:right;
  cursor:pointer;
  padding-top: 13px;
  &:focus {
    outline: none;
  }
}
.manageUsers-table .actionsDotsCell button { color: black !important; padding: 0 !important; }
.manageUsers-table .actionsDotsCell .dropdown-menu {
    left: -160px;
    padding: 0;
    top: 15px;
}

.manageUsers-table .actionsDotsCell.show .dropdown-menu {
  display:block;
}
.manageUsers-table table tr:last-of-type td {
    background-color: transparent;
    height: 100%;
    border-bottom: none;
}
.manageUsers-table table thead, .manageUsers-table table tr:nth-last-child(2) {
  //box-shadow: 0 2px 4px 0 #DCDCDC;
}

.last_login_cantainer {
    float: left;
    margin-left: 30%;
}
.questionnaireLastLogin__select {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
}
.questionnaireLastLogin__select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50% #FFFFFF;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    font-size: 16px;
    height: 42px;
    padding: 10px 25px 10px 10px;
    outline: none;
    width: 100%;
}
button:focus {
    outline: none;
}
.manageGroups-sliderChecked {
    cursor: pointer;
}
#name-tableContent {
    padding-right: 1rem;
}

#team-tableContent {
    padding-right: 1rem;
}
#mfa-tableContent {
  padding-right: 1rem;
}
#email-tableContent {
    padding-right: 1rem;
}

#login-tableContent {
    padding-right: 1rem;
}

.questionnaire-filter__input-find-on-focus {
    outline: 2px solid #3DD8DB;
    width: 100%;
  }
.not_allowed {
    cursor: not-allowed !important;
}
.questionnaireFilter {
  &__button {
    position: relative;
    &__clear_search {
      position: absolute;
      top: 29px;
      right: 10px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 768px) {
    .manageUsers-page {
        padding: 30px 82px 0;
        margin: 100px 0;
    }
    .mu_filter__header {
        justify-content: space-between;
        flex-direction: row;
    }
    .questionnaireLastLogin__select {
        margin: 0 10px 0 0;
        max-width: 300px;
        width: 20px;
    }
}

@media (max-width: 1000px) and (min-width: 870px) {
  .questionnaireFilter__select {
    width: 180px;
  }
}
@media (max-width: 870px) and (min-width: 767px) {
  .questionnaireFilter__select {
    margin-right: 5px;
    width: 160px;
  }
  .questionnaireFilter__button{
    margin-right: 5px;
  }
  .questionnaire-filter__input-find{
    margin-right: 0;
  }
}
.not-allowed{
    cursor: not-allowed;
}
.modal-content-roles {
  .swal2-actions {
    display: flex !important;
    justify-content: space-between !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    width: 100%;
  }
  .swal2-content {
    font-family: 'Roboto';
  }
}
.ownerTeam{
    font-weight: 900;
    border:2px solid #3DD8DB;
    border-radius: 13px;
    padding: 0.3rem;
    color: #FFF;
    cursor: not-allowed;
    background-color: #3DD8DB;
}
.swal2-actions {
  justify-content: space-around;
  width: 100%;
}
.modal-check-enable-user {
  .swal2-actions {
    display: flex !important;
    justify-content: space-between !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    width: 100%;
  }
  .swal2-content {
    font-family: 'Roboto';
  }
}