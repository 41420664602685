.privacyConnectorManage__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyConnectorManage__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
}

.privacyConnectorManage__boxHeader {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.privacyConnectorManage__boxTitle {
  color: #162737;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacyConnectorManage__boxTitleTotal {
  display: block;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
  position: relative;
}

.privacyConnectorManage__boxText {
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.privacyConnectorManage__boxHeader button {
  height: 34px;
}

.privacyConnectorManage__page .boxAddNewDataset__wrapper {
  margin-bottom: 40px;
}

.privacyConnectorManage__loading {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .privacyConnectorManage__boxHeader {
    flex-direction: row;
  }

  .privacyConnectorManage__boxText {
    margin-bottom: 0;
  }

  .privacyConnectorManage__boxTitleTotal {
    display: inline;
    font-size: 18px;
    margin: 0 0 0 10px;
    padding-left: 10px;
  }
  
  .privacyConnectorManage__boxTitleTotal::before {
    content: '-';
    left: 0;
    position: absolute;
    top: 0;
  }
}