.cull-report-page {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  width: 100%;
  background: #FFFFFF;
  margin-top: 34px !important;
  padding: 30px 40px 0;
  //padding: 30px 40px 20px 40px;
  //border: 2px solid rgba(0, 0, 0, 0.1);
  //margin-top: 40px;
  &__content {
    &__header {
      display: flex;
      align-items: center;
      .title-sweeped {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #382e34;
      }
      .icon-v14-question {
        font-size: 20px;
        color: #382e34;
        margin-left: 10px;
      }
      .help-message-locations {
        .content-help-message-locations {
          .text-information {
            font-size: 14px;
            font-weight: 400;
            margin-right: 3px;
          }
          .text-information-header {
            font-weight: 700;
            font-size: 14px;
          }
          .more-info-link {
            font-weight: 700;
            font-size: 14px;
            color: #00d4d8;
            cursor: pointer;
            text-decoration: underline;
            width: 65px;
            margin-left: 3px;
          }
        }
      }
    }

    &__cull-page {
      margin-top: 40px;
      .cull-report {
        .cull-report-header-list {
          width: 100% !important;
        }
        .cull-report-header {
          display: flex;
          align-items: center;
          width: 60%;
          justify-content: space-between;
          margin-bottom: 10px;

          .per-page-cull {
            border: 1px solid transparent;
            height: 20px;
            width: 88px;
            font-family: Roboto;
            font-size: 14px;
            color: #382e34;
            font-weight: 400;
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            border: 1px solid transparent;
            border-radius: 3px;
            position: relative;
            .icon-v19-arrow-medium {
              font-size: 20px;
              color: #382e34;
              position: absolute;
              left: 59px;
            }
            .icon-v19-arrow-medium:before {
              margin-right: 0px;
              margin-left: 0px;
            }
            .ant-select-arrow .ant-select-arrow-icon {
              display: none !important;
            }
            .ant-select-selection {
              border: none;
              border-radius: 3px;
            }
            .ant-select {
              height: 19px;
            }
            .ant-select-selection .ant-select-selection__rendered {
              margin-left: 0px;
            }
            .ant-select-selection--single .ant-select-selection__rendered {
              margin-right: 15px;
            }
            .ant-select-selection__rendered {
              line-height: 20px;
              letter-spacing: 0px;
              font-kerning: normal;
            }
            .ant-select-selection-selected-value {
              width: 62px;
              line-height: 19px;
            }
            .ant-select-selection--single {
              height: 20px;
              color: #382e34;
            }
            .ant-select .ant-select-selection .ant-select-arrow {
              margin-right: 0;
              top: 50%;
              right: 2px;
              color: #382e34;
            }
            .ant-select-selection {
              background-color: transparent;
            }
            &:hover {
              border: 1px solid #EDA41E;
              border-radius: 3px;
              color: #EDA41E;
              .icon-v19-arrow-medium {
                color: #EDA41E;
              }
              .ant-select-selection {
                color: #EDA41E !important;
                height: 20px;
              }
              .ant-select-arrow {
                color: #EDA41E !important;
              }
            }
          }
          .per-page-cull.disabled {
            color: #d4d7da;
            cursor: default;
            .ant-select-selection--single {
              height: 20px;
              color: #d4d7da;
            }
            .ant-select-disabled .ant-select-selection {
              cursor: default !important;
            }
            .icon-v19-arrow-medium {
              color: #d4d7da;
            }
            &:hover {
              border: 1px solid transparent;
              color: #d4d7da;
              cursor: default;
              .ant-select-selection {
                color: #d4d7da !important;
                cursor: default !important;
              }
            }
          }

          .buttons-control-pagination-cull {
            float: left;
            display: flex;
            align-items: center;
            font-family: Roboto;
            font-size: 14px;
            color: #382e34;
            font-weight: 400;
            letter-spacing: 0.2px;
            line-height: 20px;
            width: 40%;
            .disabled-text {
              color: #D4D7DA;
            }
            &__text {
              margin-left: 10px;
              margin-right: 10px;
            }
            .disabled-back {
              color: #D4D7DA;
            }
            .disabled-next {
              color: #D4D7DA;
            }
            .disabled-text {
              color: #D4D7DA;
            }
            .insert-page {
              text-align: center;
              width: 42px;
              //padding: 7px 2px;
              margin-right: 5px;
              padding: 0px;
              font-weight: 400;
              height: 20px;
              border: 1px solid #382e34;
              font-size: 14px;
              letter-spacing: 0.2px;
              line-height: 20px;
              background-color: #FFFFFF;
              border-radius: 2px;
              color: #382e34;
              &:focus {
                border-color: #EDA41E;
                outline: none;
              }
            }
            .insert-page:focus-visible {
              border: 1px solid #EDA41E;
            }
            .insert-page:focus {
              border: 1px solid #EDA41E;
            }
            .insert-page:hover {
              border: 1px solid #EDA41E;
            }
            .icon-v14-start-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .icon-v14-star {
              font-size: 20px;
              color: #EDA41E;
            }
            .back-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .icon-v21-back-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .icon-v21-forth-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .back-active:before {
              background-color: #EDA41E;
              border-radius: 50px;
            }
            .icon-v14-start-1 {
              font-size: 20px;
            }
            .icon-v14-start-1:before {
              //margin-left: 0.2em;
            }
            [class^="icon-v14"]:before {
              margin-left: 0.2em;
            }
            .icon-v14-end-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .forth-1-active {
              font-size: 20px;
              color: #EDA41E;
            }
            .forth-1-active:before {
              background-color: #EDA41E;
              border-radius: 50px;
            }
            .icon-v14-back-1 {
              font-size: 20px;
            }
            .icon-v14-forth-1 {
              font-size: 20px;
            }
            .disabled-pagination {
              cursor: default;
              //opacity: 0.6;
              font-weight: 400;
              //font-size: 14px;
              color: #D4D7DA;
            }
            .icon-v14-end-1 {
              font-size: 20px;
            }
          }

          .name-files-header {
            color: #382e34;
            display: flex;
            align-items: center;
            .header-back-file {
              display: flex;
              align-items: center;
            }
            .icon-v21-back-active {
              color: var(--generalIconFontHover);
              font-size: 20px;
              margin-right: 10px;
              cursor: pointer;
            }
            .icon-v21-back-1 {
              color: #382e34;
              font-size: 20px;
              margin-right: 10px;
            }
            .icon-v21-back-active:before {
              line-height: 30px;
            }
            .icon-v21-back-1:before {
              line-height: 30px;
            }
            .icon-v14-back-1 {
              font-size: 20px;
              margin-right: 10px;
              cursor: pointer;
              &:hover {
                color: #EDA41E;
              }
            }
            .icon-v14-back-1:before {
              line-height: 30px;
            }
            .title-files-active {
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.2px;
              //color: #EDA41E;
              line-height: 20px;
              color: #EDA41E;
              cursor: pointer;
            }
            .title-files {
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.2px;
              line-height: 20px;
              color: #382e34;
            }
          }
          .content-action-cull {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 9%;
            .number-items {
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.2px;
              line-height: 20px;
              color: #382e34;
            }
            .icon-v21-download-active-1 {
              color: var(--generalIconFontHover);
              font-size: 20px;
            }
            .disabled-download {
              color: #D4D7DA !important;
              cursor: default;
            }
            .icon-v21-download-1 {
              color: #382e34;
              font-size: 20px;
            }
          }
          .title-header-cull {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.2px;
            line-height: 20px;
            color: #382e34;
          }
        }
        .all-tables-cull {
          border-top: 1px solid #6e7882;
          .buttons-control-pagination {
            display: flex;
            align-items: center;
            font-family: Roboto;
            font-size: 14px;
            color: #382e34;
            font-weight: 400;
            letter-spacing: 0.2px;
            line-height: 20px;
            justify-content: center;
            &__text {
              margin-left: 5px;
              margin-right: 5px;
              span {
                font-weight: 400;
                font-size: 11px;
              }
            }
            .disabled-text {
              color: #D4D7DA;
            }
            .insert-page {
              text-align: center;
              width: 22px;
              padding: 3px 1px;
              margin-right: 5px;
              font-weight: 400;
              height: 15px;
              border: 1px solid #382e34;
              font-size: 11px;
              letter-spacing: 0.2px;
              line-height: 20px;
              border-radius: 2px;
              background-color: #FFFFFF;
              color: #382e34;
              &:focus {
                border-color: #EDA41E;
                outline: none;
              }
            }
            .insert-page:focus-visible {
              border: 1px solid #EDA41E;
            }
            .insert-page:focus {
              border: 1px solid #EDA41E;
            }
            .insert-page:hover {
              border: 1px solid #EDA41E;
            }
            .icon-v14-start-1 {
              font-size: 14px;
            }
            .icon-v14-start-active {
              font-size: 14px;
              color: #EDA41E;
            }
            .icon-v14-end-active {
              font-size: 14px;
              color: #EDA41E;
            }
            .icon-v21-back-1 {
              font-size: 14px;
              color: #382e34;
            }
            .icon-v21-forth-1 {
              font-size: 14px;
              color: #382e34;
            }
            .back-active {
              color: #EDA41E;
            }
            .disabled {
              color: #D4D7DA !important;
            }
            .forth-1-active {
              color: #EDA41E;
            }
            .icon-v21-back-active {
              color: #EDA41E;
              font-size: 14px;
            }
            .icon-v21-forth-active {
              color: #EDA41E;
              font-size: 14px;
            }
            .back-active {
              font-size: 14px;
            }
            .forth-1-active {
              font-size: 14px;
            }
            .icon-v14-back-1 {
              font-size: 14px;
            }
            .icon-v14-forth-1 {
              font-size: 14px;
            }
            .disabled-pagination {
              cursor: not-allowed;
              font-weight: 400;
              color: #D4D7DA;
            }
            .icon-v14-end-1 {
              font-size: 14px;
            }
          }
          .margin-table {
            margin-top: 30px;
          }
          .table-cull-content {
            .column-name-cull {
              width: 60%;
            }
            .ant-table-thead > tr > th {
              background: #FFFFFF;
              border-bottom: none !important;
              color: #382e34;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.2px;
              font-family: Roboto;
            }
            .ant-table-placeholder {
              border-top: none;
              border-bottom: none;
            }
            .table-cull-files {
              border-top: none;
            }
          }
        }
        .table-cull-content {
          margin-top: 10px;
          .table-cull-files {
            border-top: 1px solid #6e7882;
          }
          tbody {
            vertical-align: middle !important;
          }
          .ant-table colgroup > col.ant-table-selection-col {
            width: 30px;
          }
          .table-cull-files {
            .ant-table-tbody {
              vertical-align: middle !important;
            }
            .text-style {
              font-family: Roboto;
              font-weight: 400;
              font-size: 14px;
              color: #6e7882;
              letter-spacing: 0.2px !important;
              line-height: 20px !important;
            }
            .column-size {
              padding-left: 10px !important;
            }
            .column-name-cull {
              .total-items {
                display: flex;
                float: right;
                align-items: center;
                .rotate-arrow-medium {
                  -webkit-transform: rotate(270deg);
                  writing-mode: vertical-rl;
                }
                .icon-v16-arrow-medium {
                  font-size: 20px !important;
                }
              }
            }
            .ant-table-tbody > tr > td {
              background: #FFFFFF;
            }
            .ant-table-tbody > tr > td.ant-table-selection-column {
              text-align: left;
              padding-left: 5px;
              width: 31px;
            }
            .ant-table-tbody > tr {
              vertical-align: initial !important;
            }
            .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
              background: #9ae0fc50;
              cursor: pointer;
            }
            .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
              height: 30px;
              padding: 0px;
              vertical-align: middle;
            }
          }
        }

      }
    }
  }
}