.boxAddNewDataset__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 25px;
}

.boxAddNewDataset__flex {
  display: flex;
}

.boxAddNewDataset__wrapper img {
  height: 138px;
  width: 138px;
}

.boxAddNewDataset__content {
  margin-left: 25px;
  padding-top: 5px;
}

.boxAddNewDataset__title {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
}

.boxAddNewDataset__text {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
}

.boxAddNewDataset__wrapper button {
  height: 34px;
}

.boxAddNewDataset__wrapper .hideMobile {
  display: none;
}

.boxAddNewDataset__wrapper .showMobile {
  margin: 10px auto 0;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .boxAddNewDataset__title {
    margin: 10px 0;
  }

  .boxAddNewDataset__wrapper .hideMobile {
    display: block;
  }

  .boxAddNewDataset__wrapper .showMobile {
    display: none;
  }
}