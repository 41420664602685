.email-workflow {
   background-color: #FFFFFF;
  display: flex;
  //width: 100%;
  //padding: 160px 40px 40px;
  padding: 30px 0px;
  margin: 134px 82px;
  position: relative;
  .email-workflow-tabs {
    width: 100%;
  }
  &__contain {
    width: 100%;
    height: 100%;
    .header-emails-workflow {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color:#382e34;
      }
    }
    .template-emails {
      border-radius: 5px;
      position: relative;
      margin-top: 40px;
      .ant-tabs-nav-animated:before {
        margin-top: -21px;
      }
      .template-email-wrapper-work {
        .template-tab {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #000;
          border-top:1px solid #000;
          padding:0px 10px;
          margin-top:20px;
          line-height: 30px;
          .name-template {
            font-size: 14px;
            font-family: Roboto;
            font-style: normal;
            color: #382e34;
            font-weight: 600;
          }
          .actions-template {
            display: flex;
            justify-content: flex-end;
            float: right;
            .icon-v19-delete {
              font-size: 16px;
            }
            .icon-v19-delete-active {
              font-size: 16px;
              color: var(--generalIconFontHover);
            }
            .icon-v19-add {
              font-size: 16px;
              color: var(--generalButton);
            }
            .icon-v19-add-active {
              font-size: 16px;
              color: var(--generalIconFontHover);
            }
            .icon-v19-delete-disabled {
              color: #D4D7DA;
              cursor: not-allowed;
            }
          }
        }
        .content-list-tabs {
          .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: #FFFFFF;
            background-color: transparent !important;
          }
          .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--generalIconFontActive) !important;
            border: 1px solid #6e7882;
          }
          .ant-checkbox-wrapper .ant-checkbox-inner {
            border-radius: 0% !important;
          }
          .ant-checkbox-checked .ant-checkbox-inner::after {
            border: 3px solid #fff;
            border-top: 0;
            border-left: 0;
          }
          .template-name {
            margin-left: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            width: 170px;
            line-height: 12px;
            font-size: 13px;
          }
        }
        div.ant-tabs-tab[aria-selected="true"] {
          padding: 0px 0px 10px;
          font-size: 14px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          color: #1890ff;
          //border-bottom: 1px solid #382e34;
          text-align:left;
          margin-bottom: 0px;
          width: 100%;
          &:focus {
            font-weight: 600;
            color: #1890ff;
          }
          &:active {
            font-weight: 600;
            color: #1890ff;
          }
        }
        div.ant-tabs-tab[aria-selected="false"] {
          padding: 0px 0px 10px 0px;
          font-size: 13px;
          font-family: Roboto;
          font-style: normal;
          color: #000000;
          font-weight: 400;
          //border-bottom: 1px solid #382e34;
          cursor: default;
          margin-bottom: 0px;
          text-align:left;
          line-height: 20px;
        }
      }
      .ant-tabs.ant-tabs-left.template-email-wrapper-work.ant-tabs-vertical.ant-tabs-line{
        .ant-tabs-bar.ant-tabs-left-bar{
          .ant-tabs-nav-container {
            border: none;
            border-radius: 0px;
            //background-color: #D4D7DA;
            border: 1px solid #382e34;
            height:37em;
            .ant-tabs-nav-wrap {
              .ant-tabs-ink-bar {
                background-color: transparent;
                pointer-events:none;
              }
            }
          }
        }
        .ant-tabs-content.ant-tabs-content-animated.ant-tabs-left-content {
          border-left:none;
        }
      }
      .template-email-wrapper {
        div.ant-tabs-tab[aria-selected="true"] {
          border-right: 2px solid #00d3d8;
        }
      }
      .email-workflow-template {
        background-color: #FFFFFF;
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;
        .content-loading-get-email-templates {
          position: relative;
          .loading-email-template {
            position: absolute;
            left: 40%;
            top: 200px;
            .ant-spin-dot {
              position: relative;
              display: inline-block;
              font-size: 30px;
              width: 1em;
              height: 1em;
            }
            .ant-spin-dot-item {
              position: absolute;
              display: block;
              width: 12px;
              height: 12px;
            }
            .ant-spin-text {
              font-size: 25px;
            }
          }
        }
        .header-email-template-loading {
          opacity: 0.3;
        }
        .header-email-template {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #382e34;
          //margin-bottom: 30px;
          background-color: #EDA41E;
          height: 50px;
          padding-right: 10px;
          padding-left: 10px;
          .icon-v14-question {
            font-size: 20px;
          }
          .input-template-name {
            width: 60%;
            border: 1px solid #382e34;
            color: #382e34;
            background-color: #EDA41E;
            padding: 0 10px;
            font-size: 16px;
            font-weight: 400;
            height: 35px;
            border-radius: 0px;
            &:hover {
              border: 1px solid #6e7882;
              box-shadow: none;
            }
            &:focus {
              border: 1px solid #6e7882;
              box-shadow: none;
            }
            &:active {
              border: 1px solid #6e7882;
              box-shadow: none;
            }
            &::placeholder{
              color:#D4D7DA;
            }
          }
          .button-save-template:disabled {
            cursor: default;
            background: #D4D7DA40 !important;
            color: #ffffff80;
            &:hover {
              background: #D4D7DA;
              border-color: #D4D7DA;
              color: #FFFFFF;
            }

            &:focus {
              border: 1px solid #00D3D8;
              background: #D4D7DA;
              color: #FFFFFF;
            }
          }
          .button-save-template {
            background-color: #000000;
            border: 1px solid #6e7882;
            border-radius: 20px;
            box-shadow: none;
            color: #FFFFFF;
            cursor: pointer;
            font-family: Roboto;
            font-weight: 400;
            font-size: 16px;
            padding: 3px !important;
            text-transform: none;
            transition: none;
            line-height: 18.75px;
            width: 16%;
            &:hover {
              background-color: #FFC457;
              border: 1px solid #FFC457;
              color: #FFFFFF;
            }
            &:active {
              background-color: #EDA41E;
              border: 1px solid #EDA41E;
              color: #FFFFFF;
            }
          }
          .button-cancel-template {
            background-color: #FFC457;
            border: 1px solid #382e34;
            border-radius: 20px;
            box-shadow: none;
            color: #382e34;
            cursor: pointer;
            font-family: Roboto;
            font-weight: 400;
            font-size: 16px;
            padding: 3px !important;
            text-transform: none;
            transition: none;
            line-height: 18.75px;
            width: 16%;
            &:hover {
              background-color: #FFFFFF;
              border: 1px solid #FFC457;
              color: #FFC457;
            }
            &:active {
              background-color: #FFFFFF;
              border: 1px solid #EDA41E;
              color: #EDA41E;
            }
          }
        }
        .email-template-loading {
          opacity: 0.3;
        }
        .email-template {
          display: flex;
          height: 100%;
          width: 100%;
          flex-direction: column;
          .email-workflow-variables-used {
            .header-variables-used {
              display: flex;
              flex-direction: column;
              width: 100%;
              //border-top: 1px solid #382e34;
              border-left: 1px solid #000000;
              border-right: 1px solid #000000;
              border-top: 1px solid #000000;
              //background-color: #FFC457;
              background-color: #A6ACB230;
              align-items: center;
              align-content: center;
              height: 85px;
              padding-top: 10px;
              padding-right: 5px;
              .content-test-your-email {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                padding-left: 5px;
                padding-bottom: 10px;
                .test-your-email {
                  font-family: Roboto;
                  color: #382e34;
                  font-weight: 400;
                }
              }
              span {
                font-family: Roboto;
                color: #382e34;
                font-weight: 600;
              }
              .email-variables-used {
                display: flex;
                flex-direction: row;
                width: 25%;
                //border-right: 1px solid #382e34;
                height: 40px;
                align-items: center;
                margin-right: 10px;
                margin-left: 10px;
                justify-content: space-between;
                .icon-v14-question {
                  font-size: 18px;
                }
              }
              .email-test-to {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                width: 100%;
                margin-right: 10px;
                padding-left: 10px;
                .test-title {
                   width: 25%;
                }
                .email-test {
                  width: 100%;
                  margin-left: 5px;
                  border-radius: 0px;
                  color: #6e7882;
                   border: 1px solid #382e34;
                  background-color: #FFFFFF;
                  padding: 0 10px;
                  font-size: 16px;
                  font-weight: 400;
                  &:hover {
                    border: 1px solid #EDA41E;
                    box-shadow: none;
                  }
                  &:focus {
                    border: 1px solid #EDA41E;
                    box-shadow: none;
                  }
                  &:active {
                    border: 1px solid #EDA41E;
                    box-shadow: none;
                  }
                }
              }
            }
            .email-variables-used {
              padding: 5px 0px 0px 10px;
              border-left: 1px solid #382e34;
              border-right: 1px solid #382e34;
              background-color: #A6ACB230;
            }
            &__contain {
              display: flex;
              flex-direction: row;
              width: 100%;
              border-right: 1px solid #382e34;
              border-left: 1px solid #382e34;
              border-bottom: 1px solid #382e34;
              background-color: #A6ACB230;
               .variables-contain {
                 width: 25%;
                 //display: flex;
                 //border-right: 1px solid #382e34;
                 padding-right: 10px;
                 .variables-content-email {
                   display: flex;
                   flex-direction: row;
                   align-items: center;
                   font-family: Roboto;
                   color: #382e34;
                   font-weight: 500;
                   line-height: 3em;
                   justify-content: flex-end;
                 }
               }
              .content-email-to {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 10px;
                margin-right: 20px;
                .content-button-update-first {
                  justify-content: space-between !important;
                  .button-reset {
                    background-color: #EDA41E;
                    border: 1px solid #6e7882;
                    border-radius: 20px;
                    box-shadow: none;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 3px !important;
                    text-transform: none;
                    transition: none;
                    line-height: 18.75px;
                    width: 16%;
                    height: 25px;
                    margin-left: 10px;
                    &:hover {
                      background-color: #FFC457;
                      border: 1px solid #FFC457;
                      color: #FFFFFF;
                    }
                    &:active {
                      background-color: #EDA41E;
                      border: 1px solid #EDA41E;
                      color: #FFFFFF;
                    }
                  }
                }
                .content-button-update {
                  justify-content: flex-end !important;
                  .button-reset {
                    background-color: #EDA41E;
                    border: 1px solid #6e7882;
                    border-radius: 20px;
                    box-shadow: none;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 3px !important;
                    text-transform: none;
                    transition: none;
                    line-height: 18.75px;
                    width: 16%;
                    height: 25px;
                    margin-left: 10px;
                    &:hover {
                      background-color: #FFC457;
                      border: 1px solid #FFC457;
                      color: #FFFFFF;
                    }
                    &:active {
                      background-color: #EDA41E;
                      border: 1px solid #EDA41E;
                      color: #FFFFFF;
                    }
                  }
                }
                .content-button {
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  margin-right: 10px;
                  button:disabled {
                    background-color: #CCCCCC;
                    border: 1px solid #CCCCCC;
                    cursor: not-allowed;
                    color: #FFFFFF;
                    &:hover {
                      background-color: #CCCCCC;
                      border: 1px solid #CCCCCC;
                      cursor: not-allowed;
                      color: #FFFFFF;
                    }
                  }
                  .button-send-email {
                    background-color: #EDA41E;
                    border: 1px solid #6e7882;
                    border-radius: 20px;
                    box-shadow: none;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 3px !important;
                    text-transform: none;
                    transition: none;
                    line-height: 18.75px;
                    width: 16%;
                    height: 25px;
                    &:hover {
                      background-color: #FFC457;
                      border: 1px solid #FFC457;
                      color: #FFFFFF;
                    }
                    &:active {
                      background-color: #EDA41E;
                      border: 1px solid #EDA41E;
                      color: #FFFFFF;
                    }
                  }
                }
                .content-input {
                  height: 40px;
                  .email-test {
                    width: 100%;
                    margin-left: 10px;
                    border-radius: 0px;
                    color: #6e7882;
                    border: 1px solid #382e34;
                    background-color: #FFFFFF;
                    padding: 0 10px;
                    font-size: 16px;
                    font-weight: 400;
                    &:hover {
                      border: 1px solid #EDA41E;
                      box-shadow: none;
                    }
                    &:focus {
                      border: 1px solid #EDA41E;
                      box-shadow: none;
                    }
                    &:active {
                      border: 1px solid #EDA41E;
                      box-shadow: none;
                    }
                  }
                }
              }
            }
          }
          .form-content {
            margin-top: 0;
            padding-left:0;
            padding-right: 0;
            .content-email-variables-quill {
              display: flex;
              width: 100%;
              border-left: 1px solid #000;
              border-right: 1px solid #000;
               .react-quill-personalized {
                 display: flex;
                 flex-direction: column;
                 width: 100%;
               }
              .ql-text {
                width: 100px;
                padding: 0px;
              }
              .ql-text::after {
                //font-family: "sofia", cursive;
                content: "max. 100kb";
                width: 100px;
              }
              .quill-write {
                width: 100%;
                height:100%;
              }
              .email-legend-update {

              }
            }
          }
          .email-legend-update {
            padding-left: 10px;
            padding-right: 10px;
            width: 20%;
            justify-content: flex-start;
            display: flex;
            .content-variables-workflow {
               .content-must-variables {
                 margin-bottom: 20px;
                 .content-must {
                   display: flex;
                   align-items: center;
                   .email-variables-used {
                     display: flex;
                     align-items: center;
                     margin-left: 10px;
                     justify-content: space-between;
                     .icon-v14-question {
                       font-size: 18px;
                     }
                   }
                 }
                 .legend-variable {
                   margin-bottom: 5px;
                 }
                  .content-variables {
                    .name-variable {
                      font-size: 14px;
                      font-family: Roboto;
                      color: #382e34;
                      font-weight: 400;
                      padding-bottom: 2px;
                      padding-top: 2px;
                      cursor: default;
                    }
                  }
               }
            }
            .variables-content-email {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .ant-checkbox-wrapper {
              margin-left: 5px;
            }
            .ant-checkbox + span {
              font-size: 14px;
              font-family: Roboto;
              color: #382e34;
              font-weight: 400;
              padding-right:0;
            }
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 0% !important;
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
              border: 1px solid #fff;
              border-top: 0;
              border-left: 0;
              top: 46%;
              left: 19%;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
              border-color: #EDA41E;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
              border-color: #FFFFFF;
              background-color: #FFFFFF;
              color: #FFFFFF;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #EDA41E !important;
              border: 1px solid #6e7882;
            }
          }
          .email-legend {
            display: flex;
            //justify-content: center;
            //align-items: center;
            height:auto;
            //border: 1px solid #cccccc;
            color: #000;
            flex-direction: column;
            border-left:1px solid #000;
            legend {
              font-weight: 600;
              //text-align: center;
            }

            ul {
              margin: 3%;

              li {
                font-size: 1.2em;

                i {
                  padding-right: 10px;
                }
              }
            }
            .icon-info-tooltip {
              margin-left: 5px;
            }
          }

          .form-content {
            .form-group {
              //margin: 1% 0;
              margin-bottom: 0px;
              display: flex;
              background-color: #FFC457;
              display: flex;
              align-items: center;
              padding-right: 10px;
              border-left: 1px solid #382e34;
              border-right: 1px solid #382e34;
              border-bottom: 1px solid #382e34;
              height: 60px;
              label {
                margin-top: 0%;
                font-family: Roboto;
                font-weight: 700;
                font-style:normal;
                color: #000000;
                font-size: 14px;
                margin-bottom: 0px;
              }
              input {
                height: 35px;
                border: 1px solid #000000;
                border-radius: 0;
                color: #000000;
                font-size: 16px;
                font-weight: 400;
                padding: 10px;
              &:focus {
                border:none;
                outline:2px solid #382e34;
              }
              }
            }
            .quill-write {
              //border: 1px solid #382e34;
              .ql-editor {
                span {
                  background-color: #FFFFFF !important;
                  //color: rgba(0, 0, 0, 0.65) !important;
                  color: rgba(0, 0, 0, 0.65);
                }
              }
            }
          }

          .ql-container {
            height: 100%;
            strong {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .email-workflow-settings {
    border: 1px solid #382e34;
    width: 100%;
    display: flex;
    padding: 20px;
    .email-workflow-settings-contain {
      .input-email-company-name {
        margin-top: 10px;
      }
      .input-email-domain {
        //margin-top: 20px;
        .name-input {
          font-size: 12px;
          font-family: Roboto;
          font-weight: 500;
          color: #382e34;
          display: flex;
          margin-bottom: 5px;
        }
        .input-domain {
          border-radius: 0px;
          width: 300px;
          &:focus {
            border: 1px solid #EDA41E;
            box-shadow: none;
          }
          &:hover {
            border: 1px solid #EDA41E;
            box-shadow: none;
          }
        }
      }

      .email-settings-colour {
        margin-top: 20px;
        .name-colours {
          font-size: 12px;
          font-family: Roboto;
          font-weight: 500;
          color: #382e34;
          display: flex;
          margin-bottom: 10px;
        }
        .email-settings-colour-contain {
            .settings-colour-list {
              .settings-colours-contain {
                font-size: 12px;
                font-family: "Roboto";
                font-weight: 400;
                color: #382e34;
                margin-top:10px;
                margin-bottom: 10px;
                .change-colour-contain {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  width: 100%;
                  margin-bottom: 10px;
                  .change-colour-nav-header {
                    width: 100px;
                  }
                  .icon-change-color {
                    position: relative;
                    float: right;
                    width: 20px;
                    height: 20px;
                    margin-top: 0px;
                    cursor: pointer;
                    margin-right: 5px;
                    border: 1px solid #d4d7da;
                    border-radius: 3px;
                    //margin-left: 5px;
                  }
                  .content-change-colour {
                    display: flex;
                    align-items: center;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    padding: 3px;
                    width: 100px;
                    .name-change-colour {
                      font-size: 16px;
                      font-family: "Roboto";
                      font-weight: 400;
                      color: #382e34;
                      //margin-right: 5px;
                    }
                  }

                  .style-upload-home-icon {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    //flex-direction: column-reverse;
                    width:220px;
                    .home-icon-container {
                      width: 100px;
                      height: 50px;
                      border: 1px solid #d9d9d9;
                      border-radius: 4px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-top: 10px;
                      .home-icon {
                        width: 90px;
                        height: 45px;
                      }
                    }
                    .upload-home-icon {
                      margin-top: 10px;
                    }
                    .icon-v22-upload-1 {
                      font-size: 20px;
                      color: #382e34;
                    }
                    .upload-name {
                      color: #382e34;
                      font-weight: 400;
                      border-bottom: none;
                    }
                    .upload-home-icon {
                      .file-upload-home-icon {
                        p {
                          display: none;
                        }
                        .chooseFileButton.button-upload-image {
                          border: 1px solid #382e34;
                          border-radius: 3px;
                          font-family: "Roboto";
                          margin: 0px 0px 0px 0px;
                          padding: 0px 16px;
                          //text-decoration: underline;
                          font-weight: 500;
                          &:hover {
                            background: #FFFFFF;
                            color: #3dd8d8;
                            opacity: 0.5;
                          }
                        }
                      }
                    }
                    .home-icon-size {
                      font-family: "Roboto";
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      color: #d9d9d9;
                    }
                  }

                }
                .home-icon-size {
                  margin-left: 100px;
                  font-size: 12px;
                  font-family: "Roboto";
                  font-weight: 400;
                  color: #382e34;
                }
              }
            }
        }
      }
      .email-setting-button {
        margin-top: 20px;
        .button-save-email-settings {
          background-color: var(--generalButton);
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: var(--generalButtonFont);
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 150px;
          //margin-left: 40px;
          height: 26px;
          &:hover {
            background-color: var(--generalButtonHover);
            border: 1px solid var(--generalButtonHover);
            //color: #FFFFFF;
          }
          &:active {
            background-color: var(--generalButtonActive);
            border: 1px solid var(--generalButtonActive);
            //color: #FFFFFF;
          }
          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
          }
        }
      }
    }
  }
}

.help-message-email-workflow {
  .ant-tooltip-arrow::before {
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
  }
  .ant-tooltip-inner {
    font-family: Roboto;
    color: #382e34;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
    padding: 20px;
  }
  .content-help-message-locations {
    .text-information {
      font-size: 14px;
      font-weight: 400;
      margin-right: 3px;
    }
    .text-information-header {
      font-weight: 700;
      font-size: 14px;
    }
    .more-info-link {
      font-weight: 700;
      font-size: 14px;
      color: #00d4d8;
      cursor: pointer;
      text-decoration: underline;
      width: 65px;
      margin-left: 3px;
      //display: inline-flex;
      //margin-left: 5px;
    }
  }
}

.ql-container.ql-snow {
  border: none!important;
}

.ql-toolbar.ql-snow{
  border:1px solid #ccc!important;
  border-bottom: 1px solid #000!important;
}

@media screen and (min-width: 768px) {
  .email-workflow__contain .template-emails .email-workflow-template .email-template .form-content .form-group label {
    max-width: 127px;
  }
  .email-workflow__contain .template-emails .email-workflow-template .email-template .form-content .form-group input {
    max-width: 100%;
  }
}