.questions-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.questions-backButton {
  color: #3DD8DB;
  display: block;
  font-size: 14px;
  margin-bottom: 40px;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .questions-page {
    padding: 40px 40px 0;
  }
}