.categoryItem__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32%;
  margin-bottom: 20px;
  padding:3px;
}

.categoryItem__header {
  border-bottom: 1px solid #E6E6E6;
  padding: 15px 50px 15px 15px;
  position: relative;
}

.categoryItem__title {
  color: #162737;
  font-size: 18px;
}


.folder_open {
    background-image:url(../../../../assets/folder-open-16.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    width: 16px;
    height:16px;
    float:left;
}

.folder_closed {
    background-image:url(../../../../assets/folder-closed-16.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    width: 16px;
    height:16px;
    float:left;
}
.folder_name {
  margin-left:20px;
}