.backofficeCategory__wrapper {
    margin: 0 auto;
    max-width: 1200px;
    padding: 40px 20px 0;
  }

.backOfficeCategoryEdit__box {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    margin-bottom: 40px;
    position: relative;
  }
  
  .backOfficeCategoryEdit__boxHeader {
    align-items: center;
    border-bottom: 1px solid #E6E6E6;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .backOfficeCategoryEdit__boxTitle {
    color: #162737;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  
  .backOfficeCategoryEdit__boxText {
    color: #737E89;
    font-size: 14px;
    font-weight: 300;
  }
  
  .backOfficeCategoryEdit__categoryContainer{
    width:100%;
  }

  .backOfficeCategoryEdit__documentsLayout{
    width:100%;
    display: inline-block;
    max-width: 100%;
  }

  .backOfficeCategoryEdit__documentViewer{
    width:100%;
  }

  .backOfficeCategoryEdit__categoryLayout{
    width:50%;
    display: inline-block;
    vertical-align: top;
  }

  .backOfficeCategoryEdit_query{
    padding:10px;
  }

  .title-edit-query {
    padding-bottom: 5px;
  }

  .backOfficeCategoryEdit_filterQueries{
    padding:10px;
    font-size: 16px;
  }
  
  .backOfficeCategoryEdit_training{
    padding:10px;
  }
  .category-trained {
    padding-top: 5px;
  }

  .backOfficeCategoryEdit_addFilterQuery{
    cursor: pointer;
  }

  .backOfficeCategoryEdit_removeFilterQuery{
    cursor: pointer;
  }

  .backOfficeCategoryEdit_buttons {
    padding:10px;

  }
.button-red  {
  width: auto;
  height: auto;
  margin-right: 20px;
}

.content-button-topics {
  padding-bottom: 15px;
  padding-left: 5px;
}
.content-input-search {
  padding-left: 5px;
}

  .backOfficeCategoryEdit__trainingStats{
    padding-top:10px;
  }

  .backOfficeCategoryEdit__trainingTermsTitle{
    font-weight: bold;
    padding:5px;
  }

  .backOfficeCategoryEdit__trainingTerm{
    padding-left:5px;
  }

  .backOfficeCategoryEdit__trainingStatsTitle{
    font-weight: bold;
    padding:5px;
  }

  .backOfficeCategoryEdit__trainingStat{
    padding-left:5px;
  }

  .backOfficeCategoryEdit__tabsContainer {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 24px;
    position: relative;
  }
  .backOfficeCategoryEdit__tabsContainer li {
    margin: 0 10px;
    padding: 0 10px;
    border: 1px solid #AAA;
    background: #ECECEC;
    display: inline-block;
    position: relative;
    z-index: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    cursor: pointer;
  }

  .backOfficeCategoryEdit__tabsContainer{
    padding-top:5px;
  }

  .backOfficeCategoryEdit__tabsContainer li.active {
    background: #FFF;
    color: #000;
    z-index: 2;
    border-bottom-color: #FFF;
  }

  .backOfficeCategoryEdit__tabsContainer:after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #AAA;
    z-index: 1;
  }

  .backOfficeCategoryEdit__tabsContainer:before {
    z-index: 1;
  }

  .backOfficeCategoryEdit__tabsContainer li:before,
  .backOfficeCategoryEdit__tabsContainer li:after {
    border: 1px solid #AAA;
    position: absolute;
    bottom: -1px;
    width: 6px;
    height: 6px;
    content: " ";
  }
  .backOfficeCategoryEdit__tabsContainer li:before {
    left: -7px;
    border-bottom-right-radius: 6px;
    border-width: 0 1px 1px 0;
    box-shadow: 2px 2px 0 #ECECEC;
  }
  .backOfficeCategoryEdit__tabsContainer li:after {
    right: -7px;
    border-bottom-left-radius: 6px;
    border-width: 0 0 1px 1px;
    box-shadow: -2px 2px 0 #ECECEC;
  }

  .backOfficeCategoryEdit__tabsContainer li.active:before {
    box-shadow: 2px 2px 0 #FFF;
  }
  .backOfficeCategoryEdit__tabsContainer li.active:after {
    box-shadow: -2px 2px 0 #FFF;
  }

  .backOfficeCategoryEdit__tabsContainer li {
    background: linear-gradient(to bottom, #ECECEC 50%, #D1D1D1 100%);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4), inset 0 1px 0 #FFF;
    text-shadow: 0 1px #FFF;
    margin: 0 -5px;
    padding: 0 20px;
  }

  .backOfficeCategoryEdit__topicWrapper{
    display: inline-block;
    padding:15px 5px 15px 5px;
    vertical-align: top;
    border:1px solid rgb(175, 175, 175);
    margin: 5px;
    border-radius: 15px;
  }

  .backOfficeCategoryEdit__topicTitle{
    font-weight: bold;
  }


  .backOfficeCategoryEdit__topicTerms {
    margin-bottom: 20px;
    overflow-y: scroll;
  }
  
  .backOfficeCategoryEdit__topicTerms th {
    background-color: #FFFFFF;
    border-bottom: 3px solid #E6E8EA;
    color: #6E7882;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    text-transform: uppercase;
  }
  
  .backOfficeCategoryEdit__topicTerms tr {
    cursor: pointer;
  }
  
  .backOfficeCategoryEdit__topicTerms td {
    border-bottom: 1px solid #E6E8EA;
    color: #111C26;
    font-size: 14px;
    padding: 8px;
    cursor: pointer;
  }
  
  .backOfficeCategoryEdit__topicTerms tr:last-of-type td {
    border-bottom: none;
    color: #111C26;
    font-size: 14px;
    padding: 8px;
    cursor: pointer;
  }
  
  .backOfficeCategoryEdit__topicTerms tr:nth-child(even) td {
    background-color: #FFFFFF;
    cursor: pointer;
  }
  
  .backOfficeCategoryEdit__topicTerms tr:nth-child(odd) td {
    background-color: #FBFBFB;
    cursor: pointer;
  }
  
  .backOfficeCategoryEdit__topicViewContentButton{
    cursor: pointer;
    display:inline-block;
    padding-left:10px;
  }

  .backOfficeCategoryEdit_filter{
    cursor: pointer;
    display: inline-block;
    padding: 4px 15px 5px 12px;
    border-radius: 15px;
    background-color: #DCDCDC;
    border: 1px solid #737E89;
  }

  .backOfficeCategoryEdit_filtersTitle{
    display: inline-block;
    padding: 5px;
  }
  .backOfficeCategoryEdit_filterRemove{
    display: inline-block;
    padding-left:15px;
    font-weight:bold;
  }

  .backOfficeCategoryEdit__topic_showMore{
    cursor: pointer;
    display:inline-block;
  }

  .backOfficeCategoryEdit__topic_showLess{
    cursor: pointer;
    display:inline-block;
  }
  
  .backOfficeCategoryEdit__general{
    padding: 5px;
  }

.backOfficeCategoryEdit__trainingTerm{
  cursor: pointer;
  display: inline-block;
  padding: 4px 15px 5px 12px;
  border-radius: 15px;
  background-color: #DCDCDC;
  border: 1px solid #737E89;
  margin:5px;
}

.backOfficeCategoryEdit__annotatorsWrapper{
  padding: 10px;
}
.backOfficeCategoryEdit__annotatorWrapper{
  padding:2px;
}
.backOfficeCategoryEdit__annotatorsTitle{
  font-weight: bold;
  padding-left: 2px;
  padding-bottom:10px;
}
.backOfficeCategoryEdit__annotatorName{
  display: inline-block;
  width: 200px;
  padding-left: 5px;
}
.backOfficeCategoryEdit__annotatorDescription{
  display: inline-block;
  width:700px;
}
.backOfficeCategoryEdit__annotatorCheckbox{
  display: inline-block;
  padding-left: 5px;
}
.backOfficeCategoryEdit__annotatorCheckbox input{
  visibility: visible !important;
}
.backOfficeCategoryEdit__annotatorRun{
  display: inline-block;
  padding-left: 15px;
  color: #1DCBCF;
  cursor: pointer;
}