.multiselect {
  cursor: pointer;
  display: inline;
dt {
  background: url(../../assets/down-caret.png) no-repeat 97% 50%, linear-gradient(0deg, rgba(215, 215, 215, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%);
  color: #555555;
  display: block;
  padding: 10px 25px 10px 10px;
  font-size: 16px;
  line-height: normal;
  overflow: hidden;
  border: 1px solid #CCCCCC;
  width: 272px;
  border-radius: 5px;
span {
  cursor: pointer;
  display: inline-block;
  padding: 0 3px 2px 0;
  font-weight: normal;
}
}
dd .multiSelect__list {
  background-color: #4F6877;
  border: 1px solid #CCCCCC;
  color: #fff;
  display: none;
  left: 0;
  padding: 2px 15px 2px 5px;
  width: 280px;
  list-style: none;
  height: 100px;
  overflow: auto;
}
}

.multiSelectOpened {
  cursor: pointer;
  display: inline;
dd {
  position: relative;
  z-index: 1;
}
dt {
  background: url(../../assets/down-caret.png) no-repeat 97% 50%, linear-gradient(0deg, rgba(215, 215, 215, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%);
  display: block;
  padding: 10px 25px 10px 10px;
  line-height: normal;
  overflow: hidden;
  border: 1px solid #CCCCCC;
  width: 272px;
  height: 43px;
  font-size: 16px;
  border-radius: 5px;
  color: #555555;
span {
  cursor: pointer;
  display: inline-block;
  padding: 0 3px 2px 0;
  font-weight: normal;
}
}
dd .multiSelect__list {
  background-color: #f6f6f6;
  border: 1px solid #CCCCCC;
  color: #555555;
  display: block;
  left: 0;
  padding: 2px 15px 2px 5px;
  position: absolute;
  width: 280px;
  list-style: none;
  height: 100px;
  overflow: auto;
  border-radius: 5px;
}
}

.multiSelector__labelSelected {
  background-color: #3DD8DB;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 14px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 14px;
  z-index: 1;
}

.multiSelector__label {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 18px;
  position: absolute;
  width: 18px;
  z-index: 1;
}

.multiSelect__checkboxGroup {
  padding: 5px 0 5px 0;
}

.multiSelect__checkboxWrapper {
  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  height: 20px;
  display: inline-block;
  position: relative;
  width: 20px;
}

.multiSelect__checkboxGroup span {
  margin-left: 5px;
}