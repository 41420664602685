.reportSummary-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.reportSummary-link {
  color: #3DD8DB;
  display: block;
  font-size: 14px;
  margin-bottom: 40px;
  text-decoration: underline;
&:hover {
   font-style: italic;
 }
}

.reportSummary-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
}

.reportSummary-header {
  align-items: center;
  display: flex;
  height: 74px;
  justify-content: center;
  padding: 20px;
}

.reportSummary-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.reportSummary-section {
  border-top: 1px solid #E6E6E6;
  padding: 20px;
  position: relative;
}

.reportSummary-sectionHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.reportSummary-sectionTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.reportSummary-sectionSubtitle {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.reportSummary-content {
  margin-bottom: 20px;
}

.reportSummary-info {
  display: flex;
}

.reportSummary-low {
  background-color: #F44336;
  color: #FFFFFF;
  font-size: 14px;
  margin-left: 10px;
  padding: 5px 20px;
  text-transform: uppercase;
}

.reportSummary-medium {
  background-color: #D5AF00;
  color: #FFFFFF;
  font-size: 14px;
  margin-left: 10px;
  padding: 5px 20px;
  text-transform: uppercase;
}

.reportSummary-high {
  background-color: #1DCBCF;
  color: #FFFFFF;
  font-size: 14px;
  margin-left: 10px;
  padding: 5px 20px;
  text-transform: uppercase;
}

.reportSummary-item {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
&:last-of-type {
   border-bottom: none;
 }
}

.reportSummary-itemContent {
  align-items: center;
  display: flex;
  margin-top: 20px;
.area-wrapper {
  align-items: center;
}
span {
  margin-left: 10px;
}
.area-info {
  margin-left: 15px;
  flex: 1;
}
}

@media screen and (min-width: 768px) {
  .reportSummary-page {
    padding: 40px 40px 0;
  }
  .reportSummary-header {
    flex-direction: row;
    justify-content: space-between;
    height: 74px;
  }
  .reportSummary-title {
    margin-bottom: 0;
  }
  .reportSummary-sectionHeader {
    flex-direction: row;
  }
  .reportSummary-itemContent {
    margin-top: 0;
  }
  .reportSummary-content {
    margin-bottom: 0;
  }
  .reportSummary-item {
    flex-direction: row;
&:last-of-type {
   padding-bottom: 0;
 }
}
}