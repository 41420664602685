$gray: #888;

:export {
  primary: $gray;
  secondary: #909;
}
  .sarDashboard-page {
  //padding: 120px 170px 0;
  //position: relative;
  //background-color: #fafafa;
  .dashboard-workflow-title-page {
    .dashboard-workflow-headline {
      .dashboard-workflow-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #382e34;
      }
    }
  }
  .dashboard-workflow-header {
    .new-ticket-button {
      background-color: var(--generalButton);
      border: 1px solid #6e7882;
      border-radius: 20px;
      box-shadow: none;
      color: var(--generalButtonFont);
      cursor: pointer;
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      padding: 3px !important;
      text-transform: none;
      transition: none;
      line-height: 18.75px;
      width: 15%;
      margin-left: 40px;
      height: 26px;

      &:hover {
        background-color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
        //color: #FFFFFF;
      }

      &:active {
        background-color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
        //color: #FFFFFF;
      }

      &:disabled {
        cursor: default;
        background: #D4D7DA !important;
      }
    }
    .new-ticket-button.btn-loading {
      border: 1px solid #eda41e;
      background-color: #ffc457;
    }
  }
  .card-line-update {
    flex-direction: column;
    margin-top: 40px;
    width: 20%;
    margin-right: 10px;
    margin-bottom: 0px;
    .card {
      margin-bottom: 10px;
      width: 100%;
      height: 60px;
    }
  }
  .dashboard-workflow-header {
    display: flex;
    justify-content:space-between;
    width: 100%;
    //margin-top: 20px;

  }
  .sar_statistic_card {
    .card {
      cursor: default;
      border: 1px solid #222B38;
      border-radius: 0;
      background-color: #fafafa;
      &__content {
        margin-top: 20px;
        &__icon {
          font-size: 50px;
          font-weight: 600;
          color: #222B38;
        }
        .value {
          text-align: right;
          &__details {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .sar_statistic_card-update {
    display: flex;
    .sar_statistic_chart {
      margin-top: 40px;
      width: 80%;
      height: 130px;
      .title-statistic-chart-update {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
      .w-100 {
        height: 90px !important;
      }
    }

    .card-update {
      .card__title__update {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
      .card__content__update {
        margin-top:0px;
        display: flex;
        justify-content: flex-end;
        padding: 0px;
      }
      .value-update {
        .value__number__update {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #382e34;
        }
      }

    }
  }
  .sar_statistic_chart {
    margin-top: 20px;
    background-color: #fafafa;
    padding: 5px;
    border: 1px solid #222B38;
    position: relative;
    .title-statistic-chart {
      width: 353px;
      height: 21px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding-left: 10px;
    }
    .arrow {
      position: absolute;
      cursor: pointer;
      top: 40%;
      font-size: 20px;
    }
    .arrow-left {
      left: 10px;
    }
    .arrow-right {
      right: 10px;
    }
  }
  .content-button-subject {
    padding-top: 30px;
    .button-newBusiness {
      float: right;
      font-size: 20px;
      font-weight: 900;
      text-transform: capitalize;
      .anticon {
        margin-right: 8px;
      }
    }
  }
  .filter-sar-header {
  flex-direction: column-reverse;
  display: flex;
  width: 100%;
  padding-top: 30px;
  .content-filter {
    display: flex;
    width: 100%;
    .content-input-search {
      margin: 0;
      max-width: 380px;
      width: 300px;
      display: flex;
      flex: 1 1;
      display: flex;
      margin-right: 12px;
      .anticon {
        font-size: 20px;
        opacity: 0.4;
      }
        .ant-input-affix-wrapper .ant-input {
          height: 50px;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
          display: flex;
        }
    }

    .content-icon-filter {
      padding-right: 20px;
      display: flex;
      align-items: center;

      span {
        font-size: 28px;
      }
    }

    .filter-select-sar {
      display: flex;
      max-width: 380px;
      width: 300px;
      flex: 1 1;
      margin: 0px 0px 0px 19px;
      .ant-select-selection--single {
        height: 50px;
      }
      .ant-select-selection__placeholder {
        top: 80%;
        left: 10px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6e7882;
        opacity: 0.4;
      }
      .ant-select-selection-selected-value {
        padding-top: 9px;
        padding-left: 10px;
      }
    }
  }
}
  .sar-table-content {
    margin-bottom: 10px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      background-color: #fafafa;
      //box-shadow: 0px 5px 5px #ccc;
      //box-shadow: 0 0, 0 5px red initial, 0 0, 0 0;
      padding: 0px 0px;
      border-bottom: 1px solid #382e34;
      .per-page-wrapper {
        .ant-select-selection:hover {
          border-color: #00d4d8;
          border-right-width: 1px !important;
        }
      }
      .select-all-wrapper-sar {
        display: flex;
        align-items: center;
        .icon-v12-delete:before {
          //margin-bottom: 3px;
        }
        .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
          border-color: #00d4d8;
        }
        .ant-checkbox-wrapper {
          font-size: 14px;
          font-weight: 300;
          //padding-right: 10px;
          font-family: Roboto;
          width: 90px;
          color: #6e7782;
          border: 1px solid transparent;
          margin-right: 10px;
          padding-top: 2px;
          //margin-bottom: 3px;
          &:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
            //padding-top: 1px;
          }
          .ant-checkbox + span {
            padding-right: 0px;
            padding-left: 8px;
            padding-top: 3px;
          }
          .ant-checkbox-inner {
            margin-left: 5px;
          }
        }
        .select-all-sar {
          .ant-checkbox {
            font-family: Roboto;
            color: #6e7882;
          }
        }
        .icon-v12-delete-active {
          font-size: 20px;
          color: #00D3D8;
          cursor: pointer;
        }
        .icon-v12-delete {
          font-size: 20px;
          color: #6e7882;
          cursor: pointer;
        }
        .disabled-delete {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
      .button-wrapper {
        position: relative;
        .button-newBusiness {
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          text-transform: none;
          color: #00D3D8;
          background-color: #ffffff;
          border: 1px solid #00D3D8;
          padding: 10px 50px;
          &:hover {
            color: #ffffff;
            background-color: #00D3D8;
          }
          &__icon {
            position: absolute;
            left: 17px;
            top: 25px;
            font-size: 20px;
          }
        }
      }
      .content-search-dashboard-workflow {
        display: flex;
        align-items: center;
        .icon-v21-download-1 {
          font-size: 20px;
          color: #382e34;
          margin-left: 10px;
          margin-right: 10px;
        }
        .icon-v21-download-active-1 {
          font-size: 20px;
          color: var(--generalIconFontHover);
          margin-left: 10px;
          margin-right: 10px;
        }
        .disabled-download {
          color: #D4D7DA;
          cursor: default;
        }
      }
      .search-content {
        .ant-input {
          width: 200px;
          height: 30px;
          border-radius: 0;
          padding: 5px 10px;
          font-weight: 400;
          font-size: 14px;
          color: #382e34;
          letter-spacing: 0.2px;
          border: 1px solid #382e34 !important;

          &:focus {
            border-color: var(--generalButton) !important;
            box-shadow: none;
          }

          &:hover {
            border-color: var(--generalButton) !important;
            box-shadow: none;
          }

          &:active {
            border-color: #eea518 !important;
            box-shadow: none;
          }
        }
        .ant-input-search-icon {
          color:#150010;
        }
        .ant-input-clear-icon {
          &:hover {
            color: #150010;
          }
          &:active {
            color: #150010;
          }
        }
      }
    }
  }
  .sar-table-new-content {
    margin-bottom: 20px;
    .table-content-header-update {
      padding-bottom: 5px;
      height: 40px;
    }
  }
}
  .header-ticket-page-visible {
     top: 60px !important;
  }
.header-ticket-page {
  padding: 0px 20px 0px 20px;
  background-color: var(--subNavigationBackground);
  position: fixed;
  top: 100px;
  z-index: 3;
  width: 100%;
  .content-header-ticket-page {
    display: flex;
    align-content: center;
    height: 40px;
    align-items: center;
    width: 100%;
    .content-header-ticket-id {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-right: 1px solid #FFFFFF;
      .ticket-id {
        color: #FFFFFF;
        //color: var(--subNavigationBodyFont);
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.2px;
        cursor: default;
      }
      .icon-v22-request-detail {
        color: #FFFFFF;
        font-size: 20px;
      }
      .icon-v22-request-detail-active {
        color: var(--subNavigationBodyFontHover);
        font-size: 20px;
      }
    }

    .header-navigation-content {
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      .ticket-box-header {
        display: flex;
        align-items: center;
        .ticket-id {
          color: #FFFFFF;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.2px;
          font-style: normal;
          margin-left: 10px;
        }
      }
      .ticket-owner {
        display: flex;
        align-items: center;
        font-family: Roboto;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        font-style: normal;
        margin-left: 10px;
        cursor: default;
        .ant-select {
          color: #FFFFFF;
          margin-left: 10px;
        }
        .ant-select-selection {
          background-color: var(--subNavigationBackground);
          color: #FFFFFF;
        }
        .ant-select-selection__rendered {
          //color: #FFFFFF !important;
          &:hover {
            color: var(--subNavigationBackgroundHover) !important;
          }
        }
        .ant-select-arrow {
          color: #FFFFFF !important;
          &:hover {
            color: var(--subNavigationBackgroundHover) !important;
          }
        }
        .ant-select-selection--single {
          width: 105px;
          height:20px;
          border: 1px solid transparent;
          border-radius: 0;
          //margin-bottom: 3px;
          &:hover{
            border: 1px solid var(--subNavigationBackgroundHover);
            .ant-select-selection__rendered {
              &:hover {
                color: var(--subNavigationBackgroundHover) !important;
              }
            }
            .ant-select-arrow {
              &:hover {
                color: var(--subNavigationBackgroundHover) !important;
              }
            }
          }
          .ant-select-selection__rendered {
            position: relative !important;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            //color: #382e34;
          }
        }
      }
      .date-request {
        display: flex;
        align-items: center;
        cursor: default;
        .icon-v211-date {
          font-family: Roboto;
          color: #FFFFFF;
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.2px;
          font-style: normal;
          margin-left: 10px;
        }
        .icon-v211-date-active {
          font-size: 20px;
          margin-left: 10px;
          color: var(--subNavigationBodyFontHover);
        }
        .content-body-data {
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #FFFFFF;
        }
      }
      .icon-v22-close-black {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        cursor: pointer;
        &:hover {
          color: var(--subNavigationBodyFontHover);
        }
      }
    }
  }
}
.header-workflow-step-page-visible {
  top: 100px !important;
}
.header-workflow-step-page {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 3px 70px 3px 20px;
  height: 30px;
  position: fixed;
  top: 140px;
  z-index: 2;
  .header-workflow-step-components {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-v22-completed-active-completed {
      color: #eda41e;
      font-size: 20px;
    }
    .title-workflow-step {
      font-family: Roboto;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.2px;
      font-style: normal;
    }
    .icon-v22-form {
      color: #FFFFFF;
      font-size: 20px;
    }
    .icon-v22-form-active {
      color: #eda41e;
      font-size: 20px;
    }
    .icon-v22-radio-button-off {
      color: #FFFFFF;
      font-size: 10px;
    }
    .icon-v22-email-1 {
      color: #FFFFFF;
      font-size: 20px;
    }
    .active-icon {
      color: #ffffff;
      font-size: 20px;
    }
    .active-icon-hover {
      color: var(--generalButton);
      font-size: 20px;
    }
    .icon-internal-email-complete {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: flex;
      color: #eda41e;
      background-color: #FFFFFF;
    }
    .icon-internal-email-complete-active {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: flex;
      color: #eda41e;
      background-color: #eda41e;
    }
    .active-icon-hover-notification {
      color: #eda41e;
      font-size: 10px;
    }
    .active-icon-notification {
      color: #ffffff;
      font-size: 10px;
    }
    .disabled-icon {
      color: #ffffff;
      font-size: 20px;
      opacity: 0.3;
    }
    .disabled-icon-notification {
      color: #ffffff;
      font-size: 10px;
      opacity: 0.3;
    }
    .icon-notification-inactive {
      color: #FFFFFF;
      font-size: 20px;
    }
    .inactive-icon {
      color: #FFFFFF;
      font-size: 20px;
    }
  }

  .header-pagination-components {
    width: 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-back {
      .moving-icon {
        color: #FFFFFF;
        font-size: 20px;
        &:hover {
          cursor: pointer;
          color: #4096ff;
        }
      }
      .moving {
        font-family: Roboto;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        font-style: normal;
        &:hover {
          cursor: pointer;
          color: #4096ff;
        }
      }
    }
    .content-back-disabled {
      opacity: 0.6;
      cursor: not-allowed;
      .moving-icon {
        color: #FFFFFF;
        font-size: 20px;
        //cursor: default;
        &:hover {
          color: #FFFFFF !important;
          font-size: 20px !important;
          cursor: not-allowed !important;
        }
      }
      .moving {
        &:hover {
          color: #FFFFFF !important;
          cursor: not-allowed !important;
        }
      }
    }
    .content-forth-disabled {
      opacity: 0.6;
      cursor: not-allowed;
      .moving-icon {
        color: #FFFFFF;
        font-size: 20px;
        //cursor: default;
        &:hover {
          color: #FFFFFF !important;
          font-size: 20px !important;
          cursor: not-allowed !important;
        }
      }
      .moving {
        &:hover {
          color: #FFFFFF !important;
          cursor: not-allowed !important;
        }
      }

    }
    .content-forth {
      .moving-icon {
        color: #FFFFFF;
        font-size: 20px;
        //cursor: default;
        &:hover {
          cursor: pointer;
          color: #4096ff;
        }
      }
      .moving {
        font-family: Roboto;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        font-style: normal;
        &:hover {
          cursor: pointer;
          color: #4096ff;
        }
      }
    }
  }
}

.sarDashboard-page-new {
  padding:150px 100px 0px 100px;
}
.sarDashboard-disclosure-workflow {
  padding: 198px 100px 30px 100px;
}
.sarDashboard-page-edit-ticket {
  padding:198px 100px 0px 300px;
}
.sarDashboard-page-new-ticket {
  //padding:150px 100px 0px 300px;
}
.sarDashboard-page-archive {
  padding:130px 100px 0px 100px;
}
#sarFilter__display {
  margin: 0 20px 0 0;
  max-width: 300px;
  width: 234px;
}

.ospDashboard__title {
  display: block;
  color: #737D87;
  font-weight: 300;
  margin: 30px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  &:last-of-type {
    margin: 10px 0 30px;
  }
  &::before {
    background-color: #C5C9CD;
    height: 1px;
    content: "";
    left: 0;
    position: absolute;
    top: 8px;
    width: calc(50% - 140px);
  }
  &::after {
    background-color: #C5C9CD;
    height: 1px;
    content: "";
    right: 0;
    position: absolute;
    top: 8px;
    width: calc(50% - 140px);
  }
}

.ospDashboard-boxWelcome {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
}

.ospDashboard-boxHeader {
  border-bottom: 1px solid #F2F2F2;
  padding: 20px;
}

.ospDashboard-boxTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.ospDashboard-boxText {
  @extend .ospDashboard-boxTitle;
  line-height: 1.5;
  padding: 20px;
}

.ospDashboard-boxLogo {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;
  height: 200px;
  padding: 130px 0 0 10px;
  position: relative;
  width: 120px;
  &::after {
    bottom: 60px;
    content: ™;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 300;
    left: 90px;
    position: absolute;
  }
}

.ospDashboard-boxInfo {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  flex: 1;
  height: 200px;
  position: relative;
}

.ospDashboard-boxInfoText {
  @extend .ospDashboard-boxTitle;
  line-height: 1.5;
  padding: 10px;
}

.ospDashboard__resources {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  padding: 20px 20px 0;
  position: relative;
}

.ospDashboard__resources--header {
  display: flex;
  flex-direction: column-reverse;
  a {
    color: #1DCBCF;
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.ospDashboard__resources--title {
  color: #000000;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 10px;
}

.ospDashboard-whitepaper {
  width: 100%;
  &:last-of-type {
    border-right: none;
  }
}

.ospDashboard-content {
  border-top: 1px solid #F2F2F2;
  display: flex;
  padding: 20px 0;
}

.ospDashboard-wrapper {
  display: flex;
}

.ospDashboard-iconPdf {
  height: 39px;
  width: 33px;
}

.ospDashboard-whitepaperInfo {
  flex: 1;
  padding: 0 10px 0 20px;
}

.ospDashboard-whitepaperTitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin-top: -7px;
}

.ospDashboard-whitepaperDescription {
  margin: 10px 0 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
}

.ospDashboard-whitepaperLink {
  align-self: center;
}

.ospDashboard-whitepaperLinkOnMobile {
  //background: url(../../../assets/icon-arrow.png) no-repeat center center #FFFFFF;
  display: block;
  height: 19px;
  width: 13px;
}

.ospDashboard-whitepaperButton {
  display: none;
}

@media screen and (min-width: 768px) {
  .ospDashboard-page {
    padding: 40px 40px 0;
  }
  .ospDashboard__box--split {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ospDashboard-boxLogo {
    padding: 130px 0 0 20px;
    width: 268px;
  }
  .ospDashboard-boxInfo::before {
    content: "";
    border-style: solid;
    border-width: 0 0 202px 90px;
    border-color: transparent transparent #FFFFFF transparent;
    height: 0;
    left: -90px;
    position: absolute;
    top: -2px;
    width: 0;
  }
  .ospDashboard-boxInfoText {
    font-size: 15px;
    padding: 20px;
  }
  .ospDashboard__resources--header {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .ospDashboard__resources--title, .ospDashboard__resources--header a {
    margin-bottom: 0;
  }
  .ospDashboard-whitepaperLinkOnMobile {
    display: none;
  }
  .ospDashboard-whitepaperButton {
    display: block;
    button {
      width: 150px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ospDashboard-container {
    flex-direction: row;
  }
  .ospDashboard-whitepaper {
    border-right: 1px solid #F2F2F2;
    padding: 0 30px;
    width: 33.33%;
  }
  .ospDashboard-content {
    border-top: none;
    flex-direction: row-reverse;
  }
  .ospDashboard-wrapper {
    flex-direction: column;
  }
  .ospDashboard__resources {
    padding: 20px 0 0;
  }
  .ospDashboard__resources--header {
    border-bottom: 1px solid #EDEDED;
    padding: 0 20px 20px;
  }
  .ospDashboard__resources--body {
    display: flex;
    padding: 20px;
  }
  .ospDashboard-whitepaperInfo {
    padding: 0 20px 0 0;
  }
  .ospDashboard-whitepaperLink {
    align-self: flex-start;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .ospDashboard-boxResourcesTitle {
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .questionnaireFilter__select {
    margin: 0 20px 0 0;
    width: 100%;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50% #FFFFFF;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      font-size: 16px;
      height: 42px;
      padding: 10px 0 10px 8px;
      outline: none;
      width: 100%;
    }
  }
  .button-newBusiness {
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    border-radius: 30px;
    -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 15px 4px;
    text-transform: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
}

@media screen and (max-width: 768px) and (min-width: 600px) {
  .unlockedDashboard-infoContainer {
    width: 86%;
  }
}

.styleAccordion {
  .ant-table-thead {
    background-color: #fafafa;
  }
  .ant-table-tbody {
    background-color: #fafafa;
    .ant-table-row {
      cursor: pointer;
      height: 30px;
    }
  }
}
.per-page-wrapper-update {
  .per-page-update {
    font-size: 20px;
    .ant-select {
      height: 25px;
    }
    .ant-select-selection--single {
      border: none;
      background-color: transparent;
      width: 50px;
      height: 1px !important;
      .ant-select-selection-selected-value {
        display:none !important;
      }
    }
    .ant-select-selection__rendered{
      width: 80px;
      margin-left: 0px;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .icon-v14-show-20-active {
    color:var(--generalButton);
    font-size: 20px;
  }
  .icon-v14-show-50-active {
    color:var(--generalButton);
    font-size: 20px;
  }
  .icon-v14-show-100-active {
    color:var(--generalButton);
    font-size: 20px;
  }
}
.ant-select-dropdown.select-per-page-tickets {
  border:1px solid var(--subNavigationBackgroundHover);
  z-index: 1;
}
.ant-select-dropdown.select-per-page-tickets.select-status.header-select-status {
  z-index: 3;
  position: fixed;
}
.per-tickets {
  width: 75px !important;
  ul {
    padding: 0;

    li.ant-select-dropdown-menu-item {
      padding: 0px 10px 0px 10px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px !important;
      letter-spacing: 0.2px;
      color: #382e34 !important;
      height: 30px;

      &.ant-select-dropdown-menu-item-selected {
        background-color: #fff;
        font-weight: 400;
      }
    }
  }
}
.button-control-dashboard-new {
  display: flex;
  align-items: center;
  .icon-v21-forth-1.disabled {
    color: #D4D7DA !important;
  }
  .insert-page-new {
    text-align: center;
    width: 50px;
    padding: 1px 5px !important;
    border-color: #382e34;
    margin-right: 5px;
    font-weight: 400;
    background-color: #fafafa;
    border-radius: 3px;
    font-size: 14px;
    &:hover {
      border-color: #eda41e;
    }
    &:focus {
      outline:none;
    }
  }
  .icon-v14-start-1, .icon-v14-end-1 {
    font-size: 20px;
    //cursor: pointer;
    color:#382e34;
    border: 1pt solid transparent;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
  .icon-v14-start-active, .icon-v14-end-active {
    font-size: 20px;
    color:#eda41e;
    cursor: pointer;
    border: 1pt solid transparent;
    margin-left: 0.2em;
    margin-right:0.2em;
  }
  .icon-v14-start-1.disabled, .icon-v14-end-1.disabled {
    color: #D4D7DA !important;
  }
  .icon-v21-back-1, .icon-v21-forth-1 {
    color: #382e34;
    font-size: 20px;
    //cursor:pointer;
    margin-left: 0.2em;
    margin-right:0.2em;
  }
  .icon-v21-back-active, .icon-v21-forth-active {
    font-size: 20px;
    cursor:pointer;
    border:1px transparent;
    color:#eda41e;
    margin-left: 0.2em;
    margin-right:0.2em;
    &:before {
      font-size: 20px;
      color:#eda41e;
    }
  }
  .icon-v21-back-1.disabled, .icon-v21-fort-1.disabled {
    color: #D4D7DA !important;
  }
  .icon-v21-fort-1.disabled {
    color: #D4D7DA !important;
  }
  .button-control__text-new {
    .insert-page-new {
      text-align: center;
      width: 50px;
      padding: 1px 5px !important;
      border-color: #382e34;
      margin-right: 5px;
      font-weight: 400;
      background-color: #fafafa;
      border-radius: 3px;
      font-size: 14px;
      &:hover {
        border-color: #eda41e;
      }
      &:focus {
        outline:none;
      }
    }
    span {
      color:#382e34;
    }
  }
}

.modal-send-email-step-workflow {
  .ant-modal-content {
    .ant-modal-body {
      padding: 30px !important;
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          .ant-modal-confirm-title {
            font-size: 32px;
            color: #382e34;
            .icon-v22-nav-notifications {
              font-size: 40px;
              color: #382e34;
            }
          }
          .ant-modal-confirm-content {
            color: #382e34;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            font-family: Roboto;
            line-height: 20px;
            width: 300px;
          }
        }
        .ant-modal-confirm-btns {
          margin-top: 40px;
          button span {
            padding: 0;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 400;
          }
          .confirm-button {
            height: 25px;
            width: 100px;
            text-transform: capitalize;
            background-color: var(--generalButton);
            border: 1px solid #6e7882;
            color: var(--generalButtonFont);
            font-size: 14px;
            font-family: Roboto;
            &:hover {
              color: var(--generalButtonFont);
              border: 1px solid var(--generalButtonHover);
              background-color: var(--generalButtonHover);
            }
            &:active {
              //color: #FFFFFF;
              border: 1px solid var(--generalButtonActive);
              background-color: var(--generalButtonActive);
            }
          }
          .cancel-button {
            height: 25px;
            width: 100px;
            text-transform: capitalize;
            background-color: #FFFFFF;
            color: #6e7882;
            font-size: 14px;
            border: 1px solid #6e7882;
            font-family: Roboto;
            &:hover {
              color: var(--generalButtonHover);
              border: 1px solid var(--generalButtonHover);
              background-color: #FFFFFF;
            }
            &:active {
              color: var(--generalButtonActive);
              border: 1px solid var(--generalButtonActive);
              background-color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}
.modal-internal-email-notification {
  width: 440px !important;
  .ant-modal-confirm-content {
    width: 273px;
    text-align: center;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
    margin-top: 0px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    margin-bottom: 0px;
  }

}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 22vh !important;
}
.ant-dropdown-menu-item {
  align-items: center;
  display:flex;
  span:first-of-type {
    display: inline-flex;
  }
  .color-time-frame-wrapper {
    display: inline-flex;
    align-items: center;
  }
  .color-time-frame {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
  }
  .color-time-frame.no-color {
    display: none;
  }
  .box-icon-empty-filter-option {
    img {
      opacity: 0.3;
    }
  }
}
