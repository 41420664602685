.subjectsList-name {
  font-size: 13px;
  font-family: 'Ubuntu';
  font-weight: 300;
  line-height: 18px;
  width: 100%;
  margin-right: 10px;
}

.subjectsList-date {
  font-size: 14px;
  margin-right: 10%;
  font-family: 'Roboto';
  line-height: 18px;
  width: 40%;
  font-weight: 400;
  color: #7f7f7f;
}

.subjectsList-found {
  font-size: 14px;
  margin-right: 10px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 18px;
  width: 27%;
}

.subjectsList-step {
  font-size: 14px;
  margin-right: 10%;
  font-family: 'Roboto';
  line-height: 18px;
  width: 27%;
  font-weight: 400;
  color: #7f7f7f;
}

.subjectsList-time {
  font-size: 13px;
  font-family: 'Ubuntu';
  font-weight: 300;
  line-height: 18px;
  width: 21%;
  margin-right: 10px;
  text-align: center;
}

.subjectsList-span {
  flex: none;
  width: 85%;
  color: #111C26;
  cursor: pointer;
  -webkit-flex: 1 1;
  flex: 1 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
  font-size: 16px;
  max-width: 50%;
}
.check-step-state {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.subjectsList-spanRedacting {
  flex: none;
  width: 85%;
  color: #111C26;
  cursor: pointer;
  -webkit-flex: 1 1;
  flex: 1 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
  font-size: 16px;
  max-width: 50%;
}

.time-span-color {
  color: #C70039;
}
.time-span-color-yellow {
  color: #FFc300;
}

.SubjectList-infoContainer {
  -webkit-align-items: center;
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 70px 0 15px;
  min-height: 70px;
  padding-right: 20px;
  width: 100%;
  cursor: pointer;
}

.subjectList__block-title {
  display: -webkit-flex;
  display: flex;
  color: #737D87;
  font-weight: 700;
  margin: 30px 0 10px;
  font-family: 'Roboto';
  font-size: 12px;
}
.subjectList__block-title::before {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.subjectList__block-title::after {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.styleAccordion {
  width: 100%
}

p.nowrap{
  white-space: nowrap;
}

.subject-list-info {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  font-size: 14px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px 40px 20px 20px;
  position: relative;
  .emailAccordion {
    width: 30%;
    margin-left: 40px;
  }
  .detailsAccordion {
    width: 70%;
  }
  .archiveButton {
    margin-right: 50px;
  }
}

.noDashboard {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
  height: 7rem;
  align: center;
  justify-content: center;
  padding-top: 47px;
  display: flex;
}


.sar-dashboard-table {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background: white;
  .row-selected-table {
    background-color: #DCF1F9;
    border-bottom: 1px solid #9AE0FC;
    .ant-table-tbody > tr > td {
      border: 1px solid #9AE0FC;
    }
  }
  .style-actions {
    width: 8%;
  }
  .resize-icon-v10-data {
    font-size: 30px;
  }

  .ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-table-expand-icon-th {
    display: none;
  }
  .ant-table-thead > tr > th {
    padding: 0px 5px;
  }
  .ant-table-tbody > tr > td {
    padding: 0px 5px;
  }
  .ant-table-row {
    td:last-child {
      text-align: center;
      font-size: 24px;
      .expand-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .anticon {
          color: #a9b0b5;
          opacity: 0.4;
        }
      }
    }

    td > .text-container--start {
    }
    td {
      //height: 61px;
    }
  }
  .ant-table-expanded-row {
    background: white;
    td:first-child {
      display: none;
    }

    td {
      padding: 0;
      &:hover {
        background: white;
      }
    }
  }

  .ant-table-thead {
    border-radius: 3px;
    box-shadow: none;
    background-color: rgb(230, 230, 230);
    .ant-table-column-title {
      .icon-v10-arrow {
        &::before {
          font-weight: bold;
          font-size: 18px;
        }
      }
      .black-title {
        color: #000;
      }
    }
    th {
      background: transparent;
      font-size: 16px;
      font-weight: bold;
      color: #a9b0b5;
      text-align: start !important;
    }
  }

  .text-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    //text-transform: capitalize;
    .sar-completed {
      .icon-v2-finished:before {
        opacity: 0.4;
      }
    }
    .step-icon {
      display: flex;
      justify-content: flex-start;
      position: relative;
      .icon-step {
        font-size: 28px;
        display: flex;
        align-items: center;

        .name-step {
          font-size: 14px;
        }
      }
      .exclamation-mark {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        color: #fafafa;
        background-color: #DD448E;
        border-radius: 16px;
      }
    }
    .time-span-color {
      color: #C70039;
    }
    .time-span-color-yellow {
      color: #FFc300;
    }
    &--center {
      justify-content: flex-start;
    }
    &--transform {
      text-transform: lowercase;
    }
    &--icons {
      font-size: 22px;
      justify-content: flex-start;
    }
    &--days {
      font-weight: bold;
    }
    &--start {
      justify-content: flex-start;
    }
    .icon-blue {
      color: #00D3D8;
      font-size: 22px;
      cursor: pointer;
    }
    .icon-grey {
      color: #aaaaaa;
      font-size: 22px;
    }
  }
}
.ellipsis-v2 {
  writing-mode: horizontal-tb;
  font-size: 24px;
  color: #6e7882;
  margin-right: 15px;
  padding-right: 10px;
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.ellipsis-v3 {
  writing-mode: horizontal-tb;
  font-size: 24px;
  color: #6e7882;
  display: none;
  width: 20px;
}
.parent-delete-sar {
  width: 20px;
  padding: 0px 0px !important;
  //display: inline-block;
  .actionsDotsCell button {
    font-size: 0px;
    line-height: 0px;
    margin-top: 5px;
    margin-left: -80px;
  }
}
  .dots-menu-sar {
      .dropdown-menu {
      width: 120px;
      border: 1px solid #000000;
        margin: 0;
        border-radius: 0;
    }
    .btn-primary.focus, .btn-primary:focus {
      box-shadow: 0 0 0 0.2rem transparent;
    }
  }
.sar-dashboard-filter-menu.ant-dropdown-menu {
  margin: 0;
  padding: 0;
  width: 240px;
  .ant-dropdown-menu-item {
    border: 1px solid #a9b0b5;
    border-bottom: none;
    font-family: Roboto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 9px 8px;
    //width: 250px;
    &:hover {
      background-color: #00D3D8;
      color: #ffffff;
    }
    &:last-child {
      border-bottom: 2px solid #a9b0b5;
    }
    .date-picker {
      border: 1px solid #a9b0b5;
      border-radius: 5px;
      width: 44%;
      padding: 0 3px;
      cursor: pointer;
      &:focus-within {
        border: 1px solid #00D3D8;
        border-radius: 5px;
      }
      .ant-calendar-picker-input {
        border-bottom: none;
        &::placeholder {
          color: #cccccc;
        }
      }
      .ant-calendar-picker-clear {
        margin-right: 6px;
      }
      .icon-calendar-b {
        font-size: 10px;
        top: 18px;
      }
    }
    .date-picker-to {
      margin-left: 5px;
      margin-right: 5px;
      color: #6E7882;
      &:hover {
        color: #6E7882;
      }
    }
    &-selected {
      background-color: #3DD8DB;
    }
  }
  .ant-dropdown-menu-submenu {
    border: 1px solid #a9b0b5;
    font-family: Roboto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    &:hover {
      background-color: #00D3D8 !important;
      color: #ffffff;
    }
    &-title {
      margin: 5px 0;
      color: rgba(0, 0, 0, 0.65);
      &:hover {
        background-color: #00D3D8 !important;
        color: #ffffff;
      }
    }
  }
  .date-picker-input-wrapper{
    padding: 5px 5px;
    &:hover {
      background-color: #ffffff;
    }
  }
  .search-input-wrapper{
    &:hover {
      background-color: #ffffff;
    }
  }
}

.date-picker-selector {
  padding-top: 43px !important;
  .ant-calendar-input-wrap {
    display: none;
  }
}

.steps-filters-popup-position {
  margin-left: 1181px;
}
@media screen and (max-width: 1900px) and (min-width: 1800px) {
  .steps-filters-popup-position {
    margin-left: 1133px;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1700px) {
  .steps-filters-popup-position {
    margin-left: 1068px;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .steps-filters-popup-position {
    margin-left: 991px;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1500px) {
  .steps-filters-popup-position {
    margin-left: 941px;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .steps-filters-popup-position {
    margin-left: 923px;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .steps-filters-popup-position {
    margin-left: 758px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .steps-filters-popup-position {
    margin-left: 627px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 800px) {
  .steps-filters-popup-position {
    margin-left: 539px;
  }
}

.popup-position {
  /*left: 612px !important;*/
}

.content-end-ticket {
  display: flex;
  flex-direction: column;
  .header-end-ticket {
    color: #382e34;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    font-family: Roboto;
    line-height: 20px;
  }
  .subheader-end-ticket {
    color: #6e7882;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 20px;
  }
}