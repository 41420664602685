.smart-box-btl {
  &__container {
    /*width: 90%;*/
    //margin: 134px 100px 0; TASK 1 GRAV-1240
    margin:134px 82px;
  
    .box {
      /*background-color: #ffffff;*/
      margin-bottom: 30px;
      /*box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);*/
      min-height: 800px;
      padding: 30px 0;
      .body.scrolling .btl-topbar {
        padding-bottom: 20px;
        top: 120px;
      }
      .custom-spin-update {
        width: 90% !important;
      }
      .btl-topbar {
        .icon-v17-back-1:before {
          margin-left: 0px;
        }
        .btl-name {
          cursor: pointer;
        }
        .btl-description {
          cursor: pointer;
        }
      }
      .body.scrolling .btl-terms {
        position: sticky;
        top: 211px;
      }
      .btl-topbar {
        margin-top: 24px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: flex;
      }
      .update-box-title {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        .box-title {
          color: #382e34;
        }
        .icon-v14-radio-button-off {
          margin-left: 10px;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.65);
        }
        .icon-v14-question {
          margin-left: 10px;
          font-size: 20px;
          color: #382e34;
        }
      }
      .update-box-title + h3 {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
      }
      .dict-item:hover {
        background-color: #9ae0fc50;
      }
      .dict-item {
        height: 30px;
        background-color: #66536020;
        margin-bottom: 20px;
        display:flex;
        padding: 8px 10px 7px 10px;
        font-size: 14px;
        font-weight: 700;
        position: relative;
        cursor: pointer;

        .ant-checkbox-checked .ant-checkbox-inner::after {
          position: absolute !important;
          display: table;
          //border: 2px solid #fff;
          border: none !important;
          border-top: 0;
          border-left: 0;
          -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          transform: rotate(0deg) scale(1) translate(-50%, -50%);
          opacity: 1;
          -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          content: '\e8ce' !important;
          color: #FFFFFF;
          top: 7px !important;
          left: 7px !important;
          font-size: 12px !important;
          font-family: smartbox_icons_v19;
        }
        .ant-checkbox-wrapper {
          margin-right: 10px;

          .ant-checkbox-inner {
            border-radius: 0px;
            border: 1px solid #382e34;
          }
          .ant-checkbox-inner:hover {
            border: 1px solid var(--generalIconFontHover);
          }
          .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:hover {
            background-color: #EDA41E !important;
            border: 1px solid #EDA41E;
          }
          .ant-checkbox-checked:hover {
            .ant-checkbox-inner {
              background-color: var(--generalIconFontActive) !important;
              border: 1px solid var(--generalIconFontHover) !important;
            }
          }
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: var(--generalIconFontActive) !important;

              &:after {
                border-color: #fff;
                border-width: 1px;
                top: 42%;
                left: 24%;
              }
            }
          }
        }
        i {
          transform: rotate(270deg);
          font-size: 18px;
          padding-right: 3px;
          padding-left: 10px;
          position: absolute;
          right: 0;
        }
      }
    }
    .ant-select {
      margin-right: 2%;
    }
    .box-title {
      font-family: Roboto;
      font-size: 20px;
      font-weight: bold;
      color: rgba(0,0,0,0.85);
    }
    .topbar:not(.no-lists) {
      //display:flex;
      //justify-content: space-between;
      //flex-direction: row-reverse;
      //margin-top: 40px;
      margin-bottom: 30px;

      &.edit-mode {
        justify-content: flex-start;

        .search-content {
          margin-left: 30px;
        }
      }
      .ant-select-selection--single {
        width: 250px;
        height: 40px;

        .ant-select-selection__placeholder {
          display:block !important;
        }
        .ant-select-selection__rendered {
          line-height: 40px;
          margin-left: 20px;
        }
      }
      .search-content {
        width: 60%;
        margin-right: 2%;
        input {
          width: 90%;
          border-radius: 3% 0 0 3%;
        }
        .icon-wrapper {
          background-color: rgba(110, 120, 130, 0.6);
          display: inline-block;
          height: 40px;
          width: 40px;
          position: absolute;
          padding: 10px;
          border-radius: 0 15% 15% 0;
          i.anticon.anticon-search {
            font-size: 20px;
            font-weight: bold;
            color: white;
            display: inline;
            cursor: pointer;
          }
        }
        .ant-input-suffix {
          object-fit: contain;
          font-size: 16px;
          width: 40px;
          height: 40px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          background-color: rgba(110, 120, 130, 0.6);
          display: flex;
          justify-content: center;
          right: 0;
    
          i {
            font-size: 19px;
            color: white;
          }
        }
        .ant-input {
          font-size: 16px;
          font-weight: 300;
          color: #6e7882;
          background: transparent;
          border: 1px solid rgba(110, 120, 130, 0.4);
          padding: 10px 30px 10px 10px;
          height: 40px;       
        }
        .ant-input::-webkit-input-placeholder {
          font-style:italic;
        }
        .ant-input::-moz-placeholder { 
          font-style:italic;
        }
        .ant-input:-ms-input-placeholder { 
          font-style:italic;
        }
        .ant-input:-moz-placeholder { 
          font-style:italic;
        }    
      }    
    }
    
    .table-header-small {
      font-size: 12px;
      font-weight: 400;
      font-family: 'Roboto';
    }
    .table-header-bold {
      font-size: 16px;
      font-weight: 800;
      font-family: 'Roboto';
    }
    .text-right{
      text-align:right;
    }
    .ant-table-content {
      border: 1px solid #e8e8e8;

      .icon-delete-b{
        cursor: pointer;
      
        &:before { 
          font-size: 120%;
        }
      }
    }
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 10px 16px;
    }
    .ant-table-tbody > tr > td {
      font-size: 16px;
      font-family: 'Roboto';

      input[type="text"] {
        border: none;
        padding: 0;
      }
    }
    .add-single-line {
      margin-top: 20px;

      button {
        font-size: 16px;
        font-family: Roboto;
        cursor: pointer;

        img {
          vertical-align: middle;
          padding-left:8px;
        }
      }
    }
    .header-dots {
      margin-top: 10px;
      margin-left: 30px;

      button {
        background-color: transparent !important;
        border: none !important;
        color: black !important;
        font-size: 30px;
        line-height: 22px;
        outline: none;
        margin-top: -15px;   
        padding: 0;   
      }
      .dropdown { 
        margin-right: -10px;
        
        a { 
          padding: 8px 10px;
        }
      }
      .dropdown .dropdown-menu {
        width: 130px;
        min-width: 130px;
        padding: 0px !important;
      }
            
      .dropdown-toggle::after {
        display:none;
      }
      .ellipsis-v { 
        letter-spacing: 2px;
      }
    }
    .mt5 { 
      margin-top: 50px;
    }
    .no_list_available {
      margin-top: 50px;
      p {
        font-family: Roboto;
        text-align: center;
        font-weight: 400;
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
    .ant-table {
      .ant-table-row {
        .ant-input {
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2)
        }
      }
      .ant-table-thead {
        .btn-primary {
          &:focus {
            box-shadow: none;
          }
        }
        .dropdown-item {
          &:hover {
            background-color: #3DD8DB;
            color: #fff;
          }
        }
      }
    }
    .ant-table-empty {
      .ant-table-thead {
        .btn-primary {
          &:focus {
            box-shadow: transparent !important;
          }
        }
      }
      .ant-table-body {
        overflow-x: visible !important;
        overflow-y: visible !important;
      }
    }
    .spinner-page {
      margin-top: 20px;
      font-size: 100px;
    }
  }


}
.header-dots.show .dropdown-menu {
  display:block;
}

.csv-reader-btn {
  border-radius: 30px;
  border: 1px solid #3DD8DB;
  background-color: #3DD8DB;
  color: #ffffff;
  padding: 8px 15px;
  &:hover {
    color: #3DD8DB;
    background-color: #ffffff;
  }
  label {
    font-family: Roboto;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin: 0 !important;
  }
}

.export-btn {
  &:hover {
    color: #FFFFFF !important;
    background-color: #232B38 !important;
    border: 1px solid #232B38 !important;
  }
  &:focus {
    color: #FFFFFF !important;
    background-color: #232B38 !important;
    border: 1px solid #232B38 !important;
  }
}
.create-list-btn {
  &:hover {
    color: #3DD8DB !important;
    border: 1px solid #3DD8DB!important;
  }
}

div.letters-term {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 20px;
  .ant-radio-button-wrapper:hover {
    background-color: #3ED8DB;
    border-radius: 3px;
    box-shadow: none;
    display: inline-block;
    border-left: 0;
    color: #ffffff;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
  }
  .ant-radio-button-wrapper {
    height: 40px;
    padding-top: 5px;
    border-radius: 3px;
    @media ( max-width: 1400px ) {
      height: 35px !important;
      width: 30px;
      padding-top: 1px !important;
      padding-left: 8px;
      &:first-of-type {
        padding-left: 4px;
      }
    }
    @media ( max-width: 1200px ) {
      height: 35px;
      width: 20px;
      padding-top: 2px;
      padding-left: 5px;
      &:first-of-type {
        padding-left: 0;
      }
    }
    span {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      color: #6e7882;
    }
    &-checked:first-child {
      border: solid 1px #6e7882;
    }
    &-checked {
      background-color: #3ED8DB;
      border: solid 1px #6e7882;
      border-radius: 3px;
      box-shadow: none;
      &:hover {
        border: solid 1px #6e7882;
        box-shadow: none;
      }
      span {
        color: #ffffff;
      }
    }

  }
}
.buttons-terms {
  button {
    cursor: pointer;
    margin-left: 25px;
    background-color: transparent !important;
  }
  .icon-v17-add, .icon-v17-add-active {
    color: var(--generalButton);
    font-size: 20px;
  }
  .icon-v17-add-active {
    color: var(--generalIconFontHover);
  }
  .icon-v17-edit, .icon-v17-delete {
    color: #352a31;
    font-size: 20px;

    &[disabled] {
      color: #d1d4d7;
      cursor: default;
    }
  }
  .icon-v17-edit-active, .icon-v17-delete-active {
    color: var(--generalIconFontHover);
    font-size: 20px;
  }
  .ant-btn {
    height: 40px;
    object-fit: contain;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;        
    border-radius: 30px;   
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);     
  }
/*  button:disabled {
    background-color: #FFFFFF !important;
    border: 1px solid #CCCCCC !important;
    cursor: not-allowed;
    color: #CCCCCC !important;
  }*/
  .transp {
    color: #232B38;
    background-color: #ffffff;
    border: 1px solid #232B38;
    margin-right: 40px;
    @media ( max-width: 1400px ) {
      margin-right: 5px;
    }
  }
  .blue {
    color: #ffffff;
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    &:focus {
      color: #ffffff;
      background-color: #3DD8DB;
      border: 1px solid #3DD8DB;
    }
    &:hover {
      color: #3DD8DB;
      background-color: #ffffff;
      border: 1px solid #3DD8DB;
    }
  }
  .transp-blue {
    color: #3DD8DB;
    background-color: #ffffff;
    border: 1px solid #3DD8DB;
    &:hover {
      background-color: #3DD8DB;
      color: #ffffff;
      border: 1px solid #3DD8DB;
    }
  }
  .black {
    background-color: #232B38;
    color: white;

  }
}
.buttons-terms.buttons-terms-dictionaries {
  display: flex;
  justify-content: flex-end;
}
.import-terms-modal {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  .ant-modal-body {
    padding: 30px;
  }
  h1 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: #6e7882;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  label {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 300;
  }
  img { 
    margin: 10px auto; 
    display: block;
  }
  .buttons-terms {
    display: flex;
    justify-content: space-between;
    margin-top: 53px;
  }
  
  .ant-input {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 16px;
    font-weight: 300;
    color: #6e7882;
    background: transparent;
    padding: 10px 30px 10px 10px;
    height: 40px;  
    border-radius: 2px;
    border: solid 1px rgba(110, 120, 130, 0.3);
    background-color: #fcfbfb;
    opacity: 0.6;
    }
  textarea { 
    margin-top: 10px;
    height: auto !important;
  }
  .import-terms-form {
    .import-modal-text {
      font-size: 16px;
      font-weight: 400;
      font-family: Roboto;
      text-align: center;
    }
    .dzu-dropzone {
      height: auto !important;
      float:none !important;
      
    }
  }
}

.import-terms-section {
  .list-selected-color {
    width: 20px;
    height: 20px;
    background-color: #cccccc;
    float: left;
    margin-top: 13px;
    margin-left: 7px;
    cursor: pointer;
  }
}

.ant-popover-content {
  .ant-popover-inner-content {
    padding: 0;
  }
}
.help-message-locations {
  .ant-tooltip-arrow::before {
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
  }
  .ant-tooltip-inner {
    font-family: Roboto;
    color: #382e34;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
    padding: 20px;
  }
  .content-help-message-locations {
    .text-information {
      font-size: 14px;
      font-weight: 400;
      margin-right: 3px;
    }
    .text-information-header {
      font-weight: 700;
      font-size: 14px;
    }
    .more-info-link {
      font-weight: 700;
      font-size: 14px;
      color: #00d4d8;
      cursor: pointer;
      text-decoration: underline;
      width: 65px;
      margin-left: 3px;
      //display: inline-flex;
      //margin-left: 5px;
    }
  }
}
/*    .project-line {
      padding: 30px;
      border-bottom: 1px solid #ebebeb;
      display:flex;
      justify-content: space-between;

      button {
        background-color: transparent !important;
        border: none !important;
        color: black !important;
        font-size: 30px;
        line-height: 22px;
        outline: none;
        margin-top: -15px;   
        padding: 0;   
      }
      .dropdown { 
        margin-right: -10px;
      }
      .dropdown .dropdown-menu {
        width: 130px;
        min-width: 130px;
        padding: 0px !important;
      }
            
      .dropdown-toggle::after {
        display:none;
      }
      .ellipsis-v { 
        letter-spacing: 2px;
      }
      .item-name {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
      }
      ul {
        list-style: disc; 
        padding-left: 50px;
        padding-top: 20px;

        li:not(:last-child) {
          padding-bottom: 10px;
        }
      } 
    }
    .box-footer {
      padding: 50px 30px 30px;
      display:flex;
      justify-content: space-between;

      &.pt0 {
        padding-top: 0;
      }
      img { 
        display: inline-block;
        vertical-align: middle;
        margin-left: 13px;
        cursor: pointer;
      }
      label {
        opacity: 0.6;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        display:block;
        color: black;
        padding-bottom: 10px;
      }
      .subcat-wrapper {
        width: 450px;
      }
      .project-name, .dictionary-name, .subcategory-name {
        width: 400px;
        border: 1px solid rgba(151,151,151,0.4);
        height: 40px;
        border-radius: 2px;
      }
      .placeholder-cont {
        width:400px;
      }
      .placeholder-btn { 
        width: 135px;
      }
      
    }
  }
}
.asset-register-modal.project-delete-modal { 
  width: 450px !important;
}*/

.dictionary-modal {
  width: 790px !important;
  height: 255px;
  box-shadow: 2px 14px 30px rgba(21,0,16,0.3);
  color: #382e34 !important;
  .content-header-modal {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    background-color: var(--subNavigationBackground);
    .icon-v21-close-black {
      font-size: 16px;
      color: #FFFFFF;
    }
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-content {
    box-shadow: none !important;
    border-radius: 0px !important;
    border: none !important;
  }
  .ant-modal-header {
    background: #66525f;
    height: 40px;
    padding: 0px;
    position: relative;
    border-radius: 0px;
    border-bottom: none;
    color: transparent;

    .ant-modal-title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 40px;
      //padding-left: 10px;
    }
  }
  .add-term-modal-title {
    padding-left: 10px;
  }
  .modal-action-btns {
    .icon-v17-add:before {
       margin-right: 0px;
    }
    .icon-v17-add-active {
      margin-right: -4px;
    }
  }
  .ant-modal-close {
    color: #fff;

    .ant-modal-close-x {
      width: 30px;
      height: 40px;
      line-height: 40px;
    }
  }
  .dict-modal-line {
    display:flex;
    height: 40px;
    .optional-description {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      line-height: 20px;
    }
    .content-input-add-term {
      .icon-v17-close-black {
        position: absolute;
        border-radius: 5px;
        right: 20px;
        z-index: 2;
        border: none;
        //top: 90px;
        height: 30px;
        cursor: pointer;
        color: #382e34;
        margin-right: 25px;
        margin-top: 10px;
      }
      .ant-input {
        position: relative;
      }
    }
    &:first-child {
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      margin-bottom: 40px;
    }
    .ant-input {
      box-shadow: none;
      outline: none;
      padding: 7px 10px;
      border-radius: 0px;
    }
    .ant-input:active {
      border: 1px solid #EDA41E;
    }
    .ant-input:focus {
      border: 1px solid #EDA41E;
    }
    label {
      width: 245px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.2px;
      line-height: 20px;
      align-self: center;
    }
    input {
      height: 40px;
      padding: 4px 10px;
      font-weight: 400;
      font-size: 14px; color: #6e7882;
      letter-spacing: 0.2px;
      border: 1px solid #6e7882;
      border-radius: 3px;
      position: relative;
    }
    input:focus {
      box-shadow: none;
    }
    input:hover {
      border: 1px solid #EDA41E;
    }
    &.no-flex {
      display:block;
      height: auto;

      input {
        margin-bottom: 10px;
      }
    }
  }
  .buttons {
    display:flex;
     .ant-btn {
       height: 25px;
       box-shadow: none;
     }
    .transp-orange {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: #6e7882;
      border: 1px solid #6e7882;
      background-color: #fff;
      padding: 0 30px;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: .5px;

      &:hover {
        color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
      }
      &:active {
        color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
      }
    }
    .orange {
      margin-left: auto;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: var(--generalButtonFont);
      background-color: var(--generalButton);
      border: 1px solid #6e7882;
      padding: 0 30px;
      border-radius: 30px;
      font-weight: 400;
      letter-spacing: .5px;

      &[disabled] {
        //background: #6e7882;
        //opacity: 0.3;
        color: #fff;
        background: #D4D7DA;
        border: 1px solid #D4D7DA !important;
        &:active {
          color: var(--generalButtonFont);
          background: #D4D7DA;
          border: 1px solid #D4D7DA !important;
        }
      }
      &:not([disabled]):hover {
        background-color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
      }
      &:active {
        background-color: var(--generalButtonActive);
        color: var(--generalButtonFont);
        border: 1px solid var(--generalButtonActive);
      }
    }
  }
}
.ant-modal-confirm.delete-dict-modal {
  width: 450px !important;
  min-height: 250px;

  .ant-modal-body {
    padding: 30px !important;

    .anticon-question-circle {
      display:none;
    }
    .ant-modal-confirm-content {
      margin-left:0 !important;
      margin-top: 0 !important;

      .icon-del {
        font-size: 40px;
        color: #382e34;
        text-align:center;
        margin-bottom: 10px;
      }
      .delete-dict-q {
        font-weight: 700;
        font-size: 14px;
        color: #382e34;
        letter-spacing: 0.2px;
        line-height: 20px;
        margin-bottom: 10px;
        text-align:center;
      }
      .delete-dict-names {
        font-weight: 400;
        font-size: 14px;
        color: #6e7882;
        letter-spacing: 0.2px;
        line-height: 20px;
        margin-bottom: 40px;
        text-align: center;
      }
    }
  }

  .ant-modal-confirm-btns {
    display:flex;
    float:none !important;
     .ant-btn {
       box-shadow: none;
       height: 25px;
       width: 100px;
     }
    .cancel-button {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: #6e7882;
      border: 1px solid #6e7882;
      background-color: #fff;
      border-radius: 30px;

      &:hover {
        color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
      }
      &:active {
        color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
      }
    }
    .confirm-button {
      margin-left: auto;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: var(--generalButtonFont);
      background-color: var(--generalButton);
      border: 1px solid #6e7882;
      border-radius: 30px;

      &[disabled] {
        background: #6e7882;
        opacity: 0.3;
      }
      &:hover {
        background-color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
      }
      &:active {
        //color: #FFC457;
        background-color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
      }
    }
  }
}

.btl-topbar {
  margin-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #382e34;
  margin-bottom: 10px;
  display:flex;
    .btl-topbar-contain {
      display: flex;
      align-items: center;
    }
  .btl-topbar-contain:hover {
    display: flex;
    color: #EDA41E;
    .icon-v17-back-1 {
      color: #EDA41E;
      margin-right: 10px;
    }
    .icon-v21-back-1 {
      color: #382e34 ;
      margin-right: 10px;
    }
    .icon-v21-back-active:before {
      margin-left: 0px;
    }
    .icon-v21-back-active {
      color: var(--generalIconFontHover);
      margin-right: 10px;
      font-size: 20px;
      align-self: center;
      cursor: pointer;
    }
    .btl-name {
      color: #EDA41E;
    }
    .btl-description {
      color: var(--generalIconFontHover);
    }
  }
  .icon-v17-back-1 {
    font-size: 20px;
    color: #382e34;
    margin-right: 10px;
    align-self: center;
    cursor: pointer;
  }
  .btl-name {
    font-weight: 700;
    font-size: 14px;
    color: #382e34 !important;
    letter-spacing: 0.2px;
    align-self: center;
    margin-right: 10px;
  }
  .btl-description {
    font-weight: 400;
    font-size: 14px;
    color: #382e34;
    letter-spacing: 0.2px;
    align-self: center;
  }
  .actions {
    display:flex;
    margin-left: auto;
    align-items: center;
    .select-all-dictionaries-unchecked {
      .ant-checkbox-inner::before {
        border-color: #FFFFFF;
        position: absolute !important;
        display: table;
        color: #6e7882 !important;
        border: none !important;
        border-top: 0;
        border-left: 0;
        -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        transform: rotate(0deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        content: '\e8ce' !important;
        top: 7px !important;
        left: 7px !important;
        font-size: 12px !important;
        font-family: 'smartbox_icons_v19' !important;
      }
    }
     .select-all-dictionaries {
       color: #382e34;
       font-weight: 400;
       .ant-checkbox-checked .ant-checkbox-inner::after {
         border-color: #FFFFFF;
         position: absolute !important;
         display: table;
         color: #FFFFFF !important;
         border: none !important;
         border-top: 0;
         border-left: 0;
         -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
         -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
         transform: rotate(0deg) scale(1) translate(-50%, -50%);
         opacity: 1;
         -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
         transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
         content: '\e8ce' !important;
         top: 7px !important;
         left: 7px !important;
         font-size: 12px !important;
         font-family: 'smartbox_icons_v19' !important;
       }
     }
    .ant-checkbox {
      line-height: 30px;
    }
    .ant-checkbox-wrapper {
      align-self: center;
      line-height: 30px;

      .ant-checkbox-inner {
        border-radius: 0px;
        border: 1px solid #382e34;
        //border-color: #a8aeb5;
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: var(--generalIconFontActive) !important;

          &:after {
            //border-color: #fff;
            //border-width: 1px;
            //top: 42%;
            //left: 26%;
          }
        }
      }
      &:hover .ant-checkbox .ant-checkbox-inner {
        border-color: var(--generalIconFontHover);
      }
    }
    .icon-v17-delete:hover {
      color: #EDA41E;
    }
    .icon-v17-delete {
      margin-left: 40px;
      margin-right: 40px;
      font-size: 20px;
      color: #382e34;
      background-color: transparent;
      cursor:pointer;
      &[disabled] {
        color: #D4D7DA;
        cursor: default;
      }
    }
    .icon-v17-delete-active {
      margin-left: 40px;
      margin-right: 40px;
      font-size: 20px;
      color: var(--generalIconFontHover);
      cursor: pointer;
    }
    .icon-v17-export-1:hover {
      color: #EDA41E;
    }
    .icon-v17-export-1:active {
      color: #EDA41E;
    }
    .icon-v17-import-1:hover {
      color: #EDA41E;
    }
    .icon-v17-import-1:active {
      color: #EDA41E;
    }
    .icon-v17-export-active-1 {
      color: var(--generalIconFontHover);
      font-size: 20px;
      cursor: pointer;
    }
    .icon-v17-import-active-1 {
      color: var(--generalIconFontHover);
      font-size: 20px;
      cursor: pointer;
    }
    .icon-v17-add {
      font-size: 20px;
      margin-right: 20px;
      color: var(--generalButton);
    }
    .icon-v17-add-active {
      color: var(--generalIconFontHover);
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
    .icon-v17-import-1, .icon-v17-export-1 {
      font-size: 20px;
      color: #382e34;
      cursor: pointer;

      &[disabled] {
        color: rgba(0,0,0,0.25);
        cursor:not-allowed;
      }
    }
    .csv-reader-input {
      align-self: center;
    }
    .csv-reader-input + a {
      margin-left: 20px;
      margin-right: 20px;
      align-self: center;
    }
    .search-content {
      position: relative;
      .ant-input {
        width: 200px;
        height: 30px;
        border-radius: 0;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 14px;
        color: #382e34;
        letter-spacing: 0.2px;
        border: 1px solid #382e34 !important;

        &:focus{
          border-color: var(--generalIconFontHover) !important;
          box-shadow: none;
        }
        &:hover{
          border-color: var(--generalIconFontHover) !important;
          box-shadow: none;
        }
        &:active{
          border-color: #eea518 !important;
          box-shadow: none;
        }
      }
      .icon-wrapper {
        position: absolute;
        top:7px;
        right: 5px;
        cursor:pointer;
      }
      .ant-input-search-icon {
        padding-left: 5px;
      }
      .remove-search-term {
        position: absolute;
        right: 25px;
        font-size: 8px;
        top: 5px;
        color: #382e34 !important;
        border-right: 1px solid #382e34;
        padding: 5px;
        cursor:pointer;
      }
    }
  }
}
.btl-terms {
  padding-bottom: 30px;
  padding-top: 20px;

  .btl-letters {
    display: table;
    width:100%;
    .ant-radio-button-wrapper:hover {
      span:not(.ant-radio-wrapper):not(.ant-radio-button-inner):not(.ant-radio-button) {
        font-size: 14px;
        font-weight: 700;
        color: var(--generalIconFontHover);
      }
    }
    .ant-radio-button-wrapper-disabled {
      cursor: default;
    }
    .ant-radio-button-wrapper {
      border: 0;
      border-radius: 0;
      background-color: transparent;
      padding: 0;
      border-right: 1px solid #382e34;
      display:table-cell;
      text-align: center;
      min-width:45px;
      height: 20px;
      line-height: 20px;

      span:not(.ant-radio-wrapper):not(.ant-radio-button-inner):not(.ant-radio-button) {
        font-size: 14px;
        font-weight: 400;
        //color: #382e3420!important; //TASK 7 GRAV-1240
      }
      &:not(:first-child)::before {
        width: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    .ant-radio-button-wrapper-disabled span:not(.ant-radio-wrapper){
      color: #D4D7DA !important;
    }
  }
}

.letter-area {
  display:flex;
  padding-top: 60px;
 .ant-checkbox-inner:hover {
    border: 1px solid var(--generalIconFontHover)!important;
  }
  .ant-checkbox-checked:hover {
    .ant-checkbox-inner {
      border: 1px solid var(--generalIconFontHover) !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute !important;
    display: table;
    //border: 2px solid #fff;
    border: none !important;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    transform: rotate(0deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
    content: '\e8ce' !important;
    color: #FFFFFF;
    top: 7px !important;
    left: 7px !important;
    font-size: 12px !important;
    font-family: smartbox_icons_v19;
  }
   .ant-checkbox-wrapper {
     color: #6e7882;
   }
  &.first {
    padding-top: 30px;
  }
  >div:first-child {
    width: 20%;
    font-weight: 700;
    font-size: 20px;
    color: #382e34;
    letter-spacing: 0.2px;
    align-self: flex-start;
    padding-top: 0px;
    padding-left: 10px;

    &[data-empty="1"] {
      color: #c1bec1;
    }
  }
  >div:not(:first-child) {
    width: 20%;
    font-weight: 400;
    font-size: 14px;
    color: #382e34;
    letter-spacing: 0.2px;
    line-height: 20px;
  }
  .ant-checkbox+span {
    padding-right: 10px;
    padding-left: 10px;
  }
  .ant-checkbox-wrapper {
    display:block;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .ant-checkbox-inner {
      border-radius: 0px;
      border: 1px solid #637882;
      //border-color: #a8aeb5;
      border-width: 1px;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--generalIconFontActive) !important;
        border-width: 1px;
        border-color: #6e7882;

        &:after {
          border-color: #fff;
          border-width: 1px;
          top: 42%;
          left: 26%;
        }
      }
    }

    &:hover .ant-checkbox .ant-checkbox-inner {
      border-color: var(--generalIconFontHover);
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

}
.letter-area.after-search-area {
  >div:first-child {
    font-weight: 400;
    font-size: 14px;
  }
}
.ant-modal-content {
  box-shadow: 2px 14px 29px 0px rgba(0,0,0,0.4) !important;
}
body.scrolling {
  .btl-topbar {
    position: sticky;
    padding-bottom: 10px;
    top: 60px;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 3;
  }
  .btl-terms {
    position: sticky;
    top: 110px;
    background-color: #FFFFFF;
    z-index: 2;
    width: 100%;
  }
}
.modal-action-btns {
  display:flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 40px;
  .icon-v17-minus, .icon-v17-minus-active {
    font-size: 20px;
    line-height: 20px;
    margin-right: 10px;
    color: #6e7782;
    cursor:pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }
  .icon-v17-add, .icon-v17-add-active {
    font-size: 20px;
    line-height: 20px;
    color: var(--generalButton);
    cursor:pointer;
  }
  .icon-v17-add-active {
    color: var(--generalIconFontHover);
  }
}
.mt-20 {
  margin-top: 20px;
}
.error {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
}
.smart-box-btl-container-update {
  margin: 60px 82px;

}
.annotation-table-pop-confirm {
  .ant-popover-content {
    .ant-popover-inner {
      div {
        .ant-popover-inner-content {
          padding: 10px 12px !important;
          .ant-popover-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}