.boxComing__box {
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  padding: 40px;
}

.boxComing__box p {
  color: #162737;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  margin-bottom: 0;
}

.boxComing__logo {
  height: 85px;
  position: relative;
}

.boxComing__logo img {
  max-width: 295px;
}

@media screen and (min-width: 768px) {
  .boxComing__box {
    width: 48%;
  }
}