.privacyCollection__footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding-left: 20px;
}

.privacyCollection__footer-priorities {
  margin-bottom: 20px;
}

.privacyCollection__footer-text {
  color: #162737;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-bottom: 15px;
}

.privacyCollection__footer-high {
  color: #F44336;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
  text-transform: uppercase;
}

.privacyCollection__footer-high::before {
  background-color: #F44336;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.privacyCollection__footer-medium {
  color: #F0B400;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
  text-transform: uppercase;
}

.privacyCollection__footer-medium::before {
  background-color: #F0B400;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.privacyCollection__footer-low {
  color: #92C654;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
  text-transform: uppercase;
}

.privacyCollection__footer-low::before {
  background-color: #92C654;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.privacyCollection__footer-info p {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.8;
}

@media screen and (min-width: 768px) {
  .privacyCollection__footer {
    flex-direction: row;
  }

  .privacyCollection__footer-priorities {
    border-right: 1px solid #D3D5D9;
    margin: 0 30px 0 0;
    padding-right: 30px;
  }
}