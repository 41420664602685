.formHeader-header {
  border-bottom: 1px solid #EAEAEA;
  /* display: flex;
  justify-content: space-between; */
  padding: 10px 0 20px 0;
  margin: 0 -20px;
}
.newFormHeader-header {
  padding: 0 0 10px 0;
  border-bottom: none;
}
.new-header-set-password {
  padding: 0 0 10px 0;
}

.formHeader-title {
  font-size: 12px;
}

.formHeader-subtitle {
  font-size: 18px;
}

.formHeader-welcome {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}
.logo-set-password {
  display: flex;
  height: 73px;
  margin-left: 10px;
}
.newFormHeader-welcome {
  padding-top: 0px;
}

.formHeader-logo {
  text-align: center;
  margin-left: 30px;
}
.logo-osprey-login {
  width: 290px;
}
.newFormHeader-logo {
  display: flex;
  justify-content: center;
  margin: auto;
}

.formHeader-powered {
  font-size: 12px;
  position: relative;
  top: 8px;
}

@media screen and (min-width: 768px) {
  .formHeader-header {
    padding: 0 0 20px 0;
  }
  .new-header-set-password {
    padding: 0 0 10px 0;
  }
  .logo-set-password {
    display: flex;
    height: 73px;
  }
  .formHeader-logo {
    text-align: center;
    margin-left: 0;
  }
  .newFormHeader-header {
    padding: 0 0 10px 0;
  }
  .newFormHeader-logo {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}