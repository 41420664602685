.questionTypeRadio-item {
  padding: 10px 20px
}

.questionTypeRadio-group {
  align-items: center;
  display: flex
}

.questionTypeRadio-radio {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 50%;
  height: 40px;
  display: inline-block;
  position: relative;
  width: 40px;
}

.questionTypeRadio-radio:hover {
  box-shadow: 0 0 5px 0 #3DD8DB;
  border: 1px solid #3DD8DB;
}

.questionTypeRadio-radioDisabled {
  background-color: #EEEEEE;
  border: 1px solid #E0E8E8;
  border-radius: 50%;
  height: 40px;
  display: inline-block;
  position: relative;
  width: 40px;
}

.questionTypeRadio-label {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeRadio-labelChecked {
  background-color: #3DD8DB;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeRadio-labelDisabled {
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeRadio-labelCheckedDisabled {
  background-color: #BBBBBB;
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeRadio-optionTitle {
  color: #162737;
  flex: 1 1;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px
}

.questionTypeRadio-optionMessage {
  margin-left: 50px;
  font-size: 14px;
}