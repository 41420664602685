.compliance-page {
  margin: 80px 20px;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  .ant-spin {
    position: fixed !important;
    top: 160px !important;
    left: 170px !important;
  }

  &__action-container {
    background-color: #FFFFFF;
    position: relative;
    margin-top: 40px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;
      border-bottom: 1px solid rgba(110, 120, 130, 0.3);

      .title {
        font-size: 1em;
        font-weight: bold;
        margin-left: 16px;
        margin-top: 8px;
        cursor: pointer;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        font-size: 1em;
        font-weight: 300;
        text-align: center;


        .custom-caret {
          border: solid rgba(110, 120, 130, 0.3);
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 8px;
          position: absolute;
          bottom: -8.4px;
          background: white;
        }

        .up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }

        .add-tab {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 0 25px 0;
          cursor: pointer;
          position: relative;

          .icon-add-b {
            font-size: 32px;
            padding-bottom: 15px;
          }
        }

        .find-tab {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 0 10px 50px;
          cursor: pointer;
          position: relative;

          .icon-find-asset-b {
            font-size: 32px;
            padding-bottom: 15px;
          }
        }

        .update-tab {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 0 10px 50px;
          cursor: pointer;

          .icon-update-b-1 {
            font-size: 32px;
            padding-bottom: 15px;
          }
        }

        .delete-tab {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 0 10px 50px;
          cursor: pointer;

          .icon-delete-b {
            font-size: 32px;
            padding-bottom: 15px;
          }
        }
      }
    }

    &__content {
      .add-compliance-document {
        &__input-section {
          font-size: 1em;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;

          input {
            width: 70%;
            border: none;
            background: white;
            border-radius: 0;
            border-bottom: 1px solid #00d3d8;

            &:focus {
              outline: none;
            }
          }
        }

        &__buttons-section {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 40px;

          button {
            margin: 10px;
            width: 110px;
            height: 55px;
            padding: 0;
            border-radius: 30px;
            font-size: 20px;
            font-weight: 400;
          }

          .cancel-button {
            background-color: #FFFFFF;
            color: #3DD8DB;

            &:hover {
              background-color: #3DD8DB;
              color: white;
            }
          }
        }
      }

      .find-compliance-document {
        padding: 31px;

        &__search-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 41px;

          &__select-section {
            width: 20%;

            .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
              color: #6e7882 !important;
            }

            .custom-select {
              width: 100%;
            }
          }

          &__input-section {
            width: 70%;

            input {
              width: 100%;
              font-size: 1em;
              border: none;
              background: white;
              border-radius: 0;
              border-bottom: 1px solid #00d3d8;

              &:focus {
                outline: none;
              }

              ::placeholder {
                color: #bfbfbf;
              }
            }
          }
        }

        &__buttons-section {
          display: flex;
          justify-content: flex-end;

          button {
            width: 128px;
            height: 55px;
            font-size: 20px;
            padding: 10px;
            font-weight: 300;
            border-radius: 50px;

            &:disabled {
              background-color: #6e7882;
              opacity: 0.4;
              border: 1px solid rgba(0, 0, 0, 0.85) !important;
              color: rgba(0, 0, 0, 0.85);
            }
          }
        }

        &__result-section {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .add-compliance-document {
        color: #495057;

        &__select-section {
          padding: 25px 37px 25px 37px;
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 40px;

          .title {
            width: 17.5%;
            font-size: 1em;
            font-weight: bold;
          }

          .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
            color: #495057 !important;
            width: 100%;
          }

          .custom-select {
            width: 40%;

            .ant-select-selection__rendered {
              margin-left: 0;
              margin-right: 5px;
            }
          }
        }

        &__form-section {
          .form-item-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 37px 25px 37px;

            button {
              width: 128px;
              height: 50px;
              border-radius: 25px;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
              font-size: 20px;
              font-weight: 900;
              color: #00d3d8;
              border-color: #00d3d8;

              &:hover {
                background-color: #00d3d8;
                color: white !important;
              }
            }
          }

          .form-element {
            .ant-form-item-label {
              label {
                font-size: 1em;
                font-weight: 300;
                color: #6e7882;

                &:after {
                  content: '';
                }
              }
            }
          }

          .custom-file {
            color: #495057;

            .custom-file-input {
              display: none;
            }

            .custom-file-label {
              border: none;
              border-bottom: 1px solid #3DD8DB;
              border-radius: 0;
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding-left: 0;
              padding-right: 0;
              font-size: 1em;
              font-weight: 300;

              &:after {
                content: '';
                padding: 0;
                border: none;
              }
            }
          }

          .columns-section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            border-bottom: 1px solid rgba(110, 120, 130, 0.3);
            padding: 0 37px 25px 37px;

            .form-element {
              width: 12%;
            }
          }

          .history-update-section {
            position: relative;
            border-bottom: 1px solid rgba(110, 120, 130, 0.3);
            padding: 25px 37px 0 37px;

            .textarea-history-update {
              width: 100%;

              textarea {
                width: 100%;
                max-height: 250px;
                min-height: 100px;
              }
            }
          }

          .additional-fields-section {
            padding: 25px 37px 0 37px;
            .custom-textarea {
              width: 100%;
              max-height: 250px;
              min-height: 100px;
            }
          }

          .custom-caret {
            border: solid rgba(110, 120, 130, 0.3);
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 8px;
            position: absolute;
            bottom: -9.4px;
            background: white;
            left: 50%;
          }

          .down {
            transform: rotate(45deg);;
            -webkit-transform: rotate(45deg);;
          }
        }
      }
    }
  }

  &__content {
    .table-title {
      background-color: #fafafa;
      padding: 15px 0;
      margin-top: 51px;
      width: 121px;
      height: 16px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6e7882;
      margin-bottom: 3px;
    }
    &__table {
      display: flex;
      flex-flow: column nowrap;
      font-size: 1em;
      flex: 1 1 auto;
      border-radius: 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      background: white;
      position: relative;
      .table-header {
        border-radius: 3px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        background-color: rgb(230, 230, 230);
        height: 50px;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        color: #6e7882;
        display: flex !important;

        .td {
          border-bottom: none;

          &:first-child {
            flex-grow: 2;
            justify-content: flex-start !important;
          }
        }

        .td.header-actions {
          justify-content: space-evenly;
          flex-grow: 0.5;
          color: black;
          font-size: 18px;
        }
      }

      .table-row {
        border-bottom: 1px solid #d0d0d0;
        font-size: 1em;
        font-weight: 300;
        color: black;

        &__accordion-container {
          width: 100%;
          .history-container {
            width: 100%;
            border-bottom: 1px solid #e8e8e8;
            .content-version {
              cursor: pointer;
            }
            .history-icon {
              font-size: 1.3em;
              margin-right: 5px;
              cursor: pointer;
            }
            &__title {
              padding: 40px 22px 40px 22px;
              font-weight: bold;
              font-size: 1em;
              color: #6e7882;
            }

            &__table {
              padding:0 22px 40px 22px;

              .ant-table-body {
                color: black;
                .ant-table-thead > tr > th {
                  background: transparent;
                  border-bottom: 1.4px solid black;
                  padding-bottom: 5px;
                }

                .ant-table-thead > tr > th:first-child {
                  padding-left: 2px;
                }
                .ant-table-tbody > tr > td {
                  border-bottom: 1.5px dotted #e8e8e8;
                }
                .ant-table-tbody > tr > td:first-child {
                  padding-left: 2px;
                }

                .ant-table-tbody > tr:last-child > td {
                  border-bottom: none;
                }
              }
            }
          }

          .update-container {
            border-bottom: 1px solid #e8e8e8;
              position: relative;
            .custom-caret {
              border: solid rgba(110, 120, 130, 0.3);
              border-width: 0 0.8px 0.8px 0;
              display: inline-block;
              padding: 8px;
              position: absolute;
              bottom: -9.4px;
              background: white;
              margin-left: 50%;
            }

            .down {
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
            }
          }

          .additional-fields-container {
            border-bottom: 1px solid #e8e8e8;
          }
          .preview-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            [class*="preview-container__"] {
              margin: 0 22px 0 22px;
            }
            [class*="preview-container__"]:last-child {
              margin: 0 22px 10px 22px;
            }
            .title {
              padding: 40px 22px 40px 22px;
              font-weight: bold;
              font-size: 1em;
              color: #6e7882;
            }

            .custom-input {
              width: 95%;
              position: relative;
            }
          }
        }

        .td {
          line-height: 34px;
          border-bottom: none;
          padding: 18px 20px 18px 22px;
          width: 130px;

          &:first-child {
            flex-grow: 2;
            justify-content: flex-start !important;
          }

          .span-item-document {
            display: flex;
            align-items: center;

            .icon-pages-b {
              font-size: 28px;
              margin-right: 8px;
            }
          }

          .span-item-checkbox {
            display: flex;
            flex-direction: column;

            &__child {
              span[class^="icon-"] {
                font-size: 30px;
                margin-right: 10px;
              }

              span:not([class^="icon-"]) {
                width: 30px;
                height: 30px;
                margin-right: 10px;
              }
            }
          }

          .span-item-date {
            display: flex;
            flex-direction: row;
            align-items: center;

            &__with-icon {
              span[class^="icon-"] {
                font-size: 17px;
                height: 17px;
                border-radius: 50%;
                margin-left: 5px;
              }

              .date-status-red {
                background: red;
                color: red;
              }

              .date-status-orange {
                background: #ffb300;
                color: #ffb300;
              }

              .date-status-green {
                background: #38e000;
                color: #38e000;
              }

              .date-status-black {
                background: black;
                color: black;
              }
            }
          }

          span {
            display: flex;
            align-items: center;
            flex-direction: row;
          }
        }

        .td.actions {
          border-left: 1px solid #d0d0d0;
          font-size: 35px;
          color: #6e7882;

          span[class^="icon-"] {
            font-size: 18px;
            color: black;
            opacity: 1;
            cursor: pointer;
          }

          span {
            display: flex;
            align-items: center;
            opacity: 0.4;
            cursor: pointer;
          }
        }
      }

      .th {
        display: none;
        font-weight: 700;
      }

      .th > .td {
        white-space: normal;
        justify-content: flex-start;
        padding: 0 20px;
      }

      .tr {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
      }

      .td {
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        flex-basis: 0;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        white-space: nowrap;
        border-bottom: 1px solid #d0d0d0;
      }
    }

  }

  .custom-select {
    height: 35px;
  }

  .ant-skeleton {
    margin-top: 4vh;
    margin-bottom: 10vh;
  }

  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title, .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: linear-gradient(90deg, #a8d7d8ad 25%, #cef6f7 37%, #a8d7d8ad 63%);
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
}

.empty-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: grey;
  margin-right: 5px;
}

.doc-locker-pdf-modal {
  .custom-modal-body {
    position: initial;

    .ant-spin-dot {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .react-pdf__Document {
      margin-top: 20px;
      .react-pdf__Page {
        margin-bottom: 10px;
        canvas {
          box-shadow: 0 2px 4px 0 #DCDCDC;
        }
      }
    }

    .modal-custom-header {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      background-color: #212121;
      color: white;

      &__document-name {
        display: flex;
        justify-content: center;
      }
      &__page-count {
        margin-left: 10px;
        font-size: 1em;
      }

      &__document-download {
        margin-right: 30px;
        color: #00ffff;
        cursor: pointer;
      }
    }
  }

}
@media only screen and (max-height: 700px) and (min-width: 1200px) {
  .pdf-modal-locker.ant-modal {
    width: fit-content !important;
    .modal-custom-header {
      font-size: 0.8em;
    }
    .ant-modal-close-x {
      width: 23px;
      height: 22px;
      line-height: 28px;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .pdf-modal-locker.ant-modal {
    .modal-custom-header {
      font-size: 0.8em;
    }
    .ant-modal-close-x {
      width: 23px;
      height: 22px;
      line-height: 28px;
    }
  }
  .compliance-page {

    .ant-spin {
      position: fixed !important;
      top: 160px !important;
      left: 170px !important;
    }

    &__action-container {
      font-size: 0.8em;
    }

    .add-compliance-document {
      &__form-section {
        .form-item-actions {
          margin-bottom: 5px;

          button {
            font-size: 1em;
            width: 100px;
            height: 40px;
          }
        }

        .form-element {
          font-size: 0.8em;
        }

        .custom-file .custom-file-label {
          font-size: 1em;
          padding: 10px 0 6px 0;
          height: auto;
        }

        .ant-input-number-input-wrap .ant-input-number-input {
          font-size: 0.8em;
        }

        .ant-select-selection__placeholder {
          font-size: 0.8em;
        }

        .ant-checkbox-group .ant-checkbox-wrapper {
          font-size: 0.8em;
        }

        .ant-calendar-picker .ant-calendar-picker-input {
          font-size: 0.75em;
        }

        .ant-radio-wrapper {
          font-size: 0.8em;
        }

        .ant-input {
          font-size: 0.8em;
          color: #495057;
        }

        .custom-textarea {
          font-size: 0.8em;
          color: #495057;

          textarea {
            padding: 5px;
            line-height: 1;
          }
        }
      }
    }

    &__content__table {
      .table-header {
        font-size: 0.8em;
      }

      .table-row {
        font-size: 0.8em;

        .td .span-item-checkbox {
          &__child {
            span[class^="icon-"] {
              font-size: 25px;
              margin-right: 8px;
            }

            span:not([class^="icon-"]) {
              width: 25px;
              height: 25px;
              margin-right: 8px;
            }
          }
        }

        &__accordion-container {
          .history-container {
            .history-icon {
              font-size: 1.1em;
              cursor: pointer;
            }

            &__title {
              font-size: 0.8em;
              padding-bottom: 20px;
              padding-top: 20px;
            }

            &__table {
              font-size: 0.8em;
              .ant-table-row {
                font-size: 0.8em;
              }
              .ant-table-thead {
                font-size: 0.9em;
              }
            }
          }
          .preview-container {
            .title {
              font-size: 0.8em;
              padding-bottom: 20px;
              padding-top: 20px;
            }
            [class*="custom-"] {
              font-size: 0.8em;
            }
          }
        }
      }
    }

    .ant-calendar {
      width: 200px !important;
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .pdf-modal-locker.ant-modal {
    .modal-custom-header {
      font-size: 0.9em;
    }
    .ant-modal-close-x {
      width: 28px;
      height: 28px;
      line-height: 32px;
    }
  }
  .compliance-page {
    .ant-spin {
      position: fixed !important;
      top: 160px !important;
      left: 170px !important;
    }
    &__action-container {
      font-size: 0.8em;
    }

    .add-compliance-document {
      &__form-section {
        .form-item-actions {
          margin-bottom: 5px;

          button {
            font-size: 1em;
            width: 100px;
            height: 40px;
          }
        }

        .form-element {
          font-size: 0.9em;
        }

        .custom-file .custom-file-label {
          font-size: 1em;
          padding: 10px 0 6px 0;
          height: auto;
        }

        .ant-input-number-input-wrap .ant-input-number-input {
          font-size: 0.9em;
        }

        .ant-select-selection__placeholder {
          font-size: 0.9em;
        }

        .ant-checkbox-group .ant-checkbox-wrapper {
          font-size: 0.9em;
        }

        .ant-calendar-picker .ant-calendar-picker-input {
          font-size: 0.85em;
        }

        .ant-radio-wrapper {
          font-size: 0.9em;
        }

        .ant-input {
          font-size: 0.9em;
          color: #495057;
        }

        .custom-textarea {
          font-size: 0.9em;
          color: #495057;

          textarea {
            padding: 5px;
            line-height: 1;
          }
        }
      }
    }

    &__content__table {
      .table-header {
        font-size: 0.9em;
      }

      .table-row {
        font-size: 0.9em;

        .td .span-item-checkbox {
          &__child {
            span[class^="icon-"] {
              font-size: 28px;
              margin-right: 8px;
            }

            span:not([class^="icon-"]) {
              width: 28px;
              height: 28px;
              margin-right: 8px;
            }
          }
        }

        &__accordion-container {
          .history-container {
            .history-icon {
              font-size: 1.2em;
              cursor: pointer;
            }

            &__title {
              font-size: 0.9em;
              padding-bottom: 20px;
              padding-top: 20px;
            }

            &__table {
              .ant-table-row {
                font-size: 0.9em;
              }
              .ant-table-thead {
                font-size: 0.9em;
              }
            }
          }
          .preview-container {
            .title {
              font-size: 0.9em;
              padding-bottom: 20px;
              padding-top: 20px;
            }
            [class*="custom-"] {
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }

  .ant-calendar {
    width: 250px !important;
  }
}