.privacyDashboard__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyDashboard__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
}

.privacyDashboard__boxHeader {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.privacyDashboard__boxTitle {
  border-bottom: 1px solid #162737;
  color: #162737;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacyDashboard__boxTitleTotal {
  font-size: 18px;
  font-weight: 300;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
}

.privacyDashboard__boxTitleTotal::before {
  content: '-';
  left: 0;
  position: absolute;
  top: 0;
}

.privacyDashboard__boxText {
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.privacyDashboard__boxHeader button {
  height: 34px;
}

.privacyDashboard__loading {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.privacyDashboard__page .collectionList__wrapper {
  display: flex;
  flex-direction: column;
}

.privacyDashboard__page .topicList-wrapper {
  display: flex;
  flex-direction: column;
}

.privacyDashboard__page .boxAddNewDataset__wrapper {
  margin-bottom: 20px;
}

.privacyDashboard__footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.privacyDashboard__boxFooter {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}

.privacyDashboard__boxFooterHeader {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  padding: 20px;
}

.privacyDashboard__boxFooterTitle {
  border-bottom: 1px solid #162737;
  color: #162737;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
}

.privacyDashboard__boxFooterText {
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.25;
  padding: 20px 20px 0;
}

.privacyDashboard__boxFooter button {
  display: block;
  margin: 15px auto;
}

@media screen and (min-width: 768px) {
  .privacyDashboard__boxHeader {
    flex-direction: row;
  }

  .privacyDashboard__boxText {
    margin-bottom: 0;
  }

  .privacyDashboard__page .collectionList__wrapper {
    flex-direction: row;
  }

  .privacyDashboard__page .topicList-wrapper {
    flex-direction: row;
  }

  .privacyDashboard__footer {
    flex-direction: row;
  }
}