@font-face {
  font-family: 'smartbox_icons_v19';
  src: url('./font_v21/smartbox_icons_v19.eot?18146455');
  src: url('./font_v21/smartbox_icons_v19.eot?18146455#iefix') format('embedded-opentype'),
       url('./font_v21/smartbox_icons_v19.woff2?18146455') format('woff2'),
       url('./font_v21/smartbox_icons_v19.woff?18146455') format('woff'),
       url('./font_v21/smartbox_icons_v19.ttf?18146455') format('truetype'),
       url('./font_v21/smartbox_icons_v19.svg?18146455#smartbox_icons_v19') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'smartbox_icons_v19';
    src: url('../font/smartbox_icons_v19.svg?18146455#smartbox_icons_v19') format('svg');
  }
}
*/
[class^="icon-v21"]:before, [class*=" icon-v21"]:before {
  font-family: "smartbox_icons_v19";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-v21-tick-1:before { content: '\e8ce'; } /* '' */
.icon-v21-close-black:before { content: '\e8fc'; } /* '' */
.icon-v21-back-active:before { content: '\e8c6'; } /* '' */
.icon-v21-forth-active:before { content: '\e931'; } /* '' */
.icon-v21-back-1:before { content: '\e898'; } /* '' */
.icon-v21-forth-1:before { content: '\e89f'; } /* '' */
.icon-v21-arrow-bold:before { content: '\e895'; } /* '' */
.icon-v21-arrow-medium:before { content: '\e896'; } /* '' */
.icon-v21-clean-active:before { content: '\e84e'; } /* '' */
.icon-v21-clean:before { content: '\e84f'; } /* '' */
.icon-v21-lock:before { content: '\e8d0'; } /* '' */
.icon-v21-lock-open:before { content: '\e8d2'; } /* '' */
.icon-v21-lock-open-active:before { content: '\e8d4'; } /* '' */
.icon-v21-add:before { content: '\e81d'; } /* '' */
.icon-v21-add-active:before { content: '\ea51'; } /* '' */
.icon-v21-upload-1:before { content: '\e852'; } /* '' */
.icon-v21-upload-active-1:before { content: '\e8ca'; } /* '' */
.icon-v21-download-active-1:before { content: '\e8cc'; } /* '' */
.icon-v21-download-1:before { content: '\e83c'; } /* '' */
.icon-v21-edit-active:before { content: '\e800'; } /* '' */
.icon-v21-edit:before { content: '\e801'; } /* '' */
.icon-v21-quick-sweep:before { content: '\e8e1'; } /* '' */
.icon-v21-end-1:before { content: '\e89c'; } /* '' */
.icon-v21-end-active:before { content: '\e8e5'; } /* '' */
.icon-v21-start-1:before { content: '\e8a6'; } /* '' */
.icon-v21-start-active:before { content: '\e8e3'; } /* '' */
