.smart-box-settings {
  &__container {
    width: 90%;
    margin: 56px auto 0 auto;
  
    .box {
      background-color: #ffffff;
      margin-bottom: 30px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
    }
    .box-title {
      font-family: Roboto;
      font-size: 22px;
      font-weight: bold;
      padding: 30px;
      border-bottom: 1px solid #ebebeb;
    }
    .project-line {
      padding: 30px;
      border-bottom: 1px solid #ebebeb;
      display:flex;
      justify-content: space-between;

      button {
        background-color: transparent !important;
        border: none !important;
        color: black !important;
        font-size: 30px;
        line-height: 22px;
        outline: none;
        margin-top: -15px;   
        padding: 0;   
      }
      .dropdown { 
        margin-right: -10px;
      }
      .dropdown .dropdown-menu {
        width: 130px;
        min-width: 130px;
        padding: 0px !important;
      }
            
      .dropdown-toggle::after {
        display:none;
      }
      .ellipsis-v { 
        letter-spacing: 2px;
      }
      .item-name {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
      }
      ul {
        list-style: disc; 
        padding-left: 50px;
        padding-top: 20px;

        li:not(:last-child) {
          padding-bottom: 10px;
        }
      } 
    }
    .box-footer {
      padding: 50px 30px 30px;
      display:flex;
      justify-content: space-between;

      &.pt0 {
        padding-top: 0;
      }
      img { 
        display: inline-block;
        vertical-align: middle;
        margin-left: 13px;
        cursor: pointer;
      }
      label {
        opacity: 0.6;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        display:block;
        color: black;
        padding-bottom: 10px;
      }
      .subcat-wrapper {
        width: 450px;
      }
      .project-name, .dictionary-name, .subcategory-name {
        width: 400px;
        border: 1px solid rgba(151,151,151,0.4);
        height: 40px;
        border-radius: 2px;
      }
      .placeholder-cont {
        width:400px;
      }
      .placeholder-btn { 
        width: 135px;
      }
      .ant-btn {
        margin-top: 30px;        
        width: 135px;
        height: 40px;
        object-fit: contain;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        text-align: center;        
        border-radius: 30px;   
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);      
        color: #ffffff;
        background-color: #3DD8DB;
        border: 1px solid #3DD8DB;   
      }
    }
  }
}
.asset-register-modal.project-delete-modal { 
  width: 450px !important;
}