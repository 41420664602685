.questionnaireGlobal__loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.questionnaireGlobal__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
> div {
  margin-bottom: 20px;
}
}