.connectors-page{
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  //width: 100%;
  background: #FFFFFF;
  //margin-top: 100px;
  //padding:30px 100px 0 100px;
  margin: 100px 82px;
  padding: 30px 0px 0;
  &__content {
    .connectors-header {
      display: flex;
      flex-direction: row;
      color: #382e34;
      align-items: center;
      .title-header {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
      }
    }
    .content-connectors-page {
      .connectors-table{
        .topbar {
          .btn-container{
            display: flex;
            flex-direction: row-reverse;
            margin-top: 40px;
            margin-bottom: 30px;
            button {
              cursor: pointer;
              width: 20px;
              height: 20px;
            }
            .icon-v17-delete, .icon-v17-add {
              &[disabled]{
                color:#D4D7DA;
              }
            }
            .icon-v17-delete {
              color:#382E34;
            }
            .icon-v17-delete-active{
              color: var(--generalIconFontHover);
            }
            .icon-v17-add {
              color: var(--generalButton);
              margin-left: 20px;
            }
            .icon-v17-add-active {
              color: var(--generalIconFontHover);
              margin-left: 20px;
            }
          }
        }
        &__content {
          .table-connectors-content {
            .progress-info {

            }
            .active-connect:hover {
              cursor: pointer;
              color: #00d4d8;
            }
            .text-provider:hover {
              color: blue;
              cursor: pointer;
            }
            .ant-table-wrapper {
              .ant-table-content {
                .ant-table-body {
                  .ant-table-header-column {
                    .ant-table-column-title {
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 700;
                      font-size: 14px;
                      line-height: 20px;
                      span {
                        margin-right: 0;
                      }
                    }
                  }
                }
                .ant-table-tbody {
                  .ant-table-row td {
                    font-family: Roboto;
                    font-style:normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    .text-update {
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                      display: inline-block;
                      width: 173px;
                      line-height: 16px;
                    }
                  }
                }
              }
            }
            .ant-checkbox-wrapper {
              .ant-checkbox-inner {
                border-radius: 0!important;
                border:1px solid #6e7882;
                &:after {
                  border-color:#eda41e;
                  background-color: transparent;
                }
              }
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: var(--generalIconFontActive) !important;
                &:after {
                  position: absolute !important;
                  display: table;
                  color:#FFFFFF;
                  border:none !important;
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: "\e8ce" !important;
                  top: 8px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                  font-family: "smartbox_icons_v19" !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.connector-modal {
  width: 790px !important;
  height:376px !important;
  .content-header-modal-connector {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 10px;
    background-color: var(--subNavigationBackground);
  }
  .ant-modal-content {
    width: 790px;
    .ant-modal-close{
      .ant-modal-close-x {
      color:#FFFFFF;
        position:relative;
        top:-7px;
        right:-7px;
        font-size: 20px;
      }
    }
    .ant-modal-header {
      height: 40px;
      padding: 0px;
      background-color: #665360;
      border-radius: 0;
      border:none;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color:#FFFFFF;
        line-height: 30px;
        width: 100%;
      }
    }
    .ant-modal-body {
      padding:30px;
      .add-connector-form {
        .select-connector-box {
          .ant-select-dropdown {
            .icon-box {
              width: 20px;
              height: 20px;
            }
          }
          .select-box {
            color: #637882;
            .icon-box {
              width: 20px;
              height: 20px;
              margin-right: 10px;
              margin-left: 5px;
            }
          }
          .ant-select-selection-selected-value {
            display: flex !important;
            font-size: 14px;
            font-weight: 300;
            align-items: center;
          }
          .ant-select {
            width: 100%;
          }
          .ant-select-selection--single {
            height: 30px;
            display: flex;
            align-items: center;
            background-color: #fafafa;
          }
        }
        .select-connector-wrapper {
          .title-select-connector {
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;

          }
          .select-connector {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
            .content-select-connector {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 710px;
            }

          }
          .input-connection-owner {
            margin-top: 10px;
            margin-bottom: 20px;
          }
        }
      }
      .footer-connector-modal {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .action-cancel-connect {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: #FFFFFF;
          color: #6e7882;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color: #FFFFFF;
            border-color: var(--generalButtonHover);
            color: var(--generalButtonHover);
          }

          &:focus {
            border: 1px solid #6e7882;
            background-color: #FFFFFF;
            color: #6e7882;
            -webkit-box-shadow: none;
            box-shadow:none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: #FFFFFF;
            color: var(--generalButtonActive);
          }
        }
        .action-cancel-loading {
          width: 180px;
          height: 25px;
          border: 1px solid #FFC457;
          background-color: #FFFFFF;
          color: #FFC457;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-family: 'Roboto';
          font-style: normal;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;
        }
        .action-connect {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: var(--generalButton);
          color: var(--generalButtonFont);
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color:var(--generalButtonHover);
            border-color: var(--generalButtonHover);
            //color: #FFFFFF;
          }

          &:focus {
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
            //color: #FFFFFF;
            //-webkit-box-shadow: none;
            //box-shadow: none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
            //color: #FFFFFF;
          }
        }
        .action-connect:disabled {
          cursor: default;
          background: #D4D7DA;
          color:#fff;
          &:hover {
            background: #D4D7DA;
            border-color: #D4D7DA;
            color: #FFFFFF;
          }

          &:focus {
            border: 1px solid #00D3D8;
            background: #D4D7DA;
            color: #FFFFFF;
          }
        }
        .action-connect-loading {
          width: 180px;
          height: 25px;
          background-color: #FFC457;
          color: #FFFFFF;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-family: "Roboto";
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;
          border: 1px solid #EDA41E;
        }
        .anticon.anticon-loading {
          margin-right: 15px;
        }
      }
    }
  }
}
.modal-delete-connector {
  .ant-modal-content {
    .ant-modal-body {
      padding: 30px !important;
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          .ant-modal-confirm-title {
          font-size: 32px;
          color: #382e34;
          }
          .ant-modal-confirm-content {
            color: #382e34;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            font-family: Roboto;
            line-height: 20px;
          }
        }
        .ant-modal-confirm-btns {
          margin-top: 40px;
          button span {
            padding: 0;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 400;
          }
          .confirm-button {
            height: 30px;
            width: 200px;
            text-transform: capitalize;
            background-color: var(--generalButton);
            border: 1px solid #6e7882;
            color: var(--generalButtonFont);
            font-size: 16px;
            &:hover {
              color: var(--generalButtonFont);
              border: 1px solid var(--generalButtonHover);
              background-color: var(--generalButtonHover);
            }
            &:active {
              //color: #FFFFFF;
              border: 1px solid var(--generalButtonActive);
              background-color: var(--generalButtonActive);
            }
          }
          .cancel-button {
            height: 30px;
            width: 200px;
            text-transform: capitalize;
            background-color: #FFFFFF;
            color: #6e7882;
            font-size: 16px;
            border: 1px solid #6e7882;
            &:hover {
              color: var(--generalButtonHover);
              border: 1px solid var(--generalButtonHover);
              background-color: #FFFFFF;
            }
            &:active {
              color: var(--generalButtonActive);
              border: 1px solid var(--generalButtonActive);
              background-color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .modal-delete-connector {
    .ant-modal-content {
      left:0;
    }
  }
}