.errorPageStyle {
  text-align: center;
  font-size: 40px;
  position: relative;
  bottom: 14rem;
}

.styleTitleError {
  font-size: 240px;
  color: #555555;
  padding-top: 60px;
  position: relative;
  line-height: 100%;
  margin-bottom: 0rem;
  bottom: 8rem;
}
.styleSmall {
  font-size: 40px;
  line-height: 100%;
  vertical-align: top;
  bottom: 3rem;
  display: flex;
}