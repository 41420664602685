.update-list {
  font-family: Roboto;
  .category-tree-node-empty {
    display: none;
  }
  .new-tab-container {
    .container-category-reg {
      margin-bottom: 10px;
    }
    .ai-tags-scroll {
     overflow-y: initial !important;
    }
    &__content-tab {
      margin-top: 0px !important;
      padding-bottom: 20px;
      .ant-tree-checkbox {
        display: none;
      }
      .ant-tree {
        .asset-panel {
          margin-left: 0px !important;
          &__children-image {
            &__children-image-name {
              margin-right: 0px !important;
            }
          }
          .content-child {
            padding-left: 5px;
          }
          .update-name-parent {
            letter-spacing: 0.2px;
            font-weight: 400;
            font-size: 14px;
            color: #382e34;
          }
          .empty-status {
            color: transparent;
          }
          .icon-v17-redaction-hover {
            font-size: 20px;
          }
          .icon-v17-redaction {
            font-size: 20px;
          }
          &__left-container {
            .update-category-name {
              font-weight: 400;
              letter-spacing: 0.2px;
              font-size: 12px;
              color: #a6acb2;
            }
          }
          .update-parent-children {
            .total-child {
              letter-spacing: 0.2px;
              font-weight: 400;
              font-size: 14px;
              color: #382e34;
            }
            .icon-v17-redaction-hover {
              font-size: 20px;
            }
            .update-name-parent {
              letter-spacing: 0.2px;
              font-weight: 400;
              font-size: 14px;
              color: #382e34;
              justify-content: normal;
              .anticon {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.update-reg-ex {
  padding-bottom: 10px;
  .new-tab-container {
    .ai-tags-scroll {
      overflow-y: initial !important;
    }
    &__content-tab {
      margin-top: 0px !important;
      .category-tree-node-empty {
        display: none;
      }
      .ant-tree-checkbox + span {
        padding-left: 0px;
      }
      .ant-tree {
        .ant-tree-checkbox {
          display: none;
        }
        .asset-panel {
          .icon-v17-redaction-hover {
            font-size: 20px;
          }
          .icon-v17-redaction {
            font-size: 20px;
          }
          .empty-redaction {
            font-size: 20px;
            color: transparent;
          }
          .update-children-name {
            letter-spacing: 0.2px;
            font-weight: 400;
            font-size: 14px;
            color: #382e34;
            margin-right: 0px;
          }
          .position-children-ocr {
            padding-left: 5px;
          }
          .position-children {
            padding-left: 10px;
          }
          .position-parent {
            padding-left: 5px;
            justify-content: normal;
            .anticon {
              display: none;
            }
            .rotate-arrow-bold {
              -webkit-transform: rotate(180deg);
              writing-mode: vertical-rl;
            }
          }
          .child-name {
            //font-weight: 400 !important;
          }
          .number-size {
            letter-spacing: 0.2px;
            font-weight: 400;
            font-size: 14px;
            color: #382e34;
          }
          .update-category-name-top {
            font-weight: 400;
            letter-spacing: 0.2px;
            font-size: 12px;
            color: #a6acb2;
          }
          &__left-container {
            margin-top: 1px !important;
            &__category-name {
              letter-spacing: 0.2px;
              font-weight: 400;
              font-size: 14px;
              color: #382e34;
            }
          }
        }
      }
    }
  }
}
.new-tabs-page {
  .new-tab-container {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: Roboto;
    &__title-tab {
      font-size: 13px;
      font-weight: 300;
      color: #6e7782;
      padding-top: 20px;
      height: 40px;
      padding-left: 20px;
    }

    .image-tags-scroll {
      height: 58vh;

      ul {
        position: relative;
      }
    }

    .ai-tags-scroll {
      overflow-y: auto;

      ul {
        position: relative;
      }
    }

    &__content-tab {
      overflow-y: initial;
      margin-top: 20px;
      margin-bottom: 10px;

      .ant-tree li span.ant-tree-checkbox {
        padding: 5px 0;
        height: 14px;
        margin: 0;
      }

      .ant-tree .disabled-expand-icon > .ant-tree-checkbox {
        padding: 6px 0;
        height: 15px;
      }

      .disabled-expand-icon > .ant-tree-checkbox {
        padding: 6px 0;
        height: 15px;
        margin: 0;
      }

      .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
      }

      .ant-tree-child-tree > li:first-child {
        width: 100%;
        position: relative;
        padding-top: 10px;
      }

      .ant-tree li .ant-tree-node-content-wrapper {
        width: 100%;
      }

      .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
      }

      .disabled-expand-icon > .ant-tree-switcher {
        display: none;
      }


      .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: #3ED8DB;
        border-color: #3ED8DB;
      }

      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: #3ED8DB;
      }

      .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: #3DD8DB !important;
      }

      .ant-tree-checkbox-checked::after {
        border-color: transparent;
      }

      .ant-tree-checkbox-indeterminate {
        .ant-tree-checkbox-inner::after {
          left: 50%;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border-width: 1.7px;
        }
      }

      .ant-tree {
        .top-parent {
          width: 100%;
          left: 0px;
          border-top: 1px solid #6e7882;
          border-bottom: 1px solid #6e7882;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .anticon-message {
            margin-right: 15px;
          }

          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            padding-left: 23px;
          }

          .left-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 15%;
            z-index: 3;
            font-size: 18px;

            span {
              padding-left: 0;
              cursor: pointer;
              font-size: 14px;
              display: flex;
              align-items: center;
              height: 24px;

              .icon-add-b {
                font-size: 18px;
                margin-right: 2px;
              }

              .icon-add-b:before {
                height: 17px;
              }
            }
          }
        }

        .asset-panel {
          color: #382e34;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          //margin-left: 5px;

          .container-category-reg {
            border-bottom: none;
            padding-left: 2px;
          }

          &__left-container {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #6e7882;
            margin-top: 20px;

            button {
              padding-left: 45px;
            }

            &__category-name {
              font-family: Roboto;
              font-size: 12px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #6e7882;
              text-transform: uppercase;
              margin-right: 5px;
              margin-top: 2px;
            }

            &__subcategory-name {
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-stretch: normal;
              font-weight: bold;
              letter-spacing: normal;
              color: #6e7882;
              text-transform: capitalize;
              margin-right: 5px;
            }

            &__child-name {
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #6e7882;
              margin-right: 5px;
              margin-left: 15px;
              width: 100%;
              display: flex;
              justify-content: space-between;

              &--line-through {
              }
            }
          }

          &__children-image {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 12px;

            &__children-image-name {
              font-family: Roboto;
              font-weight: 300;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #6e7882;
              text-transform: lowercase;
              margin-right: 5px;
              padding-top: 2px;
              display: flex;
              width: 100%;
              justify-content: space-between;

              .child-name {
               font-weight: bold;
              }
              .child-size {
                width: 72%;
                text-transform: initial;
              }
              .child-parent-bold {
                font-weight: 700;
              }
              .child-parent {
                font-weight: 300;
              }

            }
          }
        }

        li:last-child {
          padding-bottom: 0;
        }

        li {
          padding: 5px 0;

          .ant-tree-node-content-wrapper {
            height: 20px;
            line-height: 20px;
          }

          ul {
            padding: 0;
          }

          span.ant-tree-switcher {
            display: none;
          }

          .ant-tree-checkbox-inner {
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }

          .ant-tree-checkbox-inner::after {
            left: 22%;
            width: 4.714286px;
            height: 7.142857px;
            border-width: 1.7px;
          }

          .ant-tree-checkbox-indeterminate {
            .ant-tree-checkbox-inner::after {
              left: 50%;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border-width: 1.7px;
            }
          }

        }

        .category-tree-node > .ant-tree-checkbox {
          padding: 0px 0;
          height: 15px;
          margin-top: 17px;
        }

        .disabled-expand-icon > .ant-tree-checkbox {
          padding: 8px 0;
          border-top: 1px solid #6e7882;
          height: 32px;
          margin: 0;
          border-bottom: 1px solid #6e7882;
        }

        .disabled-expand-icon > .ant-tree-node-content-wrapper {
          height: 32px;
          padding: 0 0px;
        }
      }
    }

  }
  .button-btn-redact {
    padding: 1px 13px;
  }
}

.add-loading-reg-ex {
  display: flex;
  width: 52%;
  justify-content: flex-start;
  font-size: 16px;
}