.search-history-page {
  &__container {
    width: 100%;
    .update-find-asset-container {
      margin-bottom: 0px;
      .category-tree-node  {
        margin-bottom: 20px;
      }
      .ant-tree-treenode-switcher-close:hover {
        .ant-tree-checkbox-inner {
          background-color: #de0d0d;
          border: 1px solid #d9d9d9;
        }
      }
      .ant-tree-checkbox {
        display: none;
      }
      .ant-tree-treenode-selected:hover {
        .ant-tree-checkbox-inner {
          background-color: #de0d0d;
          //border: 1px solid #d9d9d9;
        }
      }
      .ant-tree .category-tree-node > .ant-tree-checkbox:hover {
        padding: 2px 0;
        height: 15px;
        .ant-tree-checkbox-inner {
          background-color: #de0d0d;
          border: 1px solid #d9d9d9;
        }
      }
      .ant-tree-checkbox:hover {
        .ant-tree-checkbox-inner:hover {
          background-color: #de0d0d;
          border: 1px solid #d9d9d9;
        }
      }
      .asset-panel {
        .icon-v17-redaction {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 5px;
        }
        .icon-v17-redaction:before {
          margin-right: 0px;
          margin-left: 0px;
        }
        &__left-container {
          .icon-v17-redaction-hover {
            font-size: 20px;
            margin-right: 10px;
            margin-left: 5px;
          }
          .icon-v17-redaction-hover:before {
            margin-right: 0px;
            margin-left: 0px;
          }
          .icon-v17-redaction {
            font-size: 20px;
            margin-right: 10px;
            margin-left: 5px;
          }
          .space-empty {
            //width: 40px;
            color: transparent;
          }
        }
      }
    }
    .content-tree-search-history {
      width: 100%;
      .header-action-ai-results {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        margin-top: 20px;
        padding-right: 15px;
        margin-bottom: 10px;
        padding-left: 10px;

        .content-option-ai {
          display: flex;
          align-items: center;

          .icon-v17-redaction-hover {
            color: #6e7882;
            font-size: 18px;
          }
          .icon-v17-redaction {
            color: #6e7882;
            font-size: 18px;
          }
        }

        .custom-option-review {
          width: 100px;
          border: none;
          font-family: Roboto;
          font-weight: 400;
          font-size: 14px;
          color: #6e7882;
          .ant-select-selection .ant-select-selection__rendered {
            margin-top: 1px;
          }
          .ant-select-selection {
            border: none;
          }

          .ant-select-selection__placeholder {
            font-weight: 400;
            font-size: 14px;
            color: #6e7882;
          }

          .ant-select-arrow {
            color: #6e7882;
            right: 8px;
          }
        }
        .wr-review-redact {
          padding: 0px;
          margin-bottom: 0px;
          .button-control {
            width: 100%;
            padding-bottom: 0px;
            .anticon {
              padding-top: 2px;
            }
            &__left {
              color: #6e7882;
              border: 1px solid #6e7882 !important;
            }
            &__left:hover {
              color: #6e7882;
              border: 1px solid #6e7882 !important;
              background-color: #00d4d8;
            }
            &__right {
              color: #6e7882;
              border: 1px solid #6e7882 !important;
              background-color: #FFFFFF;
            }
            &__right:hover {
              color: #6e7882;
              border: 1px solid #6e7882 !important;
              background-color: #00d4d8;
            }
            button {
              height: 20px;
            }
          }
        }
      }
      .ant-tree li span.ant-tree-switcher {
        display: none;
      }
       .category-tree-node-parent {
         margin-bottom: 20px;
         margin-right: 10px;
         .ant-tree-checkbox {
           display: none;
         }
         li {
           span.ant-tree-switcher {
             display: none !important;
           }
           ul {
             padding: 0px 0px 0px 10px;
           }
         }
         .ant-tree-child-tree {
           padding: 0px 0px 0px 5px;
         }
         .ant-tree-node-content-wrapper {
           width: 100%;
           margin-right: 10px;
         }
         .asset-panel-history-parent {
           padding-left: 5px;
           background-color: #ff9157;
           //width: 260px;
           height: 30px;
           display: flex;
           padding-right: 10px;
           align-items: center;
           margin-right: 10px;
           width: 100%;
           justify-content: space-between;
           align-items: center;
           .icon-v17-redaction-hover {
             font-size: 20px;
           }
           .icon-v17-redaction {
             font-size: 20px;
           }
           .non-redacted-search {
             margin-right: 28px;
           }
           .content-visible-category {
             float: right;
             display: flex;
             align-items: center;
             justify-content: center;
             .icon-v12-visible {
               font-size: 20px;
             }
             .icon-v12-unvisible {
               font-size: 20px;
             }
           }
           &__container-search {
             align-items: center;
             .parent-search-history {
               font-weight: 700;
               font-size: 14px;
               font-family: Roboto;
               letter-spacing: 0.2px;
               color: #382e34;
             }
             .anticon {
               color: #382e34;
               font-size: 12px;
             }
           }
         }
       }
      .category-tree-node-child {
        //margin-left: 14px;
         .asset-panel-history {
           //width: 220px;
           display: flex;
           .icon-v17-redaction-hover {
             font-size: 20px;
           }
           .icon-v17-redaction {
             font-size: 20px;
           }
           .non-redacted-search {
             margin-right: 28px;
           }
           .children-history-contain {
             display: flex;
             justify-content: space-between;
             //width: 240px;
             width: 100%;
             margin-right: 20px;
             font-weight: 500;
             font-size: 14px;
             font-family: Roboto;
             letter-spacing: 0.2px;
             color: #382e34;
           }
         }
      }
    }
  }
}

.custom-option-review-history {
  border: 1px solid var(--generalIconFontHover) !important;
}