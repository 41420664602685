.near-deduplication {
  display: flex;
  font-family: Roboto;
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  width: 100%;
  background: #FFFFFF;
  margin-top: 34px !important;
  padding: 30px 40px 0;
  &__content {
    width: 100%;
    .header-near {
      display: flex;
      color: #382e34;
      align-items: center;
      margin-bottom: 40px;
      .title-header {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.5px;
        vertical-align: middle;
        alignment: left;
        line-height: 20px;
      }
      .icon-v14-question {
        margin-left: 10px;
        font-size: 20px;
        color: #382e34;
      }
      .help-message-locations {
        .ant-tooltip-arrow::before {
          width: 14px;
          height: 14px;
          background-color: #FFFFFF;
          border: 1px solid #382e34;
        }
        .ant-tooltip-inner {
          font-family: Roboto;
          color: #382e34;
          background-color: #FFFFFF;
          border: 1px solid #382e34;
          padding: 20px;
        }
        .content-help-message-locations {
          .text-information {
            font-size: 14px;
            font-weight: 400;
            margin-right: 3px;
          }
          .text-information-header {
            font-weight: 700;
            font-size: 14px;
          }
          .more-info-link {
            font-weight: 700;
            font-size: 14px;
            color: #00d4d8;
            cursor: pointer;
            text-decoration: underline;
            width: 65px;
            margin-left: 3px;
            //display: inline-flex;
            //margin-left: 5px;
          }
        }
      }
    }
    .content-near-deduplication {
      width: 100%;
        .near-table {
          width: 100%;
          &__content {
            width: 100%;
            .content-actions-deduplicate-near {
              display: flex;
              position: relative;
              .icon-v21-arrow-medium {
                font-size: 18px;
                font-weight: 700;
              }
              .icon-v21-arrow-bold {
                font-size: 18px;
                font-weight: 700;
                margin-top: -1px;
              }
              .button-auto-go {
                display: flex;
                position: absolute;
                right: 200px;
                top: -125px;
                z-index: 4;
                .ant-spin-spinning {
                  margin-right: 2px;
                }
                .button-dropdown-newest {
                  border-radius: 50px;
                  position: fixed;
                  .ant-dropdown-menu {
                    padding: 0px 0;
                    border-radius: 1px;
                  }
                  .ant-btn:disabled {
                    color: #FFFFFF;
                    background-color: #D4D7DA;
                    //border: 1px solid #D4D7DA !important;
                    cursor: default;
                    padding: 0 10px;
                    border: 1px solid #d4d7da00 !important;
                    &:hover {
                      background-color: #D4D7DA;
                      color: #FFFFFF;
                    }
                  }
                  .ant-btn:first-child {
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                  }
                  .ant-btn:last-child {
                    border-top-right-radius: 25px;
                    border-bottom-right-radius: 25px;
                  }
                  .ant-btn {
                    //border-radius: 50px;
                    background-color: var(--generalButton);
                    color: var(--generalButtonFont);
                    box-shadow: none;
                    letter-spacing: 0.5px;
                    cursor: pointer;
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Roboto;
                    //line-height: 25px; TASK 5 GRAV-2583
                    line-height: 20px;
                    border: 1px solid #6e7882;
                    margin-top: 0px;
                    padding: 0px 10px;
                    height: 25px;
                    //position: fixed;
                    &:hover {
                      background-color: var(--generalButtonHover);
                      //color: #FFFFFF;
                      border: 1px solid var(--generalButtonHover);
                    }
                    &:active {
                      background-color: var(--generalButtonActive);
                      //color: #FFFFFF;
                      border: 1px solid var(--generalButtonActive);
                    }
                  }
                }
                button:disabled {
                  padding: 1px 18px;
                }
                .button-blue-go {
                  height: 25px;
                  border: 1px solid #6e7882;
                  background-color: #EDA41E;
                  color: #ffffff;
                  box-shadow: none;
                  vertical-align: middle;
                  horiz-align: center;
                  letter-spacing: 0.5px;
                  cursor: pointer;
                  font-weight: 500;
                  font-size: 16px;
                  padding: 1px 18px;
                  //text-transform: capitalize;
                  border-radius: 40px;
                  font-family: Roboto;
                  line-height: 18.75px;
                  width: 180px;
                  position: fixed;
                  display: flex;
                  right: 3%;
                  line-height: 22px;
                  &:hover {
                    background-color: #FFC457;
                    color: #FFFFFF;
                    border: 1px solid #EDA41E;
                  }
                  &:active {
                    background-color: #EDA41E;
                    color: #FFFFFF;
                    border: 1px solid #EDA41E;
                  }
                }
                .button-blue-go:disabled {
                  color: #FFFFFF;
                  background-color: #D4D7DA;
                  border: 1px solid #D4D7DA !important;
                  //opacity: 0.3;
                  cursor: default;
                  &:hover {
                    background-color: #D4D7DA;
                    color: #FFFFFF;
                    border: 1px solid #D4D7DA;
                    //opacity: 0.3;
                    cursor: not-allowed;
                  }
                }
              }
              .button-blue-go:disabled {
                color: #FFFFFF;
                background-color: #D4D7DA;
                border: 1px solid #D4D7DA !important;
                //opacity: 0.3;
                cursor: default;
                &:hover {
                  background-color: #D4D7DA;
                  color: #FFFFFF;
                  border: 1px solid #D4D7DA;
                  //opacity: 0.3;
                  cursor: not-allowed;
                }
              }
            }
            .near-table-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              button:disabled {
                cursor: default;
              }
               .content-select-all-near {
                 display: flex;
                 align-items: center;
                 margin-left: 5px;
                 font-family: Roboto;
                 .select-deduplicate-checkbox {
                   line-height: 30px;
                 }
                 .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
                   border-color: var(--generalIconFontHover);
                 }
                 .select-non-checkbox {
                   .ant-checkbox-wrapper .ant-checkbox-inner {
                     border-radius: 0% !important;
                     border: 1px solid #6e7882;
                   }
                   .ant-checkbox-checked .ant-checkbox-inner {
                     background-color: #EDA41E !important;
                   }
                   .ant-checkbox-inner:after {
                     position: absolute !important;
                     display: table;
                     color: #6e7882 !important;
                     border: none !important;
                     border-top: 0;
                     border-left: 0;
                     background-color: #FFFFFF;
                     -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                     -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                     transform: rotate(0deg) scale(1) translate(-50%, -50%);
                     opacity: 1;
                     -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                     transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                     content: '\e8ce' !important;
                     top: 7px !important;
                     left: 7px !important;
                     font-size: 12px !important;
                     font-family: 'smartbox_icons_v19' !important;
                   }
                   .ant-checkbox-inner::before {
                     content: "\f00c" !important;
                     font-family: 'FontAwesome' !important;
                     border-radius: 50%;
                     -webkit-transform: rotate(-5deg);
                     transform: rotate(-5deg);
                     border: none;
                     color: #6e7882;
                     font-size: 10px !important;
                     top: 2px;
                     left: 2px;
                     position: absolute;
                   }
                   .ant-checkbox-checked .ant-checkbox-inner {
                     background-color: #FFFFFF !important;
                   }
                 }
                 .checkbox-near {
                   .option-select-all-disabled {
                     opacity: 0.6;
                   }
                   .ant-checkbox-checked .ant-checkbox-inner::after {
                     border-color: #FFFFFF;
                     position: absolute !important;
                     display: table;
                     color: #FFFFFF;
                     border: none !important;
                     border-top: 0;
                     border-left: 0;
                     -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                     -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                     transform: rotate(0deg) scale(1) translate(-50%, -50%);
                     opacity: 1;
                     -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                     transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                     content: '\e8ce' !important;
                     top: 7px !important;
                     left: 7px !important;
                     font-size: 12px !important;
                     font-family: 'smartbox_icons_v19' !important;
                   }
                   .ant-checkbox-wrapper .ant-checkbox-inner {
                     border-radius: 0% !important;
                     border: 1px solid #6e7882;
                   }
                   .ant-checkbox-checked .ant-checkbox-inner {
                     background-color: var(--generalIconFontActive) !important;
                   }
                   .ant-checkbox-inner:after {
                     border-color: #ffffff !important;
                   }
                 }
                 .select-all-near {
                   margin-left: 10px;
                   font-family: Roboto;
                   font-size: 14px;
                   color: #382e34;
                   font-weight: 400;
                 }
               }

              .buttons-control-pagination {
                float: left;
                display: flex;
                align-items: center;
                font-family: Roboto;
                font-size: 14px;
                color: #382e34;
                font-weight: 400;
                letter-spacing: 0.2px;
                line-height: 20px;
                &__text {
                  margin-left: 10px;
                  margin-right: 10px;
                }
                .disabled-text {
                  color: #D4D7DA;
                }
                .insert-page {
                  text-align: center;
                  width: 36px;
                  padding: 7px 2px;
                  margin-right: 5px;
                  font-weight: 400;
                  height: 25px;
                  border: 1px solid #6e7882;
                  font-size: 14px;
                  letter-spacing: 0.2px;
                  line-height: 20px;
                  &:focus {
                    border-color: #EDA41E;
                    outline: none;
                  }
                }
                .insert-page:focus-visible {
                  border: 1px solid #EDA41E;
                }
                .insert-page:focus {
                  border: 1px solid #EDA41E;
                }
                .insert-page:hover {
                  border: 1px solid #EDA41E;
                }
                .icon-v14-start-active {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .icon-v14-star {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .back-active {
                  font-size: 20px;
                  color: #FFFFFF;
                }
                .back-active:before {
                  background-color: #EDA41E;
                  border-radius: 50px;
                }
                .icon-v14-start-1 {
                  font-size: 20px;
                }
                .icon-v14-end-active {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .forth-1-active {
                  font-size: 20px;
                  color: #FFFFFF;
                }
                .forth-1-active:before {
                  background-color: #EDA41E;
                  border-radius: 50px;
                }
                .icon-v14-back-1 {
                  font-size: 20px;
                }
                .icon-v14-forth-1 {
                  font-size: 20px;
                }
                .icon-v21-back-1 {
                  font-size: 20px;
                }
                .icon-v21-forth-1 {
                  font-size: 20px;
                }
                .icon-v21-end-1 {
                  font-size: 20px;
                }
                .icon-v21-end-active {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .icon-v21-start-1 {
                  font-size: 20px;
                }
                .icon-v21-start-active {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .back-active {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .icon-v21-back-active {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .icon-v21-forth-active {
                  font-size: 20px;
                  color: #EDA41E;
                }
                .disabled-pagination {
                  cursor: not-allowed;
                  //opacity: 0.6;
                  font-weight: 400;
                  //font-size: 14px;
                  color: #D4D7DA;
                }
                .disabled-back {
                  cursor: default;
                  font-weight: 400;
                  color: #D4D7DA;
                }
                .disabled-next {
                  cursor: default;
                  font-weight: 400;
                  color: #D4D7DA;
                }
                .icon-v14-end-1 {
                  font-size: 20px;
                }
              }

              .per-page-near {
                border: 1px solid transparent;
                height: 20px;
                font-family: Roboto;
                font-size: 14px;
                color: #382e34;
                font-weight: 400;
                padding-left: 3px;
                line-height: 20px;
                display: flex;
                align-items: center;
                .ant-select-selection {
                  border: none;
                  background: none;
                }
                .ant-select-selection__rendered {
                  line-height: 20px;
                }
                .ant-select-selection--single {
                  border: none;
                  height: 20px;
                }
                .ant-select .ant-select-selection .ant-select-arrow {
                  margin-right: 0;
                  top: 50%;
                  right: 7px;
                  color: #382e34;
                }
                &:hover {
                  height: 20px;
                  border: 1px solid #EDA41E;
                  padding-left: 3px;
                  color: #EDA41E;
                  line-height: 20px;
                  .ant-select-selection {
                    color: #EDA41E !important;
                    height: 20px;
                  }
                  .ant-select-arrow {
                    color: #EDA41E !important;
                  }
                }
              }
              .per-page-near.disabled {
                .ant-select-selection--single {
                  height: 20px;
                  color: #d4d7da;
                }
                .ant-select-disabled .ant-select-selection {
                  cursor: default !important;
                }
                .ant-select .ant-select-selection .ant-select-arrow {
                  color:#d4d7da;
                }
                .ant-select-arrow:after {
                  color:#d4d7da;
                }
                &:hover {
                  border: 1px solid transparent;
                  color: #d4d7da;
                  cursor: default;

                  .ant-select-selection {
                    color: #d4d7da !important;
                    cursor: default !important;
                  }
                  .ant-select-arrow {
                    color: #d4d7da !important;
                  }
                  .ant-select-arrow:after {
                    color: #d4d7da !important;
                  }
                }
              }
              .name-box-info {
                display: flex;
                align-items: center;
                width: 30%;
              .icon-v21-back-active {
                  color: #EDA41E;
                  font-size: 20px;
                  margin-right: 10px;
                  cursor: pointer;
                }
                .icon-v21-back-1 {
                  color: #382e34;
                  font-size: 20px;
                  margin-right: 10px;
                }
                .icon-v14-back-1 {
                  font-size: 20px;
                  color: #382e34;
                  margin-right: 20px;
                }
                .content-count-file {
                  border: 1px solid #491bc2;
                  background: #491bc2;
                  border-radius: 25px;
                  height: 20px;
                  width: 20px;
                  margin-right: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .name-count {
                    color: #FFFFFF;
                  }
                }
                .name-file {
                  font-family: Roboto;
                  font-weight: 400;
                  font-size: 14px;
                  color: #491bc2;
                  alignment: left;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  vertical-align: middle;
                }
              }
              .button-control-near {
                float: left;
                button {
                  height: 20px;
                  width: 40px;
                  padding: 0;
                  border-radius: 0;
                  font-weight: 100;
                  &:hover {
                    background-color: #FFC457;
                    border: solid 1px #EDA41E !important;
                    color: #fff;
                  }
                  &:active {
                    background-color: #EDA41E;
                    border: solid 1px #EDA41E !important;
                    color: #fff;
                  }
                }

                &__left {
                  border-bottom-left-radius: 18.5px !important;
                  border-top-left-radius: 18.5px !important;
                  background: #FFFFFF;
                  border: solid 1px #382e34 !important;
                  color: #382e34;
                  border-right:solid  0 !important;
                  &:disabled{
                    background: #fff !important;
                    border: solid 1px #D4D7DA !important;
                    color: #D4D7DA !important;
                    &:hover {
                      background: #fff !important;
                      border: solid 1px #D4D7DA !important;
                      color: #D4D7DA !important;
                    }
                    &:active {
                      background: #fff !important;
                      border: solid 1px #D4D7DA !important;
                      color: #D4D7DA !important;
                    }
                  }
                }

                &__right {
                  border-bottom-right-radius: 18.5px !important;
                  border-top-right-radius: 18.5px !important;
                  background-color: #FFFFFF;
                  border: solid 1px #382e34;
                  color: #382e34;
                  &:disabled{
                    background: #fff;
                    border: solid 1px #D4D7DA !important;
                    color: #D4D7DA;
                    &:hover {
                      background: #fff !important;
                      border: solid 1px #D4D7DA !important;
                      color: #D4D7DA !important;
                    }
                    &:active {
                      background: #fff !important;
                      border: solid 1px #D4D7DA !important;
                      color: #D4D7DA !important;
                    }
                  }
                }

                &__text {
                  padding-right: 10px;
                }

                &__redact {

                }
              }
              .sweep-info {
                display: flex;
                align-items: center;
                .icon-v14-question {
                  font-size: 20px;
                  color: #382e34;
                  margin-right: 20px;
                }
                button:disabled {
                  padding: 1px 18px;
                }
                .button-blue-sweep {
                  height: 25px;
                  border: 1px solid #6e7882;
                  background-color: #EDA41E;
                  color: #ffffff;
                  box-shadow: none;
                  vertical-align: middle;
                  horiz-align: center;
                  letter-spacing: 0.5px;
                  cursor: pointer;
                  font-weight: 500;
                  font-size: 16px;
                  padding: 0px 18px;
                  text-transform: capitalize;
                  border-radius: 40px;
                  font-family: Roboto;
                  line-height: 18.75px;
                  width: 180px;
                  &:hover {
                    background-color: #FFC457;
                    color: #FFFFFF;
                    border: 1px solid #EDA41E;
                  }
                  &:active {
                    background-color: #EDA41E;
                    color: #FFFFFF;
                    border: 1px solid #EDA41E;
                  }
                }
                .button-blue-sweep:disabled {
                  color: #FFFFFF;
                  background-color: #D4D7DA;
                  border: 1px solid #D4D7DA;
                  //opacity: 0.3;
                  cursor: default;
                  &:hover {
                    background-color: #D4D7DA;
                    color: #FFFFFF;
                    border: 1px solid #D4D7DA;
                    //opacity: 0.3;
                    cursor: default;
                  }
                }
              }
            }
            .table-near-content {
              //display: flex;
              width: 100%;
              border-top: 1px solid #382e34;
              margin-top: 10px;
              .ant-table-thead > tr > th {
                background: none;
              }
              .ellipsis-v2 {
                right: 0px;
                bottom: -1px;
              }
              .table-near-list {
                .ant-checkbox-wrapper .ant-checkbox-inner {
                  background-color: #fff0 !important;
                }
                .column-name-duplicate .update-list-name .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: #fff0 !important;
                }
              }
              .table-near {
                .ant-checkbox-checked .ant-checkbox-inner::after {
                  border-color: #FFFFFF;
                  position: absolute !important;
                  display: table;
                  color: #FFFFFF;
                  border: none !important;
                  border-top: 0;
                  border-left: 0;
                  -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: '\e8ce' !important;
                  top: 7px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                  font-family: 'smartbox_icons_v19' !important;
                }
                .ant-checkbox-checked:focus {
                  .ant-checkbox-inner {
                    border: 1px solid #EDA41E;
                  }
                }
                .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
                  border-color: var(--generalIconFontHover);
                }
                .ant-table-tbody {
                  vertical-align: middle !important;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: var(--generalIconFontActive) !important;
                  border: 1px solid #6e7882;
                }
                .ant-table-tbody > tr > td {
                  //border-bottom: 1px solid #6e7882;
                  background: #FFFFFF;
                }
                .ant-table-tbody > tr > td.ant-table-selection-column {
                  text-align: left;
                  padding-left: 5px;
                }
                .ant-checkbox-inner:after {
                  border-color: #ffffff !important;
                }
                .ant-checkbox-wrapper .ant-checkbox-inner {
                  border-radius: 0% !important;
                  border: 1px solid #6e7882;
                  //border: 1px solid #EDA41E;
                }
                .ant-table-tbody > tr {
                  vertical-align: initial !important;
                }
                .ant-checkbox-checked:hover {
                  .ant-checkbox-inner {
                    background-color: var(--generalIconFontActive) !important;
                    border: 1px solid #EDA41E;
                  }
                }
                .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
                  background: #9ae0fc50;
                  cursor: pointer;
                }
                .ant-table-row:hover {
                  background: #e6f7ff;
                }
                .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
                  height: 30px;
                  padding: 0px;
                  vertical-align: middle;
                }
                .column-arrow-duplicate {
                  .arrow-duplicate-content {
                    .rotate-arrow-medium {
                      -webkit-transform: rotate(270deg);
                      writing-mode: vertical-rl;
                    }
                    .icon-v16-arrow-medium {
                      font-size: 20px !important;
                      float: right;
                    }
                  }
                }
                .column-sweep-duplicate {
                  .action-drop-cell {
                    .dropdown-menu {
                      top: 5px !important;
                    }
                  }
                  .menu-left-item-directory {
                    padding: 0px 10px !important;
                  }
                  .menu-duplicate {
                    .dropdown-item {
                      color: #382e34;
                      letter-spacing: 0.2px;
                      border: 0px;
                      font-size: 14px;
                      font-family: Roboto;
                      line-height: 30px;
                    }
                    .dropdown a:hover {
                      background-color: #9AE0FC50;
                      color: #382e34;
                    }
                    .dropdown .dropdown-menu {
                      background-color: #FFFFFF;
                      position: absolute;
                      top: -21px !important;
                      left: -93px !important;
                      width: 120px;
                      height: 30px;
                      border: 1px solid #382e34;
                      box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3);
                      margin-top: 5px;
                      font-size: 14px;
                      color: #382e34;
                      border-radius: 0rem;
                    }
                  }
                }
                .column-name-duplicate {
                  color: #382e34;
                  font-weight: 400;
                 .content-date {
                   display: flex;
                   align-items: center;
                  .content-buttons-sorter {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 20px;
                    line-height: 12px;

                    .icon-v16-arrow-bold:before {
                      line-height: 9px;
                    }

                    .active-sort {
                      color: #EDA41E;
                    }

                    .rotate-arrow {
                      -webkit-transform: rotate(180deg);
                      display: flex;
                    }
                  }
                }
                  .duplicate-list-name {
                    .icon-v21-clean {
                      font-size: 16px;
                    }
                    .icon-v21-clean:before {
                      margin-left: -2px !important;
                    }
                    .title-row {
                      font-weight: 700;
                      width: 100%;
                    }
                  }
                  .update-column-near {
                    color: #6e7882;
                    font-weight: 400;
                  }
                  .update-list-name {
                    display: flex;
                    align-items: center;
                    color: #6e7882;
                    font-weight: 400;
                    .icon-v21-clean {
                      &::before {
                        margin-left: -2px !important;
                      }
                    }
                    .icon-v21-clean:before {
                      margin-right: 9px;
                      margin-left: 7px !important;
                    }
                    .ant-checkbox-wrapper {
                      //margin-right: 10px;
                      //Task 1 GRAV-2583
                      margin-top: 4px;
                      margin-right: 0px;
                      margin-bottom: 4px;
                    }
                    .ant-checkbox-checked .ant-checkbox-inner {
                      background-color: #EDA41E !important;
                      border: 1px solid #6e7882;
                    }
                    .ant-checkbox-wrapper .ant-checkbox-inner {
                      border: none;
                    }
                      .ant-checkbox-inner::after {
                      content: '\e8d2' !important;
                      top: 7px !important;
                      left: 7px !important;
                      font-size: 20px !important;
                      font-family: 'smartbox_icons_v19' !important;
                      color: #382e34;
                      position: absolute !important;
                      display: table;
                      border: none !important;
                      border-top: 0;
                      border-left: 0;
                      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                      transform: rotate(0deg) scale(1) translate(-50%, -50%);
                      opacity: 1;
                      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s
                    }
                    .ant-checkbox-checked .ant-checkbox-inner {
                      background-color: #fff0 !important;
                    }
                    .ant-checkbox-checked .ant-checkbox-inner:after {
                      content: '\e8d0' !important;
                      top: 7px !important;
                      left: 7px !important;
                      font-size: 20px !important;
                      font-family: 'smartbox_icons_v19' !important;
                    }
                    .ant-checkbox-inner:after {
                      border-color: #ffffff !important;
                    }
                    .ant-checkbox-wrapper .ant-checkbox-inner {
                      border-radius: 0% !important;
                      margin-right: 9px;
                      margin-left: 7px;
                    }
                    .ant-checkbox {
                      left: 0px;
                    }
                  }
                  .name-duplicate-content {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    .content-number-duplicates {
                      border: 1px solid #491bc2;
                      background: #491bc2;
                      border-radius: 25px;
                      height: 20px;
                      width: 20px;
                      margin-right: 20px;
                      display: flex;
                      justify-content: center;
                      .number-duplicates {
                        color: #ffffff;
                      }
                    }
                    .text-style {
                      font-family: Roboto;
                      font-weight: 400;
                      font-size: 14px;
                      color: #491bc2;
                      alignment: left;
                      line-height: 20px;
                      letter-spacing: 0.2px;
                      vertical-align: middle;
                    }
                  }
                }
              }
            }
          }
        }
    }
  }
}
.select-per-page-near {
  color: #6e7882 !important;
  border: 1px solid #EDA41E !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  width: 80px !important;
  position: fixed !important;
  box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3) !important;
  //left: 1415px !important;
  //padding: 10px 0px 0px 10px;
  .ant-select-dropdown-menu-item {
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #382e34;
    line-height: 30px;
    letter-spacing: 0.2px;
  }
  .ant-select-dropdown-menu {
    padding: 0px !important;
  }
  .ant-select-dropdown-menu-item {
    padding: 0px 0px 0px 10px;
    height: 30px;
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #9AE0FC !important;
  }
}
.select-per-page-near.select-per-page-near-update {
  //left: 46% !important;
}

.confirm-near {
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title {
  height: auto;
  }
  .icon-v21-clean {
    font-size: 40px;
  }
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-btns button {
          box-shadow: none;
        }
        .ant-modal-confirm-btns {
          .cancel-button {
            color: #6e7882;
            border: 1px solid #6e7882;
            &:hover {
              color: var(--generalButtonHover);
              border: 1px solid var(--generalButtonHover);
            }
            &:active {
              color: var(--generalButtonActive);
              border: 1px solid var(--generalButtonActive);
            }
          }
          .confirm-button {
            &:hover {
              background-color: var(--generalButtonHover);
              border: 1px solid var(--generalButtonHover);
            }
            &:active {
              background-color: var(--generalButtonActive);
              border: 1px solid var(--generalButtonActive);
            }
          }
        }
      }
    }
  }
  .content-titles-sweep {
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    .title-sweep {
      font-size: 14px;
      font-weight: 400;
      color: #6e7882;
    }
  }
  .icon-v21-lock {
    font-size: 40px;
  }

  .content-title-lock {
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    .title-lock {
      font-size: 14px;
      font-weight: 400;
      color: #6e7882;
    }
  }
}

.dropdown-newest-class {
  .ant-dropdown-menu {
    padding: 0px 0 !important;
    border-radius: 1px !important;
  }
  .ant-select-dropdown-menu-item:hover {
    background-color: #9ae0fc50 !important;
  }
}

.ant-dropdown-menu {
  //padding: 0px 0 !important;
  border-radius: 0px !important;
  position: relative !important;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #9ae0fc50 !important;
}