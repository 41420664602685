
:root {
  --navigationBackground: #535d66;
  --navigationBackgroundHover: #535d66;
  --navigationBodyFont: #ffffff;
  --navigationBodyFontHover: #00d4d8;
  --subNavigationBackground: #6e7882;
  --subNavigationBackgroundHover: #6e7882;
  --subNavigationBodyFont: #ffffff;
  --subNavigationBodyFontHover: #00d4d8;
  --subNavigationBodyFontActive: #00d4d8;
  --generalIconFontHover: #4096ff;
  --generalIconFontActive: #0958d9;
  --generalButton: #1677ff;
  --generalButtonHover: #4096ff;
  --generalButtonActive: #0958d9;
  --generalButtonFont: #ffffff;
}

@keyframes slidedown {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
.new-header-container {
  background-color: var(--navigationBackground);
  //background-color: $gray !important;
  //background-color: #382E34;
}
.header-container {
  height: 60px;
  align-items: center;
  background-color: var(--navigationBackground);
  display: flex;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  position: fixed;
  z-index: 5;
  width: 100%;
  //padding-right: 20px;
}

.header-sidebarOpenedHeader {
  align-items: center;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 20px 25px;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.header-logo-and-navigation {
  display: flex;
  align-items: center;
  width: 625px;
  padding-left: 20px;
/*  .header-logo-xerox {
    font-size: 16px;
    color: #d92231;
    font-weight: 400;
    cursor: pointer;
    padding-right: 10px;
  }
  .header-logo-xerox:hover {
    font-size: 16px;
    color: #d9223130;
    font-weight: 400;
    cursor: pointer;
  }*/
  .header-logo {
    display: flex;
    //margin-left: 12px;
    img {
      width: 40px;
    }

    .icon-gravicus {
        //width: 50px;
        //height: 33px;
        color: #00d3d8;
        cursor: pointer;
        font-size: 40px;
      margin-right: 10px;
    }
    .icon-gravicus:before {
      margin-right: 0px;
      margin-left: 0px;
    }
    .header-separator-icon {
      width: 1px;
      height: 40px;
      border: solid 1px #FFFFFF;
    }
  }
}

.header-powered {
  color: #727D87;
  display: block;
  font-size: 12px;
}

.header-logoImage {
  height: 27px;
  width: 80px;
  cursor: pointer;
}

.header-pageContainer {
  display: flex;
}

.header-app {
  display: none;

  &::after {
    background-color: #BBBABA;
    background-image: url(../../assets/right-caret.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 50%;
    content: "";
    height: 27px;
    position: absolute;
    right: -11px;
    top: 50%;
    transform: translateY(-50%);
    width: 27px;
    z-index: 5;
  }
}

.header-appImage {
  height: 40px;
  width: 36px;
}

.header-page {
  color: #727D87;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  margin-right: 10px;
}

.header-pageName {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  margin-top: 7px;
  font-weight: 500;
}

.header-infoContainer {
  display: flex;
  //justify-content: space-between;
  justify-content: flex-end;
  align-items: center;
  //margin-right: 9px;
  min-width: 530px;
}

.header-company {
  display: none;
}
.new-header-companyName {
  .icon-v12-home-active {
    font-size: 30px;
    color: #FFFFFF;
   // color: #382e34;
    cursor: pointer;
    &:hover {
      color: #EDA41E !important;
    //  color: #d92231 !important;
    }
  }
  .ant-btn {
    background: #382E34 !important;
    border-color: #ffffff !important;
    color: #ffffff !important;

    &:hover {
      background: #382E34 !important;
      border-color: #EDA41E !important;
      color: #EDA41E !important;
    }
  }
}
.header-companyName {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: 20px;
  .ant-btn {
    background: white;
    border-color: #6e7882;
    color: #6e7882;

    &:hover {
      background: white;
      border-color: black;
      color: black;
    }
  }
}
 .header-new-icons-user {
   //width: 120px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   cursor: pointer;
   //height: 25px;
   font-family: Roboto;
   font-size: 30px;
   font-weight: 300;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: center;
   color: var(--navigationBodyFont);
  // color: #382e34;
   margin-right: 40px;
   span:hover {
     color: var(--navigationBodyFontHover);
    // color: #d92231;
   }
   a:hover {
     color: #00d3d8;
   }
   .icon-v12-nav-notifications:hover {
     color: var(--navigationBodyFontHover);
    // color: #d92231;
   }
   .content-nav-notifications {
     position: relative;
     .icon-v18-process-error {
       position: absolute;
       font-size: 12px;
       color: #EDA41E;
       right: 2px;
     }
     .icon-v23-warning-2 {
       position: absolute;
       font-size: 12px;
       color: #EDA41E;
       right: 2px;
     }
   }
   .active-nav-notifications {
     color: #EDA41E;
   }
   .nav-notifications-active {
     color: #EDA41E;
   }
   .icon-v12-nav-help:before {
     margin-left: 20px;
     margin-right: 20px;
   }
   .icon-v8-Navigation_help:before {
     margin-left: 10px;
     margin-right: 10px;
   }
   .icon-v8-Navigation_bell:before {
     margin-right: 0px;
     margin-left: 0px;
   }
 }
.header-user {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  //min-width: 18%;
  min-width: 150px;
  //margin-right: 15px;
  //padding-right: 20px;
  position: relative;
  color: #6e7882;
  padding-right: 20px;
  &::after {
    border-color: #6e7882 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    content: "";
    height: 0;
    position: absolute;
    right: 15px;
    width: 0;
    font-size: 11px;
    display: none;
  }
}

.header-userIcon {
  align-items: center;
  background-color: #FDFAFA;
  border: 1px solid #D4D4D4;
  border-radius: 50%;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-right: 10px;
  width: 35px;
  &__profile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 3px;
    border-width: 1px;
    border-style: solid;
    &:hover {
      border: 1px solid var(--navigationBodyFontHover);
     // border: 1px solid #d92231;
    }
  }
  .active-userIcon:hover {
    border: 1px solid #EDA41E;
  }
}

.header-userIcon-main-app {
  font-size: 30px;
  margin-right: 10px;
}

.header-userIconImage {
  height: 20px;
  width: 18px;
}

.header-userInfo {
  display: flex;
  //flex-direction: column;
  justify-content: flex-end;
  //min-width: 65px;
}

.header-userName {
  font-size: 16px;
  font-weight: 300;
  font-family: Roboto;
  color: var(--navigationBodyFont);
  //color: #000000;
  &:hover {
    color: var(--navigationBodyFontHover);
  //  color: #d92231;
  }
}

.header-menu {
  animation: slideDown .2s ease-in-out;
  background-color: #FFFFFF;
  display: block;
  position: absolute;
  right: 0;
  top: 45px;
  width: 160px;
  z-index: 3;
  border: 1px solid var(--navigationBodyFontHover);
}

.header-listItem {
  position: relative;
  background-color: var(--navigationBackground);

  &:hover::before {
    background-color: #FFFFFF;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.header-link {
  color: var(--navigationBodyFont);
  display: block;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  padding: 12px 20px;
  width: 100%;
  text-align: start;

  &:hover {
    background-color: var(--navigationBackgroundHover);
    color: var(--navigationBodyFontHover);
    text-decoration: none;
  }
}

.header-toggle {
  background: url(../../assets/menu-open.png) no-repeat center center #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  text-indent: -9999px;
  transition: all .2s;
  width: 41px;
}

.header-sidebarOpenedToggle {
  background: url(../../assets/menu-close.png) no-repeat center center #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
  height: 41px;
  text-indent: -9999px;
  transition: all .2s;
  width: 41px;
}

.header-multitenant {
  width: 45%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
}


.header-separator {
  width: 1px;
  height: 40px;
  border-left: solid 1px #ffffff;
}
.server-errors-notifications {
  width: 400px;
  height: 500px;
  position: fixed !important;
  .ant-popover-content {
    border: 2px solid #382e34;
  }
  .ant-popover-content > .ant-popover-arrow {
    //left: 62% !important;
  }
  .ant-popover-inner {
    border-radius: 0px !important;
    box-shadow: 0 0px 20px #15001020 !important;
  }
  .notifications-server-errors {
    padding: 20px 20px 12px 20px;
    font-family: Roboto;
    letter-spacing: 0.2px;
    font-size: 20px;
    .ant-popover-content {
      border: 1px solid #382e34;
    }
    .header-notifications-errors-update {
      border-bottom: none !important;
    }
    .header-notifications-errors {
      border-bottom: 1px solid #382e34;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
       .title-notification {
         line-height: 25px;
         color: #382e34;
         font-weight: 700;
         font-size: 16px;
       }
      .switcher-visible-notification {
        padding-left: 0;
        align-items: center;
        background-color: #ffffff;
        display: flex;
        font-size: 14px;
        position: relative;
        .switcher__slider {
          background-color: var(--generalButton);
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:after {
            content: 'On';
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 400;
            position: absolute;
            right: 34px;
            top: 1px;
            z-index: 0;
          }
        }
        .switcher__sliderChecked {
          background-color: #FFFFFF;
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:before {
            content: 'Off';
            color: #6E7882;
            position: absolute;
            z-index: 0;
            font-size: 16px;
            font-weight: 400;
            left: 32px;
            top: 1px;
          }
        }
        .switcher__sliderButton {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 46px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          z-index: 1;
          border: 1px solid #6e7882;
        }
        .switcher__sliderButtonChecked {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 4px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          border: 1px solid var(--generalButton);
        }
      }
    }
    .content-notifications-errors::-webkit-scrollbar {
      width: 7px;
    }
    .content-notifications-errors::-webkit-scrollbar-track { // set style to track
      background : transparent;
      border-radius: 10px;
      width: 20px;
    }
    .content-notifications-errors::-webkit-scrollbar-thumb { // sets style to thumb
      background : rgb(127,118,124);
      border-radius: 10px;
      margin-left: 10px;
    }
    .content-notifications-errors {
      display: flex;
      //border-bottom: 1px solid #382e34;
      flex-direction: column;
      align-items: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 300px;
      width: 370px;
      .list-notifications-errors {
         display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: space-between;
        .status-errors {
           //padding-right: 10px;
          .icon-v18-gear {
            font-size: 15px;
            vertical-align: middle;
          }
          .icon-v23-warning-1 {
            font-size: 15px;
            vertical-align: middle;
            color: #eda41e;
          }
          .icon-v20-avatar {
            font-size: 20px;
            vertical-align: middle;
            color: #382e34;
            font-weight: 400;
          }
          img {
            margin-left: 0.2em;
            margin-right: 0.2em;
          }
          .icon-v18-folder-empty {
            font-size: 15px;
            vertical-align: middle;
            color: #00d4d8;
          }
          .icon-v18-extension-pdf {
            font-size: 20px;
            vertical-align: middle;
          }
          .icon-v18-box-design {
            font-size: 15px;
            vertical-align: middle;
            color: #00d4d8;
          }
          .icon-v18-exclamation-mark {
            font-size: 20px;
            vertical-align: middle;
            color: #ff0000;
          }
          .status-without-icon {
            display: inline-block;
            margin-right: 27px;
          }
        }
        .content-info-errors {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          width: 297px;
          .action-event-name {
            font-size: 14px;
            font-weight: 700;
            color: #382e34;
            display:block;
            width: 297px;
            word-wrap: break-word;
          }
          .action-event-description {
            font-size: 14px;
            font-weight: 400;
            color: #382e34;
          }
          .events-times {
            font-size: 12px;
            font-weight: 700;
            color: #a6acb2;
          }
        }
        .content-mark-read {
          display: flex;
          align-items: center;
          margin-right: 10px;
          margin-left: 10px;
          .status-mark {
            border-radius: 50px;
            background-color: #EDA41E;
            border: 1px solid #EDA41E;
            width: 10px;
            height: 10px;
          }
          .status-mark-empty {
            border-radius: 50px;
            background-color: transparent;
            border: 1px solid transparent;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    .content-footer-mark {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      border-top: 1px solid #382e34;
      .clear-all-notification {
        cursor: pointer;
        letter-spacing: 0.2px;
        color: #382e34;
        font-size: 12px;
        font-weight: 400;
      }
      .clear-all-notification:hover {
        font-weight: 500;
      }
      .title-mark {
        cursor: pointer;
        letter-spacing: 0.2px;
        color: #382e34;
        font-size: 12px;
        font-weight: 400;
      }
      .title-mark:hover {
        font-weight: 500;
      }
    }
  }
}
.server-errors-notifications.center {
  .ant-popover-content > .ant-popover-arrow {
    left: 54% !important;
  }
}
.server-errors-notifications.left {
  .ant-popover-content > .ant-popover-arrow {
    left: 59% !important;
  }
}
.notification-first-upload {
  .ant-tooltip-arrow::before {
    background-color: #FFFFFF;
    width: 12px;
    height: 12px;
  }
  .ant-tooltip-inner {
    background-color: #FFFFFF;
    border: 1px solid #382e34;
    color: #00d3d8;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: 60px;
    padding: 20px;
    width: auto;
    min-width: auto;
  }
  .ant-tooltip-content {
    .content-notification {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      .content-folder-upload {
        display: flex;
        align-content: center;
        align-items: center;
        margin-right: 20px;
      }
      img {
        width: 20px;
        margin-right: 5px;
      }
      .name-not-upload {
        margin-right: 2px;
      }
      .name-folder-upload {
        color: #382e34;
        font-family: Roboto;
        font-weight: 300;
        font-size: 14px;
      }
      .content-nav-notifications {

      }
      .icon-v12-nav-notifications {
        color: #00d3d8 !important;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .header-app {
    background-color: #BBBABA;
    color: #FFFFFF;
    display: flex;
    height: 100px;
    margin-right: 30px;
    position: relative;
    width: 100px;
  }
  .header-rootLink {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .header-company {
    color: #6e7882;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .header-separator {
    margin-left: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .header-container {
    position: static;
  }
  .header-sidebarOpenedHeader {
    box-shadow: 0 2px 4px 0 #DCDCDC;
    position: static;
  }
  .header-logo {
    display: block;
  }
  .header-app, .header-page {
    display: none;
  }
  .header-companyName {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-right: 20px;
  }
  .header-user {
    border-right: none;
  }
  .header-toggle, .header-sidebarOpenedToggle {
    display: none;
  }
}

@media print {
  .header-container, .header-sidebarOpenedHeader {
    display: none;
  }
}

.ant-tooltip {
  max-width: 300px;
}

.subheader {
  height: 40px;
  background-color: var(--subNavigationBackground);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  padding: 0 60px;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index:4;
  display:flex;

  > div {
    padding: 12px 22px;
    color: var(--subNavigationBodyFont);
    font-size: 14px;
    min-width: 200px;
    cursor:pointer;

    &.active {
      background-color: var(--subNavigationBackgroundHover);
      color: var(--subNavigationBodyFontActive);
    }
    &:hover {
      color: var(--subNavigationBodyFontHover);
    }
  }
}

.subheader2 {
  height: 34px;
  width:100%;
  background-color: #fafafa;
  border-bottom: 1px solid #a6acb2;
  padding: 7px 42px;
  position: fixed;
  top:100px;
  z-index: 2;
  display:flex;

  > div {
    padding: 3px 40px;
    font-size: 14px;
    min-width: 180px;
    font-weight: 400;
    border-right: 1px solid #a6acb2;
    cursor:pointer;

    &:last-child {
      border-right: none;
    }
    &.active {
      color: var(--generalIconFontActive);
    }
  }
}