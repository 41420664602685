.connector-connectorList__wrapper {
  background-color: #FCFCFD;
  display: flex;
  flex-wrap: wrap;
}

.connector-connectorList__wrapper .connectorItem-wrapper {
  flex-basis: 100%;
}

@media screen and (min-width: 768px) {
  .connector-connectorList__wrapper .connectorItem-wrapper {
    flex-basis: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .connector-connectorList__wrapper .connectorItem-wrapper {
    flex-basis: 25%;
  }
}