.workflow-template-page-new {
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  //width: 100%;
  background: #FFFFFF;
  margin: 0px 0px 66px 0px !important;
  padding: 30px 100px 100px 30px !important;
  &__content {
    .content-workflow-template-page {
      .content-buttons-actions {
        .icon-v17-copy-active {
          color: var(--generalButton) !important;
        }
        .icon-v17-delete-active{
          color: var(--generalButton) !important;
        }
        .icon-v17-edit-active {
          color: var(--generalButton) !important;
        }
        .icon-v17-add-active {
          color: var(--generalButton) !important;
        }
      }
    }
  }
}

.forms-workflow-admin {
  background-color: #FFFFFF;
  display: flex;
  //width: 100%;
  //padding: 160px 40px 40px 40px;
  padding: 30px 20px 20px 20px;
  position: relative;
  //margin: 134px 82px;
  &__page {
    width: 100%;
    height: 100%;
    .header-forms-workflow {
      //display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      //flex-direction: column;
      .name-forms-workflow {
        font-family: 'Roboto';
        font-weight: 700;
        line-height: 20px;
        font-size: 20px !important;
        margin-left: 10px;
      }
      .subheader-forms-workflow {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .icon-v17-add {
          color: var(--generalButton);
          font-size:20px;
        }
        .icon-v17-add-active {
          color: var(--generalIconFontHover);
          font-size:20px;
          cursor:pointer;
        }
      }
      .select-form {
        width: 20%;
        margin-bottom: 5px;
        color: #6e7882;
        font-weight: 400;
        font-size: 14px;
        .ant-select-selection {
          border-radius:0px;
          &:hover {
            border-color:#eda41e;
          }
          &:active {
            border-color:#eda41e;
            box-shadow: none;
          }
          &:focus {
            border-color:#eda41e;
            box-shadow: none;
          }
        }
      }
    }
    .header-form {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: #EDA41E;
      margin-top: 30px;
      .input-header-form {
        width: 90%;
      }
      .action-header-forms {
        //margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        .button-cancel-template {
          background-color: #FFFFFF;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #6e7882;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          height: 30px;
          margin-right: 20px;

          &:hover {
            background-color: #FFFFFF;
            border: 1px solid #FFC457;
            color: #FFC457;
          }

          &:active {
            background-color: #FFFFFF;
            border: 1px solid #EDA41E;
            color: #EDA41E;
          }
        }
        .button-save-template {
          background-color: #150010;
          border: 1px solid #150010;
          border-radius: 20px;
          box-shadow: none;
          color: #FFFFFF;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          //margin-right: 10px;
          width: 180px;
          height: 30px;
          &:hover {
            //background-color: #FFC457;
            //border: 1px solid #FFC457;
            //color: #FFFFFF;
            background-color: #150010;
            color: #EDA41E;
            border: 1px solid #150010;
          }
          &:active {
            //background-color: #EDA41E;
            //border: 1px solid #EDA41E;
            color: #FFFFFF;
          }
          &:disabled {
            //opacity: 0.9;
            cursor: default;
            background: #D4D7DA !important;
            color:#FFFFFF;
          }
        }
        //.button-cancel-template {
        //  background-color: #FFFFFF;
        //  border: 1px solid #6e7882;
        //  border-radius: 20px;
        //  box-shadow: none;
        //  color: #6e7882;
        //  cursor: pointer;
        //  font-family: Roboto;
        //  font-weight: 400;
        //  font-size: 16px;
        //  padding: 3px !important;
        //  text-transform: none;
        //  transition: none;
        //  line-height: 18.75px;
        //  width: 15%;
        //  height: 26px;
        //  &:hover {
        //    background-color: #FFFFFF;
        //    border: 1px solid #FFC457;
        //    color: #FFC457;
        //  }
        //  &:active {
        //    background-color: #FFFFFF;
        //    border: 1px solid #EDA41E;
        //    color: #EDA41E;
        //  }
        //}
      }
      .input-form-name {
        width: 50%;
        border: 1px solid #6e7882;
        color: #6e7882;
        background-color: #FFFFFF;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        height: 35px;
        border-radius: 0px;
        margin-left: 10px;
        &:hover {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:focus {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:active {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
      }
      .icon-v19-delete {
        font-size: 16px;
        color: #382e34;
      }
      .icon-v19-delete-disabled {
        font-size: 16px;
        color: #D4D7DA;
      }
      .icon-v19-delete-active {
        font-size: 16px;
        color: #382e34;
      }
    }
    .page-form-content {
      display: flex;
      margin-top: 10px;
      .form-elements-content {
        width: 20%;
        border: 1px solid #000000;
        padding: 10px;
        .form-elements {
          font-size: 14px;
          font-weight: 700;
          color: #382e34;
          margin-bottom: 5px;
          padding-bottom: 5px;
        }
        .ant-list-bordered {
          border: none;
          border-radius: 0px;
          background-color: #D4D7DA;
        }
        .ant-list-split .ant-list-header {
          border-bottom: 1px solid #382e34;
        }
        .ant-list-bordered .ant-list-header {
          padding: 2px 0px;
          font-size: 14px;
          font-family: Roboto;
          color: #382e34;
          font-weight: 500;
          background: #FFF;
        }
        .ant-list-bordered .ant-list-item {
          padding: 2px 0px 2px 0px;
          font-size: 14px;
          font-family: Roboto;
          color: #6e7882;
          font-weight: 400;
          border-bottom: 1px solid #382e34;
          cursor: default;
        }
        .ant-list-bordered .ant-list-item:hover {
          color: #EDA41E;
        }
      }
      #mydiv {
        position: absolute;
        z-index: 9;
        background-color: #f1f1f1;
        border: 1px solid #d3d3d3;
        text-align: center;
      }

      #mydivheader {
        padding: 10px;
        cursor: move;
        z-index: 10;
        background-color: #2196F3;
        color: #fff;
      }
      .form-builder-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        .component-single {
          width: 100%;
          display: flex;
        }
      }
    }
  }
}
.select-form-dropdown {
  z-index:1 !important;
  border: 1px solid #eda41e!important;
}