.multitenant {
  margin-left: 20px;
  position: relative;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 20px;
  .ant-card-head-title {
    text-align: center;
  }

  .multitenant__select {
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    font-family: Roboto;
    position: relative;
    .ant-select-selection {
      border: none;
      /*width: 80%;*/
      border-radius: 0;
      box-shadow: none;
   /*   background-color: #FFFFFF;
      color: #382E34;*/
      background-color: var(--navigationBackground);
      color: var(--navigationBodyFont);
      font-family: Roboto;
      input {
        padding: 0;
        text-align: right;
      }
      .ant-select-selection-selected-value {
        float:right;
      }
    }
    &::after {
      border-color: #595959 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      bottom: 50%;
      content: "";
      height: 0;
      position: absolute;
      right: 5px;
      width: 0;
      cursor: pointer;
      display: none;
    }
  }

  .multitenant__select.ant-select-open {
    outline: none;
    .ant-select-selection {
      box-shadow: none;
      border: 1px solid var(--navigationBodyFontHover);
     // border: 1px solid #d92231;
      border-bottom: none;
    }
  }
}

.multitenant-option {
  text-align:right;
  color: var(--navigationBodyFont) !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  padding: 11px 12px !important;

  &:hover {
    background-color: var(--navigationBackgroundHover) !important;
    color: var(--navigationBodyFontHover) !important;
  }
}
.multitenant-option.ant-select-dropdown-menu-item-disabled:hover {
  color: #D4D7DA !important
}
.multitenant-dropdown {
  background-color: var(--navigationBackground) !important;
  border: 1px solid var(--navigationBodyFontHover) !important;
  border-top: none !important;
  //top: 46px !important;
  top: 32px !important;
  width: 100% !important;

  &-menu {
    padding: 0 !important;
  }
}
.multitenant-selected-value-wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: 307px;
}
.multitenant-selected-value {
  color: var(--navigationBodyFont) !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  padding: 11px 12px !important;
  cursor: default;
}