.source-mapping-page{
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  //width: 100%;
  background: #FFFFFF;
  //margin-top: 100px;
  //padding:30px 100px 0 100px;
  margin: 100px 82px;
  padding: 30px 0px 0;
  &__content {
    .source-mapping-header {
      display: flex;
      flex-direction: row;
      color: #382e34;
      align-items: center;

      .title-header {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
      }
    }
    .content-source-mapping-page {
      .source-mapping-table {
        .topbar {
          .btn-container{
            display: flex;
            flex-direction: row-reverse;
            margin-top: 40px;
            margin-bottom: 30px;
            font-size: 20px;
            button {
              cursor: pointer;
              width: 20px;
              height: 20px;
            }
            .icon-v17-delete, .icon-v17-add {
              &[disabled]{
                color:#D4D7DA;
              }
            }
            .icon-v17-delete {
              color:#382E34;
            }
            .icon-v17-delete-active{
              color: var(--generalIconFontHover);
            }
            .icon-v17-add {
              color: var(--generalButton);
              margin-left: 25px;
              margin-right: 5px;
            }
            .icon-v17-add-active {
              color: var(--generalIconFontHover);
              margin-left: 25px;
              margin-right: 5px;
            }
          }
        }
        &__content {
          .table-source-mapping-content {
            //.ant-table-thead > tr>th> .anticon-filter {
            //  right: 50px;
            //}
            .text-mapping {
              display: flex;
              flex-direction: column;
              span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 200px;
              }
            }
            .text-mapping-update {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: inline-block;
              width: 200px;
            }
            .ant-table-wrapper {
              .ant-table-content {
                .ant-table-body {
                  .ant-table-header-column {
                    verical-align:middle;
                    .ant-table-column-title {
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 700;
                      font-size: 14px;
                      line-height: 20px;
                      width:20vw;
                      span {
                        margin-right: 0;
                      }
                    }
                  }
                  .icon-v21-edit {
                    font-size: 20px;
                  }
                  .icon-v21-edit-active {
                    font-size: 20px;
                    color: var(--generalIconFontHover);
                    &:hover {
                      cursor:pointer;
                    }
                  }
                }
                .ant-table-tbody {
                  .ant-table-row td {
                    font-family: Roboto;
                    font-style:normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
              .ant-pagination {
                .ant-pagination-item:focus, .ant-pagination-item:hover {
                  border-color: #eda41e;
                  a {
                    color:#eda41e !important;
                  }
                }
                a:not([href]):not([tabindex]) {
                  color: rgba(0, 0, 0, 0.65);
                }
                .ant-pagination-next .ant-pagination-item-link {
                  border:none;
                }
                .ant-pagination-prev .ant-pagination-item-link {
                  border:none;
                }
                .ant-pagination-item-active {
                  border-color: #eda41e;
                  a {
                    color:#eda41e !important;
                  }
                }
                .ant-pagination-item-active:focus {
                  border-color: #eda41e;
                }
              }
            }
            .ant-checkbox-wrapper {
              .ant-checkbox-inner {
                border-radius: 0!important;
                border:1px solid #6e7882;
                &:after {
                  border-color:#eda41e;
                  background-color: transparent;
                }
              }
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: var(--generalIconFontActive) !important;
                &:after {
                  position: absolute !important;
                  display: table;
                  color:#FFFFFF;
                  border:none !important;
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: "\e8ce" !important;
                  top: 8px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                  font-family: "smartbox_icons_v19" !important;
                }
              }
            }

          }
        }
      }
    }
  }
}
.source-mapping-modal {
  width: 790px !important;
  height:720px !important;
  .header-content-mapping {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 10px;
    background-color: var(--subNavigationBackground);
  }
  .ant-modal-content {
    .ant-modal-header {
      height: 40px;
      padding: 0px;
      background-color: #665360;
      border-radius: 0;
      border:none;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color:#FFFFFF;
        line-height: 30px;
        width: 100%;
      }
    }
    .ant-modal-body {
      padding:30px;
      .source-mapping-modal-text-field {
        .content-inputs-data-hold {
          display: flex;
          .icon-v16-minus-active:hover {
            cursor:pointer;
          }
        }
        .title-text-field {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
        }
        .title-text-field.with-subtitle {
          margin-bottom: 20px;
        }
        .input-what-data-hold {
          margin:10px 0 10px 0;
          &:hover {
            border-color:#eda41e;
          }
          &:focus {
            border-color:#eda41e;
            box-shadow: none;
          }
        }
        .text-field {
          margin:10px 0 20px 0;
          &:hover {
            border-color:#eda41e;
          }
          &:focus {
            border-color:#eda41e;
            box-shadow: none;
          }
        }
        .text-field-update {
          margin:10px 0 10px 0;
        }
        .subtitle-text-field {
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .ant-select {
          width: 100%;
          margin:10px 0 10px 0;
          .ant-select-selection:hover {
            border-color: #eda41e;
          }
          .ant-select-selection:focus {
            border-color: #eda41e;
          }
        }
      }
      .wrapper-btn-add {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom:20px;
        button {
          font-size: 20px;
          color: var(--generalButton);
        }
        .icon-v17-add-active {
          color: var(--generalIconFontHover);
        }
        .icon-v17-add-active:hover {
          cursor:pointer;
        }
      }
      .wrapper-btn-add-update {
        margin-bottom:10px;
      }
      .footer-source-mapping-modal {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .action-cancel {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: #FFFFFF;
          color: #6e7882;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color: #FFFFFF;
            border-color: var(--generalButtonHover);
            color: var(--generalButtonHover);
          }

          &:focus {
            border: 1px solid #6e7882;
            background-color: #FFFFFF;
            color: #6e7882;
            -webkit-box-shadow: none;
            box-shadow:none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: #FFFFFF;
            color: var(--generalButtonActive);
          }
        }
        .action-cancel-loading {
          width: 180px;
          height: 25px;
          border: 1px solid #FFC457;
          background-color: #FFFFFF;
          color: #FFC457;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-family: 'Roboto';
          font-style: normal;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;
        }
        .action-add {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: var(--generalButton);
          color: var(--generalButtonFont);
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color: var(--generalButtonHover);
            border-color: var(--generalButtonHover);
            //color: #FFFFFF;
          }

          &:focus {
            border: 1px solid #6e7882;
            background-color: #EDA41E;
            color: #FFFFFF;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
            //color: #FFFFFF;
          }
        }
        .action-add:disabled {
          cursor: default;
          background: #D4D7DA;
          color:#ffffff;
          &:hover {
            background: #D4D7DA;
            border-color: #D4D7DA;
            color: #FFFFFF;
          }

          &:focus {
            border: 1px solid #00D3D8;
            background: #D4D7DA;
            color: #FFFFFF;
          }
        }
        .action-add-loading {
          width: 180px;
          height: 25px;
          background-color: #FFC457;
          color: #FFFFFF;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-family: "Roboto";
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;
          border: 1px solid #EDA41E;
        }
        .anticon-loading {
          margin-right: 15px;
        }
      }
    }
  }
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 50vh !important;
}
.ant-dropdown.ant-dropdown-placement-bottomRight {
  //position: absolute !important;
  //z-index: 1 !important;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  overflow: auto;
  //height: 30em;
}
.ant-dropdown-menu-item {
  color: #6e7882;
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #3DD8DB;
  }
}
.ant-dropdown-menu-item-selected {
  color: #6e7882 !important;
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #3DD8DB;
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
  border-color: #3DD8DB;
}
.ant-table-filter-dropdown {
  .ant-checkbox {
    .ant-checkbox-input {
      visibility: visible;
      color: #6e7882 !important;
    }
  }
}