.semiCircle-circle {
  height: 45px;
  overflow: hidden;
  position: relative;
  width: 100px;
}

.semiCircle-lowSpan {
  color: #F44336;
  display: block;
  font-size: 24px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 22px;
  width: 90px;
}

.semiCircle-lowActive {
  background-color: #FFFFFF;
  border-left: 10px solid #F44336;
  border-top: 10px solid #F44336;
  border-right: 10px solid #F44336;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  height: 45px;
  position: absolute;
  transform-origin: 50% 100%;
  width: 90px;
}

.semiCircle-mediumSpan {
  color: #D5AF00;
  display: block;
  font-size: 24px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 22px;
  width: 90px;
}

.semiCircle-mediumActive {
  background-color: #FFFFFF;
  border-left: 10px solid #D5AF00;
  border-top: 10px solid #D5AF00;
  border-right: 10px solid #D5AF00;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  height: 45px;
  position: absolute;
  transform-origin: 50% 100%;
  width: 90px;
}

.semiCircle-highSpan {
  color: #1DCBCF;
  display: block;
  font-size: 24px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 22px;
  width: 90px;
}

.semiCircle-highActive {
  background-color: #FFFFFF;
  border-left: 10px solid #1DCBCF;
  border-top: 10px solid #1DCBCF;
  border-right: 10px solid #1DCBCF;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  height: 45px;
  position: absolute;
  transform-origin: 50% 100%;
  width: 90px;
}

.semiCircle-border {
  background-color: #FFFFFF;
  border-left: 4px solid grey;
  border-top: 4px solid grey;
  border-right: 4px solid grey;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  height: 45px;
  left: 6px;
  position: absolute;
  width: 80px;
}