.filterAlert__wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.filterAlert__wrapper label {
  color: #6E7882;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.filterAlertItem-labelCentered {
  text-align: left;
}

.filterAlertItem-button {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.filterAlertItem-buttonWrapper {
  align-items: center;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  display: flex;
  height: 41px;
  justify-content: center;
  width: 41px;
}

.filterAlertItem-inputFind {
  background: url(../../../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  padding-left: 35px;
  width: 100%;
}

.filterAlertItem-inputFind:focus {
  outline: 2px solid #3DD8DB;
  width: 100%;
}

.filterAlertItem-select {
  align-items: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.filterAlertItem-select:last-of-type {
  margin-bottom: 0;
}

.filterAlertItem-select select {
  appearance: none;
  background: url(../../../assets/down-caret.png) no-repeat 97% 50% #FFFFFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  font-size: 16px;
  height: 42px;
  padding: 10px 25px 10px 10px;
  outline: none;
  width: 100%;
}

.filterAlertItem-title {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .filterAlert__wrapper {
    flex-direction: row;
  }

  .filterAlertItem-labelCentered {
    text-align: center;
  }

  .filterAlertItem-button {
    margin: 0 10px 0 0;
  }

  .filterAlertItem-inputFind {
    width: 0;
  }

  .filterAlertItem-select {
    margin: 0 10px 0 0;
    max-width: 200px;
  }

  .filterAlertItem-select:last-of-type {
    margin-right: 0;
  }
}