html, 
body,
#root {
  height: 100vh;
}

html {
  height: 100%;
}

body {
  background-color: #FFFFFF;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
  min-height: 100%;
	color: #382e34;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	background-color: transparent;
	border: none;
	outline: 0;
	padding: 0;
}

button:disabled {
	background-color: #FFFFFF;
	border: 1px solid #CCCCCC;
  cursor: not-allowed;
  color: #CCCCCC;
}

button:disabled:hover {
	background-color: #FFFFFF;
  color: #CCCCCC;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=submit] {
	background-color: #3DD8DB;
  color: #FFFFFF;
  border: 1px solid #3DD8DB;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight:700;
  font-size: 14px;
	padding: 10px 30px;
	text-transform: uppercase;
  transition: all .2s;
}
.input-button {
	background-color: #ffffff;
  color: #3DD8DB;
  border: 1px solid #3DD8DB;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight:700;
  font-size: 14px;
	padding: 10px 30px;
	text-transform: uppercase;
  transition: all .2s;
}
.mr20 {
  margin-right:20px;
}
.mr30 {
  margin-right:30px !important;
}
.clearfix {
  clear:both;
}

input[type=submit]:hover:enabled {
	background-color: #FFFFFF;
  color: #3DD8DB;
}

input[type=submit]:disabled, input[type=text]:disabled, select:disabled {
	border: 1px solid #CCC;
	background-color: #eee;
  cursor: not-allowed;
  color: #CCC;
}

input[type=submit]:focus {
  outline: 0;
}

/* FORM */

input {
  background-color: #FCFAFA;
}

input[type=text]:disabled, input[type=password]:disabled {
  cursor: not-allowed;
}

input[type=checkbox], input[type=radio] {
	visibility: hidden;
}

input[type=checkbox]:disabled, input[type=radio]:disabled {
	cursor: not-allowed;
}

select {
  background: url(./assets/down-caret.png) no-repeat 97% 50%,linear-gradient(0deg, rgba(215, 215, 215, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%);
}

textarea {
	background-color: #FCFAFA;
	border: 1px solid #E0E8E8;
	border-radius: 5px;
	box-sizing: border-box;
	color: #555555;
	font-size: 18px;
	font-weight: 300;
  padding: 10px;
	resize: none;
}

/* Sweet Alert */
.swal2-title {
	font-size: 22px !important;
	line-height: 1.2 !important;
}

.swal2-cancel {
	background-color: #FFFFFF !important;
	border-radius: 20px !important;
	border: 1px solid #D33D33 !important;
	color: #D33D33 !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	padding: 8px 30px !important;
	text-transform: uppercase;
  box-shadow: 0 2px 4px 0 #DCDCDC !important;
	font-family: 'Ubuntu', sans-serif;
}

.swal2-cancel:hover {
	background-color: #D33D33 !important;
	color: #FFFFFF !important;
	background-image: none !important;
}

.swal2-confirm {
	background-color: #FFFFFF !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	padding: 8px 30px !important;
	text-transform: uppercase;
  border-radius: 20px !important;
  border: 1px solid #3DD8DB !important;
  box-shadow: 0 2px 4px 0 #DCDCDC !important;
  color: #3DD8DB !important;
	font-family: 'Ubuntu', sans-serif;
}

.swal2-confirm:hover {
	background-color: #3DD8DB !important;
	color: #FFFFFF !important;
	background-image: none !important;
}

/* Placeholder animation */
@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

.placeholder {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: darkgray;
	background: linear-gradient(to right, #EEEEEE 10%, #DDDDDD 18%, #EEEEEE 33%);
	background-size: 800px 104px;
	position: relative;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote::after,
q:before, q::after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}