.processTable__row td {
  vertical-align: middle;
}

.processTable__actions {
  color: #1DCBCF !important;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  position: relative;
}

.processTable__loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.processTable__loading .loading-container {
  height: 14px;
  width: 14px;
}

.processTable__menu {
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 #DCDCDC;
  border-radius: 5px;
  left: -45px;
  position: absolute;
  text-align: left;
  top: 25px;
  z-index: 1;
}

.processTable__menu-item a {
  border-bottom: 1px solid #EAEAEA;
  color: #111C26;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  padding: 10px;
  width: 120px;
}

.processTable__menu-item a:hover {
  background-color: #1DCBCF;
  color: #FFFFFF;
}

.processTable__stop div{
  padding: 4px 15px 4px 7px;
  border-radius: 15px;
  background-color: #DCDCDC;
  border: 1px solid #737E89;
  width:27px;
  font-weight: bold;
  text-align: center;
  cursor:pointer;
}

.processTable__stop{
  text-align: center;
}

.processTable__td {
  text-align: center;
}
.display-speed {
  font-size: 13px !important;
}