//:root {
//    --navigationBackground: #382E34;
//    --navigationBackgroundHover: #150010;
//    --navigationBodyFont: #ffffff;
//    --navigationBodyFontHover: #EDA41E;
//    --subNavigationBackground: #665360;
//    --subNavigationBackgroundHover: #4d3e47;
//    --subNavigationBodyFont: #ffffff;
//    --subNavigationBodyFontHover: #EDA41E;
//    --subNavigationBodyFontActive: #EDA41E;
//    --generalIconFontHover: #EDA41E;
//    --generalIconFontActive: #EDA41E;
//    --generalButton: #EDA41E;
//    --generalButtonHover: #FFC457;
//    --generalButtonActive: #C4812F;
//    --generalButtonFont: #ffffff;
//}

.navTitleDropdown{
    cursor: pointer;
    //color: #FFFFFF;
    color: var(--navigationBodyFont);
   // color: #382e34;
    width: 217px;
    height: 40px;
    display:flex;
    padding: 12px;
    flex-direction: row;
    justify-content: space-between;
    border: solid 1px transparent;
    //border-radius: 3px 3px 0 0;
    transition: 0.4s;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    user-select: none;
    border: 1px solid transparent;
    &:hover {
    color: var(--navigationBodyFontHover);
   // color: #d92231 !important;
    border: 1px solid var(--navigationBodyFontHover);
   // border: 1px solid #d92231 ;
      background-color: var(--navigationBackgroundHover);
      //background-color:#d9223130;
    }
    .icon-hover:hover {
        color: #EDA41E;
        border: 1px solid #EDA41E ;
        background-color:#150010;
    }
}
.navTitleDropdown .icon-v14-arrow-bold {
    //color:white;
    float:right;
}

.panel-new-nav {
    width: auto;
    height: auto;
    background-color: var(--navigationBackground);
   // background-color: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: var(--navigationBodyFontHover);
    //border:1px solid #d92231;
    //transition: all 0.5s ease-in-out;
    //border-top :unset;
    box-shadow:  5px 10px 5px rgba(21,0,16,0.3);
    position: absolute;
    top:49px;
    left:70px;
    user-select: none;
    opacity: 0;
    animation: showMe 400ms forwards;
    cursor: default;
    z-index: 100;
}

@keyframes showMe{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}


.mainBoxNewNav {
    padding: 30px;
    width: 100%;
    height: 100%;
}

.sectionsNewNav{
    display: flex;
    flex-direction: row;
}

.new-nav-title-wrapper {
    white-space: nowrap;
    .icon-v211-share-1 {
        font-size: 20px;
    }
    .icon-v211-share-1:before {
        font-size: 20px;
        color: var(--navigationBodyFontHover);
    }
}

.new-nav-title{
    margin-left: 10px;
}

.list-item-new-nav, .section-items-new-nav{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    line-height: 30px;
    font-style: normal;
    font-family: Roboto, serif;
}

.section-items-new-nav{
    color: var(--navigationBodyFontHover);
   // color: #d92231;
    font-weight: 700;
    width: 140px;
    margin-right: 10px;
}

.section-items-new-nav:last-child{
    margin-right: 0;
}
.parent-title-header {
    font-size: 20px;
    color: #EDA41E;
}
.parent-title-request-tracker {
    font-size: 20px;
    color: #EDA41E;
}
.parent-title-assessments {
    font-size: 20px;
    color: #EDA41E;
}
.icon-v211-request-tracker, .icon-v211-users-1, .icon-v211-gear, .icon-v211-workflow {
    font-size: 20px;
    color: #EDA41E;
    // color: #d92231;
}
.icon-v211-request-tracker:before, .icon-v211-users-1:before, .icon-v211-gear:before, .icon-v211-workflow::before {
    font-size: 20px;
    color: var(--navigationBodyFontHover);
   // color: #d92231;
}
.list-item-new-nav{
    width: 145px;
    white-space: nowrap;
    color: var(--navigationBodyFont);
   // color: #000000;
    font-weight: 400;
    margin-left: 28.5px;
    padding-left: 10px;
    cursor: pointer;
}

.list-item-new-nav:hover {
    font-family: 'Roboto', serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:30px;
    color:var(--navigationBodyFontHover);
   // color:#d92231;
    background-color: var(--navigationBackgroundHover);
   // background-color: #d9223130;
}

.list-item-new-nav.active{
    font-family: 'Roboto', serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:30px;
    color:#FFC457;
}
.list-item-new-nav[disabled] {
    font-family: 'Roboto', serif;
    font-style:normal;
    font-weight:400;
    font-size:14px;
    line-height:30px;
    color:#6e7882;
    pointer-events: none;
    background-color: var(--navigationBackground);
}

.sectionsNewNav div {
    width: 180px;
}

