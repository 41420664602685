.redacting-container {
  padding-right: 0 !important;
  padding-left: 0 !important;
  color: #382e34;
}

.content-name-saved {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  background-color: var(--subNavigationBackground);
  border-bottom: 1px solid #6e7882;
  height: 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index:500;
  .content-document-name {
    padding-bottom: 2px;
    padding-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-v17-back-1 {
      font-size: 16px;
      margin-left: 40px;
      //margin-right: 20px;
      //margin-bottom: 2px;
    }
    .icon-v17-forth-1 {
      font-size: 16px;
      margin-right: 60px;
      line-height: 20px;
      //margin-left: 20px;
      //margin-bottom: 2px;
    }
    .disabled-v17-forth {
      cursor: not-allowed;
      opacity: 0.5;
      color: #DAD7DA;
    }
    .active-v17-forth {
      cursor: pointer;
      color: #FFFFFF;
    }
    .disabled-back-1 {
      cursor: not-allowed;
      opacity: 0.2;
      color: #dad7da;
    }
    .active-back-1 {
      cursor: pointer;
      color: #FFFFFF;
    }
    .document-name {
      font-size: 14px;
      width: fit-content;
      //margin-bottom: 4px;
      //margin-top: -4px;
      //text-overflow: ellipsis;
      //overflow: hidden;
      white-space: nowrap;
      color: #FFFFFF;
      font-weight: 400;
      opacity: 100;
      line-height: 20px;
    }

    .document-saved {
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 400;
      opacity: 0.5;
      font-style: oblique;
    }
  }
}
.ant-select-dropdown {
  .item-options-status {
    color: #000000;
    font-weight: 500;
    opacity: 0.8;
    padding: 5px 6px;
    .icon-status {
      padding-right: 3px;
    }
    .anticon {
      margin-right: 5px;
      font-weight: bold;
    }
  }
}
 .redacting-opacity {
   opacity: 0.3;
   //position: fixed;
   .redacting-block {
     //overflow: hidden !important;
   }
 }
.redacting {
  width: 100%;
  min-height: 100%;
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: 3% 18% 2% 64% 2% 0%;
  grid-template-rows: 11% 89%;
.content-spinning-document{
  font-size: 20px;
  width: 100px;
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 50px;
  .ant-spin-spinning {
    margin-left: 350px;
    margin-top: 300px;
  }
  .ant-spin-text {
    font-size: 18px;
    font-weight: 700;
  }
}
  .update-annotation-sidebar {
    position: absolute !important;
    .ant-drawer-content-wrapper {
      //height: 38% !important;
      //right: 300px;
      left: 340px;
      width: 676px !important;
      height: 150px !important;
      box-shadow: none !important;
      top: 0px;
    }
    .ant-drawer-wrapper-body {
      background-color: #f7fbff;
      border: 1px solid #382e34;
      height: 75px;
      overflow: hidden;
    }
    .ant-drawer-content {
      background-color: #f7fbff;
      height: 75px !important;
    }
    .ant-drawer-body {
      padding: 5px 5px 10px 10px;
      background-color: #f7fbff;
      line-height: 15px;
    }
    .content-annotation-sidebar {
      .header-annotation-sidebar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .custom-status-annotate {
          .ant-select-selection {
            border: 1px solid transparent;
            background-color: #f7fbff;
            height: 15px;
            margin-top: 5px;
          }
        .ant-select-selection .ant-select-selection__rendered {
            margin-left: 0px;
          }
          .ant-select-selection__rendered {
            line-height: 15px;
          }
          .ant-select-selection:focus {
            .ant-select-selection-selected-value {
              color: #00d4d8;
            }
            .ant-select-arrow {
              color: #00d4d8;
            }
          }
          .ant-select-selection:active {
            box-shadow: none;
            .ant-select-selection-selected-value {
              color: #00d4d8;
            }
            .ant-select-arrow {
              color: #00d4d8;
            }
          }
          .ant-select-selection:hover {
            color: #00d4d8;
            .ant-select-selection-selected-value {
              color: #00d4d8;
            }
            .ant-select-arrow {
              color: #00d4d8;
            }
          }
          .ant-select-arrow {
            margin-right: -10px;
            color: #382e34;
          }
          .ant-select-selection-selected-value {
            font-size: 12px;
            font-weight: 400;
            font-family: Roboto;
            letter-spacing: 0.2px;
            color: #382e34;
          }
        }
        .icon-v17-close-black {
          font-size: 15px;
          color: #382e34;
          margin-top: -7px;
        }
        .icon-v17-close-black:before {
          margin-right: 0px;
          margin-left: 0px;
        }
      }
      .body-annotation-sidebar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        .ant-input-affix-wrapper .ant-input {
          min-height: 30px;
          height: 30px;
        }
        .annotation-sidebar-search {
          height: 30px;
          width: 346px;
          .ant-input-affix-wrapper .ant-input {
            //min-height: 30px;
            height: 30px;
          }
          .ant-input:focus {
            box-shadow: none;
            border: 1px solid #00d4d8;
          }
          .ant-input:hover {
            box-shadow: none;
            border: 1px solid #00d4d8;
          }
          .ant-input:active {
            box-shadow: none;
            border: 1px solid #00d4d8;
          }
          .ant-input {
            font-size: 14px;
            font-weight: 400;
            font-family: Roboto;
            letter-spacing: 0.2px;
            color: #382e34;
            border: 1px solid #382e34;
            border-radius: 0px;
          }
          .ant-input-search-icon {
            color: #382e34;
            font-size: 20px;
            display: none;
          }
        }
        .spacing-select {
          font-size: 14px;
          font-weight: 400;
          font-family: Roboto;
          letter-spacing: 0.2px;
          color: #382e34;
        }
        .select-custom-list {
           width: 150px !important;
          height: 30px;
          .ant-select-selection-selected-value {
             margin-left: 5px;
            //margin-top: 7px;
          }
          .ant-select-arrow {
            color: #382e34;
            margin-right: 0px;
          }
          .ant-select-selection:focus {
            border: 1px solid #00d4d8;
          }
          .ant-select-selection:active {
            border: 1px solid #00d4d8;
          }
          .ant-select-selection:hover {
            border: 1px solid #00d4d8;
          }
          .ant-select-selection--single {
            height: 30px;
          }
          .ant-select-selection--single .ant-select-selection__rendered {
            line-height: 28px;
          }
          .ant-select-selection {
            font-size: 14px;
            font-weight: 400;
            font-family: Roboto;
            letter-spacing: 0.2px;
            color: #382e34;
            border: 1px solid #382e34;
            box-shadow: none;
            border-radius: 0px;
          }
        }
        .button {
          background-color: #382e34;
          border: 1px solid #382e34;
          border-radius: 40px;
          box-shadow: none;
          color: #FFFFFF;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 500;
          font-size: 16px;
          padding: 1px 3px;
          letter-spacing: 0.5px;
          text-transform: inherit;
          transition: all .2s;
          width: 100px;
          height: 25px;
          margin-right: 5px;
        }
        .button:hover {
          color: #EDA41E;
          background-color: #150010;
          border: 1px solid #150010;
        }
        .button:focus {
          color: #665360;
          background-color: #150010;
          border: 1px solid #382e34;
        }
        .button:active {
          color: #665360;
          background-color: #150010;
          border: 1px solid #382e34;
        }
        .button:disabled {
          color: #FFFFFF;
          background-color: #382e34;
          border: 1px solid #382e34;
          opacity: 0.3;
          cursor: not-allowed;
          margin-left: 20px;
        }
      }
    }
  }
  &__page-portrait {
    background-color: #efeff0;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    overflow: auto;
    z-index: 4;
    margin-top: 30px;
    .ant-drawer > * {
      transition: none;
    }
  }

  &__page-landscape {
    max-width: unset;
    background-color: #efeff0;
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 8;
    overflow: auto;
    margin-top: 30px;
    z-index: 4;
    width: 91.4%;
  }

  .canvas-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }

  .react-pdf__Document {
    overflow: hidden;
    .list-container {
      position: relative !important;
      //overflow: hidden !important;
      will-change: transform !important;
      direction: ltr !important;
    }
    .ReactVirtualized__Grid {
      overflow: hidden !important;
    }
  }

  .download {
    font-size: 20px;
    font-weight: bold;
  }

  .navigation-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 39px;
    color: rgb(110, 119, 129);
    border-bottom: 1px solid #efeff0;
    position: relative;
    grid-row-start: 2;
    grid-row-end: 4;
    background-color: white;
    .anticon-metadata {
      margin-top: 15px;
      font-size: 29px !important;
      cursor: pointer;
      width: 34px;
    }
    .navigation-active {
      color: #3DD8DB;
    }

    i {
      cursor: pointer;
    }

    .pages-navigation-tab {
      transform: rotate(180deg);
      margin-top: 30px;
    }

    i.fa-bookmark-o {
      margin-top: 30px;
      cursor: pointer;
    }

    .navigation-icon-pages {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 40px;
      margin-top: 0;
    }

    .navigation-icon-redacting {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 85px;
      margin-top: 0;
    }
    .navigation-icon-annotation {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 132px;
      margin-top: 0;
    }
    .navigation-icon-annotations {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 147px;
      margin-top: 0;
    }
    .navigation-icon-find-asset {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 177px;
      margin-top: 0;
    }
    .navigation-icon-image {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 220px;
      margin-top: 0;
    }
    .navigation-icon-spell_checker {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 270px;
      margin-top: 0;
    }
    .navigation-icon-metadata {
      position: absolute;
      left: 91%;
      font-size: 20px;
      top: 323px;
      margin-top: 0;
    }
  }

  .previewpdf {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 2;
    background: white;
    border-left: 1px solid #efeff0;
    border-right: 1px solid #382e34;
    border-bottom: 1px solid #efeff0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    flex-direction: column;
    margin-top:69px;
    .react-multi-carousel-list {
      padding: 0 27px 20px 27px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      border-radius: 5px;
    }

    .react-multi-carousel-dot-list {
      bottom: 10px;
    }

    .react-multi-carousel-dot button {
      border-color: #3DD8DB;
    }

    .react-multi-carousel-dot .react-multi-carousel-dot--active button,
    .react-multi-carousel-dot button:hover,
    .react-multi-carousel-dot--active button {
      background: #3DD8DB;
    }

    .btn-left {
      position: absolute;
      left: 3px;
    }

    .btn-right {
      position: absolute;
      right: 3px;
    }

    .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-arrow {
      display: none !important;
    }


    .prev-button {
      width: 100%;
      padding: 5px;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      color: #3DD8DB;
    }

    .next-button {
      width: 100%;
      padding: 5px;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      color: #3DD8DB;
      margin-top: 9px;
      background: white;
    }
    &__container-review {
      height: auto;
      padding-bottom: 20px;
      .header-contain-page-review {
        display: flex !important;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 0px !important;
        justify-content: flex-end !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        height: 30px;
        line-height: 30px;
        .select-profiles-colours {
          width: 170px;
          color: #6e7882;
          font-weight: 400;
          font-size: 14px;
          margin-right: 10px;
          .ant-select-selection {
            border-radius: 0px;
            font-size: 14px;
            height: 30px;
            border: 1px solid #382e34;
            font-weight: 400;
            box-shadow: none;
          }
          .ant-select-selection:hover {
            border-color: #EDA41E;
            border-right-width: 1px !important;
            .ant-select-arrow {
              color: #eda41e;
            }
          }
          .ant-select-arrow:hover {
            color: #eda41e;
          }
        }
        .icon-v17-redaction-hover {
          color: #6e7882;
          font-size: 20px !important;
        }
        .content-option-ai {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .custom-option-review {
          line-height: 30px;
          height: 30px;
          .ant-select-selection {
            border-radius: 0px;
          }
          .ant-select-selection:hover {
             border: 1px solid var(--generalIconFontHover);
          }
          .ant-select-selection--single {
            height: 30px;
          }
          .ant-select-selection__rendered {
            margin-top: 0px;
          }
        }
        .annotation-page {
          .position-relative {
            height: 30px;
            display: flex;
            align-items: center;
            width: 100%;
            .icon-v21-edit {
              color: #382e34;
              font-size: 20px;
              right: 5px;
            }
            .icon-v21-add-active {
              right: 5px;
            }
          }
        }
      }
    }
    &__container {
      overflow: auto;
      .header-contain-page-review {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;

        .icon-v17-redaction-hover {
          color: #6e7882;
          font-size: 18px;
        }
        .custom-option-review {
          width: 100px;
          border: none;
          font-family: Roboto;
          font-weight: 400;
          font-size: 14px;
          color: #6e7882;
          .ant-select-selection .ant-select-selection__rendered {
            margin-top: 1px;
          }
          .ant-select-selection {
            border: none;
          }

          .ant-select-selection__placeholder {
            font-weight: 400;
            font-size: 14px;
            color: #6e7882;
          }

          .ant-select-arrow {
            color: #6e7882;
            right: 8px;
          }
        }
        .wr-review-redact {
          padding: 0px;
          margin-bottom: 0px;
          .button-control {
            width: 100%;
            padding-bottom: 0px;
            .anticon {
              padding-top: 2px;
            }
            &__left {
              color: #6e7882;
              border: 1px solid #6e7882 !important;
            }
            &__right {
              color: #6e7882;
              border: 1px solid #6e7882 !important;
            }
            button {
              height: 20px;
            }
          }
        }
        .redacted-selected {
          .button-btn-redact {
            padding: 5px 13px;
          }
        }
      }
      .metadata-page {
        .title-metadata {
          margin-left: 20px;
          margin-bottom: 30px;
          margin-top: 20px;
          width: 93px;
          height: 16px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
        }

        .contain-categories {
          //margin-left: 20px;
          .title-categories {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.2px;
            color: #382e34;
            line-height: 20px;
            overflow-wrap: break-word;
          }

          .size-content {
            margin-top: 20px;
          }
        }
      }

      &__pages {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 25px 15px 15px 15px;
        height: 100%;
      }

      &__item {
        text-align: center;
        //width: 126px !important;
        height: auto;
        position: relative;
        margin-bottom: 40px;
        cursor: pointer;
        border: 1px solid #6e7882;
        border-radius: 0;
        //box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
        img {
          width: 68px;
        }
        .react-pdf__Document {
          width: 100%;
        }

        .react-pdf__Page__canvas {
          width: 110px !important;
          height: 150px !important;
          margin: auto;
        }

        .react-pdf__Page__textContent {
          display: none;
        }

        .react-pdf__Page__annotations {
          display: none;
        }

        &--active {
          border-color: #00d4d8;
          width: 70px;
        }

        .page-status {
          position: absolute;
          padding: 0px;
          background: #fff;
          border-radius: 0px;
          right: -12px;
          top: -11px;
          color: #3dd8db;
          margin-top: 10px;
          margin-right: -10px;

          &--red {
            color: #6e7882;
            font-weight: 400;
            //border: 1px solid #6e7882;
            margin-top: 10px;
            margin-right: -10px;
          }
        }

        .page-nr {
          font-size: 12px;
          position: absolute;
          bottom: -20px;
          right: 0;
          left: 0;
          width: 68px;
        }
        .fill-thumbnail-space {
          width: 100px;
          height: 130px;
        }
      }
    }
  }

  .previewpdf-right-open-search {
    //padding-top: 42px;
     padding-top: 1px;
    //padding-left: 18px;
  }

  .previewpdf-right {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 3;
    grid-column-end: 5;
    border-left: 1px solid #637882;
    width: 100%;
    justify-content: center;
    z-index: 401;
    margin-right: 15px;
    margin-top: 28px;
    .react-multi-carousel-list {
      padding: 0 27px 20px 27px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      border-radius: 5px;
    }

    .react-multi-carousel-dot-list {
      bottom: 10px;
    }

    .react-multi-carousel-dot button {
      border-color: #3DD8DB;
    }

    .react-multi-carousel-dot .react-multi-carousel-dot--active button,
    .react-multi-carousel-dot button:hover,
    .react-multi-carousel-dot--active button {
      background: #3DD8DB;
    }

    .btn-left {
      position: absolute;
      left: 3px;
    }

    .btn-right {
      position: absolute;
      right: 3px;
    }

    .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-arrow {
      display: none !important;
    }


    .prev-button {
      width: 100%;
      padding: 5px;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      color: #3DD8DB;
    }

    .next-button {
      width: 100%;
      padding: 5px;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      color: #3DD8DB;
      margin-top: 9px;
      background: white;
    }

    &__container {
      .metadata-page {
        .title-metadata {
          margin-left: 20px;
          margin-bottom: 30px;
          margin-top: 20px;
          width: 93px;
          height: 16px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
        }

        .contain-categories {
          margin-left: 20px;
          .title-categories {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #4a4a4a;
            line-height: 2.00;
            overflow-wrap: break-word;
          }

          .size-content {
            margin-top: 40px;
          }
        }
      }

      &__pages {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 16px;
        width: inherit;
      }
      &__item {
        text-align: center;
        width: 126px !important;
        height: auto;
        position: relative;
        margin-bottom: 47px;
        cursor: pointer;
        border: 2px solid #efeff0;
        border-radius: 0;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
        img {
          width: 100%;
        }
        .react-pdf__Document {
          width: 100%;
        }

        .react-pdf__Page__canvas {
          width: 110px !important;
          height: 150px !important;
          margin: auto;
        }

        .react-pdf__Page__textContent {
          display: none;
        }

        .react-pdf__Page__annotations {
          display: none;
        }

        &--active {
          border-color: #3DD8DB;
        }

        .page-status {
          position: absolute;
          padding: 6px;
          background: #fff;
          border-radius: 15px;
          right: 0;
          top: -1px;
          color: #3dd8db;

          &--red {
            color: red;
            font-weight: 900;
            border: 1px solid red;
          }
        }

        .page-nr {
          font-size: 14px;
          position: absolute;
          bottom: -23px;
          right: 0;
          left: 0;
        }
      }
    }
  }

  .react-pdf__Page__textContent {
    left: 0 !important;
    right: 0;
    margin: auto;
    top: 0 !important;
    transform: none !important;
    z-index: 1;

    span::selection {
      background-color: rgba(39, 126, 214, 0.4);
      color: rgba(0, 0, 0, 0);
    }

    span::-moz-selection {
      background-color: rgba(39, 126, 214, 0.4);
      color: #277ed6;
    }
  }

  .btn-action {
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 1.42;
    border-radius: 20px;
    transition: 0.3s;
    &:hover {
      color: #3DD8DB;
    }

    &--border {
      border: 3px solid #fff;
      padding-bottom: 22px;
    }

    &--red {
      color: red;
      border: 1px solid red;

      &:hover {
        color: #fff;
        background: red;
      }
    }
  }

  .redacted-options {
    display: block;
    text-align: center;
  }

  .redacted-page-portrait {
    margin: 7px;
    position: relative;
  }
   .contain-warnings-report {
     display: flex;
     align-items: center;
     border: 1px solid #eda41e;
     background-color: #ffffff;
     justify-content: space-between;
     .contain-text {
       display: flex;
       align-items: center;
       .icon-v23-warning-1 {
         font-size: 16px;
         color: #eda41e;
         margin-right: 10px;
       }
       .content-text-warning {
         font-family: Roboto;
         font-style: normal;
         font-weight: 400;
         line-height: 20px;
         font-size: 16px;
         color: #eda41e;
         margin-top: 2px;
       }
     }
     .link-text-warning {
       font-family: Roboto;
       font-style: normal;
       font-weight: 400;
       line-height: 20px;
       font-size: 14px;
       color: #eda41e;
       margin-top: 2px;
       float: right;
       display: flex;
       margin-right: 10px;
       cursor: pointer;
     }
   }
  .redacted-page-landscape {
    //margin: 5px auto 0;
    margin: 5px 5px 0;
    position: relative;
  }

  .redacted-container {
    overflow: hidden;
    margin: auto;
    .content-spinning-render-pages {
      font-size: 20px;
      width: 100px;
      display: flex;
      position: relative;
      justify-content: center;
      padding-bottom: 50px;
      padding-left: 10em;
      .ant-spin-spinning {
        margin-left: 400px;
        margin-top: 200px;
      }
      .ant-spin-text {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .wr-buttons {
    padding-left: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
    .content-option-smartview {
      display: flex;
      margin-right: 20px;
      .content-status-classification {
        margin-right: 20px;
        .ant-select-selection:hover {
          border: 1px solid #3DD8DB;
          .ant-select-selection-selected-value {
            color: #3DD8DB;
          }
          .ant-select-arrow {
            color: #3DD8DB;
          }
        }
        .ant-select-selection--single .ant-select-selection-selected-value {
          padding-top: 8px;
          font-weight: 400 !important;
          color: #6e7882;
        }
        .ant-select-selection__placeholder {
          color: #6e7882;
          padding-top: 3px;
          font-weight: 400!important;
        }
      }
    }
    .content-status-file {
      width: 150px;
      height: 30px;
      display: flex;
      align-items: center;
      .content-value-status {
        display: flex;
        align-items: center;
        //justify-content: space-between;
        .icon-v19-arrow-medium {
          font-size: 20px;
          color: #382e34;
        }
        .icon-v19-arrow-medium-rotate {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
        }
      }
      .ant-select-selection--single .ant-select-selection-selected-value {
        width: 100%;
      }
      .ant-select-selection {
        border: none;
        border-radius: 0px;
      }
      .icon-status {
        padding-right: 5px;
        font-weight: bold;
        font-size: 16px;
      }
      .item-options-status {
        color: #382e34;
        font-weight: 400;
        padding: 0px 5px;
        .icon-status {
          padding-right: 5px;
          font-weight: bold;
          font-size: 16px;
        }
      }
      .custom-status-holder {
        .ant-select-selection--single .ant-select-selection-selected-value {
          padding-top: 3px;
          //opacity: 0.6 !important;
          font-weight: 400 !important;
          color: #6e7882;
        }
      }
      .custom-status-select {
        .ant-select-selection--single .ant-select-selection-selected-value {
          font-weight: 400 !important;
          color: #382e34;
        }
      }
      .anticon {
        padding-right: 5px;
      }
      .ant-select-selection__rendered {
        line-height: 30px;
        //margin-top: 1px;
      }
      .ant-select-selection {
        border: 1px solid transparent;
      }
      .ant-select-selection:hover {
        border: 1px solid var(--generalIconFontHover);
        border-radius: 0px !important;
        .ant-select-selection-selected-value {
          color: #382e34;
        }
        .ant-select-arrow {
          color: #EDA41E;
        }
      }
      .ant-select-selection:focus, .ant-select-selection:active {
        border-color:var(--generalIconFontHover);
      }
      .ant-select-selection__placeholder {
        color: #6e7882;
      }
      .ant-select {
        width: 180px;
        .ant-select-selection--single {
          position: relative;
          height: 30px;
          cursor: pointer;
          width: 170px;
          .ant-select-selection-selected-value {
            //padding-top: 3px;
          }
        }
        .ant-select-arrow {
          margin-right: 2px;
          color: #382e34;
          font-weight: 400;
          top: 57%;
        }
      }
    }
  }

  #div-to-print-portrait {
    position: relative;
    visibility: hidden;
    height: 0;

    .react-pdf__Page__canvas {
      //height: 1584px !important;
      //width: 1190px !important;
    }
  }

  #div-to-print-landscape {
    position: relative;
    visibility: hidden;
    height: 0;

    .react-pdf__Page__canvas {
      //height: 900px !important;
      //width: 1578px !important;
    }
  }

  .canvas-modal {
    width: 15%;
    padding: 10px;
    min-width: 100px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    &__buttons {
      display: flex;
      justify-content: space-around;
    }
  }

  .pdf-page {
    padding-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
  }

  .sticky-header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    background: white;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    border-bottom: 1px solid #382e34;
    height: 40px !important;
    position: fixed;
    top: 30px;
    z-index:500;
    left: 20%;
    width:73%;

    .finalize-button {
      font-size: 17px;
      text-align: center;
      margin-right: 20px;
      padding: initial;
      width: 224px;
      height: 37px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .again-finalize-button {
      width: 260px;
      height: 40px;
    }

    .finalize-loading-container {
      display: flex !important;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Roboto';
      color: #737e89;
      line-height: 1.5;
      align-items: center;
      flex-direction: column-reverse;

      .finalize-loading {
        width: 40px;
        justify-content: center;
        align-items: center;

        .ant-spin-dot {
          .ant-spin-dot-item {
            background: rgb(61, 216, 219);
          }
        }
      }
    }
    .wr-undo-redo-button {
      display: flex;
      align-items: center;
      height: 30px;
      margin-right: 20px;
      .icon-v17-undo-selected {
        font-size: 25px;
        color: #382e34;
        padding-top: 2px;
      }
      .icon-v17-undo {
        font-size: 25px;
        color: #382e34;
        //padding-top: 2px;
      }
      .icon-v17-redo-selected {
        font-size: 25px;
        color: #382e34;
        padding-top: 2px;
      }
      .icon-v17-redo {
        font-size: 25px;
        color: #382e34;
        padding-top: 2px;
      }
    }
  .wr-select-button {
    display: flex;
    align-items: center;
    height: auto;
    .icon-v17-move-selected {
      font-size: 25px;
      color: #00d4d8;
      padding-top: 2px;
    }
    .icon-v17-move {
      font-size: 25px;
      color: #00d4d8;
      padding-top: 2px;
    }
    .icon-v17-draw-redaction-selected {
      font-size: 25px;
      color: #00d4d8;
      padding-top: 2px;
    }
    .icon-v17-draw-redaction {
      font-size: 25px;
      color: #00d4d8;
      padding-top: 2px;
    }
    .icon-v17-annotate-1-disabled {
      font-size: 25px;
      color: #6e7882 !important;
      opacity: 0.3;
      padding-top: 2px;
      cursor: not-allowed;
    }
    .icon-v17-text-selection-disabled {
      font-size: 25px;
      color: #6e7882 !important;
      opacity: 0.3;
      padding-top: 2px;
      cursor: not-allowed;
    }
    .icon-v17-text-selection-selected {
      font-size: 25px;
      color: #00d4d8;
      padding-top: 2px;
    }
    .icon-v17-text-selection {
      font-size: 25px;
      color: #00d4d8;
      padding-top: 2px;
    }
  }
    .wr-search-button {
      display: flex;
      //flex-direction: column;
      align-items: center;
      height: auto;
      margin-left: 20px;
       .icon-v17-word-search-selected {
         font-size: 25px;
         color: #382e34;
         padding-top: 2px;
         //margin-right: 5px;
       }
      .icon-v17-word-search {
        font-size: 25px;
        color: #382e34;
        padding-top: 2px;
        //margin-right: 5px;
      }
      .icon-v17-word-search-disabled {
          font-size: 25px;
          color: #6e7882 !important;
          opacity: 0.3;
          padding-top: 2px;
          cursor: not-allowed;
      }
      .icon-v17-annotate-1-disabled {
        font-size: 25px;
        color: #6e7882 !important;
        opacity: 0.3;
        padding-top: 2px;
        cursor: not-allowed;
      }
      .icon-v17-annotate-selected {
        font-size: 25px;
        color: #382e34;
        padding-top: 2px;
        //margin-right: 5px;
      }
      .icon-v17-annotate-1 {
        font-size: 25px;
        color: #382e34;
        padding-top: 2px;
        //margin-right: 5px;
      }
      .button-active {
        margin-top: 20px;
        margin: 0 auto;
        margin-top: 2px;
      }

      i {
        margin-right: .5rem !important;
        position: absolute;
        top: 6.8%;
        z-index: 520;
        background: white;
        font-size: 19px;
        width: 22px;
        display: none;
      }
    }
    .wr-clear-button {
      display: flex;
      align-items: center;
      margin-right: 100px;
      .icon-v17-clear-page {
        font-size: 25px;
        padding-top: 0px;
        color: #EDA41E;
      }
      button:disabled {
        cursor: default;
        opacity: 0.2;
      }
      .icon-v17-clear-page-selected {
        font-size: 25px;
        padding-top: 2px;
        color: #EDA41E;
      }
      .icon-v17-clear-selected-selected {
        font-size: 25px;
        padding-top: 2px;
        color: #EDA41E;
      }
      .icon-v17-clear-selected {
        font-size: 25px;
        padding-top: 2px;
        color: #EDA41E;
      }
    }
    .wr-switch-redact{
      //min-width: 335px;
      display: flex;
      font-size: 25px;
      justify-content: center;
      align-items: center;
      align-content: center;
      .icon-v17-preview {
        cursor: pointer;
        color: #382e34;
        padding-top: 2px;
      }
      .icon-v17-preview-selected-1 {
        cursor: pointer;
        color: #382e34;
        padding-top: 2px;
      }
      .icon-v17-preview-disabled {
        opacity: 0.3;
        cursor: default;
      }
    }
    .center-container {
      display: flex;
      align-items: center;
      flex-direction: column;

      &__buttons {
        display: flex;
        width: 100%;
        height: 30px;
      }

      .document-name {
        font-size: 16px;
        width: 500px;
        margin-bottom: 5px;
        margin-top: -5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #6e7882;
        font-weight: 500;
        opacity: 0.5;
      }

      .document-saved {
        font-size: 14px;
        color: #6e7882;
        font-weight: bold;
        opacity: 0.8;
        font-style: oblique;
      }

      .ant-switch {
        min-width: 107px;
        height: 30px;
        border-radius: 18.5px;
        border: solid 1px rgba(110, 120, 130, 0.3);
        background-color: #ffffff;
        display: inline-block;
        .ant-switch-inner {
          color: rgba(110, 120, 130, 0.3);
          font-family: Roboto;
          font-size: 18px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          margin: 0 15px 0 37px;
          width: 122px;
        }

        &:after {
          top: 2px;
          left: 2px;
          width: 24px;
          height: 24px;
          box-shadow: 0 2px 4px 0 rgba(0, 2, 4, 0.2);
          border: solid 1px #3ED7DA;
          background-color: #ffffff;
        }
      }
      .ant-switch-checked{
        .ant-switch-inner{
          margin: 0 37px 0 15px;
        }
      }
      .ant-switch-checked::after {
        left: 100%;
      }

      .ant-switch-checked {
        background-color: #3DD8DB;

        .ant-switch-inner {
          color: white;
        }
      }

      .disabled {
        color: #ccc;
        border-color: transparent;
        background-color: #fff;

        &:hover {
          color: #ccc;
          border-color: transparent;
          background-color: #fff;
        }
      }
    }
  }

  .wr-empty {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    border-bottom: 1px solid #8c8c8d57;
    background-color: white;
  }

  .buttons-search-affix {
    z-index: 500 !important;
    display: flex;
    justify-content: center;
  }
  .search-affix {
    grid-area: 2 / 2 / 2 / 8;
    z-index: 200;

    div:first-child {
      grid-area: 2 / 3 / 2 / 8;
      z-index: 200;
      .update-wr-search-text {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 8;
        z-index: 200;
        background-color: #f7fbff;
        padding: 5px 5px 10px 10px;
        border: 1px solid #382e34;
        width: 500px;
        position: fixed;
        top: 70px;
        left: 43%;
        //left: 42%;
        //position: absolute;
        .header-search-text {
           display: flex;
          justify-content: space-between;
          padding-top: 5px;
          padding-bottom: 10px;
          align-items: center;
          .title-header {
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            color: #382e34;
            letter-spacing: 0.2px;
          }
          .icon-v17-close-black {
            font-size: 15px;
            color: #382e34;
          }
          .icon-v17-close-black:before {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
        .search-text-input {
          padding-right: 0px;
          .ant-input:placeholder-shown {
            color: #a6acb2;
          }
          .ant-input {
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            color: #382e34;
            letter-spacing: 0.2px;
            height: 30px;
            width: 480px;
            background-color: #FFFFFF;
            border-radius: 0px;
            //border: 1px solid #382e34;
          }
          .ant-input-search-icon {
            color: #382e34;
            font-size: 20px;
          }
          .ant-input:hover {
            border: 1px solid #00d4d8;
          }
          .ant-input:focus {
            border: 1px solid #00d4d8;
            box-shadow: none;
          }
        }
      }

      .wr-search-text {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 8;
        z-index: 200;
        width: 100%;

        .search-arrow {
          position: absolute;
          top: 86px;
          z-index: 200;
          background: white;
          left: 436px;
          font-size: 19px;
        }

        .ant-input-group-wrapper {
          z-index: 100;
          left: 185px;
          border-radius: 0;
          border: 1px solid black;

          .ant-input-group-addon {
            border: 0;
          }

          input {
            border: 0;
            height: 40px;
            font-size: 18px;

            &:focus {
              box-shadow: none;
            }
          }

          .ant-input-suffix {
            font-size: 25px;
          }

          .ant-input-group-addon {
            background: white;
            margin-left: -5px;

            .button {
              background-color: white;
              padding: 2px;
              margin: 5px;

              i {
                transform: rotate(90deg);
                font-size: 16px;
              }

              .anticon-italic {
                transform: none;
              }

              &:hover {
                color: white;
                background-color: #3DD8DC;;
              }
            }
          }
        }

        .close-search {
          background: white;
          margin-right: -12px;
          padding: 8px;
          border-left: 1px solid #EFEFF0;
          color: #777778;
          cursor: pointer;
        }
      }
    }
  }

  .print-loading {
    width: 86vw;
    height: 92vh;
    position: absolute;
    z-index: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fffffffa;
    flex-direction: column;

    .ant-spin-dot {
      width: 3em;
      height: 3em;
      margin-bottom: 1em;

      .ant-spin-dot-item {
        width: 25px;
        height: 25px;
        background: rgb(61, 216, 219);
      }
    }

    .ant-spin-text {
      top: 60% !important;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Roboto';
      color: #737e89;
      line-height: 1.5;
      margin-left: 30px;
    }
  }

  .close-icon {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 4;
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #8c8c8d57;
    background: white;
    border-bottom: 1px solid #382e34;
    //cursor: pointer;
    padding-right: 20px;
    position: fixed;
    top: 30px;
    right: 0px;
    width: 7%;
    height: 40px;
    z-index:500;
    .icon-v17-close-black {
      cursor: pointer;
      font-size: 20px;
      color: #6e7882;
    }
  }
   .update-document-note {
     position: relative;
     .icon-v17-process-error {
       position: absolute;
       font-size: 15px;
       color: #F2137d;
       top: -5px;
       left: -4px;
     }
     .icon-v17-document-note-1 {
       font-size: 20px;
       color: #6e7882;
     }
     .icon-v17-document-note-active-1 {
       font-size: 20px;
       color: var(--generalIconFontHover);
     }
     .icon-v17-download {
       font-size: 20px;
       color: #6e7882;
     }
     .icon-v17-download-active {
       font-size: 20px;
       color: var(--generalIconFontHover);
     }
     .icon-download-disabled {
       cursor: not-allowed;
       opacity: 0.6;
     }
   }
  .new-navigation-smartview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //height: 40px;
    border-bottom: 1px solid #382e34;
    margin: 0px 10px 0px 0px;
    position: fixed;
    top: 30px;
    width: 19.98%;
    z-index:500;
    height: 40px;
    background-color: white;
    border-right: 1px solid #382e34;
    .ant-select-selection-selected-value {
      width: 100%;
    }
    .content-value-navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .nav-option {
        display: flex;
        align-items: center;
      }
      .icon-v19-arrow-medium-rotate {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
      }
      .icon-v19-arrow-medium {
        color: #382e34;
        font-size: 20px;
      }
    }
    .ant-select {
      width: 97%;
      height: 30px;
      margin-left: 3px;
       //margin-bottom: 5px;
    }
    .ant-select-selection:hover {
      border: 1px solid var(--generalIconFontHover) !important;
    }
    .ant-select-selection__rendered {
      margin-right: 5px;
    }
    .ant-select-dropdown-menu-item {
      padding: 5px 2px !important;
    }
    .ant-select-selection--single {
      height: 30px;
    }
    .navigation-bar-smartview {
      .ant-select-dropdown-menu-item {
        padding: 5px 2px;
      }
      .ant-select-selection-selected-value {
        display: flex !important;
      }
      .text-option {
        font-size: 14px;
        font-weight: 400;
        font-family: Roboto;
        color: #382e34;
      }
      .ant-select-selection {
        width: 100%;
        border: 1px solid transparent;
        border-radius: 0px;
      }
      .ant-select-arrow {
        //right: 80px;
        color: #382e34;
      }
      .icon-nav {
        color: #382e34;
        font-size: 20px;
      }
      .icon-nav-metadata {
        color: #382e34;
        font-size: 17px;
      }
      .icon-nav-pii {
        color: #382e34;
        font-size: 23px;
      }
      .icon-nav-spell {
        color: #382e34;
        font-size: 24px;
      }
      .options-bar {
        .icon-nav {
          color: #382e34;
          font-size: 20px;
        }
        .icon-nav-metadata {
          color: #382e34;
          font-size: 17px;
        }
        .icon-nav-pii {
          color: #382e34;
          font-size: 23px;
        }
        .icon-nav-spell {
          color: #382e34;
          font-size: 24px;
        }
      }
    }
  }
  .document-note {
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    //border-bottom: 1px solid #8c8c8d57;
    &-button {
      background-color: white;
      border: 1px solid #3DD8DB;
      cursor: pointer;
      text-transform: uppercase;
      -webkit-transition: all .2s;
      transition: all .2s;
      border-radius: 30px;
      margin-right: 27px;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3dd8db;
      width: 188px;
      height: 37px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: #3DD8DB;
        color: white;
      }
    }
  }

  .slick-prev:before, .slick-next:before {
    color: #3dd8db;
  }

  span.annotate-label {
    position: absolute;
    top: -25px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    padding: 2px;
  }
}

.redacting-mask {
  position: absolute;
  z-index: 402;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
  .redacting-mask-div {
    background-color: rgba(255, 255, 255, 1.0);
    width: 30%;
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 15px 0;
    margin: 0 auto;
    margin-top: 20%;
    .redacting-mask-spin {
      display: block;
      .ant-spin-dot {
        width: 3em;
        height: 3em;
        .ant-spin-dot-item {
          opacity: 1;
          width: 25px;
          height: 25px;
          background: rgb(61, 216, 219);
        }
      }

      .ant-spin-text {
        top: 60% !important;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Roboto';
        color: #737e89;
        line-height: 1.5;
        margin-left: 30px;
        padding-top: 20px !important;
      }
    }
    .redacting-mask-text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
      opacity: 1;
    }
  }
}

.slick-track {
  padding-bottom: 20px;
}

.slick-slide img {
  margin: auto;
}

.slick-list {
  height: 100% !important;
}

#canvas-modal {
  width: 20%;
  padding: 10px;
  min-width: 100px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

#canvas-modal-edit {
  width: 20%;
  padding: 10px;
  min-width: 100px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.show-pdf-page {
  visibility: visible !important;
  height: auto !important;
}

.message-loading-container {
  text-align: center;
  margin-top: 17rem;
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto';
  color: #737e89;
  line-height: 1.5;
}

.custom-spin {
  height: 40% !important;
  display: block;
  width: 96% !important;
  .ant-spin-dot {
    width: 3em;
    height: 3em;

    .ant-spin-dot-item {
      width: 25px;
      height: 25px;
      background: rgb(61, 216, 219);
    }
  }

  .ant-spin-text {
    top: 60% !important;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #737e89;
    line-height: 1.5;
    margin-left: 30px;
    padding-top: 20px !important;
  }
}
.spin-over-pages {
  top: 50px;
  position: absolute;
  z-index: 8;
}

.react-pdf__Page__canvas {
  margin: auto;
}

.ant-spin-blur {
  opacity: 0 !important;
}

.custom-modal-body {
  position: relative
}

input.ant-select-search__field {
  width: 100% !important;
}

.previewpdf__container__pages {
  overflow: auto;
  background-color: #fff;
  width: 100%;
  height: 99.8%;
  position: relative;
  .no-thumbnail {
    text-align: center;
    height: auto;
    position: relative;
    margin-bottom: 47px;
    cursor: pointer;
    border: 2px solid #efeff0;
    border-radius: 0;
    min-width: 100px;
    min-height: 130px;
    box-shadow: 0 2px 9px 0 #efeff0 ;
    &--active {
      border-color: #3DD8DB;
    }
    .page-nr {
      font-size: 14px;
      position: absolute;width: 100%;
      height: 99.8%;
      bottom: -23px;
      right: 0;
      left: 0;
    }
    .page-status {
      position: absolute;
      padding: 6px;
      background: #fff;
      border-radius: 15px;
      right: 0;
      top: -1px;
      color: #3dd8db;

      &--red {
        color: red;
        font-weight: 900;
        border: 1px solid red;
      }
    }
    &__icon {
      font-size: 40px;
      color: #ccc;
      top: 34%;
      position: relative;
    }
  }
  &__title {
    margin-bottom: 10px;
    font-size: 12px;
    justify-self: flex-start;
    align-self: flex-start;
    font-weight: 600;
    color: #382e34;
    width: max-content;
  }
  &--preview-landscape {
    height: 100%;
  }

  .slick-slider {
    height: 97%;
  }

  .next-button {
    position: absolute;
    bottom: 0;
  }
}

.matched-word {

}

#matched-word--current {
  animation: shadow-pulse 0.7s infinite;
  border: 1px solid;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 2px;
  animation-timing-function: ease;
}

@keyframes shadow-pulse {
  50% {
    border-top-width: 3px;
    border-bottom-width: 3px;
  }
}

.btn--action-search {
  height: 26px;
  width: 26px;
  margin: 2px 0 1px 3px
}

#btn-search {
  background-image: url("../../../assets/canvas-buttons/default/search.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/search.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-search-active {
  background-image: url("../../../assets/canvas-buttons/default/search.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1.01px solid #000000;
}

#btn-undo {
  background-image: url("../../../assets/canvas-buttons/default/undo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/undo.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-redo {
  background-image: url("../../../assets/canvas-buttons/default/redo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 20px !important;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/redo.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-select {
  background-image: url("../../../assets/canvas-buttons/default/drag.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/drag.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-select-active {
  background-image: url("../../../assets/canvas-buttons/default/drag.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1.01px solid #000000;
  box-shadow: 0 0 6px 1px #888888;
}

#btn-draw {
  background-image: url("../../../assets/canvas-buttons/default/draw.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/draw.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-draw-active {
  background-image: url("../../../assets/canvas-buttons/default/draw.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1.01px solid #000000;
  box-shadow: 0 0 6px 1px #888888;
}

#btn-text-select {
  background-image: url("../../../assets/canvas-buttons/default/text_select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/text_select.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-text-select-active {
  background-image: url("../../../assets/canvas-buttons/default/text_select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1.01px solid #000000;
  box-shadow: 0 0 6px 1px #888888;
}

#btn-message {
  //background-image: url("../../../assets/canvas-buttons/default/message.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    //background-image: url("../../../assets/canvas-buttons/hover/message.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-message-active {
  //background-image: url("../../../assets/canvas-buttons/default/message.png");
  //background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1.01px solid #000000;
  box-shadow: 0 0 6px 1px #888888;
}

#btn-save {
  background-image: url("../../../assets/canvas-buttons/default/save.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/save.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-download {
  background-image: url("../../../assets/canvas-buttons/default/download.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/download.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-eraser {
  background-image: url("../../../assets/canvas-buttons/default/eraser.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/eraser.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#btn-clear {
  background-image: url("../../../assets/canvas-buttons/default/clear.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/clear.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.btn-text-select {
  background-image: url("../../../assets/canvas-buttons/hover/text_select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/default/text_select.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.canvas-save {
  background-image: url("../../../assets/canvas-buttons/default/save.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/save.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.canvas-eraser {
  background-image: url("../../../assets/canvas-buttons/default/eraser.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/eraser.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.canvas-clear {
  background-image: url("../../../assets/canvas-buttons/default/clear.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover {
    background-image: url("../../../assets/canvas-buttons/hover/clear.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.find-asset-color-picker {
  background-image: url("../../../assets/canvas-buttons/default/drop.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 40px;
  top: 10px
}
.find-asset-view {
  background-image: url("../../../assets/canvas-buttons/default/eyes.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;

}

.fa-eye-slash {
  //todo add svg of it image
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.color-picker {
  background-image: url("../../../assets/canvas-buttons/default/drop.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 15px;
  height: 15px;
  position: absolute;
}

.btn-action--border {
  border: 0 solid #fff !important;
  padding-bottom: 22px;
}

button:disabled {
  background-color: #FFFFFF;
  border: 0 solid #CCCCCC !important;
  cursor: not-allowed;
  color: #CCCCCC;
}

.annotation-sidebar {
  .ant-drawer-content-wrapper {
    height: 94%;
    top: 8%;
  }
 .ant-drawer-content {
    width: 100%;
    height: 92%;
  }
  p {
    font-size: 16px;
  }
  &__text {
    background-color: transparent;
    font-weight: bold;
    border: 1px solid #ddd;
    height: 32px;
    width: 100%;
  }
}
.ant-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {;
      height: 92%;
    }
  }
}

.delete-extra {
  position: absolute;
  left: 35%;
  bottom: 2%;
}

.delete_annotation_wrapper.text-center {
  position: absolute;
  bottom: 10px;
  .delete_annotation-extra {
    position: relative;
    bottom: 1%;
  }
}

.disabled-clicked-page {
  background-color: transparent;
  left: 0;
  position: absolute;
  z-index: 400;
  width: 100%;
  height: 100%;
}

// Small devices
@media (max-width: 992px){
  .redacting {
    .annotation-sidebar {
      .ant-drawer-content-wrapper {
        top: 14%;
      }
    }
  }
  ant-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {;
        height: 96%;
      }
    }
  }
}

@media screen and ( min-width: 1880px ) and ( max-height: 395px ) {
  .redacting {
    padding-bottom: 0;
    grid-template-rows: 40px 0vh 100%;
    grid-template-columns: 20% 71% 9% !important;
  }
}
@media screen and ( max-width: 879px ) and ( max-height: 478px ) {
  .redacting {
    grid-template-rows: 40px 0 100%;
  }
}
@media screen and ( max-width: 963px ) and ( max-height: 617px ) {
  .redacting {
    grid-template-rows: 11% 89%;
  }
}
@media screen and ( max-width: 970px ) and ( max-height: 605px ) {
  .redacting {
    grid-template-rows: 40px 0 100%;
  }
}
@media screen and ( max-width: 1855px ) and ( max-height: 590px ) {
  .redacting {
    .previewpdf__container__pages {
      padding: 15px 20px !important;
    }
  }
}
@media screen and ( max-width: 1024px ) and ( max-height: 605px ) {
  .redacting {
    grid-template-rows: 40px 0 100%;
  }
}
@media screen and ( max-width: 1347px ) and ( max-height: 605px ) {
  .previewpdf__container__pages__title {
    width: max-content !important;
  }
}
@media screen and ( max-width: 1866px ) and ( max-height: 478px ) {
  .redacting {
    .previewpdf__container__pages {
      padding: 15px 15px 15px !important;
    }
  }
}
@media screen and ( min-width: 1400px ) and ( max-width: 1800px ) {
  .redacting {
    grid-template-rows: 40px 0vh 100% !important;
  }
}
@media screen and ( min-width: 992px ) and ( max-width: 1400px ) {
  .redacting {
    grid-template-rows: 40px 0vh 100%;
  }
}
// Notebooks devices
@media (min-width: 992px) and (max-width: 1400px) {
  .redacting {
    padding-bottom: 0;
    grid-template-rows: 40px 0vh 100%;

    .annotation-sidebar {
      .ant-drawer-content-wrapper {
        height: 94%;
        top: 10%;
      }
    }

    .custom-affix div:first-child .sticky-header .wr-search-button i {
      margin-left: -3px;
      top: 53px;
      border-radius: 40px;
    }
  }
  .ant-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {;
        height: 96%;
      }
    }
  }
}

// Large notebooks and desktops
@media (min-width: 1400px) and (max-width: 1800px) {
  .redacting {
    padding-bottom: 0;
    //grid-template-rows: 8vh 6vh 86vh;
    grid-template-rows: 40px 0vh 100% !important;

    .annotation-sidebar {
      .ant-drawer-content-wrapper {
        height: 94%;
        top: 8%;
      }
    }

    .custom-affix div:first-child .sticky-header .wr-search-button i {
      margin-left: -3px;
      top: 51px;
      border-radius: 40px;
    }
  }
  .ant-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {;
        height: 98%;
      }
    }
  }
}


// Large desktop devices
@media (min-width: 1800px) {
  .redacting {
    padding-bottom: 0;
    grid-template-rows: 40px 6vh 89vh;

    .custom-affix div:first-child .sticky-header .wr-search-button i {
      margin-left: -3px;
      top: 6.5vh;
      border-radius: 40px;
    }
  }
}
@media (min-width: 200px) and (max-width: 800px) {
  .redacting .sticky-header {
    height: 40px !important;
    //z-index: 5;
  }
}
//todo tomorow
@media (min-width: 1200px) and (max-width: 1400px) {
  .redacting .sticky-header {
    height: 40px !important;
  }
  .redacting {
    .navigation-tabs {
      font-size: 33px;
    }
    .document-note-button {
      font-size: 12px;
      font-weight: 600;
      width: 134px;
      margin-right: 15px;
    }
    .sticky-header .wr-switch-redact{
      //min-width: 263px;
    }
     .sticky-header .center-container .ant-switch {
       height: 25px;
       width: 47%;
       .ant-switch-inner{
         margin: 0;
       }
    }
     .btn-action {
       color: #fff;
       width: 25px;
       height: 25px;
     }
    .sticky-header .center-container .ant-switch .ant-switch-inner {
      font-size: 12px;
      width: 150px;
    }
  }

  .wr-search-button {
    i {
      top: 7.8% !important;
    }
  }

  .redacting .sticky-header .finalize-button{
    font-size: 12px;
    width: 160px;
  }
  .redacting .sticky-header .center-container .ant-switch:after{
    width: 18px;
    height: 19px;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .redacting .sticky-header {
    height: 40px !important;
  }
  .redacting {
    .document-note-button{
      font-size: 12px;
      font-weight: 600;
      width: 96px;
    }
    .sticky-header .center-container .ant-switch .ant-switch-inner{
      font-size: 12px;
    }
    .navigation-tabs {
      font-size: 26px;
      i {
        transition: 0.3s;
      }
    }

    .navigation-tabs {

      .navigation-icon-pages {
        position: absolute;
        left: 91%;
        font-size: 17px;
        top: 40px;
        margin-top: 0;
      }

      .anticon-copy {
        svg {
          width: 33px;
          height: 33px;
        }
      }
    }

    .finalize-button {
      font-size: 12px;
      margin-right: 15px;
    }

    .custom-affix {
      div:first-child {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 8;
        display: grid;
        grid-template-columns: 0% 8% 8% 66% 6% 7% 5%;
        height: 100%;

        .sticky-header {
          display: flex !important;
          align-items: center;
          justify-content: center;
          background: white;
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 4;
          grid-column-end: 5;

          .center-container {
            .ant-switch {
              width: 90px;
              height: 30px;
              border-radius: 25px;

              &:after {
                top: 4px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }

  }
}
.swall-redact-title{
  padding: 10px 0 20px;
  font-weight: 300;
  font-family: Roboto;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #637882;
}
.redact-swall-wr{
  .swal2-popup{
    width: 22em !important;
  }
  .swal2-actions{
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    button{
      padding: 10px !important;
      font-size: 16px !important;
      font-weight: 900 !important;
      font-family: roboto !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: normal !important;
      letter-spacing: normal !important;
    }
  }

}

#pdf-page {  
  position: relative;
}
.react-pdf__Document {
  position: relative;
  z-index: 8;
}
@media (min-width: 1800px) and (max-width: 3000px) {
  .redacting .sticky-header {
    height: 40px !important;
    z-index: 5;
  }
}
@media screen and (min-width: 3000px) {
  .redacting .sticky-header {
    height: 41px !important;
  }
}
@media screen and (min-width: 1870px) {
  .redacting {
    .previewpdf__container__pages {
      padding: 15px 20px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .redacting {
    .previewpdf__container__pages {
      padding: 15px 10px;
    }
  }
}
  .options-bar-smartview {
    .text-option {
      font-size: 14px;
      font-weight: 400;
      font-family: Roboto;
      color: #382e34;
    }
    .icon-nav {
      color: #382e34;
      font-size: 20px;
      margin-right: 5px;
    }
    .icon-nav-metadata {
      color: #382e34;
      font-size: 17px;
      margin-right: 8px;
    }
    .icon-nav-pii {
      color: #382e34;
      font-size: 23px;
      margin-right: 3px;
    }
    .icon-nav-spell {
      color: #382e34;
      font-size: 24px;
    }
  }
  .new-navigation-bar-smartview {
    border: 1px solid var(--generalIconFontHover) !important;
    position: fixed !important;
    .ant-select-dropdown-menu-item {
      padding: 0px 5px;
      height: 30px;
      display: flex;
      align-items: center;
      line-height: 30px;
    }
    .ant-select-dropdown-menu-item-selected {
       background-color: #FFFFFF;
    }
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #9ae0fc50 !important;
    }
    .ant-select-dropdown-menu {
      padding: 0px 0 !important;
    }
  }
  .new-dropdown-status-annotate {
    width: 170px !important;
    overflow: hidden !important;
    .ant-select-dropdown-menu-item-selected {
      font-family: Roboto;
      color: #382e34;
      font-size: 12px;
      letter-spacing: 0.2px;
      font-weight: 400;
    }
    .ant-select-dropdown-menu-item {
      font-family: Roboto;
      color: #382e34;
      font-size: 12px;
      letter-spacing: 0.2px;
      font-weight: 400;
    }
  }

  .dropdown-custom-list {
    border: 1px solid #00d4d8 !important;
    .ant-select-dropdown-menu-item-selected {
      font-family: Roboto;
      color: #6e7882;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-weight: 400;
      background-color: #FFFFFF;
    }
    .ant-select-dropdown-menu-item {
      font-family: Roboto;
      color: #6e7882;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-weight: 400;
      background-color: #FFFFFF;
    }
  }

  .file-status-dropdown {
    width: 170px !important;
    border: 1px solid var(--generalIconFontHover) !important;
    position: fixed !important;
    .ant-select-dropdown-menu {
      padding: 0px 0;
    }
    .item-options-status {
      color: #382e34;
      font-weight: 400;
      font-size: 14px;
      padding: 0px 5px;
    }
    .ant-select-dropdown-menu-item {
      line-height: 30px !important;
    }
  }

  .dropdown-custom-option-review {
    border: 1px solid var(--generalIconFontHover) !important;
    .ant-select-dropdown-menu {
      padding: 0px !important;
    }
    .ant-select-dropdown-menu-item {
      padding: 0px 10px !important;
      line-height: 30px !important;
    }
  }