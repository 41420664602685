.limitedFreeAccount-container {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 30px;
  padding: 30px 40px;
  position: relative;
* {
  font-family: 'Roboto';
  font-weight: 400;
}
}

.limitedFreeAccount-title {
  color: #212a34;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.limitedFreeAccount-text {
  color: #555555;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
  text-align: center;
h3 {
  font-size: 22px;
  font-weight: normal;
  padding-top: 20px;
}
}

.limitedFreeAccount-button {
  bottom: -24px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
button {
  height: 50px;
  width: 300px;
  font-size: 16px;
}
}

@media screen and (min-width: 768px) {
  .limitedFreeAccount-container {
    height: 200px;
  }
}