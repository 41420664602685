.userSettings-page {
  margin: 0 auto;
  //max-width: 1200px;
  padding: 90px 20px 0;
}
.userSettings-page-update {
  margin: 60px 82px!important;
  width: auto;
  .userSettings-section {
    padding: 30px 0px 0 !important;
    border-top: none !important;
  }
  .userSettings-sectionTitle-password {
    color: #382e34;
    font-family: Roboto;
    font-size: 14px !important;
    font-weight: 700 !important;
    padding-bottom: 20px;
  }
  .styleUploadImage {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    .m-3 {
      margin: 0px 30px 0px 0px !important;
    }
  }
  .fileContainer .chooseFileButton {
    background-color: #FFFFFF;
    border: 1px solid #637882;
    color: #637882;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    padding: 4px 23px;
    margin: 0px 0;
    height: 25px;
    width: 170px;
  }
  .fileContainer .chooseFileButton:hover {
    background-color: #FFFFFF;
    border: 1px solid var(--generalButtonHover);
    color: var(--generalButtonHover);
  }
  .fileContainer .chooseFileButton:active {
    background-color: #FFFFFF;
    border: 1px solid var(--generalButtonActive);
    color: var(--generalButtonActive);
  }
  .fileContainer input {
    display: none;
  }
    .fileContainer p {
    display: none;
  }
  .teamIconImage {
    border: 1px solid #6e7882;
  }
  .upload-title {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 0;
    line-height: 1.5;
    color: #382e34;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    .styleSpan1 {
      color: #6e7882;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 5px;
    }
  }
  .pageTitle-header-update {
    height: 20px;
    font-size: 20px;
    color: #382e34;
    margin-bottom: 10px;
    padding: 0px;
  }
  .userSettings-sectionTitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #382e34;
    margin-top: 30px;
  }
  .ant-checkbox-wrapper-disabled {
    cursor: default;
  }
  .content-multi-factor-authentication {
    .checkbox-multi-factor {
      .ant-checkbox-wrapper .ant-checkbox-wrapper-disabled {
         cursor: default;
      }
      .ant-checkbox-disabled {
         cursor: default;
      }
    }
  }
  .userSettings-footer {
    padding-bottom: 60px;
    padding-top: 60px;
    .userSettings-footerLink-content {
      padding-right: 30px;
    }
    .userSettings-footerLink {
      color: #6e7882;
      border: 1px solid #6e7882;
      height: 25px;
      width: 170px;
      align-items: center;
      line-height: 18.75px;
      text-align: center;
      padding: 3px;
      border-radius: 25px;
      text-decoration: none;
      .userSettings-footerLink-content {
        padding-right: 30px;
      }
    }
    .userSettings-footerLink:hover {
      color: var(--generalButtonHover);
      border: 1px solid var(--generalButtonHover);
    }
    .userSettings-footerLink:active {
      color: var(--generalButtonActive);
      border: 1px solid var(--generalButtonActive);
    }
    .userSettings-footerButton {
      background-color: var(--generalButton);
      color: var(--generalButtonFont);
      border: 1px solid #6e7882;
      border-radius: 20px;
      box-shadow: none;
      cursor: pointer;
      font-family: ROBOTO;
      font-weight: 400;
      font-size: 16px;
      text-transform: none;
      transition: all .2s;
      cursor: pointer;
      padding: 3px !important;
      width: 170px;
      height: 25px;
      line-height: 18.75px;
    }
    .userSettings-footerButton:hover {
      background-color: var(--generalButtonHover);
      color: var(--generalButtonFont);
      border: 1px solid var(--generalButtonHover);
    }
    .userSettings-footerButton:active {
      background-color: var(--generalButtonActive);
      //color: #FFFFFF;
      border: 1px solid var(--generalButtonActive);
    }
    .loading-btn-wrapper {
      width: 170px;
      height: 25px;
      margin-right: 30px;
      .save-changes-spinner {
        position: relative;
        bottom: 21px;
        left: 12px;
        color: #FFFFFF;
      }
      .loading-state {
        background-color: #FFC457;
        border: 1px solid #FFC457;
        pointer-events: none;
      }
    }

  }
  .userSettings-sectionButton {
    border: 1px solid #6e7882;
    color: #6e7882 !important;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    padding: 2px 20px;
    line-height: 18.75px;
    height: 25px;
  }
  .userSettings-sectionButton:hover {
    background-color: #FFFFFF !important;
    color: #FFC457 !important;
    border: 1px solid #FFC457 !important;
  }
  .userSettings-sectionButton:active {
    background-color: #FFFFFF !important;
    color: #EDA41E !important;
    border: 1px solid #EDA41E !important;
  }
  input[type=text]:disabled {
    border: 1px solid #D4D7DA;
    border-radius: 0px;
    color: #D4D7DA;
    cursor: default;
    background-color: #FFFFFF;
  }
  .userSettings-formGroupSide input:active {
    outline: none;
  }
  .userSettings-formGroupSide {
    margin-right: 30px;
    //width: 350px;
    margin-bottom: 0px;
    width: 100%;
    label {
      color: #6e7882;
      font-weight: 400;
      display: block;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 20px;
    }
    input {
      border: 1px solid #6e7882;
      border-radius: 0px;
      color: #D4D7DA;
      font-size: 16px;
      height: 40px;
      font-weight: 400;
    }
  }
  .userSettings-formGroup {
    margin-right: 30px;
    //width: 350px;
    margin-bottom: 0px;
    width: 100%;
    label {
      color: #6e7882;
      font-weight: 400;
      display: block;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 20px;
    }
    input {
      border: 1px solid #D4D7DA;
      border-radius: 0px;
      color: #D4D7DA;
      font-size: 16px;
      height: 40px;
      font-weight: 400;
    }
  }
}

.userSettings-box {
  //background-color: #FFFFFF;
  //border-radius: 5px;
  //box-shadow: 0 2px 4px 0 #DCDCDC;
  //position: relative
}

.userSettings-section {
  border-top: 1px solid #E6E6E6;
  padding: 20px 20px 0
}
.userSettings-section-update {
  display: flex;
}
 .content-app-user-settings {
   display: flex;
   align-content: center;
   justify-content: center;
   margin-top: 20px;
   .content-app-user {
     //width: 20%;
     border: 1px solid #382e34;
     padding: 30px;
     line-height: 20px;
     .input-authentication:hover {
        border: 1px solid #EDA41E;
       outline: none;
       border-radius: 0px;
     }
     .input-authentication:focus {
       border: 1px solid #EDA41E;
       outline: none;
       border-radius: 0px;
     }
     .input-authentication:active {
       border: 1px solid #EDA41E;
       outline: none;
       border-radius: 0px;
     }
     .login-user {
       margin-top: 50px;
       margin-bottom: 20px;
       display: flex;
       .button-blue-authentication {
         border: 1px solid #6e7882;
         background-color: #EDA41E;
         color: #FFFFFF;
         //box-shadow: 0 2px 4px 0 #DCDCDC;
         cursor: pointer;
         font-weight: 500;
         font-size: 16px;
         //padding: 10px 30px;
         //text-transform: uppercase;
         border-radius: 25px;
         //float: right;
         width: 100%;
         height: 25px;
         &:hover {
           background-color: #FFC457;
           color: #FFFFFF;
           border: 1px solid #EDA41E;
         }
       }
       .button-blue-authentication:disabled {
         //border: 1px solid #3DD8DB;
         background-color: #D4D7DA;
         cursor: default;
         color: #FFFFFF;
         //opacity: 0.4;
       }
     }
   }
 }
.userSettings-sectionTitle {
  font-size: 18px;
  font-weight: 500;
  //margin-bottom: 5px
}
.content-multi-factor-authentication {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #EDA41E;
  }
  .checkbox-multi-factor {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-right: 30px;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var( --generalIconFontActive) !important;
      border: 1px solid #6e7882;

    }
    .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
      border-color: var(--generalIconFontHover);
    }
    .ant-checkbox-inner:after {
      border-color: #ffffff !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #FFFFFF;
      position: absolute !important;
      display: table;
      color: #FFFFFF;
      border: none !important;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      top: 8px !important;
      left: 7px !important;
      font-size: 11px !important;
      font-family: 'smartbox_icons_v19' !important;
    }
    .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after {
      border-color: #D4D7DA !important;
    }
    .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
      border-color: var(--generalIconFontHover) !important;
    }
    .ant-checkbox-wrapper .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
      border-color: #D4D7DA !important;
      background-color: #FFFFFF !important;
      color: #ffffff !important;
      //opacity: 0.4;
    }
    .ant-checkbox-wrapper .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
      border-color: #D4D7DA !important;
      background-color: #FFF !important;
    }
    .ant-checkbox-wrapper .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after {
      border-color: #6E7882;
    }
    .ant-checkbox-disabled + span {
      color: #D4D7DA !important;
      cursor: default;
      //opacity: 0.4;
    }
    .ant-checkbox + span {
      padding-right: 0px;
      padding-left: 10px;
    }
    .ant-checkbox-wrapper .ant-checkbox-inner {
      border-radius: 0% !important;
    }
    .name-factor {
      font-size: 14px;
      font-weight: 400;
      font-family: Roboto;
      color: #000000;
    }
    .icon-v12-question {
      font-size: 18px;
    }
  }
  .content-button-reset-MFA {
    //width: 33%;
    padding-left: 30px !important;
    .button-blue-reset:disabled {
      border: 1px solid #6e7882;
      background-color: #6e7882;
      color: #FFFFFF;
      opacity: 0.4;
      cursor: not-allowed;
      &:hover {
        border: 1px solid #6e7882;
        background-color: #6e7882;
        color: #FFFFFF;
        opacity: 0.4;
      }
    }
    .button-blue-reset {
      border: 1px solid #6e7882;
      background-color: #FFFFFF;
      color: #6e7882;
      box-shadow: none;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      //padding: 10px 30px;
      //text-transform: uppercase;
      border-radius: 25px;
      padding: 3px;
      line-height: 18.75px;
      float: right;
      width: 170px;
      height: 25px;
      &:hover {
        background-color: #FFFFFF;
        color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
      }
      &:active {
        background-color: #FFFFFF;
        color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
      }
    }
  }
}
.userSettings-sectionTitlePassword {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px
}

.userSettings-sectionSubtitle {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px
}

.userSettings-formGroup {
  //margin-bottom: 25px;
  //width: 100%;
}

.userSettings-formGroup label {
  color: #555;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 20px;
}

.userSettings-formGroup input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
}

.userSettings-formGroup input:focus {
  outline: 2px solid #3DD8DB;
}

.userSettings-formGroup select {
  appearance: none;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  color: #555555;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
}

.userSettings-formGroup select:focus {
  outline: 2px solid #3DD8DB;
}

.userSettings-formGroupSide {
  //margin-bottom: 20px;
  //width: 100%;
}

.userSettings-formGroupSide label {
  color: #555;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 20px !important;
}

.userSettings-formGroupSide input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
}

.userSettings-formGroupSide input:focus {
  outline: 2px solid #EDA41E;
}
.userSettings-formGroupSide input:hover {
  //outline: 2px solid #EDA41E;
}
.userSettings-formGroupSide input:active {
  outline: 2px solid #EDA41E;
}
.userSettings-sectionButton {
  background-color: #FFFFFF;
  border: 1px solid #3DD8DB;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #3DD8DB;
  cursor: pointer;
  display: inline-block;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  margin: 10px 0 20px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
}

.userSettings-sectionButton:hover {
  background-color: #3DD8DB;
    color: #FFFFFF;
}

.userSettings-footer {
  align-items: center;
  //border-top: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  //padding: 20px;
}
#footerModal { 
  padding-top: 40px;
}

.userSettings-footerLink {
  color: #1DCBCF;
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
}

.userSettings-footerLink:hover {
  //font-style: italic;
}

.userSettings-footerButton {
  margin-bottom: 20px;
}

.userSettings-message {
  margin-top: 20px
}

.userSettings-loading {
  display: flex;
  justify-content: center
}
.information-app-user-settings {
  .ant-tooltip-arrow::before {
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
  }
  .ant-tooltip-inner {
    font-family: Roboto;
    color: #382e34;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
    padding: 20px;
    .header-authentication-info {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      .authentication-info {
        display: flex;
        align-items: center;
      }
      .icon-v12-question {
        font-size: 20px;
      }
      .text-header {
        font-size: 14px;
        font-weight: 700;
      }
      .text-close {
        font-size: 14px;
      }
    }
    .content-authentication-info {
      .text-information {
        font-size: 14px;
        font-weight: 400;
      }
      .text-link {
        font-weight: 700;
        font-size: 14px;
        color: #00d4d8;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .text-link-footer {
      font-size: 14px;
      font-weight: 400;
      color: #00d4d8;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 768px) {
  .userSettings-page {
    // padding: 30px 0px 0 !important;
  }
}
    @media screen and (min-width: 768px) {
  .userSettings-page {
    padding: 30px 0px 0px;
  }

  .userSettings-formGroup {
    //margin-right: 20px;
    //width: 410px;
  }

  .userSettings-formGroup select {
    width: 280px;
  }

  .userSettings-container {
    display: flex
  }

  .userSettings-container-password {
    .userSettings-formGroupSide input:hover {
      border: 1px solid #EDA41E;
      border-radius: 0px;
      outline: none;
    }
    .userSettings-formGroupSide input:focus {
      border: 1px solid #EDA41E;
      border-radius: 0px;
      outline: none;
    }
    .userSettings-formGroupSide input:active {
      border: 1px solid #EDA41E;
      border-radius: 0px;
      outline: none;
    }
  }

  .userSettings-formGroupSide {
    //margin-right: 20px;
    //width: 210px;
  }

  .userSettings-footer {
    flex-direction: row;
  }

  .userSettings-footerButton {
    margin-bottom: 0;
  }
}

.tooltip-over {
  .ant-tooltip-inner {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    .tooltip-wrapper {
      &__title {
        font-weight: 500;
        color: #000000;
        font-size: 14px;
      }
      &__item-green {
        color: #1DCBCF;
        i {
          margin-right: 7px;
        }
      }
      &__item-red {
        color: #ff0000;
        i {
          margin-right: 7px;
        }
      }
    }
  }
}
.information-authentication-app {
  .ant-tooltip-arrow::before {
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
  }
  .ant-tooltip-inner {
    font-family: Roboto;
    color: #382e34;
    background-color: #FFFFFF;
    border: 1px solid #382e34;
    padding: 20px;
    .header-authentication-info {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      .authentication-info {
        display: flex;
        align-items: center;
      }
      .icon-v12-question {
        font-size: 20px;
      }
      .text-header {
        font-size: 14px;
        font-weight: 700;
      }
      .text-close {
        font-size: 14px;
      }
    }
    .content-authentication-info {
      .text-information {
        font-size: 14px;
        font-weight: 400;
      }
      .text-link {
        font-weight: 700;
        font-size: 14px;
        color: #00d4d8;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .text-link-footer {
      font-size: 14px;
      font-weight: 400;
      color: #00d4d8;
      cursor: pointer;
    }
  }
}
