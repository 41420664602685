.collectionList__empty {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  text-align: center;
}

.collectionList__wrapperCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.collectionList__wrapperTable {
  margin-bottom: 20px;
  overflow-y: scroll;
  width: 100%;
}

.collectionList__wrapperTable table {
  width: 100%;
}

.collectionList__wrapperTable th {
  background-color: #FFFFFF;
  border-bottom: 3px solid #E6E8EA;
  color: #6E7882;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  text-transform: uppercase;
}

.collectionList__wrapperTable td {
  border-bottom: 1px solid #E6E8EA;
  color: #111C26;
  font-size: 14px;
  padding: 8px;
}

.collectionList__wrapperTable tr:last-of-type td {
  border-bottom: none;
  color: #111C26;
  font-size: 14px;
  padding: 8px;
}

.collectionList__wrapperTable tr:nth-child(even) td {
  background-color: #FFFFFF;
}

.collectionList__wrapperTable tr:nth-child(odd) td {
  background-color: #FBFBFB;
}

.collectionList__cellTitle {
  min-width: 140px;
  text-align: left;
}
.collectionList__cellSmall {
  width: 100px;
}
.collectionList__cellSmaller {
  width: 70px;
}

.collectionList__cellHigh {
  min-width: 70px;
  position: relative;
  text-align: center;
}

.collectionList__cellHigh::before {
  background-color: #F44336;
  border-radius: 50%;
  content: '';
  height: 10px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 10px;
}

.collectionList__cellMedium {
  min-width: 105px;
  position: relative;
  text-align: center;
}

.collectionList__cellMedium::before {
  background-color: #F0B400;
  border-radius: 50%;
  content: '';
  height: 10px;
  left: 5px;
  position: absolute;
  top: 10px;
  width: 10px;
}

.collectionList__cellLow {
  min-width: 70px;
  position: relative;
  text-align: center;
}

.collectionList__cellLow::before {
  background-color: #92C654;
  border-radius: 50%;
  content: '';
  height: 10px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 10px;
}
.actionsDotsCell {
  position: relative;
}
.actionsDotsCell .dropdown-menu {
  /*left: -90px;*/
  padding: 0;
}
.actionsDotsCell .dropdown-menu>li>a {padding: 10px 20px;}
.actionsDotsCell .dropdown-menu>li:hover a { color: white !important; background: #3DD8DB !important;}
.actionsDotsCell .dropdown-menu>li {border-bottom: 1px solid #cdd0d3;}
.actionsDotsCell .dropdown-menu>li:last-child {border-bottom: none;}

.actionsDotsCell .btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.actionsDotsCell .dropdown-toggle::after {
  display:none;
}
.actionsDotsCell button {
  background-color: transparent !important;
  border: none !important;
  color: #3DD8DB !important;
  font-size: 30px;
  line-height: 22px;
  outline: none;
  margin-top: -15px;
}

.dpiaDashboard-page .collectionList__wrapperTable tr:last-of-type td {
    background-color: transparent;
    height: 120px;
}

.dpiaDashboard-page .collectionList__wrapperTable {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .collectionList__wrapperCard {
    flex-direction: row;
  }

  .collectionList__wrapperCard .collectionCard__wrapper {
    border-bottom: none;
    flex-basis: 49%;
  }

  .collectionList__wrapperTable {
    overflow-y: auto;
  }
}

@media screen and (min-width: 1200px) {
  .collectionList__wrapperCard .collectionCard__wrapper {
    flex-basis: 32%;
  }
}