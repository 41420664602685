.terms__text {
  background-color: #F9F9FA;
  color: #43515E;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 10px 15px;
  text-transform: uppercase;
}

.terms__list {
  border-bottom: 1px solid #E5E6E7;
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px;
  padding: 10px 0;
}

.terms__list-item {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}