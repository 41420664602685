
.loginPage-page {
  min-height: 100%;
  display: flex;
  align-items:center;
  background: #ffffff url(../../assets/Background.svg) no-repeat center;
  background-size: cover;
  flex-direction: column;
}
.logo-osprey-header {
  width: 245px;
}
.loginPage-header {
  background-color: #535d66;
  margin-bottom: 60px;
  height: 60px;
  display: flex;
  padding-left: 0px;
}
.content-authentication-page {
  width: 100%;
}
.authentication-header {
  font-family: Roboto;
  font-weight: 400;
  color: #382e34;
  font-size: 29px;
  height: 30px;
  margin-bottom: 20px;
}
.authentication-header-successful {
  height: 60px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.authentication-header-title {
  font-family: Roboto;
  font-weight: 400;
  color: #382e34;
  font-size: 29px;
  margin-bottom: 10px;
}
.successful-header {
  font-family: Roboto;
  font-weight: 500;
  color: #00d4d8;
  font-size: 14px;
}
.radio-button-successful {
  color: #00d4d8;
  font-size: 20px;
}
.authentication-page {
  background-color: #FFFFFF;
  /*border-radius: 5px;*/
  height: 560px;
  max-width: 401px;
  margin: 0 auto 50px;
  padding: 55px 55px 40px 55px;
  -webkit-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  -moz-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  border: 1pt solid #382e34;

}
.authentication-page-loading {
  background-color: #FFFFFF;
  /*border-radius: 5px;*/
  height: 215px;
  max-width: 401px;
  margin: 0 auto 50px;
  padding: 55px 55px 40px 55px;
  -webkit-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  -moz-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  border: 1pt solid #382e34;

}
.authentication-page-loading-failed {
  height: 280px;
}
.authentication-header22 {
  display: flex;
  justify-content: center;
}
.content-loading-authentication {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 50px;
}
.content-success {
  margin-top: 20px;
}
.success-loading {
  color: #00d4d8;
  font-weight: 400;
  font-size: 14px;
}
.example-loading {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.failed-authentication {
  width: 290px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  color: #6e7882;
  height: 50px;
  align-items: flex-end;
}
.authentication-page-active {
  height: 473px;
}
.reset-code-page-active {
  height: 325px;
}
.email-address-authentication {
  margin-top: 48px;
  margin-bottom: 20px;
  height: 34px;
  flex-direction: column;
  display: flex;
}
.text-app {
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  color: #6E7882;
}
.text-app-email {
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  color: #382e34;
  margin-top: 10px;
}
.content-app-text-scan {
  height: 30px;
}
.content-text-codes {
  height: 140px;
  margin-bottom: 20px;
}
.text-app-codes {
  font-family: Roboto;
  font-weight: 300;
  color: #6e7882;
  font-size: 14px;
  opacity: 0.6;
  vertical-align: middle;
  horiz-align: center;
  line-height: 20px;
  letter-spacing: 0px;
}
.content-codes-list-app {
  height: 130px;
  max-height: 130px;
  overflow: auto;
  margin-bottom: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #6e7882;
  border: 1px solid #6e7882;
  padding: 10px 10px 10px 20px;
}
.columns-codes {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
.content-functionality-codes-app {
  height: 20px;
  display: flex;
  justify-content: space-between;
  color: #00d4d8;
  font-weight: 500;
  font-family: Roboto;
}
.icon-functionality {
  font-size: 20px;
}
.content-app-QRscan {
  height: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
}
.content-image-scan {
  display: flex;
  align-content: center;
  margin-bottom: 20px;
  justify-content: center;
}
.content-enter-passcode {
  height: 40px;
  margin-bottom: 20px;
}
.new-content-login-page {
  width: 100%;
}
.content-tooltip-login-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text-information {
  font-size: 14px;
  font-weight: 400;
  margin-right: 3px;
}
.more-info-link {
  font-weight: 700;
  font-size: 14px;
  color: #00d4d8;
  cursor: pointer;
  text-decoration: underline;
  width: 65px;
  margin-left: 3px;
}
.text-app-digit {
  height: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}
.image-scan-display {
  width: 120px;
  height: 120px;
}
.input-authentication {
  height: 40px;
  width: 100%;
  border-radius: 0px !important;
  border: 1px solid #382e34;
  font-weight: 400;
  color: #382e34;
}
.input-authentication:focus {
  outline: 2px solid #9AE0FC;
  border: 2px solid #9AE0FC;
}
.input-authentication:hover {
  outline: 2px solid #9AE0FC;
  border: 2px solid #9AE0FC;
}
.image-help {
  font-size: 20px;
  margin-left: 5px;
  font-weight: 500;
  color: #6e7882;
}
.text-app2 {
  padding-left: 15px;
}
.loginPage-boxTry {
  height: 570px !important;
}
.loginPage-box {
  background-color: #FFFFFF;
  /*border-radius: 5px;*/
  height: 500px;
  max-width: 401px;
  margin: 0 auto 50px;
  padding: 55px 55px 25px 55px;
  -webkit-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  -moz-box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  box-shadow: 0 0 24px 0 rgba(0,0,10,0.1);
  border: 1pt solid #382e34;
}

.loginPage-form {
  /*padding: 0 10px;*/
  width: 290px;
}

.loginPage-container {
  /* display: flex;
  flex-direction: column; */
  margin-top: 20px;
}

.loginPage-formGroup {
  /*width: 100%;*/
}
.content-unsuccessful-account {
  display: flex;
  margin-bottom: 20px;
}
.text-unsuccessful-account {
  font-family: Roboto;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #ff0000;
}
.account-process-error {
  font-size: 16px;
  color: #ff0000;
  margin-right: 10px;
}
.loginPage-formGroup label {
  color: #6e7882;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0 10px;
  text-transform: capitalize;
  font-family: Roboto;
}

.loginPage-formGroup input {
  border: 2px solid #CCCCCC;
  border-radius: 0px;
  box-sizing: border-box;
  color: #000000 !important;
  font-size: 16px;
  opacity: 0.8;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  font-family: Roboto;
  height: 40px;
}

.loginPage-formGroup input:focus {
  outline: 2px solid #9AE0FC;
  border: 2px solid #9AE0FC;
}
.loginPage-formGroup input:hover {
  outline: 2px solid #9AE0FC;
  border: 2px solid #9AE0FC;
}

.loginPage-formGroup:first-of-type {
  /*margin: 0 20px 20px 0;*/
}

.loginPage-footer {
  margin-top: 40px;
}
.display-privacy-policy {
  display: flex;
  justify-content: center;
}
.new-login-reset {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-login-reset {
  border: 1px solid #FFFFFF !important;
  color: #3DD8DB !important;
  background-color: #FFFFFF !important;
  box-shadow: none !important;
  width: 110px !important;
}
  .input-login-reset:hover {
    font-size: 15px !important;
    color: #00d4d8 !important;
    font-weight: 500 !important;
  }
.term-of-service-login {
  /*padding-top: 5px;*/
  text-decoration: none !important;
  padding-left: 5px;
  color: #6e7882 !important;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 300;
  opacity: 0.8;
}
.term-of-service-login:hover {
  font-family: Roboto;
  font-size: 14px;
  opacity: 1;
  font-weight: 500;
  font-style: normal !important;
}
.open-privacy {
  color: #6e7882;
  cursor: pointer;
  opacity: 0.8;
  font-family: Roboto;
  font-weight: 300;
  font-size: 14px;
  /*text-decoration: underline;*/
}
.open-privacy:hover {
  /*font-style: italic;*/
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
}

.loginPage-footerForm {
  align-items: center;
  display: flex;
  justify-content: center;
}
.forget-password-footer {
  margin-bottom: 40px;
}
.input-login {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 30px !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.loginPage-footerForm a {
  color: #00D4D8;
  font-size: 14px;
  text-decoration: none;
  font-weight: 300;
  font-family: Roboto;
}

.loginPage-footerForm a:hover {
  /*text-decoration: underline;*/
  font-family: Roboto;
  font-size: 14px;
  color: #00d4d8;
  font-weight: 500;
}

.loginPage-footerForm input {
  /*margin-left: auto*/
}

.loginPage-message {
  margin-top: 20px
}

.loginPage-loading {
  display: flex;
  justify-content: center
}

@media screen and (min-width: 768px) {
  .loginPage-box {
    padding: 55px 55px 25px 55px;
  }
  .display-privacy-policy {
    display: flex;
    justify-content: center;
  }
  .term-of-service-login {
    /*padding-top: 5px;*/
    opacity: 0.8;
  }
  .term-of-service-login:hover {
    opacity: 1;
    font-style: normal !important;
  }
  .open-privacy {
    color: #6e7882;
    cursor: pointer;
    opacity: 0.8;
    /*text-decoration: underline;*/
  }
  .open-privacy:hover {
    /*font-style: italic;*/
    opacity: 1;
  }

  .loginPage-form {
    /*padding: 0px 55px*/
  }

  /*.loginPage-container {
    flex-direction: row 
  }*/

  .loginPage-formGroup {
    /*width: 300px*/
  }

  .loginPage-formGroup:first-of-type {
    /*margin: 0 20px 0 0;*/
  }

  .loginPage-footer {
    margin-top: 40px
  }
}