.documentListItem__metadataField{
  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: 400px; 
  height: 1.2em; 
  white-space: nowrap;
  text-align: center;
}

.documentListItem__docline{
  width:100%;
}


.documentListItem__remove div{
  padding: 5px 15px 5px 8px;
  border-radius: 15px;
  background-color: #DCDCDC;
  border: 1px solid #737E89;
  width: 27px;
  font-weight: bold;
  text-align: center;
}

.documentListItem__remove{
  text-align: center;
}