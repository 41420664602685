.sa_page_extern_feedback {
  width: 100%;
  height: auto;
  //background: url("../../../assets/background.png") no-repeat top right #efefef;
  background-color: #A6ACB230;
  min-height: 100%;
  display: inline-block;
  -webkit-align-items: center;
  align-items: center;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .externFile-page-feedback-expired {
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 40px;
    .page-feedback-expired {
      background-color: #FFFFFF;
      border-radius: 0px;
      position: relative;
      padding: 50px;
      margin-top: 20px;
      height: 600px;
      .header-feedback-expired {
        display: flex;
        padding-bottom: 40px;
        .title-header {
          color: #000000;
          font-weight: bold;
          font-size: 30px;
        }
      }
      .feedback-expired-contain {
        display: flex;
        padding-top: 40px;
        .feedback-expired-contain-description {
          color: #000000;
          font-weight: 500;
          font-size: 26px;
        }
      }
    }
  }
  .externFile-page-feedback {
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 40px;
    .closed-extern-feedback {
      background-color: #FFFFFF;
      margin-top: 40px;
      height: 500px;
      font-family: Roboto;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      .message-closed-extern-feedback {
        color: #000000;
        font-weight: bold;
        font-size: 30px;
      }
      .message-closed-extern-feedback-update {
        font-weight: 700 !important;
        color: #000000;
        font-size: 30px;
      }
      .message-closed-data-confirmed {
        font-weight: 400 !important;
        color: #000000;
        font-size: 30px;
      }
    }
    .externFile-box-feedback {
      background-color: #FFFFFF;
      border-radius: 0px;
      //box-shadow: 0 2px 4px 0 #DCDCDC;
      position: relative;
      padding: 50px;
      margin-top: 20px;

      .externFile-header-feedback {
        //padding: 20px 20px 1px 20px;
        //border-bottom: 1px solid #E6E6E6;
         .externFile-header-feedback {
           margin-bottom: 60px;
           .externFile-title-feedback {
             font-size: 20px;
             font-weight: 400;
             margin-bottom: 10px;
             font-family: Roboto;
           }
           .externFile-title-feedback2 {
             font-size: 16px;
             font-weight: 400;
             margin-bottom: 0px;
             font-family: Roboto;
           }
         }
        .externFile-header__top__feedback {
          display: flex;
          //align-items: center;
          justify-content: flex-start;
          flex-direction: column;
        }

      }
      .externFile-textForm {
        padding: 20px;
        border-bottom: 1px solid #E6E6E6;

        .externFile-text {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
          font-family: Roboto;
        }
      }

      .titleUpload {
        margin-left: 2%;
      }

      .file-upload-container-feedback {
        margin: 0% 2% 2% 2%;
        width: 94%;
        padding-top: 20px;
        .new-tracker-radio-feedback-update {
          margin-top: 30px;
          margin-bottom: 20px;
          .ant-radio-group {
            display: flex;
            flex-direction: column;
              .true-keep {
                font-family: 'Roboto';
                font-style: normal;
                font-size: 14px;
                font-weight: 500;
                letter-spacing:normal;
                color:#000;
                margin-left: 5px;
            }
            .false-keep {
              font-family: 'Roboto';
              font-style: normal;
              font-size: 14px;
              font-weight: 500;
              letter-spacing:normal;
              color:#000;
              margin-left: 5px;
            }
            .ant-radio-wrapper {
              margin-bottom: 20px;
              .ant-radio:hover .ant-radio-inner {
                border-color:#000;
              }
              .ant-radio-checked{
                .ant-radio-inner {
                  border-color:#000;
                  background-color: #000;
                }
                .ant-radio-checked::after {
                  border: 1px solid #ffffff;
                }
                .ant-radio-inner::after {
                  background-color: #FFFFFF;
                }

              }
            }
          }
        }
        .content-choose-keep {
          .question-keep-data {
            color: #6e7882;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
          }
        }
        .tracker-radio-feedback {
          height: 100%;
          margin-top: 30px;
          margin-bottom: 20px;
        }
        .new-tracker-radio-feedback {
          padding-top: 20px;
          .ant-radio-checked .ant-radio-inner {
            border-color: #6e7882;
            background-color: #3DD8DB;
          }
          .true-keep {
            padding-right: 15px;
          }
        .ant-radio-checked::after {
          border: 1px solid #ffffff;
        }
          .ant-radio-inner::after {
            background-color: #FFFFFF;
          }
          .ant-radio-inner {
            border-color: #6e7882;
          }
          .ant-radio-wrapper {
            color: #6e7882;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .extern-file-feedback {
          display: inline-block;
          margin-bottom: 20px;
         .ant-upload-list-item-info {
            display: flex;
            align-items: center;
          }
        }

      }
      .content-feedback-upload-document {
        display: grid;
        justify-content: center;
        margin-bottom: 4%;
        .document-feedback {
          position: relative;
          display: flex;
          justify-content: center;
           img {
             width: 90px;
           }
          span {
            font-size: 36px;
            position: absolute;
            left: 76px;
            top: -6px;
          }
          .icon-v10-tool-download-neg:before {
            background-color: #FFFFFF;
            border-radius: 50%;
          }
        }
        .name-document-feedback {
          display: flex;
          justify-content: center;
          .name-document {
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            //padding-left: 20px;
            padding-top: 10px;
          }
        }
      }
      .footer-extern-file-feedback {
        //justify-content: space-between;
        //margin-right: 20px;
        align-items: center;
        padding-top: 3%;
      }
      .row {
        justify-content: flex-end;
        flex-wrap: unset;
        margin-right: unset;
        margin-left: unset;
      }
      .content-message-hyperlink {
        //margin-left: 20px;
        margin:15px 0px 40px 0px;
        display: flex;
        justify-content: center;
        .text-information {
          font-size: 14px;
          font-weight: 400;
          font-family: Roboto;
          color: #A6ACB2;
          margin-right: 5px;
        }
        .more-info-link {
          font-weight: 700;
          font-size: 14px;
          font-family: Roboto;
          color: #a7adb3;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .externFile-title-feedback {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 60px;
    font-family: Roboto;
    //padding-left: 3px;
  }
  .button-blue {
    width: 100% !important;
    height: 2.5rem;
    //text-transform: capitalize;
    font-size: 14px;
  }
  .button-download-feedback {
    margin: 2% 2% 2% 3%;
    display: flex;
    justify-content: center;
    .button-download-data {
      width: 209px !important;
      height: 30px !important;
      align-items: center;
      justify-content: center;
      display: flex;
      color: #46c9f2;
      border: 1px solid #46c9f2;
      font-family: Roboto;
      letter-spacing: 1.2px;
      font-weight: 500;
      font-size: 14px;
    }
    .button-download-data:hover {
      color: #ffffff;
      background-color: #46c9f2;
      border: 1px solid #46c9f2;
    }
  }
  .button-submit-feedback {
    //margin-top: 10px;
    .button-submit {
      width: 550px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      border: 1px solid #000;
      background-color: #000;
      font-family: Roboto;
      letter-spacing: 0.5px;
      font-size: 18px;
      font-weight: 500;
      text-transform: none;
    }
    .btn-submit-update {
      width: 550px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Roboto;
      letter-spacing: 0.5px;
      font-size: 18px;
      font-weight: 500;
      text-transform: none;
      color: #000;
      border: 1px solid #FFFFFF;
      background-color: #FFFFFF;
      &:hover {
        border: 1px solid #FFFFFF;
        background-color: #FFFFFF;
      }
    }
    .button-submit:hover {
      color: #000;
      background-color: #ffffff;
      border: 1px solid #000;
    }
    .button-submit:disabled {
      background-color: #000;
      color: #ffffff;
      opacity: 0.1;
    }
  }

}

.preview-modal {
  width: 50% !important;
  height: 99%;

  .ant-modal-content {
    height: 100%;

    .ant-modal-close-x {
      width: 35px;
      height: 40px;
      line-height: 35px;
    }

    .ant-modal-body {
      height: 100%;
      padding-top: 30px;

      .custom-modal-body {
        height: 100%;

        #viewer {
          height: 100% !important;
        }
      }
    }
  }


}