.privacyCollectionCreate__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyCollectionCreate__link {
  color: #3DD8DB;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
  text-decoration: underline;
}

.privacyCollectionCreate__link:hover {
  font-style: italic;
}

.privacyCollectionCreate__loading {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.privacyCollectionCreate__error {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  text-align: center;
}

.privacyCollectionCreate__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  position: relative;
}

.privacyCollectionCreate__boxHeader {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.privacyCollectionCreate__button-addAlert {
  padding: 20px 20px 10px;
}

.privacyCollectionCreate__boxTitle {
  color: #162737;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacyCollectionCreate__boxText {
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
}

.privacyCollectionCreate__boxLink {
  border-bottom: 1px solid #737E89;
  color: #162737;
  font-weight: 500;
}

.privacyCollectionCreate__boxHeader .saveButtons__wrapper {
  margin-top: 20px;
}

.privacyCollectionCreate__content {
  display: flex;
  flex-direction: column;
}

.privacyCollectionCreate__form {
  flex-basis: 50%;
}

.privacyCollectionCreate__formHeader {
  padding: 20px 20px 0;
}

.privacyCollectionCreate__formTitle {
  color: #162737;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.privacyCollectionCreate__formSubtitle {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacyCollectionCreate__formFields {
  border-bottom: 1px solid #D6D3D3;
  padding: 20px;
}

.privacyCollectionCreate__formFields:last-of-type {
  border-bottom: none;
}

.privacyCollectionCreate__formInfo {
  color: #737E89;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.privacyCollectionCreate__formFields .privacyCollectionCreate__formGroup {
  margin-bottom: 20px;
}

.privacyCollectionCreate__formFields .privacyCollectionCreate__formGroup:last-of-type {
  margin-bottom: 0;
}

.privacyCollectionCreate__formFields .privacyCollectionCreate__addButton {
  margin-top: 20px;
}

.privacyCollectionCreate__formGroup label {
  color: #162737;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 5px;
}

.privacyCollectionCreate__formHalf {
  display: flex;
  justify-content: space-between;
}

.privacyCollectionCreate__formHalf div {
  flex-basis: 48%;
}

.privacyCollectionCreate__scan {
  border-top: 2px solid #D6D3D3;
}

.privacyCollectionCreate__scanHeader {
  border-bottom: 1px solid #D6D3D3;
  height: 66px;
}

.privacyCollectionCreate__scanHeaderWrapper {
  align-items: center;
  display: flex;
  height: 61px;
  margin: 5px;
}

.privacyCollectionCreate__addButton {
  border-bottom: 1px solid #A6BACC;
  color: #111C26;
  cursor: pointer;
  font-size: 14px;
  padding: 0 0 2px 12px;
  position: relative;
}

.privacyCollectionCreate__addButton::before {
  content: '+';
  color: #111C26;
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 0;
}

.privacyCollectionCreate__scan .privacyCollectionCreate__addButton {
  margin: 20px 20px 10px;
}

.privacyCollectionCreate__params {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.privacyCollectionCreate__params:last-of-type {
  margin-bottom: 10px;
}

.privacyCollectionCreate__formGroup input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 40px;
  padding: 10px;
  width: 100%;
}

.privacyCollectionCreate__formGroup input:focus {
  outline: 2px solid #3DD8DB;
}

.privacyCollectionCreate__formGroup select {
  appearance: none;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  color: #555555;
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
}

.privacyCollectionCreate__formGroup select:focus {
  outline: 2px solid #3DD8DB;
}

.privacyCollectionCreate__formGroup textarea {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 100px;
  padding: 10px;
  width: 100%;
}

.privacyCollectionCreate__formGroup textarea:focus {
  outline: 2px solid #3DD8DB;
}

.privacyCollectionCreate__footer {
  border-top: 1px solid #D6D3D3;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.privacyCollectionCreate__loading-container {
  margin-left: 20px;
}

@media screen and (min-width: 768px) {
  .privacyCollectionCreate__boxHeader {
    flex-direction: row;
  }

  .privacyCollectionCreate__boxHeader .saveButtons__wrapper {
    margin-top: 0;
  }

  .privacyCollectionCreate__content {
    flex-direction: row;
  }

  .privacyCollectionCreate__form {
    border-right: 2px solid #D6D3D3;
  }

  .privacyCollectionCreate__scan {
    border-top: none;
    width: 50%;
  }

  .privacyCollectionCreate__footer {
    justify-content: flex-end;
  }
}