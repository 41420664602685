.sign-up-page {
    background: url(../../assets/Gravicus_Smartbox_BG.svg) no-repeat top right #efefef;
    min-height: 100%;
    display: flex;
    align-items: center;
}

.new-sign-box {
  background-size: cover;
}
.sign-up-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  height: auto;
  max-width: 700px;
  margin: 0 auto 50px;
  padding: 20px 10px 15px;
  width: 96%;
  -webkit-box-shadow: 0 0 24px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 24px 0 rgba(0,0,0,0.1);
}

.sign-up-header {
    /* border-bottom: 1px solid #EAEAEA; */
    padding: 0 30px 20px;
    position: relative;
    margin: 0 -20px;
}

.sign-up-header h1 {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  font-size: 18px;
  left: 50%;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: -80px;
  transform: translateX(-50%);
  width: 350px;
}

.sign-up-header h2 {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;
}

.sign-up-header a {
  color: #3DD8DB;
  text-decoration: underline;
}

.sign-up-header a:hover {
  font-style: italic;
}

.new-sign-up-page {
  background-size: cover;
}

.sign-up-footer {
  border-top: 1px solid #eeeeee;
  margin: 0 -20px;
  padding: 20px 30px 0;
}

.sign-up-container {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.sign-up-form-group {
  width: 100%;
}

.sign-up-form-group:first-of-type {
  margin: 0 20px 20px 0;
}

.sign-up-form-group label {
  color: #555;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.sign-up-form-group input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
}

.sign-up-form-group input:focus {
  outline: 2px solid #3DD8DB;
}

.sign-up-form-group select {
  appearance: none;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  color: #555555;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
}

.sign-up-form-group select:focus {
  outline: 2px solid #3DD8DB;
}

.sign-up-required {
  color: #3DD8DB;
  display: inline-block;
  margin-left: 5px;
  width: auto;
  border: none;
}

.signUp-group {
  align-items: center;
  display: flex;
  margin: 20px 10px;
}

.signUp-group span {
  color: #555555;
  font-size: 14px;
  margin-left: 10px;
}

.sign-up-checkbox {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  display: inline-block;
  height: 26px;
  position: relative;
  width: 26px
}

.sign-up-checkbox-label {
  border-radius: 5px;
  cursor: pointer;
  display: block;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: all .5s ease;
  width: 18px;
  z-index: 1;
}

.sign-up-checkbox-label--checked {
  border-radius: 5px;
  cursor: pointer;
  display: block;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: all .5s ease;
  width: 18px;
  z-index: 1;
  background-color: #3DD8DB
}

.sign-up-link {
  color: #3DD8DB;
  cursor: pointer;
  text-decoration: underline;
}

.sign-up-link:hover {
  font-style: italic;
}

.sign-up-submit {
  display: block;
  font-size: 18px !important;
  margin: 0 auto;
  width: 140px;
}
.sign-up-message {
  margin: 0 0 20px
}
.sign-up-loading {
  display: flex;
  justify-content: center
}
.signUp-back {
  float:left;
  margin:-40px 0 0 25px;
}
.newSignUp-back {
  float:left;
  margin:-56px 0 0 25px;
}

.signUp-back a {
  text-decoration: none;
  font-weight: bold;
}
.signUp-back a:hover {
  text-decoration: underline;
  font-style: normal;
}
.newFormHeader-logo {
  display: flex;
  justify-content: center;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .sign-up-box {
    padding: 25px 10px 5px
  }

  .sign-up-header {
    text-align: left;
  }
  .newFormHeader-logo {
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .sign-up-header h1 {
    font-size: 22px;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    width: 500px;
  }

  .sign-up-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .sign-up-form-group {
    width: 300px;
  }

  .sign-up-form-group:first-of-type {
    margin: 0 20px 0 0;
  }

  .sign-up-submit {
    height: 50px;
  }
}