.newQuestionnaireQuestion__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}

.newQuestionnaireQuestion__form {
  border-top: 1px solid #DCDCDC;
  padding: 20px 20px 0;
  position: relative;
}

.newQuestionnaireQuestion__wrapper {
  box-shadow: 0 2px 4px 0 #DCDCDC;
}

.newQuestionnaireQuestion__header {
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: flex;
  justify-content: space-between;
  margin-right: 70px;
  min-height: 70px;
}

.newQuestionnaireQuestion__headerOpened {
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-right: 70px;
}

.newQuestionnaireQuestion__title {
  cursor: pointer;
  flex: 1;
  font-weight: bold;
  padding: 20px;
}

.newQuestionnaireQuestion__actionButtons {
  display: flex;
  margin-right: 10px;
}

.newQuestionnaireQuestion__removeButtonDisabled {
  border-radius: 12px;
  border: 2px solid;
  color: #EEEEEE;
  cursor: not-allowed;
  font-weight: bold;
  height: 20px;
  text-align: center;
  width: 20px;
  position: relative;
  &:after {
    content: 'x';
    font-size: 14px;
    position: absolute;
    left: 4px;
  }
}

.newQuestionnaireQuestion__removeButton {
  border-radius: 12px;
  border: 2px solid;
  color: #DCDCDC;
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  text-align: center;
  width: 20px;
  position: relative;
  &::after {
    content: 'x';
    font-size: 14px;
    position: absolute;
    left: 4px;
  }
}

.newQuestionnaireQuestion__addButton {
  border-radius: 12px;
  border: 2px solid #3DD8DB;
  background-color: #3DD8DB;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-left: 5px;
  text-align: center;
  width: 20px;
  position: relative;
  &::after {
    content: '+';
    font-size: 18px;
    left: 3px;
    top: -2px;
    position: absolute;
  }
}

.newQuestionnaireQuestion__selectButton {
  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin-left: 5px;
  width: 20px;
}

.newQuestionnaireQuestion__selectLabelSelected {
  background-color: #3DD8DB;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 14px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 14px;
  z-index: 1;
}

.newQuestionnaireQuestion__selectLabel {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.newQuestionnaireQuestion__formGroup {
  padding-bottom: 20px;
  width: 100%;
  label {
    color: #AAAAAA;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.newQuestionnaireQuestion__requiredField::after {
  content: "*";
  color: #3DD8DB;
  margin-left: 5px;
}

.newQuestionnaireQuestion__formGroup {
  input {
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    color: #555555;
    font-size: 18px;
    font-weight: 300;
    padding: 10px;
    width: 100%;
    &:focus {
      outline: 2px solid #3DD8DB;
    }
    &#weight {
      width: 60px;
      text-align: center;
    }
  }
  select {
    appearance: none;
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    color: #555555;
    font-size: 16px;
    height: 43px;
    outline: none;
    padding: 10px 25px 10px 10px;
    width: 100%;
    &:focus {
      outline: 2px solid #3DD8DB;
    }
  }
  textarea {
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    color: #555555;
    font-size: 18px;
    font-weight: 300;
    height: 100px;
    padding: 10px;
    width: 100%;
    &:focus {
      outline: 2px solid #3DD8DB;
    }
  }
}

@media screen and (min-width: 768px) {
  .newQuestionnaireQuestion__formGroup select {
    width: 260px;
  }
  .newQuestionnaireQuestion__formGroup--sideBySide {
    display: flex;
    .newQuestionnaireQuestion__formGroup {
      width: 300px;
    }
  }
  .newQuestionnaireQuestion__copyButton, .newQuestionnaireQuestion__addButton, .newQuestionnaireQuestion__selectButton {
    margin-left: 10px;
  }
}
@media (max-width: 880px) and (min-width: 767px) {
  .newQuestionnaireQuestion__formGroup select {
    width: 210px;
  }
  .multiselect dt { 
    width: 210px;
  }
  #formInputAlias {
    font-size: 13px;
  }
  .multiSelectOpened dt { 
    width: 220px;
  }
  .multiSelectOpened dd .multiSelect__list { 
    width: 220px;
  }
}