.dpiaDashboard__lockedProcess-info {
  align-items: center;
  background-color: #FCFCFD;
  border-bottom: 1px solid #D6D3D3;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px 20px 20px;
  position: relative;
> div {
  min-width: 165px;
  text-align: right;
  margin-right: 32px;
}
.iconEditDisabled {
  cursor: pointer;
  margin-top: -10px;
  position: absolute;
  right: 30px;
  top: 50%;
}
p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.25;
  padding-right: 20px;
}
span {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.25;
}
.iconEditDisabled {
  cursor: default;
}
}

.dpiaDashboard__lockedProcess-recurring {
  margin-top: 5px;
}