.leftNavigation{
  /*display: flex;
  position: fixed;
  //width: 17%;
  transition: width 1.5s, height 1.5s;
  padding-left: 0px;
  overflow-y: auto;
  margin-top: 60px;
  padding-left: 10px;
  max-height: 90%;
  z-index: 1;*/
  border-right: 1px solid #a6acb2;
  background-color: #fafafa;
  justify-content: space-between;
  position: fixed;
  width: 300px !important;
  padding: 0px 0px 10px;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  //box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
  z-index: 2;
  overflow-y: auto;
  margin-top: 100px;
  overflow-x: hidden;
  height: 94vh !important;
  .collapseWrapper {
      position: relative;
      top: 17px;
      left: 275px;
      padding: 5px;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
    .icon-v16-arrow-medium {
      color:#6e7882;
      font-size: 20px;
      transform:rotate(90deg);
      width: 20px;
      height: 20px;
      cursor:pointer;
      z-index: 5;
      float:right
    }
  }
  .rst__rowLabel{
    padding-right: 0px;
  }
  .rst__tree {
    min-height: 650px;
    margin-top: -75px;
    margin-left: -25px;
    /*margin-left: 0px !important;*/
    margin-bottom: 45px;
    overflow-x:auto;
    &::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
    }

    &::-webkit-scrollbar{
      height: 5px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #d9d9d9;
    }

    .rst__node:nth-of-type(2){
      margin-top: 20px;
    }
    .rst__node {
      height: auto!important;
      padding-top: 0px;
      padding-bottom: 16px;
    }
  }
  .link-to-home {
    margin: 16px 0px;
    text-align: left;
    width: 100%;
    cursor: pointer;
    font-weight: 500;
    .icon-v11-home {
      font-size: 20px;
      padding-right: 7px;
    }
  }
}

.leftNavigation .rst__rowWrapper{
  //width: 0px;
  padding: 0px 10px 10px 0;
}
.leftNavigation1::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.leftNavigation::-webkit-scrollbar
{
  width: 5px;
  background-color: #F5F5F5;
}

.leftNavigation::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d9d9d9;
}

.leftNavigation2:hover{
  width: 660px;
  background: #f1f1f1;
  z-index: 3;
  overflow: overlay;
  background: #f1f1f1;
  border: 1px solid #ced2d5;
  border-radius: 4px;

}
.leftNavigation .list-group-item{
  background: none!important;
  padding: 5px 10px !important;

  .icon-v14-options-small {
    display:none !important;
  }
}

.leftNavigation .rst__rowContents{
  background: none !important;
  width: 0px;
  min-width: 0px;
}
.leftNavigation .rst__moveHandle, .leftNavigation .rst__loadingHandle{
  /*background: url("/static/media/smart-folder-open-paret-small.90b5afb1.svg") no-repeat !important;*/
  border: none!important;
  box-shadow:none!important;
  margin: -2px 0px 0px;
  cursor: move;
  width: 24px;
}
.leftNavigation .rst__moveHandle, .leftNavigation .folder-icon-close-empty {
  //background: url("../../../assets/smart-folder-big.png");
  background: url("../../../assets/images/Folder empty 80x80.png");
  background-size: contain;
  width: 20px;
  height: 20px;
  background-position: top;
  background-repeat: no-repeat;
  margin-right: 5px;
  float:left;
}
.leftNavigation .has-empty .rst__moveHandle, .leftNavigation .folder-icon-close-empty {
  background: url("../../../assets/images/Folder empty 80x80.png");
  background-size: contain;
  width: 20px;
  height: 20px;
  background-position: top;
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-top: 5px;
  float:left;
}
.leftNavigation .rst__moveHandle, .leftNavigation .folder-icon-close {
  //background: url("../../../assets/smart-folder-big.png");
  background: url("../../../assets/images/Folder 80x80.png");
  background-size: contain;
  width: 20px;
  height: 20px;
  background-position: top;
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-top: 5px;
  float:left;
}
.leftNavigation .has-children .rst__moveHandle, .leftNavigation .folder-icon-open {
  //background: url("../../../assets/smart-folder-open.png");
  background: url("../../../assets/images/Folder open 80x80.png");
  background-size: contain;
  width: 20px;
  height: 20px;
  background-position: top;
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-top: 5px;
  float:left;
}

.leftNavigation .rst__collapseButton , .leftNavigation .rst__collapseButton:hover {
  display: none;
}

.leftNavigation .rst__rowTitle .list-group-horizontal{
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  height: 30px;
  align-content: center;
}

.leftNavigation .rst__rowTitle {
  margin-left: 0 !important;
  font-weight: normal !important;
}
.leftNavigation .rst__rowTitle .list-group-horizontal.drop-active {
  border-radius: 4px;
  border: solid 1px #9ae0ff;
  background-color: #e5f7ff;
}
.leftNavigation .rst__rowTitle .list-group-horizontal .list-group-item{
  padding:0px;
  border:none;
  //width: 285px;
  .tree-icon-close {
    opacity: 0.6;
  }
  .tree-icon-error {
    opacity: 0.4;
  }
  .tree-icon {
    display: inline;
    float:left;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    /*margin-top: -2px;*/
    cursor: pointer;

    &.icon-before-rename {
      margin-top: 10px;
    }
  }
  .node-active-ellipsis {
    border: 1px solid #6E7882;
    border-radius: 10%;
  }
  .node-title {
    display: inline;
    float:left;
    overflow: hidden;
    cursor: pointer;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    font-size: 14px;
    font-weight: 300;
    font-family: Roboto;

    max-width: 200px;
    margin-right: 10px;
    /*padding-top: 2px;*/
    height: 20px;

    span {
      display:inline-block;
      line-height: 20px;
      color: #382e34 !important;
      letter-spacing: 0.2px !important;

      &.node-with-error {
        width: 80%;
        overflow:hidden;
      }
    }
    /*&.active {
      font-weight: bold;
    }*/
    .icon-v12-process-error {
      float: right;
      /*margin-right: 10px;*/
      color: #ff0000 !important;
    }
  }
  .icon-v14-options-small {
    font-size: 20px;
    padding: 0;
    margin: 0;
    margin-top: -2px;
    display: inline-block;
    &:before {
      margin: 0;
    }
  }
  .icon-v14-options-small-active {
    font-size: 20px;
    padding: 0;
    margin: 0;
    margin-top: -2px;
    display: inline-block;
    &:before {
      margin: 0;
    }
  }
  .ant-dropdown-open {
    //border: 1px solid #6E7882;
    //padding: 1px 3px !important;
  }
  .anticon-more {
    padding: 2px 4px;
    //border: 2px solid #ccc;
    border-radius: 5px;
    display: inline;
    float: right;
    cursor: pointer !important;
  }

  .tree-rename {
    display: inline-block;
    position: absolute;
    right: 23%;
    top: 1px;
    width: 77%;

    & + i.anticon-more {
      padding-top: 15px !important;
    }

    .rename-folder-tree-input {
      //margin-top: 10px;
      height: 28px;

      &.invalid {
        border: 1px solid red;
      }
    }
  }
}

.leftNavigation .rst__expandButton , .leftNavigation .rst__expandButton:hover{
  //background: url("/static/media/smart-folder-open-paret-small.90b5afb1.svg") !important;
  background: none!important;
  background-repeat: no-repeat!important;
  background-size: auto!important;
  backdrop-filter: none;
  border:none!important;
  box-shadow: none!important;
  width: 50px;
  height: 30px;
  border-radius: 0px;
}
.rst__lineHalfHorizontalRight::before{
  background: none!important;
}
.rst__lineHalfHorizontalRight::after{
  background: none!important;
}
.rst__lineChildren::before{
  background: none!important;
}
.rst__lineChildren::after {
  background: none !important;
}
.rst__lineFullVertical::before{
  background: none!important;
}
.rst__lineFullVertical::after {
  background: none !important;
}

.rst__rowContents {
  padding:0px!important;
  //display: block;
  border: none!important;
  box-shadow: none!important;
}
.rstcustom__lineBlock{
  background: url("../../../assets/smart-folder-open-paret-small.svg") no-repeat !important;
  background-color: #00B9BD;
  height: 20px;
}
/*.rst {
  &__moveHandle {
    display: none;
  }
}*/

.left-tree-item-menu {
  padding: 0px !important;
  border: 2px solid rgba(110,120,130,0.29);
  .dropdown-navigation-tree {

  }
  .arrow-up-dropdown {
    border: solid #FFFFFF;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    position: absolute;
    top: -2px;
    left: 67px;
  }
  .ant-dropdown-menu-item {
    padding-top: 1px;
    padding-bottom: 1px;
    span {
      padding: 4px 0px;
    }
  }
  .separator {
    line-height: 1px !important;
    height: 1px !important;
    background-color: #aaaaaa;
    padding: 0;
    &:hover {
      background-color: #aaaaaa;
    }
  }
}
.leftNavigationCollapsed {
  
  height: 94vh;
  border-right: 1px solid #a6acb2;
  background-color: #fafafa;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
  position: fixed;
  margin-top: 100px;
  z-index: 5;
  .icon-v16-arrow-medium {
    color:#6e7882;
    margin-top: 11px;
    transform: rotate(270deg);
    cursor:pointer;
  }
  .rst__tree {
    display: none;
  }
  .ant-spin.ant-spin-spinning.ant-spin-show-text.custom-spin.true {
    display: none;
  }
  .ant-spin.ant-spin-spinning.ant-spin-show-text.custom-spin.false {
    display: block;
  }
}
@media screen and (max-width: 800px) {
.media-screen-left-navigation {
  margin-top: 0px;
}
}
.rst__moveHandle + .rst__rowContents .list-group-item {
  padding-left: 0 !important;
}
.rst__moveHandle {
  margin-left: 10px !important;
}
.rst__row {
  height: 30px;

  &.depth-0 {
    width: 280px;
  }
  &.depth-1 {
    width: 265px;
  }
  &.depth-2 {
    width: 250px;
  }
  &.depth-3 {
    width: 235px;
  }
  &.depth-4 {
    width: 220px;
  }
  &.depth-5 {
   width: 205px;
 }
  &.depth-6 {
    width: 190px;
  }
  &.depth-7 {
    width: 175px;
  }
  &.depth-8 {
    width: 160px;
  }
  &.depth-9 {
    width: 145px;
  }
  &.depth-10 {
    width: 130px;
  }
}
.rst__row:hover {
  background-color: #9ae0fc50!important;
  border-radius: 0 !important;

  .icon-v14-options-small {
    display:inline-block !important;
  }
}
.rst__row.active {
  background-color: #a6acb230!important;
  border-radius: 0 !important;

  .node-title span{
    font-weight: 400 !important;
  }
}
.rst__row.active:hover {
  background-color: rgba(154,224,252, 0.5) !important;
}

.rst__row .list-group-item.active {
  color: inherit !important;
}
