.connectorList__wrapperCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.connectorList__wrapperTable {
  margin-bottom: 20px;
  overflow-y: scroll;
  width: 100%;
}

.connectorList__wrapperTable table {
  width: 100%;
}

.connectorList__wrapperTable th {
  background-color: #FFFFFF;
  border-bottom: 3px solid #E6E8EA;
  color: #6E7882;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  text-transform: uppercase;
}

.connectorList__wrapperTable td {
  border-bottom: 1px solid #E6E8EA;
  color: #111C26;
  font-size: 14px;
  padding: 8px;
}

.connectorList__wrapperTable tr:last-of-type td {
  border-bottom: none;
  color: #111C26;
  font-size: 14px;
  padding: 8px;
}

.connectorList__wrapperTable tr:nth-child(even) td {
  background-color: #FFFFFF;
}

.connectorList__wrapperTable tr:nth-child(odd) td {
  background-color: #FBFBFB;
}

.connectorList__cellDataCollector {
  min-width: 140px;
  text-align: left;
}

.connectorList__cellDataSize {
  min-width: 100px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .connectorList__wrapperCard {
    flex-direction: row;
  }

  .connectorList__wrapperCard .connectorCard__wrapper {
    border-bottom: none;
    flex-basis: 49%;
    margin-right: 2%;
  }

  .connectorList__wrapperCard .connectorCard__wrapper:nth-of-type(2n) {
    margin-right: 0;
  }
  
  .connectorList__wrapperTable {
    overflow-y: initial;
  }
}

@media screen and (min-width: 1200px) {
  .connectorList__wrapperCard .connectorCard__wrapper {
    border-bottom: none;
    flex-basis: 32%;
    margin-right: 2%;
  }

  .connectorList__wrapperCard .connectorCard__wrapper:nth-of-type(2n) {
    margin-right: 2%;
  }
  
  .connectorList__wrapperCard .connectorCard__wrapper:nth-of-type(3n) {
    margin-right: 0;
  }
}