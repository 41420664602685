.branding-profiles {
  padding: 40px 40px 0;
  position: relative;
  .branding-profiles-contain {

    .branding-profiles-header {
        display: flex;
      align-items: center;
      justify-content: space-between;
      .header-find {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        label {
          color: #6E7882;
          display: inline-block;
          font-family: "Roboto", sans-serif;
          font-size: 12px;
          font-weight: 400;
          padding-bottom: 5px;
          text-transform: uppercase;
        }
        .find-inactive {
          background: url(../../../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
          border: none;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          box-sizing: border-box;
          color: #555555;
          font-size: 18px;
          font-weight: 300;
          padding: 10px 10px 10px 35px;
          height: 42px;
          width: 0;
          margin-right: 20px;

          &:focus {
            outline: 2px solid #3DD8DB;
            width: 93%;
          }
        }
        .find-active {
          background: url(../../../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
          border: none;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          box-sizing: border-box;
          color: #555555;
          font-size: 18px;
          font-weight: 300;
          padding: 10px 10px 10px 35px;
          height: 42px;
          margin-right: 20px;
        }
      }

      .header-create-profile {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        .ant-btn {
          width: 206px;
          height: 40px;
          object-fit: contain;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #FFFFFF;
          background-color: #3DD8DB;
          border-radius: 30px;
          border: 1px solid #3DD8DB;
          &:hover{
            border: 1px solid #3DD8DB;
            color: #ffffff;
            background-color: #3DD8DB;
          }
          &:focus{
            border: 1px solid #3DD8DB;
            color: #ffffff;
            background-color: #3DD8DB;
          }
          .anticon {
            font-size: 20px;
            font-weight: bold;
          }
          a {
            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }

    .branding-profiles-table {
     .table-branding {
       .content-header-column {
         display: flex;
         align-items: center;
         .active-company {
           cursor: pointer;
           color: #eda41e;
           font-weight: bold;
         }
         .content-buttons-sorter {
           display: flex;
           flex-direction: column;
           align-items: center;
           height: 20px;
           line-height: 12px;
           .icon-v16-arrow-bold:before {
             line-height: 9px;
           }
           .active-sort {
             color: #EDA41E;
           }
           .rotate-arrow {
             -webkit-transform: rotate(180deg);
             display: flex;
           }
         }
       }
       .icon-v19-delete-active {
         font-size: 16px;
         color: #EDA41E;
       }
       .ant-table-tbody > tr > td {
         background-color: #FFFFFF;
       }
       .icon-v19-delete {
         font-size: 16px;
         color: #382e34;
       }
       .button-modify {
         background-color: #3DD8DB;
         border: 1px solid #3DD8DB;
         border-radius: 20px;
         box-shadow: 0 2px 4px 0 #DCDCDC;
         color: #FFFFFF;
         cursor: pointer;
         font-family: ROBOTO;
         font-weight: 700;
         font-size: 14px;
         padding: 5px 30px;
         text-transform: uppercase;
         transition: all .2s;
         &:hover {
           background-color: #FFFFFF;
           color: #3DD8DB;
         }
       }
     }
    }
  }

  .branding-profiles-create {
    background-color: #FFFFFF;
    padding: 20px;
     .header-profile-settings {
       border-bottom: 1px solid #d4d7da;
       padding-bottom: 10px;
       span {
         font-size: 18px;
         font-family: "Roboto";
         font-weight: 500;
         color: #382e34;
       }
     }
    .input-profile-settings {
      margin-top: 20px;
       .name-input {
         font-size: 12px;
         font-family: "Roboto";
         font-weight: 400;
         color: #382e34;
         display: flex;
         margin-bottom: 10px;
       }
      .input-profile {
        &:focus {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:hover {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
      }
    }

    .profile-settings-contain {
      //margin-top: 30px;
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
       .primary-colour-contain {
          width: 100%;
         display: flex;
         flex-direction: column;
         .primary-colour-list {
           display: flex;
           flex-direction: column;
           margin-top: 10px;
           justify-content: space-between;
           width: 100%;
           .title-category-colour {
             font-size: 12px;
             font-family: "Roboto";
             font-weight: 500;
             color: #382e34;
             margin-bottom: 5px;
           }
           .title-subcategory-colour {
             font-size: 12px;
             font-family: "Roboto";
             font-weight: 500;
             color: #382e34;
             margin-bottom: 5px;
           }
           .colours-contain {
             display: flex;
             align-items: center;
             flex-direction: row;
             width: 100%;
              .default-colour-contain {
                display: flex;
                align-items: center;
                flex-direction: row;
                margin-right: 5px;
                width: 30%;
                .square-colour {
                  display: flex;
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                  border-radius: 3px;
                }
                .name-colour {
                  font-size: 16px;
                  font-family: "Roboto";
                  font-weight: 400;
                  color: #382e34;
                }
              }

             .space-colour {
               width: 20%;
               .anticon {
                 color: #6e7882;
               }
             }
             .change-colour-contain {
               display: flex;
               align-items: center;
               flex-direction: row;
               width: 100%;
               .icon-change-color {
                 position: relative;
                 float: right;
                 width: 20px;
                 height: 20px;
                 margin-top: 0px;
                 cursor: pointer;
                 margin-right: 5px;
                 border: 1px solid #d4d7da;
                 border-radius: 3px;
                 //margin-left: 5px;
               }
               .content-change-colour {
                 display: flex;
                 align-items: center;
                 border: 1px solid #d9d9d9;
                 margin-left: 5px;
                 padding: 3px;
                 border-radius:4px;
                 .name-change-colour {
                   font-size: 16px;
                   font-family: "Roboto";
                   font-weight: 400;
                   color: #382e34;
                 }
               }
               .ant-checkbox-wrapper .ant-checkbox-inner {
                 border-radius: 3px;
               }
               .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
                 border-color: #FFFFFF;
                 background-color: transparent !important;
               }
               .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
                 background-color: #3DD8DB !important;
                 border: 3px solid #3DD8DB;
               }
               .ant-checkbox-inner::after {
                 position: absolute;
                 top: 42%;
                 left: 12%;
               }
               .ant-checkbox-inner {
                 position: relative;
                 top: 0;
                 left: 0;
                 display: block;
                 width: 12px;
                 height: 12px;
                 background-color: #fff;
                 border: 1px solid #d9d9d9;
                 border-radius: 2px;
                 border-collapse: separate;
                 -webkit-transition: all 0.3s;
                 transition: all 0.3s;
               }
             }
           }
         }
       }

      .secondary-colour-contain {
        border-top: 1px solid #d9d9d9;
        margin-top: 20px;
        padding-top: 10px;
      }
      .navigation-header-contain {
        //border-top: 1px solid #d9d9d9;
        //margin-top: 20px;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .navigation-header-title {
          font-size: 12px;
          font-family: "Roboto";
          font-weight: 500;
          color: #382e34;
          margin-top:10px;
          margin-bottom: 5px;
        }
        .navigation-colours-contain {
          font-size: 12px;
          font-family: "Roboto";
          font-weight: 400;
          color: #382e34;
          margin-top:10px;
          margin-bottom: 10px;
          .change-colour-contain {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 100%;
            .change-colour-nav-header {
              width: 38%;
            }
            .icon-change-color {
              position: relative;
              float: right;
              width: 20px;
              height: 20px;
              margin-top: 0px;
              cursor: pointer;
              margin-right: 5px;
              border: 1px solid #d4d7da;
              border-radius: 3px;
              //margin-left: 5px;
            }
            .content-change-colour {
              display: flex;
              align-items: center;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              padding: 3px;
              width: 27%;
              .name-change-colour {
                font-size: 16px;
                font-family: "Roboto";
                font-weight: 400;
                color: #382e34;
                //margin-right: 5px;
              }
            }
          }
        }
        .style-upload-home-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          .home-icon-container {
            width: 50px;
            height: 50px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .home-icon {
              width: 40px;
              height: 40px;
            }
          }
          .upload-home-icon {
            .file-upload-home-icon {
              p {
                display: none;
              }
              .chooseFileButton.button-upload-image {
                border: none;
                font-family: "Roboto";
                text-decoration: underline;
                font-weight: 500;
                &:hover {
                  background: #FFFFFF;
                  color: #3dd8d8;
                  opacity: 0.5;
                }
              }
            }
          }
          .home-icon-size {
            font-family: "Roboto";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: #d9d9d9;
          }
        }
      }
    }

    .company-logo {
      display: flex;
      align-content: center;
      align-items: center;
      .company-logo-header {
        font-family: Roboto;
        font-weight: 400;
        color: #382e34;
        font-size: 12px;
        width: 13%;
      }
      .style-upload-home-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        .home-icon-container {
          width: 120px;
          height: 50px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          .home-icon {
            width: 40px;
            height: 40px;
          }
        }
        .upload-home-icon {
          .chooseFileButton.button-upload-image {
            border: none;
            font-family: "Roboto";
            text-decoration: none !important;
            font-weight: 500;
          }
          .button-text-upload {
            display: flex;
            flex-direction: column;
            text-decoration: none !important;
            .upload-image {
              text-decoration: underline !important;
            }
            .upload-size {
              ont-family: Roboto;
              font-weight: 400;
              font-size: 12px;
              margin-top: 5px;
            }
          }
          .file-upload-home-icon {
            p {
              display: none;
            }
            .chooseFileButton.button-upload-image {
              border: none;
              font-family: "Roboto";
              text-decoration: underline;
              font-weight: 500;
              &:hover {
                background: #FFFFFF;
                color: #3dd8d8;
                opacity: 0.5;
              }
            }
          }
        }
        .home-icon-size {
          font-family: "Roboto";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: #d9d9d9;
        }
      }
    }
    .footer-profile-settings {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      .button-create {
        background-color: #FFFFFF;
        border: 1px solid #3DD8DB;
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        color: #3DD8DB;
        cursor: pointer;
        font-family: ROBOTO;
        font-weight: 700;
        font-size: 14px;
        padding: 5px 30px;
        text-transform: uppercase;
        transition: all .2s;
        &:hover {
          background-color: #3DD8DB;
          color: #FFFFFF;
        }
        &:focus {
          background-color: #3DD8DB;
          color: #FFFFFF;
        }
        &:disabled {
          color: #dddddd;
          border: 1px solid #dddddd !important;
          background-color: #ffffff;
          cursor:default;
        }
      }

      .button-discard-changes {
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 #FFFFFF;
        color: #3DD8DB;
        cursor: pointer;
        font-family: ROBOTO;
        font-weight: 700;
        font-size: 14px;
        padding: 5px 30px;
        text-transform: uppercase;
        transition: all .2s;
        &:hover {
          background-color: #FFFFFF;
          color: #3DD8DB;
          opacity: 0.5;
        }
        &:focus {
          background-color: #FFFFFF;
          color: #3DD8DB;
          opacity: 0.5;
        }
      }
    }
  }
}