.editInPlace__wrapper {
  display: inline-block;
}

.editInPlace__input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 40px;
  padding: 10px;
  width: 100%;
}

.editInPlace__input:focus {
  outline: 2px solid #3DD8DB;
}