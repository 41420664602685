.new-tracker-page {
  padding-left: 170px !important;
  padding-right: 170px !important;
}
.tracker-page {
  margin-bottom: 40px;
  padding: 100px 10px 10px;
  .tracker-surname-contain {
    width: auto;
    height: 36px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #6e7882;
    margin-top: 40px;
    border-top-right-radius: 15px;
    padding-top: 3px;
    padding-left: 6px;
    padding-right: 12px;
    text-transform: capitalize;
    display: inline-block;
    .tracker-surname {
      width: 101px;
      height: 26px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #FFFFFF;
    }
  }
  .tracker-header-step {
    background-color: #fafafa !important;
    box-shadow: none !important;
    margin-top: 90px !important;
    .ant-steps-item-disabled {
      .ant-steps-item-icon .ant-steps-icon {
        cursor: not-allowed;
      }
      .ant-steps-item-content {
        cursor: not-allowed;
      }
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
      &:hover {
        .ant-steps-item-title {
          color: #00d3d8;
        }
        .ant-steps-item-icon {
          color: #00d3d8;
          .ant-steps-icon {
            color: #00d3d8;
          }
        }
      }
    }
    .ant-steps-dot .ant-steps-item-tail {
      top: 2px;
      width: 100%;
      margin: 0 0 0 77px;
      padding: 0;
    }
    .fa {
      font-size: 40px;
    }
    .icon-finish {
      background: cadetblue !important;
    }
    .icon-wait {

    }
    .ant-steps-icon {
      color: #6D7781;
      font-size: 40px;
    }
    .ant-steps-icon:hover {
      color: #00d3d8 !important;
    }
    .ant-steps-item-icon {
      margin-left: 30px;

    }
    ant-steps-item-tail {
      top: 2px;
      width: 87%;
     margin-left: 69px;
      padding: 0;
    }
    .ant-steps-item-wait .ant-steps-item-disabled {
      cursor: not-allowed;
    }
  }
  .adjust-tracker-box-acknowledge {
    .tracker-form {
      .tracker-email-info-steps {
        position: relative;
        padding: 30px;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 40px;
        height: 180px;
        .status-step-finish {
          .ant-steps-item-tail::after {
            border-top: 3px dotted #6D7781;
          }
        }
        .status-link {
          .ant-steps-item-content .ant-steps-item-title {
            padding-top: 20px;
            padding-right: 75px;
            padding-left: 22px;
            font-size: 14px !important;
            position: relative;
            right: 1px;
          }
        }
        .status-link-visited {
          .ant-steps-item-content .ant-steps-item-title {
            padding-left: 6px;
            font-size: 14px !important;
            position: relative;
            right: 3px;
          }
        }
        .status-submitting-data {
          .ant-steps-item-content .ant-steps-item-title {
            padding-right: 70px;
            font-size: 14px !important;
            position: relative;
            right: 10px;
          }
        }
        .ant-steps-dot .ant-steps-item-icon {
          width: 8px;
          height: 8px;
          margin-left: 0px;
          padding-right: 0;
          line-height: 8px;
          background: transparent;
          border: 0;
        }
        .ant-steps {
          height: 40px;
          display: flex;
          align-items: normal;
          margin-left: 0px;
          font-family: 'Roboto';
          margin-bottom: 40px;
          width: 50%;
          .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
            cursor: auto;
          }
          .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
            color: #00d3d8;
          }
          .ant-steps-item-finish {
            .ant-steps-item-tail {
              width: 101%;
              margin: 0 0 0 35px;
              opacity: 0.8;
            }
            .ant-steps-item-icon .ant-steps-icon {
              color: #00d3d8;
              font-size: 37px;
            }
            .ant-steps-item-content .ant-steps-item-title {
              color: #00d3d8;
            }
          }
          .ant-steps-item-process {
            .ant-steps-item-content .ant-steps-item-title {
              color: #00d3d8;
            }
          }

          .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
            font-size: 37px;
          }
          .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
            font-size: 37px;
            color: #00d3d8;
          }
          .ant-steps-item {
            overflow: visible;
          }
          .ant-steps-item-content {
            display: block;
            width: 215px;
            margin-top: 8px;
            text-align: start;
            left: -12px;
            .ant-steps-item-title {
              color: #6D7781;
              font-weight: 500;
              font-size: 12px;
              padding-top: 20px;
              padding-right: 75px;
              opacity: 0.8;
            }
            .ant-steps-item-description {
              font-weight: 500;
              color: #6D7781;
              opacity: 0.6;
              display: grid;
              height: auto;
              max-height: 60px;
              width: 130px;
              overflow: auto;
              .status-pending {
                padding-left: 12px;
              }
              .status-finish-info {
                padding-left: 4px;
                opacity: 1;
              }
            }
            ant-steps-item-description:hover {
              color: #00d3d8;
            }
          }
          .ant-steps-item-tail {
            top: 2px;
            width: 97%;
            margin: 0 0 0 29px;
            padding: 0;
          }
          .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #ffffff;
            border-top: 3px dotted #6D7781;
            opacity: 0.6;
          }
          .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #ffffff;
            border-top: 3px dotted #00d3d8;
            opacity: 0.8;
            border-spacing: 1px;
          }
          .ant-steps-item-wait .ant-steps-item-tail {
            opacity: 0.6;
          }
          .ant-steps-item-wait .ant-steps-item-tail:after {
            background-color: #ffffff;
            border-top: 3px dotted #6D7781;
            height: 1px;
            width: calc(105% - 40px);
            margin-left: 11px;
          }
          .ant-steps-label-vertical .ant-steps-item {
            overflow: visible;
          }
        }
      }
      .button-acknowledge {
        button:disabled {
          background-color: #6e7882 !important;
          opacity: 0.6;
          cursor: not-allowed !important;
        }
        button:disabled:hover {
          background-color: #6e7882 !important;
          color: #FFFFFF !important;
        }
      }
      .tracker-form-group {
        .edit-input-first-name-focus {
          color: #00d3d8;
        }
        .edit-input-focus {
          color: #00d3d8;
        }
        .edit-input {
          position: absolute;
          right: 25px;
          span {
            position: absolute;
            top: -27px;
            right: 2px;
            cursor: pointer;
          }
        }
        .ant-input:focus {
          box-shadow: none !important;
          border-bottom: solid 2px #00D3D8 !important;
        }
        .ant-input {
          border: none !important;
          border-bottom: solid 2px #6e7882 !important;
          background-color: #fafafa !important;
          color: #222B38 !important;
          opacity: 1 !important;
          border-radius: 0px !important;
        }
        label {
          color: #6e7882 !important;
        }
      }
      .tracker-step-content {
        .tracker-form-group-fullname {
        .tracker-form-group-edit {
          position: relative;
          .edit-input-first-name {
            position: absolute;
            right: 31px;
            span {
              position: absolute;
              top: -26px;
              cursor: pointer;
            }
          }
        }
      }
        .tracker-form-group-fullname {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .tracker-form-group {
            width: 48%;
          }
        }
        .tracker-content-links {
          .tracker-form-link {
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #3dd8db !important;
              border-color: #3dd8db;
            }
            .ant-checkbox-inner:after {
              border-color: #ffffff !important;
            }
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 0% !important;
            }
            .send-form {
              opacity: 0.8;
            }
          }
        }
        .form-buttons-next {
          .button-blue-light {
            width: 300px !important;
          }
        }
      }
    }
  }
  .tracker-box-check-id {
    .tracker-form {
      .tracker-content-links {
        .ant-checkbox-wrapper {
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #3DD8DB !important;
            border: 3px solid #3DD8DB;
          }
          .ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: #ffffff;
          }
          .ant-checkbox-inner {
            border-radius: 0%;
          }
        }
      }
     .tracker-step-content {
       .content-subject-know {
         display: flex;
         align-items: center;
         padding-bottom: 30px;
         .title-subject-know {
           padding-right: 20px;
           color: #637882;
           font-weight: 500;
         }
         .tracker-radio-subject {
           margin: 0;
           padding-left: 50px;

         }
       }
       .switcher-option-check {
         align-items: center;
         background-color: #ffffff;
         display: flex;
         font-size: 14px;
         position: relative;
         font-family: Roboto;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         button:disabled {
           border: 1px solid #6e7882 !important;
         }
         .ant-switch {
           min-width: 70px;
           height: 30px;
           background-color: #ffffff;
           border: 1px solid #6e7882;
         }
         .ant-switch-checked .ant-switch-inner {
           margin-right: 0px;
           margin-left: 23px;
           margin-top: 1px;
           font-size: 15px;
           color: #ffffff;
           font-weight: 400;
         }
         .ant-switch-inner {
           display: block;
           margin-right: 20px;
           margin-left: 0px;
           color: #6e7882;
           font-size: 15px;
           font-weight: 400;
           margin-top: 1px;
         }
         .ant-switch-checked {
           background-color: #3DD8DB;
         }
         .ant-switch::after {
           left: 42px;
           width: 22px;
           height: 22px;
           border: 1px solid #3DD8DB;
           top: 2px;
         }
         .ant-switch-checked::after {
           left: 40%;
           width: 22px;
           height: 22px;
           top: 3px;
           margin-left: -2px;
           border: 1px solid #6e7882;
         }
       }
       .validation-check-id-new {
         font-size: 14px;
         font-weight: bold;
         color: #bd2130;
       }
       .tracker-content-radio {
         .tracker-radio-check .ant-radio-checked .ant-radio-inner {
           border: solid 1px #6e7882 !important;
           background-color: #3DD8DB;
         }
       }
       .form-group-reset-mfa {
         display: flex;
       }
       .tracker_formGroup-reset {
         display: flex;
         .button-green {
           padding: 6px 30px !important;
         }
       }
       .tracker_formGroup {
         .textArea_checkId {

         }
         .edit-input {
           position: absolute;
           right: 25px;
           span {
             position: absolute;
             top: -27px;
             right: 2px;
             cursor: pointer;
           }
         }
         .edit-input-focus {
           color: #00D3D8;
         }
       }
       .new-tracker-switch {
         .tracker-check-send-id {
           margin: 0px;
           display: flex;
           align-items: center;
           .content-message-send {
             padding-right: 70px;
             color: #637882;
             font-weight: 500;
           }
         }
       }
       .received-documents-content {
          //margin-bottom: 30px;
          display: flex;
          //justify-content: space-between;
         flex-direction: column;
         .received-delete-proof {
           font-size: 16px;
           font-family: Roboto;
           font-weight: 500;
           color: #6e7882;
         }
         .documents-empty-deleted {
           width: 104px;
           height: 140px;
           //border: 2px solid #6e7882;
           border-radius: 5px;
           margin-top: 7px;
           display: flex;
           justify-content: center;
           align-items: center;
           flex-direction: column;
           background-color: #E3E3E3;
           span {
             font-size: 16px;
             font-family: Roboto;
             font-weight: 500;
             color: #6e7882;
           }
         }
         .content-button-request-id {
           .button-blue-light {
             height: 33px !important;
             border: 1px solid #000000;
             background-color: #ffffff;
             color: #000000;
             box-shadow: 0 2px 4px 0 #dcdcdc;
             cursor: pointer;
             font-weight: 900;
             font-size: 20px;
             padding: 7px 30px !important;
             text-transform: uppercase;
             border-radius: 40px;
             margin-right: 40px;
             width: 300px;
             margin-bottom: 30px;
             &:hover {
               background-color: #000000;
               color: #ffffff;
               border: 1px solid #000000;
             }
           }
           .button-blue-light:disabled {
             border: 1px solid #000000 !important;
             background-color: #ffffff;
             color: #000000;
             cursor: not-allowed;
           }
         }
         .proof-id-documents {
            .picture-check-id-update {
              display: inline-block;
              position: relative;
              .disabled-delete {
                cursor: not-allowed;
                opacity: 0.6;
              }
              .icon-v12-delete {
                position: absolute;
                right: 2px;
                font-size: 18px;
                z-index: 30;
                top: -2px;
              }
            }
           .picture-check-id {
             margin-top: 7px;
             margin-bottom: 20px;
           }
         }
         .opacity-documents {
           opacity: 0.4;
         }
         .proof-id-documents-empty {
           .received-proof-documents {
             width: 250px;
           }
           .documents-empty {
             width: 104px;
             height: 140px;
             border: 2px solid #6e7882;
             border-radius: 5px;
             margin-top: 7px;
           }
         }
         .content-button {
           .button-blue-light {
             text-transform: none;
             font-size: 16px;
             font-weight: 500;
             height: 36px;
             width: 210px;
             padding: 8px 30px;
           }
           .button-blue-disabled {
             font-size: 16px;
             height: 36px;
             width: 210px;
             padding: 8px 30px;
             color: #3DD8DB;
             border: 1px solid #3DD8DB !important;
             opacity: 0.4;
             background-color: #ffffff;
           }
           button:disabled {
             color: #3DD8DB;
             border: 1px solid #3DD8DB !important;
             opacity: 0.4;
             cursor: not-allowed;
           }
         }
       }
       .content-radio-options-proofId {
         margin-top: 20px;
         display: flex;
         .ant-radio-group {
           width: 100%;
         }
         .content-request-additional-proof {
           width: 100%;
           margin-bottom: 50px;
           .button-blue-light {
             border: 1px solid #000000;
             background-color: #000000;
             color: #ffffff;
             width: 200px;
             box-shadow: 0 2px 4px 0 #DCDCDC;
             cursor: pointer;
             font-family: Roboto;
             font-weight: 400;
             font-size: 18px;
             padding: 3px 30px;
             text-transform: capitalize;
             border-radius: 50px;
             height: 30px;
             margin-top: 20px;
             margin-bottom: 20px;
             float: right;
             &:focus {
               background-color: #ffffff;
               border: 1px solid #000000;
               color: #000000;
             }
             &:hover {
               background-color: #ffffff;
               border: 1px solid #000000;
               color: #000000;
             }
           }
           .input-comment {
             width: 100%;
             height: 100px;
             border-radius: 0;
           }
           .ant-input:hover {
             border-color: #3DD8DB;
             border-right-width: 1px !important;
           }
           .ant-input:focus {
             border-color: #3DD8DB;
             border-right-width: 1px !important;
             box-shadow: none;
           }
         }
             .ant-radio-checked .ant-radio-inner {
               border: solid 3px #3DD8DB;
             }
         .ant-radio-checked .ant-radio-inner {
           border: solid 1px #6e7882 !important;
           background-color: #3DD8DB;
         }
             .ant-radio {
               .ant-radio-inner {
                 border: solid 1px #6e7882;
               }
               .ant-radio-inner::after {
                 content: "\f00c" !important;
                 color: #ffffff !important;
                 font-size: 9px !important;
                 font-family: 'FontAwesome' !important;
                 top: 1.9px;
                 left: 1.7px;
                 background-color: #3DD8DB;
               }
             }
             .radion-style {
               display: block;
               height: 30px;
               lineHeight: 30px;
             }

       }
       .content-footer-check-id {
         width: 100%;
         display: flex;
         justify-content: flex-end;
         margin-bottom: 30px;
         .buttonReject {
           margin-right: 20px;
           .button-blue-light {
             border: 1px solid #000000;
             background-color: #FFFFFF;
             color: #000000;
             width: 340px;
             box-shadow: 0 2px 4px 0 #DCDCDC;
             cursor: pointer;
             font-family: 'Ubuntu', sans-serif;
             font-weight: 700;
             font-size: 20px;
             padding: 10px 30px;
             text-transform: uppercase;
             border-radius: 50px;
             height: 50px;
             &:focus {
               background-color: #000000;
               border: 1px solid #000000;
               color: #ffffff;
             }
           }
           button:focus {
             background-color: #000000;
             border: 1px solid #000000;
             color: #ffffff;
           }
           .button-blue-light:hover {
             background-color: #000000;
             border: 1px solid #000000;
             color: #ffffff;
           }
         }
         .tacker-button-confirm {
           margin-bottom: 0px;
           margin-left: 20px;
           .button-blue-disabled {
             width: 340px !important;
             cursor: not-allowed;
             border: 1px solid #3DD8DB !important;
           }
           .button-confirm-check-disabled {
             width: 340px !important;
             cursor: not-allowed;
             border: 1px solid #6e7882 !important;
             background-color: #6e7882 !important;
             color: #ffffff;
             opacity: 0.6;
           }
           .button-check-id {
             width: 340px !important;
           }
         }
         .reject-check-id {
           border: 1px solid #000000;
           background-color: #FFFFFF;
           color: #000000;
           width: 210px;
           box-shadow: 0 2px 4px 0 #DCDCDC;
           cursor: pointer;
           font-family: 'Ubuntu', sans-serif;
           font-weight: 700;
           font-size: 20px;
           padding: 10px 30px;
           text-transform: uppercase;
           border-radius: 50px;
           height: 50px;
         }
         .reject-check-id:hover {
           background-color: #000000;
           border: 1px solid #000000;
           color: #ffffff;
         }
         .reject-check-id:focus {
           background-color: #000000;
           border: 1px solid #000000;
           color: #ffffff;
         }
       }
     }
      .tacker-button-confirm {
        .button-blue-light {
          width: 18% !important;
        }
        .button-blue-disabled {
          width: 18% !important;
        }
      }
    }
  }
  .tracker-box-rights {
    .ant-checkbox-wrapper {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #3DD8DB !important;
        border: 3px solid #3DD8DB;
      }
      .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: #ffffff;
      }
      .ant-checkbox-inner {
        border-radius: 0%;
      }
    }
    .switcher-option-check {
      align-items: center;
      background-color: #ffffff;
      display: flex;
      font-size: 14px;
      position: relative;
      font-family: Roboto;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      button:disabled {
        border: 1px solid #6e7882 !important;
      }
      .ant-switch {
        min-width: 70px;
        height: 30px;
        background-color: #ffffff;
        border: 1px solid #6e7882;
      }
      .ant-switch-checked .ant-switch-inner {
        margin-right: 0px;
        margin-left: 23px;
        margin-top: 1px;
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;
      }
      .ant-switch-inner {
        display: block;
        margin-right: 20px;
        margin-left: 0px;
        color: #6e7882;
        font-size: 15px;
        font-weight: 400;
        margin-top: 1px;
      }
      .ant-switch-checked {
        background-color: #3DD8DB;
      }
      .ant-switch::after {
        left: 42px;
        width: 22px;
        height: 22px;
        border: 1px solid #3DD8DB;
        top: 2px;
      }
      .ant-switch-checked::after {
        left: 40%;
        width: 22px;
        height: 22px;
        top: 3px;
        margin-left: -2px;
        border: 1px solid #6e7882;
      }
    }
  }
  .tracker-box-data {
    .tracker-form {
     .tracker-step-content {
       .content-documents-subject {
         display: flex;
         margin-bottom: 60px;
         .switcher-option-check {
           align-items: center;
           background-color: #ffffff;
           display: flex;
           font-size: 14px;
           position: relative;
           font-family: Roboto;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           button:disabled {
             border: 1px solid #6e7882 !important;
           }
           .ant-switch {
             min-width: 70px;
             height: 30px;
             background-color: #ffffff;
             border: 1px solid #6e7882;
           }
           .ant-switch-checked .ant-switch-inner {
             margin-right: 0px;
             margin-left: 23px;
             margin-top: 1px;
             font-size: 15px;
             color: #ffffff;
             font-weight: 400;
           }
           .ant-switch-inner {
             display: block;
             margin-right: 20px;
             margin-left: 0px;
             color: #6e7882;
             font-size: 15px;
             font-weight: 400;
             margin-top: 1px;
           }
           .ant-switch-checked {
             background-color: #3DD8DB;
           }
           .ant-switch::after {
             left: 42px;
             width: 22px;
             height: 22px;
             border: 1px solid #3DD8DB;
             top: 2px;
           }
           .ant-switch-checked::after {
             left: 40%;
             width: 22px;
             height: 22px;
             top: 3px;
             margin-left: -2px;
             border: 1px solid #6e7882;
           }
         }
         .tracker-radio {
           margin: 0;
           align-items: center;
         }
       }
       .tracker-content-button {
         height: 40px;
         .button-blue-light {
           width: 18%;
         }
       }
       .tracker-end-request {
         .search-and-find-loading-container {
           color: #6e7882;
           padding-right: 60px;
           width: 50px;
           display: flex;
           justify-content: flex-end;
           float: right;
           margin-top: 20px;
         }
         .button-blue-light {
           border: 1px solid #000000;
           background-color:#ffffff;
           color: #000000;
           box-shadow: 0 2px 4px 0 #DCDCDC;
           &:hover {
             background-color: #000000;
             color: #ffffff;
             border: 1px solid #000000;
           }
         }
       }
     }
    }
  }
  .tracker-import-data {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    position: relative;
    padding: 20px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    .tracker-form-import-data {
      .import-data-step-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
       .tracker-step-header-top {
          display: flex;
          flex-direction: column;
          color: #222B38;
         &__top1 {
          font-size: 16px;
           margin-bottom: 10px;
         }
         &__top2 {
          font-size: 14px;
           opacity: 0.6;
           font-weight: 300;
         }
        }
        .tracker-step-header-button {
          .import-data-content:disabled {
            cursor: not-allowed;
            border: 1px solid #6e7882 !important;
            background-color: #FFFFFF;
            color: #6e7882;
            &:hover {
              background-color: #FFFFFF;
              border-color: #6e7882;
              color: #6e7882;
            }
          }
          .import-data-content {
            width: 200px;
            height: 32px;
            border: 1px solid #00D3D8;
            background-color: #FFFFFF;
            color: #00D3D8;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            padding: 4px 30px;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;

            &:hover {
              background-color: #00D3D8;
              border-color: #00D3D8;
              color: #FFFFFF;
            }

            &:focus {
              border: 1px solid #00D3D8;
              background-color: #00D3D8;
              color: #FFFFFF;
            }

            &:active {
              border: 1px solid #00D3D8;
              background-color: #00D3D8;
              color: #FFFFFF;
            }
          }
        }
      }
      .tracker-content-import-data {
        margin-top: 60px;
        .ant-checkbox-wrapper .ant-checkbox-inner {
          border-radius: 3% !important;
          border: 1px solid #6e7882;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #3ED8DB !important;
        }
        .ant-checkbox-inner:after {
          border-color: #ffffff !important;
        }
        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: #3ED8DB !important;
        }
        .table-import-files {
          .ant-checkbox-wrapper .ant-checkbox-inner {
            border-radius: 3% !important;
            border: 1px solid #6e7882;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #EDA41E !important;
          }
          .ant-checkbox-inner:after {
            border-color: #ffffff !important;
          }
          .ant-checkbox-inner {
            border-radius: 0%;
          }
          .column-remove .remove-title-column {
            color: #637882;
            font-weight: 400;
            opacity: 0.6;
            cursor: not-allowed;
          }
          .column-files .title-files-column {
            color: #637882;
            font-weight: 400;
            padding-left: 3px;
            opacity: 0.6;
          }
        }
        .column-dates {
          font-weight: 400;
          color: #000000;
        }
        .ant-table {
          border: 1px solid #e8e8e8;
        }
        .ant-table-placeholder {
          border-bottom: none;
        }
        .ant-table-tbody > tr > td {
          padding: 2px 16px 2px 16px;
        }
        .column-files {
          font-weight: 500;
          color: #000000;
          .ant-table-tbody > tr > td {
            padding: 2px 16px 2px 10px !important;
          }
          .ant-table-column-title {
            padding-left: 4px;
          }
          .content-name-column {
            display: flex;
            align-items: center;
            .icon-v10-extension-pdf {
              font-size: 20px;
            }
            img {
              width: 20px;
              padding-right: 5px;
            }
          }
          .title-files-column {
            color: #637882;
            font-weight: 400;
            padding-left: 3px;
          }
          .icon-file-table {
            font-size: 25px;
          }
        }
        .column-remove {
          //padding: 6px 20px 0px 0px;
          padding: 6px 20px;
          color: #000000;
          .remove-title-column {
            color: #637882;
            font-weight: 400;
            cursor: pointer;
          }
          .remove-title-column-disabled {
            cursor: not-allowed !important;
            opacity: 0.8;
          }
           .ant-table-header-column {
            float: right;
          }
          .content-remove-files {
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .icon-delete-file {
              float: right;
              cursor: pointer;
            }
            .icon-delete-file-disabled {
              float: right;
              opacity: 0.6;
              cursor: not-allowed;
            }
          }
        }
        .ant-empty-normal .ant-empty-image {
          height: 40px;
          display: none !important;
        }
        .ant-empty-description {
          visibility: hidden;
        }

        .ant-empty-description::after {
          content: 'No data imported';
          padding-left: 5px;
          visibility: initial;
        }
        .content-dates-files {
          color: #6e7882;
          font-size: 14px;
          font-weight: 300;
          margin-top: 10px;
          .space-dates {
            padding-left: 15px;
            padding-right: 10px;
          }
        }
        .letters {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          margin-bottom: 20px;
          .ant-radio-button-wrapper {
            height: 40px;
            padding-top: 5px;
            span {
              font-family: Roboto;
              font-size: 16px;
              font-weight: 300;
              color: #6e7882;
            }
            &-checked {
              background-color: #3ED8DB;
              border-color: #3ED8DB;
              border-radius: 3px;
              &:hover {
                border-color: #3ED8DB;
              }
              span {
                color: #ffffff;
              }
            }

          }
        }
        .custom-pagination-data {
          display: flex;
          justify-content: space-between;
         .per-page-data {
           color: #637882;
           font-weight: 300;
           font-size: 14px;
           .ant-select-selection-selected-value {
             color: #637882;
             font-weight: 300;
             font-size: 14px;
           }
         }
          .button-control-data {
          }
          .data-nr-data {
            color: #637882;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
      .loading-import-files {
        .content-progress-import-files {
          .ant-progress-circle .ant-progress-inner {
            width: 80px !important;
            height: 80px !important;
          }
          .text-import-files {
            margin-left: 20px;
            color: #637882;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
      .tracker-content-links {
        margin-top: 70px;
        margin-bottom: 10px;
        .tracker-form-link {
          .ant-checkbox-wrapper .ant-checkbox-inner {
            border-radius: 0% !important;
          }
          .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: #ffffff;
          }
          .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #3DD8DB !important;
          }
          .send-form {
            float: right;
          }
        }
      }
      .tracker-content-button {
        height: 70px;
        margin-top: 20px;
        .search-and-find-loading-container {
          display: flex;
          float: right;
          padding-right: 46px;
          font-size: 18px;
          font-weight: 500;
          color: #6e7782;
          .ant-spin-dot-spin {
            margin-left: 5px;
          }
        }
        .button-blue-light {
          width: 240px;
          background-color: #3DD8DB;
          color: #ffffff;
        }
        .button-blue-light:hover {
          background-color: #ffffff;
          color: #3DD8DB;
        }
        button:disabled {
          background-color: #3DD8DB !important;
          color: #ffffff !important;
          border: 0 solid #3DD8DB !important;
          opacity: 0.6;
        }
      }
      .footer-content-buttons {
        display: grid;
      }
    }
  }
  .tracker-box-send {
    .tracker-form {
      .tracker-step-header {
       .message-previous {
         display: flex;
         padding-top: 17px;
         opacity: 0.6;
       }
      }
      .tracker-step-content {
        .send-securely-table {
          .ant-empty-description {
            visibility: hidden;
            font-size: 20px;
            font-family: ROBOTO;
            font-weight: 500;
            letter-spacing: 0.2px;
          }
          .ant-empty-description::after {
            content: 'Files are being imported in background! Please wait...';
            padding-left: 5px;
            visibility: initial;
          }
        }
        .tracker-content-links {
          margin-top: 30px;
          .ant-checkbox-disabled + span {
            color: #000000;
          }
          .ant-checkbox-wrapper .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
            border-color: #3DD8DB !important;
          }
        }
        .tracker-content-buttons-securley {
          justify-content: flex-end !important;
          .button-send-data-loading-wrapper {
            .ant-spin-spinning {
              position:absolute;
              right: 250px;
              bottom: 100px;
            }
            .button-send-data-loading-state {
              background-color: #b1eff0;
              border: 1px solid #b1eff0;
              pointer-events: none;
            }
          }
        }
        .tracker-content-buttons-send {
          display: flex;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          .spin-send {
            margin-right: 30px;
            margin-top: 7px;
          }
          .content-button-end-request {
            .spin-end-request {
              margin-right: 40px;
            }
            .button-end-request {
              height: 30px;
              border: 1px solid #000000;
              background-color: #ffffff;
              color: #000000;
              //box-shadow: 0 2px 4px 0 #dcdcdc;
              box-shadow: none;
              cursor: pointer;
              font-weight: 500;
              font-size: 14px;
              padding: 5px 15px;
              text-transform: uppercase;
              border-radius: 40px;
              margin-right: 40px;
              width: 120px;
              &:hover {
                background-color: #000000;
                color: #ffffff;
                border: 1px solid #000000;
              }
            }
          }
        }
        .tracker-content-button {
          .button-blue-send-data {
            width: 300px;
            height: 50px;
            margin-bottom: 20px;
            border: 1px solid #3DD8DB;
            background-color:#3DD8DB;
            color: #FFFFFF;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            font-weight: 900;
            font-size: 20px;
            padding: 10px 30px;
            text-transform: uppercase;
            border-radius: 30px;
            &:hover {
              background-color: #FFFFFF;
              color: #3DD8DB;
            }
            &:focus {
              background-color: #FFFFFF;
              color: #3DD8DB;
            }
            &:disabled {
              color: #dddddd;
              border: 1px solid #dddddd !important;
              background-color: #ffffff;
              cursor: default;
              &:hover {
                color: #dddddd;
                border: 1px solid #dddddd !important;
                background-color: #ffffff;
                cursor: default;
              }
              &:focus {
                color: #dddddd;
                border: 1px solid #dddddd !important;
                background-color: #ffffff;
                cursor: default;
              }
            }
          }
          .send-data-disabled {
            cursor: not-allowed;
            opacity: 0.6;
            &:hover {
              background-color: #3DD8DB;
              color: #ffffff;
              border: 1px solid #3DD8DB;
              opacity: 0.6;
            }
          }
        }
        .content-button-request-complete {
          margin-top: 50px;
          height: 40px;
          .button-blue-light {
            border: 1px solid #000000;
            background-color: #000000;
            color: #FFFFFF;
            &:hover {
              background-color: #FFFFFF;
              color: #000000;
              border: 1px solid #000000;
            }
          }
        }
        .custom-pagination-data {
          display: flex;
          justify-content: space-between;
          .per-page-data {
            color: #637882;
            font-weight: 300;
            font-size: 14px;
            .ant-select-selection-selected-value {
              color: #637882;
              font-weight: 300;
              font-size: 14px;
            }
          }
          .data-nr-data {
            color: #637882;
            font-weight: 300;
            font-size: 14px;
          }
        }
        .ant-table-tbody > tr > td {
          padding: 2px 16px 2px 16px;
        }
        .ant-table-placeholder {
          border-bottom: none;
        }
        .column-total-pages {
          font-weight: 400;
          color: #000000;
          padding: 2px 16px 2px 16px !important;
        }
        .ant-table-thead > tr > th {
          padding: 8px 16px;
          overflow-wrap: break-word;
        }
        table {
          margin-left: 0px;
          margin-top: 0px;
        }
        .ant-table {
          border: 1px solid #e8e8e8;
        }
        .column-name {
          width: 35%;
          color: #637882;
          .ant-table-tbody > tr > td {
            padding: 2px 16px 2px 10px !important;
          }
          .ant-table-column-title {
            padding-left: 4px;
          }
          img {
            width: 16px;
          }
          .content-name-column {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #000000;
            .icon-v10-extension-pdf {
              font-size: 18px;
            }
            .relative-name {
              padding-top: 5px;
              padding-left: 2px;
            }
          }
        }
        .column-total-pages {
          width: 20%;
          .content-total-pages-column {
            display: flex;
            align-items: center;
          }
        }
        .column-size {
          .content-size-column {
            font-weight: 400;
            color: #000000;
            display: flex;
            align-items: center;
          }
        }
        .content-dates-files {
          color: #6e7882;
          font-size: 14px;
          font-weight: 300;
          margin-top: 10px;
          .space-dates {
            padding-left: 15px;
            padding-right: 10px;
          }
        }
      }
      .button-blue-light {
        width: 300px;
      }
      .tracker-content-links {
        .ant-checkbox-wrapper .ant-checkbox-inner {
          border-radius: 0%;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #3DD8DB !important;
          border: 3px solid #3DD8DB;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
          border-color: #ffffff;
          background-color: transparent !important;
        }
      }
    }
  }
  .tracker-box {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    position: relative;
    padding: 20px;
    margin-top: 40px;
    .new-tracker-form-complete {
      .step-complete {
        .cards {
          justify-content: space-around !important;
          .card {
            align-items: center;
          }
          .icon-speed {
            padding-right: 38px;
            display: flex;
            justify-content: center;
          }
        }
        .badge-wrapper {
          .feedback-response-true {
            font-size: 59px;
            color: #00D4D8;
            margin-bottom: 5px;
          }
          .feedback-response-false {
            font-size: 59px;
            color: #6e7882;
            margin-bottom: 5px;
          }
        }
      }
      .step-complete-end-request {
        .badge-wrapper {
          opacity: 0.6;
          margin-bottom: 70px;
          span {
            color: #6e7882 !important;
          }
          p {
            color: #6e7882 !important;
          }
        }
      }
    }
    .tracker-email-info-steps {
      position: relative;
      padding: 30px;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 40px;
      height: 180px;
      .status-step-finish {
        .ant-steps-item-tail::after {
          border-top: 3px dotted #6D7781;
        }
      }
      .status-link {
        .ant-steps-item-content .ant-steps-item-title {
          padding-top: 20px;
          padding-right: 75px;
          padding-left: 22px;
          font-size: 14px !important;
          position: relative;
          right: 1px;
        }
      }
      .status-link-visited {
        .ant-steps-item-content .ant-steps-item-title {
          padding-left: 6px;
          font-size: 14px !important;
          position: relative;
          right: 3px;
        }
      }
      .status-submitting-data {
        .ant-steps-item-content .ant-steps-item-title {
          padding-right: 70px;
          font-size: 14px !important;
          position: relative;
          right: 10px;
        }
      }
      .ant-steps-dot .ant-steps-item-icon {
        width: 8px;
        height: 8px;
        margin-left: 0px;
        padding-right: 0;
        line-height: 8px;
        background: transparent;
        border: 0;
      }
      .ant-steps {
        height: 40px;
        display: flex;
        align-items: normal;
        margin-left: 0px;
        font-family: 'Roboto';
        margin-bottom: 40px;
        width: 50%;
        .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
          cursor: auto;
        }
        .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
          color: #00d3d8;
        }
        .ant-steps-item-finish {
          .ant-steps-item-tail {
            width: 101% !important;
            margin: 0 0 0 35px;
            opacity: 0.8;
          }
          .ant-steps-item-icon .ant-steps-icon {
            color: #00d3d8;
            font-size: 37px;
          }
          .ant-steps-item-content .ant-steps-item-title {
            color: #00d3d8;
          }
        }
        .ant-steps-item-process {
          .ant-steps-item-content .ant-steps-item-title {
            color: #00d3d8;
          }
        }

        .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
          font-size: 37px;
        }
        .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
          font-size: 37px;
          color: #00d3d8;
        }
        .ant-steps-item {
          overflow: visible;
        }
        .ant-steps-item-content {
          display: block;
          width: 215px;
          margin-top: 8px;
          text-align: start;
          left: -12px;
          .ant-steps-item-title {
            color: #6D7781;
            font-weight: 500;
            font-size: 12px;
            padding-top: 20px;
            padding-right: 75px;
            opacity: 0.8;
          }
          .ant-steps-item-description {
            font-weight: 500;
            color: #6D7781;
            opacity: 0.6;
            display: grid;
            height: auto;
            max-height: 60px;
            width: 130px;
            overflow: auto;
            .status-pending {
              padding-left: 12px;
            }
            .status-finish-info {
              padding-left: 4px;
              opacity: 1;
            }
          }
          ant-steps-item-description:hover {
            color: #00d3d8;
          }
        }
        .ant-steps-item-tail {
          top: 2px;
          width: 97% !important;
          margin: 0 0 0 29px;
          padding: 0;
        }
        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
          background-color: #ffffff;
          border-top: 3px dotted #6D7781;
          opacity: 0.6;
        }
        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
          background-color: #ffffff;
          border-top: 3px dotted #00d3d8;
          opacity: 0.8;
          border-spacing: 1px;
        }
        .ant-steps-item-wait .ant-steps-item-tail {
          opacity: 0.6;
        }
        .ant-steps-item-wait .ant-steps-item-tail:after {
          background-color: #ffffff;
          border-top: 3px dotted #6D7781;
          height: 1px;
          width: calc(105% - 40px);
          margin-left: 11px;
        }
        .ant-steps-label-vertical .ant-steps-item {
          overflow: visible;
        }
      }
    }
    .fa {
      font-size: 40px;
    }
    .ant-steps {
      height: 35px;
      display: flex;
      align-items: flex-end;
      margin-left: -40px;
      font-family: 'Roboto';
      margin-bottom: 20px;

      .ant-steps-item-content {
        position: absolute;
        top: 0;
        width: 115px;
      }

      .ant-steps-item-finish {
        .ant-steps-item-tail {
          opacity: 0.2;
          top: 2px;
          width: 100%;
          margin: 0 0 0 69px;
          &:after {
            background-color: #6D7781;
            height: 1px;
            width: calc(100% - 40px);
            margin-left: 7px;
          }
        }
        .ant-steps-item-tail:hover {
          color: #00d3d8 !important;
        }

        .ant-steps-item-icon {
          .ant-steps-icon {
            color: #6D7781;
          }
        }

        .ant-steps-item-content {
          .ant-steps-item-title {
            color: #6e7782;
            font-weight: 500;
            font-size: 12px;
            padding-top: 20px;
          }
          .ant-steps-item-title:hover {
            color: #00d3d8 !important;
          }
        }
        .ant-steps-item-content:hover {
          color: #00d3d8 !important;
        }
      }

      .ant-steps-item-wait {
        .ant-steps-item-tail {
          opacity: 0.2;
          width: 100%;
          &:after {
            background-color: #6D7781;
            height: 1px;
            width: calc(100% - 40px);
            margin-left: -1px;
          }
        }

        .ant-steps-item-icon {
          .ant-steps-icon {
            color: #c6c8ca;
          }
        }

        .ant-steps-item-content {
          .ant-steps-item-title {
            color: #c6c8ca;
            font-weight: 500;
            font-size: 12px;
            padding-top: 20px;
          }
          .ant-steps-item-title:hover {
            color: #00d3d8 !important;
          }
        }
      }

      .ant-steps-item-icon > .ant-steps-icon {
        top: -19px;
        left: -2px;
      }

      .active-step {
        .ant-steps-item-icon {
          .ant-steps-icon {
            [class^="icon-v2-"]:before {
              background: #FFFFFF;
              border-radius: 35px;
              color: #00d3d8;

            }
            [class^="icon-v10-"]:before {
              background: #FFFFFF;
              border-radius: 35px;
              color: #00d3d8;

            }
          }
        }

        .ant-steps-item-content {
          .ant-steps-item-title {
            color: #00d3d8;
            font-weight: 500;
            font-size: 12px;
            padding-top: 20px;
            display: inline-flex;
          }
        }
      }

      .selected-step {
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: #1DCBCF;
            font-weight: 400;
          }

        }
      }
      .finish-step {
          color: #6e7882;
      }
      .finish-step:hover {
        color: #1DCBCF !important;
      }
    }

    .tracker-form-request {
      .tracker-step-content-request {
        .content-buttons-initial-request {
          .spin-send-request {
            margin-right: 30px;
          }
          .spin-end-request {
            margin-right: 50px;
          }
          .button-blue-light:disabled {
            opacity: 0.6;
            cursor: not-allowed;
            &:hover {
              background-color: #00D3D8;
              color: #ffffff;
              border: 1px solid #00D3D8;
            }
          }
          .button-end-request {
            height: 48px;
            border: 1px solid #000000;
            background-color: #ffffff;
            color: #000000;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            font-weight: 900;
            font-size: 20px;
            padding: 10px 30px;
            text-transform: uppercase;
            border-radius: 40px;
            margin-right: 40px;
            width: 300px;
            &:hover {
              background-color: #000000;
              color: #ffffff;
              border: 1px solid #000000;
            }
          }
          .button-end-request:disabled {
            opacity: 0.6;
            cursor: not-allowed;
            border: 1px solid #000000 !important;
            &:hover {
              background-color: #ffffff;
              color: #000000;
              border: 1px solid #000000 !important;
            }
          }
        }
        .tracker-form-group-request {
          .valid-date {
            color: red;
            font-size: 16px;
          }
          .ant-input:focus {
            box-shadow: none !important;
            border-bottom: solid 2px #00D3D8 !important;
            //color: #00D3D8 !important;
            .ant-input-affix-wrapper {
              .icon-edit {
                color: #00D3D8;
              }
              .icon-v10-edit-b {
                color: #00D3D8;
              }
          }
            .ant-input-affix-wrapper .ant-input-suffix {
              color: #00D3D8;
            }
          }
          .ant-input-affix-wrapper .ant-input-suffix {
            //color: #f5222d;
            &:focus {
              color: #00D3D8;
            }
          }
          .edit-input-focus {
            color: #00D3D8;
          }
          .edit-input {
            position: absolute;
            //top: 148px;
            right: 25px;
            span {
              position: absolute;
              top: -27px;
              right: 2px;
              cursor: pointer;
            }
          }
          input {
            border: none !important;
            border-bottom: solid 2px #6e7882 !important;
            background-color: #fafafa !important;
            color: #222B38 !important;
            opacity: 1 !important;
            border-radius: 0px !important;
            &:focus {
              border: none !important;
            }
          }
          label {
            color: #6e7882 !important;
          }
        }
      }
    }
    .tracker-form {
      .content-message-documents {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        .send-securely-message {
          width: 137px;
          height: 21px;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6E7882;
        }
      }
      .contain-email-status {
        display: flex;
        color: #00d3d8;
        padding-top: 5px;
        .icon-check {
          font-size: 16px;
          font-weight: bold;
        }
        .icon-check-two {
          margin-left: 22px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .tracker-acknowledge-info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-right: 20px;
        font-size: 14px;
        font-weight: 300;
        color: #000000;
        .email-sent {
          width: 45px;
          height: 21px;
          font-size: 16px;
          font-weight: bold;
          color: #00d3d8;
          display: block;
        }
        .email-opened {
          width: 40px;
          height: 21px;
          font-size: 16px;
          font-weight: bold;
          color: #be0000;
          display: block;
        }

        .content-pending {
          .info-pending {
              width: 86px;
              height: 26px;
              font-size: 20px;
              font-weight: bold;
              color: #be0000;
          }
        }
        .anticon {
          font-size: 16px;
          font-weight: bold;
        }
        .content-email-status {
          padding-bottom: 20px;
          .info-email-status {
            width: 78px;
            height: 19px;
          }
        }

        .content-link-status {
          .info-link-status {
            width: 78px;
            height: 19px;
          }
        }

        .content-data-status {
          .info-data-status {
            width: 100px;
            height: 19px;
          }
          .contain-data-status {
            display: flex;
            color: #00d3d8;
            padding-top: 5px;
            .email-sent {
              display: contents;
            }
            .anticon {
              padding-left: 5px;
            }
          }
        }

        .tracker-content-icons {
          .tracker-icons-visible-content {
              width: 18px;
              height: 11px;
            cursor: pointer;
            .fa {
              color: #6e7882;
              font-size: 30px;
            }
          }
        }

      }

      .tracker-acknowledge-info-content {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-top: 1px solid #E6E6E6;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;

        .display-form-link-status {
          display: grid;
          padding-left: 186px;
          padding-top: 20px;
          width: 30%;
        }
        .display-email-status {
          display: grid;
          padding-top: 20px;
          width: 35%;
          padding-left: 359px;
        }
        .display-form-data-status {
          padding-left: 117px;
          width: 35%;
          padding-top: 20px;
          display: grid;
        }
      }

      .tracker-upload-container {
        margin-top: 1%;
        display: flex;
        overflow: hidden;

        &__ai-message {
          padding: 15px;
        }
      }

      .button-discard-changes {
        padding-top: 20px;
      }

      .ant-upload-list-item-info {
        display: flex;
        align-items: center;
      }

      .title-upload {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .text-container {
        margin-top: 1rem;
        margin-right: -20px;
        margin-left: 1px;
        display: inline;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
      }

      .button-red--search {
        width: 18%;
        height: 100%;
        margin-top: 20px;
        display: flex;
        margin-left: 20px;
        text-align: center;
        display: inline-block;
      }

      .tracker-content-dropdown {
        margin-left: 2%;

        .tracker-dropdown {
          margin: 2% 2% 2% 0;

          .ant-select {
            width: 19.5%;

            .ant-select-selection {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50% #FFFFFF;
              border: none;
              border-radius: 5px;
              box-shadow: 0 2px 4px 0 #DCDCDC;
              font-size: 16px;
              height: 42px;
              padding: 10px 25px 10px 10px;
              outline: none;
              width: 100%;

              &__rendered {
                display: block;
                margin-right: 11px;
                line-height: 30px;
                margin-top: -5px;
                margin-left: 0;
              }
            }
          }
        }
      }

      .validation-check-id {
        margin-bottom: 2%;
        font-size: 14px;
        font-weight: bold;
        color: #bd2130;
      }

      .tracker-radio {
        margin: 2% 2% 2% 0%;
        h3 {
          margin-bottom: 10px;
        }
        span.ant-radio + * {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6E7882;
          text-transform: uppercase;
          top: 0;
        }
        .ant-radio {
          display: none;
        }
        .ant-radio-wrapper-checked {
          z-index: 2;
          span.ant-radio + * {
              font-family: Roboto;
              font-size: 18px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #FFFFFF;
            text-transform: uppercase;
          }
        }
        .ant-radio-wrapper {
          width: 100px;
          margin-right: 0;
          text-align: center;
          padding: 8px 15px;
        }
        .custom-radio__yes {
          .ant-radio-wrapper {
            margin-right: -32px;
            span.ant-radio + * {
              padding-right: 24px;
              padding-left: 8px;
            }
          }
          .ant-radio-wrapper-checked {
            span.ant-radio + * {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
        .custom-radio__no {
          .ant-radio-wrapper {
            margin-right: -32px;
            span.ant-radio + * {
              padding-right: 0;
              padding-left: 15px;
            }
          }
          .ant-radio-wrapper-checked {
            span.ant-radio + * {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
        .custom-radio-disabled__yes {
          .ant-radio-wrapper-checked {
            z-index: 3;
            opacity: 3;
              background-color: #6E7882;
            span.ant-radio + * {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
      }

      .received-proof-documents {
          width: 250px;
          height: 21px;
          font-size: 16px;
          font-weight: 500;
          color: #6E7882;
      }

      .osprey_red {
        color: red;
        font-weight: 400;

      }

      .osprey_blue {
        color: #3DD8DB;
        font-weight: 400;

      }

      .margin_table {
        margin-left: 40px;
      }

      .tracker-audit-info {
        display: grid;
        grid-template-columns: 15% 15% 70%;
        width: 95%;
        margin: 1% auto;
        grid-gap: 0.9vh;

        &__email-sent {
          margin-left: 3%;
          margin-top: 1%;

          &--left {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
          }

          &--right {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }

        &__email-sent {
          margin-left: 3%;
          margin-top: 1%;

          &--left {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
          }

          &--right {
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }

        &__email-status {
          margin-left: 3%;
          margin-top: 1%;

          &--left {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
          }

          &--middle {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 2;
            grid-column-end: 3;
          }

          &--right {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }

        &__email-link-status {
          margin-left: 3%;
          margin-top: 1%;

          &--left {
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 2;
          }

          &--middle {
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 2;
            grid-column-end: 3;
          }

          &--right {
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }

        &__email-submit {
          margin-left: 3%;
          margin-top: 1%;

          &--left {
            grid-row-start: 4;
            grid-row-end: 5;
            grid-column-start: 1;
            grid-column-end: 2;
          }

          &--middle {
            grid-row-start: 4;
            grid-row-end: 5;
            grid-column-start: 2;
            grid-column-end: 3;
          }

          &--right {
            grid-row-start: 4;
            grid-row-end: 5;
            grid-column-start: 3;
            grid-column-end: 4;
          }
        }
      }

      table {
        margin-left: 24px;
        margin-top: 20px;

        thead {
          th {
            font-weight: 400;
            padding-right: 25px;
          }

          tbody {
            tr {
              margin-bottom: 0 !important;
            }

            th {
              font-weight: 400;
            }
          }
        }
      }

      .tracker-header {
        border-bottom: 1px solid #E6E6E6;
        padding: 20px;

        &__top {
          display: flex;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          &__step {
            font-size: 18px;
          }
        }
        &__top-business {
          display: flex;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          padding-top: 30px;
        }

        .tracker-title {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 0;
        }

        .button-blue {
          width: auto;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 14px;
          padding: 10px 30px;
          text-transform: uppercase;

          &:hover {
            background-color: #FFFFFF;
            color: #3DD8DB;
          }
        }

        .archiveButton {
          margin-right: 20px;
        }
      }
      .tracker-step-header {
        padding-bottom: 50px;
        &__top {
          display: flex;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: space-between;
          justify-content: space-between;

          &__step {
            padding-top: 15px;
            width: 400px;
            height: 21px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            .icon-v14-radio-button-off {
              margin-left: 10px;
              font-size: 15px;
              color: rgba(0, 0, 0, 0.65);
            }
            .icon-v14-question {
              margin-left: 5px;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }

      .tracker-step-content {
        width: 100%;
        margin-top: 1%;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-family: Roboto;

        .table-documents {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .image-document {
            padding-top: 15px;
            width: 5%;
            img {
              height: 32px;
            }
          }
          .name-document {
           margin-top: 15px;
            width: 50%;
            height: 19px;
            font-size: 14px;
            font-weight: bold;
            color: #000000;
          }
          .number-page {
            margin-top: 15px;
            width: 20%;
            height: 19px;
            font-size: 14px;
            font-weight: 300;
            color: #000000;
          }
          .size-document {
            margin-top: 15px;
            width: 20%;
            height: 19px;
            font-size: 14px;
            font-weight: 300;
            color: #000000;
          }
          .icon-document {
            margin-right: 20px;
            font-size: 30px;
            width: 5%;
            .image-success {
              color: #3DD8DB;
              font-size: 29px;
              font-family: 'FontAwesome';
              background-color: #FFFFFF;
              border: solid 1px #170808 !important;
              border-radius: 30px;
            }
            .image-success::after {
              content: "\f00c";
              color: #3DD8DB;
              font-size: 28px;
              width: 9px;
              height: 8px;
              font-family: 'FontAwesome';
              top: 1.9px;
              left: 1.5px;
              border-radius: 30px;
              background-color: #FFFFFF;
              border: solid 3px #3DD8DB !important;
            }
            .redact-icon {
              color: #00d3d8;
              border: solid 1px #170808 !important;
              border-radius: 30px;
              width: 32px;
              height: 31.9px;
              display: flex;
            }
            .redact-icon-false {
              color: #1890ff;
              border: solid 1px #170808 !important;
              border-radius: 30px;
              width: 32px;
              display: flex;
            }
            [class^="icon-v2"]:before {
              margin: 0;
            }
          }
        }

        .form-buttons {
          margin-bottom: 40px !important;
          margin-top: 20px;
          .button-blue-light {
            margin-bottom: 20px;
            border: 1px solid #3DD8DB;
            background-color:#3DD8DB;
            color: #FFFFFF;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            font-weight: 900;
            font-size: 20px;
            padding: 10px 30px;
            text-transform: uppercase;
            border-radius: 30px;
            &:hover {
              background-color: #FFFFFF;
              color: #3DD8DB;
            }
          }
        }
        .tracker-form-group {
          min-height: 1rem;
          margin-bottom: 20px;
          width: 100%;
          .error_item {
            color: red;
          }

          input {
            box-sizing: border-box;
            padding: 10px;
            width: 100%;
            opacity: 0.4;
            font-size: 14px;
            font-weight: 300;
            color: #000000;
            border-radius: 3px;
            border: solid 1px rgba(110, 120, 130, 0.4);
            background-color: rgba(0, 0, 0, 0);
          }
          label {
            height: 21px;
            font-size: 16px;
            font-weight: 500;
            color: #6e7887;
            margin-bottom: 8px;
          }
        }
        .form-buttons-next {
          margin-top: 20px;
          height: 50px;
          .button-blue-light {
            width: 169px;
            height: 50px;
            margin-bottom: 20px;
            border: 1px solid #3DD8DB;
            background-color:#3DD8DB;
            color: #FFFFFF;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            font-weight: 900;
            font-size: 20px;
            padding: 10px 30px;
            text-transform: uppercase;
            border-radius: 30px;
            &:hover {
              background-color: #FFFFFF;
              color: #3DD8DB;
            }
          }
        }
        .tracker-content-links{
          width: 100%;
          display: grid;
          place-content: flex-end;
          padding-top: 20px;
          padding-bottom: 20px;

          .tracker-form-link {
            flex-direction: row-reverse;
            display: block;
            cursor: pointer;
            padding-bottom: 10px;
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: #3dd8db;
                border-color: #3dd8db;
              }
            }
            .send-form {
              margin-left: 10px;
              font-size: 14px;
              font-weight: 300;
              color: #000000;
            }
          }
        }

        .tracker-switch {
          margin: 2% 2% 2% 0%;
          display: flex;
          align-content: space-between;
          width: 100%;
          align-items: flex-end;
          .content-switch {
            width: 80%;
            padding-bottom: 10px;
            h3 {
              margin-bottom: 10px;
            }
          }
          .content-button {
            width: 79%;
            .button-blue-light {
              border: 1px solid #3DD8DB;
              background-color: #3DD8DB;
              color: #FFFFFF;
              box-shadow: 0 2px 4px 0 #DCDCDC;
              cursor: pointer;
              font-weight: 900;
              font-size: 20px;
              padding: 10px 30px;
              text-transform: uppercase;
              border-radius: 25px;
              float: right;
              &:hover {
                background-color: #FFFFFF;
                color: #3DD8DB;
                border: 1px solid #3DD8DB;
              }
            }
          }
        }

        h3 {
            width: 400px;
            height: 21px;
            font-size: 16px;
            font-weight: 500;
            color: #6e7882;
        }

        .tracker-content-notes {
          margin-left: 2%;
        }
        .form_check {
          padding-top: 10px !important;
          .contain-picture-form {
            display: flex;
            justify-content: space-between;
          }
        }
        .tracker_formGroup {
          min-height: 1rem;
          width: 100%;
          padding-top: 30px;
          padding-bottom: 30px;
          .buttonReject {
            margin-top: 20px;
            margin-bottom: 50px;
            .button-blue-light {
              font-size: 20px;
              font-weight: 900;
              text-align: center;
              color: #3dd8db;
              &:hover {
                background-color: #3DD8DB;
                color: #FFFFFF;
                border: 1px solid #3DD8DB;
              }
            }
          }

          .input-checked-id-disabled {
            opacity: 0.6;
          }
          .inputCheckId {
            width: 100%;
            display: inline-block;
            margin-top: 20px;
            input:disabled {
              cursor: not-allowed;
            }
            .field_input {
              width: 100%;
              display: flex;
            }
            .text_area_resend:focus {
              border: transparent !important;
            }
            .text_area_resend {
              &:focus {
                border: transparent !important;
              }
            }

            textarea {
              border-radius: 3px;
              border: solid 1px rgba(110, 120, 130, 0.4);
              margin-bottom: 20px;
              box-sizing: border-box;
              color: #555555;
              font-size: 18px;
              font-weight: 300;
              padding: 0px;
              width: 100%;
              background-color: #FFFFFF;
            }
            textarea:focus {
              border: none !important;
            }
          }

          label {
            display: block;
            margin-bottom: 10px;
            width: 43px;
            height: 21px;
            font-size: 16px;
            font-weight: 500;
            color: #6e7882;
          }
          .textArea_checkId {
            box-sizing: border-box;
            color: #555555;
            font-size: 18px;
            font-weight: 300;
            width: 100%;
            //height: 92px;
            border-radius: 0px;
            //border: solid 1px rgba(110, 120, 130, 0.4);
            border: none;
            border-bottom: solid 2px #6e7882;
            background-color: #fafafa;

            &:focus {
              outline: 2px solid transparent;
              border: none;
              border-bottom: solid 2px #3DD8DB;
            }
          }

        }
        .tracker-content-radio {
          .tracker-radio-disbled {
            .ant-radio-checked .ant-radio-inner {
              border: solid 3px #6e7882 !important
            }
            .ant-radio {
              .ant-radio-inner {
                border: solid 1px #6e7882;
              }
              .ant-radio-inner::after {
                content: "\f00c" !important;
                color: #6e7882 !important;
                font-size: 7px !important;
                font-family: 'FontAwesome' !important;
                top: 1.9px;
                left: 1.5px;
                background-color: #FFFFFF;
                width: 7px;
                height: 7px
              }
            }
            .radion-style {
              display: block;
              height: 30px;
              lineHeight: 30px;
            }
          }
          .tracker-radio-check {
            .ant-radio-checked .ant-radio-inner {
              border: solid 3px #3DD8DB;
            }
            .ant-radio {
              .ant-radio-inner {
                border: solid 1px #6e7882;
              }
              .ant-radio-inner::after {
                content: "\f00c" !important;
                color: #ffffff !important;
                font-size: 9px !important;
                font-family: 'FontAwesome' !important;
                top: 1.9px;
                left: 1.7px;
                background-color: #3DD8DB;
              }
            }
            .radion-style {
              display: block;
              height: 30px;
              lineHeight: 30px;
            }
          }
        }
        .picture-search {

          display: flex;
          margin-left: 10px;
          width: 100%;

          .ant-upload-select-picture-card {
            display: inline-flex;
            width: 100%;
            height: 3rem !important;
            margin-top: 17px;
            margin-right: 8px;
            margin-bottom: 8px;
            text-align: center;
            vertical-align: top;
            background-color: #FFFFFF;
            border: 1px dashed #d9d9d9;
            border-radius: 4px;
            cursor: pointer;
            -webkit-transition: border-color 0.3s ease;
            transition: border-color 0.3s ease;

          }
          .anticon {
            font-size: 20px;
            color: #6e7882;
            opacity: 0.6;
            margin-bottom: 10px;
          }
          .ant-upload-picture-card-wrapper {
            zoom: 2;
            display: inline-block;
            width: 100%;
            margin-right: 19px;
          }
          .ant-upload-list-item {
            display: none !important;
          }
          .ant-upload.ant-upload-select-picture-card > .ant-upload {
           margin-bottom: 10px !important;
            padding: 1px !important;
          }
          .list-picture-container {
            display: inline;
            width: 100%;
            margin-right: 20px;
            .title-upload-documents {
              display: flex;
              font-size: 16px;
              font-weight: 500;
              color: #637882;
              margin-top: 20px;
            }
            .view-cards-container {
              margin-right: 20px;
              margin-top: 1rem;
              width: 190px;
              display: inline-block;

              .ant-upload-list-item {
                display: none;
              }


              .ant-col {
                margin-bottom: 10px;
              }

              .ant-upload {
                height: 304px !important;
                width: 190px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 6px;
                padding: 1px !important;
              }

              .ant-card-cover {
                height: 211px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
              }

              .image-loading-container {
                height: 211px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px;

                .ant-spin-text {
                  color: #238083;
                }

                .ant-spin-dot {
                  i {
                    background-color: #238083;
                  }
                }

                .error-message {
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: center;
                  padding: 5px;

                  i {
                    font-size: 5em;
                    color: red;
                  }

                  span {
                    font-size: 16px;
                    color: red;
                    text-align: center;
                  }
                }
              }

              .remove-document-icon {
                position: absolute;
                z-index: 2;
                margin-left: 176px;
                background: white;
                font-size: 20px;
                cursor: pointer;
                margin-top: -7px;
              }
            }
          }
        }
        .buttons-search-complete {
          margin-bottom: 30px;
        }
        .tracker-content-complete {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            color: #000000;
            padding-bottom: 50px;
          .anticon {
            display: block;
            font-size: 32px;
            font-weight: 900;
          }
          .content-text {
            padding-top: 20px;
            display: flex;
            width: 100%;
            justify-content: center;
          }
          span {
            display: inline-block;
            font-weight: 500;
            color: #6e7782;
          }
          .content-keep {
            color: #000000;
            font-weight: bold;
          }
        }
        .button-blue-disabled {
          border: none;
          color: #6E7882;
          background-color: rgba(110, 120, 130, 0.4);
          width: 180px;
          height: 50px;
          border: 1px solid rgba(110, 120, 130, 0.4);
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-weight: 900;
          font-size: 20px;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 25px;
          float: right;
        }
      }
      .tracker-content-button-right {
        height: 20px;
        .button-check-id {
          width: 21% !important;
        }
        .button-loading-wrapper {
          .button-check-id-loading {
            background-color: #b1eff0;
            border: 1px solid #b1eff0;
            pointer-events: none;
            padding: 10px 30px 10px 35px;
          }
          .ant-spin-spinning {
            position:absolute;
            right: 229px;
            bottom: 70px;
          }
        }
      }
      .tracker-content-button {
          margin-bottom: 70px;
        .button-end-request {
          height: 50px;
          border: 1px solid #000000;
          background-color: #ffffff;
          color: #000000;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-weight: 900;
          font-size: 20px;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 40px;
          &:hover {
            background-color: #000000;
            color: #ffffff;
            border: 1px solid #000000;
          }
        }
        .button-check-id {
          width: 10%;
        }
        .search-and-find-loading-container {
          display: flex;
          font-size: 18px;
          font-weight: 400;
          color: #238083;
          line-height: 1.5;
          justify-content: flex-end;

          .ant-spin-text {
            color: #238083;
          }

          .ant-spin-dot {
            i {
              background-color: #238083;
            }
          }
        }
        .button-blue-light {
          height: 50px;
          border: 1px solid #3DD8DB;
          background-color: #3DD8DB;
          color: #FFFFFF;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-weight: 900;
          font-size: 20px;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 40px;
          &:hover {
            background-color: #FFFFFF;
            color: #3DD8DB;
            border: 1px solid #3DD8DB;
          }
        }
        .button-blue-next {
          height: 50px;
          border: 1px solid #3DD8DB;
          background-color: #3DD8DB;
          color: #FFFFFF;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-weight: 900;
          font-size: 20px;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 40px;
          width: 13%;
          &:hover {
            background-color: #FFFFFF;
            color: #3DD8DB;
            border: 1px solid #3DD8DB;
          }
        }
      }

      .tracker-input {
        .tracker-content-links {
          .tracker-form-new-link {
              .ant-checkbox-wrapper {
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: #3DD8DB !important;
                  border: 3px solid #3DD8DB;
                }
                .ant-checkbox-checked .ant-checkbox-inner:after {
                  border-color: #ffffff;
                }
                .ant-checkbox-inner {
                  border-radius: 0%;
                }
              }
          }
        }
        input {
            background-color: rgba(0, 0, 0, 0.02) !important;
        }
        .tracker-form-group {
          input {
            border: none !important;
            border-bottom: solid 2px #6e7882 !important;
            background-color: #fafafa !important;
            color: #222B38 !important;
            opacity: 1 !important;
            border-radius: 0px !important;
          }
          .ant-input:focus {
            box-shadow: none !important;
            border-bottom: solid 2px #00D3D8 !important;
          }
          .edit-input-form {
            position: absolute;
            right: 25px;
            span {
              position: absolute;
              top: -27px;
              right: 2px;
            }
          }
          .edit-input-form-focus {
            color: #00D3D8;
          }
        }
      }

      .tracker-content {
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        margin-top: 1%;

        .tracker-step {
          font-weight: 500;
          margin-bottom: 7px;
        }

        .form_check {
          height: 100%;
          margin-left: 2%;
        }

        .form-check-email {
          margin-top: 30px;
          display: flex;
          height: 100%;
          margin-bottom: 25px;
        }

        .textArea_checkId {
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          box-sizing: border-box;
          color: #555555;
          font-size: 18px;
          font-weight: 300;
          padding: 10px;
          width: 60%;
          resize: vertical;

          &:focus {
            outline: 2px solid #3DD8DB;
          }
        }

        .tracker-content-notes {
          margin-left: 2%;
        }

        .tracker_formGroup {
          min-height: 1rem;
          margin-bottom: 20px;
          width: 100%;

          .questionCompleteSar {
            display: block;
          }

          .checkbox-areas {
            background-color: #FFFFFF;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            display: inline-block;
            height: 26px;
            position: relative;
            width: 26px;
            margin-top: 0;
            margin-bottom: 0;

            .checkboxLabelChecked {
              margin-left: 2rem;
            }
          }

          .text_area {
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            box-sizing: border-box;
            color: #555555;
            font-size: 18px;
            font-weight: 300;
            padding: 10px;
            width: 100%;
            resize: vertical;

            &:focus {
              outline: 2px solid #3DD8DB;
            }
          }

          label {
            color: #555;
            display: block;
            font-size: 14px;
            margin-bottom: 10px;
            font-weight: 300;
          }

          input {
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            box-sizing: border-box;
            color: #555555;
            font-size: 18px;
            font-weight: 300;
            padding: 10px;
            width: 100%;

            &:focus {
              outline: 2px solid #3DD8DB;
            }
          }
          input[type=checkbox] {
            overflow: visible !important;
            visibility: visible !important;
          }

          &__requested {
            color: #555;
            font-size: 0.875em;
            font-weight: bold;
            margin-right: 5px;
          }


        }

        //.inputCheckId {
        //  width: 100%;
        //  display: inline-block;
        //  margin-top: 20px;
        //
        //  textarea {
        //    width: 36%;
        //    margin-bottom: 20px;
        //    border: 1px solid #CCCCCC;
        //    border-radius: 5px;
        //    box-sizing: border-box;
        //    color: #555555;
        //    font-size: 18px;
        //    font-weight: 300;
        //    padding: 0px;
        //  }
        //}

        .styleTitleAnswer {
          margin-bottom: 20px;
        }

        .buttonsAnswer {
          margin-bottom: 20px;
        }

        .buttonsCheckId {
          margin-bottom: 70px;
        }

        .buttons-search-complete {
          margin-bottom: 70px;
        }

        .picture-check-id {
          height: 120px;
        }
      }
      .tracker-footer-update {
        button:disabled {
          background-color: #6e7882;
          color: #FFFFFF;
          cursor: not-allowed;
          opacity: 0.6;
        }
        button:disabled:hover {
          background-color: #6e7882;
          color: #FFFFFF;
        }
      }
      .tracker-footer {
        width: 100%;
        padding-left: 2%;
        margin-top: 2%;
        display: flex;
        justify-content: flex-end;

        .button-blue-light {
          border: 1px solid #3DD8DB;
          background-color: #3DD8DB;
          color: #FFFFFF;
          width: 189px;
          height: 50px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 900;
          font-size: 20px;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 30px;

          &:hover {
            background-color: #FFFFFF;
            color: #3DD8DB;
          }
        }
        .button-create-sar-loading {
          border: 1px solid #b1eff0;
          color: #FFFFFF;
          width: 189px;
          height: 50px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 900;
          font-size: 20px;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 30px;
          padding: 10px 30px 10px 40px;
          background-color: #b1eff0;
          pointer-events: none;
        }
        .ant-spin-spinning {
          position: absolute;
          bottom: 31px;
          right: 200px;
        }
      }
    }

    .signature_image {
      width: 100%;
      height: auto;
      margin-top: 20px
    }
  }
}

.full_width {
  width: 100%;
}
.tracker_formGroup-tenant {
  width: 100%;
  input {
    border: none !important;
  }
}
.new-tracker-content-links {
  .tracker-notification-email {
    .ant-checkbox-wrapper .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
      border-color: #3DD8DB !important;
      background-color: #FFF !important;
    }
    .ant-checkbox-wrapper .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after {
      border-color: #3DD8DB;
    }
    .new-send-form {
      opacity: 0.8 !important;
    }
    .ant-checkbox-disabled + span {
      color: #6E7882;
      cursor: not-allowed;
    }
  }
  .tracker-form-link {
    .new-send-form {
      color: #6e7782 !important;
    }
  }
  .ant-checkbox-wrapper {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #3DD8DB !important;
      border: 3px solid #3DD8DB;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: #ffffff;
    }
    .ant-checkbox-inner {
      border-radius: 0%;
    }
  }
}

.tracker-title {
  margin-bottom: 15px;
}

.completed {
  font-weight: bold;
  color: green;
  font-size: 17px;
}

.incomplete {
  font-weight: bold;
  color: red;
  font-size: 17px;
}

.button-blue-light {
  border: 1px solid #3DD8DB;
  background-color: #FFFFFF;
  color: #3DD8DB;
  width: auto;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 20px;

  &:hover {
    background-color: #3DD8DB;
    color: #FFFFFF;
  }
}

.button-sendEmail {
  margin-left: 30px;
  width: 20%;
  text-transform: initial;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.tracker_formGroup {


  .checkbox-area {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    height: 26px;
    position: relative;
    width: 26px;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    margin-left: 0;

    span {
      margin: 3px 33px;
      font-weight: bold;
    }
  }

  .checkboxLabel {
    background-color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    height: 18px;
    left: 3px;
    position: absolute;
    top: 3px;
    transition: all .5s ease;
    width: 18px;
    z-index: 1;
  }

  .checkboxLabelChecked {
    @extend .checkboxLabel;
    background-color: #3DD8DB;
  }
}

#signature_form {
  height: 50%;
  width: 50%;
}

.Radio {
     display: flex;
     align-items: center;
     margin-bottom: 40px;
  h3 {
      height: 21px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6e7882;
       margin-right: 40px;
  }
  .tracker-switch {
    margin: 2% 2% 2% 0%;
    display: flex;
    align-content: space-between;
    width: 100%;
    align-items: flex-end;
    .content-switch {
      width: 80%;
      padding-bottom: 10px;
      h3 {
        margin-bottom: 10px;
      }
    }
    .content-button {
      width: 20%;
    }
    .button-blue-light {
      width: 180px;
      height: 50px;
      border: 1px solid #3DD8DB;
      background-color: #3DD8DB;
      color: #FFFFFF;
      font-family: Roboto;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      cursor: pointer;
      font-weight: 900;
      font-size: 20px;
      padding: 10px 30px;
      text-transform: uppercase;
      border-radius: 25px;
      float: right;
    }
    button.switch {
      display: flex;
      justify-content: space-between;
      text-align: center;
      width: 164px;
      height: 37px;
      border-radius: 18.5px;
      border: solid 1px #979797;
      background-color: #FFFFFF;
    }

    button.switch span {
      pointer-events: none;
      height: 37px;
    }

    [role="switch"][aria-checked="false"] :first-child,
    [role="switch"][aria-checked="true"] :last-child {
      width: 88px;
      height: 35px;
      border-radius: 18.5px;
      background-color: #00d3d8;
      font-size: 18px;
      font-weight: 300;
      color: #FFFFFF;
      padding-top: 6px;
      text-align: center;
    }

    [role="switch"][aria-checked="false"] :last-child,
    [role="switch"][aria-checked="true"] :first-child {
      width: 80px;
      height: 35px;
      opacity: 0.3;
      font-size: 18px;
      font-weight: 300;
      color: #000000;
      padding-top: 6px;
      text-align: center;
    }
  }
}

.Dropdown {
  margin: 20px 0;
}

.wordComplete {
  background-color: #eee;
  border: 1px solid #CCC;
  border-radius: 40px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #CCC;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
  cursor: auto;
}

.wordIncomplete {
  background-color: #FFFFFF;
  border: 1px solid #DA282C;
  border-radius: 40px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #DA282C;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
  cursor: auto;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .tracker-page .tracker-box .tracker-form .tracker-audit-info {
    grid-template-columns: 25% 25% 50%;
    margin: 1% auto 6%;
  }

  .ant-radio-group {
    width: 100%;
    display: flex !important;
    justify-content: space-around;
  }
}

.custom-radio {
  &__yes {
    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: transparent;
    }

    .ant-radio-wrapper {
      transition: all .5s ease;
      background-color: #FFFFFF;
      border: 1px solid #E0E8E8;
      border-radius: 45px;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      padding: 5px 15px;

      span.ant-radio + * {
        padding-right: 8px;
        padding-left: 8px;
        position: relative;
        top: -2px;
        font-family: 'Ubuntu';
        font-weight: 700;
        font-size: 18px;
        color: #3DD8DB;
      }

      &-checked {
        background-color: #3DD8DB;
        border: 1px solid #E0E8E8;

        span.ant-radio + * {
          color: white;
        }
      }
    }

    .ant-radio {
      border: none;
      width: 25px;
      height: 25px;
      top: 1px;
      left: -7px;

      &-checked {
        border: none;

        &::after {
          animation: none;
          animation-fill-mode: none;
          border: none;
        }
      }

      .ant-radio-inner {
        border: none;
        top: 0;
        left: 0;
        display: block;
        width: 25px;
        height: 25px;
        transition: none;

        &::before {
          background-color: #3DD8DB;
          border-radius: 50%;
          color: #FFFFFF;
          font-family: 'FontAwesome';
          font-size: 16px;
          content: '\F00C';
          height: 25px;
          line-height: 25px;
          position: absolute;
          text-align: center;
          width: 25px;
          left: 0;
          top: 0;
          transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
        }

        &::after {
          background-color: #FFFFFF;
          border-radius: 50%;
          color: #3DD8DB;
          font-family: 'FontAwesome';
          font-size: 16px;
          content: '\F00C';
          height: 25px;
          line-height: 25px;
          position: absolute;
          text-align: center;
          width: 25px;
          left: 0;
          top: 0;
        }
      }
    }
  }

  &__no {
    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: transparent;
    }

    .ant-radio-wrapper {
      background-color: #FFFFFF;
      border: 1px solid #E0E8E8;
      border-radius: 45px;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      padding: 5px 15px;
      transition: all .5s ease;

      span.ant-radio + * {
        padding-right: 8px;
        padding-left: 8px;
        position: relative;
        top: -2px;
        font-family: 'Ubuntu';
        font-weight: 700;
        font-size: 18px;
        color: #3DD8DB;
      }

      &-checked {
        background-color: #3DD8DB;
        border: 1px solid #E0E8E8;

        span.ant-radio + * {
          color: white;
        }
      }
    }

    .ant-radio {
      border: none;
      width: 25px;
      height: 25px;
      top: 1px;
      left: -7px;

      &-checked {
        border: none;

        &::after {
          animation: none;
          animation-fill-mode: none;
          border: none;
        }
      }

      .ant-radio-inner {
        border: none;
        top: 0;
        left: 0;
        display: block;
        width: 25px;
        height: 25px;

        &::before {
          background-color: #3DD8DB;
          border-radius: 50%;
          color: #FFFFFF;
          font-family: 'FontAwesome';
          font-size: 16px;
          content: '\F00D';
          height: 25px;
          line-height: 25px;
          position: absolute;
          text-align: center;
          width: 25px;
          left: 0;
          top: 0;
          transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
        }

        &::after {
          background-color: #FFFFFF;
          border-radius: 50%;
          color: #3DD8DB;
          font-family: 'FontAwesome';
          font-size: 16px;
          content: '\F00D';
          height: 25px;
          line-height: 25px;
          position: absolute;
          text-align: center;
          width: 25px;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.tracker-redacting-radio {
  margin: 2% 2% 0% 1%;

  .redacting-radio {
    &__yes {
      .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: transparent;
      }

      .ant-radio-wrapper {
        transition: all .5s ease;
        background-color: #FFFFFF;
        border: 1px solid #E0E8E8;
        border-radius: 45px;
        display: inline-block;
        margin-right: 10px;
        position: relative;
        padding: 2px 12px;

        span.ant-radio + * {
          padding-right: 8px;
          padding-left: 8px;
          position: relative;
          top: -2px;
          font-family: 'Ubuntu';
          font-weight: 700;
          font-size: 15px;
          color: #3DD8DB;
        }

        &-checked {
          background-color: #3DD8DB;
          border: 1px solid #E0E8E8;

          span.ant-radio + * {
            color: white;
          }
        }
      }

      .ant-radio {
        border: none;
        width: 20px;
        height: 23px;
        top: 0;
        left: -7px;

        &-checked {
          border: none;

          &::after {
            animation: none;
            animation-fill-mode: none;
            border: none;
          }
        }

        .ant-radio-inner {
          border: none;
          top: 2px;
          left: 0;
          display: block;
          width: 0;
          height: 0;
          transition: none;

          &::before {
            background-color: #3DD8DB;
            border-radius: 50%;
            color: #FFFFFF;
            font-family: 'FontAwesome';
            font-size: 12px;
            content: '\F00C';
            height: 20px;
            line-height: 20px;
            position: absolute;
            text-align: center;
            width: 20px;
            left: 0;
            top: 2px;
            transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          }

          &::after {
            background-color: #FFFFFF;
            border-radius: 50%;
            color: #3DD8DB;
            font-family: 'FontAwesome';
            font-size: 12px;
            content: '\F00C';
            height: 20px;
            line-height: 20px;
            position: absolute;
            text-align: center;
            width: 20px;
            left: 0;
            top: 2px;
          }
        }
      }
    }

    &__no {
      .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: transparent;
      }

      .ant-radio-wrapper {
        background-color: #FFFFFF;
        border: 1px solid #E0E8E8;
        border-radius: 45px;
        display: inline-block;
        margin-right: 10px;
        position: relative;
        padding: 2px 12px;
        transition: all .5s ease;

        span.ant-radio + * {
          padding-right: 8px;
          padding-left: 8px;
          position: relative;
          top: -2px;
          font-family: 'Ubuntu';
          font-weight: 700;
          font-size: 15px;
          color: #3DD8DB;
        }

        &-checked {
          background-color: #3DD8DB;
          border: 1px solid #E0E8E8;

          span.ant-radio + * {
            color: white;
          }
        }
      }

      .ant-radio {
        border: none;
        width: 20px;
        height: 23px;
        top: 0;
        left: -7px;

        &-checked {
          border: none;

          &::after {
            animation: none;
            animation-fill-mode: none;
            border: none;
          }
        }

        .ant-radio-inner {
          border: none;
          top: 2px;
          left: 0;
          display: block;
          width: 0;
          height: 0;

          &::before {
            background-color: #3DD8DB;
            border-radius: 50%;
            color: #FFFFFF;
            font-family: 'FontAwesome';
            font-size: 12px;
            content: '\F00D';
            height: 20px;
            line-height: 20px;
            position: absolute;
            text-align: center;
            width: 20px;
            left: 0;
            top: 2px;
            transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          }

          &::after {
            background-color: #FFFFFF;
            border-radius: 50%;
            color: #3DD8DB;
            font-family: 'FontAwesome';
            font-size: 12px;
            content: '\F00D';
            height: 20px;
            line-height: 20px;
            position: absolute;
            text-align: center;
            width: 20px;
            left: 0;
            top: 2px;
          }
        }
      }
    }
  }
}


.custom-modal-body {
  .ant-spin-spinning {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload.ant-upload-select-picture-card {
  height: 140px !important;
}

.step-six {
  margin-right: -20px;
  margin-left: 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .ant-card-bordered {
    margin-bottom: 20px;
    width: 90%;
    border: 2px solid #e8e8e8;
  }

  .document-card {
    display: flex;
    flex-wrap: wrap;

    .list-document-card {
      display: flex;
      margin-right: 20px;
      width: 190px;

      .card-status-icon {
        position: absolute;
        z-index: 1;
        font-size: 40px;
        display: flex;
        margin-left: 150px;
        margin-top: 20px;

        &--finalized {
          color: #4CAF50;
          background: white;
          border-radius: 35px;
          height: 38px;
        }

        &--without-modifications {
          background: white;
          -webkit-text-stroke: 1px white;
          border-radius: 35px;
          height: 38px;
        }

        &--has-modifications {
          color: #1DCBCF;
          background: white;
          border-radius: 35px;
          height: 38px;
        }
      }

      .ai-ready-icon {
        position: absolute;
        z-index: 1;
        margin-left: 156px;
        margin-top: 60px;
        width: 32px;
        height: 32px;
        background-image: url("../../../assets/icons8-brain-32.png");
      }

      .active-card {
        border-color: #1DCBCF;
      }
    }

    .ant-card-cover {
      height: 211px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .ant-card-meta-detail > div:not(:last-child) {
      margin-bottom: 0;
    }

    .ant-card-actions > li > span:hover,
    .anticon-file-protect {
      color: #3DD8DB;
      cursor: auto;
    }

    .anticon-file-unknown {
      color: red;
      cursor: auto;
    }

    .ant-card-meta-detail {
      text-align: center;
    }

    .ant-row {
      width: 100%;
    }
  }
}

/* tile uploaded pictures */
.tracker-content {
  .ant-upload-list-item-name {
    display: block !important;
    position: absolute;
    bottom: 0;
  }
}
.tracker-footer-invite-tenant {
  .btn-invite {
    border: 1px solid #3DD8DB !important;
    color: #ffffff !important;
    background-color: #3DD8DB !important;

    &:hover {
      border: 1px solid #3DD8DB !important;
      color: #3DD8DB !important;
      background-color: #ffffff !important;
    }
  }
  }
.content-form-owner {
  margin-bottom: 30px;
}
.comments-page {
  padding: 10px 10px 10px 10px;
}
.annotation-page {
  padding: 10px 10px 10px 10px;
  .annotation-page-content {
    display: flex;
    cursor: pointer;
    padding-left: 0px;
    height: 30px;
    align-items: center;
    .anticon {
      color: #382e34;
      font-size: 14px;
    }
  }
  .action-redacted-sidebar {
    display: flex;
    .icon-v21-add {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
      color: #382e34;
    }
    .icon-v21-add-active {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
      color: #EDA41E;
    }
  }
  .position-relative {
    padding-left: 0px;
    background-color: #b9cadc;
    font-size: 20px;
    //height: 30px;
    height: auto;
    min-height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    &:hover {
      background-color: #9AE0FC;
    }
  }
  &__title {
    font-weight: 700;
    padding-bottom: 0px;
    text-transform: capitalize;
    font-family: Roboto;
    letter-spacing: 0.2px;
    font-size: 14px;
    color: #382e34;
  }
  &__update {
    //height: 50px;
    width: 190px;
    overflow: auto;
  }
  &__button {
    //width: 90%;
    padding: 5px 0;
    text-align: left;
    font-family: Roboto;
    letter-spacing: 0.2px;
    font-size: 14px;
    color: #382e34;
    font-weight: 400;

    //&:hover {
    //  color: #3DD8DB;
    //  font-weight: 500;
    //}
  }
  &__input {
    width: 100%;
    background-color: #D4D7DA;
    border: none;
    //border-bottom: 1px solid #00D3D8;
    border-radius: 0;
    padding: 5px 5px 5px;
    z-index: 1;
    color: #382e34;
    font-size: 16px;
    font-weight: 400;
    margin-left: 0px;
    &:focus {
      outline: none;
    }
  }
  &__edit {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 30px;
    color: #382e34;
    margin-right: 10px;
  }
  &__edit.anticon {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 0px;
    color: #382e34;
    z-index: 1;
  }

  &__button--active {
    color: #3DD8DB;
    font-weight: 500;
  }
  &__element{
    padding: 1px 0 3px 10px;
    -webkit-transition: width 2s; /* Safari prior 6.1 */
    transition: width 2s;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e7882;
    &--red{
      color: #82070d;
      text-decoration: line-through;
      cursor: not-allowed !important;
    }
    &:hover{
      font-weight: 400;
      cursor: pointer;
    }
    &--active{
      font-weight: 900;
      -webkit-transition: width 2s; /* Safari prior 6.1 */
      transition: width 2s;
    }
  }
  &__loading{
    padding: 20px;
  }
}

.feedback-response {
  font-size: 18px;
  margin-left: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
}
.tracker-header-steps {
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
  color: #6e7882;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  .contain-name-back-step {
    display: flex;
    align-items: center;
    align-content: center;
    .back-step-block {
      span {
        cursor: not-allowed !important;
      }
    }
    .back-step-hover {
      span {
        &:hover {
          color: #3DD8DB;
        }
      }
    }
    .back-step {
      font-size: 24px;
      span {
        cursor: pointer;
      }
    }
    .hover-back-step {
      span {
        &:hover {
          color: #3DD8DB;
        }
      }
    }
    .tracker-fullname-contain {
      padding-left: 30px;
      .tracker-surname {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .contain-save-last-step {
    display: flex;
    align-items: center;
    align-content: center;
    .save-close-contain {
      padding-right: 30px;
      font-size: 18px;
      .save-and-close {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .next-step-block {
      span {
        cursor: not-allowed !important;
      }
    }
    .next-step-disabled {
      .icon-v10-forth {
        cursor: not-allowed;
      }
    }
    .next-step-hover {
      span {
        &:hover {
          color: #3DD8DB;
        }
      }
    }
    .next-step {
      font-size: 24px;
      span {
        cursor: pointer;
      }
    }
  }
  .attach-box-contain {
    align-items: center;
    align-content: center;
    display: flex;
    font-size: 30px;
    .disabled-attach-box {
      cursor: not-allowed !important;
    }
    img {
      width: 40px;
    }
    .disabled-attach {
      cursor: not-allowed !important;
    }
    .attach-box {
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
.modal-import-files {
  display: flex;
  justify-content: center;
  width: 500px;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  .ant-modal-content {
    width: 500px;
    height: auto;
  }
  .modal-import-files-content{
    .header-modal-delete {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 70px;
      margin-top: 30px;
      margin-bottom: 20px;
      .question-delete {
        font-weight: 500;
        font-size: 20px;
        color: #6e7882;
      }
      .name-file {
        font-size: 21px;
        font-weight: bold;
        color: #00d3d8;
        margin-top: 10px;
      }
    }
    .footer-modal-delete {
      margin-bottom: 20px;
      .action-cancel-box {
        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      .action-import-box {
        &:focus {
          border: 1px solid #00D3D8;
          background-color: #FFFFFF;
          color: #00D3D8;
        }
      }
    }
    .header-modal-import {
      padding-top: 30px;
      padding-bottom: 30px;
      .icon-v18-import-1 {
        font-size: 40px;
        color: #637882;
      }
      img {
        margin: 10px auto;
        display: block;
      }
      span {
        display: flex;
        justify-content: center;
        color: #637882;
        font-size: 20px;
        font-weight: 300;
      }
    }
    .content-import-files {
      .ant-radio-group {
        display: flex;
        flex-direction: column;
      }
      .ant-radio-wrapper {
        margin-bottom: 10px;
      }
      .ant-radio:hover .ant-radio-inner {
        border-color: #3DD8DB;
      }
      .ant-radio .ant-radio-inner::after {
        content: "\f00c" !important;
        color: #ffffff !important;
        font-size: 9px !important;
        font-family: 'FontAwesome' !important;
        top: 2px;
        left: 2.5px;
        background-color: #3DD8DB;
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #637882;
        background-color: #3DD8DB;
      }
    }
    .content-tree-files-empty {
      .ant-tree {
        border: none !important;
      }
    }
    .content-tree-files {
      .tree-node-file-selected {
        background-color: #E5F7FF;
      }
      .file-import-content {
        display: flex;
        .icon-v10-extension-pdf {
           font-size: 20px;
        }
        img {
          width: 18px;
        }
        .title-file {
         color: #6e7882;
          font-weight: 300;
          padding-left: 5px;
        }
        .icon-file {
          font-size: 25px;
        }
      }
      .ant-tree {
        height: auto;
        max-height: 300px;
        overflow: auto;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      .ant-tree-checkbox-checked::after {
        border: 1px solid #637882;
      }
      .ant-tree li span.ant-tree-switcher {
        width: 0px;
        height: 0px;
      }
      .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
        border-color: #3DD8DB;
      }
      .ant-tree li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 8px;
      }
      .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: #3DD8DB;
        border-color: #637882;
      }
    }
    .footer-modal-buttons {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
      padding-left: 27px;
      padding-right: 27px;
      button:disabled {
        cursor: not-allowed;
        &:hover {
          background-color: #00D3D8;
          border-color: #00D3D8;
          color: #ffffff;
        }
      }
      .action-import-box {
        width: 40%;
        height: 30px;
        border: 1px solid #00D3D8;
        background-color: #00D3D8;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 4px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;

        &:hover {
          background-color: #FFFFFF;
          border-color: #00D3D8;
          color: #00D3D8;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      .action-cancel-box {
        width: 40%;
        height: 30px;
        border: 1px solid #00D3D8;
        background-color: #FFFFFF;
        color: #00D3D8;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 4px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;

        &:hover {
          background-color: #00D3D8;
          border-color: #00D3D8;
          color: #FFFFFF;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
    }
  }
}
.modal-attach-box {
  display: flex;
  justify-content: center;
  width: 500px;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  .ant-modal-content {
    width: 500px;
    height: 380px;
  }
  .modal-attach-box-content {
    .title-modal-attach {
      padding-top: 30px;
      padding-bottom: 30px;
      img {
        margin: 10px auto;
        display: block;
      }
      span {
        display: flex;
        justify-content: center;
        color: #637882;
        font-size: 20px;
        font-weight: 300;
      }
    }
    .content-attach-modal {
      padding-top: 20px;
      padding-bottom: 20px;
      justify-content: center;
      display: flex;
      .ant-select-dropdown {
        .icon-box {
          width: 20px;
          height: 20px;
        }
      }
      .select-box {
        color: #637882;
        .icon-box {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          margin-left: 5px;
        }
      }
      .ant-select-selection-selected-value {
        display: flex !important;
        font-size: 16px;
        font-weight: 300;
      }
      .ant-select {
        width: 90%;
      }
      .ant-select-selection--single {
        height: 45px;
        display: flex;
        align-items: center;
        background-color: #fafafa;
      }
    }
    .footer-modal-buttons {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
      padding-left: 27px;
      padding-right: 27px;
      .action-import-box-disabled {
        cursor: not-allowed !important;
        opacity: 0.6;

        &:hover {
          background-color: #00D3D8 !important;
          border-color: #00D3D8 !important;
          color: #FFFFFF !important;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      .action-import-box {
        width: 40%;
        height: 30px;
        border: 1px solid #00D3D8;
        background-color: #00D3D8;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 4px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;

        &:hover {
          background-color: #FFFFFF;
          border-color: #00D3D8;
          color: #00D3D8;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }

      .action-cancel-box {
        width: 40%;
        height: 30px;
        border: 1px solid #00D3D8;
        background-color: #FFFFFF;
        color: #00D3D8;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 4px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;

        &:hover {
          background-color: #00D3D8;
          border-color: #00D3D8;
          color: #FFFFFF;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
    }
  }
}

.ant-select-dropdown {
  .item-options-box {
    display: flex;
    justify-items: center;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #e8e8e8;
    .icon-box {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .select-box {
      display: flex;
    }
  }
}
.box-info-column {
  left: 736.993px;
  top: 154.993px !important;
  width: 147px !important;
  align-items: center;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: #fff;
      color: #3dd8db;
      font-weight: 500;
      border: 2px solid #3dd8db;
    }
    .ant-tooltip-arrow {
      width: 20px;
      height: 11px;
      bottom: -1px;
      &::before {
        transform: translateY(-4px) rotate(42deg);
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: 2px solid #3dd8db;
      }
    }
  }
}
.preview-file-data {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      border: 3px solid #3dd8db;
      background-color: #ffffff;
      img {
        width: 200px;
      }
    }
    .ant-tooltip-arrow {
      width: 20px;
      height: 11px;
      bottom: -1px;
      &::before {
        //transform: translateY(-4px) rotate(42deg);
        width: 15px;
        height: 15px;
        background-color: #ffffff;
        border: 3px solid #3dd8db;
      }
    }
  }
}
.notification-import-box {
  background-color: #ffffff !important;
  border: 2px solid #ffffff !important;
  color: #000000 !important;
  .ant-notification-close-icon {
    color: #000000 !important;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    color: #000000 !important;
  }
}
.delete-file-check-id-modal {
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .cancel-button {
    width: 170px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .confirm-button {
    width: 170px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button {
    font-family: Roboto;
    display: flex;
    padding: 2px 2px 2px 2px;
    height: 35px;
    justify-content: center;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button span {
    padding: 1px 2px;
    text-transform: capitalize;
    font-size: 18px;
  }
}

.dictionary-modal-redacted {
  width: 790px !important;
  height: 255px;
  box-shadow: 2px 14px 30px rgba(21,0,16,0.3);
  color: #382e34 !important;
  .content-header-modal {
    display: flex;
    justify-content: space-between;
    .icon-v21-close-black {
      font-size: 16px;
      color: #FFFFFF;
    }
  }
  .modal-add-to-dictionary {
    .content-add-to-dictionary {
    .option-redaction-dictionary {
      background-color: #FFFFFF;
      width: 100%;
      margin-bottom: 10px;
        .place-holder {
          color: #D4D7DA;
          font-weight: 400;
          //margin-left: 10px;
        }
      .select-value {
        color: #382e34;
        font-weight: 700;
        //margin-left: 10px;
      }
      .ant-select-selection-selected-value {
        margin-left: 10px;
        line-height: 40px;
      }
      .content-value {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .icon-v19-arrow-medium {
          font-size: 20px;
          color: #382e34;
        }
      }

      .ant-select-selection:disabled {
        background: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.6;
      }

      .ant-select-selection--multiple {
        min-height: 30px;
        padding-bottom: 0px;
        cursor: text;
        zoom: 0;
      }

      .ant-select-selection--single {
        position: relative;
        height: 40px;
        cursor: pointer;
      }
      .ant-select-selection {
        border: 1px solid #6e7882;
        border-radius: 0px;
      }
      .ant-select-selection:hover {
        border: 1px solid #EDA41E;
      }
      .ant-select-selection:active {
        border: 1px solid #EDA41E;
      }
      .ant-select-selection:focus {
        border: 1px solid #EDA41E;
      }
    }
      .input-term {
        border: 1px solid #6e7882;
        box-shadow: none;
        height: 40px;
        color: #382e34;
        font-weight: 400;
        border-radius: 0px;
        margin-left: 0px;
        &:hover {
          border: 1px solid #6e7882;
        }
        &:active {
          border: 1px solid #6e7882;
        }
        &:focus {
          border: 1px solid #6e7882;
        }
      }
  }
    .footer-buttons-dictionary {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
      .action-cancel-dictionary {
        width: 180px;
        height: 25px;
        border: 1px solid #6e7882;
        background-color: #FFFFFF;
        color: #6e7882;
        box-shadow: none;
        cursor: pointer;
        padding: 3px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        alignment: center;
        vertical-align: middle;
        line-height: 18.75px;

        &:hover {
          background-color: #FFFFFF;
          border-color: #FFC457;
          color: #FFC457;
        }

        &:focus {
          border: 1px solid #6e7882;
          background-color: #FFFFFF;
          color: #6e7882;
          -webkit-box-shadow: none;
          box-shadow:none;
        }

        &:active {
          border: 1px solid #EDA41E;
          background-color: #FFFFFF;
          color: #EDA41E;
        }
      }
      .action-create-dictionary {
        width: 180px;
        height: 25px;
        border: 1px solid #6e7882;
        background-color: #EDA41E;
        color: #FFFFFF;
        box-shadow: none;
        cursor: pointer;
        padding: 3px 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        alignment: center;
        vertical-align: middle;
        line-height: 18.75px;

        &:hover {
          background-color: #FFC457;
          border-color: #EDA41E;
          color: #FFFFFF;
        }

        &:focus {
          border: 1px solid #6e7882;
          background-color: #EDA41E;
          color: #FFFFFF;
          -webkit-box-shadow: none;
          box-shadow: none;
        }

        &:active {
          border: 1px solid #EDA41E;
          background-color: #EDA41E;
          color: #FFFFFF;
        }
      }
      .action-create-dictionary:disabled {
        cursor: default;
        background: #D4D7DA;
      }
    }
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-header {
    background: #66525f;
    height: 40px;
    padding: 10px;
    position: relative;
    border-radius: 0px;
    border-bottom: none;
    color: transparent;

    .ant-modal-title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 20px;
    }
  }
}

.dropdown-dict-options {
  border: 1px solid #EDA41E !important;
  .ant-select-dropdown-menu {
    padding: 0px 0px !important;
  }
  .ant-select-dropdown-menu-item {
    font-family: Roboto !important;
    font-weight: bold !important;
    font-size: 14px !important;
    letter-spacing: 0.2px;
    color: #382e34;
    vertical-align: middle;
  }
}
.more-info-link-notification {
  font-weight: 700;
  font-size: 14px;
  color: #00d4d8;
  cursor: pointer;
  text-decoration: underline;
  width: 65px;
  margin-left: 3px;
}