.highlight_query {
	background-color:yellow;
}

.highlight_training {
	background-color:#00FFFF;
	margin-right: 3px;
}

.highlight_annotate_doc_annotate_person {
	background-color:#556B2F;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_person_male {
	background-color:#808000;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_person_female {
	background-color:#6B8E23;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_location {
	background-color:#9ACD32;
}

.highlight_annotate_doc_annotate_location_region {
	background-color:#32CD32;
}

.highlight_annotate_doc_annotate_location_airport {
	background-color:#00FF00;
}

.highlight_annotate_doc_annotate_location_city {
	background-color:#7CFC00;
}

.highlight_annotate_doc_annotate_location_country {
	background-color:#7FFF00;
}

.highlight_annotate_doc_annotate_location_country_abbrev {
	background-color:#ADFF2F;
}

.highlight_annotate_doc_annotate_location_county {
	background-color:#00FF7F;
}

.highlight_annotate_doc_annotate_location_province {
	background-color:#00FA9A;
}

.highlight_annotate_doc_annotate_location_pre {
	background-color:#90EE90;
}

.highlight_annotate_doc_annotate_organization {
	background-color:#98FB98;
}

.highlight_annotate_doc_annotate_organization_company {
	background-color:#8FBC8F;
}

.highlight_annotate_doc_annotate_organization_department {
	background-color:#66CDAA;
}

.highlight_annotate_doc_annotate_organization_government {
	background-color:#3CB371;
}

.highlight_annotate_doc_annotate_organization_newspaper {
	background-color:#2E8B57;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_organization_university {
	background-color:#228B22;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_organization_team {
	background-color:#008000;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_money {
	background-color:#006400;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_percent {
	background-color:#D8BFD8;
}

.highlight_annotate_doc_annotate_date {
	background-color:#DDA0DD;
}

.highlight_annotate_doc_annotate_address {
	background-color:#EE82EE;
}

.highlight_annotate_doc_annotate_address_email {
	background-color:#DA70D6;
}

.highlight_annotate_doc_annotate_address_url {
	background-color:#FF00FF;
}

.highlight_annotate_doc_annotate_address_phone {
	background-color:#BA55D3;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_address_postcode {
	background-color:#9370DB;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_address_complete {
	background-color:#8A2BE2;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_address_ip {
	background-color:#9400D3;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_identifier {
	background-color:#9932CC;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_keyword {
	background-color:#8B008B;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_biochem_cellline {
	background-color:#B0E0E6;
}

.highlight_annotate_doc_annotate_biochem_celltype{
	background-color:#ADD8E6;
}

.highlight_annotate_doc_annotate_biochem_chemicalcompound {
	background-color:#87CEEB;
}

.highlight_annotate_doc_annotate_biochem_chemicalelement {
	background-color:#87CEFA;
}

.highlight_annotate_doc_annotate_biochem_chemicalion {
	background-color:#00BFFF;
}

.highlight_annotate_doc_annotate_biochem_dna {
	background-color:#1E90FF;
}

.highlight_annotate_doc_annotate_biochem_protein {
	background-color:#6495ED;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_biochem_gene {
	background-color:#4682B4;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_biochem_location {
	background-color:#4169E1;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_biochem_malignancy_type {
	background-color:#0000FF;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_biochem_state_altered {
	background-color:#0000CD;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_biochem_state_original {
	background-color:#00008B;
	color:#FFFFFF;
}

.highlight_annotate_doc_annotate_biochem_type {
	background-color:#000080;
	color:#FFFFFF;
}