.sa_page_wrapper {
  width: 100%;
  height: auto;
  background: url("../../../assets/background.png") no-repeat top right #efefef;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  .sa_page {
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 40px;
    .sa_box {
      background-color: #FFFFFF;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      position: relative;
      .closed-form-request {
        display: flex;
        width: 800px;
        height: 500px;
        font-family: Roboto;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        justify-content: center;
        align-items: center;
        .message-closed-sar {
          color: #000000;
          font-weight: bold;
          font-size: 30px;
        }
      }
      .sa_form {
        .sa_header {
          border-bottom: none;
          align-items: center;
          display: -webkit-flex;
          display: flex;
          height: 74px;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          padding: 20px;
          .sa_row {
            width: 100%;
            display: block;
            .sa-title{
              font-size: 24px;
              font-weight: 500;
              @media screen and (max-width: 576px) {
                text-align: center;
              }
            }
            .button-blue {
              width: auto;
              box-shadow: 0 2px 4px 0 #DCDCDC;
              cursor: pointer;
              font-family: 'Ubuntu', sans-serif;
              font-weight: 700;
              font-size: 14px;
              padding: 10px 30px;
              text-transform: uppercase;
            }
          }
        }
        .sa_content {
          padding-bottom: 2%;
          width: 100%;
          padding-left: 2%;
          padding-right: 2%;
          border-bottom: 1px solid #E6E6E6;
          border-bottom: none;
          margin-top: 2%;
          p {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
            font-family: 'Roboto';
            color: #737e89;
            line-height: 1.5;
          }
          .form-item {
            margin-top: 1%;
            label {
              font-family: roboto;
              font-size: 16px;
              font-weight: 400;
            }
            input {
              border-radius: 5px;
              border: 1px solid #CCCCCC;
              box-sizing: border-box;
              color: #555555;
              font-size: 18px;
              font-weight: 300;
              padding: 10px;
              width: 100%;
              &:focus {
                outline: 2px solid #3DD8DB;
              }
            }
          }
          .signature_wrapper {
            height: 16vh;
            padding: 1%;
            border: 1px solid #E6E6E6;
            background-color: #efeff0;
            width: 50%;
            .button-small-blue-empty {
              background-color: #FFFFFF;
              border: 1px solid #3DD8DB;
              color: #3DD8DB;
              border-radius: 20px;
              box-shadow: 0 2px 4px 0 #DCDCDC;
              width: auto;
              cursor: pointer;
              font-family: 'Ubuntu', sans-serif;
              font-weight: 700;
              font-size: 14px;
              padding: 4px 20px;
              text-transform: uppercase;
              &:hover {
                background-color: #3DD8DB;
                color: #FFFFFF;
              }
            }
            .button-small-red-empty {
              background-color: #FFFFFF;
              border: 1px solid #bd2130;
              color: #bd2130;
              border-radius: 20px;
              box-shadow: 0 2px 4px 0 #DCDCDC;
              width: auto;
              cursor: pointer;
              font-family: 'Ubuntu', sans-serif;
              font-weight: 700;
              font-size: 14px;
              padding: 4px 20px;
              text-transform: uppercase;
              margin-top: 5px;
              &:hover {
                background-color: #bd2130;
                color: #FFFFFF;
              }
            }
            .signature {
              border: 1px solid #E6E6E6;
              background-color: #ffffff;
              width: 100%;
              height: 80%;
            }
          }
          .text_area {
            width: 100%;
            height: 140px;
            border: 1px solid #CCCCCC;
            margin-bottom: 13px;
            &:focus {
              outline: 2px solid #3DD8DB;
            }
            @media screen and (min-width: 768px) {
              width: 410px;
            }
          }
          @media screen and (max-width: 1000px) {
            width: 100%;
          }
          .dn {
            display: none;
          }
          .error_container {
            .error_item{
              font-size: 12px;
              font-weight: bold;
              color: #bd2130;
            }
          }

          .custom-row {
            display: flex;
            align-items: center;
            position: relative;

            button {
              font-size: 18px;
              color: #36807d;
              margin-top: -5px;
              i {
                margin-right: 5px;
                font-size: 20px;
              }

              &:hover {
                color: #3dd8db;
              }
            }
            .error_item {
              position: absolute;
              left: 1%;
              bottom: 12%;
            }
          }
          .custom-row-group {
            width: 100%;
            margin-right: 0;
            label {
              font-weight: 500;
            }
            .edit-icon {
              position: absolute;
              top: 37px;
              right: 6px;
              cursor: pointer;
              color: #222B38;
              &:hover {
                color: #00D3D8;
              }
              &:focus {
                color: #00D3D8;
              }
            }
            &__input {
              background-color: #fafafa;
              border: none;
              border-radius: unset;
              border-bottom: 1px solid #222B38;
              &:focus {
                border: none;
                border-bottom: 1px solid #00D3D8;
                outline: none;
              }
            }
          }
        }
        .userSettings-footer {
          border-top: none;
        }
        .new-footer-settings {
          .discard-changes-button {
            width: auto;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 700;
            font-size: 14px;
            padding: 10px 30px;
            text-transform: uppercase;
            color: #00D4D8;
            border: 1px solid #00D4D8;
            &:hover {
              color: #ffffff;
              background-color: #00D4D8;
            }
          }
        }
        .button-blue {
          width: auto;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 14px;
          padding: 10px 30px;
          text-transform: uppercase;
          color: #00D4D8;
          border: 1px solid #00D4D8;
          &:hover {
            color: #ffffff;
            background-color: #00D4D8;
          }
        }
        .button-red {
          width: auto;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          font-size: 14px;
          padding: 10px 30px;
          text-transform: uppercase;
          color: #DD4475;
          border: 1px solid #DD4475;
          &:hover {
            color: #ffffff;
            background-color: #DD4475;
          }
        }
      }
    }
    @media screen and (min-width: 768px) {
      padding: 40px 40px 0;
    }
  }
}
.swal-alert-discard {
  .swal2-confirm {
    width: 100px !important;
  }
  .swal2-cancel {
    padding: 8px 23px !important;
    width: 100px !important;
  }
}