.sarDashboard-page {
  padding: 120px 170px 0;
  position: relative;
  background-color: #fafafa;
  .sar_statistic_card {
    .card {
      cursor: default;
      border: 1px solid #222B38;
      border-radius: 0;
      background-color: #fafafa;
      &__content {
        margin-top: 20px;
        &__icon {
          font-size: 50px;
          font-weight: 600;
          color: #222B38;
        }
        .value {
          text-align: right;
          &__details {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .sar_statistic_chart {
    margin-top: 20px;
    background-color: #fafafa;
    padding: 5px;
    border: 1px solid #222B38;
    position: relative;
    .title-statistic-chart {
      width: 353px;
      height: 21px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding-left: 10px;
    }
    .arrow {
      position: absolute;
      cursor: pointer;
      top: 40%;
      font-size: 20px;
    }
    .arrow-left {
      left: 10px;
    }
    .arrow-right {
      right: 10px;
    }
  }
  .content-button-subject {
    padding-top: 30px;
    .button-newBusiness {
      float: right;
      font-size: 20px;
      font-weight: 900;
      text-transform: capitalize;
      .anticon {
        margin-right: 8px;
      }
    }
  }
  .filter-sar-header {
  flex-direction: column-reverse;
  display: flex;
  width: 100%;
  padding-top: 30px;
  .content-filter {
    display: flex;
    width: 100%;
    .content-input-search {
      margin: 0;
      max-width: 380px;
      width: 300px;
      display: flex;
      flex: 1 1;
      display: flex;
      margin-right: 12px;
      .anticon {
        font-size: 20px;
        opacity: 0.4;
      }
        .ant-input-affix-wrapper .ant-input {
          height: 50px;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
          display: flex;
        }
    }

    .content-icon-filter {
      padding-right: 20px;
      display: flex;
      align-items: center;

      span {
        font-size: 28px;
      }
    }

    .filter-select-sar {
      display: flex;
      max-width: 380px;
      width: 300px;
      flex: 1 1;
      margin: 0px 0px 0px 19px;
      .ant-select-selection--single {
        height: 50px;
      }
      .ant-select-selection__placeholder {
        top: 80%;
        left: 10px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6e7882;
        opacity: 0.4;
      }
      .ant-select-selection-selected-value {
        padding-top: 9px;
        padding-left: 10px;
      }
    }
  }
}
  .sar-table-content {
    margin-bottom: 10px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      background-color: #fafafa;
      //box-shadow: 0px 5px 5px #ccc;
      //box-shadow: 0 0, 0 5px red initial, 0 0, 0 0;
      padding: 0px 0px;
      border-bottom: solid 1px;
      .per-page-wrapper {
        .ant-select-selection:hover {
          border-color: #00d4d8;
          border-right-width: 1px !important;
        }
      }
      .select-all-wrapper-sar {
        display: flex;
        align-items: center;
        .icon-v12-delete:before {
          //margin-bottom: 3px;
        }
        .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
          border-color: #00d4d8;
        }
        .ant-checkbox-wrapper {
          font-size: 14px;
          font-weight: 300;
          //padding-right: 10px;
          font-family: Roboto;
          width: 90px;
          color: #6e7782;
          border: 1px solid transparent;
          margin-right: 10px;
          padding-top: 2px;
          //margin-bottom: 3px;
          &:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
            //padding-top: 1px;
          }
          .ant-checkbox + span {
            padding-right: 0px;
            padding-left: 8px;
            padding-top: 3px;
          }
          .ant-checkbox-inner {
            margin-left: 5px;
          }
        }
        .select-all-sar {
          .ant-checkbox {
            font-family: Roboto;
            color: #6e7882;
          }
        }
        .icon-v12-delete-active {
          font-size: 20px;
          color: #00D3D8;
          cursor: pointer;
        }
        .icon-v12-delete {
          font-size: 20px;
          color: #6e7882;
          cursor: pointer;
        }
        .disabled-delete {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
      .pagination-wrapper {
        .custom-pagination-sb {
          .button-control-dashboard {
            .insert-page:hover {
              text-align: center;
              width: 50px;
              padding: 1px 5px !important;
              border-color: #00d4d8;
              //margin-right: 10px;
              font-weight: 300;
              background-color: #fafafa;
              border-radius: 3px;
              font-size: 14px;
            }
            .icon-v14-start-1, .icon-v14-end-1 {
              font-size: 20px;
              cursor: pointer;
              color: #6e7882;
              border: 1pt solid transparent;
              margin-left: 10px;
              margin-right: 5px;
            }
            .icon-v14-start-active, .icon-v14-end-active {
              font-size: 20px;
              color:#00d4d8;
              cursor: pointer;
              border: 1pt solid transparent;
              margin-left: 10px;
              margin-right: 5px;
            }
            .icon-v14-start-1 .disabled {
                color: #cccccc !important;
                cursor: not-allowed !important;
            }
            .icon-v21-back-1, .icon-v21-forth-1 {
              color: #382e34;
              font-size: 20px;
              border:none;
              cursor:pointer;
            }
            .icon-v21-back-active, .icon-v21-forth-active {
              font-size: 20px;
              cursor:pointer;
              border:1px transparent;
              color:#00d4d8;
              &:before {
                font-size: 20px;
                color:#00d4d8;
              }
             }
            .icon-v21-back-1 .disabled {
              color: #cccccc !important;
              cursor: not-allowed !important;
            }
            .icon-v21-fort-1 .disabled {
              color: #cccccc !important;
              cursor: not-allowed !important;
            }
          }
        }
      }
      .per-page-wrapper {
        .per-page {
          font-family: Roboto;
          font-weight: 300;
          font-size: 14px;
          color: #6E7882;
          .ant-select {
            margin-left: 10px;
            .ant-select-selection {
              .ant-select-arrow {
                margin-right: 0 !important;
              }
            }
          }
        }
      }
      .button-wrapper {
        position: relative;
        .button-newBusiness {
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          text-transform: none;
          color: #00D3D8;
          background-color: #ffffff;
          border: 1px solid #00D3D8;
          padding: 10px 50px;
          &:hover {
            color: #ffffff;
            background-color: #00D3D8;
          }
          &__icon {
            position: absolute;
            left: 17px;
            top: 25px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

#sarFilter__display {
  margin: 0 20px 0 0;
  max-width: 300px;
  width: 234px;
}

.ospDashboard__title {
  display: block;
  color: #737D87;
  font-weight: 300;
  margin: 30px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  &:last-of-type {
    margin: 10px 0 30px;
  }
  &::before {
    background-color: #C5C9CD;
    height: 1px;
    content: "";
    left: 0;
    position: absolute;
    top: 8px;
    width: calc(50% - 140px);
  }
  &::after {
    background-color: #C5C9CD;
    height: 1px;
    content: "";
    right: 0;
    position: absolute;
    top: 8px;
    width: calc(50% - 140px);
  }
}

.ospDashboard-boxWelcome {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
}

.ospDashboard-boxHeader {
  border-bottom: 1px solid #F2F2F2;
  padding: 20px;
}

.ospDashboard-boxTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.ospDashboard-boxText {
  @extend .ospDashboard-boxTitle;
  line-height: 1.5;
  padding: 20px;
}

.ospDashboard-boxLogo {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;
  height: 200px;
  padding: 130px 0 0 10px;
  position: relative;
  width: 120px;
  &::after {
    bottom: 60px;
    content: ™;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 300;
    left: 90px;
    position: absolute;
  }
}

.ospDashboard-boxInfo {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  flex: 1;
  height: 200px;
  position: relative;
}

.ospDashboard-boxInfoText {
  @extend .ospDashboard-boxTitle;
  line-height: 1.5;
  padding: 10px;
}

.ospDashboard__resources {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  padding: 20px 20px 0;
  position: relative;
}

.ospDashboard__resources--header {
  display: flex;
  flex-direction: column-reverse;
  a {
    color: #1DCBCF;
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.ospDashboard__resources--title {
  color: #000000;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 10px;
}

.ospDashboard-whitepaper {
  width: 100%;
  &:last-of-type {
    border-right: none;
  }
}

.ospDashboard-content {
  border-top: 1px solid #F2F2F2;
  display: flex;
  padding: 20px 0;
}

.ospDashboard-wrapper {
  display: flex;
}

.ospDashboard-iconPdf {
  height: 39px;
  width: 33px;
}

.ospDashboard-whitepaperInfo {
  flex: 1;
  padding: 0 10px 0 20px;
}

.ospDashboard-whitepaperTitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin-top: -7px;
}

.ospDashboard-whitepaperDescription {
  margin: 10px 0 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
}

.ospDashboard-whitepaperLink {
  align-self: center;
}

.ospDashboard-whitepaperLinkOnMobile {
  background: url(../../../assets/icon-arrow.png) no-repeat center center #FFFFFF;
  display: block;
  height: 19px;
  width: 13px;
}

.ospDashboard-whitepaperButton {
  display: none;
}

@media screen and (min-width: 768px) {
  .ospDashboard-page {
    padding: 40px 40px 0;
  }
  .ospDashboard__box--split {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ospDashboard-boxLogo {
    padding: 130px 0 0 20px;
    width: 268px;
  }
  .ospDashboard-boxInfo::before {
    content: "";
    border-style: solid;
    border-width: 0 0 202px 90px;
    border-color: transparent transparent #FFFFFF transparent;
    height: 0;
    left: -90px;
    position: absolute;
    top: -2px;
    width: 0;
  }
  .ospDashboard-boxInfoText {
    font-size: 15px;
    padding: 20px;
  }
  .ospDashboard__resources--header {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .ospDashboard__resources--title, .ospDashboard__resources--header a {
    margin-bottom: 0;
  }
  .ospDashboard-whitepaperLinkOnMobile {
    display: none;
  }
  .ospDashboard-whitepaperButton {
    display: block;
    button {
      width: 150px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ospDashboard-container {
    flex-direction: row;
  }
  .ospDashboard-whitepaper {
    border-right: 1px solid #F2F2F2;
    padding: 0 30px;
    width: 33.33%;
  }
  .ospDashboard-content {
    border-top: none;
    flex-direction: row-reverse;
  }
  .ospDashboard-wrapper {
    flex-direction: column;
  }
  .ospDashboard__resources {
    padding: 20px 0 0;
  }
  .ospDashboard__resources--header {
    border-bottom: 1px solid #EDEDED;
    padding: 0 20px 20px;
  }
  .ospDashboard__resources--body {
    display: flex;
    padding: 20px;
  }
  .ospDashboard-whitepaperInfo {
    padding: 0 20px 0 0;
  }
  .ospDashboard-whitepaperLink {
    align-self: flex-start;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .ospDashboard-boxResourcesTitle {
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .questionnaireFilter__select {
    margin: 0 20px 0 0;
    width: 100%;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50% #FFFFFF;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      font-size: 16px;
      height: 42px;
      padding: 10px 0 10px 8px;
      outline: none;
      width: 100%;
    }
  }
  .button-newBusiness {
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    border-radius: 30px;
    -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 15px 4px;
    text-transform: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
}

@media screen and (max-width: 768px) and (min-width: 600px) {
  .unlockedDashboard-infoContainer {
    width: 86%;
  }
}

.styleAccordion {
  .ant-table-thead {
    background-color: #fafafa;
  }
  .ant-table-tbody {
    background-color: #fafafa;
    .ant-table-row {
      cursor: pointer;
    }
  }
}