.piaSummary__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding-top: 40px;
}

.piaSummary__headerWrapper {
  height: 50px;
  border-bottom: 1px solid #f2f2f2;
}

.piaSummary__header {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 22px;
  }
}

.piaSummary__headerButtons {
  display: flex;
  margin-top: -10px;
}

.piaSummary__printButton button {
  background: url('../../assets/icon-print-black.svg') no-repeat 10px center;
  background-size: 25px;
  border-radius: 40px;
  height: 34px;
  padding-left: 43px;
  &:hover {
    background-color: #bec0c4 !important;
    color: #FFFFFF;
    background: url('../../assets/icon-print-white.svg') no-repeat 10px center;
    background-size: 25px;
    border-radius: 40px;
    height: 34px;
    padding-left: 43px;
  }
}

.piaSummary__downloadButton button {
  background: url('../../assets/icon-download-black.svg') no-repeat 10px center;
  background-size: 25px;
  border-radius: 40px;
  height: 34px;
  padding-left: 43px;
  margin-left: 10px;
  &:hover {
    background-color: #bec0c4 !important;
    color: #FFFFFF;
    background: url('../../assets/icon-download-white.svg') no-repeat 10px center;
    background-size: 25px;
    border-radius: 40px;
    height: 34px;
    padding-left: 43px;
    margin-left: 10px;
  }
}

.piaSummary__questions {
  margin-top: 25px;
  padding: 0 40px;
}

.piaSummary__question {
  font-weight: bold;
}

.piaSummary__answers {
  font-weight: bold;
  padding: 20px;
}

.piaSummary__answer {
  margin-bottom: 15px;
  font-weight: 300;
}

.piaSummary__answerSelected {
  font-weight: 500;
  padding-left: 25px;
  position: relative;
  margin-bottom: 15px;
  &::before {
    background-color: #000000;
    border-radius: 50%;
    color: #FFFFFF;
    content: '✓';
    font-size: 14px;
    height: 20px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 20px;
  }
}

.piaSummary__otherOptionText {
  font-weight: normal;
  margin-left: 25px;
  margin-top: 10px;
}

.piaScoreTotal {
  margin-left: 20px;
  margin-top: 7px;
  font-weight: 300;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.pia-title {
  display: flex;
}

.score-question {
  font-weight: 300;
  font-size: 12px;
  margin-left: 10px;
}
.button-view-questionnaire {
  border-top: 1px solid #f2f2f2;
  .button_big_blue {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.custom-spin-pia-summary {
  height: 40% !important;
  display: block;

  .ant-spin-dot {
    width: 2em;
    height: 2em;

    .ant-spin-dot-item {
      width: 18px;
      height: 18px;
      background: rgb(61, 216, 219);
    }
  }
  .ant-spin-text {
    top: 60% !important;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #737e89;
    line-height: 1.5;
    margin-left: 30px;
  }
}

@media print {
  .piaSummary__wrapper {
    padding: 10px 0;
    box-shadow: none;
    border: 1px solid #f2f2f2;
  }
  .piaSummary__headerWrapper {
    height: 30px;
  }
  .piaResult__summaryWrapper {
    padding: 0;
  }
  .piaSummary__headerButtons {
    display: none;
  }
}