.descriptionStyle {
  display: inline-block;
  margin-left: 0;
}

input {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  &.inputDisplayName, &.inputDisplayUnique {
    width: 100%;
  }
  &.inputDisplaydescription {
    width: 555px;
  }
}

button.buttonStyle {
  width: 100px;
}

.teamIconImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  float: left;
}

.styleUploadImage {
  display: inline-block;
}

.fileUpload {
  display: inline;
}

.fileContainer {
  display: contents;
  .buttonClassChange {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    background: #FFFFFF;
    color: #FFFFFF;
    height: 40px;
    width: 170px;
    font-family: sans-serif;
    font-weight: bold;
  }
  .chooseFileButton {
    background-color: #FFFFFF;
    border: 1px solid #3DD8DB;
    color: #3DD8DB;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700;
    &:hover {
      background: #3DD8DB;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
    }
  }
  p {
    font-size: 20px;
    margin: 8px 0 4px;
    font-family: sans-serif;
    font-weight: bold;
  }
}

.styleLabelImage {
  height: 40px;
  width: 310px;
}

.upload-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  font-family: auto;
}
.styleFont {
  font-family: roboto;
  font-size: 16px;
  font-weight: 400;
}
.newQuestionnaire__formGroup .styleTitleInput, .styleSpan1 {
  color: #111c26;
  @extend .styleFont
}

.styleSpan2 {
  color: #747c84;
  @extend .styleFont
}

#simulateSubmit {
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #3DD8DB;
  }
  &:focus {
    outline: none;
  }
}

.button_big_blue {
  outline: none;
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  background: #3DD8DB;
  color: #ffffff;
  height: 42px;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  text-transform: uppercase;
  &:hover {
    border: 1px solid #3DD8DB;
    background: #ffffff;
    color: #3DD8DB;
  }
  &:focus {
    outline: none;
  }
}

.button_position {
  margin-left: 0;
  margin-left: 290px;
}

@media screen and (max-width: 640px) {
  input.description1 {
    width: 261px;
  }
}

@media screen and (max-width: 400px) {
  input {
    &.description1, &.title1 {
      width: 100%;
    }
  }
}