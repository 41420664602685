.progress-mapping-files {
  .ant-card-head {
    height: 10px !important;
    margin-bottom: 0;
    padding: 0 10px;
    color: #382e34;
    font-weight: 600;
    font-size: 14px;
    border-bottom: none;
    border-radius: 0;
    zoom: normal;
    min-height: 10px !important;

    .ant-card-head-title {
      padding: 0px 0 !important;
    }
    .ant-card-extra {
      padding: 1px 0 !important;
      color: #382e34;
      font-weight: 600;
    }
  }
}
.progress-upload-files {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  z-index: 200;

  .ant-card-body {
    height: auto; /*23vh;*/
    overflow-y: auto;
    padding: 0px 10px 10px 10px !important;
  }

  .ant-card .ant-card-bordered {
    max-height: 500px;
    overflow-x: auto;
  }

  &__content {
    font-weight: 400 !important;
    margin-top:5px;

    .file-names {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      transition: all 0.3s ease;
      margin-bottom: -5px;

      &:hover {
        overflow: visible;
        white-space: normal;
        width: auto;
      }
      p {
        color: #382e34 !important;
      }
    }
    .ant-progress-small.ant-progress-line {
      font-size: 14px !important;
    }

    p {
      display: inline-block;
    }
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-1em - 10px);
    padding-right: calc(2em + -2px);
  }
  .ant-progress-status-success {
    .ant-progress-bg {
      background-color: #FFC457;
    }
    .ant-progress-text {
      color: #EDA41E !important;
      width: 12px;
    }
  }
  .ant-progress:not(.ant-progress-status-success) {
    .ant-progress-outer {
      margin-right: calc(-1em - 25px) !important;

      .ant-progress-inner {
        width:95%;
      }
    }
  }
  .ant-card-bordered {
    border: 2px solid #382e34;
    box-shadow: 5px 5px 10px -5px #15001030;

    .ant-card-head {
      height: 40px;
      margin-bottom: 0;
      padding: 0 10px;
      color: #382e34;
      font-weight: 600;
      font-size: 14px;
      border-bottom: none;
      border-radius: 0;
      zoom: normal;

      .ant-card-head-title {
        padding: 10px 0;
      }
      .ant-card-extra {
        padding: 10px 0;
        color: #382e34;
        font-weight: 600;
      }
    }
  }
}

#dropzone {
  width: 500px;
  height: 500px;
  background: #00babe;
}

.confirm-cancel-upload {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-btns {
          button span {
            padding: 0px;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 400;
          }
          .confirm-button {
            height: 30px;
            width: 200px;
            text-transform: capitalize;
          }
          .cancel-button {
            height: 30px;
            width: 200px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .ant-modal-body {
    padding: 30px !important;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    margin-top: 40px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 40px;
    color: #382e34;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
    margin-top: 1px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    margin-bottom: 0px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: 14px;
    font-weight: 700;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.2px;
    vertical-align: middle;
    color: #382e34;
    margin-top: 10px;
    height: 20px;
  }
 .ant-modal-confirm-btns button + button {
    margin-left: 0px !important;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .cancel-button {
    background-color: #FFFFFF;
    color: #EDA41E;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.5px;
    vertical-align: middle;
    margin-top: 0px;
    border: 1px solid #EDA41E;
    height: 25px;
    width: 130px;
    padding-top: 2px;
    &:hover {
      color: #c4812f;
      border: 1px solid #c4812f;
      background-color: #FFFFFF;
    }
    &:active {
      color: #FFC457;
      border: 1px solid #FFC457;
      background-color: #FFFFFF;
    }
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .confirm-button {
    background-color: #EDA41E;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.5px;
    vertical-align: middle;
    margin-top: 0px;
    border: 1px solid #6e7882;
    height: 25px;
    width: 130px;
    padding-top: 2px;
    &:hover {
      color: #FFFFFF;
      border: 1px solid #6e7882;
      background-color: #c4812f;
    }
    &:active {
      color: #FFFFFF;
      border: 1px solid #EDA41E;
      background-color: #FFC457;
    }
  }
}
.ant-progress-bg {
  background-color: #00d4d8 !important;
}
