.input_field {
    height: 34px;
    width: 258px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    color: #555;
}
.input_field:focus {
    outline: 2px solid #3DD8DB;
}
.input_field_email_address {
  width: 49%;
}
.select {
  margin-bottom: 20px;
  width: 100%;
}
.select select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  color: #000;
  font-size: 16px;
  height: 38px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
  font-family: 'Roboto';
  font-weight: 400;
}
.select select:focus {
  outline: 2px solid #3DD8DB;
}
.select_summary_frequency select {
    display: block;
    width: 23%;
}
h2.subtitle {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}
