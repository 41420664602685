@font-face {
  font-family: 'smartbox_icons_v1';
  src: url('./font_v10/smartbox_icons_v1.eot?63342094');
  src: url('./font_v10/smartbox_icons_v1.eot?63342094#iefix') format('embedded-opentype'),
       url('./font_v10/smartbox_icons_v1.woff2?63342094') format('woff2'),
       url('./font_v10/smartbox_icons_v1.woff?63342094') format('woff'),
       url('./font_v10/smartbox_icons_v1.ttf?63342094') format('truetype'),
       url('./font_v10/smartbox_icons_v1.svg?63342094#smartbox_icons_v1') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'smartbox_icons_v1';
    src: url('../font/smartbox_icons_v1.svg?63342094#smartbox_icons_v1') format('svg');
  }
}
*/
 
 [class^="icon-v10"]:before, [class*=" icon-v10"]:before {
  font-family: "smartbox_icons_v1";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-v10-smartbox-folder-finalised:before { content: '\e800'; } /* '' */
.icon-v10-analysed-b-:before { content: '\e801'; } /* '' */
.icon-v10-approved:before { content: '\e802'; } /* '' */
.icon-v10-checkid:before { content: '\e803'; } /* '' */
.icon-v10-dragpad:before { content: '\e804'; } /* '' */
.icon-v10-request-1:before { content: '\e805'; } /* '' */
.icon-v10-email:before { content: '\e806'; } /* '' */
.icon-v10-finished:before { content: '\e807'; } /* '' */
.icon-v10-redact:before { content: '\e808'; } /* '' */
.icon-v10-returne:before { content: '\e809'; } /* '' */
.icon-v10-search:before { content: '\e80a'; } /* '' */
.icon-v10-spell-checker:before { content: '\e80b'; } /* '' */
.icon-v10-image:before { content: '\e80c'; } /* '' */
.icon-v10-enter:before { content: '\e80d'; } /* '' */
.icon-v10-metadata:before { content: '\e80e'; } /* '' */
.icon-v10-annotate:before { content: '\e80f'; } /* '' */
.icon-v10-tick-button:before { content: '\e810'; } /* '' */
.icon-v10-tick-button-off:before { content: '\e811'; } /* '' */
.icon-v10-radio-button:before { content: '\e812'; } /* '' */
.icon-v10-radio-button-off:before { content: '\e813'; } /* '' */
.icon-v10-radio-button-tick:before { content: '\e814'; } /* '' */
.icon-v10-eye-open-b:before { content: '\e815'; } /* '' */
.icon-v10-smart-folder-open-2:before { content: '\e816'; } /* '' */
.icon-v10-smart-folder-parent-open2:before { content: '\e817'; } /* '' */
.icon-v10-smart-folder-medium-2:before { content: '\e818'; } /* '' */
.icon-v10-smart-folder-medium-parent-2:before { content: '\e819'; } /* '' */
.icon-v10-colourpalette-b:before { content: '\e81a'; } /* '' */
.icon-v10-grid-view:before { content: '\e81b'; } /* '' */
.icon-v10-list-view:before { content: '\e81c'; } /* '' */
.icon-v10-hamburger:before { content: '\e81d'; } /* '' */
.icon-v10-minus-b:before { content: '\e81e'; } /* '' */
.icon-v10-tool-annotation-b:before { content: '\e81f'; } /* '' */
.icon-v10-tool-annotation-neg:before { content: '\e820'; } /* '' */
.icon-v10-tool-delete-g:before { content: '\e821'; } /* '' */
.icon-v10-tool-delete-neg:before { content: '\e822'; } /* '' */
.icon-v10-tool-download-g:before { content: '\e823'; } /* '' */
.icon-v10-tool-download-neg:before { content: '\e824'; } /* '' */
.icon-v10-tool-eraser-g:before { content: '\e825'; } /* '' */
.icon-v10-tool-eraser-neg:before { content: '\e826'; } /* '' */
.icon-v10-tool-redaction-g:before { content: '\e827'; } /* '' */
.icon-v10-tool-redaction-neg:before { content: '\e828'; } /* '' */
.icon-v10-home-icon-neg2:before { content: '\e829'; } /* '' */
.icon-v10-tool-save-g:before { content: '\e82a'; } /* '' */
.icon-v10-tool-save-neg:before { content: '\e82b'; } /* '' */
.icon-v10-tool-search-g:before { content: '\e82c'; } /* '' */
.icon-v10-tool-search-neg:before { content: '\e82d'; } /* '' */
.icon-v10-tool-move-g-:before { content: '\e82e'; } /* '' */
.icon-v10-tool-textselection-g:before { content: '\e82f'; } /* '' */
.icon-v10-tool-textselection-neg:before { content: '\e830'; } /* '' */
.icon-v10-tool-undo-g:before { content: '\e831'; } /* '' */
.icon-v10-tool-undo-neg:before { content: '\e832'; } /* '' */
.icon-v10-update-b:before { content: '\e833'; } /* '' */
.icon-v10-user-b:before { content: '\e834'; } /* '' */
.icon-v10-users:before { content: '\e835'; } /* '' */
.icon-v10-user-circle-b:before { content: '\e836'; } /* '' */
.icon-v10-tool-redo-neg:before { content: '\e837'; } /* '' */
.icon-v10-delete-b:before { content: '\e838'; } /* '' */
.icon-v10-pages-b:before { content: '\e839'; } /* '' */
.icon-v10-tool-move-neg:before { content: '\e83a'; } /* '' */
.icon-v10-edit-b:before { content: '\e83b'; } /* '' */
.icon-v10-download-b:before { content: '\e83c'; } /* '' */
.icon-v10-monitor-b:before { content: '\e83d'; } /* '' */
.icon-v10-access-asset-b:before { content: '\e83e'; } /* '' */
.icon-v10-redaction-b:before { content: '\e83f'; } /* '' */
.icon-v10-find-asset-b:before { content: '\e840'; } /* '' */
.icon-v10-tool-redo-g:before { content: '\e841'; } /* '' */
.icon-v10-add-b:before { content: '\e842'; } /* '' */
.icon-v10-support-ticket-b:before { content: '\e843'; } /* '' */
.icon-v10-mail-b:before { content: '\e844'; } /* '' */
.icon-v10-ok-b:before { content: '\e845'; } /* '' */
.icon-v10-cloud-b:before { content: '\e846'; } /* '' */
.icon-v10-eye-closed-b:before { content: '\e847'; } /* '' */
.icon-v10-news-b:before { content: '\e848'; } /* '' */
.icon-v10-sar-icon:before { content: '\e849'; } /* '' */
.icon-v10-calendar-b:before { content: '\e84a'; } /* '' */
.icon-v10-status-ok:before { content: '\e84b'; } /* '' */
.icon-v10-search-b:before { content: '\e84c'; } /* '' */
.icon-v10-export-b:before { content: '\e84d'; } /* '' */
.icon-v10-assess-icon-neg:before { content: '\e84e'; } /* '' */
.icon-v10-annotation-b:before { content: '\e84f'; } /* '' */
.icon-v10-help-b:before { content: '\e850'; } /* '' */
.icon-v10-status-redaction:before { content: '\e851'; } /* '' */
.icon-v10-upload-b:before { content: '\e852'; } /* '' */
.icon-v10-status-analysed:before { content: '\e853'; } /* '' */
.icon-v10-extension-aep:before { content: '\e854'; } /* '' */
.icon-v10-extension-ai:before { content: '\e855'; } /* '' */
.icon-v10-extension-aif:before { content: '\e856'; } /* '' */
.icon-v10-extension-avi:before { content: '\e857'; } /* '' */
.icon-v10-extension-bmp:before { content: '\e858'; } /* '' */
.icon-v10-extension-cda:before { content: '\e859'; } /* '' */
.icon-v10-extension-css:before { content: '\e85a'; } /* '' */
.icon-v10-extension-csv:before { content: '\e85b'; } /* '' */
.icon-v10-extension-doc:before { content: '\e85c'; } /* '' */
.icon-v10-extension-docx:before { content: '\e85d'; } /* '' */
.icon-v10-extension-eps:before { content: '\e85e'; } /* '' */
.icon-v10-extension-fnt:before { content: '\e85f'; } /* '' */
.icon-v10-extension-fon:before { content: '\e860'; } /* '' */
.icon-v10-extension-gif:before { content: '\e861'; } /* '' */
.icon-v10-extension-htm:before { content: '\e862'; } /* '' */
.icon-v10-extension-html:before { content: '\e863'; } /* '' */
.icon-v10-extension-jfif:before { content: '\e864'; } /* '' */
.icon-v10-extension-jp2:before { content: '\e865'; } /* '' */
.icon-v10-extension-jpeg:before { content: '\e866'; } /* '' */
.icon-v10-extension-jpg:before { content: '\e867'; } /* '' */
.icon-v10-extension-mid:before { content: '\e868'; } /* '' */
.icon-v10-extension-midi:before { content: '\e869'; } /* '' */
.icon-v10-extension-mo4:before { content: '\e86a'; } /* '' */
.icon-v10-extension-key:before { content: '\e86b'; } /* '' */
.icon-v10-extension-mov:before { content: '\e86c'; } /* '' */
.icon-v10-extension-mp3:before { content: '\e86d'; } /* '' */
.icon-v10-extension-mpa:before { content: '\e86e'; } /* '' */
.icon-v10-extension-mpeg:before { content: '\e86f'; } /* '' */
.icon-v10-extension-mpg:before { content: '\e870'; } /* '' */
.icon-v10-extension-odp:before { content: '\e871'; } /* '' */
.icon-v10-extension-odt:before { content: '\e872'; } /* '' */
.icon-v10-extension-ods:before { content: '\e873'; } /* '' */
.icon-v10-extension-ogg:before { content: '\e874'; } /* '' */
.icon-v10-extension-otf:before { content: '\e875'; } /* '' */
.icon-v10-extension-pdf:before { content: '\e876'; } /* '' */
.icon-v10-extension-php:before { content: '\e877'; } /* '' */
.icon-v10-extension-png:before { content: '\e878'; } /* '' */
.icon-v10-extension-pps:before { content: '\e879'; } /* '' */
.icon-v10-extension-ppt:before { content: '\e87a'; } /* '' */
.icon-v10-extension-pptx:before { content: '\e87b'; } /* '' */
.icon-v10-extension-ps:before { content: '\e87c'; } /* '' */
.icon-v10-extension-psd:before { content: '\e87d'; } /* '' */
.icon-v10-extension-pst:before { content: '\e87e'; } /* '' */
.icon-v10-extension-rar:before { content: '\e87f'; } /* '' */
.icon-v10-extension-rtf:before { content: '\e880'; } /* '' */
.icon-v10-extension-svg:before { content: '\e881'; } /* '' */
.icon-v10-extension-tex:before { content: '\e882'; } /* '' */
.icon-v10-extension-tif:before { content: '\e883'; } /* '' */
.icon-v10-extension-tiff:before { content: '\e884'; } /* '' */
.icon-v10-extension-ttf:before { content: '\e885'; } /* '' */
.icon-v10-extension-txt:before { content: '\e886'; } /* '' */
.icon-v10-extension-wav:before { content: '\e887'; } /* '' */
.icon-v10-extension-wma:before { content: '\e888'; } /* '' */
.icon-v10-extension-wpl:before { content: '\e889'; } /* '' */
.icon-v10-extension-xhtml:before { content: '\e88a'; } /* '' */
.icon-v10-extension-xlr:before { content: '\e88b'; } /* '' */
.icon-v10-extension-xlsx:before { content: '\e88c'; } /* '' */
.icon-v10-extension-xls:before { content: '\e88d'; } /* '' */
.icon-v10-extension-xml:before { content: '\e88e'; } /* '' */
.icon-v10-extension-zip:before { content: '\e88f'; } /* '' */
.icon-v10-navigation_bell:before { content: '\e890'; } /* '' */
.icon-v10-navigation_help:before { content: '\e891'; } /* '' */
.icon-v10-navigation_setting:before { content: '\e892'; } /* '' */
.icon-v10-acknowledged:before { content: '\e893'; } /* '' */
.icon-v10-approved-1:before { content: '\e894'; } /* '' */
.icon-v10-arrow-dark-blue:before { content: '\e895'; } /* '' */
.icon-v10-arrow:before { content: '\e896'; } /* '' */
.icon-v10-attachment:before { content: '\e897'; } /* '' */
.icon-v10-back:before { content: '\e898'; } /* '' */
.icon-v10-completed:before { content: '\e899'; } /* '' */
.icon-v10-documents:before { content: '\e89b'; } /* '' */
.icon-v10-end:before { content: '\e89c'; } /* '' */
.icon-v10-ended:before { content: '\e89d'; } /* '' */
.icon-v10-filter:before { content: '\e89e'; } /* '' */
.icon-v10-forth:before { content: '\e89f'; } /* '' */
.icon-v10-process:before { content: '\e8a0'; } /* '' */
.icon-v10-refresh:before { content: '\e8a1'; } /* '' */
.icon-v10-request-form:before { content: '\e8a2'; } /* '' */
.icon-v10-save:before { content: '\e8a4'; } /* '' */
.icon-v10-share:before { content: '\e8a5'; } /* '' */
.icon-v10-start:before { content: '\e8a6'; } /* '' */
.icon-v10-time:before { content: '\e8a7'; } /* '' */
.icon-v10-paper:before { content: '\e8a8'; } /* '' */
.icon-v10-data:before { content: '\e8ab'; } /* '' */
.icon-v10-rights-1:before { content: '\e8b6'; } /* '' */
.icon-v10-speed:before { content: '\e8bb'; } /* '' */