.newQuestionnaire__loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.newQuestionnaire__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.newQuestionnaire__section {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding-bottom: 5px;
  margin-bottom: 20px;
  position: relative;
}

.newQuestionnaire__title {
  font-size: 24px;
  font-weight: 500;
}

.newQuestionnaire__header {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  height: 110px;
  justify-content: space-between;
  padding: 20px;
}

.newQuestionnaire__submitButtons {
  display: flex;
  flex-direction: column;
}

.newQuestionnaire__disabledButton button {
  cursor: not-allowed;
  background-color: #bec0c4;
  color: #FFFFFF;
}

.newQuestionnaire__formGroup--sideBySide {
  padding-bottom: 18px;
&:last-of-type {
   border-top: 1px solid #E6E6E6;
   padding-top: 30px;
   padding-bottom: 0;
 }
}

.newQuestionnaire__submitButtons button {
  height: 40px;
  width: 170px;
&:last-of-type {
   margin-top: 10px;
 }
}

.newQuestionnaire__form {
  padding: 20px 20px 0;
  position: relative;
}

.newQuestionnaire__formGroup {
  margin-bottom: 20px;
  width: 100%;
label {
  color: #555;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}
}

.newQuestionnaire__requiredField::after {
  content: "*";
  color: #3DD8DB;
  margin-left: 5px;
}
.fileContainer input {
  display: none;
}

.newQuestionnaire__formGroup {
input {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
&:disabled {
   color: #AAAAAA;
   border: 1px solid #E6E6E6;
   cursor: not-allowed;
 }
}
textarea:disabled {
  color: #AAAAAA;
  border: 1px solid #E6E6E6;
  cursor: not-allowed;
}
select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  color: #555555;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
&:disabled {
   color: #AAAAAA;
   border: 1px solid #E6E6E6;
   cursor: not-allowed;
 }
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
textarea {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 100px;
  padding: 10px;
  width: 100%;
&:focus {
   outline: 2px solid #3DD8DB;
 }
}
}

.newQuestionnaire__errorsList {
  margin-bottom: 22px;
}
.pia-questionnaire-link {
  color: #3DD8DB;
  display: block;
  font-size: 14px;
  margin-bottom: 40px;
  text-decoration: underline;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    font-style: italic;
  }
}

.link-back-questionnaires {
  display: flex;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .newQuestionnaire__formGroup select {
    width: 260px;
  }
  .newQuestionnaire__formGroup--sideBySide {
    display: flex;
  .newQuestionnaire__formGroup {
    width: 300px;
  }
}
.newQuestionnaire__submitButtons {
  flex-direction: row;
button {
  height: 40px;
&:last-of-type {
   margin-top: 0;
   margin-left: 20px;
 }
}
}
.newQuestionnaire__header {
  height: 74px;
}
}




