.api-connector-page {
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  background: rgb(255,255,255);
  margin: 100px 82px;
  padding: 30px 0px 0;

  &__content {
    .api-connector-header {
      display: flex;
      flex-direction: row;
      color: #382e34;
      align-items: center;

      .title-header {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #382E34;
      }
    }

    .content-api-connector-page {
     .content-api-connector-table {
       .btn-container{
         display: flex;
         flex-direction: row-reverse;
         margin-top: 40px;
         margin-bottom: 30px;
         button {
           cursor: pointer;
           width: 20px;
           height: 20px;
         }
         .icon-v17-delete, .icon-v17-add {
           &[disabled]{
             color:#D4D7DA;
           }
         }
         .icon-v17-add {
           color: var(--generalButton);
           margin-left: 20px;
         }
         .icon-v17-add-active {
           color: var(--generalIconFontHover);
           margin-left: 20px;
         }
       }

       .table-api-connector {
         .icon-v17-delete {
           color:#382E34;
         }
         .icon-v211-copy {
           color:#382E34;
           font-size: 16px;
         }
         .icon-v17-delete-active{
           color: var(--generalIconFontHover);
         }
         .icon-v17-edit-active {
           color: var(--generalIconFontHover);
         }
         .icon-v17-edit {
           color:#382E34;
         }
         .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row td {
           font-family: 'Roboto';
           font-style: normal;
           font-weight: 700;
           font-size: 14px;
           line-height: 20px;
         }
         .ant-table-wrapper .ant-table-content .ant-table-body .ant-table-header-column .ant-table-column-title {
           font-family: 'Roboto';
           font-style: normal;
           font-weight: 700;
           font-size: 14px;
           line-height: 20px;
         }
         .row-class-expired {
           .column-api  {
             color: #D4D7DA;
             .icon-v211-copy {
               color:#D4D7DA;
               font-size: 16px;
             }
           }
         }
       }
     }
    }
  }
}

.api-connector-modal {
  width: 790px !important;
  height: 720px !important;

  .header-connector-modal {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--subNavigationBackground);
  }
  .ant-modal-content {
    .ant-modal-header {
      height: 40px;
      padding: 0px;
      background-color: #665360;
      border-radius: 0;
      border: none;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 30px;
        width: 100%;
      }
    }
    .ant-modal-body {
      padding: 30px;
      .content-api-connector-modal {
        .api-connector-modal-text-field {
          .title-text-field {
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
          }
          .input-token-name {
            margin:10px 0 20px 0;
            &:hover {
              border-color:#eda41e;
            }
            &:focus {
              border-color:#eda41e;
              box-shadow: none;
            }
          }
          .select-expires {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
            .ant-select-selection:hover {
              border-color: #eda41e;
            }
            .ant-select-selection:active {
              border-color: #eda41e;
            }
            .ant-select-selection:focus {
              border-color: #eda41e;
            }
          }
          .selected-children-list {
            .ant-checkbox-checked .ant-checkbox-inner:after {
              border-color: #FFFFFF;
              position: absolute !important;
              display: table;
              color: #FFFFFF !important;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
            .ant-checkbox-inner::before {
              border-color: #FFFFFF;
              position: absolute !important;
              display: table;
              color: #6e7882 !important;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner::before {
                color: transparent !important;
              }
            }
          }
          .selected-all-connector {
            margin-top: 10px;
            .ant-checkbox-wrapper + .ant-checkbox-wrapper {
              margin-left: 0px;
            }
            .ant-checkbox-checked:hover .ant-checkbox-inner {
              background-color: var( --generalIconFontActive) !important;
              border: 1px solid var(--generalIconFontHover);
            }
            .ant-checkbox-wrapper {
              line-height: 2;
              span:not(:first-child) {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
              }
              .ant-checkbox-inner {
                border-radius: 0!important;
                border:1px solid #6e7882;
                &:after {
                  border-color:#eda41e;
                  background-color: transparent;
                }
                &:hover {
                  border-color: var(--generalIconFontHover);
                }
              }
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: var( --generalIconFontActive) !important;
                &:after {
                  position: absolute !important;
                  display: table;
                  color:#FFFFFF;
                  border:none !important;
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: "\e8ce" !important;
                  top: 8px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                  font-family: "smartbox_icons_v19" !important;
                }
              }
            }
          }
          .content-allow-token {
            display: grid;
            margin-top: 10px;
            .ant-checkbox-wrapper + .ant-checkbox-wrapper {
              margin-left: 0px;
            }
            .ant-checkbox-checked:hover .ant-checkbox-inner {
              background-color: var( --generalIconFontActive) !important;
              border: 1px solid var(--generalIconFontHover);
            }
            .ant-checkbox-wrapper {
              line-height: 2;
              span:not(:first-child) {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
              }
              .ant-checkbox-inner {
                border-radius: 0!important;
                border:1px solid #6e7882;
                &:after {
                  border-color:#eda41e;
                  background-color: transparent;
                }
                &:hover {
                  border-color: var(--generalIconFontHover);
                }
              }
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: var( --generalIconFontActive) !important;
                &:after {
                  position: absolute !important;
                  display: table;
                  color:#FFFFFF;
                  border:none !important;
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: "\e8ce" !important;
                  top: 8px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                  font-family: "smartbox_icons_v19" !important;
                }
              }
            }
          }
        }
        .api-connector-modal-buttons {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          .ant-btn {
            width: 80px;
          }
        }
      }
    }
  }
}
.select-expiration-period {
  border: 1px solid #eda41e;
}