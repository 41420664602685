.questionTypeYN-group {
  display: flex;
  padding-left: 20px
}

.questionTypeYN-yes {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 45px;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 100px;
}

.questionTypeYN-yes:hover {
  box-shadow: 0 0 5px 0 #3DD8DB;
  border: 1px solid #3DD8DB;
}

.questionTypeYN-no {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 45px;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 100px;
}

.questionTypeYN-no:hover {
  box-shadow: 0 0 5px 0 #3DD8DB;
  border: 1px solid #3DD8DB;
}

.questionTypeYN-idontknow {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 45px;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 170px;
}

.questionTypeYN-labelYes {
  border-radius: 45px;
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelYes::before {
  background-color: #3DD8DB;
  border-radius: 50%;
  color: #FFFFFF;
  content: '✓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelYesChecked {
  background-color: #3DD8DB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelYesChecked::before {
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #3DD8DB;
  content: '✓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelYesCheckedDisabled {
  background-color: #BBBBBB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelYesCheckedDisabled::before {
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #BBBBBB;
  content: '✓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelYesDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #000000;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelYesDisabled::before {
  background-color: #EEEEEE;
  border-radius: 50%;
  color: #000000;
  content: '✓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelNo {
  border-radius: 45px;
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelNo::before {
  background-color: #3DD8DB;
  border-radius: 50%;
  color: #FFFFFF;
  content: '☓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelNoChecked {
  background-color: #3DD8DB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelNoChecked::before {
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #3DD8DB;
  content: '☓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelNoCheckedDisabled {
  background-color: #BBBBBB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelNoCheckedDisabled::before {
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #BBBBBB;
  content: '☓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelNoDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #000000;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 100px;
  z-index: 1;
}

.questionTypeYN-labelNoDisabled::before {
  background-color: #EEEEEE;
  border-radius: 50%;
  color: #000000;
  content: '☓';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelIdontknow {
  border-radius: 45px;
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 170px;
  z-index: 1;
}

.questionTypeYN-labelIdontknow::before {
  background-color: #3DD8DB;
  border-radius: 50%;
  color: #FFFFFF;
  content: '?';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelIdontknowChecked {
  background-color: #3DD8DB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 170px;
  z-index: 1;
}

.questionTypeYN-labelIdontknowChecked::before {
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #3DD8DB;
  content: '?';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelIdontknowCheckedDisabled {
  background-color: #BBBBBB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 170px;
  z-index: 1;
}

.questionTypeYN-labelIdontknowCheckedDisabled::before {
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #BBBBBB;
  content: '?';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-labelIdontknowDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #000000;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 45px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 170px;
  z-index: 1;
}

.questionTypeYN-labelIdontknowDisabled::before {
  background-color: #EEEEEE;
  border-radius: 50%;
  color: #000000;
  content: '?';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.questionTypeYN-messageTitle {
  display: flex;
  color: #737D87;
  font-weight: 300;
  padding: 0 20px;
  margin: 30px 0 10px;
}

.questionTypeYN-messageTitle::before {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-right: 10px;
  transform: translateY(-50%);
  width: 40px;
}

.questionTypeYN-messageTitle::after {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  flex: 1 0 auto;
  margin-left: 10px;
  transform: translateY(-50%);
}

.questionTypeYN-messageText {
  color: #737D87;
  line-height: 1.5;
  padding: 0 20px
}