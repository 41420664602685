.tracker-page {
  .tracker-box {
    .tracker-form {
      .tracker-content :nth-child(9), .tracker-content :nth-child(10){
        font-weight: 900!important;
        color:black
      }
      .tracker-content {
        .tracker_formGroup-company {

        }
        .tracker_formGroup-reset {
          display: flex;
          align-items: center;
          .form-group-reset-mfa {
            width: 30%;
          }
          .button-blue-reset-new {
            width: 150px;
            height: 25px;
            padding: 5px 30px;
          }
          .button-blue-change-company {
            width: 200px;
            height: 25px;
            padding: 5px 30px;
          }
        }
        .error_container {
          .error_item {
            font-size: 12px;
            font-weight: bold;
            color: #F00;
          }
        }
      }
      .tracker-footer {
        padding-right: 2%;
        .btn-blue-light {
          border: 1px solid #3DD8DB;
          color: #3DD8DB;
          background-color: #ffffff;
          width: auto;
          font-family: Roboto;
          font-size: 20px;
          font-weight: 900;
          padding: 10px 30px;
          border-radius: 30px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          &:hover {
            border: 1px solid #ffffff;
            color: #ffffff;
            background-color: #3DD8DB;
          }
          &:disabled {
            color: #dddddd;
            border: 1px solid #dddddd !important;
            background-color: #ffffff;
          }
        }

        .btnAndStatus {
          width: auto;
          display: flex;
          flex-direction: column;
          &__status {
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            color: #484848;
            text-transform: uppercase;
            padding-top: 15px;
          }
        }
      }
      .step-complete {
        .badge-wrapper {
          text-align: center;
          margin-top: 50px;
          .icon-v10-ended {
            font-size: 59px;
            color: #00D4D8;
            margin-bottom: 5px;
          }
          .badge-text {
            font-size: 16px;
            font-weight: 300;
            font-family: Roboto;
            color: #8b949c;
            line-height: 28px;
            &__special {
              text-transform: uppercase;
              font-weight: 500;
              margin: 0 5px;
            }
          }
        }
        .cards {
          margin: 60px 0;
          display: flex;
          justify-content: space-between;
          .card {
            padding: 10px;
            width: 15%;
            box-shadow: none;
            border: 1px solid #8d9399;
            border-radius: unset;
            .card-icon {
              font-size: 43px;
              margin: 10px 0;
            }
            .icon-v10-speed {
              width: 90%;
            }
            .badge-text {
              margin-bottom: 10px;
              &--bold {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}