.boxComplete-wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  padding: 40px 20px;
  text-align: center;
}

.boxComplete-content {
  margin: 0 auto;
  max-width: 520px;
  width: 100%;
}

.boxComplete-title {
  color: #212A34;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
}

.boxComplete-text {
  color: #737D87;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 40px;
}

.boxComplete-message {
  margin-top: 30px;
}

.boxComplete-loading {
  display: flex;
  justify-content: center;
}