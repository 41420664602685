.new-page-redact {
  background: #FFFFFF;
  font-family: Roboto, serif;
  .header-new-page-redact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 40px 4px 5px;
    border-bottom: 1px solid #a6acb2;
    height: 34px !important;
    z-index: 2;
    position: fixed;
    //margin-left: 50px;
    width: calc(100% - 0px);
    background-color: #FAFAFA;
    .header-title-bulk {
      width: 50%;
      .title-redact {
        font-size: 14px;
        color: #EDA41E;
        padding: 0px 40px 0 35px;
      }
      .space-redact {

      }
    }
    .button-bulk-go {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .content-colour-profiles {
        width: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .title-colour-profiles {
          color: #6e7882;
          font-weight: 400;
          font-size: 14px;
          margin-right: 20px;
        }
        .select-profiles-colours {
          width: auto;
          min-width: 100px;
          color: #6e7882;
          font-weight: 400;
          font-size: 14px;
          margin-right: 20px;
          .ant-select-selection {
            border-radius: 0px;
            font-size: 14px;
            height: 30px;
            border: 1px solid #382e34;
            font-weight: 400;
            box-shadow: none;
          }
          .ant-select-selection:hover {
            border-color: #EDA41E;
            border-right-width: 1px !important;
            .ant-select-arrow {
              color: #eda41e;
            }
          }
          .ant-select-arrow:hover {
            color: #eda41e;
          }
        }
      }
      .button-blue-bulk-stop {
        margin-right: 20px;
      }
      .button-blue-bulk-resume {
        margin-right: 20px;
      }
      .button-dropdown-redact {
        border-radius: 50px;
        //position: fixed;
        width: 200px;
        .ant-dropdown-menu {
          padding: 0px 0;
          border-radius: 1px;
        }
        .ant-btn:disabled {
          color: #FFFFFF;
          background-color: #D4D7DA;
          //border: 1px solid #D4D7DA !important;
          cursor: default;
          padding: 0 10px;
          border: 1px solid #d4d7da00 !important;
          &:hover {
            background-color: #D4D7DA;
            color: #FFFFFF;
          }
        }
        .ant-btn:first-child {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
        .ant-btn:last-child {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
        .ant-btn {
          //border-radius: 50px;
          background-color: var(--generalButton);
          color: var(--generalButtonFont);
          box-shadow: none;
          letter-spacing: 0.5px;
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          font-family: Roboto;
          //line-height: 25px; TASK 5 GRAV-2583
          line-height: 20px;
          border: 1px solid #6e7882;
          margin-top: 0px;
          padding: 0px 10px;
          height: 25px;
          //position: fixed;
          &:hover {
            background-color: var(--generalButtonHover);
            //color: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
          }
          &:active {
            background-color: var(--generalButtonActive);
            //color: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
          }
        }
      }
      .button-blue-bulk {
        height: 25px;
        border: 1px solid #382e34;
        background-color: #382e34;
        color: #ffffff;
        box-shadow: none;
        vertical-align: middle;
        horiz-align: center;
        letter-spacing: 0.5px;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        padding: 0px 18px;
        text-transform: capitalize;
        border-radius: 40px;
        font-family: Roboto;
        line-height: 18.75px;
        width: 180px;
        &:hover {
          background-color: #150010;
          color: var(--generalButton);
          border: 1px solid #150010;
        }
        &:active {
          background-color: #665360;
          color: #FFFFFF;
          border: 1px solid #382e34;
        }
      }
      .button-blue-bulk:disabled {
        opacity: 0.3;
        cursor: not-allowed;
        border: 1px solid #6e7882;
        background-color: #6e7882;
        &:hover {
          background-color: #6e7882;
          color: #FFFFFF;
          border: 1px solid #6e7882;
        }
        &:active {
          background-color: #6e7882;
          color: #FFFFFF;
          border: 1px solid #6e7882;
        }
      }
    }
  }
  .page-bulk-redaction {
    padding: 34px 40px 0px;
     .title-bulk-redaction {
       margin-top: 30px;
       .title-bulk {
         font-size: 20px;
         font-weight: bold;
         letter-spacing: 0.2px;
         vertical-align: middle;
         horiz-align: left;
         color: #382e34;
         margin-top: 20px;
         margin-left: 0px;
       }
       .icon-v14-question {
        font-size: 20px;
        color: #382e34;
        margin-left: 10px;
      }
       .help-message-locations {
        .content-help-message-locations {
          .text-information {
            font-size: 14px;
            font-weight: 400;
            margin-right: 3px;
          }
          .more-info-link {
            font-weight: 700;
            font-size: 14px;
            color: #00d4d8;
            cursor: pointer;
            text-decoration: underline;
            width: 65px;
            margin-left: 3px;
          }
        }
      }
     }
    .content-bulk-redaction {
      margin-top: 40px;
      width: calc(100% - 0px);
      margin-left: 0px;
      .pagination-bulk-redaction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        margin-bottom: 10px;
        background-color: #EDA41E;
        padding: 8px 10px 7px 10px;
        height: 46px;
        border-bottom: 1px solid #382e34;
        .per-page-redaction-content {
          display: flex;
          align-items: center;
        }
        .icon-v24-download-1 {
          font-size: 30px;
          color: #382e34;
          //margin-left: 10px;
        }
        .icon-v24-download-active-1 {
          font-size: 30px;
          color: #382e34;
        }
        .per-page-redaction:hover {
          border: 1px solid #382e34 !important;
          background-color: #FFC457;
          border-radius: 3px;
          color: #382e34 !important;
        }
        .per-page-redaction {
          //border: 1px solid transparent;
          height: 20px;
          font-family: Roboto;
          font-size: 14px;
          color: #382e34;
          font-weight: 400;
          display: flex;
          align-items: center;
          letter-spacing: -0.2px;
          width: 88px;
          padding: 5px;
          border: 1px solid transparent;
          cursor: pointer;
          align-content: center;
          .name-show {
            margin-right: 5px;
            padding-top: 2px;
          }
          .ant-select-arrow:after {
            //background-color: #b3ff00;
            border-radius: 50%;
            color: #382e34;
            content: '\e896';
            font-family: 'smartbox_icons_v19' !important;
            display: inline-block;
            width: 17px;
            height: 17px;
            padding-top: 0px;
            padding-left: 6px;
            padding-bottom: 9px;
            font-style: normal;
            font-size: 20px;
          }
          .icon-v19-arrow-medium {
            font-size: 20px;
            color: #382e34;
            position: absolute;
            left: 59px;
          }
          .icon-v19-arrow-medium:before {
            margin-right: 0px;
            margin-left: 0px;
          }
          .ant-select-arrow .ant-select-arrow-icon {
            display: none !important;
          }
          .ant-select-selection {
            border: none;
            background-color: transparent;
          }
          .ant-select-selection:hover {
            border: none;
            //background-color: #FFC457;
          }
          .ant-select-selection__rendered {
            line-height: 20px;
            margin-left: 0px;
            margin-right: 20px;
          }
          .ant-select-selection--single {
            border: none;
            height: 20px;
          }
          .ant-select .ant-select-selection .ant-select-arrow {
            margin-right: 0;
            top: 30%;
            right: 5px;
          }
          .ant-select-arrow {
            color: #382e34 !important;
          }
          &:hover {
            color: #382e34;
            .ant-select-selection {
              color: #382e34 !important;
              height: 20px;
            }
            .ant-select-arrow {
              color: #382e34 !important;
            }
          }
        }
        .buttons-control-pagination {
          float: left;
          display: flex;
          align-items: center;
          font-family: Roboto;
          font-size: 14px;
          color: #382e34;
          font-weight: 400;
          &__text {
            margin-left: 10px;
            margin-right: 10px;
          }
          .insert-page {
            text-align: center;
            width: 50px;
            padding: 1px 5px !important;
            margin-right: 10px;
            font-weight: 400;
            height: 20px;
            border: 1px solid #382e34;
            font-size: 14px;
            border-radius: 3px;
            box-sizing: border-box;
            color: #382e34;
            background-color: #eda41e;
            &:focus {
              background-color: #FFC457;
              outline: none;
            }
            &:hover {
              background-color: #FFC457;
              outline: none;
            }
          }
          .icon-v19-start-1 {
            font-size: 20px;
          }
          .disabled-prev {
            cursor: default;
            opacity: 0.3;
          }
          .icon-v19-forth-1:before {
            //background-color: #FFC457;
            //border-radius: 50%;
          }
          .icon-v19-start-active {
            font-size: 20px;
          }
          .next-active:before {
            background-color: #FFC457;
            border-radius: 50%;
          }
          .icon-v19-end-active {
            font-size: 20px;
          }
          .icon-v19-back-1 {
            font-size: 20px;
          }
          .active-back:before {
            background-color: #FFC457;
            border-radius: 50%;
          }
          .icon-v19-forth-1 {
            font-size: 20px;
          }
          .disabled-next {
            cursor: not-allowed;
            opacity: 0.6;
          }
          .icon-v19-back-1:before {
            //background-color: #FFC457;
            //border-radius: 50%;
          }
          .icon-v19-end-1 {
            font-size: 20px;
          }
          .insert-page:focus-visible {
            background-color: #FFC457;
          }
          .insert-page:focus {
            //border: 1px solid #00d4d8;
            background-color: #FFC457;
          }
          .insert-page:hover {
            //border: 1px solid #00d4d8;
            background-color: #FFC457;
          }
          .icon-v14-start-1 {
            font-size: 20px;
          }
          .icon-v14-start-active {
            font-size: 20px;
            color: #00d4d8;
          }
          .icon-v14-star {
            font-size: 20px;
            color: #00d4d8;
          }
          .back-active {
            font-size: 20px;
            color: #00d4d8;
          }
          .icon-v14-end-active {
            font-size: 20px;
            color: #00d4d8;
          }
          .forth-1-active {
            font-size: 20px;
            color: #00d4d8;
          }
          .icon-v14-back-1 {
            font-size: 20px;
          }
          .icon-v14-forth-1 {
            font-size: 20px;
          }
          .icon-v14-end-1 {
            font-size: 20px;
          }
        }

        .input-search-bulk-redaction {
          .ant-input {
            border: 1px solid #382e34;
            font-size: 14px;
            letter-spacing: 0.2px;
            width: 200px;
            height: 30px;
            padding: 10px 7px 10px 7px;
            border-radius: 0;
          }
          .ant-input-affix-wrapper .ant-input-suffix {
            right: 5px;
            z-index: 1;
            color: #382e34;
          }
          .search-redaction {
            display: flex;
            justify-content: flex-end;
            color: #382e34;
            .ant-input-clear-icon {
              color: #382e34;
              font-size: 16px;
              padding-right: 7px;
            }
            .ant-input::placeholder {
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.2px;
              //background-color: #EDA41E;
              color: #382e34;
              opacity: 0.6;
            }
            .ant-input::placeholder:hover {
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.2px;
              background-color: #FFC457;
              color: #382e34;
            }
            .ant-input {
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.2px;
              background-color: #EDA41E;
              color: #382e34;
              vertical-align: middle;
              alignment: left;
            }
            .ant-input:placeholder-shown {
              //width: 150px;
              height: 30px;
              //padding: 10px 5px 5px 5px;
              font-size: 14px;
              color: #382e34;
              opacity: 0.6;
            }
            .ant-input:hover {
              background-color: #FFC457;
              border: 1px solid #382e34 !important;
            }
            .ant-input:focus {
              background-color: #FFC457;
              border: 1px solid #382e34 !important;
            }
          }
          input[type=text]:disabled {
            color: #382e34 !important;
            background: #EDA41E;
            border: 1px solid #382e34;
          }
          input[type=text]:disabled:hover {
            color: #382e34 !important;
            background: #EDA41E;
            border: 1px solid #382e34 !important;
          }
          .ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
            font-size: 20px;
            border-left: none;
            padding-left: 5px;
            color: #D4D7DA !important;
          }
          .ant-input-search-icon {
            font-size: 20px;
            border-left: 1px solid #382e34;
            padding-left: 5px;
            color: #382e34 !important;
          }
          .ant-input:focus {
            box-shadow: none;
          }
          .ant-input-clear-icon + i {
            margin-left: 0px;
          }
        }
      }

      .filters-bulk-redaction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .ant-select-open {
          .ant-select-search__field__placeholder {
            .place-holder-pii {
              display: flex;
              justify-content: space-between;
              .icon-v19-arrow-medium {
                transform: rotate(-180deg);
                -webkit-transform: rotate(-180deg);
              }
            }
          }
        }
        .content-filters-redaction {
          height: 30px;
          width: 100%;
          display: flex;
          .custom-option-redaction {
            background-color: #FFFFFF;
            //opacity: 0.6;
            .ant-select-arrow-icon {
              display: none;
            }
            .ant-select-arrow:after {
              border-radius: 50%;
              color: #637882;
              content: '\e896';
              font-family: 'smartbox_icons_v19' !important;
              display: inline-block;
              width: 17px;
              height: 17px;
              padding-top: 0px;
              padding-left: 0px;
              padding-bottom: 9px;
              font-style: normal;
              font-size: 20px;
            }
            .content-value {
              display: flex;
              justify-content: space-between;
              width: 100%;
              .value-option {

              }
              .icon-v19-arrow-medium {
                font-size: 20px;
                color: #382e34;
              }
            }
            .ant-select-arrow {
              top: 35%;
              margin-right: 5px;
              //display: none !important;
            }
            .ant-select-selection:disabled {
              background: #FFFFFF;
              cursor: not-allowed;
              opacity: 0.6;
            }
            .ant-select-selection--multiple {
              min-height: 30px;
              padding-bottom: 0px;
              cursor: text;
              zoom: 0;
            }
            .ant-select-selection--single {
              position: relative;
              height: 30px;
              cursor: pointer;
            }
          }
          .ant-select-disabled .ant-select-selection {
            background: #FFFFFF;
            cursor: not-allowed;
            opacity: 0.6;
          }
          .tree-select-option-redaction {
            .ant-select-selection__choice {
              display: none;
            }
            .place-holder-pii {
              display: flex;
              justify-content: space-between;
              .icon-v19-arrow-medium {
                font-size: 20px;
                color: #382e34;
              }
            }
            input[type=text]:disabled, select:disabled {
              border: 1px solid #FFFFFF;
              background-color: #FFFFFF;
              cursor: not-allowed;
              color: #CCC;
            }
            .ant-select-search__field__placeholder {
              left: 12px;
              display: flex !important;
              font-weight: 400;
              letter-spacing: 0.2px;
              vertical-align: middle;
              font-size: 14px;
              color: #382e34;
              .place-holder-pii {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .icon-v19-arrow-medium {
                  font-size: 20px;
                  color: #382e34;
                }
              }
            }
          }
          .ant-select-arrow {
            display: flex !important;
          }
          .custom-option-redaction {
             //width: 200px;
            margin-right: 10px;
            font-weight: 400;
            letter-spacing: 0.2px;
            vertical-align: middle;
            font-size: 14px;
            color: #382e34;
            width: 100%;
            .ant-select-selection-selected-value {
              padding-left: 10px;
              font-weight: 400;
              letter-spacing: 0.2px;
              vertical-align: middle;
              font-size: 14px;
              color: #382e34;
              padding-left: 10px;
              width: 100%;
            }
            .ant-select-selection--single .ant-select-selection__rendered {
              margin-right: 10px;
              margin-left: 0px;
            }
            .ant-select-selection {
              border: 1px solid #382e34;
              border-radius: 0px;
              box-shadow: none;
              cursor: pointer;
            }
            .ant-select-selection:hover {
              border: 1px solid #EDA41E;
              border-radius: 0px;
            }
            .ant-select-arrow {
              color: #382e34;
            }
            .ant-select .ant-select-selection .ant-select-arrow {
             margin-right: 10px;
            }
            .ant-select-selection__placeholder {
              font-weight: 400;
              letter-spacing: 0.2px;
              vertical-align: middle;
              font-size: 14px;
              color: #382e34;
              padding-left: 10px;
            }
          }
          .icon-v19-clear {
            font-size: 20px;
          }
        }
        .content-ai-dust {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 30px;
          .icon-v19-clear {
            font-size: 20px;
          }
          .icon-v19-clear-active {
            font-size: 20px;
            color: var(--generalIconFontHover);
          }
          .ai-dust {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .checkbox-auto {
            .option-select-all-disabled {
              opacity: 0.6;
            }
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 0% !important;
              border: 1px solid #6e7882;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #EDA41E !important;
            }
            .ant-checkbox-inner:after {
              border-color: #ffffff !important;
            }
          }
          .select-auto-dust {
            margin-right: 5px;
            margin-left: 5px;
            font-weight: 400;
            letter-spacing: 0.2px;
            vertical-align: middle;
            font-size: 14px;
            color: #382e34;
          }
          .icon-v19-question {
            font-size: 20px;
          }
        }
      }
      .table-bulk-redaction {
        width: 100%;
        .ant-checkbox-wrapper {
          vertical-align: middle;
          padding-left: 10px;
          line-height: 30px;
        }
        .ant-table-tbody > tr .column-ai-select-all {
          display: none;
        }
        .table-new-redaction {
          //.ant-table {
          //  line-height: 0;
          //}
          .ant-table-selection-column {
            //padding: 0px;
          }
          .ant-table-thead > tr:first-child > th:first-child {
            display: none;
          }
          .ant-table-thead > tr:first-child > th:last-child {
            border-top-right-radius: 0px;
          }
          .ant-checkbox-checked .ant-checkbox-inner::after {
            border-color: #FFFFFF;
            position: absolute !important;
            display: table;
            color: #FFFFFF;
            border: none !important;
            border-top: 0;
            border-left: 0;
            -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
            transform: rotate(0deg) scale(1) translate(-50%, -50%);
            opacity: 1;
            transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
            content: '\e8ce' !important;
            top: 8px !important;
            left: 7px !important;
            font-size: 12px !important;
            font-family: 'smartbox_icons_v19' !important;
          }
          .ant-table-tbody > tr > td {
            padding: 0px;
          }
          .ant-table-row-level-0 {
            .ant-table-selection-column {
              //display: flex;
              margin-left: 0px;
            }
          }
          .ant-table-header-column {
            vertical-align: bottom;
            .ant-checkbox-wrapper {
              display: none;
            }
          }
          .row-redacted {
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #535d66 !important;
            }
            td {
              background-color: #B9CADC !important;
            }
          }
          .row-redacted:hover {
            background-color: #b9cadc !important;
            opacity: 0.5;
          }
          .row-level-redaction {
            .ant-table-tbody > tr > td {
              padding: 16px 16px 16px 16px;
            }
            .ant-checkbox-wrapper {
              vertical-align: center !important;
              .ant-checkbox {
                vertical-align: middle;
              }
            }
          }
          tbody > tr > td.ant-table-selection-column {
            text-align: left;
          }
          .ant-table-header-column {
            margin-bottom: 0px;
          }
          .ant-table-thead > tr > th {
            color: #382e34;
            font-weight: 400;
            text-align: left;
            background: #FFFFFF;
            border-bottom: 1px solid #382e34;
            box-shadow: none !important;
            vertical-align: bottom;
          }
          .ant-table-thead > tr:first-child > th:first-child {
            border-top-left-radius: 0px;
          }
          .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: #EDA41E;
          }
          .ant-table-thead > tr > th {
            padding: 0px;
            overflow-wrap: break-word;
          }
          .ant-checkbox-wrapper .ant-checkbox-inner {
            border-radius: 0% !important;
            border: 1px solid #6e7882;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--generalIconFontActive) !important;
          }
          .ant-checkbox-inner:after {
            border-color: #ffffff !important;
          }
          .ant-table-tbody > tr > td {
            //border-bottom: 1px solid rgba(166,172,178,0.2);
          }
          .ant-table-selection-column {
            //display: none;
          }
          .ant-table-thead {
            .ant-table-selection-column {
              //display: none;
            }
          }
          .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
            margin-top: 0px !important;
            .ant-table-column-sorter-up {
              height: 9px !important;
            }
          }
          .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
            height: 1.5em;
            margin-top: 0.35em;
            margin-left: 0.57142857em;
            color: #bfbfbf;
            line-height: 30px;
            text-align: center;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            .ant-table-column-sorter-up {
              margin-top: -1px !important;
              font-size: 12px !important;
              line-height: 12px;
            }
          }
          .column-ai-select-all {
            width: 20px;
          }
          .column-ai-results {
            padding: 0px 10px 0px 0px;
            align-items: center;
            .ant-table-column-title span {
              margin-right: 0px;
              padding-right: 1px;
            }
            .select-all-bulk {
              .ant-checkbox-wrapper {
                display: initial;
                padding-left: 10px;
              }
            }
            .ant-table-header-column {
              width: 100%;
              display: flex;
              align-items: center;
              height: 30px;
            }
            .ant-input:placeholder-shown {
              color: #a6acb2;
            }
            .input-value-row:hover {
              border: 1px solid #EDA41E;
            }
            .input-value-row {
              border: 1px solid #6e7882;
              color: #6e7882;
              border-radius: 0px;
              width: 90%;
              overflow: auto;
              height: 30px;
              padding: 5px 10px;
              line-height: 30px;
            }
            .ant-table-column-title {
              font-family: Roboto;
              font-weight: 400;
              color: #382e34;
              letter-spacing: 0.2px;
              font-size: 14px;
              vertical-align: sub;
              line-height: 30px;
            }
            .text-ai-results {
              font-family: Roboto;
              font-weight: 400;
              color: #6e7882;
              letter-spacing: 0.2px;
              font-size: 14px;
              vertical-align: middle;
            }
            .icon-v19-edit {
              color: #6e7882;
              font-size: 20px;
              margin-left: 20px;
            }
            .icon-v19-edit-active {
              color: #EDA41E;
              font-size: 20px;
              margin-left: 20px;
            }
          }
          .ant-table-tbody > tr > td {
            //padding: 0px 16px;
            overflow: auto;
          }
          .column-ai-categories {
            //padding: 0px 0px;
            //align-items: center;
            .ant-table-header-column {
              //display: flex;
              width: 100%;
            }
            .content-row-dictionaries {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .icon-v19-delete:before {
                display: inline-flex;
                line-height: 20px;
              }
              .icon-v19-delete {
                font-size: 20px;
                color: #6e7882;
                line-height: 39px;
              }
              .icon-v19-delete-active {
                font-size: 20px;
                color: #EDA41E;
                line-height: 39px;
              }
              .icon-v19-delete-disabled {
                //opacity: 0.5;
                cursor: default;
                color: #DAD7DA;
              }
            }
            .ant-select-disabled .ant-select-selection {
              background: #FFFFFF;
              cursor: default;
              //opacity: 0.5;
              color: #D4D7DA !important;
              border: 1px solid #DAD7DA !important;
            }
            .ant-select-selection--single {
              height: 30px;
            }
            .custom-option-redaction:disabled {
              .ant-select-selection {
                background: #FFFFFF;
                cursor: not-allowed;
              }
            }
            .custom-option-redaction {
              //width: 200px;
              margin-right: 10px;
              font-weight: 400;
              letter-spacing: 0.2px;
              vertical-align: middle;
              font-size: 14px;
              color: #6e7882;
              width: 70%;
              overflow: hidden;
              height: 30px;
              line-height: 30px;
              .ant-select-selection-selected-value {
                padding-left: 10px;
                font-weight: 400;
                letter-spacing: 0.2px;
                vertical-align: middle;
                font-size: 14px;
                //color: #6e7882;
                padding-left: 10px;
                overflow: auto;
              }
              .ant-select-selection--single .ant-select-selection__rendered {
                margin-right: 10px;
                margin-left: 0px;
              }
              .ant-select-selection {
                border: 1px solid #6e7882;
                border-radius: 0px;
                //box-shadow: none;
              }
              .ant-select-selection:hover {
                border: 1px solid #EDA41E;
                border-radius: 0px;
              }
              .ant-select-arrow {
                //color: #6e7882;
              }
              .ant-select .ant-select-selection .ant-select-arrow {
                margin-right: 10px;
              }
              .ant-select-selection__placeholder {
                font-weight: 400;
                letter-spacing: 0.2px;
                vertical-align: middle;
                font-size: 14px;
                color: #382e34;
                padding-left: 10px;
              }
            }
            .content-column-ai-results {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .icon-v19-add {
                color: var(--generalButton);
                font-size: 20px;
                cursor: default;
                //opacity: 0.5;
              }
              .icon-v19-add-active {
                color: var(--generalIconFontHover);
                font-size: 20px;
                cursor: default;
              }
              .icon-v19-add-disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
            .ant-table-column-title {
              font-family: Roboto;
              font-weight: 400;
              color: #382e34;
              letter-spacing: 0.2px;
              font-size: 14px;
              vertical-align: middle;
            }
            .text-ai-categories {
              font-family: Roboto;
              font-weight: 400;
              color: #6e7882;
              letter-spacing: 0.2px;
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
.folder-redaction-page {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  .content-header-folder-redaction {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    margin-top: 30px;
    .new-radio-buttons {
      margin-bottom: 60px;
      .ant-radio-wrapper {
        margin-right: 20px;
      }
    }
    .redaction-button {
      margin-bottom: 35px;
      /*margin-left: 15px;*/
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-btn {
      height: 40px;
      object-fit: contain;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #232B38;
      background-color: white;
      border-radius: 30px;
      border: 1px solid #232B38;
      text-transform: uppercase;

      &:hover {
        border: 1px solid #232B38;
        color: #ffffff;
        background-color: #232B38;
      }

      &:focus {
        border: 1px solid #232B38;
        color: #ffffff;
        background-color: #232B38;
      }
    }
  }

  &__button-green {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-btn {
      height: 40px;
      object-fit: contain;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3DD8DB;
      background-color: white;
      border-radius: 30px;
      border: 1px solid #3DD8DB;
      text-transform: uppercase;

      &:hover {
        border: 1px solid #3DD8DB;
        color: #ffffff;
        background-color: #3DD8DB;
      }

      &:focus {
        border: 1px solid #3DD8DB;
        color: #ffffff;
        background-color: #3DD8DB;
      }
    }
    .ant-btn[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border: 1px solid rgba(0, 0, 0, 0.25) !important;
    }
  }

  &__folder-info-expanded {
    display: flex;
    margin-top: 40px;
  }

  &__folder-info {
    display: flex;
    margin-top: 40px;
    margin-bottom: 60px;

    &__info-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__name {
        font-size: 20px;
        font-weight: 300;
        color: #6e7882;
        margin-bottom: 5px;
        display: block;
        text-align: center;
      }

      &__data {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #6e7782;
        width: 100%;
      }
    }
  }

  &__radio-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 100px;
    .ant-radio-checked .ant-radio-inner {
      border: solid 4px #3DD8DB !important;
    }
    .ant-radio:hover {
      color: #3DD8DB;
    }
    .ant-radio {
      padding-bottom: 2px;
      &:hover {
        color: #3DD8DB;
      }
      .ant-radio-inner {
        border: solid 1px #6e7882;
        width: 18px;
        height: 18px;
      }
      .ant-radio-inner::after {
        content: "\f00c" !important;
        color: #3DD8DB !important;
        font-size: 7px !important;
        font-family: 'FontAwesome' !important;
        top: 2px;
        left: 1.5px;
        background-color: #FFFFFF;
      }
    }
    .radion-style {
      display: block;
      height: 30px;
      lineHeight: 30px;
    }

    .ant-radio-group {
      width: 35%;
      display: flex;
      justify-content: space-around;

      .ant-radio-wrapper {
        font-weight: 300;
        font-size: 16px;
        color: #6e7882;
      }
    }
  }

  .ant-tree {
    margin-top: 60px;
    .ant-tree-switcher {
      display: none;
    }

    .ant-tree-child-tree {
      padding: 0;
      margin-left: 10px;
    }

    .ant-tree-node-content-wrapper {
      width: calc(100% - 30px);
      height: 35px;
    }

    .ant-tree-checkbox {
      .ant-tree-checkbox-inner {
        border-radius: 50%;
        border: solid 1px #6e7882;
        width: 19px;
        height: 19px;
        z-index: 2;
      }

      .ant-tree-checkbox::after {
        visibility: hidden;

      }

      .ant-tree-checkbox-inner::after {
        content: "\f00c" !important;
        color: #3DD8DB !important;
        font-size: 8px !important;
        font-family: 'FontAwesome' !important;
        top: 1.2px;
        left: 1.2px;
        background-color: #FFFFFF !important;
        border-radius: 50%;
        transform: rotate(-5deg);
        padding: 1px 0 0 1px;
      }
    }

    .ant-tree-checkbox-checked {
      .ant-tree-checkbox-inner {
        background-color: #04D3D8;
        border: solid 3px #3DD8DB !important;
        &:after {
          padding: 1px 0 0 1px;
        }
      }
      &:after {
        border: none;

      }
    }

    .ant-tree-checkbox-indeterminate {
      .ant-tree-checkbox-inner::after {
        background-color: #04D3D8;
        left: 4px;
        top: 4px;
      }
    }
    .ant-tree-treenode-disabled {
      .ant-tree-node-content-wrapper {
        span.redact-label-fill {
          color: #fff !important;
        }
      }

    }
  }

  .ant-tree-checkbox-wrapper:hover {
    .ant-tree-checkbox-inner {
      border-color: #04D3D8;
    }
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
      border-color: #04D3D8;
    }
    .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
      border-color: #04D3D8;
    }
  }

  .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: transparent;
  }

  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background: transparent;
  }

  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .new-folder-insights-tree {
    background-color: #ffffff;
    padding: 50px 20px 50px 20px;
    border: 2px solid rgba(0, 0, 0, 0.10);
  }
  .folder-insights-tree {
    .top-parent {
      grid-template-columns: 30% 20% 20% 18% 10% 2%;
      &__parent-name {
        margin-left: 0;
      }

      &__parent-show-details {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        span {
          display: flex;
          height: 22px;
          align-items: center;
          font-size: 13px;
          border: 1px solid #6e7782;
          border-radius: 15px;
          padding: 4px;
          margin: 0 auto;
          &:hover {
            color: #04D3D8;
            border-color: #04D3D8;
          }
        }
      }
    }

    .top-parent.active-parent {
      color: #04D3D8;
      font-weight: bold !important;
      .top-parent__parent-name {
        color: #04D3D8;
      }
      .top-parent__parent-show-details {
        span {
          border-color: #04D3D8;
        }
      }
    }

    .category-parent {
      &__name {
        margin-left: 0;
      }
    }

    .category-name-parent {
      &__name {
        margin-left: 30px;
      }
    }

    .child {
      &__name {
        margin-left: 80px;
        .name {
          margin-right: 10px;
        }
      }
    }

    .child-tree-node {
      .ant-tree-node-content-wrapper {
        margin-left: 0;
      }
    }

    .category-name-parent-tree-node {
      .bottom-border {
        width: 98.5% !important;
        margin-left: 1.5% !important;
      }
    }
  }
  .redact-label {
    padding: 1px 2px;
    font-family: Roboto;
    font-size: 11px;
    font-weight: 500;
    &-fill {
      padding: 1px 2px;
      font-family: Roboto;
      font-size: 11px;
      font-weight: 500;
      color: #ffffff;
      border: 1px solid #000;
      background-color: #000;
    }
  }

  .top {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 40% 58% 2%;
    position: relative;
    margin-bottom: 5px;
    margin-right: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    &__name {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      color: #637882;
    }
    &__found {
      text-align: right;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      margin-right: 10px;
      color: #637882;
    }
    &__arrow {
      font-size: 15px;
    }
    &__bottom-border {
      border-top: 1px solid #777777;
      height: 1px;
      display: block;
      margin-bottom: 20px;
      margin-right: 18px;
    }
  }
  .top-parent {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 30% 20% 20% 20% 10%;
    color: #6e7782;
    font-size: 16px;
    font-weight: 500 !important;
    position: relative;
    margin-bottom: 5px;

    &__bottom-border {
      border-top: 1px solid #aaaaaa;
      height: 1px;
      display: block;
      margin-bottom: 20px;
    }

    &__parent-arrow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 16px;
    }

    &__parent-name {
      margin-left: 35px;
      font-weight: bold;
      font-size: 16px;
      font-weight: bold;
    }

    &__parent-show-details {
      display: none;
    }
  }

  .tp {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 36% 17% 17% 17% 11% 2%;
    color: #6e7782;
    font-size: 16px;
    font-weight: 500 !important;
    position: relative;
    margin-bottom: 5px;

    &__bottom-border {
      border-top: 1px solid #aaaaaa;
      height: 1px;
      display: block;
      margin-bottom: 20px;
    }

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 15px;
    }

    &__name {
      font-weight: bold;
      font-size: 16px;
      font-weight: bold;
      margin-left: 40px;
    }

    &__show-details {
      display: none;
    }
  }

  .category-parent {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 2% 28% 20% 20% 26% 2% 2%;
    color: #6e7782;
    font-size: 16px;
    font-weight: 500 !important;
    position: relative;
    line-height: 30px;
    &.active-parent {
      color: #00d3d8;
    }
    &__space-before {
      border-bottom: none;
    }
    &__name {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      text-transform: capitalize;
      border-bottom: 1px solid #aaaaaa;
      padding-left: 7px;
    }
    &__found {
      border-bottom: 1px solid #aaaaaa;
    }
    &__arrow2 {
      font-size: 14px !important;
    }
    &__arrow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px solid #aaaaaa;
      font-size: 16px;
    }
    &__space-after {
      border-bottom: 1px solid #aaaaaa;
    }
  }

  .cp {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 36% 17% 17% 17% 11% 2%;
    color: #6e7782;
    font-size: 16px;
    font-weight: 500 !important;
    position: relative;
    line-height: 30px;
    &.active-parent {
      color: #00d3d8;
    }
    &__space-before {
      border-bottom: none;
    }
    &__name {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      text-transform: capitalize;
      border-bottom: 1px solid #aaaaaa;
      padding-left: 7px;
      margin-left: 50px;
    }
    &__found {
      border-bottom: 1px solid #aaaaaa;
      padding-left: 3px;
    }
    &__arrow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px solid #aaaaaa;
      font-size: 15px;
    }
    &__space-after {
      border-bottom: 1px solid #aaaaaa;
    }
  }

  .category-name-parent {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 30% 20% 20% 20% 10%;
    color: #6e7882;
    font-size: 16px;
    font-weight: 300 !important;
    margin-bottom: 5px;

    &__folder-count {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &__document-count {
      grid-column-start: 4;
      grid-column-end: 5;
    }

    &__name {
      margin-left: 70px;

      .name {
        margin-right: 12px;
      }
    }

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: flex-end;

    }
  }

  .child {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 5% 13% 19% 17% 23% 2%;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    &.active-parent {
      color: #00d3d8;
    }
    &__name {
      margin-left: 0px !important;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 300;

    }
    &__found {
      text-align: right;
      font-size: 16px;
      font-weight: 300;
    }

    &__folder-name {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &__document-name {
      grid-column-start: 4;
      grid-column-end: 5;
    }
    &__btn-wrapper {
      text-align: right;
      float: right;
      margin-left: 100px;
      .show-all-btn {
        border: 2px solid #00d3d8;
        border-radius: 50px;
        color: #00d3d8;
        font-weight: bold;
        font-size: 14px;
        padding: 0 20px;
        line-height: 20px;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          background-color: #00d3d8;
        }
      }

    }
  }

  .ch {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 34% 9% 14% 20% 13% 2%;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    &.active-parent {
      color: #00d3d8;
    }
    &__name {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 300;
      margin-left: 60px;
    }
    &__found {
      text-align: right;
      font-size: 16px;
      font-weight: 300;
    }
    &__folder {
      margin-right: 5px;
    }

    .ch-redacted {
      margin-left: 5px;
    }

    &__folder-name {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &__document-name {
      grid-column-start: 4;
      grid-column-end: 5;
    }
    &__btn-wrapper {
      text-align: right;
      float: right;
      .show-all-btn {
        border: 2px solid #00d3d8;
        border-radius: 50px;
        color: #00d3d8;
        font-weight: bold;
        font-size: 14px;
        padding: 0 20px;
        line-height: 20px;
        cursor: pointer;
        margin-left: 22px;
        &:hover {
          color: #ffffff;
          background-color: #00d3d8;
        }
      }

    }
  }

  .nephew {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 7% 13% 17% 18% 21%;
    font-size: 16px;
    line-height: 30px;
    &__name {
      margin-left: 0px !important;
      text-transform: capitalize;

    }
    &__found {
      text-align: right;
      &__number {
        font-weight: bold;
      }
    }
  }

  .np {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 35% 8% 47% 5% 2%;
    font-size: 16px;
    line-height: 30px;
    &__name {
      text-transform: capitalize;
      margin-left: 70px!important;

    }
    &__found {
      text-align: right;
      &__number {
        font-weight: bold;
      }
      &__text {
        padding-right: 4px;
      }
    }
    &__redacted {
      text-align: right;
      .redact-label-fill {
        //margin-right: -7px;
      }
    }
  }

  .category-tree-node {
    margin-top: 0;

    .ant-tree-checkbox {
      width: 0;
      position: relative;

      .ant-tree-checkbox-inner {
        position: absolute;
        left: 12px;
        top: 4px;
        border-radius: 50%;
        margin-left: 0;
      }
    }
  }

  .top-parent-tree-node {
    margin-bottom: 0px;
    .ant-tree-checkbox {
      width: 0;
      position: relative;
      .ant-tree-checkbox-inner {
        position: relative;
        border-radius: 50%;
      }
    }
  }

  .category-name-parent-tree-node {
    .ant-tree-checkbox {
      width: 0;
      position: relative;

      .ant-tree-checkbox-inner {
        position: absolute;
        left: 48px;
        top: 4px;
        border-radius: 50%;
      }
    }

    .ant-tree-node-content-wrapper {
      margin-bottom: 7px;
      .bottom-border {
        display: none;
      }
    }

    .ant-tree-node-content-wrapper-open {
      .bottom-border {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        height: 1px;
        display: block;
        margin-bottom: 20px;
        width: 97.5%;
        margin-left: 2.5%;
      }
    }
  }

  .child-tree-node {
    .ant-tree-checkbox {
      width: 0;
      position: relative;

      .ant-tree-checkbox-inner {
        position: absolute;
        left: 39px;
        top: 4px;
        border-radius: 50%;
      }
    }

    .ant-tree-node-content-wrapper {
      margin-left: 7px;
      .bottom-border {
        display: none;
      }


    }
  }
  &__tree-redaction {
    background-color: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.10);
    margin-left: 10px;
    margin-right: 10px;
  }

  &__tree {
    .ant-tree {
      span.ant-tree-checkbox {
        padding-left: 11px;
      }
      //.ant-tree-treenode-switcher-open {
      //  height: 45px;
      //}
    }
    .title {
      margin-top: 28px;
      font-family: Roboto;
      font-weight: 300;
      font-size: 18px;
    }
  }
  .empty {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: Roboto;
    font-weight: 300;
    font-size: 18px;
  }
}
 .folder-insigths-page {
   .ant-tree {
     margin-top: 40px;
   }
 }
.folder-redaction-new-page {
  position: relative;
  .icon-v14-question {
    position: absolute;
    left: 60px;
    font-size: 20px;
    top: 30px;
  }
  .ant-tree {
    width: 98%;
    margin-bottom: 50px;
    margin-left: 20px;
    padding-right: 30px;
  }
}

.select-per-page-redaction {
  left: 50px !important;
  color: #382e34 !important;
  border: 1px solid #382e34 !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  width: 88px !important;
  height: 92px;
  .ant-select-dropdown {
    line-height: 30px;
  }
  .ant-select-dropdown-menu-item {
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #382e34;
    letter-spacing: 0.2px;
    alignment: left;
    vertical-align: middle;
    line-height: 30px !important;
    padding: 0px 10px !important;
  }
  .ant-select-dropdown-menu {
    padding: 0px 0 !important;
  }
}
.filter-option-all-redacted {
  border: 1px solid #EDA41E !important;
  top:312px !important;
  z-index: 1 !important;
  .ant-select-dropdown-menu-item {
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 0.2px;
    color: #382e34;
    vertical-align: middle;
    line-height: 30px !important;
    padding: 0px 10px;
  }
  .ant-select-dropdown-menu-item-selected {
    background-color: #FFFFFF !important;
  }
  .ant-select-dropdown-menu {
    max-height: 92px;
    padding: 0px 0;
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #9AE0fc50 !important;
  }
  .ant-select-dropdown {
    box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3);
  }
}
.tree-pii-options {
  line-height: 30px !important;
  top: 295px !important;
  min-width: 295px;
  .ant-select-selection--multiple {
    min-height: 30px !important;
    padding-bottom: 0px !important;
  }
  .ant-select-tree {
    margin: 0px;
    padding: 0px;
  }
  .ant-select-tree li ul {
    padding: 0 0 0 26px;
    background: #FFFFFF;
    margin-left: -10px;
    margin-right: -10px;
  }
  .ant-select-tree li .ant-select-tree-node-content-wrapper {
    padding: 0px 0px;
    border-radius: 0px;
  }
  .tree-parent-node .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
    font-size: 12px !important;
  }
  .tree-child-node .content-child-tree .title-child {
    line-height: 30px;
  }
  .ant-select-tree-checkbox {
    font-size: 12px;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    position: absolute !important;
    display: table;
    color: #6e7882 !important;
    border: none !important;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    transform: rotate(0deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
    content: '\e8ce' !important;
    top: 7px !important;
    left: 7px !important;
    font-size: 14px !important;
    font-family: 'smartbox_icons_v19' !important;
    background: #FFFFFF !important;
  }
  .ant-select-tree-checkbox-checked::after {
    border: 1px solid #EDA41E;
    border-radius: 0px;
  }
  .ant-select-tree li {
    margin: 0px 0;
  }
}

.dropdown-tree-dictionary {
  top: 295px !important;
  min-height: fit-content !important;
  .ant-select-tree {
    line-height: 30px;
    margin: 0px 0 0;
    padding: 0 0px;
  }
}
.dropdown-tree-regex {
  //min-width: fit-content !important;
  .title-parent {
    font-weight: 400;
  }
  .ant-select-tree {
    line-height: 30px !important;
    margin: 0px 0 0 !important;
    padding: 0 0px !important;
  }
  .ant-select-tree {
    margin-top: 0px !important;
  }
}

.dropdown-tree-pii-options {
  max-height: 60vh !important;
  border: 1px solid #EDA41E !important;
  top:312px !important;
  z-index: 1 !important;
  .ant-select-tree-checkbox-disabled {
    cursor: default;
  }
  .ant-select-tree li:hover {
    background-color: #9ae0fc50 !important;
  }
  .ant-select-tree-checkbox-disabled + span {
    color: rgba(0,0,0,0.25) !important;
    cursor: default !important;
  }
  .tree-buttons-node {
    //border-top: 1px solid #382e34;
    margin: 0px;
    li {
      margin: 0px;
    }
    .content-buttons-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0px 10px;
      border-top: 1px solid #382e34;
      padding: 10px 0px;
      .button-blue-apply:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
      .button-blue-apply {
        height: 25px;
        border: 1px solid #637882;
        background-color: #EDA41E;
        color: #ffffff;
        box-shadow: none;
        vertical-align: middle;
        horiz-align: center;
        letter-spacing: 0.5px;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        padding: 0px 18px;
        text-transform: capitalize;
        border-radius: 40px;
        font-family: Roboto;
        line-height: 18.75px;
        width: 80px;
        &:hover {
          background-color: #FFC457;
          color: #FFFFFF;
          border: 1px solid #EDA41E;
        }
        &:focus {
          background-color: #FFC457;
          color: #FFFFFF;
          border: 1px solid #EDA41E;
        }
        &:active {
          background-color: #EDA41E;
          color: #FFFFFF;
          border: 1px solid #EDA41E;
        }
      }
      .button-blue-clear:disabled {
        border: 1px solid #382e34 !important;
        background-color: #FFFFFF;
        opacity: 0.3;
        cursor: not-allowed;
      }
      .button-blue-clear {
        height: 25px;
        border: 1px solid #6e7882;
        background-color: #FFFFFF;
        color: #6e7882;
        box-shadow: none;
        vertical-align: middle;
        horiz-align: center;
        letter-spacing: 0.5px;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        padding: 0px 18px;
        text-transform: capitalize;
        border-radius: 40px;
        font-family: Roboto;
        line-height: 18.75px;
        width: 80px;
        &:hover {
          background-color: #FFFFFF;
          color: #FFC457;
          border: 1px solid #FFC457;
        }
        &:focus {
          background-color: #FFFFFF;
          color: #FFC457;
          border: 1px solid #FFC457;
        }
        &:active {
          background-color: #FFFFFF;
          color: #EDA41E;
          border: 1px solid #EDA41E;
        }
      }
    }
  .ant-select-tree-node-content-wrapper {
      width: calc(100% - 41px) !important;
    }
      .ant-select-tree-checkbox {
        display: none;
      }
    .ant-select-tree-node-content-wrapper:hover {
      background-color: #FFFFFF;
    }
  }
  .ant-select-tree li {
    padding: 0px 10px;
    margin: 0px 0px;
  }
  .ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
    width: calc(100% - 5%);
  }
  .ant-select-tree li .ant-select-tree-node-content-wrapper {
    padding: 0px 0px;
  }
  .ant-select-tree li span.ant-select-tree-checkbox {
    margin: 0 10px 0 0;
  }
  .tree-parent-node {
    //margin-left: 10px;
    .ant-select-tree-switcher {
      display: none;
    }
    .ant-select-tree-node-content-wrapper {
      //width: calc(100% - 20px) !important;
    }
    .ant-select-tree-node-content-wrapper:hover {
      //background-color: #9ae0fc50 !important;
    }
    .ant-select-tree-checkbox-inner:hover {
      border: 1px solid var(--generalIconFontHover) !important
    }
    .ant-select-tree-checkbox-inner:after {
      font-size: 12px !important;
    }
    .ant-select-tree-checkbox-checked::after {
      border: 1px solid var(--generalIconFontHover);
      border-radius: 0px;
    }
    .content-parent-tree {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .title-parent-regex {
        font-weight: 400 !important;
      }
      .title-parent {
        font-weight: 400;
        letter-spacing: 0.2px;
        vertical-align: middle;
        alignment: left;
        font-size: 14px;
        color: #382e34;
      }
      .icon-v19-arrow-medium {
        color: #382e34;
        font-size: 20px;
        margin-right: 10px;
      }
      .open-arrow {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        display: inline-block;
      }
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
      border-color: #FFFFFF;
      position: absolute !important;
      display: table;
      color: #FFFFFF !important;
      border: none !important;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      top: 7px !important;
      left: 7px !important;
      font-size: 12px !important;
      font-family: 'smartbox_icons_v19' !important;
    }
    .ant-select-tree li .ant-select-tree-node-content-wrapper {
      padding: 0px 0px !important;
      border-radius: 0px;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
      background-color: var(--generalIconFontActive);
      border-color: #6e7882;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:hover {
      border-color: #EDA41E;
    }
    .ant-select-tree-checkbox-inner::after {
      background-color: var(--generalIconFontActive);
    }
    .ant-select-tree-checkbox-inner:hover {
      border-color: #EDA41E;
    }
    .ant-select-tree-checkbox-inner {
      //background-color: #FFFFFF !important;
      border: 1px solid #382e34 !important;
      border-radius: 0px;
    }
  }
  .tree-child-node {
    .content-child-tree {
      .title-child {
        font-weight: 400;
        letter-spacing: 0.2px;
        vertical-align: middle;
        font-size: 14px;
        alignment: left;
        color: #6e7882;
      }
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
      background-color: var(--generalIconFontActive);
      border-color: #6e7882;
    }
  }
}
.dropdown-filters-options {
  border: 1px solid #EDA41E !important;
  .ant-select-dropdown-menu {
    padding: 0px 0px !important;
  }
  .ant-select-dropdown-menu-item {
    font-family: Roboto !important;
    font-weight: bold !important;
    font-size: 14px !important;
    letter-spacing: 0.2px;
    color: #382e34;
    vertical-align: middle;
  }
}
.custom-option-redaction-row {
  border: 1px solid #EDA41E !important;
  .ant-select-dropdown-menu {
    padding: 0px 0px !important;
  }
  .ant-select-dropdown-menu-item {
    font-family: Roboto !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 0.2px;
    color: #6e7882;
    vertical-align: middle;
  }
  .content-add-dictionaries {
    display: flex;
    align-items: center;
    .icon-v19-add {
      font-size: 20px;
      color: #EDA41E;
      margin-right: 5px;
    }
    .icon-v19-add:before {
      margin-left: 0px;
    }
  }
  .item-add-dictionaries {
    border-top: 1px solid #6e7882;
  }
}
.dictionary-modal-new-dictionary {
  .import-terms-section {
    .import-terms-form {
      .dict-modal-line-first {
        margin-bottom: 10px;
      }
    }
  }
}