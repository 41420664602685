.piaReport__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px;
}

.piaReport__summaryWrapper {
  margin-top: 30px;
}

.piaReport__loading {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.piaReport__header {
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 20px;
  padding-bottom: 20px;
  h1 {
    font-size: 24px;
    font-weight: 500;
  }
}

.piaReport__link {
  color: #3DD8DB;
  display: block;
  font-size: 14px;
  margin-bottom: 40px;
  text-decoration: underline;
  &:hover {
    font-style: italic;
  }
}

@media print {
  .piaReport__page {
    padding: 0;
  }
  .piaReport__summaryWrapper {
    margin: 0;
  }
  .piaReport__link {
    display: none;
  }
  .piaReport__header {
    border-bottom: 1px solid #f2f2f2;
  }
}