.select-content-file {
  background: #00FF00;
}
.ant-modal-confirm {
  width: 600px;
}
.ant-modal-confirm .ant-modal-body {
  word-break: break-word;
}
.new-content-file-name {
  width: 160px !important;
  height: 31px !important;
  margin-bottom: 5px;
  margin-top: 0px;
.name-file-smart-box {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  color: #382e34;
  line-height: 16px;
}
}
.is-draggable {
  cursor: pointer;
}
.new-content-file-box {
  width: 180px;
  height: 180px;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  .react-contextmenu-wrapper {
    //width: 160px;
    //height: 160px;
  }
  .content-file__image .bulk-processing {
    left: 18px;
    top: 14px;
  }
  .content-file__image .image-animation-culling {
    color: #382e34;
    width: 20px;
    height: 18px;
    left: 18px;
    top: 14px;
    position: absolute;
  }
  .imgCulling-loading {
    color: #382e34;
    width: 20px !important;
    height: 18px !important;
    left: 18px !important;
    top: 14px !important;
    position: absolute !important;
  }
  .content-file__image .imgCulling-loading {
    color: #382e34;
    width: 20px;
    height: 18px;
    left: 18px;
    top: 14px;
    position: absolute;
  }
  .content-file__image .new-bulk-processing {
    background-color: #fafafa;
  }
  .content-file__image .new-bulk-processing.new-bulk-processing-update {
    background-color: transparent;
    left: -31px;
    top: -16px;
    z-index: 0;
  }
  .content-file__image.directory .icon-finalise {
    left: 18px;
    top: 14px;
  }
}
.content-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-end;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 2px;
  padding: 10px;
  word-break: break-word;
  border: solid 1px transparent;
  margin-right: 1px;
  border: 1px solid transparent;

  &.drop-active {
    border: 2px dashed #9ae0ff;
  }
  &__select {
    border-radius: 4px;
    border: solid 1px #9ae0ff;
    background-color: #e5f7ff;
    margin-right: 1px;
  }
  &.drag-select {
    border-radius: 4px;
    border: solid 1px #9ae0ff;
    background-color: #e5f7ff;
    margin-right: 1px;
  }

  &__image {
    position: relative;
    .bulk-processing {
      position: absolute;
      z-index: 1;
      left: 40px;
      top: 10px;
      background: white;
      border-radius: 50%;
    }
    .position-icons {
      position: absolute;
      z-index: 1;
      left: 7px;
      top: 8px;
      background: white;
      border-radius: 50%;
    }
    .position-icons-update {
      z-index: 0;
      left: -28px;
      top: -14px;
      background: transparent;
    }
    .error-message-file {
      z-index: 1;
      background: white;
      border-radius: 50%;
    }

    &__delete {
      display: none;
      position: absolute;
      top: 40%;
      left: 42%;
      cursor: pointer;
    }
    .action-panel-hovering {
      position: absolute;
      top: 1px;
      right: 2px;
      opacity: 0.8;
      width: 30px;
      z-index: 1;
      cursor: pointer;
    }
    .action-panel-hovering:before {
      font-size: 30px;
    }

    .image-container:hover > &__delete {
      display: block;
    }

    &__name {
      word-break: break-word;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &__name > .new-folder-input {
      width: 148px;
    }
  }



  &__image.directory {
    .content-file__image__delete {
      top: 45%;
      left: 47%;
    }
  }

  &__image.directory {
    cursor: pointer;
  }

  &__image:not(.directory) {
    .image-container {
      .settings {
        display: none;
      }
    }
  }


  &__image.directory {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    .icon-finalise2 {
      font-size: 12px;
      height: 16px;
      left: 104px !important;
      top: 1px !important;
      color: #6e7882;
      transform: rotate(11deg);
      background-color: #FFFFFF;
    }
    .icon-finalise {
      font-size: 15px;
      height: 16px;
      left: 32px;
      top: 10px;
    }
    .icon-finalise-on-rename {
      font-size: 15px;
      height: 16px;
      left: 4px;
      top: 8px;
    }

  }

  &__image:not(.directory) {
    .image-container {
      .settings {
        display: none;
      }
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;
    .processing {
      //opacity: 0.5;
    }
    &>span{
      margin-bottom: 0;
    }
    .icon-v12-process-error {
      color: #ff0000;
    }

    //.content-info-total-files {
    //  height: 20px;
    //}
    .new-duplicate-file-progress {
     margin-bottom: 4px;
    }
    .content-name-duplicate-file {
      .icon-v15-duplicate {
        font-size: 16px;
        color: #491bc2;
      }
      .icon-v14-star-active {
        font-size: 17px;
        color: #EDA41E;
      }
    }
    .content-number-files {
      font-size: 12px;
      font-weight: 300;
      font-family: Roboto;
      color: #6e7882;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 3px;
    }
    .content-number-files-progress {
      margin-bottom: 0px !important;
    }
    .thumbnail-container-landscape {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        box-shadow: 0px 0px 0px 1px #6e7882;
        height: 100%;
        width: 60px;
      }
    }
    .thumbnail-container-svg {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        box-shadow: 0px 0px 0px 1px #6e7882;
        height: 100%;
        width: 60%;
      }
    }
    .thumbnail-container {
      height: 80px;
      display: flex;
      align-items: center;
      img {
        box-shadow: 0px 0px 0px 1px #6e7882;
        height: 100%;
        width: auto;
      }
    }

    [class^="icon-v3-"] {
      font-size: 80px !important;
      height: 80px;
      line-height: 1em;
      &:before {
        /*height: 70px;*/
        margin-left:0;
        margin-right: 0;
      }
    }
    .box-error {
      opacity: 0.4;
    }
    .new-file-box {
      width: 80px;
    }
    img {
      width: 80px;
    }

    [class^="icon-v2-smart-folder"] {
      font-size: 112px !important;
    }

    .settings {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 24px;
      font-weight: 700;
      cursor: pointer;
      letter-spacing: 2px;
    }
      .update-progress-bar-wrapper {
        min-height: 5px !important;
      }
    .progress-bar-wrapper {
      width: 160px;
      min-height: 19px;
      margin-bottom: 0px;
      .animation-extraction {
        width: 30px;
        height: 20px;
      }
      .content-progress-extraction {
        display: flex;
        align-items: center;
        img {
          width: 12px;
          height: 16px;
        }
      }
      .stripes-progress {
        .ant-progress-line .ant-progress-outer .ant-progress-inner {
          width: 100px;
          border: 1px solid #6E7882;
          vertical-align: middle;
          background-color: #ffffff;
          height: 7px;
        }
        @-webkit-keyframes MOVE-BG {
          from {
            -webkit-transform: translateX(0);
          }
          to {
            -webkit-transform: translateX(46px);
          }
        }

        @keyframes MOVE-BG {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(46px);
          }
        }
        @keyframes move {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: 50px 50px;
          }
        }
        .ant-progress-bg {
          //background-image: linear-gradient(120deg,
          //        #00D3D8 30%,
          //        #00B8C4 30%,
          //        #00B8C4 50%,
          //        #00D3D8 50%,
          //        #00D3D8 80%,
          //        #00B8C4 80%,
          //        #00B8C4 100%);
          //background-image: -webkit-linear-gradient(120deg,
          //        #00D3D8 30%,
          //        #00B8C4 30%,
          //        #00B8C4 50%,
          //        #00D3D8 50%,
          //        #00D3D8 80%,
          //        #00B8C4 80%,
          //        #00B8C4 100%);
          background-image: linear-gradient(
                          -45deg,
                          #00B8C4 25%,
                          transparent 25%,
                          transparent 50%,
                          #00B8C4 50%,
                          #00B8C4 75%,
                          transparent 75%,
                          transparent
          );
          //background-size: 5.77px 10.00px;
          z-index: 1;
          background-size: 10px 10px;
          animation: move 4s linear infinite;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          overflow: hidden;
          //-webkit-animation-name: MOVE-BG;
          //-webkit-animation-duration: 2s;
          //-webkit-animation-timing-function: linear;
          //-webkit-animation-iteration-count: infinite;
          //
          //animation-name: MOVE-BG;
          //animation-duration: 2s;
          //animation-timing-function: linear;
          //animation-iteration-count: infinite;
        }
        .image-animation-extract {
          width: 20px;
          height: 18px;
          color: #382e34;
        }
        .image-animation-culling {
          color: #382e34;
          width: 20px;
          height: 18px;
          img {
            width: 20px;
            height: 18px;
          }
        }
      }
      .width-progress-update {
        .ant-progress-line {
          width: 100%;
        }
      }
      .width-progress-extraction {
        .ant-progress-line {
          width: 80%;
        }
      }
      .ant-progress-line {
        .ant-progress-outer {
          background-color: transparent;
          padding: 2px 16px 2px 16px;
          .ant-progress-inner {
            width: 100px;
            border: 1px solid #6E7882;
            vertical-align: middle;
            background-color: #ffffff;
            height: 8px;
          }
        }
      }
    }
  }
  .content-file {
    &__status-icons {
      //width:20px;
      //height:20px;
      position: absolute;
      top:35px;
      left:15px;
      .color-status-file {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
      }
    }
  }
   .icon-v11-options-small-active:before {
     /*line-height: 0.95em;*/
     margin-left: 0em;
   }
  .icons-container-file-view {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px 5px;
    font-size: 14px;
    position: absolute;
    top: 9px;
    left: 48px;
    z-index: 2;

    &.not-ready {
      padding: 0;
      font-size: 16px;
      color: #6e7882;
      top: 31px !important;
      left: 72px;
    }
    i, img {
      background-color: #ffffff;
    }
    :only-child {
      margin-right: 4px;
    }
  }
&__validation {
  height: 70px !important;
}
  &__name {
    overflow: hidden;
    height: 50px;
    //margin-top: 20px;
    width: 156px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;

    .validation-name {
      color: red;
      font-size: 12px;
    }
  }
}

.content-file:hover, .content-file.active{
  border-radius: 4px;
  border: solid 1px #9ae0ff;
}

.react-contextmenu {
  z-index: 10;
}
.delete-selected-box {
  width: 450px !important;
  height: 195px;
  padding: 0 0 0px !important;
  .ant-spin-spinning {
    margin-right: 2px;
  }
  .ant-modal-content {
    box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3) !important;
    border-radius: 0px;
    .ant-modal-body {
      padding: 30px;
      line-height: 20px;
      font-weight: 700;
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 10px;

          .ant-modal-confirm-title {
            font-weight: 700;
            font-size: 14px;
            color: #382e34;
            line-height: 20px;
          }

          .ant-modal-confirm-content {
            margin-left: 0;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #382e34;
            font-style: normal;
            line-height: 20px;
          }
        }
        .ant-modal-confirm-btns {
          margin-top: 40px !important;
          margin-bottom: 0px;
          .cancel-button {
            background-color: #FFFFFF;
            color: #6e7882;
            width: 100px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: 'Roboto';
            font-weight: 400;
            border-radius: 30px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            span {
              padding: 0px;
            }
          }
          .cancel-button:hover {
            color: var(--generalButtonHover);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
          }
          .cancel-button:active {
            color: var(--generalButtonActive);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
          }
          .confirm-button {
            background-color: var(--generalButton);
            color: var(--generalButtonFont);
            width: 100px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: ROBOTO;
            font-weight: 400;
            border-radius: 30px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            span {
              padding: 0px;
            }
          }
          .confirm-button:hover {
            color: var(--generalButtonFont);
            border: 1px solid var(--generalButtonHover);
            background-color: var(--generalButtonHover);
          }
          .confirm-button:active {
            //color: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
          }
        }
      }
    }
  }
}
.modal-delete-box {
  .ant-modal-content {
    box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3) !important;
    width: 450px;
    border-radius: 0px;
    .ant-modal-body {
      padding: 30px;
       .content-delete-box {
         display: flex;
         align-items: center;
         flex-direction: column;
         .icon-v19-delete {
           font-size: 40px;
           color: #382e34;
         }
       }
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;

        .ant-modal-confirm-title {
          font-weight: 700;
          font-size: 14px;
          color: #382e34;
          line-height: 20px;
        }

        .ant-modal-confirm-content {
          margin-left: 0;
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #6e7882;
        }
      }
        .ant-modal-confirm-btns {
          margin-top: 40px !important;
          .cancel-button {
            background-color: #FFFFFF;
            color: #6e7882;
            width: 100px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: ROBOTO;
            font-weight: 400;
            border-radius: 25px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            span {
              padding: 0px;
            }
          }
          .cancel-button:hover {
            color: var(--generalButtonHover);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
          }
          .cancel-button:active {
            color: var(--generalButtonActive);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
          }
          .confirm-button {
            background-color: var(--generalButton);
            color:var(--generalButtonFont) ;
            width: 100px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: ROBOTO;
            font-weight: 400;
            border-radius: 25px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            span {
              padding: 0px;
            }
          }
          .confirm-button:hover {
            color: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
            background-color: var(--generalButtonHover);
          }
          .confirm-button:active {
            color: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
          }
        }
    }
    }
  }
}

.modal-delete-failed {
  .icon-v23-information {
    font-size: 29px;
  }
}