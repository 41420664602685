@font-face {
  font-family: 'smartbox_icons_v9';
  src: url('./font_v16/smartbox_icons_v9.eot?11482270');
  src: url('./font_v16/smartbox_icons_v9.eot?11482270#iefix') format('embedded-opentype'),
       url('./font_v16/smartbox_icons_v9.woff2?11482270') format('woff2'),
       url('./font_v16/smartbox_icons_v9.woff?11482270') format('woff'),
       url('./font_v16/smartbox_icons_v9.ttf?11482270') format('truetype'),
       url('./font_v16/smartbox_icons_v9.svg?11482270#smartbox_icons_v9') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'smartbox_icons_v9';
    src: url('../font/smartbox_icons_v9.svg?11482270#smartbox_icons_v9') format('svg');
  }
}
*/
[class^="icon-v17"]:before, [class*=" icon-v17"]:before {
  font-family: "smartbox_icons_v9";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-v17-document-note-active-1:before { content: '\e8d5'; } /* '' */
.icon-v17-document-note-1:before { content: '\e8d6'; } /* '' */
.icon-v17-word-search-selected:before { content: '\e82c'; } /* '' */
.icon-v17-word-search:before { content: '\e82d'; } /* '' */
.icon-v17-download:before { content: '\e83c'; } /* '' */
.icon-v17-download-active:before { content: '\e8cc'; } /* '' */
.icon-v17-undo:before { content: '\e837'; } /* '' */
.icon-v17-undo-selected:before { content: '\e841'; } /* '' */
.icon-v17-redo-selected:before { content: '\e831'; } /* '' */
.icon-v17-redo:before { content: '\e832'; } /* '' */
.icon-v17-clear-selected-selected:before { content: '\e825'; } /* '' */
.icon-v17-clear-selected:before { content: '\e826'; } /* '' */
.icon-v17-clear-page-selected:before { content: '\e821'; } /* '' */
.icon-v17-clear-page:before { content: '\e822'; } /* '' */
.icon-v17-move:before { content: '\e83a'; } /* '' */
.icon-v17-move-selected:before { content: '\e82e'; } /* '' */
.icon-v17-draw-redaction-selected:before { content: '\e827'; } /* '' */
.icon-v17-draw-redaction:before { content: '\e828'; } /* '' */
.icon-v17-text-selection-selected:before { content: '\e82f'; } /* '' */
.icon-v17-text-selection:before { content: '\e830'; } /* '' */
.icon-v17-annotation:before { content: '\e80f'; } /* '' */
.icon-v17-annotate-selected:before { content: '\e81f'; } /* '' */
.icon-v17-annotate-1:before { content: '\e820'; } /* '' */
.icon-v17-preview:before { content: '\e815'; } /* '' */
.icon-v17-preview-selected-1:before { content: '\e847'; } /* '' */
.icon-v17-close-black:before { content: '\e8fc'; } /* '' */
.icon-v17-personal-active:before { content: '\e8ff'; } /* '' */
.icon-v17-personal:before { content: '\e900'; } /* '' */
.icon-v17-search-history:before { content: '\e904'; } /* '' */
.icon-v17-images:before { content: '\e80c'; } /* '' */
.icon-v17-spell-checker-1:before { content: '\e80b'; } /* '' */
.icon-v17-db-documents:before { content: '\e936'; } /* '' */
.icon-v17-metadata-1:before { content: '\e80e'; } /* '' */
.icon-v17-redaction-hover:before { content: '\e8d7'; } /* '' */
.icon-v17-redaction:before { content: '\e8d8'; } /* '' */
.icon-v17-redaction-active:before { content: '\e8b0'; } /* '' */
.icon-v17-back-1:before { content: '\e898'; } /* '' */
.icon-v17-forth-1:before { content: '\e89f'; } /* '' */