.privacyConnectorCreate__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyConnectorCreate__error {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  text-align: center;
}

.privacyConnectorCreate__loading {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.privacyConnectorCreate__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
}

.privacyConnectorCreate__boxHeader {
  border-bottom: 1px solid #E6E6E6;
  padding: 20px;
}

.privacyConnectorCreate__boxTitle {
  color: #162737;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
}

.privacyConnectorCreate__formFields {
  border-bottom: 1px solid #D6D3D3;
  max-width: 480px;
  padding: 20px;
}

.privacyConnectorCreate__formFields .privacyConnectorCreate__formGroup {
  margin-bottom: 20px;
}

.privacyConnectorCreate__formGroup label {
  color: #162737;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 5px;
}

.privacyConnectorCreate__formGroup p {
  color: #162737;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding-bottom: 10px;
}

.privacyConnectorCreate__formGroup input {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  height: 40px;
  padding: 10px;
  width: 100%;
}

.privacyConnectorCreate__formGroup input:focus {
  outline: 2px solid #3DD8DB;
}

.privacyConnectorCreate__formGroup select {
  appearance: none;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  color: #555555;
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
}

.privacyConnectorCreate__formGroup select:focus {
  outline: 2px solid #3DD8DB;
}

.privacyConnectorCreate__buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.privacyConnectorCreate__buttons button {
  margin-top: 20px;
  width: 200px;
}

@media screen and (min-width: 768px) {
  .privacyConnectorCreate__buttons {
    flex-direction: row;
    margin-top: 25px;
  }

  .privacyConnectorCreate__buttons button {
    margin-right: 15px;
    margin-top: 0;
  }
}