.new-custom-pagination-sb {
  .button-control {
    .button-control-content {
      font-family: Roboto;
      font-size: 14px;
      color: #6e7882;
      .insert-page {
        padding: 3px 2px;
        &:hover {
          color: var(--generalIconFontHover);;
          border: 1px solid var(--generalIconFontHover);;
        }
      }

    }
  }
}

.custom-pagination-sb {
  .button-control {
    float: left;
    display: flex;
    align-items: center;
    .disabled {
      color: #cccccc !important;
      cursor: not-allowed !important;
    }
    :not(.disabled) {
      color: #6e7882;
    }
    .icon-v9-start {
      font-size: 30px;
      margin-right: 3px;
      color: #6e7882;
      cursor: pointer;
    }
    .icon-v14-back-1 {
      font-size: 20px;
      color: #6e7882;
      cursor: pointer;
      border: 1px solid transparent;
      margin-left: 5px;
      margin-right: 5px;
      &:hover {
        color: #00d4d8;
        //border: 1px solid #00d4d8;
      }
    }
    .icon-v9-end {
      font-size: 20px;
      color: #6e7882;
      cursor: pointer;
      &:hover {
        color: #00d4d8;
        border: 1pt solid #00d4d8; }
    }
    .icon-v14-start-active {
      font-size: 20px;
      color: var(--generalIconFontHover);
      margin-left: 10px;
      margin-right: 5px;
      cursor: pointer;
      border: 1pt solid transparent;
    }
    .icon-v14-start-1 {
      font-size: 20px;
      cursor: pointer;
      color: #6e7882;
      border: 1pt solid transparent;
      margin-left: 10px;
      margin-right: 5px;
      /*&:hover {
        color: #00d4d8;
        border: 1pt solid #00d4d8; }*/
    }

    .icon-v21-back-active{
      font-size: 20px;
      color: #eda41e;
      border:1px transparent;
    }
    .icon-v21-back-active:before{
      font-size: 20px;
      color: var(--generalIconFontHover);
      border:1px solid #6e7882;
      border-radius: 50%;
      width: 22px;
      height: 22px;
    }
    .icon-v21-back-active:before:hover{
      border: 1px solid transparent;
    }
    .icon-v14-forth-1.disabled{
        font-size: 20px;
      }

    .icon-v21-forth-active{
      font-size: 20px;
      color: var(--generalIconFontHover);
      border:1px transparent;
    }
    .icon-v21-forth-active:before{
      font-size: 20px;
      color: var(--generalIconFontHover);
      border:1px solid #6e7882;
      border-radius: 50%;
      width: 22px;
      height: 22px;
    }
    .icon-v21-forth-active:before:hover{
      border: 1px solid transparent;
    }

    .icon-v14-end-active {
      font-size: 20px;
      color: var(--generalIconFontHover);
      cursor: pointer;
      border: 1px solid transparent;
      margin-left: 5px;
      margin-right: 10px;
    }
    .icon-v14-end-1 {
      font-size: 20px;
      cursor: pointer;
      color: #6e7882;
      border: 1px solid transparent;
      /*&:hover {
        color: #00d4d8;
        border: 1px solid #00d4d8; }*/
      margin-left: 5px;
      margin-right: 10px;
    }
    &__text {
      margin: 0 10px;
      font-weight: 300;
      font-size: 14px;
    }
    .insert-page {
      text-align: center;
      width: 50px;
      padding: 1px 5px !important;
      border-color: #6e7882;
      margin-right: 10px;
      font-weight: 300;
      background-color: #fafafa;
      border-radius: 3px;
      font-size: 14px;

      &::placeholder {
        color: #ccc;
      }
      &:focus {
        border-color: #ccc;
        outline: none;
      }
    }
  }
}