.smart-box-folder-data-collector {
  position: relative;
  margin-top: 0px;
  padding-bottom: 100px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_header {
    position: relative;
    padding: 20px 20px 40px 20px;
    width: calc(100% - 300px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 0px 0px 40px 0px;
    margin-top: 60px !important;
    @media (max-width: 1200px) {
      padding: 20px 20px 20px 0px;
    }

    .folder-name {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    .ant-btn {
      width: 110px;
      height: 45px;
      border: 1px solid #3DD8DB;
      background-color:#3DD8DB;
      color: #FFFFFF;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      cursor: pointer;
      padding: 10px 30px;
      text-transform: uppercase;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 900;
      &:hover {
        background-color: #FFFFFF;
        border-color: #3DD8DB;
        color: #3DD8DB;
      }
      &:focus {
        border: 1px solid #3DD8DB;
        background-color:#3DD8DB;
        color: #FFFFFF;
      }
      &:active {
        border: 1px solid #3DD8DB;
        background-color:#3DD8DB;
        color: #FFFFFF;
      }
    }
  }

  &_content {
    background-color: #FFFFFF !important;
    width: 100%;
    .page-data-collector {
      padding: 0px 40px 0px;
      .header-data-collector {
        //margin-top: 30px;
        margin-bottom: 40px;
        .title-data-collector {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.2px;
          vertical-align: middle;
          horiz-align: left;
          color: #382e34;
          margin-top: 20px;
          margin-left: 0px;
        }
      }

      .content-data-collector {

        .data-collector-contain {
          border: 1px solid #382e34;
           .date-source-search-queries {
             .content-date-source {
               .contact-details {
                 border-bottom: 1px solid transparent !important;
               }
               .contact-details-contain {

                 .checkbox-contain {
                   //width: auto !important;
                   width: 50%;
                   .ant-checkbox-wrapper {
                     align-items: center;
                     display: flex;

                   }
                 }
               }
             }
           }
          .date-source {
            display: flex;
            flex-direction: row;
            .icon-v23-upload-1 {
              font-size: 30px;
              margin-top: 10px;
            }
            .icon-v22-search-b {
              font-size: 20px;
              margin-top: 0px;
              padding: 20px 0px 0px 70px;
            }
            .icon-v22-db-storage {
              font-size: 30px;
              margin-top: 20px;
              margin-left: 11px;
            }
            .content-date-source {
              width: 100%;
              padding: 20px 20px 20px 16px;
              .date-source-header {
                width: 100%;
                border-bottom: 1px solid #382e34;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 30px;
                .icon-v22-add {
                  color: #eda41e;
                }
                .icon-v22-add-active {
                  color: #eda41e;
                  cursor: pointer;
                }
                .name-data-source {
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #382e34;
                  //padding-bottom: 5px;
                }
              }
              .contact-details {
                margin-top: 20px;
                border-bottom: 1px solid #D4D7DA;
                .contact-details-name {
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #382e34;
                  padding-bottom: 5px;
                  margin-bottom: 10px;
                  display: flex;
                }
                .contact-details-contain {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  margin-bottom: 10px;
                  align-items: center;
                  .input-folder-name-default {
                    width: 100% !important;
                    border: 1px solid #382e34;
                    color: #6e7882;
                    background-color: rgba(255, 255, 255, 0.2509803922);
                    padding: 0 10px;
                    font-size: 16px;
                    font-weight: 400;
                    height: 30px;
                    border-radius: 0px;
                    cursor: default;
                    &:hover {
                      border: 1px solid #EDA41E;
                      box-shadow: none;
                    }
                    &:focus {
                      border: 1px solid #EDA41E;
                      box-shadow: none;
                    }
                    &:active {
                      border: 1px solid #EDA41E;
                      box-shadow: none;
                    }
                  }
                  .input-folder-name {
                    width: 100% !important;
                  }
                  .checkbox-contain-manual {
                    //width: 1% !important;
                  }
                  .checkbox-contain {
                    width: 50%;
                    display: flex;
                    .value-checkbox {
                      .first-value-checkbox {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        color: #382e34;
                      }
                      .last-value-checkbox {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        color: #d4d7da;
                      }
                    }
                    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                      margin-left: 0px;
                    }
                    .ant-checkbox-wrapper {
                      line-height: 2;
                      span:not(:first-child) {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 30px;
                      }
                      .ant-checkbox-inner {
                        border-radius: 0!important;
                        border:1px solid #6e7882;
                        &:after {
                          border-color:#eda41e;
                          background-color: transparent;
                        }
                        &:hover {
                          border-color:#eda41e;
                        }
                      }
                    }
                    .ant-checkbox-checked {
                      .ant-checkbox-inner {
                        background-color: #EDA41E !important;
                        &:after {
                          position: absolute !important;
                          display: table;
                          color:#FFFFFF;
                          border:none !important;
                          transform: rotate(0deg) scale(1) translate(-50%, -50%);
                          opacity: 1;
                          transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                          content: "\e8ce" !important;
                          top: 8px !important;
                          left: 7px !important;
                          font-size: 12px !important;
                          font-family: "smartbox_icons_v19" !important;
                        }
                      }
                    }
                  }

                  .option-contact-details {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    justify-content: flex-end;
                    .input-folder-name-remove {
                      width: 77%;
                    }
                    .icon-v22-add {
                      color: #eda41e;
                    }
                    .icon-v22-add-active {
                      color: #eda41e;
                      cursor: pointer;
                    }
                    .icon-v22-minus {
                      margin-left: 5px;
                      color: #6e7882;
                    }
                    .icon-v22-minus-active {
                      margin-left: 5px;
                      cursor: pointer;
                    }
                    .input-folder-name {
                      border: 1px solid #382e34;
                      color: #6e7882;
                      background-color: rgba(255, 255, 255, 0.2509803922);
                      padding: 0 10px;
                      font-size: 14px;
                      font-weight: 400;
                      height: 30px;
                      border-radius: 0px;
                      cursor: default;
                      //width: 80%;
                      //margin-right: 10px;
                      &:hover {
                        border: 1px solid #EDA41E;
                        box-shadow: none;
                        cursor: pointer;
                      }
                      &:focus {
                        border: 1px solid #EDA41E;
                        box-shadow: none;
                      }
                      &:active {
                        border: 1px solid #EDA41E;
                        box-shadow: none;
                      }
                    }
                    .date-picker {
                      // margin-top: 10px;
                      //margin-bottom: 20px;
                      border:1px solid #382e34;
                      //border-radius: 4px;
                      margin-right: 40px;
                    }
                    .date-picker:hover {
                      border:1px solid #eda41e;
                    }
                    .ant-calendar-picker .ant-calendar-picker-input:hover {
                      border-color: #eda41e !important;
                      cursor: pointer;
                    }
                    .ant-calendar-range-picker-input:hover {
                      cursor: pointer;
                    }
                    .ant-calendar-range-picker-input:placeholder-shown {
                      cursor: pointer;
                    }
                    .ant-calendar-picker {
                      width: 80%;
                      .ant-calendar-picker-input {
                        border-bottom: none;
                      }
                      .ant-calendar-picker-icon {
                        right:12px;
                      }
                      .ant-calendar-range-picker-input::placeholder {
                        color: #382e34;
                        font-size: 16px;
                      }
                    }
                    .select-details-email {
                      width: 100%;
                      margin-right: 10px;
                      .ant-select-selection{
                        border: 1px solid #382e34;
                        border-radius: 0px;
                      }
                      .ant-select-selection__placeholder {
                        font-weight: 400;
                      }
                      .ant-select-selection:hover {
                        border-color: #eda41e;
                      }
                      .ant-select-selection:active {
                        border-color: #eda41e;
                      }
                      .ant-select-selection:focus {
                        border-color: #eda41e;
                      }
                      .ant-select-selection__rendered {
                        color: #382e34;
                      }
                    }
                  }
                }
                .finance-payroll-system {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
                  align-items: center;
                  .name-finance-payroll {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #382e34;
                  }
                  .icon-v22-upload-1 {
                    font-size: 18px;
                    &:hover {
                      cursor: pointer;
                      color: #eda41e;
                    }
                  }
                }
              }
            }
          }
          .contain-data-business-active {
            background-color: #FFC457;
          }
          .contain-data-business-manual {
            background-color: #FAFAFA;
          }
          .contain-data-business {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px 10px 7px;
            width: 100%;
            border-bottom: 1px solid #382e34;
            //background-color: #FAFAFA;
            .data-business-option {
              display: flex;
              align-content: center;
              align-items: center;
              width: 100%;
              .icon-v23-company {
                font-size: 40px;
                color: #DCDCDC;
              }
              .icon-v23-company-active {
                color: #382e34;
              }
              .select-business-disabled {
                width: 30%;
                //margin-bottom: 5px;
                color: #DCDCDC;
                font-weight: 400;
                font-size: 14px;
                margin-left: 6px;
                .ant-select-selection {
                  background: #FAFAFA;
                  cursor: default;
                }
                .ant-select-selection--single {
                  height: 30px;
                }
              }
              .select-business {
                width: 30%;
                //margin-bottom: 5px;
                color: #000000;
                font-weight: 400;
                font-size: 14px;
                padding-left: 7px;
                .ant-select-selection--single {
                  height: 30px;
                }
                .ant-select-selection {
                  background-color: #FFC457;
                  border-color: #000000;
                  border-radius: 0px;
                }
                .ant-select-selection:hover {
                  border-color: #EDA41E;
                }
                .ant-select-selection:active {
                  border-color: #EDA41E;
                }
                .ant-select-selection:focus {
                  border-color: #EDA41E;
                }
              }
            }
            .switcher-select-option-business-disabled {
              opacity: 0.3;
              .switcher__sliderChecked {
                cursor: not-allowed !important;
              }
              .switcher__slider {
                cursor: not-allowed !important;
              }
            }
            .switcher-select-option-business {
              padding-left: 0;
              align-items: center;
              background-color: #ffffff;
              display: flex;
              font-size: 14px;
              position: relative;
              border-radius: 50%;
              .switcher__slider:hover {
                border: 1px solid var(--generalButtonHover);
              }
              .switcher__slider:active {
                border: 1px solid var(--generalButtonActive);
              }
              .switcher__slider {
                background-color: #ffffff;
                border-radius: 34px;
                position: relative;
                width: 70px;
                height: 25px;
                cursor: pointer;
                border: 1px solid #6e7882;
                &:after {
                  content: 'No';
                  color: #6E7882;
                  font-size: 16px;
                  font-weight: 400;
                  position: absolute;
                  right: 16px;
                  top: 2px;
                  z-index: 0;
                }
              }
              .switcher__sliderChecked:hover {
                background-color: var(--generalButtonHover);
                border: 1px solid var(--generalButtonHover);
              }
              .switcher__sliderChecked:active {
                background-color: var(--generalButtonActive);
                border: 1px solid var(--generalButtonActive);
              }
              .switcher__sliderChecked {
                background-color: var(--generalButton);
                border-radius: 34px;
                position: relative;
                width: 70px;
                height: 25px;
                cursor: pointer;
                border: 1px solid #6e7882;
                &:before {
                  content: 'YES';
                  color: var( --generalButtonFont);
                  position: absolute;
                  z-index: 0;
                  font-size: 16px;
                  font-weight: 400;
                  left: 14px;
                  top: 2px;
                }
              }
              .switcher__sliderButton {
                background-color: #FFFFFF;
                border-radius: 50px;
                cursor: pointer;
                display: block;
                height: 19px;
                left: 3px;
                position: absolute;
                top: 2px;
                transition: all 0.4s ease;
                width: 19px;
                z-index: 1;
                border: 1px solid #6e7882;
              }
              .switcher__sliderButtonChecked:hover {
                border: 1px solid #EDA41E;
              }
              .switcher__sliderButtonChecked:active {
                border: 1px solid #EDA41E;
              }
              .switcher__sliderButtonChecked {
                background-color: #FFFFFF;
                border-radius: 50px;
                cursor: pointer;
                display: block;
                height: 19px;
                left: 47px;
                position: absolute;
                top: 2px;
                transition: all 0.4s ease;
                width: 19px;
                border: 1px solid #6e7882;
              }
            }
          }

          .contain-manual-upload {
            display: flex;
            //align-items: center;
            width: 100%;
            //padding: 10px;
            //border-bottom: 1px solid #382e34;
            .icon-v22-db-storage {
              font-size: 30px;
              color: #382e34;
            }
            .icon-v23-upload-1 {
              font-size: 30px;
              color: #382e34;
            }

            .manual-upload-describe {
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              align-content: normal;
              justify-content: normal;
              align-items: flex-end;
              width: 100%;
              //padding-left: 30px;
              .header-manual-upload {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                .title-manual-upload {
                  font-size: 14px;
                  font-weight: 600;
                  letter-spacing: 0.2px;
                  vertical-align: middle;
                  horiz-align: left;
                  color: #382e34;
                }

                .icon-v23-add {
                  color: #eda41e;
                  font-size: 16px;
                }
              }

              .manual-upload-content {
                display: flex;
                //padding: 10px;
                //padding-left: 30px;
                //margin-left: 10px;
                align-items: center;
                //border-bottom: 1px solid #d4d7da;
                justify-content: flex-end;
                width: 98%;
                //dmargin-bottom: 10px;
                .button-collect {
                  background-color: #EDA41E;
                  border: 1px solid #6e7882;
                  border-radius: 20px;
                  box-shadow: none;
                  color: #FFFFFF;
                  cursor: pointer;
                  font-family: Roboto;
                  font-weight: 400;
                  font-size: 16px;
                  padding: 3px !important;
                  text-transform: none;
                  transition: none;
                  line-height: 18.75px;
                  width: 180px;
                  height: 25px;
                  //margin-right: 10px;
                  &:hover {
                    background-color: #FFC457;
                    border: 1px solid #FFC457;
                    color: #FFFFFF;
                  }
                  &:active {
                    background-color: #EDA41E;
                    border: 1px solid #EDA41E;
                    color: #FFFFFF;
                  }
                  &:disabled {
                    background-color: #CCCCCC;
                    border: 1px solid #CCCCCC;
                    cursor: not-allowed;
                    color: #FFFFFF;
                  }
                }
                .icon-v22-upload-1-disabled {
                  opacity: 0.3;
                  cursor: not-allowed;
                }
                .icon-v23-add {
                  color: #eda41e;
                  font-size: 16px;
                }
                .icon-v23-add-active {
                  color: #eda41e;
                  font-size: 16px;
                }
                .ant-btn {
                  //background-color: #eda41e;
                  //border-color: transparent;
                  padding: 0px;
                }
                .icon-v23-upload-1 {
                  font-size: 14px;
                  color: #382e34;
                }

                .describe-input {
                  width: 95%;
                  //border: 1px solid #382e34;
                  //border: none;
                  border: 1px solid #382e34;
                  color: #6e7882;
                  background-color: #FFFFFF;
                  padding: 0 10px;
                  font-size: 14px;
                  font-weight: 400;
                  height: 30px;
                  border-radius: 0px;
                  //margin-left: 10px;
                  margin-right: 10px;

                  &:hover {
                    //border: 1px solid #EDA41E;
                    //border: none;
                    box-shadow: none;
                  }
                  &:focus {
                    //border: 1px solid #EDA41E;
                    //border: none;
                    box-shadow: none;
                  }
                  &:active {
                    //border: 1px solid #EDA41E;
                    //border: none;
                    box-shadow: none;
                  }
                }
              }
            }
          }

          .contain-manual-connectors-upload {
            border-bottom: 1px solid #382e34;
            align-items: center;
            padding: 20px 20px 20px 11px !important;
          }
          .contain-manual-connectors {
            display: flex;
            width: 100%;
            padding: 20px 20px 20px 11px;
            .icon-v23-upload-1 {
              font-size: 30px;
              color: #382e34;
            }
            .icon-v23-db-storage {
              font-size: 30px;
              color: #382e34;
            }
            .icon-v23-connector {
              font-size: 30px;
              color: #382e34;
            }
            .manual-connectors-manual-upload {
              flex-direction: row !important;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              .header-connectors {
                width: 40%;
              }
            }
             .manual-connectors {
               display: flex;
               flex-direction: column;
               width: 100%;
               margin-left: 8px;
               .header-connectors {
                 display: flex;
                 justify-content: space-between;
                 align-items: center;
                 line-height: 30px;
                 margin-left: 7px;
                 //padding: 10px;
                 //border-bottom: 1px solid #382e34;
                 .title-connectors {
                   font-size: 14px;
                   font-weight: 600;
                   letter-spacing: 0.2px;
                   vertical-align: middle;
                   horiz-align: left;
                   color: #382e34;
                 }
                 .icon-v23-add {
                   color: #eda41e;
                   font-size: 16px;
                 }
                 .icon-v23-add-active {
                   color: #eda41e;
                   font-size: 16px;
                 }
               }
               .connectors-content {
                 display: flex;
                 padding: 20px 0px 20px 8px;
                 align-items: center;
                 flex-direction: row;
                 .select-connectors-contain {
                   display: flex;
                   width: 90%;
                   align-items: center;
                   margin-bottom: 0px;
                   //border-bottom: 1px solid #d4d7da;
                   .icon-v23-add {
                     color: #eda41e;
                     font-size: 16px;
                   }
                   .icon-v23-add-active {
                     color: #eda41e;
                     font-size: 16px;
                   }
                   .select-connectors {
                     width: 100%;
                     color: #6e7882;
                     font-weight: 400;
                     font-size: 14px;
                     //margin-right: 10px;
                     .ant-select-selection--single {
                       height: 30px;
                     }
                     .ant-select-arrow {
                       //display: none;
                     }
                     .ant-select-selection {
                       //border: 1px solid #382e34;
                       //border: none;
                       border: 1px solid #382e34;
                       border-radius: 0%;
                     }
                   }
                   .icon-v23-minus {
                     margin-left: 20px;
                   }
                 }

                 .connectors-content-details {
                   display: flex;
                   width: 100%;
                   //margin-top: 10px;
                   flex-direction: column;
                   margin-left: 40px;
                    .content-details-options {
                      display: flex;
                      align-items: center;
                      width: 100%;
                      //margin-bottom: 10px;
                      .ant-input {
                        height: 30px;
                      }
                      .date-picker {
                        border: 1px solid #000000;
                        border-radius: 0px;
                        width: 80%;
                        padding: 0 3px;
                        cursor: pointer;
                        //margin-left: 10px;
                        margin-right: 40px;
                        &:focus-within {
                          border: 1px solid #eda41e;
                          border-radius: 0px;
                        }
                        .ant-calendar-picker-input {
                          border-bottom: none;
                          &::placeholder {
                            color: #cccccc;
                          }
                        }
                        .ant-calendar-picker-clear {
                          margin-right: 6px;
                        }
                        .icon-calendar-b {
                          font-size: 10px;
                          top: 18px;
                        }
                      }
                      .date-picker:hover {
                        border:1px solid #eda41e;
                      }
                      .ant-calendar-picker .ant-calendar-picker-input:hover {
                        border-color: #eda41e !important;
                        cursor: pointer;
                      }
                      .ant-calendar-range-picker-input:hover {
                        cursor: pointer;
                      }
                      .ant-calendar-range-picker-input:placeholder-shown {
                        cursor: pointer;
                      }
                      .select-details-share-site {
                        margin-left: 10px;
                        width: 45% !important;
                      }
                      .select-details-share-folder {
                        width: 45% !important;
                      }
                      .select-details-email-picker {
                        width: 100%;
                        margin-right: 0px !important;
                        .ant-select-selection__placeholder {
                          font-size: 14px !important;
                          font-weight: 400;
                        }
                      }
                      .select-details-email {
                        width: 100%;
                        margin-right: 10px;
                        .ant-select-selection{
                          border: 1px solid #382e34;
                          border-radius: 0px;
                        }
                        .ant-select-selection:hover {
                          border-color: #eda41e;
                        }
                        .ant-select-selection:active {
                          border-color: #eda41e;
                        }
                        .ant-select-selection:focus {
                          border-color: #eda41e;
                        }
                        .ant-select-selection__rendered {
                          color: #382e34;
                        }
                      }
                      .input-folder-name {
                        border: 1px solid #382e34;
                        color: #6e7882;
                        background-color: rgba(255, 255, 255, 0.2509803922);
                        padding: 0 10px;
                        font-size: 14px;
                        font-weight: 400;
                        height: 30px;
                        border-radius: 0px;
                        cursor: default;
                        width: 100%;
                        //margin-right: 10px;
                        //margin-left: 10px;
                        &:hover {
                          border: 1px solid #EDA41E;
                          box-shadow: none;
                          cursor: pointer;
                        }
                        &:focus {
                          border: 1px solid #EDA41E;
                          box-shadow: none;
                        }
                        &:active {
                          border: 1px solid #EDA41E;
                          box-shadow: none;
                        }
                      }
                      .icon-v23-add {
                        color: #eda41e;
                        font-size: 16px;
                      }
                      .icon-v23-minus{
                        font-size: 16px;
                      }
                      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                        margin-left: 0px;
                      }
                      .ant-checkbox-wrapper {
                        line-height: 2;
                        span:not(:first-child) {
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 30px;
                        }
                        .ant-checkbox-inner {
                          border-radius: 0!important;
                          border:1px solid #6e7882;
                          &:after {
                            border-color:#eda41e;
                            background-color: transparent;
                          }
                          &:hover {
                            border-color:#eda41e;
                          }
                        }
                      }
                      .ant-checkbox-checked {
                        .ant-checkbox-inner {
                          background-color: #EDA41E !important;
                          &:after {
                            position: absolute !important;
                            display: table;
                            color:#FFFFFF;
                            border:none !important;
                            transform: rotate(0deg) scale(1) translate(-50%, -50%);
                            opacity: 1;
                            transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                            content: "\e8ce" !important;
                            top: 8px !important;
                            left: 7px !important;
                            font-size: 12px !important;
                            font-family: "smartbox_icons_v19" !important;
                          }
                        }
                      }
                    }
                 }
               }
             }
          }
           .content-action-collect-no {
             padding:0px 0px !important;
           }
          .content-action-collect {
            display: flex;
            width: 100%;
            padding:30px 10px;
            justify-content: flex-end;
            .button-collect {
              background-color: #EDA41E;
              border: 1px solid #6e7882;
              border-radius: 20px;
              box-shadow: none;
              color: #FFFFFF;
              cursor: pointer;
              font-family: Roboto;
              font-weight: 400;
              font-size: 16px;
              padding: 3px !important;
              text-transform: none;
              transition: none;
              line-height: 18.75px;
              width: 180px;
              height: 25px;
              margin-right: 10px;
              &:hover {
                background-color: #FFC457;
                border: 1px solid #FFC457;
                color: #FFFFFF;
              }
              &:active {
                background-color: #EDA41E;
                border: 1px solid #EDA41E;
                color: #FFFFFF;
              }
              &:disabled {
                background-color: #CCCCCC;
                border: 1px solid #CCCCCC;
                cursor: not-allowed;
                color: #FFFFFF;
              }
            }
          }

        }
      }

      .import-data-collector {
        display: flex;
        margin-top: 30px;
        padding-bottom: 5px;
        flex-direction: column;
        .header-import-data-collector {
          margin-bottom: 10px;
          border-bottom: 1px solid #382e34;
          padding-bottom: 5px;
          .title-import {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.2px;
            vertical-align: middle;
            color: #382e34;
          }
        }
        .content-import-data-collector {
          .ant-collapse {
            background-color: #FFFFFF;
            border: none;
          }
          .ant-collapse-item-active {
            //border-bottom: 1px solid #6e7882 !important;
          }
          .header-import-collapse {
            border-bottom: 1px solid #d4d7da !important;
            width: 100% !important;
            .title-date-source-name {
              color: #000000 !important;
              line-height: 30px !important;
              font-family: Roboto !important;
              font-size: 14px !important;
              font-weight: 700 !important;
              letter-spacing: 0.2px !important;
              font-style: normal !important;
            }
            .header-import-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              border-bottom: 1px solid #d4d7da !important;
            }
          }
          .subheader-import-collapse {
            .ant-collapse-header {
              color: #000000 !important;
              line-height: 30px !important;
              font-family: Roboto !important;
              font-size: 14px !important;
              font-weight: 700 !important;
              letter-spacing: 0.2px !important;
              font-style: normal !important;
            }
          }
          .ant-collapse > .ant-collapse-item > .ant-collapse-header {
            position: relative;
            padding: 0px 0px;
            height: 30px;
            align-items: center;
            display: flex;
            padding-left: 40px;
            color: #000000;
            line-height: 30px;
            cursor: pointer;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
            font-style: normal;
          }
          .ant-collapse-content > .ant-collapse-content-box {
            padding: 2px;
            margin-left: 40px;
          }
          .ant-collapse > .ant-collapse-item {
            border-bottom: none;
          }
          .source-items-queries {
            color: #000000;
            line-height: 30px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
            font-style: normal;
            display: flex;
            flex-direction: row;
          }
          .source-items {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 30px;
            justify-content: space-between;
            align-items: center;
            .data-source-collapse {
              display: flex;
              .data-source-item-name {
                color: #000000;
                line-height: 30px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.2px;
                font-style: normal;
              }
              .data-source-item-type {
                color: #d4d7da;
                line-height: 30px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.2px;
                font-style: normal;
              }
            }
            .file-upload-number {
              color: #000000;
              line-height: 30px;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.2px;
              font-style: normal;
            }
          }
          .paragraf-text {
            color: #000000;
            line-height: 30px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
            font-style: normal;
          }
          .ant-collapse-content {
            border-top: none;
          }
        }
      }
    }
  }
}

.data-upload-from-modal {
  width: 790px !important;
  height: 600px !important;
  .header-connector-modal {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--subNavigationBackground);
  }
  .ant-modal-content {
    .ant-modal-header {
      height: 40px;
      padding: 0px;
      background-color: #665360;
      border-radius: 0;
      border: none;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 30px;
        width: 100%;
      }
    }
    .ant-modal-body {
      padding: 30px;
      .content-data-upload-from {
        .data-upload-from-text-field {
          .input-token-name {
            margin:10px 0 20px 0;
            &:hover {
              border-color:#eda41e;
            }
            &:focus {
              border-color:#eda41e;
              box-shadow: none;
            }
          }
        }
        .data-upload-from-modal-buttons {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          .ant-upload-list {
            display: none;
          }
          .ant-btn {
            width: 80px;
          }
        }
      }
    }
  }
}