.spell-checker-page {
  .spell-checker-container {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: Roboto;
    .header-action-ai-results {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 20px;
      margin-bottom: 10px;
      margin-top: 20px;
      //margin-bottom: 20px;
      padding-right: 10px;
      //padding-left: 10px;

      .content-option-ai {
        display: flex;
        align-items: center;

        .icon-v17-redaction-hover {
          color: #6e7882;
          font-size: 18px;
        }
      }

      .custom-option-review {
        width: 100px;
        border: none;
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #6e7882;

        .ant-select-selection {
          border: none;
        }
        .ant-select-selection .ant-select-selection__rendered {
          margin-top: 1px;
        }
        .ant-select-selection__placeholder {
          font-weight: 400;
          font-size: 14px;
          color: #6e7882;
        }

        .ant-select-arrow {
          color: #6e7882;
          right: 8px;
        }
      }
      .wr-review-redact {
        padding: 0px;
        margin-bottom: 0px;
        .button-control {
          width: 100%;
          padding-bottom: 0px;
          .anticon {
            padding-top: 2px;
          }
          &__left {
            color: #6e7882;
            border: 1px solid #6e7882 !important;
          }
          &__right {
            color: #6e7882;
            border: 1px solid #6e7882 !important;
          }
          button {
            height: 20px;
          }
        }
      }
      .redacted-selected {
        .button-btn-redact {
          padding: 5px 13px;
        }
      }
    }
    &__title-spell {
      font-size: 12px;
      font-weight: 300;
      color: #6e7782;
      padding-top: 20px;
      border-bottom: solid 1px #6e7782;
      height: 40px;
    }

    &__radio-contain {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;

      .ant-select-dropdown {
        width: 32px !important;
        left: 109.9915px !important;
        top: 301.991px !important;
      }

      .ant-select-dropdown-hidden {
        width: 32px !important;
        left: 109.9915px !important;
        top: 301.991px !important;
      }
      .ant-select-selection-selected-value {
        float: right;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 37px;
        display: flex !important;
        padding-left: 12px !important;
        align-items: center;
        height: 25px;
      }
      .ant-select {
        .ant-select-selection {
          .ant-select-selection__rendered {

          margin-left: 0px !important;
            margin-right: 0px !important;
          //display: flex;
          align-content: center;
          text-align: center;
        }
      }
    }


      .ant-radio-checked .ant-radio-inner {
        border: solid 3px #3DD8DB !important;
      }

      .ant-radio {
        padding-right: 7px;

        .ant-radio-inner {
          border: solid 1px #6e7882;
          width: 14px;
          height: 14px;

          &:hover {
            border-color: #3DD8DB !important;
          }
        }

        .ant-radio-inner::after {
          content: "\f00c" !important;
          color: #3DD8DB !important;
          font-size: 7px !important;
          font-family: 'FontAwesome' !important;
          top: 0.9px;
          left: 1px;
          background-color: #FFFFFF;
        }
      }

      .radion-style {
        display: block;
        height: 30px;
        lineHeight: 30px;
      }

      .ant-radio-group {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .ant-radio-wrapper {
          font-weight: 300;
          font-size: 12px;
          color: #6e7882;
          align-items: center;
          display: flex;
        }
      }

      .items-options {
        width: 32px !important;
        left: 109.9915px !important;
        top: 301.991px !important;
        .ant-select-dropdown {
          width: 32px !important;
          left: 109.9915px !important;
          top: 301.991px !important;
        }
      }
      .ant-select {
        .ant-select-dropdown {
          width: 32px !important;
          left: 109.9915px !important;
          top: 301.991px !important;
        }
      }
      .select-page {
        width: 46px;
        .items-options {
          width: 32px !important;
          left: 109.9915px !important;
          top: 301.991px !important;
          .ant-select-dropdown {
            width: 32px !important;
            left: 109.9915px !important;
            top: 301.991px !important;
          }
        }
        .ant-select-selection--single {
          position: relative;
          height: 24px;
          cursor: pointer;
        }
        .ant-select-arrow svg {
          display: none;
        }
      }
    }

    .image-tags-scroll {
      height: auto;

      ul {
        position: relative;
      }
    }

    &__button-search-spell {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button:disabled {
        background-color: #FFFFFF;
        border: 1px solid #CCCCCC !important;
        cursor: not-allowed;
        color: #CCCCCC;

        &:hover {
          background-color: #FFFFFF;
          border: 1px solid #CCCCCC !important;
          cursor: not-allowed;
          color: #CCCCCC !important;
        }
      }

      .search-button {
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        padding: initial;
        width: 99px;
        border-radius: 25px;
        height: 25px;
      }

      .button-green {
        background-color: #382e34;
        border: 1px solid #382e34;
        border-radius: 20px;
        color: #FFFFFF;
        cursor: pointer;
        text-transform: capitalize;
        transition: all .2s;

        &:hover {
          background-color: #FFFFFF;
          color: #382e34 !important;
          border-color: #382e34 !important;
        }

        &:focus {
          background-color: #FFFFFF;
          color: #382e34;
          border-color: #382e34;
        }
      }
    }

    .ai-tags-scroll {
      //overflow-y: auto;
      width: 260px;

      ul {
        position: relative;
      }
    }

    &__content-spell-checker {
      //padding-top: 20px;
      overflow-y: initial;

      .ant-tree li span.ant-tree-checkbox {
        padding: 0px 0;
        height: 14px;
        margin: 0;
      }

      .ant-tree .disabled-expand-icon > .ant-tree-checkbox {
        padding: 6px 0;
        height: 15px;
      }

      .disabled-expand-icon > .ant-tree-checkbox {
        padding: 6px 0;
        height: 15px;
        margin: 0;
      }

      .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
      }

      .ant-tree-child-tree > li:first-child {
        width: 100%;
        position: relative;
        padding-top: 10px;
      }

      .ant-tree li .ant-tree-node-content-wrapper {
        width: 91%;
      }

      .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
      }

      .disabled-expand-icon > .ant-tree-switcher {
        display: none;
      }


      .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: #3ED8DB;
        border-color: #3ED8DB;
      }

      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: #3ED8DB;
      }

      .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: #3DD8DB !important;
      }

      .ant-tree-checkbox-checked::after {
        border-color: transparent;
      }

      .ant-tree-checkbox-indeterminate {
        .ant-tree-checkbox-inner::after {
          left: 50%;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border-width: 1.7px;
        }
      }

      .ant-tree {
        .top-parent {
          ///position: absolute;
          width: 100%;
          left: 0px;
          border-top: 1px solid #6e7882;
          border-bottom: 1px solid #6e7882;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .anticon-message {
            margin-right: 15px;
          }

          .title-parent-tree {
            ///opacity: 0.5;
          }

          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            padding-left: 14px;
          }

          .left-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 15%;
            z-index: 3;
            font-size: 18px;

            span {
              padding-left: 0;
              cursor: pointer;
              font-size: 14px;
              display: flex;
              align-items: center;
              height: 24px;

              .icon-add-b {
                font-size: 18px;
                margin-right: 2px;
              }

              .icon-add-b:before {
                height: 17px;
              }
            }
          }
        }

        .asset-panel {
          color: #6e7882;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 10px;
          width: 260px;
          &__left-container-spell {
             background-color: #00A6FF;
            .anticon {
              color: #382e34;
            }
            .category-name-spell {
              font-size: 14px;
              color: #382e34;
              font-weight: 700;
              height: 30px;
              letter-spacing: 0.2px;
              display: flex;
              align-items: center;
            }
          }

          &__left-container {
            display: flex;
            align-items: center;
            width: 90%;
            //border-bottom: 1px solid #6e7882;
            margin-top: 20px;

            button {
              padding-left: 45px;
            }

            &__category-name {
              font-family: Roboto;
              font-size: 12px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #6e7882;
              text-transform: uppercase;
              margin-right: 5px;
            }

            &__subcategory-name {
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-stretch: normal;
              font-weight: bold;
              letter-spacing: normal;
              color: #6e7882;
              text-transform: capitalize;
              margin-right: 5px;
            }

            &__child-name {
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #6e7882;
              margin-right: 5px;
              margin-left: 15px;
              width: 100%;
              display: flex;
              justify-content: space-between;

              &--line-through {
                text-decoration: line-through;
              }
            }
          }

          &__children-image {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 14px;
            height: 20px;
            .children-spell {
              font-weight: 400;
              color: #382e34;
              letter-spacing: 0.2px;
            }
            &__children-image-name {
              font-family: Roboto;
              font-weight: 300;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #6e7882;
              text-transform: lowercase;
              margin-right: 5px;
              padding-top: 1px;

              .child-click-bold {
                font-weight: bold;
              }

              .child-size {
                width: 72%;
                text-transform: initial;
              }

            }
          }
        }

        li:last-child {
          padding-bottom: 0;
        }

        li {
          padding: 5px 0;

          .ant-tree-node-content-wrapper {
            height: 20px;
            line-height: 20px;
          }

          ul {
            padding: 0;
          }

          span.ant-tree-switcher {
            display: none;
          }

          .ant-tree-checkbox-inner {
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }

          .ant-tree-checkbox-inner::after {
            left: 22%;
            width: 4.714286px;
            height: 7.142857px;
            border-width: 1.7px;
          }

          .ant-tree-checkbox-indeterminate {
            .ant-tree-checkbox-inner::after {
              left: 50%;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border-width: 1.7px;
            }
          }

        }

        .category-tree-node > .ant-tree-checkbox {
          padding: 0px 0;
          height: 15px;
          margin-top: 30px;
        }

        .disabled-expand-icon > .ant-tree-checkbox {
          padding: 8px 0;
          border-top: 1px solid #6e7882;
          height: 32px;
          margin: 0;
          border-bottom: 1px solid #6e7882;
        }

        .disabled-expand-icon > .ant-tree-node-content-wrapper {
          height: 32px;
          padding: 0 0px;
        }
      }
    }

  }
  .button-btn-redact {
    padding: 5px 13px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1360px) {
.spell-checker-page {
  .spell-checker-container {
    &__radio-contain {
      .ant-radio-group {
        width: 100%;
        display: inline-block;
        justify-content: space-between;
      }
    }

  }
}
}