.accessControl__group {
  align-items: center;
  display: flex;
  margin: 10px 0;
}

.accessControl__group span {
  color: #555555;
  font-size: 14px;
  margin-left: 10px;
}

.accessControl__checkbox {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  display: inline-block;
  height: 26px;
  position: relative;
  width: 26px
}

.accessControl__label {
  border-radius: 5px;
  cursor: pointer;
  display: block;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: all .5s ease;
  width: 18px;
  z-index: 1;
}

.accessControl__label--checked {
  border-radius: 5px;
  cursor: pointer;
  display: block;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: all .5s ease;
  width: 18px;
  z-index: 1;
  background-color: #3DD8DB
}