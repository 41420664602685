.smart-box-folder-opened {
  &_container {
    position: relative;
    width: 100%;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    overflow-y: visible;
    .spin-new-contain-folder-opened-empty {
      width: 15%!important;
      height: 40px!important;
      .custom-spin-folder-opened-empty {
        top:10vh !important;
        left: 38vw !important;
      }
    }
    &__row {
      width: 100%;
    }
    &__tree {
      width: 17%;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
      height: 100vh;
      position: fixed;
      top: 0;
      padding-top: 60px;
    }
    .folder-opened-padding-false {
      padding-top: 58px !important;
      margin-left: 300px;
    } 
    .folder-opened-padding-true {
      padding: 58px 0px 0px 58px !important;
      width: 100%;
      float: left;
    }
    .new-contain-folder-opened-empty.true{
      margin-left: 50px;
    }
    .new-contain-folder-opened-empty {
      margin-top: 130px !important;
      margin-left: 300px;
      
      .new-content-file {
        margin-bottom: 40px !important;
        padding-top: 20px !important;
        margin-top: 0px !important;
        height: fit-content !important;
      }
    }
   .new-contain-folder-opened {
     padding: 0px;
     min-height: 500px;
     .smart-box-folder-opened_container__content__files {
       margin-bottom: 100px;
       margin-top: 90px;

       //padding-right: 20px;
       padding-left: 20px;
       .content-file-action {
         height: auto !important;
       }
       .new-content-file-folder {
         .react-contextmenu-wrapper {
           //width: 160px;
           //height: 160px;
         }
       }
       .content-file {
         margin-right: 24px;
         margin-bottom: 24px;
         width: 180px;
         height: 180px;
       }
     }
   }
   &__content-true {
    margin: 0px 40px;
   }
   &__content-false {
    margin-left: 50px;  
   }
   
    &__content {
      display: flex;
      flex-direction: column;
          

      .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
        //top: 90% !important;
      }
      .context-menu-directory {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6e7882;
        border: 2px solid rgba(110, 120, 130, 0.29);
        z-index: 1000;
        .menu-item-directory-disabled-rename:hover {
          background-color: #FFFFFF;
          color: #a6acb2;
        }
        .menu-item-directory-disabled-rename {
          pointer-events: none;
          cursor: not-allowed;
          color: #a6acb2;
        }
        .menu-item-directory {
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          border: none !important;
        }
        .menu-item-disabled {
          cursor: not-allowed;
          color: #a6acb2 !important;
        }
        .menu-item-directory3 {
          cursor: not-allowed;
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          color: #cccccc;
          background: #ffffff;
          border: none !important;
        }
        .menu-item-directory8 {
          border-bottom: solid 1px rgba(110, 120, 130, 0.29) !important;
          height: 0px !important;
          border: none;
          width: 90% !important;
          margin-left: 9px;
        }
        .menu-item-directory2 {
          cursor: not-allowed;
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          color: #cccccc;
          border: none !important;
        }
        .menu-item-directory2:hover {
          color: #cccccc;
          background: #ffffff;
        }
        menu-item-category3 {
          border-bottom: solid 1px rgba(110, 120, 130, 0.29);
        }
        .menu-item-directory6 {
          cursor: not-allowed;
        }
        .menu-item-directory2 {
          cursor: not-allowed;
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          color: #cccccc;
          &:hover {
            color: #cccccc;
            background: #ffffff;
          }
        }
        .context-sub-menu {
          .react-contextmenu-item {
            width: 200px;
            height: 30px;
            border: solid 1px rgba(110, 120, 130, 0.29);
            display: flex;
            align-items: center;
            justify-content: flex-start !important;
            cursor: pointer;
            padding-left: 5px;
            border: none;
          }
          .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
            content: ">";
            display: inline-block;
            position: absolute;
            right: 7px;
          }
          .react-contextmenu {
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.15);
            border-radius: .25rem;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #6e7882;
            margin: 0px 0 0;
            min-width: 160px;
            outline: none;
            opacity: 0;
            pointer-events: none;
            text-align: left;
            transition: opacity 250ms ease !important;
          }

          .react-contextmenu.react-contextmenu--visible {
            opacity: 1;
            pointer-events: auto;
            z-index: 9999;
          }
        }
        .menu-item-directory4 {
          cursor: not-allowed;
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          color: #cccccc;
          border: none !important;
        }
        .menu-item-directory4:hover {
          color: #cccccc;
          background: #ffffff;
        }
        menu-item-category3 {
          border-bottom: solid 1px rgba(110, 120, 130, 0.29) !important;
        }
        .menu-item-category {
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          height: 0px;
          font-size: 14px;
          font-weight: 500;
          color: #FFFFFF;
          //background-color: #6e7882;
          border: none !important;
          border-bottom: solid 1px rgba(110, 120, 130, 0.29) !important;
          width: 90%;
          margin-left: 9px;
          &:hover {
            color: #FFFFFF;
            background-color: #6e7882;
            cursor: auto;
          }
        }
      }
        .ant-spin-nested-loading {
          //height: 100%;
          //position: static !important;

          .ant-spin-spinning {
            margin-top: 100px;
          }
        }
      .ant-spin-nested-loading > div > .ant-spin {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        display: block;
        width: 100%;
        height: 100% !important;
        max-height: 500px;
        margin-bottom: 50px !important;
      }
      .ant-spin-dot {
        display: flex !important;
        margin: 0 !important;
      }
      &__files {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        overflow: hidden;
        overflow-y: visible;
        margin-top: 148px;
      }
      &__file-image-delete {
        display: initial;
        position: absolute;
        top: 32%;
        left: 32%;
      }

      &__file-image-delete.directory {
        display: initial;
        position: absolute;
        top: 36%;
        left: 36%;
      }

      .ant-upload.ant-upload-select-picture-card {
        background-color: #EFEFF0;
        width: 100%;
        height: 280px !important;
      }

      .file-expand-status {
        display: inline;
        position: relative;
        top: 44px;
      }
      .parent-files {
        tbody > tr > td .action-drop-cell .dropdown-menu {
          //left: -59px !important;
        }
        tbody > tr > td .action-drop-cell .dots-menu {
          .dropdown-menu.show {
            transform: translate(0px,30px) !important;
            inset:0px auto auto -153px !important;
          }
        }
        .ant-table {
          overflow: visible;
          color: #6e7882;
          line-height: 20px;

          .ant-table-header {
            margin-bottom: 0px !important;
            overflow: hidden !important;
          }
        }
        /*.icons-container {
          &__content {
            .folder-info {
              color: #000000;
              &__name {
                font-weight: bold;
              }
              &__row-name {
                color: #000000;
                font-weight: 500;
              }
            }
          }
        }*/
      }
      .files-table {
        width: 100%;
        cursor: pointer;
        padding: 0px 0px 100px 0px ;
        margin-top: 0px;

        .ant-table-body {
          //overflow-y: auto !important;
        }
        .ant-table-tbody > tr > td {
          padding: 0px;
        }
        .parent-status {
          color: #000000;
          font-weight: 500;
          .status-redacted-list-view {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            .color-status-file {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              display: flex;
            }
            .icon-v12-process-error {
              color: #ff0000;
            }
          }
        }
        .parent-modified {
          color: #6e7882;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 300;
        }
        .parent-column {
          .ant-table-column-title {
            display: flex !important;
            align-items: center;
          }
          .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
            display: flex;
            align-items: center;
          }
          .ant-table-column-sorters {
            display: flex;
            align-items: center;
          }
        }
        .ant-table-row-level-0 {
          .parent-modified {
            color: #6e7882;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
          }
        }
        /*.parent-column {
          font-weight: 500;
          .ant-table-column-title {
            padding-left: 30px;
          }
        }*/
        .ant-table-body {
          background: white;
        }
        .ant-table-column-title {
          height: 20px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #637882;
        }

        .ant-table-column-sorter-inner {
          color: #6e7882 !important;
        }
        .ant-table-thead > tr > th[colspan] {
          text-align: left;

        }

        .ant-table-thead > tr > th {
          background-color: #FFFFFF;
          padding: 0 !important;
        }

        .ant-table-row-cell-break-word {
          position: relative;
          .ant-table-row-indent {
            display: none;
          }

          .expand-collapse {
            position: absolute;
            top: 35px;
            overflow: visible;
          }
        }

        tbody > tr > td {
          vertical-align: middle;
          background-color: #FFFFFF;

          &:nth-child(2) {
            height: 19px;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
          }
          .action-drop-cell {
            .dropdown-menu {
              top: 0px !important;
              //left: -10px !important;
              width: 193px;
              border: 1px solid #382e34 !important;
              box-shadow: none;
              color: #382e34 !important;
              border-radius: 0 !important;
              box-shadow: 5px 5px 10px -5px rgba(21,0,16,.3);
            }
          }
          .actionsDotsCell {
            button {
              color: black !important;
              padding: 0 !important;
              background-color: transparent !important;
              border: none !important;
              font-size: 30px;
              line-height: 22px;
              outline: none;
              margin-top: 0px;

              &:focus {
                box-shadow: none;
              }
            }


            .dropdown {
              font-family: 'Roboto';
              font-weight: 400;

              .ellipsis-v {
                -webkit-writing-mode: horizontal-tb;
                writing-mode: horizontal-tb;
                font-size: 26px;
                font-weight: 700;
                float: right;
                cursor: pointer;
                padding-top: 13px;
                color: #000000;

                &:hover {
                  color: #00d3d8;
                }
              }

              &.open .ellipsis-v {
                color: #00d3d8;
              }

              .dropdown-menu {
                left: -153px !important;
                padding: 0;
                top: 30px;
                border: 1px solid #00d3d8;

                & > li {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      .image-container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        [class^="icon-v3-"] {
          font-size: 60px;
          line-height: normal;
        }

        [class^="icon-v2-smart-folder"] {
          font-size: 60px;
          line-height: normal;
        }

      }
   /*.icons-selected-click {
    background-color: #e5f7ff;
     height: 40px;
   }*/
      .icons-selected-click-modified {
        background-color: #e5f7ff;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
      }
      .icons-selected-click-action {
        background-color: #e5f7ff;
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: flex-end;
      }
      .icons-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 0px;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 0;
        position: relative;
        width: 100%;
        .folder-file {
          position: relative;
          padding-top: 2px;
          padding-left: 2px;
        }

        .react-contextmenu-wrapper {
          width: 100%;
        }

        &__icons {
          position: absolute;
          display: flex;
          padding: 2px;
          font-size: 10px;
          z-index: 2;
          .anticon {
            margin-left: 2px;
          }
          img {
            width: 11px;
            height: 11px;
          }
        }
        .display-status-bulk {
          .image-thumbnail-wrapper {
            width: 23px;
            height: 30px;
            display: flex;
            justify-content: center;
            .image-thumbnail {
              width: auto;
              max-width: 100%;
              height: 100%;
              max-height: 30px;
              border: 1px solid #6e7882;
            }
          }
          .bulk-processing {
            position: absolute;
            left: 20px;
            top: 0px;
            background: white;
            border-radius: 50%;
            font-size: 10px;
          }
          .bulk-processing-data-finalised {
            font-size: 9px;
            line-height: 9px;
          }
        }
        &__content {
          display: flex;
          align-items:center;
          height: 30px;
          .analysed-file {
            position: absolute;
            top: 2px;
            left: 33px;
            background: white;
            border-radius: 50%;
          }
          .folder-info {
            margin-left: 10px;
            font-size: 14px;
            /*display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;*/
            &__info {
              font-size: 14px;
              color: black;
              span {
                padding-right: 7px;
              }
            }
            /*&__name {
              font-weight: 500;
            }*/
          }
        }
        i::before {
          margin-left: 0;
          margin-right: 0;
          display: flex;
        }
      }
      /*@media screen and (max-width: 1800px) {
        width: 80%;
      }
      @media screen and (max-width: 1500px) {
        width: 78%;
      }
      @media screen and (max-width: 1400px) {
        width: 76%;
      }
      @media screen and (max-width: 1350px) {
        width: 74%;
      }
      @media screen and (max-width: 1300px) {
        width: 72%;
      }
      @media screen and (max-width: 1200px) {
        width: 71%;
      }
      @media screen and (max-width: 1100px) {
        width: 70%;
      }
      @media screen and (max-width: 1000px) {
        width: 68%;
      }*/
    }

    .list-view-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      margin-top: 0px;
    }
  }
  &__breadcrumb {
    display: flex;
    position : relative;
    z-index: 1;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .icon-v14-back-1 {
      display:flex;
      justify-content: center;
      flex-direction: column;
      color: #6e7882;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;

    }
    .icon-v14-back-1:hover{
      color: var(--generalIconFontHover);
    }
    .disabled{
      display:flex;
      justify-content: center;
      flex-direction: column;
      color: #6e788200;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .disabled:hover{
      color: #6e788200;
      cursor:default;
    }


    .content-check-grid {
      display: flex;
      align-items: center;
      .content-icon-refresh {
        display: flex;
        padding-right: 20px;
      }
      .content-checkbox {
        display: flex;
        padding-right: 15px;
        width: 102px;

        .ant-checkbox-wrapper {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #637882;
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #00d3d8;
            border-color: #00d3d8;
          }
        }
      }
      .view-mode {
        display: flex;
      }
    }
    .view-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 3px;
      border: solid 1px rgba(110, 120, 130, 0.4);
      background-color: rgba(255, 255, 255, 0.4);

      .icon-v2-list-view {
        color: #6e7882;
        &:before {
          width: auto;
        }
      }
    }
    &--bold {
      font-size: 14px;
      color: black;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 90%;
      height: 20px;

      .smart-box-settings-menu {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #6e7882;
          border: solid 1px #6e7882;
          height: 40px;
        .ant-menu-item:hover {
          color: #ffffff;
          background-color: #00d3d8;
          height: 33px;
        }
        .ant-menu-item {
          margin-bottom: 0px;
          margin-top: 0px;
        }
        .ant-menu-item:not(:last-child) {
          margin-bottom: 0px;
        }
        .ant-menu-submenu-title {
          height: 31px !important;
          display: flex !important;
          align-items: center !important;
          padding-left: 15px !important;
        }
        .ant-menu-inline .ant-menu-item::after {
          border: none !important;
        }
        .ant-menu-submenu-selected {
          color: #00d3d8;
        }
        .ant-menu-submenu-title:hover {
          color: #00d3d8;
          .ant-menu-submenu-arrow::after {
            color: #00d3d8 !important;
            background: linear-gradient(to right, #00d3d8, #00d3d8) !important;
          }
          .ant-menu-submenu-arrow::before {
            color: #00d3d8 !important;
            background: linear-gradient(to right, #00d3d8, #00d3d8) !important;
          }
        }
        .ant-menu-item-selected {
          color: #ffffff;
          background-color: #00d3d8 !important;
        }
        .ant-menu-submenu {
          margin-left: -1px;
        }
        .ant-menu-submenu-arrow::after {
          color: #00d3d8 !important;
        }
        .ant-menu-submenu-open .ant-menu-sub {
          border: solid 1px #6e7882;
          width: 200px;
          height: auto;
          li.ant-menu-item-disabled {
            border-bottom: solid 1px #6e7882 !important;
            width: 199px;
            height: 40px;
            display: flex !important;
            align-items: center !important;
            padding-left: 15px !important;
          }
          li {
            border-bottom: solid 1px #6e7882;
            width: 199px;
            height: 40px;
            display: flex !important;
            align-items: center !important;
            padding-left: 15px !important;
          }
        }
      }
      a {
        font-family:'Roboto';
        font-style:normal;
        font-weight:400;
        font-size:14px;
        line-height:20px;
        display:flex;
        align-items:center;
        letter-spacing:0.2px;
        color:#6E7882;
      }
      a:last-child {
        font-family:'Roboto';
        font-style:normal;
        font-weight:400;
        font-size:14px;
        line-height:20px;
        color:#382E34;
        display:flex;
        align-items:center;
        letter-spacing:0.2px;
      }

      a:hover {
        //color: #3DD8DB;
        color:var(--generalIconFontHover);
      }

      .arrow {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 25px;
        color: #6e7882;
        margin-left: 5px;
        margin-right: 5px;
        height: 100%;
      }
    }

    .run-analytics {
      width: 207px;
      height: 41px;
      object-fit: contain;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3DD8DB;
      border-radius: 30px;
      border: 1px solid #3DD8DB;
      margin-right: 20px;
    }
  }

  &_buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;

    .ant-btn {
      width: 207px;
      height: 50px;
      object-fit: contain;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3DD8DB;
      border-radius: 30px;
      border: 1px solid #3DD8DB;
      margin-right: 20px;
    }

    .analytic-button-content {
      float: right;
      order: 4;

      .ant-btn {
        background-color: #3DD8DB;
        color: #FFFFFF;
      }
    }

    .new-button-content {
      float: right;
      order: 3;
    }

    .settings-button-content {
      float: right;
      order: 2;

      .ant-btn {
        width: 140px;
        height: 50px;
        object-fit: contain;

      }
    }

    .upload-button-content {
      position: relative;
      order: 1;

      .custom-file-input {
        display: none;
      }

      .custom-file-label {
        width: 150px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

.breadcrumb-top {
  margin-top: 95px !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.react-contextmenu {
  background: white;
  .react-contextmenu-item {
    width: 207px;
    height: 40px;
    border: solid 1px rgba(110, 120, 130, 0.29);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: rgba(154,224,252,.3137254902);
      //color: white;
    }
  }
}

.menu-left-item-directory {
  align-items: center;
  justify-content: start;
  padding: 7px 7px !important;
}
.menu-left-item-category {
  padding-top: 0 !important;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  height: 23px;
  font-size: 15px;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #6e7882;
  &:hover {
    color: #FFFFFF;
    background-color: #6e7882;
    cursor: auto;
  }
}

.modal-customer-status {
  margin-top: 25vh !important;
  &__line {
    display: inline-block;
    .label {
      float: left;
    }
    .inputs {
      float: right;
      width: 50%;
    }
  }
  .pick-up-color-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .input-color {
      padding: 5px;
      background: #fff;
      border-radius: 3px;
      display: inline-block;
      cursor: pointer;
      border: 1px solid #ccc;
      margin: 0 auto;

      &__selected {
        width: 36px;
        height: 14px;
        borderRadius: 2px;
      }
    }

    .popup-wrapper {
      position: absolute;
      z-index: 2;

      &__cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
    }
  }
  .error {
    color: #ff0000;
  }
}
.validation-name {
  color: red;
  font-size: 12px;
  display:inline-block;
  margin-left: 20px;
}

.smart-box-folder-opened_container__content:not(.new-contain-folder-opened) {
  height: calc(100vh - 0px);

  .list-view-content {
    height:100%;

    .files-table {
      height:100%;

      .ant-spin-nested-loading {
        height:100%;

        .ant-spin-container {
          height:100%;

          .ant-table {
            height:100%;

            .ant-table-content {
              height:100%;

              .ant-table-scroll {
                height:100%;

                .ant-table-body {
                  height: calc(100% - 20px);
                  max-height: calc(100% - 20px) !important;
                  background: transparent !important;
                  overflow-x: hidden;

                  table {
                    margin-bottom: 60px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}