.completeAssessment-wrapper {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D6D3D3;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
&:last-of-type {
   border-bottom: none;
   border-bottom-left-radius: 5px;
   border-bottom-right-radius: 5px;
 }
a {
  display: block;
  padding: 15px 0;
}
}

.completeAssessment-actionsContainer {
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: flex;
  justify-content: center;
  min-height: 50px;
  width: 131px;
.iconReport, .iconView, .iconDownload {
  cursor: pointer;
}
}

.completeAssessment-infoContainer {
  cursor: pointer;
  display: flex;
  width: 100%;
}

.completeAssessment-textContainer {
  align-items: center;
  cursor: pointer;
  display: inline-block;
  flex: 1;
  margin-left: 15px;
  //margin-bottom: 20px;
  margin-top: 10px;
}

.completeAssessment-status {
  align-items: center;
  display: flex;
}

.completeAssessment-completed {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.completeAssessment-iconContainer {
  padding: 0 20px;
}

.completed-dpia-score {
  font-weight: 300;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

@media screen and (min-width: 768px) {
  .completeAssessment-wrapper {
    align-items: center;
    flex-direction: row;
  }
}