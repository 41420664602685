.piaActiveAssessment__wrapper {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D6D3D3;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
&:last-of-type {
   border-bottom: none;
   border-bottom-left-radius: 5px;
   border-bottom-right-radius: 5px;
 }
a {
  display: block;
  flex: 1;
  padding: 15px 0;
}
}

.piaActiveAssessment__actionsContainer {
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: flex;
  justify-content: center;
  min-height: 50px;
  width: 131px;
.iconPlay {
  cursor: pointer;
}
}

.piaActiveAssessment__infoContainer {
  cursor: pointer;
  display: flex;
  width: 100%;
}

.piaActiveAssessment__textContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  margin-left: 15px;
}

.piaActiveAssessment__status {
  align-items: center;
  display: flex;
}

.piaActiveAssessment__notCompleted {
  color: #DA282C;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.piaActiveAssessment__iconContainer {
  padding: 0 20px;
}

@media screen and (min-width: 768px) {
  .piaActiveAssessment__wrapper {
    align-items: center;
    flex-direction: row;
  }
  .piaActiveAssessment__textContainer {
    margin-bottom: 0;
  }
}