.toggleAccordion__wrapper {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top:32px;
  &:before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   border-top: 15px solid #B0B0B0;
   border-left: 15px solid transparent;
   border-right: 15px solid transparent;
  transition: all .2s;
 }
&:after {
   content: '';
   position: absolute;
   left: 2px;
   top: 0;
   border-top: 13px solid #FFFFFF;
   border-left: 13px solid transparent;
   border-right: 13px solid transparent;
  transition: all .2s;
 }
}

.toggleAccordion__wrapperOpened {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top:32px;
&:before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   border-top: 15px solid #B0B0B0;
   border-left: 15px solid transparent;
   border-right: 15px solid transparent;
  transition: all .2s;
  transform: rotate(180deg);
 }
&:after {
   content: '';
   position: absolute;
   left: 2px;
   top: 2px;
   border-top: 14px solid #fff;
   border-left: 13px solid transparent;
   border-right: 13px solid transparent;
  transition: all .2s;
  transform: rotate(180deg);
 }
}