.piaAlertNeedPia__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 60px;
}

.piaAlertNeedPia__title {
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}

.piaAlertNeedPia__subTitle {
  margin-top: 30px;
  text-align: center;
  color:  #68727c;
  font-weight: 300;
}

.piaAlertNeedPia__details {
  margin-top: 20px;
}

.piaAlertNeedPia__details-repeat {
  background: url(../../../assets/big-check.png) no-repeat 0 center;
  padding-left: 70px;
  height: 50px;
  margin: 35px auto 0 auto;
  width: 600px;
  color:  #68727c;
}

.piaAlertNeedPia__details-repeat h2 {
  margin-bottom: 14px;
}

.piaAlertNeedPia__details-assigned {
  background: url(../../../assets/calendar.png) no-repeat 0 center;
  padding-left: 70px;
  height: 50px;
  margin: 30px auto 0 auto;
  width: 600px;
  color:  #68727c;
}

.piaAlertNeedPia__details-assigned h2 {
  margin-bottom: 14px;
}

.piaAlertNeedPia__buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.piaAlertNeedPia__buttons button:last-of-type {
  margin-left: 20px;
}

@media print {
  .piaAlertNeedPia__wrapper {
    display: none;
  }
}