.questionsHeaderSmall__wrapper {
  display: flex;
  justify-content: space-between;
}

.questionsHeaderSmall__wrapper li {
  margin-right: 8px;
}

.questionsHeaderSmall__link {
  color: #3DD8DB;
  display: block;
  font-size: 14px;
  margin-bottom: 40px;
  text-decoration: underline;
}

.questionsHeaderSmall__link:hover {
  font-style: italic;
}