.content-spinning-standard {
  font-size: 20px;
  width: 100px;
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 50px;
  .ant-spin-spinning {
    margin-left: 170px;
  }
  .ant-spin-text {
    font-size: 18px;
    font-weight: 700;
  }
}

.content-spinning-standard-ai {
  font-size: 20px;
  width: 100px;
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 50px;
  .ant-spin-spinning {
    margin-left: 170px;
    margin-top: 200px;
  }
  .ant-spin-text {
    font-size: 18px;
    font-weight: 700;
  }
}
.update-find-asset-container {
  margin-bottom: 0px;
  .category-tree-node  {
    margin-bottom: 20px;
  }
  .ant-tree-treenode-switcher-close:hover {
    .ant-tree-checkbox-inner {
      background-color: #de0d0d;
      border: 1px solid #d9d9d9;
    }
  }
  .ant-tree-checkbox {
    display: none;
  }
  .ant-tree-treenode-selected:hover {
    .ant-tree-checkbox-inner {
        background-color: #de0d0d;
      //border: 1px solid #d9d9d9;
    }
  }
  .ant-tree .category-tree-node > .ant-tree-checkbox:hover {
    padding: 2px 0;
    height: 15px;
    .ant-tree-checkbox-inner {
      background-color: #de0d0d;
      border: 1px solid #d9d9d9;
    }
  }
  .ant-tree-checkbox:hover {
    .ant-tree-checkbox-inner:hover {
      background-color: #de0d0d;
      border: 1px solid #d9d9d9;
    }
  }
  .asset-panel {
    .icon-v17-redaction {
      font-size: 20px;
      margin-right: 10px;
      margin-left: 5px;
    }
    .icon-v17-redaction:before {
      margin-right: 0px;
      margin-left: 0px;
    }
    &__left-container {
      .icon-v17-redaction-hover {
        font-size: 20px;
        margin-right: 10px;
        margin-left: 5px;
      }
      .icon-v17-redaction-hover:before {
        margin-right: 0px;
        margin-left: 0px;
      }
      .icon-v17-redaction {
        font-size: 20px;
        margin-right: 10px;
        margin-left: 5px;
      }
      .space-empty {
        //width: 40px;
        color: transparent;
      }
    }
  }
}
.find-asset-container-manual {
  margin-bottom: 30px;
  .top-parent span {
    padding-left: 5px !important;
  }
  .ant-tree .top-parent {
    .redaction-empty {
      color: transparent;
    }
    .icon-v17-redaction-hover {
      font-size: 15px;
    }
    .icon-v17-redaction {
      font-size: 15px;
    }
  }
  .ant-tree li span.ant-tree-checkbox {
    height: 17px;
    padding: 5px 0;
    display: none;
  }
  .asset-panel {
    &__left-container {
      &__category-name {
        padding-left: 4px;
      }
      .icon-v17-redaction-hover {
        font-size: 15px;
      }
      .redaction-empty {
        color: transparent;
      }
      .icon-v17-redaction {
        font-size: 15px;
      }
    }
  }
}
.find-asset-container {
  border: none;
  font-family: Roboto;
  font-style: normal;
  //add css code here for new category and write correct position of arrow
  .header-action-ai-results {
    //display: flex;
    //width: 100%;
    //justify-content: space-between;
    //align-items: center;
    height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    .content-option-ai {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .option-ai-contain {
        .icon-v22-visible-active {
          color: #6e7882;
          font-size: 20px;
          margin-left: 10px;
        }
        .icon-v22-unvisible-active {
          color: #6e7882;
          font-size: 20px;
          margin-left: 10px;
        }
      }
      .icon-v17-redaction-hover-block {
        opacity: 0.3;
        cursor: not-allowed;
      }
      .ant-select-selection-selected-value {
        width: 100%;
      }
      .content-options-review {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-v19-arrow-medium-rotate {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
        }
        .icon-v19-arrow-medium {
         color: #382e34;
          font-size: 20px;
        }
      }
      .icon-v17-redaction-hover {
        color: #6e7882;
        font-size: 20px;
        margin-left: 1px;
      }
    }
    .custom-option-review {
      width: 100px;
      border: none;
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      color: #6e7882;
      margin-left: 40px;
      .ant-select-selection {
        //border: none;
        //display: flex;
        //align-items: center;
        padding-left: 5px;
        //height: 20px;
        border: 1px solid transparent;
        border-radius: 0px;
      }
      .ant-select-open .ant-select-selection {
        border-color: #EDA41E;
        color: #EDA41E;
      }
      .ant-select-selection:hover {
        border-color:var(--generalIconFontHover);
        //color: #EDA41E;
      }
      .ant-select-selection .ant-select-selection__rendered {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
      }
      .ant-select-selection__placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #6e7882;
      }
      .ant-select-arrow {
        color: #6e7882;
        right: 0px;
      }
    }
    .wr-review-redact {
      padding: 0px;
      margin-bottom: 0px;
      .button-control {
        width: 100%;
        padding-bottom: 0px;
        .anticon {
          padding-top: 2px;
        }
        &__left {
          color: #6e7882;
          border: 1px solid #6e7882 !important;
        }
        &__right {
          color: #6e7882;
          border: 1px solid #6e7882 !important;
        }
        button {
          height: 20px;
        }
      }
    }
  }
  .update-ai-results-manual {
    width: auto;
    padding-bottom: 20px;
    .find-asset-container {
      height: auto;
    }
    .image-tags-scroll {
      height: auto !important;
    }
    .component-manual-lists {
      margin-bottom: 50px;
      .tab-update-lists {
        background-color: #A186FF;
        width: 92%;
        display: flex;
        height: 30px;
        align-items: center;
        padding-left: 25px;
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
        .title-lists {
          margin-right: 5px;
          font-family: Roboto;
          font-weight: 700;
          font-size: 14px;
          color: #382e34;
        }
        .anticon {
          color: #382e34;
        }
      }
    }
    .component-reg-ex-emails {
      margin-top: 20px;
      margin-bottom: 30px;
      .icon-v21-arrow-bold {
        font-size: 20px;
        color: #382e34;
      }
      .rotate-arrow-bold {
        -webkit-transform: rotate(180deg);
        writing-mode: vertical-rl;
      }
      .tab-update-reg-ex-empty {
        background-color: #FFFFFF;
        //width: 92%;
        display: flex;
        height: 30px;
        align-items: center;
        padding-left: 25px;
        cursor: default;
        margin-left: 10px;
        margin-right: 10px;
        border: 2px solid #6e7882;
        opacity: 0.3;
        margin-bottom: 30px;
        .icon-empty-redaction-disabled {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 10px;
        }
        .space-empty {
          color: transparent;
        }
        .icon-empty-redaction {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 23px;
        }
        .title-regEx {
          margin-right: 5px;
          font-family: Roboto;
          font-weight: 700;
          font-size: 14px;
          color: #382e34;
        }
        .anticon {
          color: #382e34;
        }
      }
      .tab-update-reg-ex-redact {
        padding-left: 0px !important;
      }
      .tab-update-reg-ex-new-redact {
        padding-left: 0px !important;
      }
      .tab-update-reg-ex {
        background-color: #E4C801;
        //width: 92%;
        display: flex;
        height: 30px;
        align-items: center;
        padding-left: 35px;
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
        justify-content: space-between;
        .icon-v17-redaction:before {
          content: '\e8d8';
          font-size: 20px;
          margin-right: 10px;
          margin-left: 5px;
        }
        .content-reg-terms {
          display: flex;
          align-items: center;
        }
        .space-empty {
          color: transparent;
        }
        .icon-empty-redaction {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 23px;
        }
        .content-visible-category {
          display: flex;
          align-items: center;
          margin-right: 5px;
          .icon-v12-visible {
            font-size: 20px;
            color: #382e34;
          }
          .icon-v12-unvisible {
            font-size: 20px;
            color: #382e34;
          }
        }
        .icon-v17-redaction-active:before {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 5px;
        }
        .icon-v17-redaction-hover:before {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 5px;
        }
        .icon-v17-redaction-hover {
          font-size: 15px;
        }
        .icon-v17-redaction {
          font-size: 15px;
        }
        .empty-redaction {
          font-size: 15px;
          color: transparent;
        }
        .title-regEx {
          margin-right: 5px;
          font-family: Roboto;
          font-weight: 700;
          font-size: 14px;
          color: #382e34;
        }
        .anticon {
          color: #382e34;
        }
      }
    }
    .component-box-terms {
      margin-top: 0px;
      .icon-v21-arrow-bold {
        font-size: 20px;
        color: #382e34;
      }
      .rotate-arrow-bold {
        -webkit-transform: rotate(180deg);
        writing-mode: vertical-rl;
      }
      .tab-update-box-terms-empty {
        background-color: #FFFFFF;
        //width: 92%;
        display: flex;
        height: 30px;
        align-items: center;
        padding-left: 25px;
        cursor: default;
        margin-left: 10px;
        margin-right: 10px;
        border: 2px solid #6e7882;
        opacity: 0.3;
        .icon-empty-redaction-disabled {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 10px;
        }
        .space-empty {
          color: transparent;
        }
        .icon-empty-redaction {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 23px;
        }
        .title-box-terms {
          margin-right: 5px;
          font-family: Roboto;
          font-weight: 700;
          font-size: 14px;
          color: #382e34;
        }
        .anticon {
          color: #382e34;
        }
      }
      .tab-update-business-redact {
        padding-left: 0px !important;
      }
     .tab-update-box-terms {
       background-color: #01E493;
       //width: 92%;
       display: flex;
       height: 30px;
       align-items: center;
       padding-left: 35px;
       cursor: pointer;
       margin-left: 10px;
       margin-right: 10px;
       justify-content: space-between;
       .content-dictionary-terms {
         display: flex;
         align-items: center;
         height: 30px;
       }
       .space-empty {
         color: transparent;
       }
       .icon-empty-redaction {
         font-size: 20px;
         margin-right: 10px;
         margin-left: 23px;
       }
       .icon-v17-redaction:before {
         content: '\e8d8';
         font-size: 20px;
         margin-right: 10px;
         margin-left: 5px;
       }
       .icon-v17-redaction-active:before {
         font-size: 20px;
         margin-right: 10px;
         margin-left: 5px;
       }
       .icon-v17-redaction-hover:before {
         font-size: 20px;
         margin-right: 10px;
         margin-left: 5px;
       }
       .content-visible-category {
         display: flex;
         align-items: center;
         .icon-v12-visible {
           font-size: 20px;
           color: #382e34;
           margin-right: 5px;
         }
         .icon-v12-unvisible {
           font-size: 20px;
           color: #382e34;
           margin-right: 5px;
         }
       }
       .title-box-terms {
         margin-right: 5px;
         font-family: Roboto;
         font-weight: 700;
         font-size: 14px;
         color: #382e34;
       }
       .anticon {
         color: #382e34;
       }
     }
    }
  }
  .disabled-standard-tree {
    .ant-tree-checkbox-indeterminate {
      .ant-tree-checkbox-inner::after {
        background-color: transparent;
      }
    }
  }
   .ant-tree-treenode-switcher-close {

   }
  .title {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 11px 17px;
  }
   .check-duplicate-name {
     color: red;
     font-size: 12px;
     display: flex;
     padding-left: 36px;
   }

  .check-found-name {
    color: red;
    font-size: 12px;
    display: flex;
    padding-left: 36px;
    padding-top: 3px;
  }
  .ant-tabs-nav {
    margin-left: 15px;
    .ant-tabs-tab-active {
      color: #3ED8DB;
      font-weight: bold;

      &:hover {
        color: #3ED8DB;
      }
    }

    .ant-tabs-tab {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      width: 60px;
      text-align: center;
      height: 20px;
      padding: 0;
      margin: 0 11px 0 0;
      //padding: 12px 4px 12px 19px;
      &:hover {
        color: #3ED8DB;
      }
    }

    .ant-tabs-ink-bar {
      background-color: #3ED8DB;
      width: 60px !important;
    }
  }

  .ant-tabs-bar {
    margin: 0 0 1px 0;
    padding-bottom: 10px;
    border-bottom: none;
  }

  .ant-tree {
    margin-left: 10px;
    margin-right: 10px;
    .update-content-top-parent {
      display: flex;
      align-items: center;
      .icon-v17-redaction-hover {
        font-size: 15px;
        margin-right: 10px;
      }
      .space-empty {
        color: transparent;
      }
      .top-parent {
        width: 87% !important;
        left: 23px !important;
        border-top: none !important;
        border-bottom: none !important;
        span {
          padding-left: 15px !important;
        }
      }
    }
    .top-parent {
      position: absolute;
      width: 100%;
      left: 0;
      border-top: 1px solid rgba(110, 120, 130, 0.3);
      border-bottom: 1px solid rgba(110, 120, 130, 0.3);
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .anticon-message {
        margin-right: 15px;
      }
      span {
        padding-left: 23px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }

      .left-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 50%;
        z-index: 3;
        font-size: 18px;
        margin-right: 5px;
        span {
          padding-left: 0;
          cursor: pointer;
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 24px;
          .icon-add-b {
            font-size: 18px;
            margin-right: 2px;
          }

          .icon-add-b:before {
            height: 17px;
          }
        }
      }
    }

    li:last-child {
      padding-bottom: 0;
    }

    li {
      padding: 0px 0;
      .ant-tree-node-content-wrapper {
        height: 30px;
        line-height: 30px;
      }
      span.ant-tree-checkbox {
        //padding: 0;
        height: 17px;
        padding: 5px 0;
      }

      ul {
        padding: 0;
      }

      span.ant-tree-switcher {
        display: none;
      }

      .ant-tree-checkbox-inner {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }

      .ant-tree-checkbox-inner::after {
        left: 22%;
        width: 4.714286px;
        height: 7.142857px;
        border-width: 1.7px;
      }

      .ant-tree-checkbox-indeterminate {
        .ant-tree-checkbox-inner::after {
          left: 50%;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border-width: 1.7px;
        }
      }
    }

    .category-tree-node > .ant-tree-checkbox {
      padding: 2px 0;
      height: 15px;
    }

    .disabled-expand-icon > .ant-tree-checkbox {
      padding: 6px 0;
      height: 15px;
    }

    .disabled-expand-icon > .ant-tree-node-content-wrapper {
      height: 20px;
      display: none;
    }
  }

  .new-category {
    display: flex;
    align-items: center;
    margin-top: 8px;
    &__input-wrapper {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #3ED8DB;
      border-radius: 0;
      width: 86%;
      &__input {
        border: none;
        padding: 0;
        height: 23px;
        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }

      &__icon {
        color: #3ED8DB;
        cursor: pointer;
        font-size: 15px;
        z-index: 10;
      }
    }

    &__radio {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid;
      border-color: #d9d9d9 !important;
      margin-left: 17px;
      margin-right: 8px;
      display: block;
    }
  }
  .asset-panel:hover {
    background-color: rgba(154,224,252,0.5);
    //opacity: 0.5;
  }

  .asset-panel-no-found {
    border: 2px solid #6e7882;
    opacity: 0.3;
    &:hover {
      background-color: #FFFFFF;
    }
    &:active {
      background-color: #FFFFFF;
    }
    &__left-container-no-found {
      display: flex;
      &__category-name-no-found {
        font-family: Roboto;
        font-weight: 700;
        font-size: 14px;
        color: #6e7882;
        padding-left: 20px;
        //opacity: 0.3;
      }
    }
  }

  .asset-panel {
    color: #382e34;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    height: 30px;
    margin-bottom: 0px;

    &__icons {
      display: flex;
      align-items: center;
      font-size: 12px;
      .icon-v12-unvisible {
        font-size: 20px;
        color: #382e34;
      }
      .icon-v12-visible {
        font-size: 20px;
        color: #382e34;
      }
      .icon-eye-open-b {
        margin-top: 3px;
        color: #382e34;
      }
      .icon-eye-open-b:hover {
        margin-top: 3px;
        color: #9AE0FC;
        opacity: 0.5;
      }

      .icon-eye-closed-b {
        margin-top: 3px;
      }

      .icon-colourpalette-b {
        font-size: 14px;
        margin-right: 5px;
        height: 21px;
      }
    }
    &__left-container {
      display: flex;
      align-items: center;
      width: 100%;
      .icon-v21-arrow-bold {
        font-size: 20px;
        color: #382e34;
      }
      .rotate-arrow-bold {
        -webkit-transform: rotate(180deg);
        writing-mode: vertical-rl;
      }
      .icon-redacted {
        color: #ffffff;
        background: #000000;
        border: 1px solid #000000;
        padding: 0px 4px 3px 4px;
        height: 19px;
        font-size: 11px;
        font-weight: 500;
        margin-right: 14px;
        position: absolute;
        display: flex;
        right: 19px;
      }
      .icon-expand-category {
        color: #382e34;
      }
      .icon-expand-category-visible {
        color: #382e34;
        opacity: 0.5;
      }
      &__update-category-name {
        font-size: 14px !important;
        color: #382e34 !important;
        letter-spacing: 0.2px !important;
      }
      &__update-category-name-invisible {
        font-size: 14px !important;
        color: #382e34 !important;
        letter-spacing: 0.2px !important;
        opacity: 0.5;
      }

      &__category-name {
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #6e7882;
        //text-transform: uppercase;
        margin-right: 5px;
      }
       .padding-title {
         padding-left: 10px;
         color: #6e7882 !important;
       }
       .update-child-name {
         font-family: Roboto;
         letter-spacing: 0.2px;
         font-size: 14px;
         font-weight: 400;
         color: #382e34;
         display: flex;
         align-items: center;
       }
      .child-non-redacted {
        padding-left: 30px;
      }
      .update-child-name {
         .child-names-length {
           padding-left: 4px;
         }
      }
      .size-subcategory-name {
        width: 100%;
        font-family: Roboto;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: #a6acb2;
        padding-left: 5px;
        height: 20px;
      }

      &__subcategory-name {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        font-weight: bold;
        letter-spacing: normal;
        color: #6e7882;
        text-transform: capitalize;
        margin-right: 5px;
      }

      &__child-name {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #382e34;
        margin-right: 5px;
        margin-left: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .child-names-length {
          color: #382e34;
        }
        .size-child {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          color: #382e34;
        }
        .page-click-bold {
          font-weight: bold;
        }
        .page-number {
          font-weight: 300;
        }
        .child-page-number {
          width: 17px;
          font-family: Roboto;
          letter-spacing: 0.2px;
          font-size: 14px;
          font-weight: 400;
          color: #382e34;
        }
        .child-size {
          width: 100%;
        }
        .on-click-bold {
          font-weight: bold;
        }

        .icon-redacted {
          color: #ffffff;
          background: #000000;
          border: 1px solid #000000;
          padding: 0px 4px 3px 4px;
          height: 19px;
          font-size: 11px;
          font-weight: 500;
          margin-right: 14px;
          position: absolute;
          display: flex;
          right: 19px;
        }
        &--line-through {
        }
      }
    }

    .asset-panel__bar {
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 1px;
    }

    .ant-collapse-extra {

      .fa {
        font-size: 15px;
      }
    }

    &__children {
      &:first-of-type {
        padding-top: 8px;
      }

      .asset-panel__grandchildren {
        &__name {
          text-indent: 10px;
          font-weight: 300;
        }
      }
    }
  }

  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent;
  }

  .ant-tree-child-tree > li:first-child {
    width: 100%;
    position: relative;
    //padding-top: 20px;
  }
  .ant-tree li .ant-tree-node-content-wrapper {
    padding: 0 0px;
    border-radius: 0px;
  }
  .ant-tree li .ant-tree-node-content-wrapper {
    width: 100%;
  }

  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }

  .disabled-expand-icon > .ant-tree-switcher {
    display: none;
  }


  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #3ED8DB;
    border-color: #3ED8DB;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #3ED8DB;
  }

  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: transparent;
  }

  .ant-tree-checkbox-checked::after {
    border-color: transparent;
  }
  .search-and-find-loading-container {
    display: flex;
    font-weight: bold;
    font-family: 'Roboto';
    color: #6e7882;
    line-height: 1.5;
    justify-content: center;
    position: absolute;
    height: 100%;
    align-items: center;
    width: 100%;
    flex-direction: column-reverse;
    z-index: 4;
    background: rgba(255, 255, 255, 0.39);
    font-size: 12px;

    .ant-spin-dot {
      font-size: 80px;
      margin-bottom: 10px;
      i {
        background-color: #3ED8DB;
        width: 30px;
        height: 30px
      }
    }
  }

}

.ai-tags-scroll {
  //overflow-y: auto;
  //height: 72vh;
  overflow-y: hidden;
  ul{
    position: relative;
  }
}

.disabled-check-keys {
  .ant-tree-checkbox-indeterminate {
    .ant-tree-checkbox-inner::after {
      background-color: transparent;
    }
  }
}

.wr-review-redact {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 300;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
  margin-bottom: 30px;

  .button-control {
    width: 100%;
    padding-bottom: 10px;
    button {
      height: 25px;
      width: 40px;
      padding: 0;
      border-radius: 0;
      font-weight: 100;
      &:hover {
        background-color: #EDA41E;
        //border: solid 1px #3ED8DB;
        //color: #fff;
      }
    }

    &__left {
      border-bottom-left-radius: 18.5px !important;
      border-top-left-radius: 18.5px !important;
      background: #fff;
      border: solid 1px rgba(110, 120, 130, 0.3) !important;
      color: #afb9c3;
      border-right:solid  0 !important;
    }

    &__right {
      border-bottom-right-radius: 18.5px !important;
      border-top-right-radius: 18.5px !important;
      //background-color: #3ED8DB;
      //border: solid 1px #3ED8DB;
      //color: #fff;
      &:disabled{
        background: #fff;
        border: solid 1px rgba(110, 120, 130, 0.3) !important;
        color: #afb9c3;
      }
    }

    &__text {
      padding-right: 10px;
    }

    &__redact {

    }
  }
  .button-btn-redact{
    padding: 0 13px;
    border-radius: 32px;
    border: solid 1px #3ED8DB !important;
    color: #fff;
    font-weight: 300;
    font-size: 17px;
    background-color: #3ED8DB;
    cursor: pointer;
    &:disabled{
      background: #fff;
      border: solid 1px rgba(110, 120, 130, 0.3) !important;
      color: #afb9c3;
      cursor: not-allowed;
    }
  }
}
@media (min-width: 1500px) and (max-width: 1650px) {
  .find-asset-container .asset-panel__left-container__child-name {
    .child-size {
      width: 80%;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .find-asset-container .asset-panel__left-container__child-name {
    .child-size {
       max-width: 180px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .find-asset-container .asset-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .asset-panel__bar {
    width: 89%;
    height: 2px;
    position: absolute;
  }
}
@media (min-width: 1870px) {
  .header-action-ai-results {
      margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1401px) and (max-width: 1600px) {
  .find-asset-container .asset-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .asset-panel__bar {
    width: 90%;
    height: 2px;
    position: absolute;
  }
}
@media (min-height: 900px) and (max-height: 1000px) {
  .ai-tags-scroll {
    //height: 70vh;
  }
}
@media (min-height: 800px) and (max-height: 900px) {
  .ai-tags-scroll {
    //height: 69vh;
  }
}

@media (min-height: 700px) and (max-height: 800px) {
  .ai-tags-scroll {
    //height: 68vh;
  }
}

.custom-color-picker-popover {
  padding: 0;

  .ant-popover-inner-content {
    padding: 0;
  }
}

.annotate {
  position: absolute;
  z-index: 401;
  left: 400px;
  top: 129px;
  width: 248px;
  height: 295px;
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.11);
  background-color: #f7fbff;
  padding: 9px 19px 19px 19px;
  &__header {
    margin-bottom: 45px;
    &__title {
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 300;
      color: #6E7882;
    }
    &__close {
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 300;
      color: #6E7882;
      float: right;
      cursor: pointer;
      margin-top: 3px;
    }
  }
  &__body {
    &__line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 19px;
      .input-space {
        input {
          width: 186px;
          height: 40px;
          border-radius: 3px;
          border: solid 1px rgba(110, 120, 130, 0.6);
        }
      }
      .button-space {
        padding-top: 3px;
        &__circle {
          border-radius: 50px;
          background-color: #AAAAAA;
          width: 14px;
          height: 14px;
        }
        .icon-colourpalette-b {
          font-size: 18px;
          cursor: pointer;
        }
      }
      .select-space {
        &__select {
          width: 186px;
          border-radius: 3px;
          .ant-select-selection {
            border-color: rgba(110, 120, 130, 0.6);
            height: 40px;
            .ant-select-selection-selected-value {
              padding-top: 4px;
            }
          }
        }
      }
    }
  }
  &__footer {
    text-align: center;
    margin-top: 45px;
    &__btn {
      padding: 5px 30px;
    }
    &__number {
      margin-top: 10px;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 300;
    }
  }
}
.simple-custom-color-picker {
  position: absolute;
  height: auto;
  width: auto;
  top: 110px;
  left: 80px;
  background-color: #FFFFFF;
  border: 1px solid rgba(110, 120, 130, 0.6);
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.11);
  .color-picker-a {
    height: 92px;
    width: 247px;
  }
}

.no-ai-results-found {
  width: 300px;
  height: 60px;
  border: 2px solid #150010;
  border-radius: 0px !important;
  padding: 10px 10px 10px 10px !important;
  display: flex;
  align-items: center;
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 36px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 700;
    color: #150010;
    line-height: 0px;
  }
  .anticon.ant-notification-notice-icon-info {
    color: #150010;
    font-size: 20px;
  }
  .ant-notification-notice-close {
    color: #150010;
    top: 8px;
    right: 16px;
  }
  .ant-notification-notice-close:hover {
    color: #150010;
  }
  .ant-notification-close-icon {
    font-size: 20px;
  }
}

.custom-option-review-ai {
  border: 1px solid var(--generalIconFontHover) !important;
  color: #382e34 !important;
  box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3) !important;
  .ant-select-dropdown-menu {
    padding: 0px 0 !important;
  }
  .ant-select-dropdown-menu-item {
   font-size: 14px !important;
    line-height: 20px !important;
    padding: 5px 10px !important;
    color: #382e34 !important;
  }
}