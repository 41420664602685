.privacyDashboardConnectorItem__wrapper {
  border-bottom: 1px solid #E6E6E6;
  padding: 20px;
}

.privacyDashboardConnectorItem-wrapper:last-of-type {
  border-bottom: none;
}

.privacyDashboardConnectorItem__info {
  color: #162737;
  font-size: 18px;
  font-weight: 300;
}

.privacyDashboardConnectorItem__info strong {
  font-weight: 700;
}

.privacyDashboardConnectorItem__logo {
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.privacyDashboardConnectorItem__logo img {
  height: auto;
  margin-bottom: 10px;
  width: 100px;
}

.privacyDashboardConnectorItem__actions {
  align-items: center;
  display: flex;
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
  justify-content: space-between;
  margin-top: 10px;
}

.privacyDashboard__connectorItem-loading img {
  width: 35px;
}

@media screen and (min-width: 768px) {
  .privacyDashboardConnectorItem__wrapper {
    border-bottom: 0;
    border-right: 1px solid #E6E6E6;
    width: 25%;
  }
  
  .privacyDashboardConnectorItem-wrapper:last-of-type {
    border-right: none;
  }

  .privacyDashboardConnectorItem__info img {
    margin-bottom: 0;
  }
}