.settings-redact-new {
  .new-page-redact {
    background: #FFFFFF;
  }
}
.smart-box-folder-settings {
  &_new-container {
    align-items: flex-end !important;
    .breadcrumb-data {
      width: 80% !important;
    }
  }
  &_new-container-security {
    align-items: flex-end !important;
  }
  &_container {
    position: relative;
    margin-top: 0px;
    padding-bottom: 100px;
    min-height: 400px;
    display: flex;
    //justify-content: center;
    flex-direction: column;
    align-items: center;
    .breadcrumb-top {
      margin-top: 100px;
    }
  }
  &_container.smart-box-folder-settings_new-container-update {
    padding-bottom: 0px;
  }
  &_new-container-folder-redaction {
    align-items: flex-end !important;
    /*margin-right: 30px;*/
  }
  &_new-container-analysis {
    align-items: flex-end !important;
    background-color: #fff;
  }
  &_cull {
    width: calc(100% - 0px);
    margin-top: 0px;
    .child-tabs-data {
      .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
        //background-color: #FFFFFF;
      }
    }
    .child-tabs .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div {
      display: flex;
      justify-content: normal;
    }
    .child-tabs .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
      border: none;
      background: white;
      font-weight: 400;
      color: #382e34;
      font-size: 14px;
      line-height: 20px;
      //margin-top: 3px;
      border-right: 1px solid #a6acb2;
    }
    .child-tabs {
      .ant-tabs-content {
        margin-top: 0;
      }
    }
  }
  &_data-insight {
    width: calc(100% - 0px);

    margin-top: 0px;
    .child-tabs-data {
      .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
        //background-color: #FFFFFF;
      }
    }
    .child-tabs .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div {
      display: flex;
      justify-content: normal;
    }
    .child-tabs .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
      border: none;
      background: white;
      font-weight: 400;
      color: #382e34;
      font-size: 14px;
      line-height: 20px;
      //margin-top: 3px;
      border-right: 1px solid #a6acb2;
    }
    .child-tabs {
      .ant-tabs-content {
        margin-top: 0;
      }
    }
  }
  &_header {
    position: relative;
    padding: 20px 20px 40px 20px;
    width: calc(100% - 300px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .folder-name {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    .ant-btn {
      width: 110px;
      height: 45px;
      border: 1px solid #3DD8DB;
      background-color:#3DD8DB;
      color: #FFFFFF;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      cursor: pointer;
      padding: 10px 30px;
      text-transform: uppercase;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 900;
      &:hover {
        background-color: #FFFFFF;
        border-color: #3DD8DB;
        color: #3DD8DB;
      }
      &:focus {
        border: 1px solid #3DD8DB;
        background-color:#3DD8DB;
        color: #FFFFFF;
      }
      &:active {
        border: 1px solid #3DD8DB;
        background-color:#3DD8DB;
        color: #FFFFFF;
      }
    }
  }

  &_header2 {
    padding: 0px 0px 40px 0px;
    margin-top: 60px !important;
    @media (max-width: 1200px) {
      padding: 20px 20px 20px 0px;
    }
  }

  &_box-last {
    .parent-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {
      &:not(.ant-tabs-tab-active):nth-child(even) {
        border-bottom: 2px solid #e8e8e8;
        color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #9199a1 !important;
        margin-left: -17px;
        margin-right: -17px;
        z-index: 0;
        padding: 0 49px;
        width: 200px;
      }
      &:not(.ant-tabs-tab-active) {
        z-index: 1;
        width: 172px;
        margin-right: -1px;
      }

    }
  }

  &_box-next {
    .parent-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {

      &:not(.ant-tabs-tab-active):nth-child(even) {
        border-bottom: 2px solid #e8e8e8;
        color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #babfc4 !important;
        margin-left: -17px;
        margin-right: -17px;
        z-index: 1;
        width: 173px;
      }
      &:last-child {
        width: 198px !important;
        margin-left: -2px !important;
        padding: 0 39px !important;

      }
    }
  }
  &_security-content {
    background-color: #fafafa !important;
    .tabs-security {
      box-shadow: none !important;
    }
    .tabs-security > .ant-tabs-top-content {
      background-color: #fafafa !important;
      padding: 40px;
    }
  }

  &_content-analysis {
    .parent-tabs {
      box-shadow: none !important;
    }
    .colour-profile-settings {
      .colour-profile-label {
        margin-bottom: 20px !important;
      }
      .colour-profile-settings-select {
        margin-bottom: 20px !important;
        .select-profiles-colours {
          width: 300px;
          color: #6e7882;
          font-weight: 400;
          font-size: 14px;
          margin-right: 10px;
          .ant-select-selection {
            border-radius: 0px;
            font-size: 14px;
            height: 30px;
            border: 1px solid #382e34;
            font-weight: 400;
            box-shadow: none;
          }
          .ant-select-selection:hover {
            border-color: #EDA41E;
            border-right-width: 1px !important;
            .ant-select-arrow {
              color: #eda41e;
            }
          }
          .ant-select-arrow:hover {
            color: #eda41e;
          }
        }
      }
    }
    .icon-v14-radio-button-off {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.65);
    }
    .icon-v14-question {
      font-size: 20px;
      color: #150010;
      margin-left: 5px;
    }
    .parent-tabs > .ant-tabs-top-content {
      background-color: #fafafa !important;
    }
  }

  &_content-empty {
    background-color: #fafafa !important;
  }
  &_content {
    width: 100%;
    background-color: #FFFFFF;
    .parent-tabs {
      border-radius: 3px;
      box-shadow: none;
      .ant-tabs-nav-scroll {
        overflow: visible;
      }
    }
    .parent-redaction-tabs {
      background-color: #fafafa;
      box-shadow: none !important;
      padding: 0 !important;
    }
    .parent-tabs2 {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
      padding: 40px;
    }

    .parent-tabs .ant-tabs-bar > .ant-tabs-nav-container {
      height: 25px !important;
      //border-bottom: 1px solid #a6acb2;
      //padding: 0 40px 0 20px;
      overflow: inherit !important;
      margin-bottom: 0px;
      line-height: inherit;
      white-space: nowrap;
      box-sizing: inherit;
    }

    .parent-tabs .ant-tabs-bar {
      margin-bottom: 0;
      height: 34px;
      padding: 4px 40px 4px 5px;
      position: fixed;
      background-color: #fafafa;
      border-bottom: 1px solid #a6acb2;
      //right: 0;
      z-index: 2;
      //z-index: 1000;
    }


    .tabs-analysis-settings {
      //margin-left: 50px;
      
      padding: 0 !important;
      .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab.ant-tabs-tab-active {
        //border-right: 1px solid #a6acb2;
      }
      .ant-tabs-card-bar .ant-tabs-tab {
        margin: 0 0px 0 0 !important;
      }
      .ant-tabs-card-bar .ant-tabs-nav-wrap {
        margin-top: 3px;
      }

      .ant-tabs-bar:first-child{
        padding: 0 40px 0 5px;
        width: calc(100% - 0px);
      }
      .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
        border: none;
        background: white;
        font-weight: 400;
        color: #382e34;
        font-size: 14px;
        line-height: 20px;
      }
      .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav {
        width: 100%;
        //margin-top: 3px;
      }
     .ant-tabs-card-bar .ant-tabs-tab {
        margin-top: 0px !important;
        //padding: 0 40px 0 35px !important;
      }
      .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab {
        background-color: #fafafa;
        //font-weight: 400;
        //color: #382e34;
        font-size: 14px;
        border-radius: 0;
        height: 20px;
        line-height: 20px;
        color: #382e34;
        //padding: 0 40px;
        float: left;

        &:first-child {
          //border-right: 1px solid #AFACAF;
          //padding-left: 20px;
          //padding-right: 40px;
          //color: #382e34;
          //vertical-align: middle;
          font-weight: 400;
          margin-top: 3px !important;
          padding: 0 40px 0 35px;
          border-right: 1px solid #a6acb2;
          &:hover {
            color:var(--generalIconFontActive);
            //border: none;
            font-weight: 400;
            font-size: 14px;
            //border-right: 1px solid #a6acb2;
          }
          &:focus {
            color: #EDA41E;
          }
        }
        &:nth-child(2) {
          //width: 180px;
          color: #382e34;
          //vertical-align: middle;
          font-weight: 400;
          margin-top: 3px !important;
          height: 20px !important;
          padding: 0px 40px 0 40px !important;
          &:hover {
            color: var(--generalIconFontActive);
          }
          &:focus {
            color: #EDA41E;
          }
        }
        &:last-child {
          float:right;
          //margin-top: 1px;
        }
        /*&:last-child {
          padding-right: 30px !important;
        }*/
        &::before {
          display: none;
        }
      }
      .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav > div .ant-tabs-tab.ant-tabs-tab-active {
        color: var(--generalIconFontActive);
        border-bottom: none;
        height: 20px;
        padding: 0px 40px 0 35px;
        margin-top: 3px;

        &::before {
          display: none;
        }
      }
      .ant-tabs-content {
        padding: 30px 40px 0 40px;
        margin-top: 33px !important;
        background-color: white;
      }
      .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
        cursor: alias;
      }
      .ant-tabs-tab-disabled {
        width: 45%;
        height: 50px;
        padding-right: 0 !important;
        margin-right: 0 !important;
      }
      .ant-tabs-nav .ant-tabs-tab-disabled {
        cursor: auto;
      }
      .top-label {
        font-family: Roboto;
        font-weight: 700;
        font-size: 20px;
        color: #382e34;
      }
      .ai-configuration-content {
        margin-top: 40px;
      }
      .top-text {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #382e34;
        margin-top: 10px;
        align-items: center;
        display: flex;
      }
      .left-side {
        float: left;
        clear: both;
        width: 247px;
        font-family: Roboto;
        font-weight: 700;
        font-size: 14px;
        color: #382e34;
        margin-top: 0px;
      }
      .right-side {
        float: left;
        width: calc(100% - 247px);
        margin-top: 0px;
      }
      .content-new-run-analytics {
        min-width: 248px;
        display: flex;
        justify-content: flex-end;
        .ant-btn {
          height: 25px;
          object-fit: contain;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          //font-style: normal;
          //font-stretch: normal;
          line-height: 22px;
          letter-spacing: 0.5px;
          text-align: center;
          vertical-align: middle;
          color: var(--generalButtonFont);
          background-color:var(--generalButton);
          border-radius: 30px;
          border: 1px solid #6e7882 /*232B38*/;
          //box-shadow: 0 2px 4px 0 #DCDCDC;
          box-shadow: none;
          width: 180px;
          padding-top: 1px;
          text-transform: capitalize;

          &:hover {
            /*border: 1px solid #232B38;
            color: #ffffff;
            background-color: #232B38;*/
            //border: 1px solid #00d4d8;
            //background: #00f0de;
            //color: #FFFFFF;
            border:1px solid var(--generalButtonHover);
            background-color: var(--generalButtonHover);
          }

          &:focus {
            /*border: 1px solid #232B38;
            color: #ffffff;
            background-color: #232B38;*/
            //border: 1px solid #00d4d8;
            //background: #00d4d8;
            color: #FFFFFF;
            background-color: #EDA41E;
            border:1px solid #EDA41E;
          }
          &:active {
            color: #FFFFFF;
            background-color: var(--generalButtonActive);
            border:1px solid var(--generalButtonActive);
            //border: 1px solid #00d4d8;
          }
        }
        .ant-btn[disabled]:hover {
          //color: #EDA41E;
          //opacity: 0.3;
          //border: 1px solid #EDA41E !important;
          //background: #FFFFFF;
        }
        .ant-btn[disabled] {
          color: #FFFFFF;
          //opacity: 0.3;
          //border: 1px solid #EDA41E !important;
          background-color: #D4D7DA;
          cursor: default;
        }
      }
    }
    .parent-tabs > .ant-tabs-top-content {
      background-color: white;
      padding: 40px;
    }

    .parent-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {
      border-radius: 0 15px 0 0;
      border-left: none;
      border-top: none;
      background: white;
      font-size: 16px;
      margin-right: 0;
      font-weight: bold;
      height: 31px;
      padding: 0 36px;
      line-height: 30px;
      box-shadow: 3px 2px 4px -3px rgba(0, 0, 0, 0.5);

      &:not(.ant-tabs-tab-active) {
        border-bottom: 2px solid #babfc4 !important;
        border: none;
        color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #babfc4;
        margin-left: -17px;
        margin-right: -17px;
      }

      &:not(.ant-tabs-tab-active):nth-child(even) {
        border-bottom: 2px solid #e8e8e8;
        color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #9199a1;
        margin-left: -17px;
        margin-right: -17px;
      }

      &.ant-tabs-tab-active {
        height: 28px;
        color: #6e7782 !important;
        background: white !important;
        z-index: 12;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        padding: 0 25px;
        width: 167px;
      }

      &:last-child {
        margin-right: 0;
        color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #9199a1;
        padding: 0 25px;
        width: 183px;
      }
    }

    .parent-tabs > .ant-tabs-nav > div {
      background: white;
      border-top: 1px solid #e8e8e8;
      border-top-right-radius: 20px;
    }

    .buttons {
      margin-top: 100px;
      clear: both;

      .button-white {
        border: 1px solid #3DD8DB;
        background-color: #FFFFFF;
        color: #3DD8DB;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 20px;
        padding: 10px 30px;
        text-transform: uppercase;
        border-radius: 30px;
      }

      .button-white:hover {
        background-color: #3DD8DB;
        color: #FFFFFF;
      }

      .button-blue {
        width: auto;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 20px;
        padding: 10px 30px;
        border-radius: 30px;

      }

      .button-white:hover {
        background-color: #FFFFFF;
        color: #3DD8DB;
      }
    }
  }
}

.setting-container {
  clear: both;
  padding-bottom: 40px;

  &_name {
    float: left;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 40px;
  }

  &_value {
    float: right;
    margin-bottom: 40px;
  }
}

.smart-box-settings-menu {
  width: 200px !important;
  border: 1px solid #e8e8e8;

  .ant-menu-submenu-open .ant-menu-sub {
    width: 200px;
    position: absolute;
    z-index: 100;
    li {
      padding-left: 24px !important;
      color: #6e7882
    }
  }
}
.dashboard-wrapper-processing {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 40px;
}
.new-dashboard-wrapper {
  background-color: #ffffff;
  padding: 30px;
  //border: 2px solid rgba(0, 0, 0, 0.10);
  margin-top: 34px;
}
.dashboard-wrapper {
  .image {
    img {
      width: 125px;
      height: 125px;
      cursor: pointer;
    }
  }
  .data-insights-title {
    font-size: 20px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #382e34;
    font-weight: 600;
    line-height: 20px;
    .anticon-caret-left {
      cursor: pointer;
    }
  }
  .ascending-pie-chart {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    &__chart {
      width: 100%;
      margin-top: 12%;
      margin-bottom: 16%;
      svg {
        overflow: visible;
        path:nth-child(1) {
          stroke-width: 20;
        }
        path:nth-child(2) {
          stroke-width: 18;
        }
        path:nth-child(3) {
          stroke-width: 16;
        }
        path:nth-child(4) {
          stroke-width: 14;
        }
        path:nth-child(5) {
          stroke-width: 12;
        }
        path:nth-child(6) {
          stroke-width: 10;
        }
        path:nth-child(7) {
          stroke-width: 8;
        }
        path:nth-child(8) {
          stroke-width: 6;
        }
      }
    }
  }
}
.new-progress-bars {
  .ant-dropdown-open {
    background-color: #e5f7ff;
  }
  .ant-progress-inner {
    width: 92%;
  }
  .ant-dropdown-trigger {
    cursor: pointer;
  }
  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 54px);
  }
}
.progress-bars {
  .ant-progress-inner {
    background: transparent;
    border: 1px solid #ccc;
  }
  .progress_label {
    color: #000;
    font-weight: 300;
    font-family: Roboto;
  }
  .ant-progress-line {
    .ant-progress-text {
      color: #000;
      font-weight: 400;
    }
  }
  .show_all {
    text-align: center;
    color: #6e7882;
    font-weight: 300;
    font-family: Roboto;
    margin-top: 20px;
    cursor: pointer;
  }
}

.legend_wrapper {
  width: 40%;
  position: relative;
  float: right;
  margin-top: 36vh;
  .legend_item {
    color: #00d3d8;
    margin-bottom: 15px;
    display: grid;
    .legend_wrapper_value {
      font-weight: 600;
      font-family: Roboto;
      font-size: 22px;
    }

    .legend_wrapper_name {
      font-weight: 500;
      font-family: Roboto;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .button-green-empty {
      background-color: #FFFFFF;
      border: 1px solid #3DD8DB;
      border-radius: 20px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      color: #3DD8DB;
      cursor: pointer;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
      font-size: 14px;
      padding: 0px 10px;

      &:hover {
        background-color: #3DD8DB;
        color: #FFFFFF;
      }
    }
  }
}
.border-allocate {
  border: 1px solid rgba(110, 120, 130, 0.3) !important;
  //.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup .ant-dropdown-menu {
  //  border-radius: 0;
  //  box-shadow: none;
  //  padding: 0;
  //  border: 1px solid rgba(110, 120, 130, 0.3);
  //}
  //.ant-dropdown-menu {
  //  border-radius: 0;
  //  box-shadow: none;
  //  //border-bottom: 1px solid rgba(110, 120, 130, 0.3) !important;
  //  padding: 0;
  //  border: 1px solid rgba(110, 120, 130, 0.3);
  //}
}
.dashboard-progress-submenu {
  .ant-dropdown-open {
    background-color: #0b2e13 !important;
  }
  .ant-dropdown-menu {
    box-shadow: none;
  }
  .anticon:first-child {
    display: none;
  }
  .ant-dropdown-menu-root {
    padding: 0px !important;
    width: 200px !important;
    border: 1px solid rgba(110, 120, 130, 0.3);

  }
  .ant-dropdown-menu-item{
    width: 300px !important;
    padding: 10px 20px !important;
    overflow-wrap: break-word;
  }
  .ant-dropdown-menu-submenu-title {
    //border: 1px solid rgba(110, 120, 130, 0.3);
    font-size: 16px !important;
    padding: 10px 20px !important;
    min-width: 90px;

    &:hover {
      color: white;
      background-color: #3DD8DB;
    }
  }
  .ant-dropdown-menu-root .ant-dropdown-menu-submenu-title {
    padding-right: 30px !important;
  }

  &.ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup ul {
    margin-right: 0 !important;
    //margin-left: -5px !important;
    margin-left: 0px !important;
    min-width: 200px;
    //border: 1px solid rgba(110, 120, 130, 0.3);
  }
}
.dashboard-progress-submenu-categories {
  .ant-dropdown-menu-submenu-title {
    text-transform: capitalize !important;
  }
}

/*.file-settings-tab .ant-tabs-nav > div { display:block !important; }*/