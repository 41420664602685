.topicItem-wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32%;
  margin-bottom: 20px;
}

.topicItem-header {
  border-bottom: 1px solid #E6E6E6;
  padding: 15px;
  position: relative;
}

.topicItem-title {
  color: #162737;
  font-size: 18px;
}

.topicItem-content {
  border-bottom: 1px solid #E6E6E6;
  padding: 15px;
}

.topicItem-contentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.topicItem-text {
  color: #737E89;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.topicItem-listItem {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.topicItem-listItem-title {
  color: #111C26;
  font-size: 14px;
  flex: 1;
}

.topicItem-listItem-valueWrapper {
  position: relative;
  width: 60px;
}

.topicItem-listItem-value {
  background-color: #DADCE0;
  height: 10px;
  position: absolute;
  right: 0;
  top: -5px;
}

.topicItem-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.topicItem-link {
  color: #1DCBCF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
}

.topicItem-link::after {
  border-color: transparent transparent transparent #1DCBCF;
  border-style: solid;
  border-width: 3px 0 3px 6px;
  content: '';
  height: 0;
  position: absolute;
  right: -12px;
  top: 5px;
  width: 0;
}