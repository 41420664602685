.clutter-page {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  width: 100%;
  background: #FFFFFF;
  margin-top: 34px !important;
  padding: 30px 40px 0;
  &__content {
    .header-clutter {
      display: flex;
      flex-direction: row;
      color: #382e34;
      align-items: center;
      margin-bottom: 40px;
      .title-header {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.2px;
        vertical-align: middle;
        alignment: left;
      }
      .icon-v14-radio-button-off {
        margin-left: 10px;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.65);
      }
      .help-message-locations {
        .ant-tooltip-arrow::before {
          width: 14px;
          height: 14px;
          background-color: #FFFFFF;
          border: 1px solid #382e34;
        }
        .ant-tooltip-inner {
          font-family: Roboto;
          color: #382e34;
          background-color: #FFFFFF;
          border: 1px solid #382e34;
          padding: 20px;
        }
        .content-help-message-locations {
          .text-information {
            font-size: 14px;
            font-weight: 400;
            margin-right: 3px;
          }
          .text-information-header {
            font-weight: 700;
            font-size: 14px;
          }
          .more-info-link {
            font-weight: 700;
            font-size: 14px;
            color: #00d4d8;
            cursor: pointer;
            text-decoration: underline;
            width: 65px;
            margin-left: 3px;
            //display: inline-flex;
            //margin-left: 5px;
          }
        }
      }
      .icon-v14-question {
        margin-left: 10px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.65);
      }
      .subtitle-header {
        height: 30px;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.2px;
        vertical-align: middle;
        alignment: left;
        margin-bottom: 30px;
      }
    }

    .content-clutter-page {
      .clutter-table {
        &__content {
          .content-actions-declutter {
            display: flex;
            position: relative;
            .button-auto-go {
              display: flex;
              position: absolute;
              right: 2px;
              top: -125px;
              z-index: 4;
              button:disabled {
                padding: 1px 18px;
              }
              .button-blue-go {
                height: 25px;
                border: 1px solid #6e7882;
                background-color: var(--generalButton);
                color: var(--generalButtonFont);
                box-shadow: none;
                vertical-align: middle;
                horiz-align: center;
                letter-spacing: 0.5px;
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
                padding: 1px 18px;
                text-transform: capitalize;
                border-radius: 40px;
                font-family: Roboto;
                line-height: 18.75px;
                width: 180px;
                position: fixed;
                display: flex;
                right: 3%;
                line-height: 22px;
                justify-content: center;
                &:hover {
                  background-color: var(--generalButtonHover);
                  //color: #FFFFFF;
                  border: 1px solid var(--generalButtonHover);
                }
                &:active {
                  background-color: var(--generalButtonActive);
                  //color: #FFFFFF;
                  border: 1px solid var(--generalButtonActive);
                }
              }
              .button-blue-go:disabled {
                color: #FFFFFF;
                background-color: #D4D7DA;
                border: 1px solid #D4D7DA !important;
                cursor: default;
                &:hover {
                  background-color: #D4D7DA;
                  color: #FFFFFF;
                  border: 1px solid #D4D7DA;
                  cursor: not-allowed;
                }
              }
              .button-loading {
                height: 25px;
                border: 1px solid #eda41e;
                background-color: #FFC457;
                color: #ffffff;
                box-shadow: none;
                vertical-align: middle;
                horiz-align: center;
                letter-spacing: 0.5px;
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
                padding: 1px 18px;
                text-transform: capitalize;
                border-radius: 40px;
                font-family: Roboto;
                line-height: 18.75px;
                width: 180px;
                position: fixed;
                display: flex;
                right: 3%;
                line-height: 22px;
                justify-content: center;
                pointer-events: none;
                .anticon-loading {
                  margin-right: 15px;
                }
              }
            }
          }
          .clutter-table-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            height: 15px;
            //margin-bottom: 13px;
            .content-select-all-clutter {
              display: flex;
              align-items: center;
              margin-left: 5px;
              font-family: Roboto;
              .select-non-checkbox {
                .ant-checkbox-wrapper .ant-checkbox-inner {
                  border-radius: 0% !important;
                  border: 1px solid #6e7882;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: #EDA41E !important;
                }
                .ant-checkbox-inner:after {
                  border-color: #ffffff !important;
                }
                .ant-checkbox-inner::before {
                  border-color: #FFFFFF;
                  position: absolute !important;
                  display: table;
                  color: #FFFFFF !important;
                  border: none !important;
                  border-top: 0;
                  border-left: 0;
                  -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: '\e8ce' !important;
                  top: 7px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                }
                .ant-checkbox-checked .ant-checkbox-inner::after {
                  border-color: #FFFFFF !important;
                  position: absolute !important;
                  display: table;
                  color: #6e7882 !important;
                  border: none !important;
                  border-top: 0;
                  border-left: 0;
                  -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: '\e8ce' !important;
                  top: 7px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: #FFFFFF !important;
                }
              }
              .select-deduplicate-checkbox {
                line-height: 30px;
              }
              .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
                border-color: var(--generalIconFontHover);
              }
              .ant-checkbox-checked .ant-checkbox-inner::after {
                border-color: #FFFFFF;
                position: absolute !important;
                display: table;
                color: #FFFFFF;
                border: none !important;
                border-top: 0;
                border-left: 0;
                -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                transform: rotate(0deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                content: '\e8ce' !important;
                top: 7px !important;
                left: 7px !important;
                font-size: 12px !important;
                font-family: 'smartbox_icons_v19' !important;
              }
              .checkbox-auto-disabled {
                cursor: not-allowed;
                opacity: 0.6;
                .ant-checkbox-inner {
                  cursor: not-allowed;
                }
                .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
                  border-color: #d9d9d9;
                  opacity: 0.6;
                }
              }
              .checkbox-auto {
                .option-select-all-disabled {
                  opacity: 0.6;
                }
                .ant-checkbox-wrapper .ant-checkbox-inner {
                  border-radius: 0% !important;
                  border: 1px solid #6e7882;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: var(--generalIconFontActive) !important;
                }
                .ant-checkbox-inner:after {
                  border-color: #ffffff !important;
                }
              }
              .select-auto {
                margin-left: 10px;
                font-family: Roboto;
                font-size: 14px;
                color: #382e34;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.2px;
              }
            }
          }
          .table-clutter-content {
            margin-top: 10px;
            border-top: 1px solid #382e34;
            tbody {
              vertical-align: middle !important;
            }
            .ant-table colgroup > col.ant-table-selection-col {
              width: 30px;
            }
            .table-clutter-files {
              .ant-checkbox-checked:focus {
                .ant-checkbox-inner {
                  border: 1px solid #EDA41E;
                }
              }
              .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
                border-color: var(--generalIconFontHover);
              }
              .ant-table-tbody {
                vertical-align: middle !important;
              }
              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: var(--generalIconFontActive) !important;
                border: 1px solid #6e7882;
              }
              .ant-table-tbody > tr > td {
                //border-bottom: 1px solid #6e7882;
                background: #FFFFFF;
              }
              .ant-table-tbody > tr > td.ant-table-selection-column {
                text-align: left;
                padding-left: 5px;
                width: 31px;
              }
              .ant-checkbox-checked .ant-checkbox-inner::after {
                border-color: #FFFFFF;
                position: absolute !important;
                display: table;
                color: #FFFFFF !important;
                border: none !important;
                border-top: 0;
                border-left: 0;
                -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                transform: rotate(0deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                content: '\e8ce' !important;
                top: 7px !important;
                left: 7px !important;
                font-size: 12px !important;
                font-family: 'smartbox_icons_v19' !important;
              }
              .ant-checkbox-inner:after {
                border-color: #ffffff !important;
              }
              .ant-checkbox-wrapper .ant-checkbox-inner {
                border-radius: 0% !important;
                border: 1px solid #6e7882;
                //border: 1px solid #EDA41E;
              }
              .ant-table-tbody > tr {
                vertical-align: initial !important;
              }
              .ant-checkbox-checked:hover {
                .ant-checkbox-inner {
                  background-color: var(--generalIconFontActive) !important;
                  border: 1px solid var(--generalIconFontHover);
                }
              }
              .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
                background: #9ae0fc50;
                cursor: pointer;
              }
              .ant-table-row:hover {
                //background: #e6f7ff;
              }
              .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
                height: 30px;
                padding: 0px;
                vertical-align: middle;
              }
              .rotate-arrow-medium {
                -webkit-transform: rotate(270deg);
                writing-mode: vertical-rl;
              }
              .icon-v16-arrow-medium {
                font-size: 20px !important;
                //height: 20px;
              }
              .text-style {
                font-family: Roboto;
                font-weight: 400;
                font-size: 14px;
                color: #6e7882;
                alignment: left;
                vertical-align: middle;
                line-height: 20px;
                letter-spacing: 0.2px;
              }
              .total-files {
                padding-top: 0px !important;
                display: flex;
                justify-content: flex-end;
                .total-files-content {
                  display: flex;
                  float: right;
                  align-items: center;
                  .number-duplicate {
                    border: 1px solid #491bc2;
                    background: #491bc2;
                    color: #ffffff;
                    padding: 5px;
                    border-radius: 25px;
                    height: 20px;
                    width: 100%;
                    margin-right: 20px;
                    //margin-top: 5px;
                    .nr-dupl {
                      display: flex;
                      position: relative;
                      bottom: 5px;
                    }
                  }
                }
              }
              .column-name-clutter {
                span {
                  vertical-align: middle !important;
                }
                .name-duplicate-content {
                  vertical-align: middle !important;
                  span {
                    vertical-align: middle !important;
                  }
                  .icon-v14-star-active {
                    color: #EDA41E;
                    font-size: 20px;
                    margin-right: 10px;
                  }
                  .icon-v14-star {
                    color: #A6ACB2;
                    font-size: 20px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }

        }
      }
    }

  }
}

.confirm-declutter {
  width: 450px !important;
  .ant-modal-body {
    padding: 30px !important;
  }
  .ant-modal-confirm-btns button + button {
    margin-bottom: 0 !important;
    margin-left: 0px !important;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    margin-top: 40px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title {
    .icon-v21-clean {
      color: #382e34;
      font-size: 40px;
    }
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: 14px;
    font-weight: 700;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.2px;
    vertical-align: middle;
    color: #382e34;
    margin-top: 10px;
    height: 20px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .cancel-button {
    background-color: #FFFFFF;
    color: #6e7882;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.5px;
    vertical-align: middle;
    margin-top: 0px;
    border: 1px solid #6e7882;
    height: 25px;
    width: 130px;
    padding-top: 2px;
    box-shadow: none;
    &:hover {
      color: var(--generalButtonHover);
      border: 1px solid var(--generalButtonHover);
      background-color: #FFFFFF;
    }
    &:active {
      color: var(--generalButtonActive);
      border: 1px solid var(--generalButtonActive);
      background-color: #FFFFFF;
    }
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .confirm-button {
    background-color: var(--generalButton);
    color: var(--generalButtonFont);
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;
    line-height: 18.72px;
    letter-spacing: 0.5px;
    vertical-align: middle;
    margin-top: 0px;
    border: 1px solid #6e7882;
    height: 25px;
    width: 130px;
    padding-top: 2px;
    box-shadow: none;
    &:hover {
      color: var(--generalButtonFont);
      border: 1px solid var(--generalButtonHover);
      background-color: var(--generalButtonHover);
    }
    &:active {
      //color: #FFFFFF;
      border: 1px solid var(--generalButtonActive);
      background-color: var(--generalButtonActive);
    }
    span {
      padding: 0px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
    }
  }
}