.privacyFiles__wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyFiles__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
}

.privacyFiles__boxHeader {
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.privacyFiles__boxTitle {
  color: #162737;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacyFiles__boxTitleTotal {
  display: block;
  font-size: 16px;
  font-weight: 300;
  position: relative;
}

.privacyFiles__boxText {
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.privacyFiles__boxButton {
  text-align: center;
}

.privacyFiles__boxButton button {
  padding: 8px 20px;
  width: 140px;
}

.privacyFiles__loading {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .privacyFiles__boxHeader {
    align-items: center;
    flex-direction: row;
  }

  .privacyFiles__boxTitleTotal {
    display: inline;
    font-size: 18px;
    margin-left: 5px;
    padding-left: 10px;
  }
  
  .privacyFiles__boxTitleTotal::before {
    content: '-';
    left: 0;
    position: absolute;
    top: 0;
  }

  .privacyFiles__boxText {
    margin-bottom: 0;
  }
}
