.ospDashboard-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.ospDashboard__title {
  display: block;
  color: #737D87;
  font-weight: 300;
  margin: 30px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  &:last-of-type {
    margin: 10px 0 30px;
  }
  &::before {
    background-color: #C5C9CD;
    height: 1px;
    content: "";
    left: 0;
    position: absolute;
    top: 8px;
    width: calc(50% - 140px);
  }
  &::after {
    background-color: #C5C9CD;
    height: 1px;
    content: "";
    right: 0;
    position: absolute;
    top: 8px;
    width: calc(50% - 140px);
  }
}

.ospDashboard-boxWelcome {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
}

.ospDashboard-boxHeader {
  border-bottom: 1px solid #F2F2F2;
  padding: 20px;
}

.ospDashboard-boxTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.ospDashboard-boxText {
  @extend .ospDashboard-boxTitle;
  line-height: 1.5;
  padding: 20px;
}

.ospDashboard-boxLogo {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;
  height: 200px;
  padding: 130px 0 0 10px;
  position: relative;
  width: 120px;
  &::after {
    bottom: 60px;
    content: ™;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 300;
    left: 90px;
    position: absolute;
  }
}

.ospDashboard-boxInfo {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  flex: 1;
  height: 200px;
  position: relative;
}

.ospDashboard-boxInfoText {
  @extend .ospDashboard-boxTitle;
  line-height: 1.5;
  padding: 10px;
}

.ospDashboard__resources {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  padding: 20px 20px 0;
  position: relative;
}

.ospDashboard__resources--header {
  display: flex;
  flex-direction: column-reverse;
  a {
    color: #1DCBCF;
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.ospDashboard__resources--title {
  color: #000000;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 10px;
}

.ospDashboard-whitepaper {
  width: 100%;
  &:last-of-type {
    border-right: none;
  }
}

.ospDashboard-content {
  border-top: 1px solid #F2F2F2;
  display: flex;
  padding: 20px 0;
}

.ospDashboard-wrapper {
  display: flex;
}

.ospDashboard-iconPdf {
  height: 39px;
  width: 33px;
}

.ospDashboard-whitepaperInfo {
  flex: 1;
  padding: 0 10px 0 20px;
}

.ospDashboard-whitepaperTitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin-top: -7px;
}

.ospDashboard-whitepaperDescription {
  margin: 10px 0 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
}

.ospDashboard-whitepaperLink {
  align-self: center;
}

.ospDashboard-whitepaperLinkOnMobile {
  background: url(../../../assets/icon-arrow.png) no-repeat center center #FFFFFF;
  display: block;
  height: 19px;
  width: 13px;
}

.ospDashboard-whitepaperButton {
  display: none;
}

@media screen and (min-width: 768px) {
  .ospDashboard-page {
    padding: 40px 40px 0;
  }
  .ospDashboard__box--split {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ospDashboard-boxLogo {
    padding: 130px 0 0 20px;
    width: 268px;
  }
  .ospDashboard-boxInfo::before {
    content: "";
    border-style: solid;
    border-width: 0 0 202px 90px;
    border-color: transparent transparent #FFFFFF transparent;
    height: 0;
    left: -90px;
    position: absolute;
    top: -2px;
    width: 0;
  }
  .ospDashboard-boxInfoText {
    font-size: 15px;
    padding: 20px;
  }
  .ospDashboard__resources--header {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .ospDashboard__resources--title, .ospDashboard__resources--header a {
    margin-bottom: 0;
  }
  .ospDashboard-whitepaperLinkOnMobile {
    display: none;
  }
  .ospDashboard-whitepaperButton {
    display: block;
    button {
      width: 150px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ospDashboard-container {
    flex-direction: row;
  }
  .ospDashboard-whitepaper {
    border-right: 1px solid #F2F2F2;
    padding: 0 30px;
    width: 33.33%;
  }
  .ospDashboard-content {
    border-top: none;
    flex-direction: row-reverse;
  }
  .ospDashboard-wrapper {
    flex-direction: column;
  }
  .ospDashboard__resources {
    padding: 20px 0 0;
  }
  .ospDashboard__resources--header {
    border-bottom: 1px solid #EDEDED;
    padding: 0 20px 20px;
  }
  .ospDashboard__resources--body {
    display: flex;
    padding: 20px;
  }
  .ospDashboard-whitepaperInfo {
    padding: 0 20px 0 0;
  }
  .ospDashboard-whitepaperLink {
    align-self: flex-start;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .ospDashboard-boxResourcesTitle {
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .questionnaireFilter__select {
    margin: 0 20px 0 0;
    width: 100%;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50% #FFFFFF;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      font-size: 16px;
      height: 42px;
      padding: 10px 0 10px 8px;
      outline: none;
      width: 100%;
    }
  }
  .button-newBusiness {
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    border-radius: 30px;
    -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 12px;
    padding: 15px 4px;
    text-transform: uppercase;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
}

@media screen and (max-width: 768px) and (min-width: 600px) {
  .unlockedDashboard-infoContainer {
    width: 86%;
  }
}