.alert-alertList-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .alert-alertList-wrapper {
    flex-direction: row;
  }

  .alert-alertList-wrapper .alertItem-wrapper {
    border-bottom: none;
    width: 33.333%;
  }
}