.newQuestionnaireFilter__wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  label {
    color: #6E7882;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
}

.newQuestionnaireFilter__button {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.newQuestionnaireFilter__buttonWrapper {
  align-items: center;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  display: flex;
  height: 41px;
  justify-content: center;
  width: 41px;
}

.newQuestionnaireFilter__labelCentered {
  text-align: center;
}

.newQuestionnaireFilter__inputFind {
  background: url(../../../../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  box-sizing: border-box;
  color: #555555;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  padding-left: 35px;
  width: 100%;
  height: 42px;
  &:focus {
    outline: 2px solid #3DD8DB;
    width: 100%;
  }
}

.newQuestionnaireFilter__select {
  align-items: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.newQuestionnaireFilter__selectActions {
  line-height: 12px;
  display: flex;
  justify-content: space-around;
  label {
    color: #3DD8DB;
    cursor: pointer;
  }
}

.newQuestionnaireFilter__select select {
  appearance: none;
  background: url(../../../../assets/down-caret.png) no-repeat 97% 50% #FFFFFF;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  font-size: 16px;
  height: 42px;
  padding: 10px 25px 10px 10px;
  outline: none;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .newQuestionnaireFilter__inputFind {
    width: 0;
  }
  .newQuestionnaireFilter__button {
    margin: 0 10px 0 0;
  }
  .newQuestionnaireFilter__wrapper {
    flex-direction: row;
    justify-content: space-around;
  }
  .newQuestionnaireFilter__select {
    margin: 0 10px 0 0;
    max-width: 200px;
  }
}