.piaAlertDoNotNeedPia__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 60px;
}

.piaAlertDoNotNeedPia__title {
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}

.piaAlertDoNotNeedPia__subTitle {
  margin-top: 30px;
  text-align: center;
  color:  #68727c;
  font-weight: 300;
}

.piaAlertDoNotNeedPia__buttons {
  margin-top: 20px;
  text-align: center;
}