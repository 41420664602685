.privacyCollection__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyCollection__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
}

.privacyCollection__boxHeader {
  align-items: initial;
  border-bottom: 1px solid #E6E6E6;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.privacyCollection__boxTitle {
  color: #162737;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.privacyCollection__boxTitleTotal {
  font-size: 18px;
  font-weight: 300;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
}

.privacyCollection__boxTitleTotal::before {
  content: '-';
  left: 0;
  position: absolute;
  top: 0;
}

.privacyCollection__boxText {
  color: #737E89;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.privacyCollection__boxLink {
  border-bottom: 1px solid #737E89;
  color: #162737;
  font-weight: 500;
}

.privacyCollection__boxButton {
  text-align: center;
}

.privacyCollection__boxButton button {
  height: 34px;
  padding: 8px 20px;
  width: 180px;
}

.privacyCollection__loading {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.privacyCollection__page .collectionList__wrapperCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .privacyCollection__boxHeader {
    align-items: center;
    flex-direction: row;
  }

  .privacyCollection__boxHeader button {
    margin-left: 10px;
  }

  .privacyCollection__boxText {
    margin-bottom: 0;
  }

  .privacyCollection__page .collectionList__wrapperCard {
    flex-direction: row;
  }
}

@media screen and (min-width: 1200px) {
  .privacyCollection__boxHeader {
    align-items: center;
    flex-direction: row;
  }
}