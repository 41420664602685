.location-input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00d3d8;
  &__input {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    border: none;
    background: white;
    outline: none;
  }

  span {
    font-size: 18px;
    color: #00d3d8;
    margin-right: 7px;

    &:before {
      margin-right: 0;
    }
  }
}

.textarea-component {
  width: 100%;
  &__input {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    background: white;
    outline: none;
    border: 1px solid #00d3d8;
    border-radius: 0;
  }
}


.text-component {
  width: 100%;
  &__input {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    background: white;
    outline: none;
    border: 1px solid #00d3d8;
    border-radius: 0;
  }
}

.asset-register-modal {
  .ant-modal-content {
    box-shadow: 2px 14px 29px 0 rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        font-family: Roboto;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        .ant-modal-confirm-body {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 17px;

          i {
            display: none;
          }
          .ant-modal-confirm-title {
            font-weight: 500;
            font-size: 20px;
            color: #6e7882;
          }
          .ant-modal-confirm-content {
            margin-left: 0;
            margin-top: 13px;
            font-size: 21px;
            font-weight: bold;
            color: #00d3d8;
          }
        }
        .ant-modal-confirm-btns {
          width: 100%;
          margin-top: 69px;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            border: 1px solid #3DD8DB;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 400;
            border-radius: 30px;
            font-size: 20px;
            text-transform: uppercase;
            -webkit-transition: all .2s;
            transition: all .2s;
            height: auto;
            span {
              padding: 12px 2px;
            }
          }
          .confirm-button {
            background-color: #3DD8DB;
            color: #FFFFFF;
            width: 110px;
            &:hover {
              background-color: #FFFFFF;
              color: #3DD8DB;
            }
          }

          .cancel-button {
            background-color: #FFFFFF;
            color: #3DD8DB;
            width: 110px;
            &:hover {
              background-color: #3DD8DB;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}

.check-box-view {
  &__item {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: black;
    &__check {
      width: 16px;
      height: 16px;
      border: 1px solid #3DD8DB;
      display: block;
      margin-right: 13px;
    }
  }
}

.radio-view {
  &__item {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: black;
    &__check {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 1px solid #3DD8DB;
      display: block;
      margin-right: 13px;
    }
  }
}

.dropdown-view {
  &__item {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: black;
  }
}

.history-table-view {
  display: flex;
  border-bottom: 1.5px solid #6e7882;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 20px;
  span {
    margin-right: 50px;
    margin-bottom: 5px;
  }
}

.custom-radio-buttons {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-top: 20px !important;
  width: 100%;
  .ant-radio-wrapper {
    width: 100%;
    line-height: 2.14;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;

    .ant-radio {
      order: 2;
    }
    span.ant-radio + * {
      padding-left: 0;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #3DD8DB;
    }

    &:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: #3DD8DB;
    }

    .ant-radio-inner:after {
      background-color: #3DD8DB;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .asset-register-modal {
    .ant-modal-content {
      width: 100%;
      //left: 80px;

    }
  }
}