.documentList__wrapperTable {
  margin-bottom: 20px;
  overflow-y: scroll;
  width:100%;
}

.documentList__wrapperTable th {
  background-color: #FFFFFF;
  border-bottom: 3px solid #E6E8EA;
  color: #6E7882;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  text-transform: uppercase;
}

.documentList__wrapperTable tr {
  cursor: pointer;
}

.documentList__wrapperTable td {
  border-bottom: 1px solid #E6E8EA;
  color: #111C26;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
}

.documentList__wrapperTable tr:last-of-type td {
  border-bottom: none;
  color: #111C26;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
}

.documentList__wrapperTable tr:nth-child(even) td {
  background-color: #FFFFFF;
  cursor: pointer;
}

.documentList__wrapperTable tr:nth-child(odd) td {
  background-color: #FBFBFB;
  cursor: pointer;
}
