.reviewTopic__header {
  align-items: center;
  border-bottom: 2px solid #D6D3D3;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding-left: 20px;
}

.reviewTopic__header-title {
  color: #111C26;
  font-size: 24px;
}

.reviewTopic__content {
  display: flex;
  height: calc(100% - 70px);
  justify-content: center;
}

.reviewTopic__status-left .reviewTopic__content-left {
  display: none;
}

.reviewTopic__status-left .reviewTopic__content-right {
  flex-basis: calc(100% - 20px);
  overflow-y: auto;
}

.reviewTopic__status-split .reviewTopic__content-left {
  flex-basis: calc(50% - 10px);
  overflow-y: auto;
}

.reviewTopic__status-split .reviewTopic__content-right {
  flex-basis: calc(50% - 10px);
  overflow-y: auto;
}

.reviewTopic__status-right .reviewTopic__content-left {
  flex-basis: calc(100% - 10px);
  overflow-y: auto;
}

.reviewTopic__status-right .reviewTopic__content-right {
  display: none;
}

.reviewTopic__content-toggle {
  align-items: center;
  border-left: 2px solid #E0E0E0;
  border-right: 2px solid #E0E0E0;
  display: flex;
  flex-basis: 20px;
  justify-content: center;
  text-align: center;
}

.reviewTopic__content-toggle-item {
  border-bottom: 1px solid #D6D3D3;
  border-top: 1px solid #D6D3D3;
  color: #111C26;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  height: 35px;
  line-height: 35px;
  width: 16px;
}

.reviewTopic__content-toggle-item-active {
  background-color: #D6D3D3;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  height: 35px;
  line-height: 35px;
  width: 16px;
}

.reviewTopic__footer {
  align-items: center;
  border-top: 2px solid #D6D3D3;
  display: flex;
  justify-content: space-between;
}

.reviewTopic__footer-item {
  flex-basis: 50%;
  margin-top: 10px;
  padding: 10px 20px;
}

.reviewTopic__footer-item:first-of-type {
  border-right: 2px solid #E0E0E0;
}

.reviewTopic__footer-item h2 {
  color: #6E7882;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.reviewTopic__footer-item span {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.reviewTopic__footer-item strong {
  text-transform: uppercase;
}

.reviewTopic__content--empty {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.reviewTopic__content--action {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15px;
}
