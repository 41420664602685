.questionnaireOptionsList__optionsHeader {
  background-color: #FCFAFA;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  font-size: 14px;
span {
  text-transform: uppercase;
  font-size: 14px;
&::after {
   content: '?';
   color: #3DD8DB;
   padding-left: 5px;
   font-weight: bold;
 }
}
}

.questionnaireOptionsList__headerTitle {
  padding-left: 50px;
  flex: 1;
}

.questionnaireOptionsList__headerMessage {
  text-align: center;
  width: 42px;
  margin-right: 9px;
}

.questionnaireOptionsList__headerScore {
  text-align: center;
  width: 57px;
  margin-right: 18px;
}

.questionnaireOptionsList__headerJump {
  margin-right: 120px;
}

.questionnaireOptionsList__headerJumpSmall {
  margin-right: 90px;
}

.questionnaireOptionsList__actionsWrapper {
  display: flex;
  border-top: 1px solid #CCCCCC;
}

.questionnaireOptionsList__actionItem {
  color: #3DD8DB;
  cursor: pointer;
  font-weight: bold;
  padding: 20px 5px;
  text-align: center;
  flex: 1;
  font-size: 14px;
  border-right: 1px solid #CCCCCC;
&:last-of-type {
   border-right: none;
 }
}

.questionnaireOptionsList__actionItemGray {
  color: #555;
  cursor: pointer;
  font-weight: bold;
  padding: 20px 5px;
  text-align: center;
  flex: 1;
  font-size: 14px;
  border-right: 1px solid #CCCCCC;
}