.lockedDashboard-wrapper {
  margin-bottom: 30px;
}

.lockedDashboard-info {
  cursor: pointer;
  flex: 1;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
}

.lockedDashboard-name {
  display: flex;
span {
  margin-left: 5px;
  height: 15px;
  width: 15px;
}
}

.lockedDashboard__questionGroup {
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
}

.lockedDashboard-itemHeaderOpened {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 2px solid #D6D3D3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
}

.lockedDashboard-itemHeader {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
}

.lockedDashboard-infoContainer {
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: flex;
  justify-content: space-between;
  margin: 0 70px 0 15px;
  min-height: 70px;
  padding-right: 20px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .lockedDashboard-itemHeaderOpened, .lockedDashboard-itemHeader {
    align-items: center;
    flex-direction: row;
  }
}