.alertInfo__loading {
  margin-left: 20px;
}

.alertInfo__item {
  color: #6E7882;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 5px 30px;
  text-transform: uppercase;
}

.alertInfo__item:first-of-type {
  border-right: 1px solid #6E7882;
}

.alertInfo__item strong {
  color: #111C26;
  font-size: 14px;
}