.company-settings {
  //width: 80%;
  //margin: 120px auto 0 auto;
  padding: 100px 82px 0;
  position: relative;
  .content-company-settings {
    //background-color: #ffffff;
    //margin-bottom: 30px;
    //min-height: 500px;
    //box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.7);
    //font-family: Roboto;
    &__header {
      //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: 20px;
      display: flex;
      align-content: center;
      margin: 30px 0 40px 0;
      .title-company {
        font-size: 20px;
        font-weight: 700;
        color: #382e34;
        align-items: center;
        display: flex;
        //padding-left: 20px;
      }
    }
    &__content {
      display: flex;
      width: 100%;
      flex-direction: column;
       .content-company-name-upload {
         //padding-top: 20px;
         //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
         //width: 100%;
         .name-company {
           //margin-left: 20px;
           display: flex;
           flex-direction: column;
           margin-bottom: 30px;
           label {
             //height: 21px;
             font-size: 14px;
             font-weight: 400;
             color: #6e7882;
             margin-bottom: 10px;
             line-height: 20px;
           }
           .input-company {
             width: 50%;
             border: 1px solid #6e7882;
             color: #6e7882;
             background-color: #FFFFFF;
             padding: 0 10px;
             font-size: 16px;
             font-weight: 400;
             height: 40px;
             border-radius: 0px;
             &:hover {
               border: 1px solid #EDA41E;
               box-shadow: none;
             }
             &:focus {
               border: 1px solid #EDA41E;
               box-shadow: none;
             }
             &:active {
               border: 1px solid #EDA41E;
               box-shadow: none;
             }
           }
         }
         .content-upload-logo-company {
           width: 100%;
           //padding-left: 20px;
           margin-bottom: 30px;
           .m-3 {
             margin: 0rem !important;
           }
           .float-left {
             float: none !important;
           }
           .image-empty {
             width: 100px;
             height: 100px;
             background-color: rgba(110, 120, 130, 0.4);;
             border: 1px solid #6e7882;
             border-radius: 50%;
           }
           .company-icon-logo {
             width: 100px;
             height: 100px;
             border-radius: 50%;
             float: left;
             margin-right: 30px;
             border: 1px solid #6e7882;
           }
           .upload-title-logo {
             margin-bottom: 10px;
             line-height: 20px;
             color: #6e7882;
             .style-span-logo {
               font-size: 14px;
               font-weight: 400;
               color: #6e7882;
             }
           }
           .file-upload-company {
             border-bottom: 1px solid rgba(0, 0, 0, 0.1);
             .fileContainer {
               display: flex;
               align-items: center;
               height: 100px;
               justify-content: flex-start;
               flex-direction: initial;
               box-shadow: none;
               padding: 0px;
               margin: 0px;
               border-radius: 0px;
               .chooseFileButton {
                 background-color: #FFFFFF;
                 border: 1px solid #6e7882;
                 color: #6e7882;
                 font-family: Roboto;
                 font-size: 16px;
                 font-weight: 400;
                 height: 25px;
                 line-height: 18.75px;
                 width: 170px;
                 padding: 3px;
                 margin: 0px;
                 &:hover {
                   background-color: #FFFFFF;
                   border: 1px solid var(--generalButtonHover);
                   color: var(--generalButtonHover);
                 }
                 &:active {
                   background-color: #FFFFFF;
                   border: 1px solid var(--generalButtonActive);
                   color: var(--generalButtonActive);
                 }
               }
             }
           }
         }
       }
      .content-factor-authentication {
        display: flex;
        //padding: 20px;
        flex-direction: column;
        //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
        .title-factor {
          //height: 21px;
          font-size: 14px !important;
          font-weight: 700;
          color: #382e34 !important;
          margin-bottom: 10px !important;
          line-height: 20px;
          margin-top: 10px;
          font-family: Roboto;
        }
        .switcher-option-check {
          align-items: center;
          background-color: #ffffff;
          display: flex;
          font-size: 14px;
          position: relative;
          font-family: Roboto;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          button:disabled {
            border: 1px solid #6e7882 !important;
          }
          .ant-switch {
            min-width: 70px;
            height: 25px;
            background-color: #ffffff;
            border: 1px solid #6e7882;
            &:hover {
              border: 1px solid var(--generalButtonHover);
            }
          }
          .ant-switch-checked .ant-switch-inner {
            margin-right: 0px;
            margin-left: -18px;
            margin-top: 1px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
          }
          .ant-switch-inner {
            display: block;
            margin-right: -18px;
            margin-left: 0px;
            color: #6e7882;
            font-size: 16px;
            font-weight: 400;
            margin-top: 1px;
          }
          .ant-switch-checked {
            background-color:var(--generalButton);
          }
          .ant-switch::after {
            left: 3px;
            width: 19px;
            height: 19px;
            border: 1px solid #6e7882;
            top: 2px;
          }
          .ant-switch-checked::after {
            left: 40%;
            width: 19px;
            height: 19px;
            top: 2px;
            margin-left: 38px;
            border: 1px solid #6e7882;
          }
        }
      }

      .userSettings-footer {
        margin-top: 60px;
        margin-bottom: 90px;
        display: flex;
        align-items: center;
        .userSettings-footerLink {
          color: #6e7882;
          text-decoration: none;
          border: 1px solid #6e7882;
          height: 25px;
          width: 170px;
          align-items: center;
          line-height: 18.75px;
          text-align: center;
          padding: 2px 25px;
          border-radius: 25px;
          font-weight: 400;
          font-size: 16px;
          &:hover {
            color: var(--generalButtonHover);
            border: 1px solid var(--generalButtonHover);
          }
          &:active {
            color: var(--generalButtonActive);
            border: 1px solid var(--generalButtonActive);
            background-color: #FFFFFF !important;
          }
        }
        .newQuestionnaire__submitButtons {
          button {
            height: 25px;
            margin-top: 0px;
          }
          .button:last-of-type {
            margin-top: 0px;
          }
          .button-second {
            background-color: var(--generalButton);
            border: 1px solid #6e7882;
            border-radius: 20px;
            box-shadow: none;
            color: var(--generalButtonFont);
            cursor: pointer;
            font-family: Roboto;
            font-weight: 400;
            font-size: 16px;
            padding: 3px !important;
            text-transform: none;
            transition: none;
            line-height: 18.75px;
            &:hover {
              background-color: var(--generalButtonHover);
              border: 1px solid var(--generalButtonHover);
              //color: #FFFFFF;
            }
            &:active {
              background-color: var(--generalButtonActive);
              border: 1px solid var(--generalButtonActive);
              //color: #FFFFFF;
            }
          }
          .button-loading {
            background-color: #FFC457;
            border: 1px solid #eda41e;
            pointer-events: none;
            .anticon-loading {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and ( min-width: 603px ) {
  .userSettings-footer {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .newQuestionnaire__submitButtons button:last-of-type {
    margin-top: 0;
    margin-left: 0px;
  }
}
