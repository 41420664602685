@font-face {
  font-family: 'osprey_icons_v6';
  src: url('./files-font/osprey_icons_v6.eot?98559245');
  src: url('./files-font/osprey_icons_v6.eot?98559245#iefix') format('embedded-opentype'),
       url('./files-font/osprey_icons_v6.woff2?98559245') format('woff2'),
       url('./files-font/osprey_icons_v6.woff?98559245') format('woff'),
       url('./files-font/osprey_icons_v6.ttf?98559245') format('truetype'),
       url('./files-font/osprey_icons_v6.svg?98559245#osprey_icons_v6') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'osprey_icons_v6';
    src: url('../font/osprey_icons_v6.svg?98559245#osprey_icons_v6') format('svg');
  }
}
*/
 
 [class^="icon-v3-"]:before, [class*=" icon-v3-"]:before {
  font-family: "osprey_icons_v6";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-v3-aep:before { content: '\e800'; } /* '' */
.icon-v3-ai:before { content: '\e801'; } /* '' */
.icon-v3-aif:before { content: '\e802'; } /* '' */
.icon-v3-avi:before { content: '\e803'; } /* '' */
.icon-v3-bmp:before { content: '\e804'; } /* '' */
.icon-v3-cda:before { content: '\e805'; } /* '' */
.icon-v3-css:before { content: '\e806'; } /* '' */
.icon-v3-csv:before { content: '\e807'; } /* '' */
.icon-v3-doc:before { content: '\e808'; } /* '' */
.icon-v3-docx:before { content: '\e809'; } /* '' */
.icon-v3-eps:before { content: '\e80a'; } /* '' */
.icon-v3-fnt:before { content: '\e80b'; } /* '' */
.icon-v3-fon:before { content: '\e80c'; } /* '' */
.icon-v3-gif:before { content: '\e80d'; } /* '' */
.icon-v3-htm:before { content: '\e80e'; } /* '' */
.icon-v3-html:before { content: '\e80f'; } /* '' */
.icon-v3-jfif:before { content: '\e810'; } /* '' */
.icon-v3-jp2:before { content: '\e811'; } /* '' */
.icon-v3-jpeg:before { content: '\e812'; } /* '' */
.icon-v3-jpg:before { content: '\e813'; } /* '' */
.icon-v3-key:before { content: '\e814'; } /* '' */
.icon-v3-mid:before { content: '\e815'; } /* '' */
.icon-v3-midi:before { content: '\e816'; } /* '' */
.icon-v3-mo4:before { content: '\e817'; } /* '' */
.icon-v3-mov:before { content: '\e818'; } /* '' */
.icon-v3-mp3:before { content: '\e819'; } /* '' */
.icon-v3-mpa:before { content: '\e81a'; } /* '' */
.icon-v3-mpeg:before { content: '\e81b'; } /* '' */
.icon-v3-mpg:before { content: '\e81c'; } /* '' */
.icon-v3-odp:before { content: '\e81d'; } /* '' */
.icon-v3-ods:before { content: '\e81e'; } /* '' */
.icon-v3-odt:before { content: '\e81f'; } /* '' */
.icon-v3-ogg:before { content: '\e820'; } /* '' */
.icon-v3-otf:before { content: '\e821'; } /* '' */
.icon-v3-pdf:before { content: '\e822'; } /* '' */
.icon-v3-php:before { content: '\e823'; } /* '' */
.icon-v3-png:before { content: '\e824'; } /* '' */
.icon-v3-pps:before { content: '\e825'; } /* '' */
.icon-v3-ppt:before { content: '\e826'; } /* '' */
.icon-v3-ps:before { content: '\e827'; } /* '' */
.icon-v3-psd:before { content: '\e828'; } /* '' */
.icon-v3-pst:before { content: '\e829'; } /* '' */
.icon-v3-pttx:before { content: '\e82a'; } /* '' */
.icon-v3-rar:before { content: '\e82b'; } /* '' */
.icon-v3-rtf:before { content: '\e82c'; } /* '' */
.icon-v3-svg:before { content: '\e82d'; } /* '' */
.icon-v3-tex:before { content: '\e82e'; } /* '' */
.icon-v3-tif:before { content: '\e82f'; } /* '' */
.icon-v3-tiff:before { content: '\e830'; } /* '' */
.icon-v3-ttf:before { content: '\e831'; } /* '' */
.icon-v3-txt:before { content: '\e832'; } /* '' */
.icon-v3-wav:before { content: '\e833'; } /* '' */
.icon-v3-wma:before { content: '\e834'; } /* '' */
.icon-v3-wpl:before { content: '\e835'; } /* '' */
.icon-v3-xhtml:before { content: '\e836'; } /* '' */
.icon-v3-xlr:before { content: '\e837'; } /* '' */
.icon-v3-xls:before { content: '\e838'; } /* '' */
.icon-v3-xlsx:before { content: '\e839'; } /* '' */
.icon-v3-xml:before { content: '\e83a'; } /* '' */
.icon-v3-zip:before { content: '\e83b'; } /* '' */