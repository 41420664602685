.pageTitle-header {
  align-items: center;
  display: flex;
  height: 74px;
  justify-content: space-between;
  padding: 20px;
}
.pageTitle-header-update {
  height: 20px;
  font-size: 20px;
  color: #382e34;
  margin-bottom: 40px;
  padding: 0px;
}
.pageTitle-title {
  font-size: 20px;
  font-weight: 700;
}
.page-title-update {
  /*margin-right: 10px;*/
}
.help-location-update {
  margin-left: 10px;
  font-size: 20px;
}