.collectionCard__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32%;
  margin-bottom: 20px;
}

.collectionCard__header {
  border-bottom: 1px solid #E6E6E6;
  padding: 15px 50px 15px 15px;
  position: relative;
}

.collectionCard__title {
  color: #162737;
  font-size: 18px;
}

.collectionCard__description {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
}

.collectionCard__header .iconEdit {
  position: absolute;
  right: 15px;
  top: 15px;
}

.collectionCard__content {
  display: flex;
}

.collectionCard__alerts {
  border-right: 1px solid #E6E6E6;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  padding: 15px;
}

.collectionCard__text {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 15px;
  padding-top: 2px;
  text-transform: uppercase;
}

.collectionCard__loading {
  margin: 0 auto;
  height: 158px;
  line-height: 183px;
}

.collectionCard__high {
  color: #162737;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 25px;
  position: relative;
}

.collectionCard__high::before {
  background-color: #F44336;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 15px;
}

.collectionCard__medium {
  color: #162737;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 25px;
  position: relative;
}

.collectionCard__medium::before {
  background-color: #F0B400;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 15px;
}

.collectionCard__low {
  color: #162737;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-left: 25px;
  position: relative;
}

.collectionCard__low::before {
  background-color: #92C654;
  border-radius: 50%;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 15px;
}

.collectionCard__info {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  justify-content: flex-end;
  padding: 15px;
}

.collectionCard__objects {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 62px;
  margin-bottom: 20px;
}

.collectionCard__total {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.collectionCard__datasets {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-top: 10px;
}

.collectionCard__datasets strong {
  font-size: 18px;
}

.collectionCard__review-alerts {
  color: #1DCBCF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
}

.collectionCard__review-alerts::after {
  content: '';
  right: -12px;
  top: 5px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 6px;
  border-color: transparent transparent transparent #1DCBCF;
}

.collectionCard__review-files {
  color: #1DCBCF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
}

.collectionCard__review-files::after {
  content: '';
  left: 95px;
  top: 5px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 6px;
  border-color: transparent transparent transparent #1DCBCF;
}