.piaResult__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.piaResult__summaryWrapper {
  margin-top: 30px;
}

.piaResult__loading {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

@media print {
  .piaResult__page {
    padding: 0;
  }
  .piaResult__summaryWrapper {
    margin: 0;
  }
}