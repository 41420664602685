.questionnaireDashboard__accordionWrapper {
  margin-bottom: 20px;
}

.questionnaireTemplate__itemHeaderOpened {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 2px solid #D6D3D3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
  cursor: pointer;
}

.questionnaireTemplate__itemHeader {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  position: relative;
  margin-bottom: 14px;
  cursor: pointer;
}

.questionnaireTemplate__infoContainer {
  border-right: 1px solid #DCDCDC;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 70px 0 15px;
  min-height: 70px;
  padding-right: 20px;
  width: 100%;
}

.questionnaireTemplate__loadingItem {
  display: flex;
  align-self: center;
}

.questionnaireTemplate__currentVersionWrapper {
  text-align: left;
  font-size: 14px;
  padding-bottom: 15px;
}

.questionnaireTemplate__versionDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  min-width: 200px;
}

.questionnaireTemplate__versionDetailsInfo .questionnaireTemplate__activeFlag {
  margin: 8px 10px 0 0;
}

.questionnaireTemplate__versionDetailsInfo label {
  font-size: 14px;
}

.questionnaireTemplate__versionDetailsInfo {
  display: flex;
}

.questionnaireTemplate__name {
  color: #111C26;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
}

.questionnaireTemplate__questionGroup {
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
}

.questionnaireTemplate__description {
  align-items: center;
  background-color: #FCFCFD;
  border-bottom: 1px solid #D6D3D3;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 20px 40px 20px 20px;
  position: relative;
}

.questionnaireTemplate__versionsWrapper {
  align-items: flex-start;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D6D3D3;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  position: relative;
}

.questionnaireTemplate__moreVersionWrapper {
  display: flex;
  border-bottom: 1px solid #D6D3D3;
  width: 100%;
  height: 50px;
  padding: 18px 0 0 20px;
  font-size: 14px;
  color: #3DD8DB;
  cursor: pointer;
}

.questionnaireTemplate__moreVersionIconWrapper {
  margin-top: 5px;
}

.questionnaireTemplate__versionWrapper {
  border-bottom: 1px solid #D6D3D3;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.questionnaireTemplate__version {
  display: flex;
  justify-content: space-between;
}

.questionnaireTemplate__versionError {
  align-self: flex-end;
  margin-bottom: 12px;
  margin-right: 93px;
  color: red;
}

.questionnaireTemplate__info {
  display: flex;
  font-size: 14px;
  min-height: 50px;
  min-width: 590px;
  padding-left: 20px;
  justify-content: space-between;
}

.questionnaireTemplate__infoInactive {
  display: flex;
  font-size: 14px;
  min-height: 50px;
  min-width: 590px;
  padding-left: 20px;
  justify-content: space-between;
}

.questionnaireTemplate__infoItem {
  display: flex;
  align-self: center;
  flex-direction: row;
}

.questionnaireTemplate__actions {
  cursor: pointer;
  margin-right: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionnaireTemplate__activeFlag {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #80bb41;
  border: 1px solid #80bb41;
  margin: 3px 10px 0 0;
}

.iconPreviewActive {
  margin-right: 20px;
}

@media screen and (min-width: 768px) {
  .questionnaireTemplate__itemHeaderOpened {
    align-items: center;
    flex-direction: row;
  }

  .questionnaireTemplate__itemHeader {
    align-items: center;
    flex-direction: row;
  }

  .questionnaireTemplate__infoContainer {
    align-items: center;
    flex-direction: row;
  }

  .questionnaireTemplate__currentVersionWrapper {
    text-align: right;
    padding-bottom: 0;
  }
}