.subjectsList-name {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  margin-right: 10px;
  color: #7f7f7f;
}

.subjectsList-found {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 18px;
  width: 27%;
  margin-right: 10px;
  color: #7f7f7f;
}
.subjectsList-time {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 18px;
  width: 21%;
  margin-right: 10px;
  color: #7f7f7f;
}

.subjectsList-span {
  flex: none;
  width: 85%;
  color: #111C26;
  cursor: pointer;
  -webkit-flex: 1 1;
  flex: 1 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
  font-size: 16px;
  max-width: 50%;
}

.subjectsList-spanRedacting {
  flex: none;
  width: 85%;
  color: #111C26;
  cursor: pointer;
  -webkit-flex: 1 1;
  flex: 1 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
  font-size: 16px;
  max-width: 50%;
}

.SubjectList-infoContainer {
  -webkit-align-items: center;
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 70px 0 15px;
  min-height: 70px;
  padding-right: 20px;
  width: 100%;
  cursor: pointer;
}

.subjectList__block-title {
  display: -webkit-flex;
  display: flex;
  color: #737D87;
  font-weight: 700;
  margin: 30px 0 10px;
  font-family: 'Roboto';
  font-size: 12px;
}
.subjectList__block-title::before {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.subjectList__block-title::after {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.styleAccordion {
  width: 100%
}

.noArchive {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
  height: 7rem;
  align: center;
  justify-content: center;
  padding-top: 47px;
  display: flex;
}

.subject-list-info-archive {
  -webkit-align-items: center;
  align-items: center;
  background-color: #FCFCFD;
  border-bottom: 1px solid #D6D3D3;
  display: -webkit-flex;
  font-size: 14px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
  position: relative;
  .email-accordion-archive {
    width: 30%;
    margin-left: 40px;
  }
  .details-accordion-archive {
    width: 70%;
  }
  .archive-button-archive {
    margin-right: 42px;
  }
  .archive-button-delete {
    cursor: pointer;
    .icon-v14-delete {
      font-size: 20px;
      color: #6e7882;
    }
    .icon-v14-delete-active {
      font-size: 20px;
      color: #00d4d8;
    }
  }
}

.time-span-color {
  color: #C70039;
}
.time-span-color-yellow {
  color: #FFc300;
}
