@import "../assets/fonts/neuro-fonts/osprey_icons_v3.css";
@import "../components/Common/Bootstrap.min.css";
@import "../assets/fonts/neuro-fonts/osprey_icons_v5.css";
@import "../assets/fonts/neuro-fonts/osprey_icons_v6.css";
@import "../assets/fonts/neuro-fonts/osprey_icons_v8.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v2.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v3.css";
@import "../assets/fonts/neuro-fonts/new.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v1.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v5.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v6.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v8.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v9.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v10.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v13.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v14.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v16.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v19.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v21.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v22.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v23.css";
@import "../assets/fonts/neuro-fonts/smartbox_icons_v24.css";

$notification-background-red: #ffe9e9;
$notification-color-red: #f5222d;
$notification-background-green: #F9FFFA;
$notification-color-green: #3fa044;
$button-color-blue: #3DD8DB;
@media screen and (max-width: 720px) {
  .newQuestionnaire__page {
    padding: 15px 0 0
  }
}

.notification.notification-error {
  background-color: $notification-background-red;
  border: 2px solid $notification-color-red;
  color: $notification-color-red;
}

.notification .notification-success {
  background-color: $notification-background-green;
  border: 2px solid $notification-color-green;
  color: $notification-color-green;
}

span {
  .notification {
    padding: 10px 10px 10px 53px;
    line-height: 15px;
  }
}

div.nt-error {
  background-color: $notification-background-red;
  border: 2px solid $notification-color-red;
  color: $notification-color-red;

  .ant-notification-notice-message {
    color: $notification-color-red;
  }

  i {
    color: $notification-color-red;
  }
}

div.nt-success {
  background-color: $notification-background-green;
  border: 2px solid $notification-color-green;
  color: $notification-color-green;

  .ant-notification-notice-message {
    color: $notification-color-green;
  }

  i {
    color: $notification-color-green;
  }
}

.ant-notification-notice-description__link {
  text-decoration: none;
  color: $notification-color-red;
  font-weight: 600;

  &:hover {
    color: $notification-color-red;
  }

  &:focus {
    color: $notification-color-red;
  }
}

.button-small-red-empty {
  background-color: transparent;
  border: 1px solid $notification-color-red;
  color: $notification-color-red;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  width: auto;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 20px;
  text-transform: uppercase;

  &:hover {
    background-color: $notification-color-red;
    color: #FFFFFF !important;
  }
}

.button-small-red {
  background-color: transparent;
  border: 1px solid $notification-color-red;
  color: $notification-color-red;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  width: auto;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 20px;
  text-transform: uppercase;

  &:hover {
    background-color: $notification-color-red;
    color: #FFFFFF;
  }
}

.button-circle-red-empty {
  background-color: transparent;
  border: 1px solid $notification-color-red;
  color: $notification-color-red;
  border-radius: 25px 25px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  width: auto;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 7px;
  text-transform: uppercase;

  &:hover {
    background-color: $notification-color-red;
    color: #FFFFFF;
  }
}

.button-circle-blue {
  background-color: $button-color-blue;;
  border: 1px solid $button-color-blue;
  color: #FFFFFF;
  border-radius: 25px 25px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  width: auto;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 7px;
  text-transform: uppercase;

  &:hover {
    background-color: $button-color-blue;
    color: #FFFFFF;
  }
}

.button-circle-blue-empty {
  background-color: transparent;
  border: 1px solid $button-color-blue;
  color: $button-color-blue;
  border-radius: 25px 25px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  width: auto;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 7px;
  text-transform: uppercase;

  &:hover {
    background-color: $button-color-blue;
    color: #FFFFFF;
  }
}

.button-blue {
  width: auto;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;

  &:hover {
    background-color: #FFFFFF;
    color: $button-color-blue;
  }
}

.button-blue-empty {
  border: 1px solid $button-color-blue;
  background-color: #FFFFFF;
  border-radius: 20px;
  color: $button-color-blue;
  width: auto;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;

  &:hover {
    background-color: $button-color-blue;
    color: #FFFFFF;
  }
}

.button-red-empty {
  border: 1px solid $notification-color-red;
  background-color: #FFFFFF;
  border-radius: 20px;
  color: $notification-color-red;
  width: auto;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;

  &:hover {
    background-color: $notification-color-red;
    color: #FFFFFF;
  }
}

.pagination__box {
  &__header {
    border-bottom: 1px solid #382e34;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 20px;
    -ms-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    background-color: #fff;
    @media screen and (max-width: 1200px) {
      padding: 15px;
    }
    @media screen and (max-width: 768px) {
      padding: 10px;
    }

    &__total {
      font-size: 18px;
      font-weight: 300;
      position: relative;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
      @media screen and (max-width: 576px) {
        font-size: 14px;
      }
    }

    &__text {
      margin-bottom: 0;
      color: #737E89;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Roboto';
      margin-top: 10px;
      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
      @media screen and (max-width: 576px) {
        font-size: 12px;
      }
    }

    .pagination__wrapper {
      .pagination__nav {
        @media screen and (max-width: 768px) {
          padding: 6px 14px;
        }
        @media screen and (max-width: 576px) {
          padding: 4px 10px;
        }

        &--disabled {
          @media screen and (max-width: 768px) {
            padding: 6px 14px;
          }
          @media screen and (max-width: 576px) {
            padding: 4px 10px;
          }
        }
      }

      .pagination__item {
        @media screen and (max-width: 768px) {
          padding: 8px;
        }
        @media screen and (max-width: 576px) {
          padding: 6px;
        }
      }
    }
  }
}

.form-header {
  border-bottom: 1px solid #E6E6E6;
  padding: 20px;

  &__top {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .form-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.newSignUp-back {
  float:left;
  margin:-56px 0 0 25px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  a {
    color: #3DD8DB;
    &:hover {
      text-decoration: none !important;
      color: #3DD8DB;
    }
  }
}
.form-content {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 2%;

  .form-group {
    min-height: 1rem;
    margin-bottom: 20px;
    width: 100%;

    .checkbox-areas {
      background-color: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      display: inline-block;
      height: 26px;
      position: relative;
      width: 26px;
      margin-top: 0;
      margin-bottom: 0;

      .checkboxLabelChecked {
        margin-left: 2rem;
      }
    }

    textarea {
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      box-sizing: border-box;
      color: #555555;
      font-size: 18px;
      font-weight: 300;
      padding: 10px;
      width: 100%;

      &:focus {
        outline: 2px solid $button-color-blue;
      }
    }

    label {
      color: #555;
      display: block;
      font-size: 14px;
      margin-bottom: 10px;
    }

    input {
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      box-sizing: border-box;
      color: #555555;
      font-size: 18px;
      font-weight: 300;
      padding: 10px;
      width: 100%;

      &:focus {
        outline: 2px solid $button-color-blue;
      }
    }
  }
}

.form-footer {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
}

.pdf-modal-locker {
  height: 95vh;

  .ant-modal-content {
    height: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0 !important;
    .ant-modal-header {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #212121;
      .ant-modal-title {
        color: #ffffff;
        font-size: 14px;
      }
    }

    .ant-modal-close-x {
      width: 35px;
      height: 32px;
      line-height: 32px;
      color: #00ffff;
    }

    .ant-modal-body {
      padding: 0;
      height: 100%;
      padding-top: 30px;
      .custom-modal-body {
        overflow-y: auto;
        height: 100%;
        overflow-x: hidden;
      }
    }
  }
}

.pdf-modal {
  height: 99%;
  width: 67% !important;

  .align-text {
    white-space: pre-line;
    font-size: 18px;
    font-weight: bold;
    font-family: Roboto;
    padding-left: 20px;
  }
  .ant-modal-content {
    height: 100%;

    .ant-modal-close-x {
      width: 35px;
      height: 40px;
      line-height: 35px;
    }

    .ant-modal-body {
      height: 100%;
      padding-top: 30px;
      .custom-modal-body {
        overflow-y: auto;
        height: 100%;
      }
    }
  }
}

.redacting-modal-portrait {
  height: 99%;
  width: 1400px !important;

  .ant-modal-content {
    height: 100%;

    .ant-modal-close-x {
      line-height: 19px;
      height: 45px;
      width: 19px;
    }

    .ant-modal-body {
      height: 100%;
      padding-top: 15px;
      .custom-modal-body {
        overflow-y: auto;
        height: 100%;
      }
    }
  }
}

.redacting-modal-landscape {
  height: 99%;
  width: 1700px !important;

  .ant-modal-content {
    height: 100%;

    .ant-modal-close-x {
      line-height: 19px;
      width: 19px;
      height: 45px;
    }

    .ant-modal-body {
      height: 100%;
      padding-top: 15px;
      .custom-modal-body {
        overflow-y: auto;
        height: 100%;
      }
    }
  }
}

/**
  Common styles for page container
 */

.page-container {
  padding: 40px 40px 0;
  position: relative;

  .page-filter {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    &__header {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      &--filter-container {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;

        label, span {
          color: #6E7882;
          display: inline-block;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          font-weight: 400;
          padding-bottom: 5px;
          text-transform: uppercase;
        }
         .back-request {
           display: flex;
           //flex-direction: column;
           align-items: center;
           .icon-v21-back-1 {
             color: #382e34;
             font-size: 20px;
             margin-right: 10px;
             margin-top: 10px;
           }
           .icon-v21-back-active {
             color: #EDA41E;
             font-size: 20px;
             margin-right: 10px;
             cursor: pointer;
             margin-top: 10px;
           }
         }
        &__filter {
          display: flex;
          flex-direction: column;

          &--search-inactive {
            background: url(../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
            border: none;
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            box-sizing: border-box;
            color: #555555;
            font-size: 18px;
            font-weight: 300;
            padding: 10px 10px 10px 35px;
            height: 42px;
            width: 0;
            margin-right: 20px;

            &:focus {
              outline: 2px solid #3DD8DB;
              width: 93%;
            }
          }

          &--search-active {
            background: url(../assets/icon-search.png) 14px 13px no-repeat #FFFFFF;
            border: none;
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 #DCDCDC;
            box-sizing: border-box;
            color: #555555;
            font-size: 18px;
            font-weight: 300;
            padding: 10px 10px 10px 35px;
            height: 42px;
            margin-right: 20px;
          }
        }

        &__select {
          margin: 0 20px 0 0;
          display: flex;
          flex-direction: column;
          width: 10vw;
        }
      }


    }
  }
}

// Doc Locker Section global styles
.custom-checkbox {
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
    font-size: 1em;
    font-weight: 300;
    line-height: 2.14;
    color: black;
    display: flex;
    align-items: center;

    span[class^="icon-"] {
      margin-right: 8px;
    }

    .ant-checkbox + span {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: -3px;
    }
  }

  .ant-checkbox-disabled + span {
    color: initial;
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background: white;

      &:hover {
        border-color: #6E7882 !important;
      }

      &:focus {
        border-color: #6E7882 !important;
      }
    }
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6E7882;
    border-color: #6E7882 !important;

    &:hover {
      border-color: #6E7882 !important;
    }

    &:focus {
      border-color: #6E7882 !important;
    }

    &:after {
      border-color: white;
    }
  }

  .ant-checkbox-disabled.ant-checkbox-checked:after {
    border-color: #6E7882 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00d3d8;
    border-color: #00d3d8;
  }

  .ant-checkbox-checked:after {
    border-color: #00d3d8;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #00d3d8;
  }

  .ant-checkbox {
    outline: none;
    border-color: white;

    .ant-checkbox-input:hover {
      border: none;
      outline: none;
    }

    &:hover {
      outline: none;
      border: none;
    }

    .ant-checkbox-inner {
      border-radius: 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.custom-select {
  border-radius: 0 !important;
  border: none !important;
  height: 32px !important;

  .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  .ant-select-selection {
    border: none;
    border-radius: 0;
    background: white;
    border-bottom: 1px solid #00d3d8 !important;

    &__rendered {
      width: 90%;
      margin-left: 0;

      .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
        color: #495057 !important;
        width: 100%;
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    &:active {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    .ant-select-arrow {
      right: 0;
    }

    .ant-select-selection-selected-value {
      &:focus {
        outline: none !important;
      }
    }
  }
}

.custom-select.ant-select-disabled {
  color: inherit;
  background-color: white;

  .ant-select-selection {
    background: white;
  }
}

.ant-calendar {
  border-color: #00d3d8 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.ant-select-dropdown {
  border: 1px solid #00d3d8;
  border-radius: 0 !important;
}

.ant-select-dropdown-menu-item {
  overflow: visible !important;
  white-space: inherit !important;
}

.custom-radio {
  .ant-radio-wrapper {
    width: 100%;
    line-height: 2.14 !important;
    font-weight: 300 !important;
    font-size: 1em !important;
    display: flex !important;
    align-items: center !important;
    color: black !important;

    .ant-radio-checked .ant-radio-inner {
      border-color: #3DD8DB;
    }

    .ant-radio-disabled + span {
      color: initial;
    }

    .ant-radio-disabled {
      .ant-radio-inner:after {
        background-color: #6E7882;
      }
    }

    &:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: #3DD8DB;
    }

    .ant-radio-inner:after {
      background-color: #3DD8DB;
    }
  }
}

.custom-textarea {
  border-radius: 0;
  border-color: #3DD8DB;
  box-shadow: none;
  background-color: white;
  resize: vertical;
  line-height: 1;
  font-size: 1em;

  &:focus {
    border-color: #3DD8DB;
    outline: none;
  }

  &:disabled {
    border-color: #6E7882;
    color: initial;
  }
}

.ant-input-number-focused {
  box-shadow: none !important;
}

.custom-input-number {
  width: 100%;
  border: none !important;
  border-bottom: 1px solid #3DD8DB !important;
  border-radius: 0 !important;
  color: #495057 !important;

  .ant-input-number-input-wrap {
    outline: none;

    .ant-input-number-input {
      padding-left: 0 !important;
    }
  }
}

.custom-input {
  border-radius: 0 !important;
  border-color: #3DD8DB !important;
  box-shadow: none !important;
  background: white !important;

  &:disabled {
    border-color: #6E7882 !important;
    color: initial !important;
  }
}


.ant-calendar-picker {
  color: #495057;
  width: 100%;

  .ant-calendar-picker-icon {
    color: #495057;
    right: 1px;
  }

  .ant-calendar-picker-input {
    border: none;
    border-bottom: 1px solid #3DD8DB;
    border-radius: 0;
    padding-left: 0;
    color: #495057;
    outline: none;
    box-shadow: none !important;

    &::placeholder {
      color: #495057;
    }

    &:hover {
      border-color: #3DD8DB !important;
    }

    &:focus {
      border-color: #3DD8DB !important;
    }
  }
}

.popconfirm-content {
  padding: 5px;
  position: relative;

  .fa-times {
    position: absolute;
    top: -5px;
    right: -5px;
  }

  &__body {
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 180px;
    flex-wrap: wrap;

    span {
      width: 30px;
      height: 30px;
      font-size: 25px;
    }

    .empty-icon {
      width: 25px;
      height: 25px;
      margin-top: 6px;
    }
  }
}

.multitenancy {
  background-color: #FFFFFF;
  border-radius: 5px;
  height: auto;
  max-width: 700px;
  margin: 0 auto 50px;
  padding: 20px 10px 15px;
  width: 96%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  &__header {
    .formHeader-logo {
      border-bottom: 1px solid #ccc;
    }
    .logo-set-password {
      display: flex;
      height: 73px;
    }
  }
  &__body {
    padding-top: 20px;
    padding-bottom: 20px;
    h2 {
      text-align: center;
      font-size: 18px;
      padding-bottom: 10px;
    }
    p {
      text-align: center;
      font-size: 18px;
    }
    .login-btn {
      padding-bottom: 30px;
      padding-left: 30px;
      a {
        font-weight: bold;
        color: #1DCBCF;
        text-decoration: none;
        &:hover {
          color: #1DCBCF;
          font-style: normal;
        }
        &:focus {
          color: #1DCBCF;
        }
      }
    }
  }
}