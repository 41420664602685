.resources-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.resources-title {
  color: #737D87;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center
}

.resources-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 60px 0 50px;
}

.resources-containerBox {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 100px;
  padding-bottom: 20px;
  position: relative;
  width: 100%;
}

.resources-containerBox:last-of-type {
  margin-bottom: 0;
}

.resources-boxIconWhitepaper {
  left: 50%;
  margin-left: -67px;
  position: absolute;
  top: -40px;
}

.resources-boxIconBlog {
  left: 50%;
  margin-left: -59px;
  position: absolute;
  top: -40px;
}

.resources-boxIconWebinar {
  left: 50%;
  margin-left: -70px;
  position: absolute;
  top: -40px;
}

.resources-boxHeader {
  border-bottom: 1px solid #F2F2F2;
  padding: 20px 0
}

.resources-boxTitle {
  color: #162737;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 300;
  padding-top: 80px;
  text-align: center
}

.resources-boxButton {
  bottom: -15px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.resources-boxButton button {
  width: 150px;
}

.resources-item {
  border-bottom: 1px solid #F2F2F2;
}

.resources-item:last-of-type {
  border-bottom: none;
}

.resources-areasBox {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  position: relative;
}

.resources-areasHeader {
  border-bottom: 1px solid #F2F2F2;
  padding: 20px
}

.resources-areasTitle {
  color: #162737;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300
}

.resources-content {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.resources-block {
  padding: 0 20px;
  width: 100%;
}

.resources-block:first-of-type {
  border-right: 1px solid #F2F2F2;
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .resources-page {
    padding: 40px 40px 0;
  }

  .resources-container {
    flex-direction: row;
  }

  .resources-containerBox {
    margin-bottom: 0;
    padding-bottom: 0;
    width: 31%;
  }

  .resources-content {
    flex-direction: row;
  }

  .resources-block {
    width: 50%;
  }

  .resources-block:first-of-type {
    border-right: none;
    margin-bottom: 0;
  }
}