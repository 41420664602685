.progress-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.progress-value {
  display: block;
  font-weight: 500;
  margin: 0 auto;
}

.progress-navigation {
  display: none;
  flex: 1;
}

.progress-list {
  display: flex;
  justify-content: space-between;
}

.progress-answeredStatus {
  background-color: #DCDCDC;
  border-radius: 50%;
  cursor: pointer;
  height: 8px;
  text-indent: -9999px;
  transition: all .5s;
  width: 8px;
&:hover {
   transform: scale(1.4);
 }
}

.progress-skippedStatus {
  background-color: #F44336;
  border-radius: 50%;
  cursor: pointer;
  height: 8px;
  text-indent: -9999px;
  transition: all .5s;
  width: 8px;
&:hover {
   transform: scale(1.4);
 }
}

.progress-unansweredStatus {
  background-color: #AAAAAA;
  border-radius: 50%;
  cursor: pointer;
  height: 8px;
  text-indent: -9999px;
  transition: all .5s;
  width: 8px;
&:hover {
   transform: scale(1.4);
 }
}

.progress-notapplicableStatus {
  background-color: #AA00EE;
  border-radius: 50%;
  cursor: pointer;
  height: 8px;
  text-indent: -9999px;
  transition: all .5s;
  width: 8px;
&:hover {
   transform: scale(1.4);
 }
}

.progress-activeStatus {
  background-color: #3DD8DB;
  border-radius: 50%;
  cursor: pointer;
  height: 8px;
  position: relative;
  text-indent: -9999px;
  transition: all .5s;
  width: 8px;
&:hover {
   transform: scale(1);
 }
&::after {
   border: 1px solid #3DD8DB;
   border-radius: 50%;
   content: "";
   height: 12px;
   left: -3px;
   position: absolute;
   top: -3px;
   width: 12px;
 }
}

@media screen and (min-width: 768px) {
  .progress-wrapper {
    margin-bottom: 35px;
  }
  .progress-value {
    margin: 0 20px 0 0;
  }
  .progress-navigation {
    display: block;
  }
}