.redaction-colour {
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  background: #FFFFFF;
  margin: 134px 82px;
  padding: 30px 0px 0;
  .redaction-colour-page {

    &__content {
       .redaction-colour-header {
         display: flex;
         flex-direction: row;
         color: #382e34;
         align-items: center;

         .title-header {
           font-family: Roboto;
           font-style: normal;
           font-weight: 700;
           font-size: 20px;
           line-height: 20px;
           color: #382E34;
           letter-spacing: 0.5px;
         }
       }
      .content-redaction-colour-page {
        margin-top: 40px;
        .content-buttons-actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 20px;
          .action-edit {
            margin-right: 25px;
            color: #382E34;
          }
          .action-delete {
            margin-right: 25px;
            color: #382E34;
          }
          .action-edit:disabled {
            color: #D4D7DA;
          }
          .action-delete:disabled {
            color: #D4D7DA;
          }
          .icon-v17-add {
            color: #eda41e;
          }
          .icon-v17-edit-active {
            color: var(--generalButton);
            cursor:pointer;
          }
          .icon-v17-delete-active {
            color: var(--generalButton);
            cursor:pointer;
          }
          .icon-v17-add-active {
            color: var(--generalButton);
            cursor:pointer;
          }
        }

        .content-table-redaction-colour {
          margin-top: 30px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
          .list-process {
            display: flex;
            font-family: Roboto;
            font-style: normal;
            width: 100%;
            align-items: center;
            flex-direction: column;
            .row-list-process:hover {
              background-color: rgba(154,224,252,0.3);
            }
            .row-list-process {
              display: flex;
              justify-content: space-between;
              width: 100%;
              background-color: rgba(102,83,96,0.13);
              padding: 8px 10px 7px;
              height: 30px;
              align-items: center;
              margin-bottom: 20px;
              font-size: 14px;
              font-weight: 700;
              .content-name {
                width: 100%;
                display: flex;
                align-items: center;
                .name-first-active {
                  //color: #eda41e !important;
                  cursor: pointer !important;
                }
                .name-first {
                  font-weight: 700;
                  font-size: 14px;
                  color: #382e34;
                  line-height: 20px;
                  margin-left: 10px;
                  width: 100%;
                  display: flex;
                }
                .name-last {
                  font-weight: 400;
                  font-size: 14px;
                  color: #6e7882;
                  line-height: 16px;
                  margin-left: 10px;
                }
                .ant-checkbox-wrapper {
                  .ant-checkbox-inner {
                    border-radius: 0!important;
                    border:1px solid #6e7882;
                    &:after {
                      border-color:#eda41e;
                      background-color: transparent;
                    }
                    &:hover {
                      border-color:var(--generalIconFontHover);
                    }
                  }
                }
                .ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: var(--generalIconFontActive) !important;
                    &:after {
                      position: absolute !important;
                      display: table;
                      color:#FFFFFF;
                      border:none !important;
                      transform: rotate(0deg) scale(1) translate(-50%, -50%);
                      opacity: 1;
                      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                      content: "\e8ce" !important;
                      top: 8px !important;
                      left: 7px !important;
                      font-size: 12px !important;
                      font-family: "smartbox_icons_v19" !important;
                    }
                  }
                }
              }
              .icon-v14-arrow-bold {
                -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
                font-size: 18px;
                padding-right: 3px;
                padding-left: 10px;
                //position: absolute;
                //right: 0;
              }
            }
          }
          .content-empty {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .anticon {
              font-size: 60px;
              color: #D4D7DA;
            }
            .no-data {
              color: #D4D7DA;
              font-size: 14px;
              font-weight: 300;
            }
          }
        }

      }
    }
  }
  .redaction-colour-profiles-page {
    &__content {
      .redaction-colour-profiles-header {
        display: flex;
        flex-direction: row;
        color: #382e34;
        align-items: center;

        .title-header {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          color: #382E34;
          letter-spacing: 0.5px;
        }
        .icon-v14-question {
          margin-left: 10px;
          font-size: 20px;
          color: #382e34;
        }
        .icon-v24-disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
        .icon-v24-back-1 {
          margin-right: 10px;
          font-size: 20px;
          color: #382e34;
        }
        .icon-v24-back-active {
          margin-right: 10px;
          font-size: 20px;
          color: #eda41e;
        }
      }

      .redaction-colour-profiles-action {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        border-bottom: 1px solid #D4D7DA;
        padding-bottom: 10px;
        .button-refresh-redaction-colour {
          background-color: #FFFFFF;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #6e7882;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          height: 30px;
          margin-right: 20px;

          &:hover {
            background-color: #FFFFFF;
            border: 1px solid #FFC457;
            color: #FFC457;
          }

          &:active {
            background-color: #FFFFFF;
            border: 1px solid #EDA41E;
            color: #EDA41E;
          }
          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
            color:#FFFFFF;
          }
        }
        .button-save-redaction-colour {
          background-color: var(--generalButton);
          border: 1px solid var(--generalButtonFont);
          border-radius: 20px;
          box-shadow: none;
          color: var(--generalButtonFont);
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          height: 30px;
          &:hover {
            background-color: var(--generalButtonHover);
            color: var(--generalButtonFont);
            border: 1px solid #150010;
          }
          &:active {
            color: var(--generalButtonFont);
          }
          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
            color:#FFFFFF;
          }
        }
         .reset-profiles-colours {
           display: flex;
           align-items: center;
           .value-select-profile {
             font-family: Roboto;
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 20px;
             color: #382E34;
             letter-spacing: 0.5px;
             margin-right: 10px;
           }
           .select-profiles-colours {
             width: 300px;
             color: #6e7882;
             font-weight: 400;
             font-size: 14px;
             margin-right: 10px;
             .ant-select-selection {
               border-radius: 0px;
               font-size: 14px;
               height: 30px;
               border: 1px solid #382e34;
               font-weight: 400;
               box-shadow: none;
             }
             .ant-select-selection:hover {
               border-color: #EDA41E;
               border-right-width: 1px !important;
               .ant-select-arrow {
                 color: #eda41e;
               }
             }
             .ant-select-arrow:hover {
               color: #eda41e;
             }
           }
           .icon-v14-refresh-1 {
             font-size: 20px;
             color: #382E34;
           }
           .icon-v14-refresh-1:disabled {
             color: #D4D7DA;
           }
           .icon-v14-refresh-active {
             font-size: 20px;
             color: #eda41e;
           }
         }
        .icon-v24-save-1 {
          font-size: 20px;
          color: #382E34;
        }
        .icon-v24-save-1:disabled {
          color: #D4D7DA;
        }
        .icon-v24-save-active {
          font-size: 20px;
          color: #eda41e;
        }
      }

      .redaction-colour-profiles-content-loading {
        position: relative;
        .loading-redaction-colour-profiles {
          position: absolute;
          left: 42%;
          top: 150px;
          .ant-spin-dot {
            position: relative;
            display: inline-block;
            font-size: 30px;
            width: 1em;
            height: 1em;
          }
          .ant-spin-dot-item {
            position: absolute;
            display: block;
            width: 12px;
            height: 12px;
          }
          .ant-spin-text {
            font-size: 25px;
          }
        }
      }
      .redaction-colour-profiles-content {
        .redaction-colour-profiles-table {
           .profiles-content-list {
             display: flex;
             border-bottom: 1px solid #D4D7DA;
             width: 100%;
             padding-top: 10px;
             padding-bottom: 10px;
             .category-name-content {
               height: 30px;
               display: flex;
               align-items: center;
               width: 30%;
               .category-name {
                 font-family: Roboto;
                 font-weight: 700;
                 font-size: 14px;
                 line-height: 30px;
                 letter-spacing: 0.2px;
                 color: #382E34;
               }
               .data-picker-category {
                 margin-left: 10px;
                 display: flex;
                 .ant-popover-inner-content {
                   padding: 12px 16px;
                   color: rgba(0, 0, 0, 0.65);
                 }
                 .selected-color-category {
                   position: relative;
                   width: 80px;
                   height: 17px;
                   float: left;
                   cursor: pointer;
                   border: 1px solid #D4D7DA;

                 }
               }
             }
             .subCategory-list {
               display: flex;
               flex-direction: column;
               justify-content: flex-start;
               width: 100%;
               .subCategory-content {
                   height: 30px;
                 width: 100%;
                 display: flex;
                 .subCategory-name {
                   font-family: Roboto;
                   font-weight: 400;
                   font-size: 14px;
                   line-height: 30px;
                   letter-spacing: 0.2px;
                   color: #382E34;
                   width: 50%;
                 }
                 .data-picker-subcategory-content {
                   width: 30%;
                   display: flex;
                   align-items: center;
                   .data-picker-subCategory {
                     margin-right: 10px;
                     display: flex;
                     .selected-color-subCategory {
                       position: relative;
                       width: 80px;
                       height: 17px;
                       float: left;
                       cursor: pointer;
                       border: 1px solid #D4D7DA;
                     }
                   }
                    .input-color-picker {
                      width: 80px;
                      height: 25px;
                      border-radius: 0px;
                      border: 1px solid #382E34;
                    }
                 }
                 .input-annotation {
                   width: 200px;
                   height: 25px;
                   border-radius: 0px;
                   border: 1px solid #382E34;
                 }
               }
             }
           }
        }
      }
    }
  }
}

.color-picker-redaction {
  .ant-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.65);
  }
}

.new-redaction-colour-modal {
  .ant-modal-header {
    background: #66525f;
    height: 40px;
    padding: 0px 10px 0px 10px;
    position: relative;
    border-radius: 0px;
    border-bottom: none;
    color: transparent;
    display: flex;
  }
.ant-modal-header .ant-modal-title {
    color: #fff !important;
    font-family: Roboto !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    letter-spacing: 0.5px !important;
    line-height: 30px !important;
    display: flex;
    align-items: center;
  }
}