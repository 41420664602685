.page-container-update {
  padding: 30px 0px;
  margin:134px 82px 0px 82px;
}
.templateEmail-page {
  /*margin: 0px 0.5% ;*/
  //padding-top: 100px;
  .header-emails-templates {
    display: flex;
    align-items: center;
    .icon-v14-question {
      margin-left: 10px;
      font-size: 20px;
      color: #382e34;
    }
  }
  .templateEmail-box {
    /*background-color: #FFFFFF;*/
    border-radius: 5px;
    //box-shadow: 0 2px 4px 0 #DCDCDC;
    position: relative;
    //padding: 20px;
    //margin-top: 40px;
    .templateEmail-wrapper {
      div.ant-tabs-tab[aria-selected="true"] {
        border-right: 2px solid #00d3d8;
      }
    }
    .custom-top-tabs {
      div.ant-tabs-tab[aria-selected="true"] {
        border-right: none;
      }
    }
    .ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active {
      color: #00d3d8 !important;
    }
  }
  header {
    padding: 0px 0 40px 0;
    h1{
      font-size: 20px;
      font-weight: bold;
      font-family: 'Roboto';
      line-height: 20px;
    }
    h3 {
      margin-top: 10px;
    }
  }

  .ant-tabs-left-bar {
    .ant-tabs-nav {
      & > div {
        display: inline-block !important;
      }
      .ant-tabs-tab {
        //display: block;
        float: none;
        font-size: 14px !important;
        font-weight: 300 !important;
        color: #6e7882;
        line-height: 38px;
        margin: 0 3px 16px 0;
        padding: 8px 24px !important;

        &-active {
          border-bottom: none !important;
        }
      }
      .ant-tabs-ink-bar {
        visibility: visible;
      }
    }
    .ant-tabs-content {
      .ant-tabs-bar {
        border-bottom: 1px solid #e8e8e8;
        .ant-tabs-nav {
          & > div {
            display: inline-block !important;
          }
          .ant-tabs-tab {
            font-size: 16px;
            font-weight: 500;
            margin: 0 32px 2px 0;
            padding: 7px 16px;
          }
          .ant-tabs-ink-bar {
            visibility: hidden;
          }
        }
      }
    }
  }

  .button-blue {
    width: auto;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 14px;
    padding: 10px 30px;
    text-transform: uppercase;
  }

  .custom-top-tabs {
    .ant-tabs-content {
      height: 90%;
    }
  }

  .email-template {
    display: flex;
    height: 100%;
    .form-content {
      margin-top: 0;
    }
    .email-legend {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 84.5%;
      border: 1px solid #cccccc;
      color: #555;
      legend {
        font-weight: 600;
        text-align: center;
      }

      ul {
        margin: 3%;

        li {
          font-size: 1.2em;

          i {
            padding-right: 10px;
          }
        }
      }
      .icon-info-tooltip {
        margin-left: 5px;
      }
    }

    .form-content {
      .form-group {
        margin: 1% 0;
        display: flex;
        label {
          margin-top: 1%;
        }

        input {
          height: 43px;
        }
      }

      .form-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2% 0;

        .discard {
          border: none;
          color: #1dcbcf;
        }
      }

    }

  .ql-container {
    height: 26vh;
    strong {
      font-weight: 700;
    }
  }
}

.ant-tabs-nav {
  .ant-tabs-ink-bar {
    background-color: #1DCBCF !important;
  }
  .ant-tabs-tab {
    &:hover {
      color: #1DCBCF;
    }
  }
  .ant-tabs-tab-active {

    color: #1DCBCF;

    &:hover {
      color: #1DCBCF;
    }
  }
}
}