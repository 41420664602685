.sa_page_extern {
  width: 100%;
  height: auto;
  background: url("../../../assets/background.png") no-repeat top right #efefef;
  min-height: 100%;
  display: inline-block;
  -webkit-align-items: center;
  align-items: center;

  .externFile-page {
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 40px;
     .extern-file-upload-completed {
       height: 300px;
       font-family: Roboto;
       font-style: normal;
       font-stretch: normal;
       line-height: normal;
       letter-spacing: normal;
       text-align: center;
       justify-content: center;
       align-items: center;
       display: flex;
       .file-upload-completed {
         color: #000000;
         font-weight: bold;
         font-size: 30px;
       }
     }
    .externFile-box {
      background-color: #FFFFFF;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      position: relative;
      padding: 20px;
      margin-top: 40px;

        .externFile-header {
          padding: 20px;
          .externFile-header__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

        }
      .externFile-textForm {
        padding: 20px;
        .extern-file-content {
          color: #6E7882;
        }
        .externFile-text {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 20px;
          font-family: Roboto;
        }
      }

        .titleUpload {
          margin-left: 2%;
        }

        .file-upload-container {
          margin: 2% 0 2% 2%;
          width: 94%;
          padding-top: 20px;
          .ant-upload-picture-card-wrapper {
            display: flex;
            align-items: center;
          }
          .ant-upload.ant-upload-select-picture-card {
            margin-top: 20px;
          }
          .ant-upload-list-picture-card-container {
            float: left;
            width: 104px;
            height: 104px;
            margin: 0 8px 8px 0;
            display: contents;
          }
          h1 {
            color: #6E7882;
            font-weight: 500;
          }

        }
    }
  }
  .ant-upload-list-picture-card{
    float: none;
    padding-top: 20px;
  }
  .ant-upload-list{
    display: block;
  }
  .ant-upload-list-picture-card .ant-upload-list-item{
    display: inline-block;
    float: none;
  }
  .button-red{
    &--danger{
      height: auto;
      width: 100%;
    }
  }
  .externFile-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: Roboto;
  }

  .buttons {
    padding: 0 30px 10px 30px;
    display: flex;
    justify-content: space-between;
    .button-blue {
      width: 100% !important;
      height: 2.5rem;
      text-transform: uppercase;
      font-size: 18px;
      color: #00D4D8;
      border: 1px solid #00D4D8;

      &:hover {
        color: #ffffff;
        background-color: #00D4D8;
      }
    }
    .button-blue-extern-disabled {
      border: 1px solid #00D4D8 !important;
      &:hover {
        color: #00D4D8;
        background-color: #ffffff;
        border: 1px solid #00D4D8;
      }
    }
    .button-red-fill-disabled {
      width: 100% !important;
      height: 2.5rem;
      text-transform: uppercase;
      font-size: 18px;
      border-radius: 20px;
      padding: 10px 30px;
      color: #ffffff;
      border: 1px solid #DD4475;
      background-color: #DD4475;
      cursor: not-allowed;
      opacity: 0.6;
      &:hover {
        color: #ffffff;
        background-color: #DD4475;
        border: 1px solid #DD4475;
      }
    }

    .button-red-fill {
      width: 100% !important;
      height: 2.5rem;
      text-transform: uppercase;
      font-size: 18px;
      border-radius: 20px;
      padding: 10px 30px;
      color: #ffffff;
      border: 1px solid #DD4475;
      background-color: #DD4475;
      cursor: pointer;

      &:hover {
        color: #DD4475;
        background-color: #ffffff;
      }
    }
  }
}


.custom-modal-body {
  .ant-spin-spinning {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}