.custom-pagination {
  .button-control {
    float: left;
    padding-bottom: 10px;
    button {
      height: 25px;
      width: 40px;
      padding: 0;
      border-radius: 0;
      font-weight: 100;
      &:hover {
        background-color: #3ED8DB;
        border: solid 1px #3ED8DB;
        color: #fff;
      }
    }

    &__left {
      border-bottom-left-radius: 18.5px !important;
      border-top-left-radius: 18.5px !important;
      background: #fff;
      border: solid 1px rgba(110, 120, 130, 0.3) !important;
      color: #afb9c3;
      border-right:solid  0 !important;
    }

    &__right {
      border-bottom-right-radius: 18.5px !important;
      border-top-right-radius: 18.5px !important;
      background-color: #3ED8DB;
      border: solid 1px #3ED8DB;
      color: #fff;
      &:disabled{
        background: #fff;
        border: solid 1px rgba(110, 120, 130, 0.3) !important;
        color: #afb9c3;
      }
    }

    &__text {
      padding-right: 10px;
    }

    &__redact {

    }
  }
  .data-nr { 
    float:left; 
    margin-right: 20px;
  }
  .per-page { 
    float:right;
    width: 15%;
    text-align: right;
  }
  .clearfix { 
    clear: both;
  }
  .ant-select-selection {
    border: none;
    margin-left: 10px;
  }
  .ant-select .ant-select-selection .ant-select-arrow {
    margin-right: 0;
  }
  .ant-select .ant-select-selection .ant-select-selection__rendered {
    font-weight: 800;
  }
  strong {
    font-weight: 800;
  }
}