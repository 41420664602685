.disabled-close {
  .ant-modal-close-x {
    cursor: not-allowed;
  }
}
.content-update-download {
  height: 144px !important;
  .ant-modal-content {
    height: 144px !important;
  }
}
.import-modal-option-box {
  display: flex;
  width: 788px !important;
  height: 475px;
  font-family: Roboto;
  .ant-modal-content {
    width: 788px !important;
    height: 710px;
    border-radius: 0 !important;
  }
  .ant-modal-body {
    padding: 40px 30px 40px 40px;
  }
  .ant-modal-header {
    padding: 0px;
    //background-color: #665360;
    font-weight: 700;
    border: none;
    border-radius: 0;
  }
  .ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    line-height: 44px;
    font-size: 20px;
    color: #ffffff;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: #EDA41E;
  }
  .ant-radio .ant-radio-inner::after {
    //content: "\f00c" !important;
    content: " " !important;
    color: #ffffff !important;
    font-size: 9px !important;
    font-family: 'FontAwesome' !important;
    top: 2.7px;
    left: 3px;
    border: 4px solid #FFFFFF;
    border-radius: 50%;
    background-color: #FFFFFF;
    transition: none;
  }
  //.ant-radio-checked .ant-radio-inner {
  //  border-color: #637882;
  //  background-color: #3DD8DB;
  //}
  .ant-radio-checked .ant-radio-inner {
    border-color: #637882;
    background-color: #EDA41E;
    border-radius: 50%;
  }
  .content-header-modal-collect {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 10px;
    background-color: var(--subNavigationBackground);
    .title-header {
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
  .access-modal-download {
    .header-access-modal {
      display: flex;
      //flex-direction: column;
      //height: 60px;
      align-items: center;
      margin-bottom: 30px;
      justify-content: space-between;
      .content-download {
        display: flex;
        align-items: center;
      }
      .export-preview-setting {
        .action-collect-data {
          width: 216px;
          height: 25px;
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          cursor: pointer;
          padding: 3px 30px;
          border-radius: 30px;
          font-size: 14px;
          font-weight: 400;

          &:hover {
            background-color: #FFFFFF;
            border-color: #00D3D8;
            color: #00D3D8;
          }

          &:focus {
            border: 1px solid #00D3D8;
            background-color: #00D3D8;
            color: #FFFFFF;
          }

          &:active {
            border: 1px solid #00D3D8;
            background-color: #00D3D8;
            color: #FFFFFF;
          }
        }
        .action-collect-data-disabled {
          width: 216px;
          height: 25px;
          border: 1px solid #637882;
          background-color: #637882;
          color: #FFFFFF;
          box-shadow: none;
          cursor: not-allowed;
          opacity: 0.6;
          padding: 3px 30px;
          border-radius: 30px;
          font-size: 14px;
          font-weight: 400;
          &:hover {
            background-color: #637882;
            border-color: #637882;
            color: #FFFFFF;
          }

          &:focus {
            border: 1px solid #637882;
            background-color: #637882;
            color: #FFFFFF;
          }

          &:active {
            border: 1px solid #637882;
            background-color: #637882;
            color: #FFFFFF;
          }
        }
      }
      .icon-v14-radio-button-off {
        margin-left: 10px;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.65);
      }
      .icon-v14-question {
        margin-left: 10px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.65);
      }
      .title-download {
        color: #382e34;
        font-size: 20px;
        font-weight: 700;
        vertical-align: middle;
      }
      .title-choose {
        color: #382e34;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &__content-modal {
      display: flex;
      margin-left: 2px;
      .content-modal-option-file {
        .ant-radio-wrapper {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #6e7782;
          margin-left: 10px;
          display: flex;
          align-items: center;
          vertical-align: middle;
          line-height: 20px;
        }
        .content-files-types {
          height: 90px !important;
          .content-functionality-files-option {
            .text-option-redact {
              margin-top: 2px;
            }
          }
        }
        .content-format-options {
          .content-functionality-files-option {
            .format-options-download {
              .ant-checkbox-wrapper {
                font-size: 14px;
                font-weight: 400;
                color: #6e7782;
                align-items: center;
                vertical-align: middle;
                line-height: 20px;
                margin-bottom: 10px;
              }
            }
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 0% !important;
              border: 1px solid #6e7882;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              //background-color: var(--generalIconFontActive) !important;
              background-color: #EDA41E !important;
            }
            .ant-checkbox-inner:after {
              border-color: #ffffff !important;
            }
            .ant-checkbox-wrapper + .ant-checkbox-wrapper {
              margin-left: 0px;
            }
            .ant-checkbox-group {
              margin-left: 10px;
            }
          }
        }
        .content-format-files {
          height: 115px !important;
          .content-functionality-files-option {
            .text-option-file {
              margin-top: 2px;
            }
          }
        }
        .content-files-option {
          display: flex;
          margin-bottom: 30px;
          height: 60px;
          padding-top: 5px;
          .title-option {
            width: 216px;
            margin-right: 30px;
            font-size: 14px;
            font-weight: 700;
            color: #382E34;
            vertical-align: middle;
          }
          .content-functionality-files-option {
            width: 216px;
            margin-right: 30px;
            .text-option {
              span.ant-radio + * {
                padding-top: 2px;
              }
            }
          }
          .content-option-tag {
            display: flex;
            align-items: flex-end;
            width: 216px;
            .ant-select-selection-selected-value {
              width: 170px;
              display: flex !important;
              align-items: center;
            }
            .item-options-status-new {
              color: #000000 !important;
              font-weight: 500 !important;
              opacity: 0.8;
              padding: 5px 6px !important;
              display: flex !important;
              align-items: center !important;
              .anticon {
                margin-right: 4px;
                padding-left: 2px;
              }
            }
            .ant-select-selection--single {
              height: 30px;
              padding-left: 10px;
              padding-right: 10px;
            }
            .ant-select .ant-select-selection .ant-select-arrow {
              margin-right: 2px;
            }
            .ant-select {
              width: 216px;
              font-family: Roboto;
              font-weight: 400;
              font-size: 14px !important;
              color: #6e7782 !important;
              cursor: pointer;
            }
            .ant-select-selection {
              border: 1px solid #6e7882;
              border-radius: 3px;
            }
            .ant-select-disabled {
              cursor: not-allowed;
            }
            .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
              color: #6e7782;
              font-size: 14px;
            }
            .ant-select-selection__rendered {
              margin-left: 0px;
            }
          }
        }
        .content-format-option {
          display: flex;
          margin-bottom: 40px;
          height: 60px;
          padding-top: 5px;
          .title-option {
            width: 216px;
            margin-right: 30px;
            font-size: 14px;
            font-weight: 700;
            color: #382E34;
            vertical-align: middle;
          }
          .content-functionality-files-option {
            width: 216px;
            margin-right: 30px;
          }
          .content-option-tag {
            display: flex;
            align-items: flex-end;
            width: 216px;
            .ant-select {
              width: 216px;
            }
          }
        }
      }
    }
    .footer-buttons-action {
      display: flex;
      justify-content: space-between;
      height: 25px;
      .action-cancel-collect {
        width: 216px;
        height: 25px;
        border: 1px solid #6e7882;
        background-color: #FFFFFF;
        color: #6e7882;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 3px 30px;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          background-color: #6e7882;
          border-color: #6e7882;
          color: #FFFFFF;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      .action-collect-data:disabled {
        cursor: not-allowed !important;
        opacity: 0.6;
        &:hover {
          background-color: #00D3D8 !important;
          border-color: #00D3D8 !important;
          color: #FFFFFF !important;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
      .action-collect-data {
        width: 216px;
        height: 25px;
        border: 1px solid #6e7882;
        background-color: #EDA41E;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 3px 30px;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          background-color: #FFFFFF;
          border-color: #EDA41E;
          color: #EDA41E;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
    }
  }
  .access-modal-finish-download {
      //width: 788px;
     .content-progress-collection {
       display: flex;
       height: 30px;
       color: #6e7882;
       align-items: center;
       display: flex;
       width: 677px;
       justify-content: space-between;
       .collection-progress-text {
         font-size: 14px;
         font-weight: 700;
         font-family: Roboto;
         width: 216px;
         color: #382e34;
       }
       .ant-progress-status-success .ant-progress-bg {
         background-color: #00d4d8;
         height: 12px !important;
       }
       .ant-progress-line {
         width: 462px;
       }
       .ant-progress-inner {
         border: 1px solid #6e7882;
         margin-left: 5px;
         width: 440px;
         height: 12px;
       }
       .ant-progress-bg {
         background-color: #00d4d8;
         height: 10px !important;
       }
       .ant-progress-outer {
         padding-right: 0px;
         display: inline;
       }
       .ant-progress-text {
         float: left;
         color: #382e34;
         padding-top: 4px;
         padding-right: 10px;
         font-size: 14px;
         font-weight: 700;
         width: 44px;
         .anticon {
           font-size: 14px;
         }
       }
     }
    .content-collection-completed {
      display: flex;
      justify-content: space-between;
      .content-title-completed {
        width: 216px;
        .collection-completed {
          font-size: 14px;
          font-weight: 700;
          font-family: Roboto;
          color: #382e34;
          margin-right: 10px;
        }
        .icon-v12-radio-button-tick {
          color: #00d4d8;
        }
      }
      .action-collect-data-href-disabled {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .action-collect-data-href {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .action-collect-data-disabled {
        width: 216px;
        height: 25px;
        border: 1px solid #637882;
        background-color: #637882;
        color: #FFFFFF;
        box-shadow: none;
        cursor: not-allowed;
        opacity: 0.6;
        padding: 3px 30px;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          background-color: #637882;
          border-color: #637882;
          color: #FFFFFF;
        }

        &:focus {
          border: 1px solid #637882;
          background-color: #637882;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #637882;
          background-color: #637882;
          color: #FFFFFF;
        }
      }
      .action-collect-data {
        width: 216px;
        height: 25px;
        border: 1px solid #00D3D8;
        background-color: #00D3D8;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        cursor: pointer;
        padding: 3px 30px;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          background-color: #FFFFFF;
          border-color: #00D3D8;
          color: #00D3D8;
        }

        &:focus {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }

        &:active {
          border: 1px solid #00D3D8;
          background-color: #00D3D8;
          color: #FFFFFF;
        }
      }
    }
  }
}
.import-modal-option-box-finish {
  height: 144px !important;
  .ant-modal-content {
    height: 144px !important;
  }
}
.ant-select-dropdown .options-status-collection {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  color: #6e7782;
  opacity: 1;
  //padding: 5px 6px;
  padding-left: 10px;
}
//.options-status-collection:hover {
//  background-color: #9AE0FC !important;
//}