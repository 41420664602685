
.workflow-template-page-new2 {
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  //width: 100%;
  background: #FFFFFF;
  margin: 134px 82px !important;
  padding: 30px 0px 0px !important;
  &__content {
    .content-workflow-template-page {
      .content-buttons-actions {
        .icon-v17-copy-active {
          color: var(--generalButton) !important;
        }
        .icon-v17-delete-active{
          color: var(--generalButton) !important;
        }
        .icon-v17-edit-active {
          color: var(--generalButton) !important;
        }
        .icon-v17-add-active {
          color: var(--generalButton) !important;
        }
      }
    }
  }
}
.workflow-template-page {
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  //width: 100%;
  background: #FFFFFF;
  margin: 134px 82px;
  padding: 30px 0px 0;
  //margin-top: 100px;
  //padding: 70px 40px 0;

  .workflow-builder-ai-page {
    .workflow-builder-ai-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      align-content: center;
      .title-builder-ai-header {
        display: flex;
        width: 100%;
        align-items: center;
        .icon-v17-back-1 {
          margin-right: 10px;
          font-size: 20px;
          color: #382e34;
        }
        .icon-v21-back-active {
          margin-right: 10px;
          font-size: 20px;
          color: var(--generalIconFontHover);
        }
        .title-ui {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          color: #382e34;
        }
      }
      .action-header-builder-ai {
        display: flex;
        justify-content: flex-end;
        width: 80%;

        .button-save-template {
          background-color: var(--generalButton);
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: var(--generalButtonFont);
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          margin-left: 40px;
          height: 26px;

          &:hover {
            background-color: var(--generalButtonHover);
            border: 1px solid var(--generalButtonHover);
            //color: #FFFFFF;
          }

          &:active {
            background-color: var(--generalButtonActive);
            border: 1px solid var(--generalButtonActive);
            //color: #FFFFFF;
          }

          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
          }
        }

        .button-cancel-template {
          background-color: #FFFFFF;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #6e7882;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          height: 26px;
          &:disabled {
            background-color: #d4d7da;
            border: 1px solid var(--generalButtonHover);
            color: #FFFFFF;
            &:hover {
              background-color: #d4d7da;
              border: 1px solid var(--generalButtonHover);
              color: var(--generalButtonHover);
            }
          }
          &:hover {
            background-color: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
            color: var(--generalButtonHover);
          }

          &:active {
            background-color: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
            color: var(--generalButtonActive);
          }
        }
      }
    }

    .workflow-builder-ai-content {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      width: 100%;
      .workflow-builder-ai-left {
        display: flex;
        width: 220px;
        min-height:25em;
        border: 1px solid #382e34;
        padding-top: 10px;
        margin-bottom: 20px;
        .form-workflow-content {
          width: 100%;
          .content-steps-workflow {
            span {
              margin-left: 10px;
            }
             .content-internal-email {
               display: flex;
               flex-direction: column;
               border-top: 1px solid #382e34;
               margin-top: 10px;
               margin-left: 0px;
               padding-left: 10px;
               width: 219px;
               .header-internal {
                 font-weight: 700;
                 margin-bottom: 5px;
                 margin-top: 5px;
                 font-size: 14px;
               }
               span {
                 margin-left: 0px;
               }
               span:last-child {
                 //font-weight: 500;
                 &:hover {
                   color:#eda41e;
                 }
               }
             }
          }
          .ant-list-bordered {
            border: none;
            border-radius: 0px;
            //background-color: #D4D7DA;
          }
          .ant-list-split .ant-list-header {
            //border-bottom: 1px solid #382e34;
          }
          .ant-list-bordered .ant-list-header {
            padding: 2px 0px;
            font-size: 14px;
            font-family: Roboto;
            color: #382e34;
            font-weight: 700;
            background: #FFF;
            border-bottom: none;
            margin-left: 10px;
            line-height: 20px;
          }
          .ant-list-bordered .ant-list-item {
            padding: 2px 20px 2px 0px;
            font-size: 12px;
            font-family: Roboto;
            color: #382e34;
            font-weight: 400;
            border-bottom: none;
            cursor: default;
            height: 30px;
          }
          .ant-list-bordered .ant-list-item:not(:last-child):hover {
            color: var(--generalIconFontHover);
          }
        }
          .workflow-steps {
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #382e34;
          }
      }
      .workflow-builder-ai-right {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        width: 80%;
        .page-builder-content {
          display: flex;
          margin-top: 20px;
          .form-builder-content {
            display: flex;
            width: 100%;
            flex-direction: column;
            .header-component {
              margin-left: 10px;
              .icon-v19-delete {
                font-size: 14px;
                color: #382e34;
              }

              .icon-v19-delete-active {
                font-size: 14px;
                color: #EDA41E;
              }
            }
            .component-form {
              display: flex;
              font-family: Roboto;
              height: auto;
              align-items: center;
              margin-left: 0px;
              background-color: #F5F5F5;
              margin-bottom: 20px;
              padding-bottom: 10px;
              padding-top: 10px;
              border: none;
              .component-content-web-form {

              }
              .component-content-new {
                //align-items: center;
                .header-component {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-left: 10px;
                  .header-title-collector {
                    //margin-left: 10px;
                  }
                  .header-title {
                    margin-bottom: 0px;
                  }
                }
                .content-disclosure {
                  display: flex;
                  width: 100%;
                  .icon-v19-delete-disabled {
                    font-size: 14px;
                    color: #d4d7da !important;
                    cursor: default;
                    margin-left: 5px;
                    margin-right: 5px;
                  }
                  .icon-v19-delete {
                    margin-top: 5px;
                    font-size: 14px;
                    margin-left: 5px;
                    margin-right: 5px;
                  }
                  .icon-v19-delete-active {
                    margin-top: 5px;
                    color: var(--generalIconFontHover);
                    font-size: 14px;
                    margin-left: 5px;
                    margin-right: 5px;
                  }
                  .contain-disclosure {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    .title-contain {
                      width: 10%;
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 20px;
                      color: #382e34;
                    }
                    .contain-email-to {
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      .select-form {
                        width: 50%;
                        color: #6e7882;
                        font-weight: 400;
                        font-size: 14px;
                        margin-right: 10px;
                        .ant-select-selection {
                          border: 1px solid #382e34;
                          border-radius: 0;
                        }
                        .ant-select-selection:hover {
                          border: 1px solid #EDA41E !important;
                          border-right-width: 1px !important;
                        }
                      }
                    }
                    .contain-result-url {
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                      .select-form {
                        width: 100%;
                        color: #6e7882;
                        font-weight: 400;
                        font-size: 14px;
                        margin-right: 10px;
                        .ant-select-selection {
                          border: 1px solid #382e34;
                          border-radius: 0;
                        }
                        .ant-select-selection:hover {
                          border: 1px solid #EDA41E !important;
                          border-right-width: 1px !important;
                        }
                      }
                    }
                    .contain-variables {
                      display: flex;
                      .default-variables {
                        width: 10%;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #6e7882;
                        margin-top: 5px;
                      }
                      .list-emails {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .content-select-email {
                          display: flex;
                          flex-direction: row;
                          align-content: center;
                          justify-content: space-between;
                          align-items: center;
                          width: 100%;
                          margin-bottom: 10px;
                          .content-result-url {
                            height: 30px;
                            padding-top: 5px;
                          }
                          .name-list {
                            width: 20%;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: #382e34;
                          }
                          .select-form-disabled {
                            width: 60%;
                            color: #6e7882;
                            font-weight: 400;
                            font-size: 14px;
                            margin-right: 10px;
                            .ant-select-selection {
                              border: 1px solid #382e34;
                              border-radius: 0;
                            }
                            .ant-select-selection:hover {
                              border: 1px solid #382e34 !important;
                              border-right-width: 1px !important;
                            }
                          }
                          .select-form {
                            width: 60%;
                            color: #6e7882;
                            font-weight: 400;
                            font-size: 14px;
                            margin-right: 10px;
                            .ant-select-selection {
                              border: 1px solid #382e34;
                              border-radius: 0;
                            }
                            .ant-select-selection:hover {
                              border: 1px solid #EDA41E !important;
                              border-right-width: 1px !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .component-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                .content-form-variables {
                  margin-left: 30px;
                  margin-top: 10px;
                  .variables-active {
                    margin-right: 10px;
                    color: #6e7882;
                    font-weight: 400;
                    font-size: 12px;
                  }
                  .variables-default {
                    margin-right: 10px;
                    color: #6e7882;
                    font-weight: 400;
                    font-size: 12px;
                  }
                  .variables {
                    margin-right: 10px;
                    color: #382e34;
                    font-weight: 400;
                    font-size: 12px;
                  }
                }
                .select-content-internal {
                  .content-internal {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    flex-direction: column;
                    .contain-internal-email {
                      width: 100%;
                      display: flex;
                      margin-top: 10px;
                      .select-email {
                        width: 97%;
                        color: #6e7882;
                        font-weight: 400;
                        font-size: 14px;
                        margin-right: 10px;

                        .ant-select-selection {
                          border: 1px solid #382e34;
                          border-radius: 0;
                        }

                        .ant-select-selection:hover {
                          border: 1px solid #EDA41E !important;
                          border-right-width: 1px !important;
                        }
                      }
                    }
                    .contain-internal {
                      width: 100%;
                      display: flex;
                      margin-top: 10px;
                      .select-form {
                        width: 100%;
                        color: #6e7882;
                        font-weight: 400;
                        font-size: 14px;
                        margin-right: 10px;

                        .ant-select-selection {
                          border: 1px solid #382e34;
                          border-radius: 0;
                        }

                        .ant-select-selection:hover {
                          border: 1px solid #EDA41E !important;
                          border-right-width: 1px !important;
                        }
                      }
                      .select-form.select-form-update {
                        margin-right: 6px;
                        width: 90%;
                      }

                      .icon-v17-add {
                        color: var(--generalButton);
                      }

                      .icon-v17-add-active {
                        color: var(--generalIconFontHover);
                      }
                      .icon-v16-minus-active {
                        color: #6e7882;
                      }
                      .icon-v16-minus {
                        color: #6e7882;
                      }
                    }
                  }
                }
                .select-content {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  .icon-v19-delete-disabled {
                    font-size: 14px;
                    color: #d4d7da !important;
                    cursor: default;
                    margin-left: 5px;
                    margin-right: 5px;
                  }
                  .icon-v19-delete {
                    font-size: 14px;
                    color: #382e34;
                    margin-left: 5px;
                    margin-right: 5px;
                  }

                  .icon-v19-delete-active {
                    font-size: 14px;
                    color: var(--generalIconFontHover);
                    margin-left: 5px;
                    margin-right: 5px;
                  }

                  .select-form {
                    width: 100%;
                    color: #6e7882;
                    font-weight: 400;
                    font-size: 14px;
                    margin-right: 10px;
                    .ant-select-selection {
                      border: 1px solid #382e34;
                      border-radius: 0;
                    }
                    .ant-select-selection:hover {
                      border: 1px solid #EDA41E !important;
                      border-right-width: 1px !important;
                    }
                  }
                }

                .content-variables-web-email {
                  .content-active-variables-email {
                    .variables-email {
                      margin-top: 0px !important;
                    }
                    .content-select-email {
                      flex-direction: column !important;
                      align-items: flex-start !important;

                      .content-select-web-email {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-top: 10px;

                        .header-title {
                          font-size: 12px;
                          font-family: Roboto;
                          color: #382e34;
                          font-weight: 600;
                          margin-bottom: 10px;
                        }
                        .contain-web-email {
                          display: flex;
                          flex-direction: row;
                          //align-items: center;
                          width: 100%;
                           .content-actions-completion-notification {
                             display: flex;
                             flex-direction: row;
                             //align-items: center;
                             width: 40%;
                             .ant-checkbox + span {
                               font-size: 12px;
                               font-family: Roboto;
                               color: #382e34;
                               font-weight: 700;
                             }
                             .ant-checkbox-wrapper .ant-checkbox-inner {
                               border-radius: 0% !important;
                               border: 1px solid #6e7882;
                             }
                             .ant-checkbox-checked .ant-checkbox-inner::after {
                               border: 1px solid #fff;
                               border-top: 0;
                               border-left: 0;
                               top: 46%;
                               left: 19%;
                             }
                             .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
                               border-color: #EDA41E;
                             }
                             .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
                               border-color: #FFFFFF;
                               background-color: #FFFFFF;
                               color: #FFFFFF;
                               border: none !important;
                               border-top: 0;
                               border-left: 0;
                               -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                               -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                               transform: rotate(0deg) scale(1) translate(-50%, -50%);
                               opacity: 1;
                               -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                               transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                               content: '\e8ce' !important;
                               top: 7px !important;
                               left: 7px !important;
                               font-size: 12px !important;
                               font-family: 'smartbox_icons_v19' !important;
                             }
                             .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
                               background-color: var(--generalIconFontActive) !important;
                               border: 1px solid #6e7882;
                             }
                             .ant-checkbox-wrapper {
                               width: 100%;
                             }
                             .actions-select-completion-notification {
                                  display: flex;
                               width: 100%;
                               flex-direction: column;
                               .select-form-update {
                                 width: 180px !important;
                                 margin-bottom: 10px;
                               }
                             }
                           }
                        }
                      }
                    }
                  }
                }
                .content-variables-email {
                  margin-top: 20px;
                  margin-left: 30px;
                  .content-active-variables-email {
                    display: flex;
                     .variables-email {
                       margin-right: 10px;
                       color: #6e7882;
                       font-weight: 400;
                       font-size: 12px;
                       //margin-left: 20px;
                       margin-top: 8px;
                     }
                    .list-emails {
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      .content-select-email {
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 10px;
                        .name-list {
                          width: 20%;
                          font-family: Roboto;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 20px;
                          color: #382e34;
                        }
                        .select-form {
                          width: 60%;
                          color: #6e7882;
                          font-weight: 400;
                          font-size: 14px;
                          margin-right: 10px;
                          .ant-select-selection {
                            border: 1px solid #382e34;
                            border-radius: 0;
                          }
                          .ant-select-selection:hover {
                            border: 1px solid #EDA41E !important;
                            border-right-width: 1px !important;
                          }
                        }
                      }
                    }
                  }
                  .variables-email-default {
                    margin-right: 10px;
                    color: #6e7882;
                    font-weight: 400;
                    font-size: 12px;
                    //margin-left: 20px;
                  }
                }
              }
              .component-move {
                width: 3%;
                display: flex;
                justify-content: center;

                .icon-v211-grid-view-1:hover {
                  font-size: 14px;
                  color: #EDA41E;
                }

                .icon-v211-grid-view-1 {
                  font-size: 14px;
                  color: #382e34;
                }
              }
            }
            .component-form.component-form-internal {
              margin-left: 30px;
            }
          }
        }
         .template-workflow-box {
           display: flex;
           flex-direction: column;
           border: 1px solid #382e34;
           padding: 10px 10px 20px 10px;
           background-color: #FFC457;
           .workflow-box {
             font-family: Roboto;
             font-style: normal;
             font-weight: 700;
             font-size: 14px;
             line-height: 20px;
             color: #382e34;
             margin-bottom: 10px;
           }

           .workflow-box-all-action {
             display: flex;
             align-items: center;
             justify-content: space-between;
             width: 100%;
           .action-configuration {
             display: flex;
             align-items: center;

             .workflow-configuration {
               font-family: Roboto;
               font-style: normal;
               font-weight: 400;
               font-size: 14px;
               line-height: 20px;
               color: #382e34;
               margin-right: 5px;
             }

             .select-workflow {
               width: 150px;
               color: #6e7882;
               font-weight: 400;
               font-size: 14px;
               margin-right: 10px;
               .ant-select-selection{
                 border-color: #382e34;
                 border-radius: 0!important;
                 background-color: #FFC457;
                 color: #382e34;
                 padding-left: 3px;
               }
               .ant-select-selection:hover{
                 border-color: #382e34;
               }
               .ant-select-arrow {
                 color: #382e34;
                 margin-right: 0px !important;
               }
             }
           }

           .action-configuration-workflow {
             display: flex;
             align-items: center;

             .automations {
               font-family: Roboto;
               font-style: normal;
               font-weight: 400;
               font-size: 14px;
               line-height: 20px;
               color: #382e34;
               margin-right: 10px;
             }
             .ant-checkbox-group {
               .ant-checkbox-group-item.ant-checkbox-wrapper {
                 span:not(:first-child){
                   font-family: Roboto;
                   font-style: normal;
                   font-weight: 400;
                   font-size: 14px;
                   line-height: 20px;
                   color: #382e34;
                   margin-right: 10px;
                   padding-left: 0px !important;
                 }
               }
             }

             .ant-checkbox-wrapper .ant-checkbox-inner {
               border-radius: 0px;
               border: 1px solid #637882;
               border-width: 1px;
             }

             .ant-checkbox-checked .ant-checkbox-inner::after {
               position: absolute !important;
               display: table;
               border: none !important;
               border-top: 0;
               border-left: 0;
               -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
               transform: rotate(0deg) scale(1) translate(-50%, -50%);
               opacity: 1;
               transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
               content: "\e8ce" !important;
               color: #FFFFFF;
               top: 7px !important;
               left: 7px !important;
               font-size: 12px !important;
               font-family: smartbox_icons_v19;
             }

             .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
               background-color: #000000 !important;
               border-width: 1px;
               border-color: #6e7882;
             }
           }
         }
         }
      }
    }
  }
  &__content {
    .workflow-template-header {
      display: flex;
      flex-direction: row;
      color: #382e34;
      align-items: center;

      .title-header {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
      }
    }

    .content-workflow-template-page {
      margin-top: 40px;
      .content-buttons-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 20px;
        .action-edit {
          margin-right: 25px;
        }
        .action-delete {
          margin-right: 25px;
        }
        .icon-v17-add {
          color: var(--generalButton);
        }
        .icon-v17-edit-active {
          color: var(--generalButton);
          cursor:pointer;
        }
        .icon-v17-delete-active {
          color: var(--generalButton);
          cursor:pointer;
        }
        .icon-v17-add-active {
          color: var(--generalButton);
          cursor:pointer;
        }
        .icon-v17-copy-active {
          color: var(--generalButton);
          cursor:pointer;
        }
      }

      .content-table-workflow-template {
        margin-top: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        .list-process {
          display: flex;
          font-family: Roboto;
          font-style: normal;
          width: 100%;
          align-items: center;
          flex-direction: column;
          .row-list-process:hover {
            background-color: rgba(154,224,252,0.3);
          }
          .row-list-process {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: rgba(102,83,96,0.13);
            padding-left: 10px;
            height: 30px;
            align-items: center;
            margin-bottom: 20px;
            .content-name {
              .name-first-active {
                //color: #eda41e !important;
                cursor: pointer !important;
              }
              .name-first {
                font-weight: 700;
                font-size: 14px;
                color: #382e34;
                line-height: 20px;
                margin-left: 10px;
              }
              .name-last {
                font-weight: 400;
                font-size: 14px;
                color: #6e7882;
                line-height: 16px;
                margin-left: 10px;
              }
              .ant-checkbox-wrapper {
                .ant-checkbox-inner {
                  border-radius: 0!important;
                  border:1px solid #6e7882;
                  &:after {
                    border-color:#eda41e;
                    background-color: transparent;
                  }
                  &:hover {
                    border-color:var(--generalIconFontHover);
                  }
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: var(--generalIconFontActive) !important;
                  &:after {
                    position: absolute !important;
                    display: table;
                    color:#FFFFFF;
                    border:none !important;
                    transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    opacity: 1;
                    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                    content: "\e8ce" !important;
                    top: 8px !important;
                    left: 7px !important;
                    font-size: 12px !important;
                    font-family: "smartbox_icons_v19" !important;
                  }
                }
              }
            }
            .icon-v14-arrow-bold {
              -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
              font-size: 18px;
              padding-right: 3px;
              padding-left: 10px;
              //position: absolute;
              //right: 0;
            }
          }
        }
        .content-empty {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          .anticon {
            font-size: 60px;
            color: #D4D7DA;
          }
          .no-data {
            color: #D4D7DA;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.new-workflow-modal {
  width: 790px !important;
  height: 200px;
  box-shadow: 2px 14px 30px rgba(21,0,16,0.3);
  color: #382e34 !important;
  .content-header-modal {
    display: flex;
    justify-content: space-between;
    .icon-v21-close-black {
      font-size: 16px;
      color: #FFFFFF;
    }
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-content {
    box-shadow: none !important;
    border-radius: 0px !important;
    border: none !important;
  }
  .ant-modal-header {
    background: #66525f;
    height: 40px;
    padding: 10px;
    position: relative;
    border-radius: 0px;
    border-bottom: none;
    color: transparent;

    .ant-modal-title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 20px;
    }
  }
  .new-process-section {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    .new-process-form {
      .process-modal-line {
        label {
          margin: 10px 0;
          font-size: 14px;
          font-weight: 600;
          color: #382e34;
        }
        .ant-input {
          border-radius:0px;
          &:focus {
            border:1px solid #eda41e;
            box-shadow: none;
          }
          &:hover {
            border:1px solid #eda41e;
            box-shadow: none;
          }
        }
      }
      .process-detail-line {
        margin-top: 10px;
      }
      .content-buttons-modal {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;
        .action-save:disabled {
          background-color: #D4D7DA;
          color: #FFFFFF;
          width: 180px;
          height: 25px;
          //border: 1px solid #6e7882 !important;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;
          &:hover {
            background-color: #D4D7DA;
            color: #FFFFFF;
            //border: 1px solid #6e7882 !important;
            cursor: default;
          }
        }
        .action-cancel {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: #FFFFFF;
          color: #6e7882;
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color: #FFFFFF;
            border-color: var(--generalButtonHover);
            color: var(--generalButtonHover);
          }

          &:focus {
            border: 1px solid #6e7882;
            background-color: #FFFFFF;
            color: #6e7882;
            -webkit-box-shadow: none;
            box-shadow:none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: #FFFFFF;
            color: var(--generalButtonActive);
          }
        }
        .action-save {
          width: 180px;
          height: 25px;
          border: 1px solid #6e7882;
          background-color: var(--generalButton);
          color: var(--generalButtonFont);
          box-shadow: none;
          cursor: pointer;
          padding: 0px 30px;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
          alignment: center;
          vertical-align: middle;
          line-height: 18.75px;

          &:hover {
            background-color: var(--generalButtonHover);
            border:1px solid var(--generalButtonHover);
            //color: #FFFFFF;
          }

          &:focus {
            border: 1px solid #6e7882;
            background-color: #EDA41E;
            color: #FFFFFF;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          &:active {
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
            //color: #FFFFFF;
          }
        }
        .create-process {
          width: 100px;
        }
      }
    }
  }
}