
.document-note-page {
  left: 0;
  top: 0;
  position: fixed;
  z-index: 500;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: #382e3465;
  .document-note-page-container {
    background: white;
    min-width: 650px;
    min-height: 300px;
    width: 32vw;
    height: 60vh;
    box-shadow: 2px 14px 30px rgba(21, 0, 16, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 14vh;
    margin-left: 15vh;
    position: absolute;
    z-index: 4;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #BCBCBC;
      border-bottom: 1.5px solid #E9EAED;
      &--text {
        width: 100%;
        padding: 15px 35px;
        border-right: 1.5px solid #E9EAED;
      }
      &--close-icon {
        padding: 15px;
      }
    }
    &__content {
      height: 100%;
      display: flex;
      .quill {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1.5px solid #E9EAED;
      }
      .ql-container.ql-snow {
        border: none;
        display: flex;
        flex-direction: column;
      }
      .ql-toolbar.ql-snow .ql-formats {
        margin-right: 0;
      }
    }
    &__content.directory-notes {
      display:block;
      overflow-y:auto;
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      .save-loading {
        width: 40px;
        justify-content: center;
        align-items: center;

        .ant-spin-dot {
          .ant-spin-dot-item {
            background: rgb(61, 216, 219);
          }
        }
      }
      button {
        margin: 7px;
        font-size: 16px;
        &:disabled {
          border: 1px solid #3DD8DB;
          opacity: 0.5;
        }
      }
      .button-green {
        background-color: var(--generalButton);
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 #dcdcdc;
        color:var(--generalButtonFont);
        cursor: pointer;
        font-family: ROBOTO;
        font-weight: 400;
        font-size: 16px;
        text-transform: none;
        width: 100px;
        height: 25px;
        line-height: 25px;
        margin: 0px;
        padding: 0px;
        &:hover {
          background: var(--generalButtonHover);
          color: #FFFFFF;
          border: 1px solid var(--generalButtonHover);
        }
        &:active {
          background: var(--generalButtonActive);
          color: #FFFFFF;
          border: 1px solid var(--generalButtonActive) ;
        }
      }
      .cancel-button {
        background: white;
        color: #6e7882;
        padding: 8px 18px;
        border: 1px solid #6e7882;
        border-radius: 25px;
        box-shadow: none;
        cursor: pointer;
        font-family: ROBOTO;
        font-weight: 400;
        font-size: 16px;
        text-transform: none;
        width: 100px;
        height: 25px;
        line-height: 25px;
        margin: 0px;
        padding: 0px;
        &:hover {
          background: #FFFFFF;
          color: var(--generalButtonHover);
          border: 1px solid var(--generalButtonHover);
        }
        &:active {
          background: #FFFFFF;
          color:var(--generalButtonActive) ;
          border: 1px solid var(--generalButtonActive);
        }
      }
    }
    .directory-note { 
      border-bottom: 1px solid #BCBCBC;
      margin: 30px;

      .user-img {
        float:left;
        width: 30px; 
        height: 30px; 
        border-radius: 30px; 
        background-color: #3DD8DB;
        border: 1px solid #BCBCBC;
      }
      .user-data {
        float:left;
        margin-left: 8px;
        font-family: Roboto;

        strong {
          font-weight: 600;
          display:block;
          line-height: 25px;
        }        
      }
      .user-note {
        clear:both;
        padding: 20px 0 30px 0;
        font-family: Roboto;
        line-height: 22px;
      }
    }
  }
}