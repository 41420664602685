.activeQuestion-page {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding-bottom: 20px;
}

.activeQuestion-pageDisabled {
  background-color: #DDDDDD;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding-bottom: 20px;
}

.activeQuestion-header {
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.activeQuestion-title {
  color: #162737;
  flex: 1;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
}

.activeQuestion-buttons {
  align-self: flex-start;
  display: flex;
}

.activeQuestion-button button {
  height: 40px;
}

.activeQuestion-skipButton {
  background: url('../../../assets/arrow.svg') no-repeat center 6px;
  background-color: #F44336;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  margin: 0 15px 0 0;
  width: 40px;
&:hover {
   background-color: #FFFFFF;
   background: url('../../../assets/arrow-red.svg') no-repeat center 6px;
   border: 1px solid #F44336;
 }
}

.activeQuestion-skipButtonSelected {
  background-color: #FFFFFF;
  background: url('../../../assets/arrow-red.svg') no-repeat center 6px;
  border: 1px solid #F44336;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  margin: 0 15px 0 0;
  width: 40px;
&:hover {
   background-color: #F55F54 !important;
   background: url('../../../assets/arrow.svg') no-repeat center 6px;
 }
}

.activeQuestion-description {
  color: #737D87;
  line-height: 1.5;
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .activeQuestion-header {
    flex-direction: row;
  }
  .activeQuestion-title {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .activeQuestion-buttons {
    align-self: initial;
  }
  .activeQuestion-skipButton {
    margin-right: 15px;
  }
}