.piaCompleteBox__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  padding: 60px;
}

.piaCompleteBox__title {
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}

.piaCompleteBox__subTitle {
  margin-top: 40px;
  text-align: center;
  color: #68727c;
  font-weight: 300;
}

.piaCompleteBox__buttons {
  margin-top: 40px;
  text-align: center;
  button {
    background: url('../../assets/icon-lock-big.png') no-repeat 50px center #3DD8DB;
    border-radius: 40px;
    height: 60px;
    padding-left: 60px;
    width: 300px;
    &:hover {
      background: url('../../assets/icon-lock-big-green.png') no-repeat 50px center #FFFFFF;
      border-radius: 40px;
      height: 60px;
      padding-left: 60px;
      width: 300px;
    }
  }
}