.dpiaDashboard__unLockedProcess-info {
  align-items: center;
  background-color: #FCFCFD;
  border-bottom: 1px solid #D6D3D3;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px 20px 20px;
  position: relative;
> div {
  min-width: 220px;
  text-align: right;
  margin-right: 32px;
}
.iconEdit {
  cursor: pointer;
  margin-top: -16px;
  position: absolute;
  right: 20px;
  top: 50%;
}
p {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  padding-right: 20px;
}
span {
  color: #111C26;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.50;
}
}