.content-popover-on-new-folder {
  top: 171.991px !important;

  .ant-popover-content {
    font-family: Roboto, serif;
    font-size: 14px;
    color: #000000;
    .ant-popover-arrow {
      background: #ffffff !important;
      width: 19.5px;
      height: 19.5px;
      top: 3px !important;
      box-shadow: -2px -2px 0px #3DD8DB !important;
    }
    .ant-popover-inner {
      box-shadow: none;
      border: 2px solid #3DD8DB;
      top: 171.991px;

      .ant-popover-title {
        display: flex;
        font-weight: bold;
        border-bottom: none !important;
        padding: 13px 16px 0px !important;

        span {
          text-align: center;
          flex: auto;
        }
      }

      .ant-popover-inner-content {
        padding: 0px 16px 13px !important;
        font-weight: 300;
      }
    }
  }
}
.dots-menu {
  .dropdown-menu {
    width: 206px;
  }
}
.ant-modal.rename-folder {
  margin-top: 18%;
  .subtitle {
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
  }
  .error {
    font-size: 14px;
    font-weight: 300;
    font-family: Roboto;
    color: #f00;
  }
  .ant-input {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ant-modal-footer {
    height: 55px;
    .ant-btn {
      border-radius: 30px;
      float: left;
      border: 2px solid #3DD8DB;
      color: #3DD8DB;
      font-weight: bold;
      min-width: 100px;
      &:hover {
        background-color: #3DD8DB;
        color: #fff;
      }
    }
    .ant-btn.ant-btn-primary {
      float: right;
      border: 2px solid #3DD8DB;
      background-color: #3DD8DB;
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: #fff;
        color: #3DD8DB;
      }
    }
  }
}

.update-pagination-header-box {
  padding: 0px 20px 0px 21px;
 .new-folder-wrapper {
   //margin-left: 20px !important;
 }
}

.new-pagination-header {
  top: 100px !important;
}
.new-pagination-header.false.grid {
    width: calc(100% - 300px);
}
.new-pagination-header.true.grid {
  width: calc(100% - 20px);
}
.new-pagination-header.true.list {
  width: 100%;
}
.smart-box-page {
  margin: 0 0.5%;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  &__action-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &__row {
      width: 100%;

      .row {
        margin: 0;

        .col-md-2 {
          padding: 0;
        }
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      position: fixed;
      //padding: 20px 20px;
      height: 33px;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      //box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
      //-webkit-box-shadow: 0 7px 4px -6px rgba(0, 0, 0, 0.1);
      //-moz-box-shadow: 0 7px 4px -6px rgba(0, 0, 0, 0.1);
      //box-shadow: 0 7px 4px -6px rgba(0, 0, 0, 0.1);
      border-bottom: 1pt solid #A6ACB2;
      width: calc(100% - 0px);
      float: right;
      top: 60px;
      right: 0;
      z-index: 3;
      background-color: #fafafa;
      //margin-right: 20px;
      //margin-left: 20px;
      .buttons-section-disabled {
        .upload-button-content-disabled {
          .ant-btn {
            object-fit: contain;
            font-size: 20px;
            font-weight: 900;
            text-align: center;
            color: #555;
            cursor: not-allowed;
            background-color: #ccc;
            border-radius: 30px;
            border: 1px solid #555;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
            &:hover {
              color: #555;
              cursor: not-allowed;
              background-color: #ccc;
            }
          }
        }
      }
      .buttons-section {
        width: 30vw;
        display: contents;
        justify-content: flex-end;
        align-items: center;

        .ant-btn {
          object-fit: contain;
          font-size: 20px;
          font-weight: 900;
          text-align: center;
          color: #3DD8DB;
          background-color: white;
          border-radius: 30px;
          border: 1px solid #3DD8DB;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
          &:hover {
            color: #FFFFFF;
            background-color: #3DD8DB;
          }
        }
        .upload-button-content {
          margin-right: 30px;
          position: relative;
          order: 1;

          .custom-file-input {
            display: none;
          }

          .custom-file-label {
            width: 150px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            &:after {
              display: none;
            }
          }

          .ant-btn {
            width: 140px;
            height: 41px;
          }
        }

        .new-folder-button-content {
          order: 2;

          .ant-btn {
            width: 206px;
            height: 41px;

            .anticon {
              font-size: 20px;
              font-weight: bold;
            }

          }
        }
      }

      .search-content {
        margin-right: 30px;
        width: 80%;
        display: flex;
        height: 40px;
        border-radius: 3px;
        border: solid 1px rgba(110, 120, 130, 0.4);
        background: rgba(255,255,255, 0.4);

        .ant-input {
          font-size: 16px;
          font-weight: 300;
          color: #6e7882;
          background: transparent;
          border: none;
          padding: 10px 30px 10px 10px;

          &:focus {
            box-shadow: none;
          }
        }

        .ant-select {
          color: #6e7882;
          border-right: solid 1px rgba(110, 120, 130, 0.4);

          .ant-select-selection {
            background: transparent;
            height: 40px;
            width: 110px;
            border: none;

            &__rendered {
              height: 40px;
              line-height: 16px;
              display: flex;
              align-items: center;
              margin: 0 0 0 10px;

              .ant-select-arrow {
                right: 0;
              }

              .ant-select-selection-selected-value {
                margin-top: -2px;
              }
            }
          }
        }

        .ant-input-suffix {
          object-fit: contain;
          font-size: 16px;
          width: 40px;
          height: 40px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          background-color: rgba(110, 120, 130, 0.6);
          display: flex;
          justify-content: center;
          right: 0;
          z-index: 0;

          i {
            font-size: 19px;
            color: white;
          }
        }


        .ant-input-affix-wrapper {
          border: none;
        }
      }
      .filter-wrapper-contain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .navigation-pagination-width {
          .content-result-filter-root {
            display: flex;
            align-items: center;
            .icon-v14-refresh-1 {
              font-size: 14px;
              color: var(--generalIconFontHover);
            }
            .icon-v14-refresh-active {
              font-size: 14px;
              color: #EDA41E;
            }
            .total-filter {
              color: var(--generalIconFontHover);
              font-family: Roboto;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              padding-right: 3px;
              padding-left: 3px;
              padding-top: 2px;

            }
            .name-results {
              color: var(--generalIconFontHover);
              font-family: Roboto;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              padding-right: 3px;
              padding-top: 2px;

            }
          }
          .filter-wrapper-root {
            position: absolute;
            right: 15px;
            width: 150px;
            display: flex !important;
            align-items: center;
            flex-direction: row;
          }
          .filter-wrapper-folder {
            position: absolute;
            right: 15px;
            width: 150px;
            display: flex !important;
            align-items: center;
            flex-direction: row;
          }
          .filter-wrapper {
            border: 1px solid transparent;
            display: block;
            margin-left: 5px;
          }
          .ant-select .ant-select-selection .ant-select-arrow {
            margin-right: -6px;
          }
          button {
            color: #6e7882 !important;
            padding: 0 !important;
            background-color: transparent !important;
            border: none !important;
            font-size: 20px;
            line-height: 22px;
            outline: none;
            margin-top: -2px;
            margin-left: 20px;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
      .filter-wrapper-folder {
        position: absolute;
        right: 15px;
        //width: 150px;
        display: flex !important;
        align-items: center;
        flex-direction: row;
        align-content: center;
        .content-result-filter-root {
          display: flex;
          align-items: center;
          .icon-v14-refresh-1 {
            font-size: 14px;
            color: var(--generalIconFontHover);
          }
          .icon-v14-refresh-active {
            font-size: 14px;
            color: #EDA41E;
          }
          .total-filter {
            color: var(--generalIconFontHover);
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            padding-right: 3px;
            padding-left: 3px;
            padding-top: 2px;

          }
          .name-results {
            color: var(--generalIconFontHover);
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            padding-right: 3px;
            padding-top: 2px;

          }
        }
      }
      .filter-wrapper-root {
        position: absolute;
        right: 15px;
        //width: 150px;
        display: flex !important;
        align-items: center;
        flex-direction: row;
        align-content: center;
        .content-result-filter-root {
          display: flex;
          align-items: center;
          .icon-v14-refresh-1 {
            font-size: 14px;
            color: var(--generalIconFontHover);
          }
          .icon-v14-refresh-active {
            font-size: 14px;
            color: var(--generalIconFontHover);
          }
          .total-filter {
            color: var(--generalIconFontHover);
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            padding-right: 3px;
            padding-left: 3px;
            padding-top: 2px;
            cursor:default;

          }
          .name-results {
            color: var(--generalIconFontHover);
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            padding-right: 3px;
            padding-top: 2px;

          }
        }
      }
      .filter-wrapper {
        border: 1px solid transparent;
        //&:hover {
        //  color: #00d4d8;
        //  border: 1px solid #00d4d8;
        //  .anticon-down {
        //    color: #00d4d8 !important;
        //  }
        //}
        display: inline;
        //padding-top: 19px;
        cursor: pointer;
        color: #6e7882;
        /*width: 60px;*/
        margin-left: 20px;
        margin-right: 10px;
        .icon-v9-filter {
          margin-right: 8px;
        }
        .icon-v14-filter-dropdown {
          font-size: 20px;
          width: 40px;
          display: inline-block;
          height: 20px;
        }
        .icon-v14-filter-dropdown-active {
          font-size: 20px;
          width: 40px;
          display: inline-block;
          height: 20px;
          color: var(--generalIconFontHover);
        }
        .icon-v14-filter-dropdown.update {
          color: #ffffff;
          background-color: var(--generalIconFontActive);
          border-radius:2px;
          &:hover {
            color: #ffffff;
            background-color:var(--generalIconFontHover);
            border-radius:2px;
          }
        }
        .bi-filter {
          fill: #6e7882;
        }
        .anticon-down {
          color: rgba(0, 0, 0, 0.3) !important;
          position: absolute;
          font-size: 16px !important;
          top: 14px;
        }
        .filter-menu {
          position: absolute;
          z-index: 3;
          border: 1px solid #EDA41E;
          .ant-dropdown-menu-item-selected {
            background-color: #FFFFFF;
            color: #EDA41E !important;
          }
          .ant-dropdown-menu-item {
            /*border: 1px solid #ccc;*/
            font-family: Roboto;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            margin: 0;
            padding: 0 8px;
            &:hover {
              background-color: #00d3d8;
              color: rgba(0, 0, 0, 0.65);
            }
            .ant-input-search {
              &:hover {
                border-color: #00d3d8;
              }
              .ant-input-suffix {
                right: 0;
              }
            }
          }
          .ant-dropdown-menu-item-active {
            background-color: #ffffff !important;
            &:hover {
              background-color: #ffffff;
            }
          }
          .ant-menu-submenu {
            /*border: 1px solid #ccc;*/
            margin: 0;
            overflow: hidden;
            .ant-menu-submenu-title {
              margin: 0;
              color: rgba(0, 0, 0, 0.65);
              font-family: Roboto;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.65);
              &:hover {
                background-color: #9AE0FC50;
                color: rgba(0, 0, 0, 0.65);
              }
            }
          }
        }
      }
      .items-displayed-wrapper {
        //padding-top: 16px;
        //padding-left: 10px;
        margin-left: 10px;
        margin-right: 10px;
        /*margin-top:8px;*/
        color: #6e7882;
        width: 80px;
        position: relative;
        .per-page {
          font-size: 20px;
          /*border: 1px solid transparent;
          font-family: Roboto;
          font-weight: 300;
          padding-top: 3px;
          padding-left: 2px;
          height: 20px;
          //padding-bottom: 30px;
          display: flex;
          align-content: center;
          align-items: center;
          &:hover {
            height: 20px;
            //padding-bottom: 30px;
            color: #00d4d8;
            border: 1px solid #00d4d8;
            .ant-select-selection {
              color: #00d4d8 !important;
            }
            .ant-select-arrow {
              color: #00d4d8 !important;
            }
          }*/
          .ant-select-selection--single {
            border:none;
            background-color: transparent;
            width: 50px;
            height: 1px;
            .ant-select-selection-selected-value {
              display:none !important;
            }
          }
          .ant-select-selection__rendered {
            width: 80px;
          }

          .ant-select-arrow {
            display:none;
          }
        }
        .icon-v14-show-20-active, .icon-v14-show-50-active,  .icon-v14-show-100-active {
          color: var(--generalIconFontHover);
        }
        /*.ant-select {
          font-size: 14px;
          .ant-select-selection {
            border: none;
            background-color: transparent;
            &__rendered {
              margin-right: 40px;
              padding-top: 1px;
            }
          }
          .ant-select-arrow-icon {
            margin-top: -1px;
          }
        }*/
      }
      .select-all-wrapper {
        //padding-top: 18px;
        cursor: pointer;
        width: 170px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      .ant-dropdown-trigger {
        //width: 20px;
        margin-left: 10px;
      }
        .ant-checkbox-wrapper {
          display:none;
        }
        .icon-v14-select-all {
          font-size: 20px;
          display:inline-block;
          width: 100px;
          color: #6e7882;

          &-selected {
            color: var(--generalIconFontActive);
            font-size: 20px;
            display:inline-block;
            width: 100px;
          }
          &-active {
            color: var(--generalIconFontHover);
            font-size: 20px;
            display:inline-block;
            width: 100px;
          }
        }

        .icon-v14-delete-active {
          color: var(--generalIconFontHover);
          font-size: 20px;
        }
        .icon-v9-delete {
          border: 1px solid transparent;
          &:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }
        }
        .icon-v14-delete {
          font-size: 20px;
          color: #6e7882;
          /*&:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }*/
        }
        .ant-checkbox-wrapper {
          font-size: 14px;
          font-weight: 300;
          //padding-right: 10px;
          font-family: Roboto;
          width: 90px;
          color: #6e7882;
          border: 1px solid transparent;
          margin-right: 10px;
          margin-bottom: 3px;
          &:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }
          .ant-checkbox + span {
            padding-right: 0px;
            padding-left: 8px;
            padding-top: 3px;
          }
          .ant-checkbox-inner {
            margin-left: 5px;
          }
        }
        .disabled-icon {
          cursor: not-allowed;
          color: rgba(0, 0, 0, 0.25) !important;
        }


        .icon-v211-label-dropdown {
          font-size: 20px;
          width: 40px;
          display: inline-block;
          height: 20px;
        }
        .icon-v211-label-dropdown-disabled {
          font-size: 20px;
          width: 40px;
          display: inline-block;
          height: 20px;
          color: #D9D9D9 !important;
          cursor: not-allowed !important;
        }
        .icon-v211-label-dropdown-active {
          font-size: 20px;
          width: 40px;
          display: inline-block;
          height: 20px;
          color: var(--generalIconFontHover);
        }
        .icon-v211-label-dropdown.update {
          color: #ffffff;
          background-color:#eda41e;
          border-radius:2px;
          &:hover {
            color: #ffffff;
            background-color:#eda41e;
            border-radius:2px;
          }
        }

      }
      .new-pagination-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        .number-total-files-content {
          font-size: 14px;
          font-family: Roboto;
          font-weight: 300;
          color: #6e7882;
        }
      }
      .pagination-wrapper {
        //padding-top: 5px;
        .ant-select {
          .ant-select-selection {
            border: none;
            background-color: transparent;
            &__rendered {
              margin-right: 40px;
            }
          }
        }
      }
      .switch-view-wrapper-contain-root {
        width: 400px;
      }
      .switch-view-wrapper-contain {
        display: flex;
        color: #6e7882;
        align-items: center;
        .refresh-wrapper {
          width: 28px;
          //display: contents;
          border: 1px solid transparent;
          //&:hover {
          //  color: #00d4d8;
          //  border: 1px solid #00d4d8;
          //}
          .icon-v9-refresh {
            font-size: 14px;
          }
          .icon-v14-refresh-active {
            color: var(--generalIconFontHover);
            font-size: 20px;
          }
          .icon-v14-refresh-1 {
            font-size: 20px;
          }
        }
        .icon-v9-upload {
          &:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }
        }
        .icon-v14-upload-active {
          font-size: 20px;
          color: var(--generalIconFontHover);
        }
        .icon-v14-upload {
          font-size: 20px;
          /*&:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }*/
        }
      }
      .switch-view-wrapper {
        cursor: pointer;
        //padding-top: 19px;
        margin-left: 10px;
        margin-right: 10px;
        .view-mode {
          width: 25px;
          .view-icon-wrapper {
            display: flex;
          }
        }
        .icon-v9-list-view {
          border: 1px solid transparent;
          padding-top: 1px;
          font-size: 14px;
          &:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }
        }
        .icon-v14-grid-view-active {
          color:var(--generalIconFontHover);
          font-size: 20px;
        }
        .icon-v14-list-view-avtive {
          color: var(--generalIconFontHover);
          font-size: 20px;
        }
        .icon-v14-list-view-1 {
          font-size: 20px;
          /*&:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }*/
        }
        .icon-v14-grid-view-1 {
          font-size: 20px;
          /*&:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }*/
        }
        .icon-v9-grid-view {
          border: 1px solid transparent;
          &:hover {
            color: #00d4d8;
            border: 1px solid #00d4d8;
          }
        }
      }
      .refresh-wrapper {
        cursor: pointer;
        //padding-top: 19px;
        font-size: 18px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .upload-wrapper {
        width: 30px;
        cursor: pointer;
        position: relative;
        //padding-top: 19px;
        font-size: 20px;
        //width: 60px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        .disabled-icon {
          cursor: not-allowed;
          color: rgba(0, 0, 0, 0.25);
        }
        .hidden-file-input {
          cursor: pointer;
          width: 27px;
          height: 27px;
          position: absolute;
          z-index: 1;
          top: 0px;
          left: 0px;
          opacity: 0;
        }
        .icon-v14-upload-active {
          cursor: pointer;
        }
      }
      .new-folder-wrapper {
        //padding-top: 16px;
        font-size: 20px;
        cursor: pointer;
        margin-left: 10px;
        margin-right: 20px;
        color: #6e7882;
        .disabled-icon {
          cursor: not-allowed;
          color: rgba(0, 0, 0, 0.25);
        }
        .icon-v14-add-box-small {
          font-size: 20px;
          &:hover {
            color: var(--generalIconFontHover);
          }
        }
        .icon-v14-add-folder-small {
          font-size: 20px;
          color: var(--generalButton);
          &:hover {
            color: var(--generalIconFontHover);
          }
        }
      }
    }

    &__breadcrumb {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;

      .content-check-grid {
        display: flex;
        align-items: center;
        .content-icon-refresh {
          display: flex;
          padding-right: 20px;
        }
        .content-checkbox {
          display: flex;
          padding-right: 15px;
          .ant-checkbox-wrapper {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #637882;
            .icon-v9-delete {
              color: #637882;
            }
            .hover-icon-delete:hover {
                color: #00d4d8 !important;
                border: 1pt solid #00d4d8 !important;
              }
          }

          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: #00d3d8;
              border-color: #00d3d8;
            }
          }
        }

        .view-mode {
          cursor: pointer;
          float: left;
          padding-top: 5px;

          .anticon {
            font-size: 40px;
            width: 20px;
            color: #6e7882
          }
        }
      }

      .view-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px rgba(110, 120, 130, 0.4);
        background-color: rgba(255, 255, 255, 0.4);

        .icon-v2-list-view {
          color: #6e7882;

          &:before {
            width: auto;
          }
        }
      }

      &--bold {
        font-size: 14px;
        color: black;
        font-weight: 500;
        a {
          &:hover {
            color: #3DD8DB;
          }
        }
      }
    }

    &__tree {
      width: 17%;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
      height: 100vh;
      position: fixed;
      top: 0;
      padding-top: 85px;
    }
    &__content {
      //width: calc(100% - 326px);
      width: calc(100% - 0px);
      position: relative;
      min-height: 200px;
      height: auto;
      display: block;
      align-items: center;
      flex-wrap: wrap;
      //padding: 0 20px;
      //padding-left: 20px;
      margin-top: 92px;
      //overflow: hidden;
      overflow-y: visible;
      float: right;
      padding: 30px 40px;
      .menu-item-directory8 {
        border-bottom: solid 1px rgba(110, 120, 130, 0.29) !important;
        height: 0px !important;
        border: none;
        width: 90% !important;
        margin-left: 9px;
      }
      .context-menu-directory {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        border: solid 2px rgba(110,120,130,.29);
        color: #382e34;
        z-index: 1000;
        box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3);
        .menu-item-disabled {
          cursor: not-allowed;
          color: #a6acb2 !important;
        }
        .menu-item-directory {
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          border: none !important;
          //background: #9ae0fc50;
          background: #FFFFFF;
          color: #382e34;
          width: 200px;
          height: 30px;
          font-weight: 400;
          &:hover {
            color: #382e34;
            background-color: #9ae0fc50;
          }
        }
        .menu-item-disabled-rename {
          cursor: not-allowed;
          color: #a6acb2 !important;
        }
        .menu-item-disabled-rename:hover {
          cursor: not-allowed;
          color: #a6acb2 !important;
        }
        .menu-item-directory-disabled:hover {
          background-color: #FFFFFF;
          color: #D4D7DA;
        }
        .menu-item-directory-disabled {
          cursor: default;
          color: #D4D7DA;
        }
        .menu-disabled {
          cursor: not-allowed;
          color: rgba(110, 120, 130, 0.39);
        }
        .menu-item-directory4 {
          cursor: not-allowed;
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          color: #cccccc;
          border: none !important;
        }
        .menu-item-directory4:hover {
          color: #cccccc;
          background: #ffffff;
        }
        .menu-item-directory-disabled-export:hover {
          background-color: #FFFFFF;
          color: #a6acb2;
        }
        .menu-item-directory-disabled-export {
          cursor: not-allowed;
          color: #a6acb2;
        }
        .menu-item-directory-disabled-rename:hover {
          background-color: #FFFFFF;
          color: #a6acb2;
        }
        .menu-item-directory-disabled-rename {
          cursor: not-allowed;
          color: #a6acb2;
        }
        menu-item-category3 {
          border-bottom: solid 1px rgba(110, 120, 130, 0.29);
          height: 0px !important;
        }
        .menu-item-category {
          align-items: center;
          justify-content: start;
          padding-left: 10px;
          height: 0px;
          font-size: 14px;
          font-weight: 500;
          color: #FFFFFF;
          border: none !important;
          border-bottom: solid 1px #D4D7DA !important;
          width: 87%;
          margin: 0px 10px;
          &:hover {
            color: #382e34;
            background-color: #9ae0fc50;
            cursor: auto;
          }
        }
      }

      .ant-spin-nested-loading {
        position: static !important;
        /*display: contents;*/

        .ant-spin-spinning {
          margin-top: 100px;
        }
      }
      .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -10px;
      }
      &__files {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        //margin-top: 40px;
      }

      &__file-image-delete {
        display: initial;
        position: absolute;
        top: 32%;
        left: 32%;
      }

      &__file-image-delete.directory {
        display: initial;
        position: absolute;
        top: 36%;
        left: 36%;
      }
      .parent-files {
        .menu-item-disabled {
          cursor: not-allowed;
          color: #a6acb2;
        }
        .ant-table {
          //overflow: visible;
          //overflow-y: hidden;
          color: #6e7882;
          line-height: 20px;

          .ant-table-header {
            margin-bottom: 0px !important;
            overflow: hidden !important;
            margin-right: 10px;
          }
        }
        /*.icons-selected-click {
          background-color: #e5f7ff;
          height: 40px;
        }
        .icons-container {
          &__content {
            .folder-info {
              color: #000000;
              &__name {
                font-weight: bold;
                color: #000000;
              }
              &__row-name {
                color: #000000;
                font-weight: 500;
              }
            }
          }
        }*/
      }

      .files-table {
        width: 100%;
        cursor: pointer;
        /*padding: 20px;*/

        .image-thumbnail {
          box-shadow: 0px 0px 0px 1px #6e7882;
        }
        .ant-table-body{
          //overflow-y: auto !important;
        }
        .ant-table-tbody > tr > td {
          padding: 0px;
        }
        .parent-status {
          color: #000000;
          font-weight: 500;
          .status-redacted-list-view {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            .color-status-file {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              display: flex;
            }
            .icon-v12-process-error {
              color: #ff0000;
            }
          }
        }
        .parent-column {
          .ant-table-column-title {
            display: flex !important;
            align-items: center;
          }
          .ant-table-column-sorters {
            display: flex;
            align-items: center;
          }
          .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
            display: flex;
            align-items: center;
          }
        }
        .ant-table-row-level-0 {
          .ant-table-row-cell-break-word {
            color: #6e7882;
            border-bottom: 1px solid #000000;
            font-family: Roboto;
            font-weight: 300;
            font-size: 12px;
          }
          .parent-modified {
            color: #6e7882;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
          }
        }
        /*.parent-column {
          font-weight: 500;
          .ant-table-column-title {
            padding-left: 30px;
          }
        }*/
        .ant-spin-dot {
          width: 3em;
          height: 3em;
          top: 20% !important;
          left: 52%;
        }
        .ant-spin-dot-item {
          width: 25px;
          height: 25px;
          background: #3dd8db !important;
        }

        .ant-table-body {
          background: white;
          &::-webkit-scrollbar {
            width: 8px;
            background: #eeeeee;
          }
          &::-webkit-scrollbar-track {
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: #cccccc;
            border-radius: 10px;
          }
        }
        .ant-table-column-title {
          height: 20px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #637882;
        }

        .ant-table-column-sorter-inner {
          color: #6e7882 !important;
        }
        .ant-table-thead > tr > th[colspan] {
          text-align: left;

        }

        .ant-table-thead > tr > th {
          background-color: #FFFFFF;
          border: none;
          padding: 0 !important;
        }

        .ant-table-row-cell-break-word {
          position: relative;
          .ant-table-row-indent {
            display: none;
          }

          .expand-collapse {
            position: absolute;
            top: 35px;
            overflow: visible;
            z-index: 2;
          }
        }
        tbody > tr > td {
          vertical-align: middle;
          background-color: #FFFFFF;
          border-bottom: 1px solid #e6e6e6;
          /*&:nth-child(2) {
            height: 19px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          }*/
          .action-drop-cell {
            .dropdown-menu {
              //top: 0px !important;
              //left: -10px!important;
              border: 1px solid #382e34 !important;
              color: #382e34 !important;
              border-radius: 0 !important;
              box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3);
              width: 160px;
              .dropdown-item {
                color: #382e34;
                line-height: 30px;
                height: 30px;
                font-size: 14px;
                display: flex;
                align-items: center;
              }
              .dropdown-item:hover {
                background-color: #9ae0fc50;
                color: #382e34;
              }
              .menu-item-category3 {
                height: 0px !important;
              }
              .menu-item-disabled {
                cursor: default;
                color: #D4D7DA;
                background-color: #FFFFFF;
              }
              .menu-item-disabled:hover {
                cursor: default;
                color: #D4D7DA;
                background-color: #FFFFFF;
              }
            }
          }
          .disabled-icon {
            cursor: not-allowed;
          }
          .actionsDotsCell {
            button {
              color: black !important;
              padding: 0 !important;
              background-color: transparent !important;
              border: none !important;
              font-size: 30px;
              line-height: 22px;
              outline: none;
              margin-top: 0px;

            }


            .ellipsis-v {
              -webkit-writing-mode: horizontal-tb;
              writing-mode: horizontal-tb;
              font-size: 26px;
              font-weight: 700;
              float: right;
              cursor: pointer;
              color: #000000;
              padding: 0;
              &:hover {
                color: #00d3d8;
              }
            }
          }
        }
      }
      .file-expand-status {
        display: inline;
        position: relative;
        top: 44px;
      }
      .custom-pagination {
        display: block;
        padding: 0 20px;
      }
    }
    .content-list {
      margin-top: 92px;
      .files-table {
        .ant-table-column-sort {
          .ant-table-column-title {
            color: #EDA41E;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .expand-icon-container {
      display: inline-flex;
    }
    .icons-container {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding: 0 5px 0;
      margin-top: 8px;
      margin-bottom: 8px;
      position: relative;
      width: 100%;
      font-family: Roboto;
      font-weight: 300;
      font-size: 14px;
      color: #382e34;

      .react-contextmenu-wrapper {
        width: 100%;
      }
      &__icons {
        position: absolute;
        display: flex;
        padding: 2px;
        font-size: 10px;
        z-index: 2;

        .anticon {
          margin-left: 2px;
        }

        img {
          width: 11px;
          height: 11px;
        }
      }
      .new-display-status-bulk {
        .bulk-processing {
          left: 20px !important;
        }
      }
      .display-status-bulk {
        .image-thumbnail-wrapper {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          .image-thumbnail {
            width: auto;
            max-width: 100%;
            height: 100%;
          }
        }
        .bulk-processing {
          position: absolute;
          left: 20px;
          top: 0px;
          background: white;
          border-radius: 50%;
          font-size: 10px;
        }
        .bulk-processing-data-finalised {
          font-size: 9px;
          line-height: 9px;
        }
      }
      &__content {
        display: flex;
        .bulk-processing {
          position: absolute;
          left: 35px;
          top: 8px;
          background: white;
          border-radius: 50%;
        }
        .analysed-file {
          position: absolute;
          top: 2px;
          left: 33px;
          background: white;
          border-radius: 50%;
        }
        .folder-info {
          margin-left: 10px;
          font-size: 14px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          &__info {
            font-size: 14px;
            color: black;
            span {
              padding-right: 7px;
            }
          }
          /*&__name {
            font-weight: bold;
          }*/
        }
      }

      i::before {
        margin-left: 0;
        margin-right: 0;
        display: flex;
      }
    }

    .image-container {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      [class^="icon-v3-"] {
        font-size: 60px;
        line-height: normal;
      }
      [class^="icon-v2-smart-folder"] {
        font-size: 60px;
        line-height: normal;
      }
      .settings {
        top: 26px;
        right: -6 px;
      }
      img {
        width: 94px;
      }
    }
  }

  &__action-sidebar {
    width: 15%;
    background-color: #ffffff;
    display: none;
    position: fixed;

    &__close {
      position: absolute;
      z-index: 2;
      top: 94px;
      // left: -14px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      cursor: pointer;
      padding: 5px 7px;
    }
  }
}
.filter-menu-box {
  right: -26px !important;
}
.filter-menu {
  position: absolute;
  z-index: 3;
  padding: 0 !important;
  width: 203px !important;
  height:fit-content;
  padding: 20px 20px 15px 20px !important;
  border: 1px solid var(--generalIconFontHover);
  border-radius: 0 !important;
  .ant-dropdown-menu-item-selected {
    background-color: #FFFFFF;
    color: #eda41e !important;
  }
  .ant-dropdown-menu-item {
    /*border: 1px solid #ccc;*/
    font-family: Roboto;
    font-size: 14px;
    color: #6e7882;
    margin: 0;
    padding:0px;
    line-height: 20px;
    &:hover {
      background-color: #9ae0fc50;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .search_input {
    position: relative;
    .ant-select-show-search {
      width: 100%;
    }
    &:hover {
      background-color: #ffffff;
    }
    .ant-input-search {
      .ant-input-suffix {
        right: 8px;
      }
    }
    .close-circle {
      margin-right: 12px;
      font-size: 14px;
      font-family: Roboto;
    }
    .anticon-search {
      z-index: 3;
      right: 2px;
      color: #999999;
      margin-left:8px;
    }
  }
  .sectionsNewFilter {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #382e34;
    flex-direction: column;
    max-height: 481px;
    width: 191px;
    overflow-y: auto;
    overflow-x: hidden;
    .section-items-newFilter {
      width: 150px;
      //margin-right: 20px;
      margin-bottom: 10px;
      .icon-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 4px;
        //background-color: #0b2e56;
        display: flex;
      }
      .content-filter-items {
        width: 150px !important;
        display: flex;
        align-items: center;
        .anticon {
          margin-left: 4px;
          margin-right: 4px;
        }
        .item-title {
          margin-left: 3px;
          text-wrap: nowrap;
          width: 120px;
        }
      }
      .item-title {
        //width: 120px !important;
        margin-left: 3px;
        text-wrap: nowrap;
      }
      .section-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #382e34;
      }
    }
  }
  .clear-all-btn {
    height: 20px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    cursor: default;
    justify-content: flex-end;
    span {
      font-family:'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color:#382e34;
      &:hover {
        color: var(--generalIconFontHover);
      }
    }
  }
  .ant-radio-group.ant-radio-group-outline {
    display: flex ;
    flex-direction: column;
    width:130px;
    height:100px;
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      text-align: center;
      .item-title {
        margin-left: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color:#382e34;
      }
      span:not(:first-child) {
        padding-left:0px !important
      }
    }
  }
  .ant-radio .ant-radio-inner {
    border-radius: 50px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-color: #6e7882;
    border-style: solid;
    border-width: 1px;
    transition: none;
  }
  .ant-radio:after {
    border-radius: 1px;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: var(--generalIconFontHover);
  }
  .ant-radio .ant-radio-inner::after {
    content: " " !important;
    color: #ffffff !important;
    font-size: 9px !important;
    font-family: 'FontAwesome' !important;
    top: 3px;
    left: 3px;
    //background-color: #EDA41E;
    border: 4px solid #FFFFFF;
    border-radius: 50%;
    background-color: #FFFFFF;
    transition: none;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #637882;
    background-color: var(--generalIconFontActive);
    border-radius: 50%;
  }
  .ant-radio-checked::after {
    border-radius: 50%;
    animation: none;
    border: 1px solid var(--generalIconFontHover);
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      text-align: center;
      height: 25px;
      margin-left: 0;
      .item-title {
        margin-left: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color:#382e34;
      }
      span:not(:first-child) {
        padding-left: 0px !important;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      position: absolute !important;
      display: table;
      //border: 2px solid #fff;
      border: none !important;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      color: #FFFFFF;
      top: 7px !important;
      left: 7px !important;
      font-size: 12px !important;
      font-family: smartbox_icons_v19;
    }

    .ant-checkbox-wrapper {
      margin-right: 0px;

      .ant-checkbox-inner {
        border-radius: 0px;
        border: 1px solid #382e34;
      }

      .ant-checkbox-inner:hover {
        border: 1px solid var(--generalIconFontHover);
      }

      .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:hover {
        background-color: #EDA41E !important;
        border: 1px solid #EDA41E;
      }

      .ant-checkbox-checked:hover {
        .ant-checkbox-inner {
          background-color: var(--generalIconFontHover) !important;
          border: 1px solid var(--generalIconFontHover) !important;
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: var(--generalIconFontActive) !important;
          border-color:var(--generalIconFontActive);

          &:after {
            border-color: #fff;
            border-width: 1px;
            top: 42%;
            left: 24%;
          }
        }
      }
    }
  }

  .ant-dropdown-menu-submenu {
    /*border: 1px solid #ccc;*/
    margin: 0;
    overflow: hidden;
    .ant-dropdown-menu-submenu-title {
      padding: 5px 10px 5px;
      margin: 0;
      color: #6e7882 !important;
      font-family: Roboto;
      font-size: 14px;
      &:hover {
        background-color: #9AE0FC50;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.ant-dropdown-menu-submenu {
  .ant-dropdown-menu-sub {
    .ant-dropdown-menu-item {
      border: none;
      font-family: Roboto;
      font-size: 14px;
      color: #6e7882;
      margin: 0 !important;
      padding: 5px 10px !important;
      font-weight: 400;
      &:hover {
        background-color: #9AE0FC50;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .ant-dropdown-menu-item-selected{
      background-color: #9AE0FC50;
      /*color: #ffffff;*/
    }
    .ant-menu-item-selected {
      background-color: #ffffff !important;
      color: #00d3d8;
      &:hover {
        background-color: #ffffff !important;
        color: #00d3d8;
      }
    }
  }
}


.small-content {
  width: 86%;
}
.sidebar-class {
  top: 0;
  padding-top: 3%;
  width: 14%;
  display: inline;
  height: 100vh;
  overflow: auto;
  z-index: 4;
  &::-webkit-scrollbar { width: 0 !important }
  .metadata-page {
    padding-left: 7%;
    padding-right: 7%;
    .pie-chart {
      position: relative;
      &__total {
        position: absolute;
        top: 28%;
        font-family: Roboto;
        font-size: 29px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #6e7882;
        width: 100%;
      }
      &__chart {
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 30%;
        margin-bottom: 16%;
        svg {
          overflow: visible;
          path:nth-child(1) {
            stroke-width: 19;
          }
          path:nth-child(2) {
            stroke-width: 15;
          }
          path:nth-child(3) {
            stroke-width: 11;
          }
        }
      }
      &__legend {
        margin-top: 20px;
        margin-bottom: 20px;
        &__item {
          color: #6e7882;
          font-family: Roboto;
          font-size: 15px;
          font-weight: 500;
          margin-top: 10px;
        }
        #regular {
          color: #38e000;
        }
        #manual {
          color: #00d3d8;
        }
        #business {
          color: #feb236;
        }
      }
    }
    &__img-container {
      display: flex;
      justify-content: center;
      position: relative;
      width: 120px;
      margin: auto;
      margin-top: 17%;
      margin-bottom: 5%;

      .display-thumbnail-metadata {
        img {
          border: 1px solid #6E7882;
        }
      }
      .analysed-file {
        height: 25px;
        top: 10px;
        left: 32px;
        position: absolute;
      }
    }
    .title-file-metadata {
      color: #6E7882 !important;
      font-size: 16px !important;
    }
    .title-metadata {
      height: 19px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      overflow-wrap: break-word;
      height: auto;
    }
    .subtitle-metadata {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      color: #4a4a4a;
      margin-bottom: 17%;
    }
    .subtitle-metadata-number {
      color: #6E7882;
    }
    .item-metadata {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      color: #6E7882;
      margin-bottom: 10px;
      overflow-wrap: break-word;
      &__title {
        font-weight: bold;
      }
    }
    #availability {
      margin-top: 15%;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.react-contextmenu {
  background: white;
  .react-contextmenu-item {
    width: 200px;
    height: 30px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      //color: #382e34;
      background-color: #9ae0fc50;
    }
  }
  .react-contextmenu.context-menu-directory2 {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
    menu-item-directory.react-contextmenu-item {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      min-width: 160px;
    }


  }
  .react-contextmenu.context-menu-directory2-update {
    display: none;
  }
}

.header-container {
  position: fixed;
  @media screen and (max-width: 800px) {
    position: relative;
  }
}
  @media screen and (max-width: 800px) {
    .new-content-smart-box {
      overflow-x: scroll;
      .content-files {
        width: 600px;
      }
    }
  }

.files-table{
  .ant-table-tbody {
    > tr > td {

      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent !important;
      font-family: Roboto;
      /*font-weight: 400;*/
      font-size: 12px;
      text-align: left;
      vertical-align: middle;
      transition: none !important;

      &:first-child{
        padding: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-left: 2px solid transparent;
      }
      &:last-child{
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-right: 2px solid transparent;
      }
    }
    > tr.ant-table-row-selected > td {
      background: rgba(230, 247, 255, 0.5);

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #fafafa;
      border-top: 2px solid #9AE0FC ;
      border-bottom: 2px solid #9AE0FC !important;

      &:first-child {
        border-left: 2px solid #9AE0FC;
      }
      &:last-child {
        border-right: 2px solid #9AE0FC;
      }

      .actionsDotsCell {
        display:block;
      }
    }

  }
  .ant-table-thead > tr > th {
    border-bottom: none !important;
  }

}

.files-table {
  tr td {
    &:last-child {
      /*padding: 5px !important;*/
    }

    .actionsDotsCell {
      display:none;
      height: 30px;
      width: 30px;
      text-align: center;

      .ellipsis-v {
        padding-top: 13px !important;
        font-size: 24px !important;
        &:hover {
          color: #382E34 !important;
        }
      }
    }
  }

  tr.selected td {
    background-color: rgba(154,224,252, 0.5) !important;
    border-top: 2px solid #9AE0FC !important;
    border-bottom: 2px solid #9AE0FC !important;

    .icons-container {
      font-weight: 400 !important;
    }

    &:first-child {
      border-left: 2px solid #9AE0FC !important;
    }
    &:last-child {
      border-right: 2px solid #9AE0FC !important;
    }
    .actionsDotsCell {
      display:block;
    }
  }
  tr.selected td.ant-table-row-cell-break-word {
    //font-weight: 400 !important;
  }
  tr.selected .parent-modified {
    font-weight: 400 !important;
  }
  .icon-v12-arrow-medium {
    font-size: 20px;
    padding-top: 0px;
    padding-right: 0px;
    margin-left: -5px;

    &:before {
      margin-right: 0px;
      line-height: 20px;
      margin-left:0;
    }
    &:not(.expanded) {
      transform: rotate(180deg);
      padding-top: 0px;
      padding-right: 0;
      margin-left: 0px;
      &:before {
        margin-right: 0px;
      }
    }
  }
  .icon-v12-arrow-medium + img {
    margin-left: 10px;
  }
  .icon-v12-arrow-medium.up:before {
    transform: rotate(180deg);
  }
  .icon-v12-file-approved {
    font-size: 16px;
    color: #6e7882;
  }
  .icon-v12-file-redacted {
    font-size: 16px;
    color: #6e7882;
  }
  .icon-v12-file-sent {
    font-size: 16px;
    color: #6e7882;
  }
  .icon-v12-options {
    font-size: 30px;
    color: #382e34;
    display:inline-block;
    padding-top: 0px;

    &:before {
      margin: 0;
    }
  }
  tr > th:not(:first-child) {
    padding-left: 5px;
  }
  .ant-table-column-sorter {
    display:none !important;
  }
}
.filter-submenu ul.ant-dropdown-menu {
  margin-left: -2px;
  border: 1px solid #EDA41E;
  border-bottom: 1px solid #EDA41E !important;
}
.popup-position ul.ant-dropdown-menu {
  margin-left: -2px;
  border: 1px solid #eda41e;
  border-bottom: 1px solid #eda41e !important;
}
.filter-menu .ant-input {
  border-radius: 0;
  border: 1px solid #150010;
  height: 30px;
  padding: 10px 7px 10px 7px;
  line-height: 20px;
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color:#a6acb2;
  &:focus {
    box-shadow: 0 0 0 1px var(--generalIconFontHover);
    /*border-color: #00d4d8 !important;*/
    border: none;
    border-radius: 0;
    border-color: #eda41e !important;
  }
  &:hover {
    color:#a6acb2;
    border-color: var(--generalIconFontHover) !important;
    border-radius: 0;
  }
}
.filter-menu .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
}
.ant-select-dropdown.per-page-select {
  border:1px solid var(--generalIconFontHover);
}
.per-page-select {
  top: 132px !important;
  width: 80px !important;
  position: fixed !important;

  ul {
    padding: 0;

    li.ant-select-dropdown-menu-item {
      padding: 0 12px;
      line-height: 30px !important;
      color: #6e7882 !important;

      &.ant-select-dropdown-menu-item-selected {
        background-color: #fff;
        font-weight: 400;
      }
    }
  }
}

// not collapsed in folder
.ant-select-dropdown.per-page-select.from-folder.false.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft{
  width: 80px !important;
  top: 132px !important;
  position: fixed !important;  
 // left: 477px !important;
 // @media screen and (max-width: 1280px) {
 //left: 390px !important;
 // }
}
// collapsed in folder
.ant-select-dropdown.per-page-select.from-folder.true.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft{
  width: 80px !important;
  top: 132px !important;
  position: fixed !important;  
  //left: 105px !important;
}
.ant-select-dropdown.per-page-select.from-folder.list{
  left: 83px !important;
}
// .ant-select-dropdown-placement-bottomLeft:hover{
//   left:inherit;
// }
// homepage 
.per-page-select.from-homepage {
  width: 80px !important;
  position: fixed !important;
  top: 92px !important;
  //left: 270px !important;
}


.icon-v14-show-20:before, .icon-v14-show-50:before, .icon-v14-show-100:before {
  width: 1px;
}
.icon-v14-show-20-active:before, .icon-v14-show-50-active:before,  .icon-v14-show-100-active:before {
  width: 1px;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f7ff !important;
}
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #6e7882 !important;
}

.ant-table {
  td p, td img {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }
  .ant-table-tbody table {
    margin-bottom: 60px;
  }
}

.files-table .dots-menu.dropdown {
  a {
    border-bottom: none;
    //padding: 0px 10px;
  }
  .menu-item-category {
    height: 1px;
    padding: 0;
    border-bottom: 1px solid #D4D7DA;
    margin: 0px 10px;
    width: 86%;
  }
}
.files-table tr .ant-dropdown-trigger .icon-v12-options {
  display:none;
}

.files-table tr:hover .ant-dropdown-trigger .icon-v12-options {
  display:block;
}
.row-actions-menu {
  margin-top: -20px !important;
}
.files-table .dots-menu .context-sub-menu {
  padding:5px 10px;
  font-weight: 400;
  color: #212529;

  nav.react-contextmenu {
    display: none;
  }
}
.files-table .dots-menu .context-sub-menu:hover {

  background-color: rgba(154,224,252,.3137254902);
  //color: white;

  nav.react-contextmenu {
    display: block;
    top: -5px !important;
    left: -211px !important;

    border: solid 1px rgba(110, 120, 130, 0.29);
    & > div {
      //color: #212529;
      //border: none !important;
      &:hover {
        //color: white;
      }
    }
  }
}
.icon-v15-duplicate {
  color: #491bc2;
}

.ant-table-column-title {
  span {
    margin-right: 5px;
  }
}
.empty-25 {
  width: 25px;
}

.navigation-modal-delete {
  width: 450px !important;
  .icon-v14-delete {
    font-size: 40px;
    color: #382e34;
  }
  .ant-modal-content {
    box-shadow: 2px 14px 30px rgba(21, 0, 16, 0.3) !important;
    border-radius: 0px;
    .ant-modal-body {
      padding: 30px;

      .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-body {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 0px;

        .ant-modal-confirm-content {
          margin-left: 0;
          margin-top: 10px;
          font-size: 14px;
          font-weight: 700;
          color: #382e34;
        }
      }
        .ant-modal-confirm-btns {
          margin-top: 40px !important;
          margin-bottom: 0px;
          .cancel-button {
            background-color: #FFFFFF;
            color: #6e7882;
            width: 100px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: 'Roboto';
            font-weight: 400;
            border-radius: 30px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            line-height: 0px;
            span {
              padding: 0px;
            }
          }
          .cancel-button:hover {
            color: var(--generalButtonHover);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
          }
          .cancel-button:active {
            color: var(--generalButtonActive);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
          }
          .confirm-button {
            background-color: var(--generalButton);
            color: var(--generalButtonFont);
            width: 100px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: ROBOTO;
            font-weight: 400;
            border-radius: 30px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            span {
              padding: 0px;
            }
          }
          .confirm-button:hover {
            //color: #FFFFFF;
            color:var(--generalButtonFont);
            border: 1px solid var(--generalButtonHover);
            background-color: var(--generalButtonHover);
          }
          .confirm-button:active {
            //color: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
          }
        }
    }
    }
  }
}
.new-content-smart-box {
  .react-contextmenu-submenu {
    .react-contextmenu-item {
      display: flex;
      justify-content: flex-start;
      padding-left: 10px;
    }
  }
}
.list-view-content {
  .react-contextmenu {
    .react-contextmenu-item {
      width: 200px;
      height: 30px;
      border: 1px solid #382e34;
    }
  }
}
.ant-dropdown-menu {
  padding: 10px 10px 10px !important;
  box-shadow: 0px 0px 20px rgba(21,0,16,0.2) !important;
  position: absolute !important;
}
.ant-dropdown {
  position:fixed !important;
}

.dropdown-filter-box {
  z-index:3 !important;
}
.dropdown-filter-file {
  z-index:3 !important;
}
.icon-none {
  margin-left: 15px !important;
}
.icon-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  //background-color: #0b2e56;
  display: flex;
}
.item-tagName {
  width: 120px;
  text-wrap: noWrap;
}
.item-options-status-new {
  color: #000000 !important;
  font-weight: 500 !important;
  opacity: 0.8;
  padding: 5px 6px !important;
  display: flex !important;
  align-items: center !important;
  line-height: 25px!important;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-wrap: nowrap;
  }
  .anticon {
    margin-right: 4px;
    padding-left: 2px;
  }
}
.dropdown-status-box {
  z-index:3 !important;
  border: 1px solid var(--generalIconFontHover);
  width: 200px;
  height: auto;
  background-color: #FFFFFF;
  .ant-select-open .ant-select-selection {
    border: 1px solid #d9d9d9;
  }
  .ant-select-selection:hover {
    border-color: var(--generalIconFontHover);
  }
  .ant-select-selection:focus {
    border-color: var(--generalIconFontHover);
  }
  .dropdown-status {
    display: flex;
    flex-direction: column;
    padding: 0px 5px 0px 5px;
    .icon-none {
      margin-left: 15px;
    }
    .item-options-status {
      .icon-none {
        margin-left: 15px !important;
      }
    }
    .content-value-status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .anticon {
        margin-right: 2px;
      }
      .icon-v19-arrow-medium {
        font-size: 20px;
        color: #382e34;
      }
      .icon-v19-arrow-medium-rotate {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
      }
    }
    .content-value-status > span:nth-child(2) {
      display: inline-block;
      width: 129px;
    }
    .title-status {
      font-family: Roboto;
      font-size: 14px;
      color: #6E7882;
      margin-bottom: 10px;
      overflow-wrap: break-word;
      font-weight: bold;
    }

    .selected-icon {
      position: absolute;
      top: 30px;
      left: 7px;
      z-index: 1;
    }

    .custom-status-select {
      margin-top: 5px;
      margin-bottom: 5px;
      .ant-select-arrow {
        margin-right: 0px !important;
      }
      .css-1uccc91-singleValue {
        margin-left: 18px;
      }
      .status-circle {
        width: 15px !important;
        height: 15px !important;
        border-radius: 50px;
        float: left;
        margin-right: 10px;
      }
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
      .css-1dimb5e-singleValue {
        margin-left: 15px;
      }
    }
    .button-green:disabled {
      color: #D9D9D9;
      border: 1px solid #D9D9D9 !important;
      background-color: #fff;
      &:hover {
        color: #D9D9D9;
        border: 1px solid #D9D9D9 !important;
        background-color: #fff;
      }
      &:active {
        color: #D9D9D9;
        border: 1px solid #D9D9D9 !important;
        background-color: #fff;
      }
    }
    .button-green {
      color: #637882;
      border: 1px solid #637882;
      background-color: #fff;
      width: 100%;
      margin: 10px 0;
      font-family: ROBOTO;
      font-weight: 400;
      font-size: 16px;
      height: 25px;
      padding: 0px;
      text-transform: capitalize;
      border-radius: 20px;
      &:hover {
        color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
        background-color: #FFFFFF;
      }
      &:active {
        color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
        background-color: #FFFFFF;
      }
    }
    .button-green-loading {
      color: #FFC457;
      border: 1px solid #FFC457;
      background-color: #fff;
      width: 100%;
      margin: 10px 0;
      font-family: ROBOTO;
      font-weight: 400;
      font-size: 16px;
      height: 25px;
      padding: 0px;
      text-transform: capitalize;
      border-radius: 20px;
      .anticon-loading{
        margin-right: 15px !important;
      }
    }

  }
}
.modal-delete-failed {
  width: 450px !important;
  .ant-modal-confirm-content {
    height: 30px;
    text-align: center;
  }
  .ant-modal-confirm-btns {
    justify-content: flex-end !important;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .cancel-button {
    display: none !important;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .confirm-button {
   width: 100px !important;
  }
}

.custom-status-select-options {
  border: 1px solid var(--generalIconFontHover) !important;
}
@media only screen and (max-width: 1440px) {
  .dropdown-filter-box {
    top: 93px !important;
    .filter-menu .sectionsNewFilter {
      max-height: 432px;
    }
  }
  .dropdown-filter-file {
    top: 133px !important;
    .sectionsNewFilter {
      max-height: 432px;
    }
  }
}
@media only screen and (max-width: 1920px) and (max-height: 1080px) {
  .dropdown-filter-box {
    top: 93px !important;
    .filter-menu .sectionsNewFilter {
      //max-height: 432px;
    }
  }
  .dropdown-filter-file {
    top: 133px !important;
    .sectionsNewFilter {
      //max-height: 408px;
    }
  }
}