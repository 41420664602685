.buttonsArea__wrapper {
  display: flex;
  flex-direction: column;
}

.buttonsArea__wrapper button {
  height: 34px;
  margin: 10px;
}

@media screen and (min-width: 768px) {
  .buttonsArea__wrapper {
    flex-direction: row;
  }

  .buttonsArea__wrapper button {
    margin: 0 0 0 10px;
  }
}