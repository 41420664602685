.report-dashboard {
  background-color: #ffffff;
  padding: 30px 40px;
  margin-top: 34px;
  font-family: ROBOTO;
  &__header {
    margin-bottom: 40px;
    .title-header {
      font-size: 20px;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 30px;
      color: #382e34;
      font-weight: 600;
      line-height: 20px;
    }
    .icon-v14-question {
      font-size: 20px;
      color: #382e34;
      margin-left: 10px;
    }
    .help-message-locations {
      .content-help-message-locations {
        
        .text-information {
          font-size: 14px;
          font-weight: 400;
          margin-right: 3px;
        }
        .more-info-link {
          font-weight: 700;
          font-size: 14px;
          color: #00d4d8;
          cursor: pointer;
          text-decoration: underline;
          width: 65px;
          margin-left: 3px;
        }
      }
    }
  }
  &__content-dashboard {
    display: flex;
    justify-content: space-between;
    .content-cards {
      .ant-card {
        width: 200px;
        margin-bottom: 20px;
        padding: 20px;
        border: 1px solid #382e34;
      }
      .ant-card-head {
        border-bottom: none;
        padding: 0px;
        min-height: 0px;
      }
      .content-title-types {
        justify-content: space-between;
        display: flex;
        .icon-v20-expand {
          font-size: 20px;
        }
        .icon-v20-shrink {
          font-size: 20px;
        }
      }
      .ant-card-head-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #382e34;
        padding: 0px;
        margin-bottom: 12px;
        .content-files-type {
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          overflow: auto;
          max-height: 258px;
          height: auto;
          .files-type {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #382e34;
          }
        }
      }
      .ant-card-extra {
        padding: 0px;
        font-size: 20px;
        position: relative;
        .icon-v20-expand {
          position: absolute;
          right: -4px;
          top: -26px;
        }
        .icon-v20-shrink {
          position: absolute;
          right: -4px;
          top: -82px;
        }
      }
      .ant-card-body {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #EDA41E;
        padding: 0px;
      }
    }
    .content-ai-analysis {
      width: 100%;
      .first-ai-analysis {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        //width: 80%;
        //align-items: center;
      }
    }
    .ai-analysis {
      border: 1px solid #382e34;
      padding: 20px;
      //margin-left: 20px;
      width: 100%;
      margin:0px 20px 20px 20px;
      .title-ai-analysis {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #382e34;
        margin-bottom: 20px;
        display: flex;
      }
       .content-ai-analysis {
         display: flex;
         justify-content: space-between;
         width: 540px;
         .content-pia-chart {
           background-color: #ffffff;
         }
         .category-ai-analysis {
           .category-ai {
             display: flex;
             align-items: center;
             padding-bottom: 10px;
             .title-category {
               font-weight: 400;
               font-size: 14px;
               line-height: 20px;
               letter-spacing: 0.5px;
               color: #382e34;
             }
             .ball-pii {
               width: 12px;
               height: 12px;
               border-radius: 50%;
               background-color: #00d4d8;
               margin-right: 10px;
             }
             .ball-dictionary {
               width: 12px;
               height: 12px;
               border-radius: 50%;
               background-color: #01E493;
               margin-right: 10px;
             }
             .ball-reg-ex {
               width: 12px;
               height: 12px;
               border-radius: 50%;
               background-color: #E4C801;
               margin-right: 10px;
             }
           }
         }
       }
    }

    .content-work-progress {
      display: flex;
      flex-direction: column;
      .work-progress-content {
        display: flex;
        flex-direction: column;
        width: 316px;
        border: 1px solid #382e34;
        padding: 20px;
        .title-work {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.5px;
          color: #382e34;
          margin-bottom: 20px;
        }
        .progress-contain {
          width: 316px;
          max-width:275px;
          overflow-y: auto;
          max-height: 148px;
          .progress-all {
            display: flex;
            flex-direction: row;
            align-items: center;
            .title-progress {
              font-weight: 400;
              font-size: 14px;
              line-height: 14px;
              letter-spacing: 0.5px;
              color: #382e34;
              margin-right: 10px;
              width: 88px;
              margin-bottom: 10px;
            }
            .ant-progress-text {
              margin-left: 10px;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.5px;
              color: #382e34;
            }
            .ant-progress-outer {
              display: inline-block;
              width: 80%;
            }
            .ant-progress-inner {
              border: 1px solid #382e34;
            }
            .ant-progress-bg {
              background-color: #EDA41E !important;
              //border: 1px solid #382e34;
            }
          }
        }
      }

      .content-latest-member {
        border: 1px solid #382e34;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 100%;
        .title-member {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.5px;
          color: #382e34;
          margin-bottom: 20px;
          display: flex;
        }
        .latest-member-interactions {
          overflow: auto;
          max-height: 290px;
          //height: 70px;
          .picture-member {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-top: 3px;
          }
          .member-interactions {
            width: 100%;
            display: flex;
            .interactions-title {
              width: 50px;
            }
            .member-list {
              display: inline-grid;
              margin-left: 10px;
              margin-bottom: 10px;
              width: 70%;
              .name-member {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.5px;
                color: #382e34;
              }
              .name-message {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #382e34;
                //margin-top: 6px;
                //margin-bottom: 6px;
              }
              .time-member {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.5px;
                color: #6e7882;
              }
            }
          }
        }
      }
    }
  }
  &__content-chart-bubble {
    position: relative;
    .activity-content {
      display: flex;
      width: 52%;
      justify-content: space-between;
      position: absolute;
      top: 12%;
      left: 14%;
      z-index: 10;
      .name-activity {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        font-family: Roboto;
      }
      .info-date {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #6e7882;
        font-family: Roboto;
      }
    }
    .chart-bubble {

    }
    .v-axis-bubble {
      position: absolute;
      height: 168px;
      left: 55px;
      top: 166px;
      z-index: 12;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 10px;
       .title-v-axis {
         font-weight: 400;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: 0.2px;
         color: #382e34;
         font-family: Roboto;
         display: flex;
         flex-direction: row-reverse;
       }
    }
    .h-axis-bubble {
      position: absolute;
      left: 195px;
      z-index: 12;
      bottom: 60px;
      display: flex;
      justify-content: space-evenly;
      width: 615px;
      .title-h-axis {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        font-family: Roboto;
        width: 155px;
        cursor: pointer;
        display: flex;
        justify-content: center;
      }
      .title-h-axis-active {
        color: #EDA41E;
      }
    }
  }
}