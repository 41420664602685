.ticket-log-page {
  width: 80%;
  .ticket-log-page-content {
    border-radius: 0 !important;
    font-family: Roboto;
    box-shadow: 2px 14px 30px rgba(21, 0, 16, 0.3) !important;
    .ticket-log-page-header {
      height: 40px;
      padding: 10px;
      background-color: var(--subNavigationBackground);
      border-radius: 0;
      border:none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .header-content-ticket-log-modal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .header-title {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color:#FFFFFF;
          line-height: 30px;
          width: 100%;
        }
        .icon-v14-close-black {
          font-size: 20px;
          color:#FFFFFF;
          cursor: pointer;
        }
      }
    }

    .ticket-log-page-contain {
      padding: 30px;
      background-color: #FFFFFF;
      .ticket-log-modal-body-content-headline {
        display: flex;
        justify-content: space-between;
        height: 30px;
        margin-bottom: 30px;
        .date-request-range-picker {
          display: flex;
          align-items: center;
          align-content: center;
          .date-picker-new-tickets {
            width: 16%;
            .ant-calendar-picker-input.ant-input {
              line-height: 1.5;
              //display: none;
            }
            .ant-calendar-picker-icon {
              display: none;
              color: rgba(0, 0, 0, 0.25);
              font-size: 14px;
              line-height: 1;
            }
            .ant-calendar-range-picker-input {
              width: 11%;
              height: 99%;
              text-align: center;
              background-color: transparent;
              border: 0;
              outline: 0;
            }
          }
          .content-range-picker-empty {
            color: #D4D7DA;
          }
          .content-range-picker-full {
            color: #382e34;
          }
          .content-range-picker {
            display: flex;
            align-items: center;
             .opening-date-contain {
               .content-headlines-date {
                 font-family: 'Roboto';
                 font-style: normal;
                 font-weight: 700;
                 font-size: 14px;
                 line-height: 20px;
                 letter-spacing: 0.2px;
                 //color: #382e34;
               }
               .start-date-empty {
                 font-family: 'Roboto';
                 font-style: normal;
                 font-weight: 400;
                 font-size: 14px;
                 line-height: 20px;
                 letter-spacing: 0.2px;
                 color: #382e34;
               }
               .content-headlines-date-start {
                 color: #382e34;
               }
               .start-date {
                 font-family: 'Roboto';
                 font-style: normal;
                 font-weight: 400;
                 font-size: 14px;
                 line-height: 20px;
                 letter-spacing: 0.2px;
                 //color: #382e34;
               }
             }
            .space-date {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              letter-spacing: 0.2px;
              //color: #382e34;
              padding-left: 10px;
              padding-right: 10px;
            }
            .number-days-empty {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.2px;
              //color: #D4D7DA;
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
        .date-request {
          .icon-v211-date {
            color: #382e34;
            font-size: 20px;
            //margin-left: 10px;
          }
          .icon-v211-date-active {
            font-size: 20px;
            //margin-left: 10px;
            color: #eda41e;
            cursor: pointer;
          }
          .opening-date {
            display: flex;
            justify-content: flex-end;
            .date-picker-ticket {
              .ant-calendar-picker-input.ant-input {
                border-bottom: none !important;
                padding-bottom: 15px;
                padding-left: 7px;
                padding-right: 0px;
                width: 80px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: .2px;
                color: #382e34;
              }
              .anticon-close-circle {
                display: none;
              }
              .anticon-calendar {
                display: none;
              }
            }
            .selected-opening-date {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.2px;
              color: #382e34;
              margin-left: 5px;
            }
          }

        }
        .process-time {
          .ant-select-selection--single {
            width: 150px;
            border: 1px solid #382e34;
            border-radius: 0;
            &:hover {
              border: 1px solid #eda41e;
            }
            .ant-select-selection__rendered {
              .ant-select-selection-selected-value {
                font-family: 'Roboto';
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: #382e34;
              }
            }
          }
        }
      }
      .wrapper-form-fields-two {
        display: flex;
        flex-direction: row;
        width: 100%;
        .wrapper-form-fields-phone {
          margin-left: 20px;
        }
        .wrapper-form-fields {
          width: 100%;
        }
      }
      .wrapper-form-fields {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        .ant-input[disabled] {
          background-color: #f5f5f5;
          border:1px solid  #f5f5f5;
          opacity: 0.5;
          &:hover {
            border:1px solid  #f5f5f5;
          }
        }
        .ant-input[disabled]:hover {
          border:1px solid  #f5f5f5;
        }
        .ant-input.request-details:disabled {
          border:1px solid  #f5f5f5;
          &:hover {
            border:1px solid  #f5f5f5;
          }
        }
        .ant-select-search__field {
          padding-left: 0px !important;
        }
        .select-form-fields {
          .ant-select-selection--single {
            border-radius: 0;
            border:1px solid  #382e34;
            height: 40px;
            .ant-select-arrow {
              right: 12px;
            }
            .ant-select-selection__rendered {
              height: 40px;
            }
            &:hover {
              border: 1px solid #eda41e;
            }
            &:active {
              border: 1px solid #eda41e;
            }
            .ant-select-selection-selected-value {
              font-family: 'Roboto';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              color: #382e34;
              line-height: 3;
            }
          }
        }
        .text-form-fields {
          border: 1px solid #382e34;
          border-radius: 0px;
          font-family: 'Roboto';
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          color: #382e34;
          height: 40px;
          &:hover{
            border: 1px solid #eda41e;
          }
          &:active {
            border: 1px solid #eda41e;
          }
          &:focus {
            border: 1px solid #eda41e;
            box-shadow: none;
          }
        }
        .ant-input.request-details {
          border-radius: 0;
          border: 1px solid #382e34;
          height: 80px;
          &:hover{
            border:1px solid #eda41e;
            box-shadow: none;
          }
          &:focus {
            border:1px solid #eda41e;
            box-shadow: none;
          }

        }
      }
      .wrapper-form-fields-with-checkbox {
        margin-top: 20px;
        margin-bottom: 10px;
        .ant-checkbox-wrapper {
          .ant-checkbox-inner {
            border-radius: 0!important;
            border:1px solid #6e7882;
            &:after {
              border-color:#eda41e;
              background-color: transparent;
            }
            &:hover {
              border-color: var(--generalIconFontHover);
            }
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: var( --generalIconFontActive) !important;
            &:after {
              position: absolute !important;
              display: table;
              color:#FFFFFF;
              border:none !important;
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: "\e8ce" !important;
              top: 8px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: "smartbox_icons_v19" !important;
            }
          }
        }
      }
      .ticket-log-modal-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .button-cancel-action {
          background-color: #FFFFFF;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #6e7882;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          height: 25px;

          &:hover {
            background-color: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
            color: var(--generalButtonHover);
          }

          &:active {
            background-color: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
            color: var(--generalButtonActive);
          }
        }
        .button-cancel-action.loading {
          border:1px solid #FFC457;
          color: #FFC457;
        }
        .button-open-ticket-action {
          background-color: var(--generalButton);
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: var(--generalButtonFont);
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          margin-left: 40px;
          height: 25px;

          &:hover {
            background-color: var(--generalButtonHover);
            border: 1px solid var(--generalButtonHover);
            //color: #FFFFFF;
          }

          &:active {
            background-color: var(--generalButtonActive);
            border: 1px solid var(--generalButtonActive);
            //color: #FFFFFF;
          }

          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
          }
        }
        .button-open-ticket-action.button-loading {
          border: 1px solid #eda41e;
          background-color: #FFC457;
        }
      }

    }
  }

  .modal-content-headlines-disabled {
    opacity: 0.5;
  }
  .modal-content-headlines {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #382e34;
  }
  .modal-content-headlines.update {
    margin-right: 10px;
  }
  .modal-content-body-data {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #382e34;
  }

}

.ticket-log-modal {
  width: 790px !important;
  .ant-modal-content {
    border-radius: 0 !important;
    font-family: Roboto;
    box-shadow: 2px 14px 30px rgba(21, 0, 16, 0.3) !important;
    .ant-modal-header {
      height: 40px;
      padding: 10px;
      background-color: #665360;
      border-radius: 0;
      border:none;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color:#FFFFFF;
        line-height: 30px;
        width: 100%;
        .header-content-ticket-log-modal {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .ant-modal-body {

      padding: 30px;

      .ticket-log-modal-body-content-headline {
        display: flex;
        justify-content: space-between;
        height: 30px;
        margin-bottom: 30px;
        .date-request {
          .icon-v211-date {
            color: #382e34;
            font-size: 20px;
            margin-left: 10px;
          }
          .icon-v211-date-active {
            font-size: 20px;
            margin-left: 10px;
            color: #eda41e;
          }
        }
        .process-time {
          .ant-select-selection--single {
            width: 80px;
            border: 1px solid #382e34;
            border-radius: 0;
            &:hover {
              border: 1px solid #eda41e;
            }
            .ant-select-selection__rendered {
              .ant-select-selection-selected-value {
                font-family: 'Roboto';
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: #382e34;
              }
            }
          }
        }
      }
      .wrapper-form-fields {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        .ant-input[disabled] {
          background-color: #f5f5f5;
          border:1px solid  #f5f5f5;
          opacity: 0.5;
          &:hover {
            border:1px solid  #f5f5f5;
          }
        }
        .ant-input[disabled]:hover {
          border:1px solid  #f5f5f5;
        }
        .ant-input.request-details:disabled {
          border:1px solid  #f5f5f5;
        }
        .select-form-fields {
          .ant-select-selection--single {
            border-radius: 0;
            border:1px solid  #382e34;
            height: 40px;
            .ant-select-arrow {
              right: 12px;
            }
            .ant-select-selection__rendered {
              height: 40px;
            }
            &:hover {
              border: 1px solid #eda41e;
            }
            &:active {
              border: 1px solid #eda41e;
            }
            .ant-select-selection-selected-value {
              font-family: 'Roboto';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              color: #382e34;
              line-height: 3;
            }
          }
        }
        .text-form-fields {
          border: 1px solid #382e34;
          border-radius: 0px;
          font-family: 'Roboto';
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          color: #382e34;
          height: 40px;
          &:hover{
            border: 1px solid #eda41e;
          }
          &:active {
            border: 1px solid #eda41e;
          }
          &:focus {
            border: 1px solid #eda41e;
            box-shadow: none;
          }
        }
        .ant-input.request-details {
          border-radius: 0;
          border: 1px solid #382e34;
          height: 80px;
          &:hover{
            border:1px solid #eda41e;
            box-shadow: none;
          }
          &:focus {
            border:1px solid #eda41e;
            box-shadow: none;
          }

        }
      }
      .wrapper-form-fields-with-checkbox {
        margin-top: 20px;
        margin-bottom: 10px;
        .ant-checkbox-wrapper {
          .ant-checkbox-inner {
            border-radius: 0!important;
            border:1px solid #6e7882;
            &:after {
              border-color:#eda41e;
              background-color: transparent;
            }
            &:hover {
              border-color:#eda41e;
            }
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #EDA41E !important;
            &:after {
              position: absolute !important;
              display: table;
              color:#FFFFFF;
              border:none !important;
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: "\e8ce" !important;
              top: 8px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: "smartbox_icons_v19" !important;
            }
          }
        }
      }
      .ticket-log-modal-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .button-cancel-action {
          background-color: #FFFFFF;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #6e7882;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          height: 25px;

          &:hover {
            background-color: #FFFFFF;
            border: 1px solid #FFC457;
            color: #FFC457;
          }

          &:active {
            background-color: #FFFFFF;
            border: 1px solid #EDA41E;
            color: #EDA41E;
          }
        }
        .button-cancel-action.loading {
          border:1px solid #FFC457;
          color: #FFC457;
        }
        .button-open-ticket-action {
          background-color: #EDA41E;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #FFFFFF;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          margin-left: 40px;
          height: 25px;

          &:hover {
            background-color: #FFC457;
            border: 1px solid #FFC457;
            color: #FFFFFF;
          }

          &:active {
            background-color: #EDA41E;
            border: 1px solid #EDA41E;
            color: #FFFFFF;
          }

          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
          }
        }
        .button-open-ticket-action.button-loading {
          border: 1px solid #eda41e;
          background-color: #FFC457;
        }
      }
    }
  }
  .modal-content-headlines-disabled {
    opacity: 0.5;
  }
  .modal-content-headlines {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #382e34;
  }
  .modal-content-headlines.update {
    margin-right: 10px;
  }
  .modal-content-body-data {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #382e34;
  }
}
.ant-select-dropdown.select-per-page-tickets {
  ul {
    padding: 0;
    li {
      padding: 5px 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
    }
  }
}
.opening-date-ticket {
  z-index: 1 !important;
  .ant-calendar {
    border-color: #eda41e !important;
  }
}

.date-picker-tickets {
  width: 520px !important;
  z-index: 1 !important;
  top: 200px !important;
  .ant-calendar {
    border-color: #eda41e !important;
  }
  .ant-calendar-range {
    width: 520px !important;
    overflow: hidden;
  }
  .ant-calendar-footer-extra {
    .render-extra-footer {
      display: flex;
      align-items: center;
      .time-frame {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
      .content-total-days {
        .remove-day-disabled {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #382e34;
          padding-left: 5px;
          padding-right: 5px;
          &:hover {
            color: #382e34;
            cursor: not-allowed;
          }
        }
        .remove-day {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #382e34;
          padding-left: 5px;
          padding-right: 5px;
          &:hover {
            color: #eda41e;
            cursor: pointer;
          }
        }
        .total-days {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #382e34;
        }
        .add-day {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #382e34;
          padding-left: 5px;
          padding-right: 5px;
          &:hover {
            color: #eda41e;
            cursor: pointer;
          }
        }
      }
    }
  }
}