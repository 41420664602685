.whatIsBusinessProcessPia__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 40px;
  padding: 20px;
  position: relative;
}

.whatIsBusinessProcessPia__title {
  color: #212A34;
  font-size: 24px;
  line-height: 1.2;
  margin: 20px 0 10px 0;
  text-align: center;
}

.whatIsBusinessProcessPia__subtitle {
  color: #737D87;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: center;
}

.whatIsBusinessProcessPia__container {
  border-top: 1px solid #DDDDDD;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
}

.whatIsBusinessProcessPia__item {
  border-bottom: 1px solid #DDDDDD;
  margin-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
}

.whatIsBusinessProcessPia__step {
  color: #3DD8DB;
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.whatIsBusinessProcessPia__containerImage {
  align-items: center;
  display: flex;
  height: 145px;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 40px;
}

.whatIsBusinessProcessPia__itemTitle {
  margin: 20px 0;
}

.whatIsBusinessProcessPia__itemText {
  color: #737D87;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .whatIsBusinessProcessPia__container {
    flex-direction: row;
  }
  .whatIsBusinessProcessPia__item {
    border-bottom: none;
    margin-top: 30px;
    padding: 0 20px;
  }
}