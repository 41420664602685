@font-face {
  font-family: 'smartbox_icons_v16';
  src: url('./font_v20/smartbox_icons_v16.eot?63159772');
  src: url('./font_v20/smartbox_icons_v16.eot?63159772#iefix') format('embedded-opentype'),
       url('./font_v20/smartbox_icons_v16.woff2?63159772') format('woff2'),
       url('./font_v20/smartbox_icons_v16.woff?63159772') format('woff'),
       url('./font_v20/smartbox_icons_v16.ttf?63159772') format('truetype'),
       url('./font_v20/smartbox_icons_v16.svg?63159772#smartbox_icons_v16') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'smartbox_icons_v16';
    src: url('../font/smartbox_icons_v16.svg?63159772#smartbox_icons_v16') format('svg');
  }
}
*/
[class^="icon-v20"]:before, [class*=" icon-v20"]:before {
  font-family: "smartbox_icons_v16";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-v20-avatar:before { content: '\e934'; } /* '' */
.icon-v20-expand:before { content: '\e8ba'; } /* '' */
.icon-v20-shrink:before { content: '\e8bc'; } /* '' */
