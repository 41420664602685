.explore-display-none {
  visibility: hidden;
}
.explore-data-page {
  position: relative;
  z-index: 3;
  width: 100%;
  background: #FFFFFF;
  padding: 50px 20px 50px 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: 40px;

  font-family: 'Roboto';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  #export_btn {
    position: absolute;
    top: -45px;
    right: 20px;
  }
  .ant-btn {
    width: 180px;
    height: 40px;
    object-fit: contain;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
    background-color: #232B38;
    border-radius: 30px;
    border: 1px solid #232B38;

    &:hover {
      border: 1px solid #232B38;
      color: #232B38;
      background-color: #FFFFFF;
    }

  }

  &__content-export-data {
     .new-content-category {
       padding-left: 20px;
     }
    .content-category {
      display: flex;
      padding-top: 40px;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 40px;
      flex-wrap: wrap;
      .select-category {
        display: grid;
        color: #6e7782;
        padding-left: 20px;
        padding-bottom: 20px;

        .label-category {
          padding-bottom: 10px;
          color: #6e7782;
          font-weight: 500;
        }

        .ant-select .ant-select-selection .ant-select-arrow {
          margin-right: 0;
          margin-left: 10px;
        }
        .ant-select {
          width: 506px;
        }
      }
      .content-category-buttons {
        padding-left: 20px;
        .ant-btn {
          width: auto;
          height: 40px;
          object-fit: contain;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #232B38;
          background-color: white;
          border-radius: 30px;
          border: 1px solid #232B38;
          margin-right: 20px;

          &:hover {
            border: 1px solid #232B38;
            color: #ffffff;
            background-color: #232B38;
          }
          &:disabled {
            opacity: 0.4;
            color: #232B38;
            background-color: white;
            border: 1px solid #232B38 !important;
          }
        }
      }
    }
    .label-search {
      padding-top: 20px;
      padding-left: 20px;
      color: #6e7782;
      font-weight: 500;
    }
    .condition-or {
      padding-left: 20px;
      color: #6e7782;
      font-weight: 500;
    }
    .content-search-category {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 20px;

      .option-category {
        display: grid;
        color: #6e7782;
        padding-left: 20px;
        .label-search {
          padding-bottom: 10px;
          color: #6e7782;
          font-weight: 500;
        }
      }
      .ant-select {
        width: 506px;
      }
      .signal-and {
        padding-bottom: 6px;
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .content-buttons-add {
      padding-left: 20px;
      padding-top: 20px;
      font-size: 30px;

      .anticon-plus-circle {
        padding-right: 20px;
        color: #00d3d8;
      }
      .anticon-minus-circle {
        color: #6e7782;
      }
    }

    .buttons-search {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 20px;
      padding-bottom: 20px;

      .ant-btn {
        width: 180px;
        height: 40px;
        object-fit: contain;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #FFFFFF;
        background-color: #232B38;
        border-radius: 30px;
        border: 1px solid #232B38;

        &:hover {
          border: 1px solid #232B38;
          color: #232B38;
          background-color: #FFFFFF;
        }
      }
    }
  }

  &__content-table-search {
    padding: 50px 20px 20px 30px;
    .find-result {
      padding-bottom: 10px;
      font-weight: bold;
      color: #6e7782;
      display: block;
    }
    .ant-table table {
      border-radius: 0;
      border: 1px solid #eeeeee;
      .ant-table-thead {
        background-color: #eeeeee;
      }

      .ant-table-tbody {
        tr {
          td {
            border-right: 1px solid #eeeeee;
          }

          td:last-child {
            border-right: none;
          }
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
    .table-search {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #EEEEEE;

      .table-search-title {
        border: 1px solid #e6e6e6;;
        font-weight: 500;
        padding: 12px 20px;
        text-align: left;
        text-transform: uppercase;
        color: #6e7782;
        font-size: 16px;
        background-color: #eeeeee;
      }

      .table-search-content {
        border: 1px solid #e6e6e6;;
        font-weight: 400;
        padding: 12px 20px;
        position: relative;
        text-align: left;
        vertical-align: middle;
        opacity: 0.6;
      }
    }
  }

}