.newQuestionnaire__update {
  padding:120px 20px 0px;
}
.newAddUser_section{
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
  height: max-content;
}
.newQuestionnaire__section {
    background-color: #FFFFFF;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    padding-bottom: 5px;
    margin-bottom: 20px;
    position: relative;
}
.newTitle_header{
  min-height: 170px;
  display: block;
  padding: 20px;
}

.fileContainer .buttonExcelClassChange{
  background-color: #268a3d;
  border: 1px solid #268a3d;
  background: #FFFFFF;
  color: #268a3d;
  height: 54px;
  width: 266px;
  weight: 700px;
  font-family:sans-serif;
  font-weight: bold;
  margin-left: -13%;
}

.fileContainer .buttonExcelClassChange:hover{
  background: #268a3d;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}

.fileContainer .buttonUploadNewUsers{
  background-color: #FFFFFF;
  border: 1px solid #3DD8DB;
  background: #3DD8DB;
  color: #FFFFFF;
  height: 54px;
  width: 266px;
  @extend .styleFont;
  margin-top : 10px;
  margin-left: 424px;
}

.fileContainer .buttonUploadNewUsers:hover{
  background: #FFFFFF;
  border: 1px solid #3DD8DB;
  color: #3DD8DB;
}

.teamIconExcel {
 width: 80px;
 height: 80px;
 border-radius: 50%;
 float: left;
 margin-left: 4px;
}
.newAddUser_formGroup {
  width: 96%;
  .error-message-send-email {
    color: red;
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
  }
}

.newAddUser_formGroup input {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #5555554f;
  background: #cccccc17;
  @extend .styleFont;
  padding: 0 10px 130px 14px;
  width: 100%;
  height: 180px;
  margin-left: 7px;
  }
  .user-footer{
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    flex-direction: row;
    button:disabled {
      background-color: #FFFFFF;
      border: 1px solid #D4D7DA !important;
      color: #D4D7DA;
      box-shadow: none;
      cursor: default;
    }
  }

  .dpiaDashboard-learn2 {
    -ms-flex-align: center;
    align-items: center;
    background-color: #20e0111a;
    border: 1px solid #37bf577d;
    border-radius: 5px;
    color: #37bf577d;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 14px 15px 14px 25px;
    width: 96%;
    margin-top: -2px;
    margin-right: 20px;
    margin-left: 25px;
    line-height: 18px;

  }
  .styleFont {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 700;
  }

.dpiaDashboard-learnLink {
  color: #3DD8DB;
  @extend .styleFont;
}

.dpiaDashboard-learnLink2 {
  color: #1b6d2e;
  @extend .styleFont;
}

.styleEditor{
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #5555554f;
    background: #cccccc17;
    @extend .styleFont;
    width: 152%;
    height: 220px;
    margin-bottom: 20px;
    padding: 14px 15px 14px -53px;
    margin-top: -2px;
    margin-right: 20px;
    margin-left: 25px;
    line-height: 18px;
}

#hot-display-license-info{
 display: none !important;

}
.mce-panel {
 width: 96% !important;
 margin-left: 7% !important;
}
.mce-container{
  margin-left: 2% !important;
}
.styleTitleCSV{
  margin-bottom: 0;
  line-height: 1.5;
  color: #111c26;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 443;
  margin: 0;
}

.styleTitleInput {
  margin: 1%;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 443;
}
.styleTitleInput2 {
  @extend .styleTitleInput;
    margin-left: 2%;
}
.mce-notification-inner{
  display: none !important;
}

.mce-notification .mce-close {
  display: none !important;
}
.mce-label {
  display: none !important;
}
.mce-statusbar .mce-container-body .mce-path {
  display: none !important;
}
div.mce-edit-area {
 height: 200px !important;
}
.mce-menubar .mce-menubtn {
  display: none !important;
}
i.mce-i-resize {
  display: none !important;
}

.mce-notification.mce-has-close {
  display: none;
}
.mce-container-body {
  margin-inline-start: -2% !important;
}

.styleTitlesAddUser {
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  margin: 0;
}
.styleBulk
{
  padding: 3px;
}
.styleTitlesAddUser1{
  margin-top: 2%;
}

.handsontable td {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
 vertical-align: middle !important;
 background-color: #eeeeee5c !important;
}

.button_big_green {
  outline: none;
  background-color: #FFFFFF;
  border: 1px solid #268a3d;
  background: #ffff;
  color: #268a3d;
  height: 48px;
  width: 266px;
  @extend .styleFont;
  margin-top: 10px;
  border-radius: 30px;
  text-transform: uppercase;
}
.button_big_green:hover {
  background-color: #268a3d;
  border: 1px solid #268a3d;
  background: #268a3d;
  color: #FFFFFF;
}
.button_big_green:focus {
  outline: none;
}
#file_csv_upload {
  display: none ;
}
.upload_csv_btn_container label {
  background-color: #3DD8DB;
  color: #FFFFFF;
  padding: 16px 27px;
  cursor: pointer;
  border-radius:30px;
  border: 1px solid #3DD8DB;
  @extend .styleFont;
  height: 48px;
  width: 266px;
  text-align: center;
  margin-top: 10px;
  margin-right: 5px;
}
.upload_csv_btn_container label:hover {
  color: #3DD8DB;
  background-color: #FFFFFF;
}
.download_csv_container {
  float: left;
  margin-left: 10px;
}
.upload_csv_container {
  float: right;
  margin-right: 25px;
}
.img_btn {
  width: 350px
}
#hot {
  width: 97%;
}

.fr-toolbar + div + div.fr-wrapper > div:first-child a {
  display:none !important;
}

@media screen and (max-width: 700px) {
  .teamIconExcel {
    width: 50px;
    height: 50px;
  }
  .styleUploadImage .button_big_green {
    height: 30px;
    width: 200px;
    font-size: 12px;
  }
  .upload_csv_btn_container label {
    padding: 8px 0;
    font-size: 12px;
    height: 30px;
    width: 200px;
  }
  .img_btn {
    width: 270px
  }
}

@media screen and (max-width: 600px) {
  #hot {
    overflow-x: auto;
    min-height: 10vh;
  }
}

@media screen and (max-width: 500px) {
  .download_csv_container {
    float: none;
    text-align: center;
    display: block;
    position: relative;
    margin: 0 auto; 
  }
  .upload_csv_container {
    text-align: center;
    float: none;
  }
  .img_btn {
    width: 265px;
    position: relative;
    margin: 0 auto;
  }
  .upload_csv_btn_container {
    width: 207px;
    position: relative;
    margin: 0 auto;
  }
}

.CSV-Table{
  th{
    background-color: rgb(241, 236, 236);
  }
  th, td{
    // padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
    font-weight: bold;
    font-size: 14px;
    padding-left:5px;
  }
  .Grey-border{
      text-align: center;
      padding: 1rem 6.4rem
  }
  .td-style{
    background-color: rgb(241, 236, 236);
    padding: 15px 20px;
  }
}

