.data-collection-workflow-page {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  &__header {
    display: flex;
    flex-direction: column;
    .name-data-collection {
      font-size: 18px!important;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
    }
  }
  &__content {
    border: 1px solid #382e34;
    margin-top: 40px;
      .content-company {
        display: flex;
        align-items: center;
        //height: 50px;
        background-color: #FFC457;
        padding: 10px 20px 10px 7px;
        border-bottom: 1px solid #382E34;
        .name-data-collection {
          font-weight: 700;
          padding-left: 6px;
        }
        .icon-v22-company {
          font-size: 40px;
        }
        .select-options-company {
          width: 40%;
          background-color: #FFC457;
          .ant-select-selection__placeholder {
            color: #382e34;
          }
          .ant-select-selection {
            background-color: #FFC457;
            border-radius: 0px;
            border: 1px solid #382e34;
          }
          .ant-select-arrow {
            color: #382e34;
          }
        }
      }

    .date-source {
      display: flex;
      flex-direction: row;
      .icon-v22-search-b {
        font-size: 20px;
        margin-top: 20px;
        margin-left: 55px;
      }
      .icon-v22-db-storage {
        font-size: 30px;
        margin-top: 20px;
        margin-left: 11px;
      }
      .content-date-source {
        width: 100%;
        padding: 20px 20px 20px 16px;
         .date-source-header {
           width: 100%;
           border-bottom: 1px solid #382e34;
           display: flex;
           justify-content: space-between;
           align-items: center;
           height: 30px;
           .icon-v22-add {
             color: #eda41e;
           }
           .icon-v22-add-active {
             color: #eda41e;
             cursor: pointer;
           }
           .name-data-source {
             font-size: 14px;
             font-weight: 700;
             line-height: 20px;
             letter-spacing: 0.2px;
             color: #382e34;
             //padding-bottom: 5px;
           }
         }
        .contact-details-search {
          border-bottom: 1px solid transparent !important;
        }
        .contact-details {
          margin-top: 20px;
          border-bottom: 1px solid #D4D7DA;
          .contact-details-name {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #382e34;
            padding-bottom: 5px;
            margin-bottom: 10px;
            display: flex;
          }
          .contact-details-contain {
              display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            align-items: center;
            .checkbox-contain {
               width: 50%;
              display: flex;
              .value-checkbox {
                .first-value-checkbox {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #382e34;
                }
                .last-value-checkbox {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #d4d7da;
                }
              }
              .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                margin-left: 0px;
              }
              .ant-checkbox-wrapper {
                line-height: 2;
                span:not(:first-child) {
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 30px;
                }
                .ant-checkbox-inner {
                  border-radius: 0!important;
                  border:1px solid #6e7882;
                  &:after {
                    border-color:#eda41e;
                    background-color: transparent;
                  }
                  &:hover {
                    border-color:#eda41e;
                  }
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: #EDA41E !important;
                  &:after {
                    position: absolute !important;
                    display: table;
                    color:#FFFFFF;
                    border:none !important;
                    transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    opacity: 1;
                    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                    content: "\e8ce" !important;
                    top: 8px !important;
                    left: 7px !important;
                    font-size: 12px !important;
                    font-family: "smartbox_icons_v19" !important;
                  }
                }
              }
            }

            .option-contact-details {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              justify-content: flex-end;
              .input-folder-name-remove {
                width: 100% !important;
              }
              .icon-v22-add {
                color: #eda41e;
              }
              .icon-v22-add-active {
                color: #eda41e;
                cursor: pointer;
              }
              .icon-v22-minus {
                color: #6e7882;
              }
              .icon-v22-minus-active {
                cursor: pointer;
              }
              .input-folder-name {
                border: 1px solid #382e34;
                color: #6e7882;
                background-color: rgba(255, 255, 255, 0.2509803922);
                padding: 0 10px;
                font-size: 14px;
                font-weight: 400;
                height: 30px;
                border-radius: 0px;
                cursor: default;
                width: 100%;
                margin-right: 10px;
                &:hover {
                  border: 1px solid #EDA41E;
                  box-shadow: none;
                  cursor: pointer;
                }
                &:focus {
                  border: 1px solid #EDA41E;
                  box-shadow: none;
                }
                &:active {
                  border: 1px solid #EDA41E;
                  box-shadow: none;
                }
              }
              .date-picker {
               // margin-top: 10px;
                //margin-bottom: 20px;
                border:1px solid #382e34;
                //border-radius: 4px;
                margin-right: 40px;
              }
              .date-picker:hover {
                border:1px solid #eda41e;
              }
              .ant-calendar-picker .ant-calendar-picker-input:hover {
                border-color: #eda41e !important;
                cursor: pointer;
              }
              .ant-calendar-range-picker-input:hover {
                cursor: pointer;
              }
              .ant-calendar-range-picker-input:placeholder-shown {
                cursor: pointer;
              }
              .ant-calendar-picker {
                width: 80%;
                .ant-calendar-picker-input {
                  border-bottom: none;
                }
                .ant-calendar-picker-icon {
                  right:12px;
                }
                .ant-calendar-range-picker-input::placeholder {
                  color: #382e34;
                  font-size: 16px;
                }
              }
              .select-details-email {
                width: 100%;
                margin-right: 10px;
                font-weight: 400;
                .ant-select-selection{
                  border: 1px solid #382e34;
                  border-radius: 0px;
                }
                .ant-select-selection:hover {
                  border-color: #eda41e;
                }
                .ant-select-selection:active {
                  border-color: #eda41e;
                }
                .ant-select-selection:focus {
                  border-color: #eda41e;
                }
                .ant-select-selection__rendered {
                  color: #382e34;
                }
              }
            }
          }
          .finance-payroll-system {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;
            .content-upload-data {
              display: flex;
              align-items: center;
              .anticon-spin {
                color: #3DD8DB;
                font-size: 20px;
                margin-right: 10px;
              }
            }
            .name-finance-payroll {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.2px;
              color: #382e34;
            }
            .icon-v22-upload-1 {
              font-size: 18px;
              &:hover {
                color: #eda41e;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .content-collect {
      display: flex;
      justify-content: flex-end;
      padding: 30px 20px;
      .button-collect-loading {
        background-color: #FFC457 !important;
      }
      .button-collect {
        background-color: #EDA41E;
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: none;
        color: #FFFFFF;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        padding: 3px !important;
        text-transform: none;
        transition: none;
        line-height: 18.75px;
        width: 180px;
        margin-left: 40px;
        height: 25px;

        &:hover {
          background-color: #FFC457;
          border: 1px solid #FFC457;
          color: #FFFFFF;
        }

        &:active {
          background-color: #EDA41E;
          border: 1px solid #EDA41E;
          color: #FFFFFF;
        }

        &:disabled {
          cursor: default;
          background: #D4D7DA !important;
        }
      }
    }
  }
  .import-data-collector {
    display: flex;
    margin-top: 20px;
    padding: 10px 10px 10px 10px;
    flex-direction: column;
    .header-import-data-collector {
      margin-bottom: 10px;
      border-bottom: 1px solid #382e34;
      padding-bottom: 5px;
      .title-import {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.2px;
        vertical-align: middle;
        color: #382e34;
      }
    }
    .content-import-data-collector {
      .ant-collapse {
        background-color: #FFFFFF;
        border: none;
      }
      .title-date-source-name {
        color: #000000 !important;
        line-height: 30px !important;
        font-family: Roboto !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        letter-spacing: 0.2px !important;
        font-style: normal !important;
      }
      .ant-collapse-item-active {
        //border-bottom: 1px solid #6e7882 !important;
      }
      .header-import-collapse {
        border-bottom: 1px solid #d4d7da !important;
        width: 100% !important;
        .header-import-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #d4d7da !important;
        }
      }
      .subheader-import-collapse {
        .ant-collapse-header {
          color: #000000 !important;
          line-height: 30px !important;
          font-family: Roboto !important;
          font-size: 14px !important;
          font-weight: 700 !important;
          letter-spacing: 0.2px !important;
          font-style: normal !important;
        }
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        position: relative;
        padding: 0px 0px;
        height: 30px;
        align-items: center;
        display: flex;
        padding-left: 40px;
        color: #000000;
        line-height: 30px;
        cursor: pointer;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px;
        font-style: normal;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 2px;
        margin-left: 40px;
      }
      .ant-collapse > .ant-collapse-item {
        border-bottom: none;
      }
      .source-items-queries {
        color: #000000;
        line-height: 30px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px;
        font-style: normal;
        display: flex;
        flex-direction: row;
      }
      .source-items {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 30px;
        justify-content: space-between;
        align-items: center;
        .data-source-collapse {
          display: flex;
          .data-source-item-name {
            color: #000000;
            line-height: 30px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
            font-style: normal;
          }
          .data-source-item-type {
            color: #d4d7da;
            line-height: 30px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;
            font-style: normal;
          }
        }
        .file-upload-number {
          color: #000000;
          line-height: 30px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.2px;
          font-style: normal;
        }
      }
      .paragraf-text {
        color: #000000;
        line-height: 30px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px;
        font-style: normal;
      }
      .ant-collapse-content {
        border-top: none;
      }
    }
  }
}
.dropdown-select-options-company {
  border: 1px solid #eda41e;
  z-index: 1!important;
}
.dropdown-select-details-email {
  border: 1px solid #eda41e;
  z-index: 1!important;
}
.date-picker-up {
  //z-index: 1 !important;
  .ant-calendar.ant-calendar-range {
    border: 1px solid #eda41e !important;
  }
}
