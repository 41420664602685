.about-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 120px 20px 0;
}

.about-footerContainer {
  display: flex;
  flex-direction: column;
}

.about-buttonAddContainer {
  margin-bottom: 30px;
}

.about-limitedFreeAccountContainer {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .about-page {
    padding: 120px 40px 0;
  }
  .about-footerContainer {
    flex-direction: row;
  }
  .about-buttonAddContainer {
    flex: 1;
  }
  .about-limitedFreeAccountContainer {
    margin-left: 20px;
  }
}