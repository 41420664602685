.builder-workflow {
  background-color: #FFFFFF;
  display: flex;
  //width: 100%;
  //padding: 160px 40px 0;
  padding: 30px 0px;
  margin: 134px 82px 0px;
  position: relative;
  &__page {
    width: 100%;
    height: 100%;
    .header-builder-workflow {
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size:20px;
        line-height: 20px;
      }
    }
    .header-builder {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 40px;
      .select-workflow {
        width: 20%;
        color: #6e7882;
        font-weight: 400;
        font-size: 14px;
      }
      .ant-select-selection {
        border-radius:0;
        &:hover {
          border-color:#eda41e;
        }
        &:focus{
          border-color: #eda41e;
        }

      }
      .action-header-builder {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .button-save-template {
          background-color: #EDA41E;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #FFFFFF;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 15%;
          margin-left: 40px;
          height: 26px;

          &:hover {
            background-color: #FFC457;
            border: 1px solid #FFC457;
            color: #FFFFFF;
          }

          &:active {
            background-color: #EDA41E;
            border: 1px solid #EDA41E;
            color: #FFFFFF;
          }

          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
          }
        }

        .button-cancel-template {
          background-color: #FFFFFF;
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #6e7882;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 15%;
          height: 26px;

          &:hover {
            background-color: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
            color: var(--generalButtonHover);
          }

          &:active {
            background-color: var(--generalButtonActive);
            border: 1px solid var(--generalButtonActive);
            color: #EDA41E;
          }
        }
      }
    }
    .input-header-builder {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: #EDA41E;
      margin-top: 30px;
      justify-content: space-between;
      .content-input-header-builder {
        width: 100%;
        button:disabled {
          background-color: #EDA41E;
        }
        .icon-v19-delete-active {
          cursor:pointer;
        }
      }
      .input-form-name {
        width: 50%;
        border: 1px solid #6e7882;
        color: #6e7882;
        background-color: #FFFFFF;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        height: 35px;
        border-radius: 0px;
        margin-left: 10px;
        &:hover {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:focus {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:active {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
      }
      .select-form {
        width: 20%;
        color: #6e7882;
        font-weight: 400;
        font-size: 14px;
        margin-right: 10px;
      }
      .ant-select-selection {
        border-radius:0;
        &:hover {
          border-color: #eda41e;
        }
        &:focus {
          border-color: #eda41e;
        }
      }
    }

    .page-builder-content {
      display: flex;
      margin-top: 10px;
      .form-builder-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        .header-component {
          margin-left: 20px;
          .icon-v19-delete {
            font-size: 14px;
            color: #382e34;
          }

          .icon-v19-delete-active {
            font-size: 14px;
            color: #EDA41E;
          }
        }
        .component-form {
          display: flex;
          font-family: Roboto;
          height: auto;
          align-items: center;
          margin-left: 0px;
          background-color: #F5F5F5;
          margin-bottom: 10px;
          padding-bottom: 10px;
          padding-top: 10px;
          .component-content-new {
            //align-items: center;
            .header-component {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-left: 0px;
              .header-title {
                margin-bottom: 0px;
              }
            }
          }
          .component-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            .content-form-variables {
              margin-left: 20px;
              margin-top: 10px;
              .variables {
                 margin-right: 10px;
                color: red;
                font-weight: 400;
                font-size: 14px;
              }
            }
          .select-content {
            width: 100%;
            display: flex;
            align-items: center;

            .icon-v19-delete {
              font-size: 14px;
              color: #382e34;
            }

            .icon-v19-delete-active {
              font-size: 14px;
              color: #EDA41E;
            }

            .select-form {
              width: 100%;
              color: #6e7882;
              font-weight: 400;
              font-size: 14px;
              margin-right: 10px;
              .ant-select-selection {
                border: 1px solid #382e34;
                border-radius: 0;
              }
              .ant-select-selection:hover {
                border: 1px solid #EDA41E !important;
                border-right-width: 1px !important;
              }
            }
          }
        }
          .component-move {
            width: 3%;
            display: flex;
            justify-content: center;

            .icon-v211-grid-view-1:hover {
              font-size: 14px;
              color: #EDA41E;
            }

            .icon-v211-grid-view-1 {
              font-size: 14px;
              color: #382e34;
            }
          }
        }
      }
    }
  }
}
.select-workflow-dropdown {
  border: 1px solid #382e34 !important;
  z-index:1 !important;
}