.locations__text {
  background-color: #F9F9FA;
  color: #43515E;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 10px 15px;
  text-transform: uppercase;
}

.locations__list-item {
  background-color: #F9F9FA;
  font-size: 14px;
  line-height: 1.25;
  padding: 10px 15px;
}

.locations__list-item:nth-of-type(odd) {
  background-color: #FFFFFF;
}

.locations__list-item:hover {
  background-color: #1DCBCF;
  color: #FFFFFF;
  cursor: pointer;
}