.modal-container, .modal-containerUser,  .modalCheckout{
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  height: 80vh;
  width: 80%;
  left: 50%;
  position: fixed;
  top: 50%;
  width: 25%;
  z-index: 10;
}
.modalCheckout{
  height: 82vh;
  left: 18%;
  width: 60%;
  top: 12%;

  .container {
    padding: 0;
  }
}

.modal__padding {
  height: calc(100% - 70px);
  margin-top: 70px;
  overflow-x: hidden;
  padding: 0 20px 20px;
}

.modal-title {
  color: #555555;
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-subtitle {
  color: #555555;
  font-size: 18px;
  margin: 10px 0;
}

.modal-text {
  line-height: 1.2;
  margin-bottom: 10px;
}

.modal-link {
  color: #3DD8DB;
  text-decoration: underline;
&:hover {
   font-style: italic;
 }
}

.modal-address {
  border-left: 2px solid #555555;
  line-height: 1.2;
  margin-bottom: 10px;
  padding-left: 10px;
}

.modal-navigation {
  margin-bottom: 20px;
}

.modal-listItem {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
&::before {
   background-color: #555555;
   border-radius: 50%;
   content: "";
   height: 5px;
   left: 3px;
   position: absolute;
   top: 7px;
   width: 5px;
 }
}

.modal-close {
  background: url('../../assets/icon-close.png') center center no-repeat #FFFFFF;
  cursor: pointer;
  height: 27px;
  margin-left: 363px;
  margin-top: -285px;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 27px;
  z-index: 15;
  margin-left: 355px;
  margin-top: -280px;
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .modal-container {
    height: 600px;
    left: 50%;
    margin-left: -350px;
    margin-top: -300px;
    width: 700px;
    top: 50%;
  }
  .modal-containerUser {
    height: 82vh;
    left: 18%;
    width: 25%;
    top: 12%;
  }
  .modalCheckout{
    height: 82vh;
    left: 18%;
    width: 60%;
    top: 12%;
  }
  .modal-close {
    margin-left: 302px;
    margin-top: -280px;
    padding: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .modal-container {
    height: auto;
    left: 42%;
    width: 60%;
    top: 40%;
  }
  .modal-containerUser {
    height: auto;
    max-height: 100%;
    left: 30%;
    width: 25%;
    top: 15%
  }
  .modalCheckout{
    height: 82vh;
    left: 18%;
    width: 60%;
    top: 12%;
  }
}

@media screen and (min-width: 2000px) {
  .modal-container {
    height: 50vh;
    left: 42%;
    width: 50%;
    top: 40%;
  }
  .modal-containerUser {
    height: 54vh;
    left: 31%;
    width: 25%;
    top: 17%
  }
  .modalCheckout{
    height: 67vh;
    left: 40%;
    width: 25%;
    top: 13%;
  }
}

.modal__addUser {
  position: absolute;
  z-index: 15;
  background-color: #FFFFFF;
  height: 80vh;
  width: 70%;
  top: 1%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1000px) {
  .modal__addUser {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .userSettings-footer button.button-dark {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1705px) {
  .modal-container {
    height: 80vh;
    left: 42%;
    width: 60%;
    top: 40%;
  }
}