.backOffice__tabsContainer {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
  position: relative;
}
.backOffice__tabsContainer li {
  margin: 0 10px;
  padding: 0 10px;
  border: 1px solid #AAA;
  background: #ECECEC;
  display: inline-block;
  position: relative;
  z-index: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
}

.backOffice__tabsContainer{
  padding-top:5px;
}

.backOffice__tabsContainer li.active {
  background: #FFF;
  color: #000;
  z-index: 2;
  border-bottom-color: #FFF;
}

.backOffice__tabsContainer:after {
  position: absolute;
  content: "";
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #AAA;
  z-index: 1;
}

.backOffice__tabsContainer:before {
  z-index: 1;
}

.backOffice__tabsContainer li:before,
.backOffice__tabsContainer li:after {
  border: 1px solid #AAA;
  position: absolute;
  bottom: -1px;
  width: 6px;
  height: 6px;
  content: " ";
}
.backOffice__tabsContainer li:before {
  left: -7px;
  border-bottom-right-radius: 6px;
  border-width: 0 1px 1px 0;
  box-shadow: 2px 2px 0 #ECECEC;
}
.backOffice__tabsContainer li:after {
  right: -7px;
  border-bottom-left-radius: 6px;
  border-width: 0 0 1px 1px;
  box-shadow: -2px 2px 0 #ECECEC;
}

.backOffice__tabsContainer li.active:before {
  box-shadow: 2px 2px 0 #FFF;
}
.backOffice__tabsContainer li.active:after {
  box-shadow: -2px 2px 0 #FFF;
}

.backOffice__tabsContainer li {
  background: linear-gradient(to bottom, #ECECEC 50%, #D1D1D1 100%);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4), inset 0 1px 0 #FFF;
  text-shadow: 0 1px #FFF;
  margin: 0 -5px;
  padding: 0 20px;
}
.backOffice__tabsSpacer {
  border-top: 7px solid #FFFFFF;
  z-index: 3;
  position: relative;
}