.newRepeatOption-yes, .newRepeatOption-no {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 45px;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  position: relative;
  width: 120px;
}

.newRepeatOption-yesLabel {
  border-radius: 45px;
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
&::before {
   background-color: #3DD8DB;
   border-radius: 50%;
   color: #FFFFFF;
   font-family: 'FontAwesome';
   font-size: 16px;
   content: '\f00c';
   height: 25px;
   line-height: 25px;
   left: 10px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.newRepeatOption-yesLabelDisabled {
  border-radius: 45px;
  color: #EEEEEE;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
&::before {
   background-color: #EEEEEE;
   border-radius: 50%;
   color: #FFFFFF;
   font-family: 'FontAwesome';
   font-size: 16px;
   content: '\f00c';
   height: 25px;
   line-height: 25px;
   left: 10px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.newRepeatOption-yesLabelChecked {
  background-color: #3DD8DB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
}

.newRepeatOption-yesLabelCheckedDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
&::before {
   background-color: #FFFFFF;
   border-radius: 50%;
   color: #EEEEEE;
   font-family: 'FontAwesome';
   font-size: 16px;
   content: '\f00c';
   height: 25px;
   line-height: 25px;
   left: 10px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.newRepeatOption-yesLabelChecked::before {
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #3DD8DB;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f00c';
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px;
}

.newRepeatOption-noLabel {
  border-radius: 45px;
  color: #3DD8DB;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
&::before {
   background-color: #3DD8DB;
   border-radius: 50%;
   color: #FFFFFF;
   font-family: 'FontAwesome';
   font-size: 16px;
   content: '\f00d';
   height: 25px;
   line-height: 25px;
   left: 10px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.newRepeatOption-noLabelDisabled {
  border-radius: 45px;
  color: #EEEEEE;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
&::before {
   background-color: #EEEEEE;
   border-radius: 50%;
   color: #FFFFFF;
   font-family: 'FontAwesome';
   font-size: 16px;
   content: '\f00d';
   height: 25px;
   line-height: 25px;
   left: 10px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.newRepeatOption-noLabelChecked {
  background-color: #3DD8DB;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
&::before {
   background-color: #FFFFFF;
   border-radius: 50%;
   color: #3DD8DB;
   font-family: 'FontAwesome';
   font-size: 16px;
   content: '\f00d';
   height: 25px;
   line-height: 25px;
   left: 10px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}

.newRepeatOption-noLabelCheckedDisabled {
  background-color: #EEEEEE;
  border-radius: 45px;
  color: #FFFFFF;
  cursor: not-allowed;
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  padding-left: 60px;
  position: absolute;
  text-transform: uppercase;
  transition: all .5s ease;
  top: 0;
  width: 120px;
  z-index: 1;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 18px;
&::before {
   background-color: #FFFFFF;
   border-radius: 50%;
   color: #EEEEEE;
   font-family: 'FontAwesome';
   font-size: 16px;
   content: '\f00d';
   height: 25px;
   line-height: 25px;
   left: 10px;
   position: absolute;
   text-align: center;
   top: 10px;
   width: 25px;
 }
}