.sar-settings-page {
  position: relative;
  margin-top: 0px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__wrapper {
    width: 95%;
    margin-top: 50px;
    &__insight {
      background-color: #ffffff;
      padding: 40px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
      .sar-settings-tabs {
        .ant-tabs-bar {
          border-bottom: none;
          .ant-tabs-nav  {
            width: 100%;
            &>div {
              display: flex;
              justify-content: space-between;
            }
            .ant-tabs-tab {
              border-bottom: none;
              background: white;
              font-weight: bold;
              color: #6e7882;
              font-size: 20px;
              height: 40px;
              padding: 0;
              line-height: 38px;

              &-active {
                height: 40px;
                border-bottom: 3px solid #00d3d8;
                transition: none;
              }
            }
            .ant-tabs-ink-bar {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}