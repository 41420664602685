@mixin btnModal () {
  width:180px;
  height:25px;
  border:1px solid #6e7882;
  background-color: #FFFFFF;
  box-shadow: none;
  cursor: pointer;
  padding:0 30px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  vertical-align: middle;
  line-height: 18.75px;
  //&:hover {
  //  background-color: #FFFFFF;
  //  border-color: #FFC457;
  //  color: #FFC457;
  //}

  &:focus {
    border: 1px solid #6e7882;
    background-color: #FFFFFF;
    color: #6e7882;
    -webkit-box-shadow: none;
    box-shadow:none;
  }

  //&:active {
  //  border: 1px solid #EDA41E;
  //  background-color: #FFFFFF;
  //  color: #EDA41E;
  //}
}
.data-mapping-modal-progress {
  .ant-modal-content {
    width: 790px !important;
    border-radius: 0 !important;
    font-family: Roboto;
    box-shadow: 2px 14px 30px rgba(21,0,16,0.3) !important;
    .ant-modal-body {
      padding: 30px 30px 30px 30px !important;
    }
  }
}
.data-mapping-modal {
  width:790px !important;
  .content-header-data-mapping {
    display: flex;
    justify-content: space-between;
  }
  .ant-modal-content {
    width: 790px !important;
    border-radius: 0 !important;
    font-family: Roboto;
    box-shadow: 2px 14px 30px rgba(21,0,16,0.3) !important;
    .ant-modal-body {
      padding: 30px;
    }
  }
  .ant-modal-header {
    height: 40px;
    padding: 10px;
    background-color: #665360;
    border-radius: 0;
    border:none;
    display: flex;
    align-items: center;
    .ant-modal-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color:#FFFFFF;
      line-height: 30px;
      width: 100%;
    }
  }
  .data-mapping-update {
    margin-top: 10px;
  }
  .select-connector-box {
    .ant-select-dropdown {
      .icon-box {
        width: 20px;
        height: 20px;
      }
    }
    .select-box {
      color: #637882;
      .icon-box {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 5px;
      }
    }
    .ant-select-selection-selected-value {
      display: flex !important;
      font-size: 14px;
      font-weight: 300;
      align-items: center;
    }
    .ant-select {
      width: 100%;
    }
    .ant-select-selection--single {
      height: 30px;
      display: flex;
      align-items: center;
      background-color: #fafafa;
    }
  }
  .data-mapping-modal-content {
   .title-select {
     font-family:Roboto;
     font-style:normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 20px;
  }
    .select-data-mapping-connector {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .date-picker {
      margin-top: 10px;
      margin-bottom: 20px;
      border:1px solid lightgray;
      border-radius: 4px;
    }
    .ant-calendar-picker {
      width: 40%;
      .ant-calendar-picker-input {
        border-bottom: none;
      }
      .ant-calendar-picker-icon {
        right:12px;
      }
    }
    .data-mapping-input {
      margin-top: 10px;
      margin-bottom: 20px;
    }

  }
  .btn-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .btn-container {
      button {
        cursor: pointer;
        width: 20px;
        height: 20px;
        font-size: 16px;
      }
      .icon-v16-minus, .icon-v16-add {
        &[disabled]{
          color:#D4D7DA;
        }
      }
      .icon-v16-minus {
        margin-right: 10px;
        color:#6e7882;
      }
      .icon-v16-minus-active {
        margin-right: 10px;
        color:#6e7882;
        &[disabled]{
          color:#D4D7DA;
        }
      }
      .icon-v16-add {
        color: var(--generalButton);
      }
      .icon-v16-add-active {
        color: var(--generalIconFontHover);
      }
    }

  }

  .footer-dataMapping-modal {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .action-cancel-search {
     @include btnModal;
      span {
        padding-top: 2px;
      }
      &:active {
        border:1px solid var(--generalButtonActive);
        background-color: #fff;
        color: var(--generalButtonActive);
      }
      &:hover {
        background-color: #FFFFFF;
        border-color: var(--generalButtonHover);
        color: var(--generalButtonHover);
      }
    }
    .action-button-search {
      background-color: var(--generalButton) !important;
      color:var(--generalButtonFont);
      font-weight: 500;
      alignment: center;
      @include btnModal;
      &:hover {
        background-color: var(--generalButtonHover) !important;
        border-color: var(--generalButtonHover);
        //color: #FFFFFF;
      }
      &:focus {
        background-color: var(--generalButtonActive) !important;
        color: var(--generalButtonFont);
        border: var(--generalButtonActive);
      }

      &:active {
        border: 1px solid var(--generalButtonActive);
        background-color: var(--generalButtonActive) !important;
      }
    }
    .action-button-search:disabled {
      background-color: #D4D7DA !important;
      color: #FFFFFF;
      cursor: default !important;
      font-weight: 500;
      alignment: center;
      @include btnModal;
      &:hover {
        background-color: #D4D7DA;
        border-color: #6e7782;
        color: #FFFFFF;
      }
      &:focus {
        background-color: #D4D7DA;
        color: #FFFFFF;
      }
      &:active {
        border: 1px solid #6e7882;
        background-color: #D4D7DA;
        color: #FFFFFF;
      }
    }
    .action-search {
      background-color: var(--generalButton);
      color:var(--generalButtonFont);
      font-weight: 500;
      alignment: center;
      @include btnModal;
      &:hover {
        background-color: var(--generalButtonHover);
        border-color: var(--generalButtonHover);
        //color: #FFFFFF;
      }
      &:focus {
        background-color: var(--generalButtonActive);
        color: var(--generalButtonFont);
        border: var(--generalButtonActive);
      }

      &:active {
        border: 1px solid var(--generalButtonActive);
        background-color: var(--generalButtonActive);
      }
    }
    .action-search:disabled {
      background-color: #D4D7DA;
      color: #FFFFFF;
      cursor: default;
      font-weight: 500;
      alignment: center;
      @include btnModal;
      &:hover {
        background-color: #D4D7DA;
        border-color: #6e7782;
        color: #FFFFFF;
      }
      &:focus {
        background-color: #D4D7DA;
        color: #FFFFFF;
      }
      &:active {
        border: 1px solid #6e7882;
        background-color: #D4D7DA;
        color: #FFFFFF;
      }
    }
  }
  .modal-content-on-search {
    .text-info-connect {
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
    }
    .spin-wrapper {
      display: flex;
      justify-content: center;
    }
    .progress-connect-info {
      flex-direction: column;
      //width: 80%;
      .import-name {
        margin-bottom: 10px !important;
        display: flex;
      }
      .progress-info {
        display: flex;
        width: 80%;
        margin-top: 5px;
        margin-bottom: 5px;
        .ant-progress-text {
          margin-bottom: 5px;
          margin-top: 5px;
          margin-left: 10px;
        }
        .ant-progress-outer {
          width: 95%;
        }
      }
    }
    .body-progress-import-finally {
      flex-direction: column;
      .title-upload {
        margin-bottom: 10px;
      }
      .progress-connect-info {
        flex-direction: column;
        width: 80%;
        .import-name {
          margin-bottom: 10px !important;
          display: flex;
        }
        .progress-info {
          display: flex;
          width: 80%;
          margin-top: 5px;
          margin-bottom: 5px;
          .ant-progress-text {
            margin-bottom: 5px;
            margin-top: 5px;
            margin-left: 10px;
          }
          .ant-progress-outer {
            width: 95%;
          }
        }
      }
    }
    .body-modal-on-search {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
}
.date-picker-up {
  width: 520px !important;
  .ant-calendar-range {
    width: 520px !important;
    overflow: hidden;
  }
}
.ant-select-dropdown-menu-item-disabled {
  color:#D4D7DA !important;
  cursor:default !important;
}
