.custom-table {
  table {
    border-radius: 0 !important;
  }
  .button-blue-reset {
    border: 1px solid #3DD8DB;
    background-color: #FFFFFF;
    color: #3DD8DB;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    border-radius: 25px;
    //float: right;
    width: 115px;
    height: 25px;
    text-transform: capitalize;
    &:hover {
      background-color: #3DD8DB;
      color: #FFFFFF;
      border: 1px solid #3DD8DB;
    }
  }
  .ant-table-body::-webkit-scrollbar{
    width: 5px;
    border-radius: 10px;
    border: 1px solid #3DD8DB;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #3DD8DB;
    border-radius: 20px;
    border: 1px solid rgb(255, 254, 254);
  }

  .ant-table-body {
    border-radius: 0;
    overflow-x: auto;
    scrollbar-width: thin;
    font-size: 16px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    font-family: 'Roboto';
    font-weight: 400;

    .ant-table-thead {
      tr {
        background: white;

        th {
          text-transform: uppercase;
          font-family: 'Roboto';
          color: #7f7f7f;
          font-size: 14px;
          font-weight: 400;
        }
      }

      tr:first-child > th:last-child {
        border-radius: 0 !important;
      }

      tr:first-child > th:first-child {
        border-radius: 0 !important;
      }

    }


    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background: white;
    }

    .ant-table-tbody {
      background-color: white;

      tr {
        td {
          border-bottom: 1px solid #d6d3d3;
        }
      }
    }

  }

  .edit-button {
    color: #3dd8db;
    text-decoration: underline;
    font-size: 15px;

    &:hover {
      color: #196164;
    }

    &:disabled {
      border: none;
    }
  }

  .save-button {
    color: #3dd8db;
    text-decoration: underline;
    font-size: 15px;

    &:hover {
      color: #196164;
    }

  }

  .cancel-button {
    color: #db170d;
    text-decoration: underline;
    font-size: 15px;

    &:hover {
      color: rgba(250, 20, 12, 0.4);
    }

  }
}
.new-custom-table {
  .button-blue-reset {
    padding: 1px 20px;
  }
  .active-company {
    cursor: pointer;
    color: #eda41e;
    font-weight: 400;
  }
  .ant-table-column-title span {
    margin-right: 0px;
  }
  .content-header-column {
    display: flex;
    align-items: center;
    .content-buttons-sorter {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 20px;
      line-height: 12px;
      .icon-v16-arrow-bold:before {
        line-height: 9px;
      }
      .active-sort {
        color: #EDA41E;
      }
      .rotate-arrow {
        -webkit-transform: rotate(180deg);
        display: flex;
      }
    }
  }
}

.custom-table-client {
  .content-full-name-table {
    width: 27%;
  }
  .ant-table-fixed-left {
    .ant-table-body-inner {
      height: 100%;
      width: 200px;
    }
  }
  .content-send-table {
    width: 10%;
    .ant-table-header-column {
      width: 10%;
      max-width: 10%;
    }
  }
  .send-request {
    border-radius: 20px;
    background-color: #EDA41E;
    color: #fff;
    border: 1px solid #eda41e;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    text-transform: initial;
    transition: all .2s;
    height:25px;
    padding:0px;
    letter-spacing: 0.5px;
    width: 80px;
    &:hover {
      background-color: #fff;
      border:1px solid #eda41e;
      color:#eda41e;
    }
    &:active {
      background-color: #fff;
      border:1px solid #eda41e;
      color:#eda41e;
    }
    &:focus {
      background-color: #fff;
      border:1px solid #eda41e;
      color:#eda41e;
    }
  }
  .ant-table-column-title span {
    margin-right: 0px;
  }
  .content-header-column {
    display: flex;
    align-items: center;
    .active-company {
      cursor: pointer;
      color: #eda41e;
      font-weight: bold;
    }
    .content-buttons-sorter {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 20px;
      line-height: 12px;
      .icon-v16-arrow-bold:before {
        line-height: 9px;
      }
      .active-sort {
        color: #EDA41E;
      }
      .rotate-arrow {
        -webkit-transform: rotate(180deg);
        display: flex;
      }
    }
  }
}
.role-admin{
  color: #db170d;
  font-weight: bold;
}

.buttons-section {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  .button-green-invite {
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 18px;
    padding: 8px 30px;
    text-transform: uppercase;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  &__btn {
    .ant-btn {
      width: 206px;
      height: 40px;
      object-fit: contain;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #FFFFFF;
      background-color: #3DD8DB;
      border-radius: 30px;
      border: 1px solid #3DD8DB;
      &:hover{
        border: 1px solid #3DD8DB;
        color: #ffffff;
        background-color: #3DD8DB;
      }
      &:focus{
        border: 1px solid #3DD8DB;
        color: #ffffff;
        background-color: #3DD8DB;
      }
      .anticon {
        font-size: 20px;
        font-weight: bold;
      }
      a {
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}

.invite-tenant-modal {
  .ant-modal-content {
    top: 260px;
    .ant-modal-header {
      padding-left: 15px;
      .ant-modal-title {
        font-family: Roboto;
        color: rgba(0, 0, 0, 0.85);
        font-size: 22px;
        font-weight: 500;
      }
    }
    .invite-user-section {
      padding: 0;
      border-top: none;
      .content-details-company {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        font-weight: 400;
        font-family: Roboto;
        font-size: 14px;
        color: #6e7882;
      }
      .content-change-company-admin {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        .title-change {
          margin-bottom: 10px;
          font-weight: 500;
          font-family: Roboto;
          font-size: 14px;
          color: #6e7882;
        }
        .button-blue-change-company {
          width: 200px;
          height: 25px;
          padding: 2px 30px;
        }
      }
      .invite-user-form {
        flex-direction: row;
        justify-content: space-between;
        .invite-user-form-group {
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
}