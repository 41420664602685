.new-box-modal-settings {
  width: 790px !important;
  .ant-modal-content {
    width: 790px !important;
    height: 295px;
    border-radius: 0 !important;
    font-family: Roboto;
    box-shadow: none !important;
  }
  .ant-modal-body {
    padding: 30px 30px 30px 30px;
  }
  .ant-modal-header {
    padding: 0px;
    //background-color: #665360;
    font-weight: 700;
    border: none;
    border-radius: 0;
    .ant-modal-title {
      margin: 0;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      word-wrap: break-word;
      letter-spacing: 0.5px;
    }
  }
  .ant-modal-close-x {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    color: #ffffff;
  }
  .ant-radio .ant-radio-inner {
    border-radius: 50px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-color: #6e7882;
    border-style: solid;
    border-width: 1px;
    transition: none;
  }
  .ant-radio:after {
    border-radius: 1px;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: var(--generalButtonHover);
  }
  .ant-radio .ant-radio-inner::after {
    content: " " !important;
    color: #ffffff !important;
    font-size: 9px !important;
    font-family: 'FontAwesome' !important;
    top: 3px;
    left: 3px;
    //background-color: #EDA41E;
    border: 4px solid #FFFFFF;
    border-radius: 50%;
    background-color: #FFFFFF;
    transition: none;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #637882;
    background-color: var(--generalButton);
    border-radius: 50%;
  }
  .ant-radio-checked::after {
    border-radius: 50%;
    animation: none;
    border: 1px solid #EDA41E;
  }
  .update-header-modal-new-box {
     display: flex;
    justify-content: space-between;
    //height: 20px;
    padding: 10px;
    background-color: var(--subNavigationBackground);
    .icon-v18-close-black {
      color: #FFFFFF;
      font-size: 20px;
    }
    .icon-v18-close-black:before {
       margin-left: 0px;
      margin-right: 0px;
    }
  }

  .content-header-modal-new-box {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
  .settings-modal-new-box {
    .box-setup-new-box {
       &__content-new-box {
         display: flex;

         .content-box-option {
         .content-box-name {
           display: flex;
           margin-bottom: 30px;
           height: 40px;

           .title-box-name {
             width: 237px;
             margin-right: 10px;
             font-size: 14px;
             font-weight: 700;
             color: #382E34;
             display: flex;
             align-items: center;
           }

           .content-input-new-box {
             .ant-input {
               width: 483px;
               height: 40px;
               border: 1px solid #6e7882;
               font-weight: 400;
               font-size: 14px;
               color: #6e7882;
               border-radius: 0px;
             }
             .ant-input:focus {
               box-shadow: none;
               border: 1px solid var(--generalButton);
             }
             .ant-input:hover {
               box-shadow: none;
               border: 1px solid var(--generalButton);
             }
           }
         }
         .content-box-configuration {
           height: 60px;
           display: flex;
           margin-bottom: 0px;
           .title-box-configuration {
             width: 237px;
             margin-right: 10px;
             font-size: 14px;
             font-weight: 700;
             color: #382E34;
             line-height: 30px;
             .icon-v14-radio-button-off {
               margin-left: 10px;
               font-size: 15px;
               color: rgba(0, 0, 0, 0.65);
             }
             .icon-v14-question {
               margin-left: 10px;
               font-size: 17px;
               color: rgba(0, 0, 0, 0.65);
             }
           }
           .content-radio-configuration {
             height: 60px;
             margin-left: 0px !important;
             .ant-radio-wrapper {
               margin-bottom: 0px;
             }
             .ant-radio-group {
               line-height: 30px;
             }
             .descriptions-radio {
               display: flex;
               padding-left: 24px;
               font-weight: 300;
               color: #a6acb2;
               font-size: 14px;
               margin-top: 5px;
             }
             .title-radio {
               font-weight: 400;
               color: #6e7882;
               font-size: 14px;
             }
           }
           .ant-radio-wrapper {
             .advanced-configuration {
               display: flex;
               flex-direction: column;
             }
           }
           .advanced-configuration {
             display: flex;
             flex-direction: column;
           }
         }
       }
       }
    }

    .box-setup-pii {
      .header-box-AI {
        display: flex;
        flex-direction: column;
        color: #382e34;
        //height: 60px;
        .top-label {
          font-family: Roboto;
          font-weight: 700;
          font-size: 20px;
          color: #382e34;
          line-height: 20px;
          margin-bottom: 30px;
          .icon-v14-question {
            padding-left: 5px;
          }
        }
        .title-ai {
          font-size: 20px;
          font-weight: 700;
        }
        .description-ai {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .update-content-pii-tree {
        //margin-top: 30px;
        //margin-bottom: 40px;
        display: flex;
        //justify-content: space-between;
        height: 385px;
        overflow: auto;
        .title-pii-configuration {
          width: 186px;
          font-family: Roboto;
          font-weight: 700;
          font-size: 14px;
          color: #382e34;
          letter-spacing: 0.5px;
          vertical-align: middle;
          alignment: center;
          padding-top: 0px;
        }
      }
      .content-pii-tree {
        //margin-bottom: 40px;
        //margin-top: 30px;
        width: 100%;
        height: 420px;
        max-height: 420px;
        //overflow: auto;
        .rotate-arrow {
          -webkit-transform: rotate(180deg);
          display: flex;
        }
        .icon-v21-arrow-bold {
          color: #382e34;
        }
       .ant-tree-checkbox-checked:hover {
         .ant-tree-checkbox-inner {
           border: 1px solid var(--generalIconFontHover) !important;
         }
        }
        .ant-tree-checkbox-inner:hover {
          border: 1px solid var(--generalIconFontHover) !important;
        }
        .ant-tree {
          width: 582px;
        }
        .ant-tree > li:first-child {
          padding-top: 0px;
        }
        .ant-tree li {
          margin: 0;
          padding: 0px 0;
        }
        .ant-tree li span.ant-tree-checkbox {
          height: 30px;
          margin: 0 10px 0 10px;
          padding: 7px 0;
        }
        .ant-tree-child-tree > li:first-child {
          //padding-top: 0px;
        }
        .box-tree {
          .ant-tree-switcher {
            display: none;
          }
          .ant-tree li span.ant-tree-checkbox {
            margin: 0 0px 0 2px !important;
          }
          .ant-tree-checkbox {
            .ant-tree-checkbox-inner::after {
              position: absolute !important;
              display: table;
              color: #FFFFFF !important;
              //background-color: #FFFFFF !important;
              //border: 2px solid #fff;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
          }
          .category-parent2-tree-node {
            border-bottom: none !important;
            .category-parent {
              .rotate-arrow {
                -webkit-transform: rotate(180deg);
                display: flex;
              }
              &__name {
                color: #6e7882;
                padding: 0 0px;
              }
              &__arrow {
                padding-right: 5px;
                i {
                  font-size: 16px !important;
                  color: #382e34;
                }
              }
            }
            .ant-tree-node-content-wrapper .category-parent {
              //border-bottom: 1px solid #00d3d8 !important;
            }
            .ant-tree-checkbox {
              //border-bottom: 1px solid #6e7882 !important;
              height: 30px;
              margin: 0 10px 0 10px;
              padding: 7px 0;
            }
            .ant-tree-node-content-wrapper {
              //padding: 0 5px;
              padding: 0 0px !important;
            }
            .ant-tree-checkbox .ant-tree-checkbox-inner {
              border: solid 1px #6e7882;
              border-radius: 0px;
            }
            .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
              background-color: var(--generalIconFontActive);
              border: solid 1px #6e7882;
              //border-radius: 1px;
              //margin-right: 5px;
            }
            .category-child2-tree-node {
              .ant-tree-checkbox {
                border-bottom: none !important;
              }
              .category-child {
                &__name {
                  //padding: 0 10px;
                  //padding-top: 3px;
                  font-size: 14px;
                  font-weight: 400;
                  padding-left: 36px;
                }
              }
            }
          }
          .category-parent-tree-node {
            //border-bottom: 1px solid #000;
            display: flex;
            flex-wrap: wrap;
            padding-left: 0px;
            //margin-top: 20px;
            //height: 30px;
            margin-bottom: 20px;
            .category-parent {
              &__arrow {
                i {
                  color: #382e34 !important;
                  font-size: 16px !important;
                }
              }
            }
            .ant-tree-node-content-wrapper {
              padding: 0px 0px !important;
              transition: none;
              border-radius: 0px;
            }
            .ant-tree-checkbox {
              border-bottom: none;
            }
            .ant-tree-node-content-wrapper {
              //padding: 0 5px;
            }
            .ant-tree-node-content-wrapper {
              width: 100%;
              margin-left: -36px;
              height: 30px;
              line-height: 30px;
              border-radius: 0px;
            }
          }

          .ant-tree-node-content-wrapper {
            width: 95%;
            font-family: Roboto;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #6e7882;
            &:hover {
              background: transparent;
            }
            &.ant-tree-node-selected {
              background: transparent;
            }
            .category-parent-expand {
              color: #382e34;
              .ant-tree-node-content-wrapper {
                .ant-tree-node-selected {
                  border-bottom: 1px solid #000 !important;
                }
              }
              &__name {
                color: #6e7882;
              }
              &__arrow {
                margin-left: 5px !important;
                i {
                 margin-left: 5px !important;
                }
              }
            }
            .category-parent {
              display: flex;
              //justify-content: space-between;
              align-items: center;
              padding-left: 36px;

              &__name {
                font-size: 14px;
                font-weight: 700;
                color: #382e34;
              }
              &__arrow2 {
                i {
                  font-size: 14px;
                }
              }
              &__arrow {
                i {
                  font-size: 20px;
                }
              }
            }
          }

          .ant-tree-child-tree {
            display: contents;

            .category-child-tree-node {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25%;
              font-size: 16px;
              //padding-left: 5%;
            }
          }
          ant-tree-child-tree > li:first-child {
            padding-top: 8px;
          }
          .ant-tree-checkbox-inner {
            border-radius: 0px;
          }
          .ant-tree-checkbox {
            .ant-tree-checkbox-inner {
              //border-radius: 1px;
              border: solid 1px #6e7882;
            }

            .ant-tree-checkbox-inner::after {
              //content: "\f00c" !important;
              //color: #FFFFFF !important;
              //font-size: 10px !important;
              //font-family: 'FontAwesome' !important;
              //top: 0.2px;
              //left: 0.2px;
              //background-color: #EDA41E;
              //border-radius: 50%;
              //border: 1px solid #EDA41E;
              //transform: rotate(-5deg);
            }
          }

          .ant-tree-checkbox-checked {
            .ant-tree-checkbox-inner {
              background-color: var(--generalIconFontActive);
              border: solid 1px #6e7882;
              border-radius: 1px;
            }

            &:after {
              border: none;
            }
          }

          .ant-tree-checkbox-indeterminate {
            .ant-tree-checkbox-inner::after {
              position: absolute !important;
              display: table;
              color: #6e7882 !important;
              background: #FFFFFF;
              //border: 2px solid #fff;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
          }
        }
      }
    }

    .box-setup-custom {
      .configuration-buttons {
        //margin-top: 40px;
        //padding-bottom: 50px;
      }
      .header-box-custom {
        //display: flex;
        flex-direction: column;
        color: #382e34;
        //height: 20px;
        margin-bottom: 30px;
        line-height: 16px;
        .title-custom {
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
        }
        .description-custom {
          font-size: 14px;
          font-weight: 400;
        }
      }
      &__content-update2 {
        height: 385px;
        .row {
          margin-right: 0px;
          margin-left: 0px;
        }
        .business-terms-setup {
          .content-business-configuration {
            .ant-checkbox-inner {
              //border: 1px solid #6e7882;
              margin-left: 0px;
            }
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 0px;
              margin-left: 0px;
            }
            .ant-checkbox-inner {
              border: 1px solid #6e7882;
              &:hover {
                border:1px solid var(--generalIconFontHover);
              }
            }
            .ant-checkbox-checked .ant-checkbox-inner:after {
              position: absolute !important;
              display: table;
              color: #FFFFFF !important;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
              background-color: var(--generalIconFontActive) !important;
              border: 1px solid #6e7882;
              margin-left: 0px;
            }
            .content-list-setup-reg-ex {
              .icon-v16-arrow-medium:before {
                font-weight: 700;
              }
              .ant-checkbox-checked:hover {
                .ant-checkbox-inner {
                  border: 1px solid var(--generalIconFontHover) !important;
                }
              }
              .ant-checkbox {
                padding: 0px 10px;
              }
              .select-all-regular {
                background-color: #E4C801;
                .select-all-checkbox-finish:hover {
                  .ant-checkbox-inner {
                    border: 1px solid #EDA41E !important;
                  }
                }
                .select-all-regular-ex {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  .icon-v18-edit {
                    font-size: 20px;
                    color: #382e34;
                    margin-right: 10px;
                  }
                  .icon-v18-edit:before {
                    margin-right: 0px;
                    margin-left: 0px;
                    line-height: 30px;
                  }
                }
                .select-all-checkbox-parent {
                  .ant-checkbox-inner::before {
                    position: absolute !important;
                    display: table;
                    color: #6e7882 !important;
                    border: none !important;
                    border-top: 0;
                    border-left: 0;
                    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    opacity: 1;
                    -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                    content: '\e8ce' !important;
                    top: 7px !important;
                    left: 7px !important;
                    font-size: 12px !important;
                    font-family: 'smartbox_icons_v19' !important;
                  }
                }
                .select-all-checkbox-finish {
                  .ant-checkbox-inner {
                    background-color: var(--generalIconFontActive) !important;
                    border: 1px solid #6e7882;
                    &:hover {
                      border:1px solid var(--generalIconFontHover);
                    }
                  }
                  .ant-checkbox-inner::before {
                    border-color: #FFFFFF;
                    position: absolute !important;
                    display: table;
                    color: #FFFFFF !important;
                    border: none !important;
                    border-top: 0;
                    border-left: 0;
                    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    transform: rotate(0deg) scale(1) translate(-50%, -50%);
                    opacity: 1;
                    -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                    content: '\e8ce' !important;
                    top: 8px !important;
                    left: 7px !important;
                    font-size: 12px !important;
                    font-family: 'smartbox_icons_v19' !important;
                  }
                }
                .select-all-checkbox {
                  background-color: transparent !important;
                  display: flex;
                  align-items: center;
                  font-weight: 700;
                }
              }
              .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
                padding: 0px 0px 0px 0px;
                line-height: 30px;
              }
              .ant-collapse-borderless > .ant-collapse-item {
                border-bottom: none;
              }
              .ant-collapse-borderless {
                background-color: #FFFFFF;
              }
              .ant-collapse-content {
                color: #6e7882;
              }
              .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
                padding-top: 0px;
              }
              .ant-collapse-content > .ant-collapse-content-box {
                padding: 0px 0px 10px 35px;
              }
              .ant-checkbox-wrapper {
                color: #6e7882;
                font-weight: 400;
              }
            }
          }
        }
      }
      &__content-update {
        height: 385px;
        .business-terms-setup-dictionary {
          .ant-checkbox-checked:hover {
            .ant-checkbox-inner {
              border: 1px solid var(--generalIconFontHover) !important;
            }
          }
          .select-all-checkbox-empty {
            .ant-checkbox-checked .ant-checkbox-inner:after {
              border-color: #6e7882;
              background-color: #FFFFFF !important;
              position: absolute !important;
              display: table;
              color: #FFFFFF !important;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
          }
          .select-all-dictionaries {
            .ant-checkbox {
              //margin-left: 10px;
              top: auto;
            }
            .ant-checkbox-inner {
              border: 1px solid #6e7882;
            }
            .icon-v18-edit:before {
              cursor: pointer;
            }
          }
          .content-business-configuration {
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
              //margin-left: 10px;
            }
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 0px !important;
            }
            .ant-checkbox-inner:hover {
              border: 1px solid var(--generalIconFontHover) !important;
            }
            .select-all-checkbox-finish .ant-checkbox-inner {
              margin-left: 0px;
            }
          }
        }
        .business-terms-setup {
          .content-business-configuration {
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 1px;
            }
            .ant-checkbox-checked .ant-checkbox-inner:after {
              border-color: #FFFFFF;
              background-color: var(--generalIconFontActive) !important;
              position: absolute !important;
              display: table;
              color: #FFFFFF !important;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
              background-color: var(--generalIconFontActive) !important;
              border: 1px solid #6e7882;
            }
            .select-all-checkbox-finish {
              .ant-checkbox-inner {
                background-color: #EDA41E !important;
                border: 1px solid #6e7882;
              }
              .ant-checkbox-inner::before {
                border-color: #FFFFFF;
                position: absolute !important;
                display: table;
                color: #FFFFFF !important;
                border: none !important;
                border-top: 0;
                border-left: 0;
                -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                transform: rotate(0deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                content: '\e8ce' !important;
                top: 8px !important;
                left: 7px !important;
                font-size: 12px !important;
                font-family: 'smartbox_icons_v19' !important;
              }
            }
             .select-all-checkbox-child {
               .ant-checkbox-inner::before {
                 position: absolute !important;
                 display: table;
                 color: #6e7882 !important;
                 border: none !important;
                 border-top: 0;
                 border-left: 0;
                 -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                 -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                 transform: rotate(0deg) scale(1) translate(-50%, -50%);
                 opacity: 1;
                 -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                 transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                 content: '\e8ce' !important;
                 top: 7px !important;
                 left: 7px !important;
                 font-size: 12px !important;
                 font-family: 'smartbox_icons_v19' !important;
               }
             }
          }
        }
      }
      &__content {
        .business-terms-setup {
          display: flex;
          height: 385px;
          overflow: auto;
          overflow-x: hidden;
          //height: 150px;
           .title-box-setup {
             width: 186px;
             margin-right: 32px;
             font-size: 14px;
             font-weight: 700;
             color: #382E34;
             letter-spacing: 0.5px;
             vertical-align: middle;
             padding-top: 0px;
           }
          .content-business-configuration {
            width: 100%;
            height: 420px;
            max-height: 420px;
            //overflow: auto;
            .ant-checkbox {
              padding: 0px 10px;
            }
            .row {
              flex-wrap: nowrap;
              width: 582px;
              margin-right: 0px !important;
              margin-left: 0px !important;
            }
            .col-md-3 {
              flex: auto;
              max-width: none;
              padding-left: 0px;
              padding-right: 0px;
            }
            .tree-business-terms {
              .ant-checkbox {
                //margin-left: 10px;
              }
              .business-terms-item {
                font-weight: 400;
                font-size: 14px;
                color: #6e7882;
              }
              .ant-checkbox-group {
                width: 582px;
                line-height: 30px;
              }
              .line-item {
                margin-top: 0px;
              }
              .ant-checkbox-inner {
                border: 1px solid #6e7882;
              }
              .ant-checkbox + span {
                padding-right: 8px;
                padding-left: 0px;
              }
              .icon-change-color {
                position: relative;
                float: right;
                width: 16px;
                height: 16px;
                margin-top: 7px;
                cursor: pointer;
                margin-right: 10px;
              }
            }
          }
          .content-inputs {
            width: 462px;
            .validation-reg-ex {
              color: #ff0000;
              font-weight: 300;
              font-size: 12px;
            }
            .insert-regular-expression {
              margin-bottom: 10px;
              .validation-reg-ex {
                color: #ff0000;
                font-weight: 300;
                font-size: 12px;
              }
              .input-insert-expression {
                height: 40px;
              }
            }
            .add-regular-expression {
              position: relative;
              .icon-add-b {
                position: absolute;
                right: 15px;
                bottom: 1px;
                font-size: 25px;
                color: #00d4d8;
              }
            }
          }
        }
        .regular-expressions-setup {
          //height: 180px;
          .content-business-configuration {
            //height: 180px;
            //max-height: 180px;
            .select-all-checkbox-finish .ant-checkbox-inner {
              margin-left: 0px;
            }
            .rotate-arrow-medium {
              -webkit-transform: rotate(180deg);
              writing-mode: vertical-rl;
            }
            .ant-collapse-content {
              //padding-bottom: 10px;
            }
            .icon-v18-edit:before {
              font-weight: 700;
            }
            .select-all-checkbox .ant-checkbox-inner {
              margin-left: 0px !important;
            }
            .content-list-setup-reg-ex .ant-collapse-content > .ant-collapse-content-box {
              padding: 0px 0px 0px 35px;
            }
            .content-list-setup {
              .select-all-regular {
                width: 582px;
                display: flex;
                justify-content: space-between;
              }
              .content-list-view-add {
                width: 582px;
                display: flex;
                flex-direction: column;
                margin-top: 0px;
                .content-single-collapse {
                  display: flex;
                  //align-items: center;
                  .radio-style {
                    margin-top: 5px;
                  }
                  .ant-collapse-content > .ant-collapse-content-box {
                    padding: 0px 0px 0px 0px;
                  }
                }
                .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                  color: #6e7882;
                  font-weight: 400;
                }
                .font-weight-bold {
                  font-weight: 400 !important;
                }
                .content-new-box-reg {
                  .radio-style {
                    //margin-right: 10px;
                  }
                  .rotate-arrow-medium:before {
                    margin-left: 0.55em;
                    margin-top: -2px;
                  }
                  .icon-v16-arrow-medium:before {
                    width: 20px;
                    margin-left: 5px;
                  }
                }
                .radio-style ant-checkbox-wrapper {
                  margin-left: 0px;
                }
                .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                  margin-left: 0px;
                }
                .ant-checkbox-group {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
    }
    .box-setup-configuration {
      max-height: 370px;
      .header-box-custom {
        height: 20px;
        margin-bottom: 30px;
      }
      .content-configuration {
        //overflow: auto;
        //max-height: 385px;
        display: flex;
        flex-direction: column;
        height: auto;
        //overflow: auto;
        //overflow-x: hidden;
        width: 740px;
        max-height: 480px;
        .content-configuration-box-update {
          overflow: auto;
          overflow-x: hidden;
        }
        .content-visibility-folder-update {
          max-height: 300px;
          //overflow: auto;
          //overflow-x: hidden;
        }
        .content-visibility-folder {
          margin-bottom: 30px;
          width: 730px;
          align-items: flex-start;
          height: auto;
          max-height: 480px;
          .title-folder-visibility {
            width: 247px;
            line-height: 25px;
            color: #382e34;
            font-weight: 700;
            font-size: 14px;
          }
          .content-functionality-visibility-folder {
            width: 483px;
            margin-left: 0px !important;
            margin-bottom: 0px;
            //overflow: auto;
          }
          .content-automated-deduplication {
            margin-left: 0px !important;
            margin-bottom: 0px;
            .share-folder-container {
              .left-container {
                width: 483px;
                margin-right: 0px;
              }
            }
          }
        }
      }
      .switcher-select-configuration {
        padding-left: 0;
        align-items: center;
        background-color: #ffffff;
        display: flex;
        font-size: 14px;
        position: relative;
        .switcher__slider:hover {
          background-color: #FFC457;
          border: 1px solid #EDA41E;
        }
        .switcher__slider:active {
          background-color: #FFC457;
          border: 1px solid #EDA41E;
        }
        .switcher__slider {
          background-color: #ffffff;
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:after {
            content: 'No';
            color: #6E7882;
            font-size: 16px;
            font-weight: 400;
            position: absolute;
            right: 16px;
            top: 0px;
            z-index: 0;
          }
        }
        .switcher__slider:hover {
          border: 1px solid var(--generalIconFontHover);
          background-color: #FFFFFF;
        }
        .switcher__slider:active {
          border: 1px solid var(--generalIconFontActive);
          background-color: #FFFFFF;
        }
        .switcher__sliderChecked:hover {
          background-color: var(--generalIconFontHover);
          border: 1px solid var(--generalIconFontHover);
        }
        .switcher__sliderChecked:active {
          background-color: var(--generalIconFontActive);
          border: 1px solid var(--generalIconFontActive);
        }
        .switcher__sliderChecked {
          background-color: var(--generalButton);
          border-radius: 34px;
          position: relative;
          width: 70px;
          height: 25px;
          cursor: pointer;
          border: 1px solid #6e7882;
          &:before {
            content: 'Yes';
            color: #FFFFFF;
            position: absolute;
            z-index: 0;
            font-size: 18px;
            font-weight: 400;
            left: 9px;
            top: 0px;
          }
        }
        .switcher__sliderButton {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 3px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          z-index: 1;
          border: 1px solid #6e7882;
        }
        .switcher__sliderButtonChecked {
          background-color: #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          display: block;
          height: 19px;
          left: 47px;
          position: absolute;
          top: 2px;
          transition: all 0.4s ease;
          width: 19px;
          border: 1px solid #6e7882;
        }
      }
      .content-configuration {
        .content-visibility-permission {
          margin-top: 30px;
        }
       .content-visibility-folder {
         display: flex;
         margin-bottom: 30px;
         width: 730px;
         align-items: flex-start;
         .title-folder-visibility-update {
           padding-top: 0px !important;
           //margin-top: 30px;
         }
         .title-folder-visibility {
           width: 247px;
           color: #382e34;
           font-size: 14px;
           font-weight: bold;
           //padding-top: 5px;
           line-height: 25px;
         }
         .content-automated-deduplication {
           //margin-bottom: 30px;
           margin-left: 0px !important;
           .share-folder-container {
             //padding-left: 20px;
           }
           .switcher__slider {
             background-color: #ffffff;
             border-radius: 34px;
             position: relative;
             width: 70px;
             height: 25px;
             cursor: pointer;
             border: 1px solid #6e7882;
             &:after {
               content: 'Off';
               color: #6E7882;
               font-size: 18px;
               font-weight: 400;
               position: absolute;
               right: 10px;
               top: -1px;
               z-index: 0;
             }
           }
           .switcher__sliderChecked {
             background-color: #EDA41E;
             border-radius: 34px;
             position: relative;
             width: 70px;
             height: 25px;
             cursor: pointer;
             border: 1px solid #6e7882;
             &:before {
               content: 'On';
               color: #FFFFFF;
               position: absolute;
               z-index: 0;
               font-size: 18px;
               font-weight: 400;
               left: 9px;
               top: 0px;
             }
           }
         }
         .content-functionality-visibility-folder {
           width: 483px;
           margin-left: 0px;
           .share-folder-container-visible {
             //margin-top: 30px;
           }
           .share-folder-container {
             display: flex;
             align-items: center;
             .row {
               margin-left: 0px;
               margin-right: 0px;
             }
             .title-users-disabled {
               color: #D4D7DA;
             }
             .title-users {
               color: #6e7882;
               font-weight: 400;
               font-size: 14px;
               //width: 216px;
               //margin-left: 30px;
               //padding-top: 5px;
               margin-bottom: 20px;
             }
             .left-container {
               width: 483px;
               margin-right: 0px;
             }
             .right-container {
               display: flex;
               flex-direction: column;
               width: 483px;
               .content-radio-share-folder {
                 //margin-left: 22px;
               }
             }
           }
           .share-and-permissions-container {
             margin-top: 30px;
             .title-users-disabled {
               color: #D4D7DA;
             }
             .right-container {
               width: 644px;
               padding-right: 0px;
               padding-left: 0px;
               .ant-select-selection-selected-value {
                 font-weight: 400;
                 width: 100%;
               }
               .ant-select-arrow-icon {
                 display: none;
               }
               .ant-select-arrow {
                 top: 40%;
               }
               .ant-select-arrow:after {
                 border-radius: 50%;
                 color: #637882;
                 content: '\e896';
                 font-family: 'smartbox_icons_v19' !important;
                 display: inline-block;
                 width: 17px;
                 height: 17px;
                 padding-top: 0px;
                 padding-left: 0px;
                 padding-bottom: 9px;
                 font-style: normal;
                 font-size: 20px;
               }
               .ant-select-disabled {
                 .ant-select-arrow:after {
                   color: #D4D7DA;
                 }
               }
                 .content-value-user {
                 display: flex;
                 justify-content: space-between;
                 width: 100%;
                   .icon-v16-arrow-medium {
                     font-size: 18px;
                   }
               }
               .ant-checkbox-checked .ant-checkbox-inner::after {
                 position: absolute !important;
                 display: table;
                 color: #FFFFFF !important;
                 border: none !important;
                 border-top: 0;
                 border-left: 0;
                 -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                 -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                 transform: rotate(0deg) scale(1) translate(-50%, -50%);
                 opacity: 1;
                 -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                 transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                 content: '\e8ce' !important;
                 top: 7px !important;
                 left: 7px !important;
                 font-size: 12px !important;
                 font-family: 'smartbox_icons_v19' !important;
               }
               .ant-checkbox-inner:hover {
                 border: 1px solid var(--generalIconFontHover);
               }
               .ant-checkbox-checked .ant-checkbox-inner:hover {
                 border: 1px solid var(--generalIconFontHover) !important;
               }
               .ant-checkbox-checked:hover {
                 .ant-checkbox-inner {
                   border: 1px solid var(--generalIconFontHover) !important;
                 }
               }
               .ant-select-disabled .ant-select-selection:hover {
                 border-color: #D4D7DA !important;
               }
               .ant-select-disabled .ant-select-selection {
                 background-color: #FFFFFF;
               }
               .ant-select-selection:hover {
                 border-color: #EDA41E;
                 border-right-width: 1px !important;
               }
               .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
                 background-color: var(--generalIconFontActive) !important;
                 border: 1px solid #6e7882;
               }
               .ant-checkbox+span {
                 padding-right: 0px;
                 padding-left: 0px;
               }
               .ant-checkbox-wrapper .ant-checkbox-inner {
                 border-radius: 0px !important;
               }
               .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
                 border-color: #FFFFFF;
                 background-color: transparent !important;
               }
               .ant-checkbox {
                 line-height: 40px;
                 margin-top: 12px;
                 padding-right: 10px;
               }
               .ant-checkbox-wrapper {
                 margin-left: 0px;
                 //width: 494px;
                 display: flex;
                 margin-bottom: 10px;
                 line-height: 40px;
                 .ant-checkbox-inner {
                   //border-radius: 1%;
                   //margin-top: 4px;
                 }
               }
               .ant-checkbox-inner {
                 border: 1px solid #6e7882;
               }
               .ant-select-selection {
                 width: 442px;
                 margin-bottom: 0px;
               }
               .ant-select-disabled .ant-select-selection {
                 background: #FFFFFF;
                 cursor: default;
                 border: 1px solid #D4D7DA;
                 color: #D4D7DA !important;
               }
               .ant-select-selection-selected-value {
                 font-weight: 400;
                 //color: #6e7882;
               }
               .ant-checkbox-disabled {
                 cursor: default;
               }
               .ant-checkbox-disabled .ant-checkbox-input {
                 cursor: default !important;
               }
               .ant-checkbox-disabled .ant-checkbox-inner {
                 background-color: #FFFFFF;
                 border-color: #D4D7DA !important;
               }
               .ant-select-selection--single {
                 height: 40px;
                 background-color: #FFFFFF !important;
                 border: 1px solid #6e7882;
                 border-radius: 0px;
                 color: #6e7882;
               }
               .ant-select-arrow {
                 right: 0px;
                 margin-top: -5px;
                 //color: #D4D7DA;
               }
               .ant-select .ant-select-selection .ant-select-selection__rendered {
                 margin-left: 10px;
                 margin-right: 10px;
                 line-height: 40px;
               }
               .ant-checkbox-group {
                 width: 483px;
                 display: flex;
                 flex-direction: column;
                 margin-left: 0px;
                 margin-top: 0px;
                 line-height: 40px;
               }
               .action-buttons-wrapper {
                 display: flex;
                 justify-content: flex-end;
                 width: 100%;
                 padding-right: 10px;
                 button:disabled {
                   cursor: not-allowed;
                   color: #D4D7DA;
                   //opacity: 0;
                 }

                 button {
                   margin-left: 0px;
                   cursor: pointer;
                   font-size: 25px;
                 }
                 .icon-v16-add {
                   color: #EDA41E;
                   font-size: 25px;
                 }
                 .icon-v16-add-active {
                   color: #EDA41E;
                 }
                 .icon-v16-minus {
                   font-size: 25px;
                   color: #6e7882;
                 }
                 .icon-v16-minus:before {
                   line-height: 25px;
                   margin-left: 10px;
                   //color: #D4D7DA;
                 }
                 .icon-add-b {
                   color: #EDA41E;
                 }
                 .colour-disabled {
                   opacity: 0.6;
                 }
                 .icon-add-b-disabled {
                   opacity: 0.6;
                 }
               }
             }
           }
         }
       }
      }
    }
  }
  .select-all-dictionaries {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #01E493;
    width: 582px;
    height: 30px;
    align-items: center;
    .icon-v18-edit {
      font-size: 20px;
      color: #382e34;
      margin-right: 10px;
    }
    .icon-v18-edit:before {
      margin-right: 0px;
      line-height: 30px;
      margin-left: 0px;
      font-weight: 700;
    }
  }
  .select-all-checkbox {
    font-weight: 700;
    font-size: 14px !important;
    color: #382e34 !important;
    //width: 470px;
    height: 30px;
    display: flex;
    align-items: center;
    .ant-checkbox {
      //line-height: 30px;
      padding-right: 10px;
      //padding-left: 10px;
    }
    .ant-checkbox + span {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .custom-buttons {
    //margin-bottom: 30px;
    //height: 40px;
    //padding-bottom: 40px;
    margin-top: 40px;
  }
  .footer-buttons-action-new-box {
    display: flex;
    justify-content: space-between;
    height: 25px;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 730px;
    .statistics-active-new-box {
      display: flex;
      align-items: center;
      .dot-new-box {
        width: 8px;
        height: 8px;
        background-color: #382e34;
        opacity: 0.3;
        border-radius: 50%;
        margin-right: 10px;
      }
      .active-dot-new-box {
        opacity: initial;
      }
    }
    .action-cancel-new-box {
      width: 180px;
      height: 25px;
      border: 1px solid #6e7882;
      background-color: #FFFFFF;
      color: #6e7882;
      box-shadow: none;
      cursor: pointer;
      padding: 0px 30px;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
      alignment: center;
      vertical-align: middle;
      line-height: 18.75px;

      &:hover {
        background-color: #FFFFFF;
        border-color: var(--generalButtonHover);
        color: var(--generalButtonHover);
      }

      &:focus {
        border: 1px solid #6e7882;
        background-color: #FFFFFF;
        color: #6e7882;
        -webkit-box-shadow: none;
        box-shadow:none;
      }

      &:active {
        border: 1px solid var(--generalButtonActive);
        background-color: #FFFFFF;
        color: var(--generalButtonActive);
      }
    }
    .action-create-new-box {
      width: 180px;
      height: 25px;
      border: 1px solid #6e7882;
      background-color: var(--generalButton);
      color: var(--generalButtonFont);
      box-shadow: none;
      cursor: pointer;
      padding: 0px 30px;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.5px;
      alignment: center;
      vertical-align: middle;
      line-height: 18.75px;

      &:hover {
        background-color: var(--generalButtonHover);
        border-color: var(--generalButtonHover);
        //color: #FFFFFF;
      }

      &:focus {
        border: 1px solid #6e7882;
        background-color: var(--generalButton);
        //color: #FFFFFF;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      &:active {
        border: 1px solid var(--generalButtonActive);
        background-color:var(--generalButtonActive);
        //color: #FFFFFF;
      }
    }
    .action-create-new-box:disabled {
      cursor: default;
      //opacity: 0.6;
      background: #D4D7DA;
      &:hover {
        background: #D4D7DA;
        border-color: #D4D7DA;
        color: #FFFFFF;
      }

      &:focus {
        border: 1px solid #00D3D8;
        background: #D4D7DA;
        color: #FFFFFF;
      }
    }
  }
  .advanced-configuration {
    display: flex;
    flex-direction: column;
  }
}
.new-box-modal-settings2 {
  width: 790px !important;
  .ant-modal-content {
    height: 600px !important;
    max-height: 600px;
    overflow: auto;
    width: 790px !important;
    overflow-x: hidden !important;
  }
  .ant-modal-body {
    max-height: 700px;
    min-height: 560px;
  }
}

.user-team-select-dropdown {
  width: 442px;
}