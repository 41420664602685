.admin-navbar {
  box-shadow: 0 2px 4px 0 #DCDCDC;
  .sidebar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: white;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding: 25px 10px;
  }

  .ant-menu {
    width: 100% !important;
    border-right: none;

    .ant-menu-submenu-selected {
      color: #03D3D8;
    }
    .ant-menu-submenu-title {
      i {
        font-size: 20px !important;
      }
     &:hover {
       color: #03D3D8;
     }
      .ant-menu-submenu-arrow {
        &:after, &:before {
          background: linear-gradient(to right, #03D3D8, #03D3D8) !important;
        }
      }

    }
    .ant-menu-item {

      a {
        outline: none !important;
        text-decoration-line: none;
        &:hover {
          color: #03D3D8;
          text-decoration: none;
        }
      }
      &:hover {
        color: #03D3D8;
      }
      &-selected {
        background-color: rgba(3, 211, 216, 0.3) !important;
        color: #03595e;
        span > a {
          color: #03595e;
        }
        &:after {
          border-right: 3px solid #03D3D8;
        }

      }
    }
  }
}