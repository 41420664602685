.privacyConnectors__page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px 20px 0;
}

.privacyConnectors__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  position: relative;
}

.privacyConnectors__boxHeader {
  border-bottom: 1px solid #E6E6E6;
  padding: 20px;
}

.privacyConnectors__boxHeader h1 {
  color: #111C26;
  font-size: 18px;
  font-weight: 300;
}