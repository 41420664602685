button.butCancel {
  background-color: #f44336;
  border: 1px solid #737d87;
  border-radius: 20px;
  -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.headerStyle {
  display: block;
}

.newUsersForm__selectLabelSelected {
  background-color: #3DD8DB;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 14px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 14px;
  z-index: 1;
}

.newUsersForm__selectLabel {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.newUsersForm__selectButton {
  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin-left: 5px;
  width: 20px;
}

.newUsersForm__actionButtons {
  display: flex;
  margin-right: 10px;
}

.removeStyle {
  margin-left: -50%;
}

.newUsersForm__removeButtonDisabled {
  border-radius: 12px;
  border: 2px solid;
  color: #EEEEEE;
  cursor: not-allowed;
  font-weight: bold;
  height: 20px;
  text-align: center;
  width: 20px;
  position: relative;
  &:after {
    content: 'x';
    font-size: 14px;
    position: absolute;
    left: 4px;
  }
}

.newUsersForm__removeButton {
  border: 2px solid;
  color: #f8f9fa;
  background: #737e89;
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  text-align: center;
  width: 20px;
  position: relative;
  &::after {
    content: 'x';
    font-size: 14px;
    position: absolute;
    left: 4px;
  }
}

.dpiaNew__radio-label--checked:before {
  font-family: 'FontAwesome';
  content: "\f00c";
  color: #3DD8DB;
  font-size: 16px;
}

.dpiaNew__radio-label--checkedDisabled {
  background-color: #ffffff;
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 27px;
  left: 10px;
  position: absolute;
  top: 10px;
  transition: all .5s ease;
  width: 27px;
  z-index: 1;
  padding-top: 6px;
  padding-left: 6px;
  &:before {
    font-family: 'FontAwesome';
    content: "\f00c";
    color: #3DD8DB;
    font-size: 16px;
  }
}

.dpiaNew__radio-label--checked {
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 27px;
  left: 10px;
  position: absolute;
  top: 10px;
  transition: all .5s ease;
  width: 27px;
  z-index: 1;
  padding-top: 6px;
  padding-left: 6px;
  &:before {
    font-family: 'FontAwesome';
    content: "\f00c";
    color: #3DD8DB;
    font-size: 16px;
  }
}

.dpiaNew__radio-label--checkedDisabled:before {
  font-family: 'FontAwesome';
  content: "\f00c";
  color: #3DD8DB;
  font-size: 16px;
}

.dpiaNew__radio-label--disabled {
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  top: 4px;
  transition: all .5s ease;
  width: 30px;
  z-index: 1;
}

.dpiaNew__radio-label {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  top: 4px;
  transition: all .5s ease;
  width: 30px;
  z-index: 1;
}

.dpiaNew__radio-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #3DD8DB;
  border-radius: 45px;
  display: inline-block;
  height: 50px;
  margin-right: 0;
  position: relative;
  width: 50px;
  &.checked {
    background-color: #3DD8DB;
  }
  &.checkedDisabled {
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
  }
  &.disabled {
    border: 1px solid #EEEEEE;
  }
}