.pagination__wrapper {
  align-items: center;
  display: flex;
}

.pagination__item {
  border-radius: 5px;
  color: #162737;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  margin: 0 5px;
  padding: 10px;
}

.pagination__item:hover {
  background-color: #737D87;
  color: #FFFFFF;
}

.pagination__item--active {
  background-color: #1DCBCF;
  border-radius: 5px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 0 5px;
  padding: 10px;
}

.pagination__nav {
  border: 1px solid #D2D3D4;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #162737;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 0 5px;
  padding: 8px 18px;
}

.pagination__nav:hover {
  background-color: #D4D7DA;
  color: #FFFFFF;
}

.pagination__nav--disabled {
  border: 1px solid #D2D3D4;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #D4D7DA;
  font-size: 14px;
  font-weight: 700;
  margin: 0 5px;
  padding: 8px 18px;
}
.pagination__nav.fa-angle-left:before, 
.pagination__nav.fa-angle-right:before, 
.pagination__nav--disabled.fa-angle-left:before,
.pagination__nav--disabled.fa-angle-right:before  {
  font-size: 26px;
  font-weight: 700;
}
.pagination__ellipsis {
  border-radius: 5px;
  color: #162737;
  font-size: 18px;
  font-weight: 700;
  margin: 0 5px;
  padding: 10px;
  letter-spacing: 1px;
}