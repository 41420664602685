@font-face {
    font-family: 'new';
    src: url('./font_v9/new.eot?96945972');
    src: url('./font_v9/new.eot?96945972#iefix') format('embedded-opentype'),
    url('./font_v9/new.woff2?96945972') format('woff2'),
    url('./font_v9/new.woff?96945972') format('woff'),
    url('./font_v9/new.ttf?96945972') format('truetype'),
    url('./font_v9/new.svg?96945972#new') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'new';
    src: url('../font/new.svg?96945972#new') format('svg');
  }
}
*/

[class^="icon-v9"]:before, [class*=" icon-v9"]:before {
    font-family: "new";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-v9-annotation-b:before { content: '\e804'; } /* '' */
.icon-v9-radio-button-tick:before { content: '\e82d'; } /* '' */
.icon-v9-redaction-b:before { content: '\e830'; } /* '' */
.icon-v9-refresh:before { content: '\e831'; } /* '' */
.icon-v9-upload:before { content: '\e832'; } /* '' */
.icon-v9-list-view:before { content: '\e833'; } /* '' */
.icon-v9-grid-view:before { content: '\e834'; } /* '' */
.icon-v9-filter:before { content: '\e835'; } /* '' */
.icon-v9-delete:before { content: '\e836'; } /* '' */
.icon-v9-box-temp:before { content: '\e837'; } /* '' */
.icon-v9-rights-grey:before { content: '\e838'; } /* '' */
.icon-v9-data-grey:before { content: '\e839'; } /* '' */
.icon-v9-arrow:before { content: '\e83a'; } /* '' */
.icon-v9-end:before { content: '\e83b'; } /* '' */
.icon-v9-start:before { content: '\e83c'; } /* '' */
.icon-v9-back:before { content: '\e83d'; } /* '' */
.icon-v9-forth:before { content: '\e83e'; } /* '' */