.butCancel {
    background-color: #f44336;
    border: 1px solid #737d87;
    border-radius: 20px;
    -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    padding: 8px 30px;
    text-transform: uppercase;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
  
  button.button-green2 {
    margin-top: -4% !important;
    padding: 17px 30px;
  }
  .styleTeamTwo {
    color: #1cc5c9;
    font-size: 12px;
    font-weight: 700;
    line-height: 40.8px;
    text-align: left;
  }
  
  .manageTeam-table {
    border-collapse: collapse;
    width: inherit;
  }
  .review-user-box {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .review-user-box-content {
    .table-review-user {
      .column-review-user {
        .data-review {
          color: #382e34;
          font-size: 14px;
          font-weight: 700;
        }
        .initiator-review {
          color: #1cc5c9;
          font-size: 14px;
          font-weight: 700;
        }
        .action-review {
          color: #382e34;
          font-size: 14px;
          font-weight: 700;
        }
        .document-review {
          color: #1cc5c9;
          font-size: 14px;
          font-weight: 700;
        }
        .details-review {
          color: #382e34;
          font-size: 14px;
          font-weight: 700;
        }
        .details-content-review {
          color: #1cc5c9;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .ant-table-tbody {
        vertical-align: middle !important;
      }
      .ant-table-tbody > tr > td {
        //border-bottom: 1px solid #6e7882;
        background: #FFFFFF;
      }
      .ant-table-tbody > tr > td.ant-table-selection-column {
        text-align: left;
        padding-left: 5px;
        width: 31px;
      }
      .ant-table-tbody > tr {
        vertical-align: initial !important;
      }
      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: #9ae0fc50;
        cursor: pointer;
      }
      .ant-table-row:hover {
        //background: #e6f7ff;
      }
      .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        height: 30px;
        padding: 0px;
        vertical-align: middle;
      }
    }

    .review-user-pagination {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      background: white;
      padding: 20px !important;
      box-shadow: 0 2px 4px 0 #DCDCDC;

      .ant-pagination-total-text {
        line-height: 21px;
      }

      .ant-pagination-item {
        border: 0;
        min-width: auto;
        height: auto;

        a {
          border-radius: 5px;
          color: #162737 !important;
          cursor: pointer;
          font-size: 14px;
          font-weight: 700;
          margin: 0;
          padding: 1px 10px;

          &:hover {
            background-color: var(--generalIconFontHover);
            color: #FFFFFF !important;
          }
        }
      }

      .ant-pagination-item-active {
        border: 0;
        min-width: auto;
        height: auto;

        a {
          background-color: var(--generalIconFontHover);
          border-radius: 5px;
          color: #FFFFFF !important;
          cursor: pointer;
          font-size: 14px;
          font-weight: 700;
          margin: 0;
          padding: 1px 10px;

          &:hover {
            background-color: var(--generalIconFontHover);
            color: #FFFFFF !important;
          }
        }
      }

      .ant-pagination-prev:focus {
        background-color: #EDA41E !important;
        .ant-pagination-item-link {
          border-color: #EDA41E !important;
          color: #EDA41E !important;
        }
      }
      .ant-pagination-next:focus {
        background-color: #EDA41E !important;
        .ant-pagination-item-link {
          border-color: #EDA41E !important;
          color: #EDA41E !important;
        }
      }
      .ant-pagination-prev, .ant-pagination-next {
        min-width: auto;
        height: auto;

        .ant-pagination-item-link {
          border: 1px solid #D2D3D4;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          color: #162737;
          cursor: pointer;
          font-weight: 700;
          margin: 0;
          padding: 3px 11px;
          min-width: auto;
          height: auto;
          font-size: 18px;

          &:hover {
            background-color: #D4D7DA;
            color: #FFFFFF;
            border: 1px solid #D2D3D4;
          }
        }
      }

      .ant-pagination-disabled {
        min-width: auto;
        height: auto;

        .ant-pagination-item-link {
          border: 1px solid #D2D3D4;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 #DCDCDC;
          color: #D4D7DA;
          margin: 0;
          padding: 3px 11px;
          min-width: auto;
          height: auto;
          font-size: 18px;

          &:hover {
            background-color: white;
            color: #D4D7DA;
          }
        }
      }


      .total-elements {
        display: flex;
        flex-direction: column;

        .pagination__box__header__total {
        }

        .pagination__box__header__text {
          margin-top: 4px;
        }
      }

      li:first-child {
        width: 100%;
        display: flex;
        align-self: start;
        height: auto;
      }

    }

  }
.review-user-header {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  height: auto;
  justify-content: space-between;
  padding: 20px;
}
  .user-review-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex-direction: row;
  }
  .manageTeam-paragraphsContentInfo {
    border-bottom: 1px solid #EEEEEE;
    color: #172737;
    padding: 15px 20px;
    position: relative;
    text-align: left;
    vertical-align: middle;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 40.8px;
  }
  
  .manageTeam-section {
    padding: 5px 0 20px 0;
  }
  
  .styleTable {
    font-weight: bold;
  }
  
  .styleTable1 {
    width: 500px;
    height: 520px;
  }
  
  .logs-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
  }
  
  .styleReviewSpanOne {
    color: #101b26;
    font-family: Ubuntu;
    font-size: 24px;
    font-weight: 300;
    line-height: 54.682px;
  }
  
  .styleReviewSpanTwo {
    color: #101b26;
    font-family: Ubuntu;
    font-size: 24px;
    // font-weight: 300;
    font-weight: bold;
    line-height: 54.682px;
  }
  
  .styleButton {
    width: 162px;
    height: 42px;
    position: absolute;
    right: 173px;
  }
  .styleTitlesReview {
    height: auto;
    width: 65%;
    margin-right: 20px;
  }
  
  .newLogsForTeam__section {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    padding-bottom: 5px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
  }
  
  .dpiaDashboard__boxHeader {
    border-bottom: 0 solid #E6E6E6;
  }
  
  .dpiaDashboard1 {
    font-family: Ubuntu;
    color: #162737;
    font-size: 18px;
    /* Approximation due to font substitution */
    font-weight: 300;
    line-height: 21px;
    /* Approximation due to font substitution */
    text-align: left;
  }
  
  .dpiaDashboard2 {
    font-family: Roboto;
    color: #737e89;
    font-size: 14px;
    /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px;
    /* Approximation due to font substitution */
    text-align: left;
  }
.button_big_blue_download {
  outline: none;
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  background: #3DD8DB;
  color: #ffffff;
  height: 100px;
  width: 170px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  text-transform: uppercase;
  padding-bottom: 8px;
  padding-top: 8px;
  //margin-left: 37rem;
  &:hover {
    border: 1px solid #3DD8DB;
    background: #ffffff;
    color: #3DD8DB;
  }
  &:focus {
    outline: none;
  }
}
  .button_big_blue {
    outline: none;
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    background: #3DD8DB;
    color: #ffffff;
    height: 42px;
    width: 170px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700;
    border-radius: 50px;
    text-transform: uppercase;
    //margin-left: 37rem;
    &:hover {
      border: 1px solid #3DD8DB;
      background: #ffffff;
      color: #3DD8DB;
    }
    &:focus {
      outline: none;
    }
  }
  
  .manageLogsForTeam-page {
    padding: 80px 40px 0;
    width: 100%;
  }
  
  .button_positionLogs {
    display: flex;
    width: 34%;
  }
  
  .styleAddNewTeam {
    margin-right: 1rem;
  }
  .logsForTeam-table{
    text-transform: uppercase;
    font-weight: bold;
    padding:10px 0 10px 20px;
    border-bottom:1px solid gainsboro;
    span {
      padding:0 5px 0 5px;
      font-size: 14px;
    }
  }
  .logsForTeam-date{
    font-size: 16px;
    padding-left: 20px;
  }
  .logsForTeam-initialization, .logsForTeam-receiver{
    color: #1cc5c9;
    font-size: 16px;
  }
  .button_positionReview {
    display: flex;
    width: 28%;
    margin-left: 20px;
  }
.styleCsvLogs {
  float: right;
}
.styleClearLogs {
  float: right;
}
.content_header {
  display: contents;
  width: 100%;
}

.styleButtonsReview {
  width: 35%;
}
.styleCsvLogs {
  .button_big_blue {
    outline: none;
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    background: #3DD8DB;
    color: #ffffff;
    height: 42px;
    width: 160px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700;
    border-radius: 50px;
    text-transform: uppercase;
  }
}

.styleMessageActivities {
  color: #101b26;
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 300;
  margin-left: 25px;
}
@media screen and (min-width: 1700px) {
  .button_positionReview {
    display: flex;
    width: 23%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (min-width: 2100px) {
  .button_positionReview {
    display: -webkit-flex;
    display: flex;
    width: 19%;
    margin-left: 20px;
  }
}
@media screen and (min-width: 2400px) {
  .button_positionReview {
    display: -webkit-flex;
    display: flex;
    width: 17%;
    margin-left: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .button_positionReview {
    display: flex;
    width: 35%;
    margin-left: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .button_positionReview {
    display: flex;
    width: 35%;
    margin-left: 20px;
  }
  .styleTitlesReview {
    height: 4rem;
    width: 60%;
    margin-right: 20px;
  }
  .styleReviewSpanOne  {
    color: #101b26;
    font-family: Ubuntu;
    font-size: 19px;
    font-weight: 300;
    line-height: 54.682px;
  }
  .styleReviewSpanTwo {
    color: #101b26;
    font-family: Ubuntu;
    font-size: 19px;
    font-weight: bold;
    line-height: 54.682px;
  }
}
@media screen and (max-width: 800px) {
  .styleReviewSpanOne  {
    line-height: 22.682px;
  }
  .styleReviewSpanTwo {
    line-height: 40.682px;
  }
  #review_header {
    flex-direction: column;
    height: 13rem;
    width: 100%;
  }
  .button_positionReview {
    width: 100%;
    flex-direction: column;
  }
  .styleTitlesReview {
    height: 3rem;
    width: 100%;
    text-align: center;
  }
  .styleCsvLogs {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .styleCsvLogs {
    .button_big_blue {
      outline: none;
      background-color: #3DD8DB;
      border: 1px solid #3DD8DB;
      background: #3DD8DB;
      color: #ffffff;
      height: 34px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: 400;
      border-radius: 50px;
      text-transform: uppercase;
    }
  }
  .styleClearLogs {
    margin-bottom: 10px;
    margin-right: 19px;
    .button-red {
      width: 100%;
      height: 34px;
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: 400;
      border-radius: 50px;
    }
  }

}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .styleTitlesReview {
    width: 60%;
  }
  .button_positionReview {
    width: 40%;
  }
  .styleReviewSpanOne {
    font-size: 20px;
  }
  .styleReviewSpanTwo {
    font-size: 20px;
  }
}


