.taxonomyList__empty {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  text-align: center;
}

.taxonomyList__wrapperCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.taxonomyList__wrapperTable {
  margin-bottom: 20px;
  overflow-y: scroll;
  width: 100%;
}
