.questionnaireGlobalRadio__wrapper {
  background-color: #DDDDDD;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  padding: 20px;
  position: relative
}

.questionnaireGlobalRadio__item {
  padding: 10px 20px
}

.questionnaireGlobalRadio__title {
  color: #162737;
  flex: 1;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
}

.questionnaireGlobalRadio__group {
  align-items: center;
  display: flex
}

.questionnaireGlobalRadio__radioDisabled {
  background-color: #EEEEEE;
  border: 1px solid #E0E8E8;
  border-radius: 50%;
  height: 40px;
  display: inline-block;
  position: relative;
  width: 40px;
}

.questionnaireGlobalRadio__labelDisabled {
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionnaireGlobalRadio__optionTitle {
  color: #162737;
  flex: 1 1 0%;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}