@font-face {
  font-family: 'smartbox_icons_v8';
  src: url('./font_v15/smartbox_icons_v8.eot?17296916');
  src: url('./font_v15/smartbox_icons_v8.eot?17296916#iefix') format('embedded-opentype'),
       url('./font_v15/smartbox_icons_v8.woff2?17296916') format('woff2'),
       url('./font_v15/smartbox_icons_v8.woff?17296916') format('woff'),
       url('./font_v15/smartbox_icons_v8.ttf?17296916') format('truetype'),
       url('./font_v15/smartbox_icons_v8.svg?17296916#smartbox_icons_v8') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'smartbox_icons_v8';
    src: url('../font/smartbox_icons_v8.svg?17296916#smartbox_icons_v8') format('svg');
  }
}
*/
[class^="icon-v16"]:before, [class*=" icon-v16"]:before {
  font-family: "smartbox_icons_v8";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-v16-db-gear:before { content: '\e8a0'; } /* '' */
.icon-v16-gear:before { content: '\ea54'; } /* '' */
.icon-v16-add:before { content: '\e81d'; } /* '' */
.icon-v16-add-active:before { content: '\ea51'; } /* '' */
.icon-v16-minus:before { content: '\e811'; } /* '' */
.icon-v16-minus-active:before { content: '\ea53'; } /* '' */
.icon-v16-arrow-bold:before { content: '\e895'; } /* '' */
.icon-v16-arrow-medium:before { content: '\e896'; } /* '' */
