.boxAvailable__box {
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 20px;
  padding: 40px;
  p {
    color: #162737;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    /* margin-bottom: 20px; */
  }
}

.boxAvailable__logo {
  height: 85px;
  padding-top: 0;
  position: relative;
}

.boxAvailable__logo--privacy::after {
  background-color: #67338B;
  color: #FFFFFF;
  content: 'Available now';
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 11px 10px;
  position: absolute;
  right: -40px;
  top: 0;
}

.boxAvailable__logo--mailroom::after {
  background-color: #E53434;
  color: #FFFFFF;
  content: 'Available now';
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 11px 10px;
  position: absolute;
  right: -40px;
  top: 0;
}

@media screen and (min-width: 768px) {
  .boxAvailable__box {
    margin-bottom: 0;
    width: 49%;
  }
}