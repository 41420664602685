.privacyAlertContent__wrapper {
  background-color: #FFFFFF;
  margin-bottom: 20px;
  position: relative;
}

.privacyAlertContent__wrapper--loading {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 15px;
}

.privacyAlertContent__fileItem {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  justify-content: space-between;
  padding: 10px 15px;
}

.privacyAlertContent__fileItem:nth-child(even) {
  background-color: #F9F9FA;
}

.privacyAlertContent__path {
  color: #111C26;
  font-size: 14px;
}

.privacyAlertContent__view {
  background: url('../../../assets/icon-view-green.png') 5px center no-repeat;
  color: #1DCBCF;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 0 5px 30px;
  text-transform: uppercase;
}

.privacyAlertContent__footerWrapper {
  align-items: center;
  border-bottom: 2px solid #D6D3D3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #D6D3D3;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  position: relative;
}

.privacyAlertContent__assignedSelect {
  max-width: 310px;
  width: 100%;
}

.privacyAlertContent__assignedSelect select {
  appearance: none;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  color: #555555;
  font-size: 16px;
  height: 43px;
  outline: none;
  padding: 10px 25px 10px 10px;
  width: 100%;
}

.privacyAlertContent__assignedSelect select:focus {
  outline: 2px solid #3DD8DB;
}

@media screen and (min-width: 768px) {
  .privacyAlertContent__fileItem {
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    line-height: initial;
  }

  .privacyAlertContent__footerWrapper {
    flex-direction: row;
  }
}