.boxDpia__wrapper {
  margin-bottom: 20px;
}

.boxDpia__header {
  background: url('../../../assets/dpi-logo-symb.png') calc(100% - 20px) center no-repeat #277ED6;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  height: 110px;
  /* margin-bottom: 15px; */
  padding: 15px 20px;
  h1 {
    background: url('../../../assets/osprey-dashboard.jpg') left top no-repeat;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
    height: 50px;
    margin-bottom: 10px;
    padding-top: 30px;
  }
  p {
    color: #BCDDFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
}

.boxDpia__content {
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
}

.boxDpia__content--header {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 20px;
}

.boxDpia__content--action {
  color: #1DCBCF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.boxDpia__content--body p {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  padding: 30px;
  text-align: center;
  line-height: 1.5em;
}

.boxDpia__content--button {
  border-top: 1px solid #E6E6E6;
  margin: 0 20px;
  padding: 20px 0;
  button {
    padding: 8px 20px;
  }
}

.boxDpia__content--item {
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  color: #1DCBCF;
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  padding: 20px 0;
  &:last-of-type {
    border-bottom: none;
  }
}

@media screen and (min-width: 768px) {
  .boxDpia__wrapper {
    margin-bottom: 0;
    width: 49%;
  }
}