@keyframes elastic {
  0% {
    transform: scale(0);
  }

  33% {
    transform: scale(1.1);
  }

  66% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}


.message-text {
  line-height: 1.2;
  padding: 10px 0;
}

.message-success {
  animation: elastic .1s ease-in-out;
  align-items: center;
  background: url(../../assets/icon-check-alternative.png) no-repeat 10px center #F9FFFA;
  border: 1px solid #D1E9D1;
  border-radius: 5px;
  color: #3FA044;
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
}

.message-email {
  animation: elastic .1s ease-in-out;
  align-items: center;
  background: url(../../assets/icon-letter.png) no-repeat 10px center #F9FFFA;
  border: 1px solid #D1E9D1;
  border-radius: 5px;
  color: #3FA044;
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
}

.message-error {
  animation: elastic .1s ease-in-out;
  align-items: center;
  background: url(../../assets/icon-error.png) no-repeat 10px center #FFE9E9;
  border: 1px solid #F44336;
  border-radius: 5px;
  color: #DA0E00;
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
}

.message-close {
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 10px;
}