.unLimitedAccount__container {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
  * {
    font-family: 'Roboto';
    font-weight: 400;
  }
}

.unLimitedAccount__title {
  background: url('../../../assets/icon-unlimited.svg') no-repeat left;
  background-size: 40px;
  color: #212a34;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 28px;
  padding-left: 20px;
  text-align: center;
}

.styleSubscriptionPlan {
  background-size: 40px;
  color: #212a34;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: lowercase;
}

.unLimitedAccount__text {
  color: #555555;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 10px;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .unLimitedAccount__container {
    height: 240px;
  }
}