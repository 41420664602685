.questionTypeCheckbox-title {
  display: flex;
  color: #737D87;
  font-weight: 300;
  padding: 0 20px;
  margin: 20px 0 10px;
}

.questionTypeCheckbox-title::before {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-right: 10px;
  transform: translateY(-50%);
  width: 40px;
}

.questionTypeCheckbox-title::after {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  flex: 1 0 auto;
  margin-left: 10px;
  transform: translateY(-50%);
}

.questionTypeCheckbox-item {
  padding: 10px 20px;
}

.questionTypeCheckbox-group {
  align-items: center;
  display: flex;
}

.questionTypeCheckbox-checkbox {
  background-color: #FFFFFF;
  border: 1px solid #E0E8E8;
  border-radius: 50%;
  height: 40px;
  display: inline-block;
  position: relative;
  width: 40px;
}

.questionTypeCheckbox-checkbox:hover {
  box-shadow: 0 0 5px 0 #3DD8DB;
  border: 1px solid #3DD8DB;
}

.questionTypeCheckbox-checkboxDisabled {
  background-color: #EEEEEE;
  border: 1px solid #E0E8E8;
  border-radius: 50%;
  cursor: not-allowed;
  height: 40px;
  display: inline-block;
  position: relative;
  width: 40px;
}

.questionTypeCheckbox-checkboxCheckedDisabled {
  background-color: #BBBBBB;
  border: 1px solid #E0E8E8;
  border-radius: 50%;
  cursor: not-allowed;
  height: 40px;
  display: inline-block;
  position: relative;
  width: 40px;
}

.questionTypeCheckbox-label {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeCheckbox-labelChecked {
  background-color: #3DD8DB;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeCheckbox-labelDisabled {
  background-color: #EEEEEE;
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeCheckbox-labelCheckedDisabled {
  background-color: #BBBBBB;
  border-radius: 50%;
  cursor: not-allowed;
  display: block;
  height: 30px;
  left: 4px;
  position: absolute;
  transition: all .5s ease;
  top: 4px;
  width: 30px;
  z-index: 1;
}

.questionTypeCheckbox-optionTitle {
  color: #162737;
  flex: 1 1;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px
}

.questionTypeCheckbox-textarea {
  height: 80px;
  margin: 10px 0 0 50px;
  width: calc(100% - 70px)
}

.questionTypeCheckbox-optionMessage {
  margin-left: 50px;
  font-size: 14px;
}