@font-face {
  font-family: 'osprey_icons_v3';
  src: url('./fonts/osprey_icons_v3.eot?49685780');
  src: url('./fonts/osprey_icons_v3.eot?49685780#iefix') format('embedded-opentype'),
       url('./fonts/osprey_icons_v3.woff2?49685780') format('woff2'),
       url('./fonts/osprey_icons_v3.woff?49685780') format('woff'),
       url('./fonts/osprey_icons_v3.ttf?49685780') format('truetype'),
       url('./fonts/osprey_icons_v3.svg?49685780#osprey_icons_v3') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'osprey_icons_v3';
    src: url('../font/osprey_icons_v3.svg?49685780#osprey_icons_v3') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "osprey_icons_v3";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: auto;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-calendar-b:before { content: '\e800'; } /* '' */
.icon-tool-redo-neg:before { content: '\e801'; } /* '' */
.icon-edit-b:before { content: '\e802'; } /* '' */
.icon-annotation-b:before { content: '\e803'; } /* '' */
.icon-assetregister-icon-neg:before { content: '\e804'; } /* '' */
.icon-delete-b:before { content: '\e805'; } /* '' */
.icon-export-b:before { content: '\e806'; } /* '' */
.icon-access-asset-b:before { content: '\e807'; } /* '' */
.icon-untitled-1:before { content: '\e808'; } /* '' */
.icon-find-asset-b:before { content: '\e809'; } /* '' */
.icon-tool-redo-g:before { content: '\e80a'; } /* '' */
.icon-mail-b:before { content: '\e80b'; } /* '' */
.icon-dragpad:before { content: '\e80c'; } /* '' */
.icon-cloud-b:before { content: '\e80d'; } /* '' */
.icon-download-b:before { content: '\e80e'; } /* '' */
.icon-eye-closed-b:before { content: '\e80f'; } /* '' */
.icon-help-b:before { content: '\e810'; } /* '' */
.icon-minus-b:before { content: '\e811'; } /* '' */
.icon-monitor-b:before { content: '\e812'; } /* '' */
.icon-tool-move-g-:before { content: '\e813'; } /* '' */
.icon-news-b:before { content: '\e814'; } /* '' */
.icon-tool-move-neg:before { content: '\e815'; } /* '' */
.icon-tool-redo-g-1:before { content: '\e816'; } /* '' */
.icon-ok-b:before { content: '\e817'; } /* '' */
.icon-tool-redo-neg-1:before { content: '\e818'; } /* '' */
.icon-pages-b:before { content: '\e819'; } /* '' */
.icon-redaction-b:before { content: '\e81a'; } /* '' */
.icon-search-b:before { content: '\e81b'; } /* '' */
.icon-support-ticket-b:before { content: '\e81c'; } /* '' */
.icon-add-b:before { content: '\e81d'; } /* '' */
.icon-user-circle-b:before { content: '\e81e'; } /* '' */
.icon-tool-annotation-g:before { content: '\e81f'; } /* '' */
.icon-tool-annotation-neg:before { content: '\e820'; } /* '' */
.icon-tool-delete-g:before { content: '\e821'; } /* '' */
.icon-tool-delete-neg:before { content: '\e822'; } /* '' */
.icon-tool-download-g:before { content: '\e823'; } /* '' */
.icon-tool-download-neg:before { content: '\e824'; } /* '' */
.icon-tool-eraser-g:before { content: '\e825'; } /* '' */
.icon-tool-eraser-neg:before { content: '\e826'; } /* '' */
.icon-tool-redaction-g:before { content: '\e827'; } /* '' */
.icon-tool-redaction-neg:before { content: '\e828'; } /* '' */
.icon-tool-save:before { content: '\e82a'; } /* '' */
.icon-tool-save-neg:before { content: '\e82b'; } /* '' */
.icon-tool-search-g:before { content: '\e82c'; } /* '' */
.icon-tool-search-neg:before { content: '\e82d'; } /* '' */
.icon-tool-textselection-g:before { content: '\e82f'; } /* '' */
.icon-tool-textselection-neg:before { content: '\e830'; } /* '' */
.icon-tool-undo-g:before { content: '\e831'; } /* '' */
.icon-tool-undo-neg:before { content: '\e832'; } /* '' */
.icon-update-b:before { content: '\e833'; } /* '' */
.icon-user-b:before { content: '\e834'; } /* '' */
.icon-users:before { content: '\e835'; } /* '' */
.icon-users-g:before { content: '\e836'; } /* '' */
.icon-colourpalette-b:before { content: '\e83a'; } /* '' */
.icon-tick-button-neg:before { content: '\e83b'; } /* '' */
.icon-radio-button-neg:before { content: '\e83c'; } /* '' */
.icon-tick-button-off:before { content: '\e83d'; } /* '' */
.icon-radio-button-off:before { content: '\e83e'; } /* '' */
.icon-update-b-1:before { content: '\e83f'; } /* '' */
.icon-eye-open-b:before { content: '\e840'; } /* '' */
.icon-artboard-1:before { content: '\e843'; } /* '' */
.icon-assess-icon-neg:before { content: '\e844'; } /* '' */
.icon-home-icon-neg2:before { content: '\e847'; } /* '' */
.icon-sar-icon:before { content: '\e848'; } /* '' */