.footer-wrapper {
  padding: 25px;
  text-align: center;
}
.footer-wrapper.in-smartbox {
  padding: 0;
}
.footer-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 auto 15px;
}

.footer-logo {
  height: auto;
  margin-bottom: 10px;
  width: 135px;
}

.footer-listItem {
  display: inline-block;
  padding: 0 15px;
}

.footer-action {
  text-decoration: none;
  color: #999999;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
&:hover {
   text-decoration: underline;
 }
}

.footer-copyright {
  color: #999;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 20px;
a {
  text-decoration: none;
  font-weight: normal;
}
}

@media screen and (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    width: 400px;
  }
  .footer-logo {
    margin-bottom: 0;
  }
  .footer-navigation {
    margin-left: 30px;
  }
}

@media print {
  .footer-wrapper {
    display: none;
  }
}