/* WHITE */

.button {
  background-color: #FFFFFF;
  border: 1px solid #3DD8DB;
  border-radius: 40px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #3DD8DB;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
&:hover {
   background-color: #3DD8DB;
   color: #FFFFFF;
 }
}
.button-view {
  background-color: #FFFFFF;
  border: 1px solid #3DD8DB;
  border-radius: 40px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #3DD8DB;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
  width: 8rem;
  &:hover {
    background-color: #3DD8DB;
    color: #FFFFFF;
  }
}

/* GREEN */

.button-green {
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
&:hover {
   background-color: #FFFFFF;
   color: #3DD8DB;
 }
}

/* GRAY */

.button-gray {
  background-color: #FFFFFF;
  border: 1px solid #bec0c4;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #000000;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
&:hover {
   background-color: #bec0c4;
   color: #FFFFFF;
 }
}

.button-newBusiness {
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 15px 30px;
  text-transform: uppercase;
  transition: all .2s;
  margin: 16px 4px;
&:hover {
   background-color: #FFFFFF;
   color: #3DD8DB;
 }
}
.button-newBusinessOnFocus{
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 9px 45px;
  text-transform: uppercase;
  transition: all .2s;
  margin: 16px 4px;
  // height: 45px;
&:hover {
   background-color: #FFFFFF;
   color: #3DD8DB;
 }
}
.button-editProfile {
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 12px 30px;
  text-transform: uppercase;
  transition: all .2s;
  margin: 12px 10px;
&:hover {
   background-color: #FFFFFF;
   color: #3DD8DB;
 }
}

.button-second {
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
&:hover {
   background-color: #FFFFFF;
   color: #3DD8DB;
 }
}

/* DARK */

.button-dark {
  background-color: #162737;
  border: 1px solid #162737;
  border-radius: 20px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
&:hover {
   background-color: #3DD8DB;
 }
}

/* RED */

.button-red {
  background-color: #FFFFFF;
  border: 1px solid #DA282C;
  border-radius: 20px;
  color: #DA282C;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  padding: 8px 30px;
  text-transform: uppercase;
  transition: all .2s;
&:hover {
   background-color: #DA282C;
   color: #FFFFFF;
 }
}