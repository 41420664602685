.page-container-tags {
  //padding: 134px 100px 0;
  margin: 50px 50px;
  padding:0px;
  .page-filter {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
    &__header {
      margin-top: 0px;
      justify-content: flex-end;
      &--button-container {
        display: flex;
        align-items: center;
        .button-newBusiness {
          box-shadow: none;
          margin: 0px;
        }
        .icon-v21-add {
          font-size: 16px;
          color: var(--generalButton);
          margin-left: 25px;
        }
        .icon-v21-add-active {
          font-size: 16px;
          color: var(--generalIconFontHover);
          margin-left: 25px;
          cursor: pointer;
        }
        .icon-v21-upload-active-1 {
          font-size: 16px;
          color: #EDA41E;
          margin-left: 25px;
        }
        .icon-v21-upload-1 {
          font-size: 16px;
          color: #382e34;
          margin-left: 25px;
        }
        .icon-v21-download-1 {
          font-size: 16px;
          color: #382e34;
          margin-left: 25px;
        }
        .icon-v21-download-active-1 {
          font-size: 16px;
          color: #EDA41E;
          margin-left: 25px;
        }
      }

      &--filter-container {
        margin-bottom: 30px;
        #questionnaireFilter__teams .ant-select-selection {
          border-radius: 0px;
          font-size: 14px;
          height: 30px;
          padding: 5px 20px 5px 10px;
          border: 1px solid #382e34;
          font-weight: 400;
          box-shadow: none;
        }
      }
    }
  }

  .page-content {
    .tags-table {
      .list-selected-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #cccccc;
        float: left;
        margin-top: 13px;
        margin-left: 7px;
        cursor: pointer;
      }
      .input-default {
        border: none;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 4px 35px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
      }
      .custom-status-select {
        width: 100px;
      }
      .icon-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 4px;
        //background-color: #0b2e56;
        display: flex;
      }
      table {
        border-radius: 0 !important;
      }
      .ant-input:hover {
        border-color: #EDA41E;
        box-shadow: none;
      }
      .ant-input:active {
        border-color: #EDA41E;
        box-shadow: none;
      }
      .ant-input:focus {
        border-color: #EDA41E;
        box-shadow: none;
      }
      .ant-table-body {
        border-radius: 0;
        overflow: hidden;
        font-size: 16px;
        box-shadow: 0 2px 4px 0 #DCDCDC;
        font-family: 'Roboto';
        font-weight: 400;

        .ant-table-thead {
          tr {
            background: white;

            th {
              text-transform: uppercase;
              font-family: 'Roboto';
              color: #7f7f7f;
              font-size: 14px;
              font-weight: 400;
            }
          }

          tr:first-child > th:last-child {
            border-radius: 0 !important;
          }

          tr:first-child > th:first-child {
            border-radius: 0 !important;
          }

        }


        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
          background: white;
        }

        .ant-table-tbody {
          background-color: white;

          tr {
            td {
              border-bottom: 1px solid #d6d3d3;
            }
          }
        }

      }
      .icon-v21-edit {
        font-size: 20px;
        color: #382e34;
      }
      .icon-v21-edit-active {
        font-size: 20px;
        color: var(--generalIconFontHover);
        cursor: pointer;
      }
      .edit-button {
        color: #3dd8db;
        text-decoration: underline;
        font-size: 15px;

        &:hover {
          color: #196164;
        }

        &:disabled {
          border: none;
        }
      }
      .content-edit-annotate {
        display: flex;
        flex-direction: column;
        .icon-v19-delete {
          font-size: 20px;
          color: #382e34;
          width: 20px;
          height: 20px;
        }
        .icon-v19-delete-active {
          font-size: 20px;
          color: var(--generalIconFontHover);
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
      .save-button {
        color: #382e34;
        text-decoration: underline;
        font-size: 15px;

        &:hover {
          color: #EDA41E;
          cursor: pointer;
        }
        &:disabled {
          &:hover {
            color: rgba(0, 0, 0, 0.25);
          }
        }

      }

      .cancel-button {
        color: #db170d;
        text-decoration: underline;
        font-size: 15px;

        &:hover {
          color: rgba(250, 20, 12, 0.4);
          cursor: pointer;
        }

      }
    }
  }
}