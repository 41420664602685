.connectorCard__wrapper {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32%;
  margin-bottom: 20px;
}

.connectorCard__wrapper .loading-container {
  height: 75px;
  line-height: 100px;
  margin: 0 auto;
}

.connectorCard__header {
  align-items: flex-start;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  min-height: 56px;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.connectorCard__header .iconEdit {
  cursor: pointer;
  margin-top: -16px;
  position: absolute;
  right: 10px;
  top: 50%;
}

.connectorCard__description {
  color: #737E89;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-top: 10px;
}

.connectorCard__content {
  padding: 15px;
  position: relative;
}

.connectorCard__info {
  color: #162737;
  font-size: 18px;
}

.connectorCard__buttons {
  margin-top: 10px;
}

.connectorCard__buttonRescan {
  border-bottom: 1px solid #FFFFFF;
  color: #1DCBCF;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.connectorCard__buttonRescan:hover {
  border-bottom: 1px solid #1DCBCF;
}

@media screen and (min-width: 768px) {
  .connectorCard__header {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}