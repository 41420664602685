.collectionTable__row td {
  vertical-align: middle;
}

.collectionTable__actions {
  color: #1DCBCF !important;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  position: relative;
}

.collectionTable__loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.collectionTable__loading .loading-container {
  height: 14px;
  width: 14px;
}

.collectionTable__menu {
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 #DCDCDC;
  border-radius: 5px;
  left: -45px;
  position: absolute;
  text-align: left;
  top: 25px;
  z-index: 1;
}

.collectionTable__menu-item a {
  border-bottom: 1px solid #EAEAEA;
  color: #111C26;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  padding: 10px;
  width: 120px;
}

.collectionTable__menu-item a:hover {
  background-color: #1DCBCF;
  color: #FFFFFF;
}