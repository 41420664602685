.notification-warning-upload {
  .ant-notification-notice-content {
    height: auto;
    max-height: 350px;

    .ant-notification-notice-description {
      overflow-wrap: break-word;
      width: 85%;
      overflow-y: auto;
      max-height: 337px;
    }
  }
}
.mega-drop-zone {
  position: fixed;
  margin-top: 0px;
  bottom: 0;
  right: 0;
  width: calc(100% - 0px);
  z-index: 1;
  background-color: #66536020;

  .dzu-dropzone {
    background-color: transparent;
    width: 100%;
    height: 45px !important;
    border-radius: 0px;
    cursor: pointer;
    /*margin-right: 8px;
    margin-bottom: 8px;*/
    text-align: center;
    display: table;
    float: left;
    padding-top: 0px;
    .hidden-file-input {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      opacity: 0;
    }
    .hidden-file-input:after {
      content:'';
      height:100%;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      z-index:1;
    }
    .btn-upload {
      display: table-cell;
      width: 100%;
      height: 100%;
      padding: 8px;
      text-align: center;
      vertical-align: middle;
      font-size: 15px;
      opacity: 0.4;

      &:drop,
      &:hover {
        border: 1px dashed #00babe;
      }
    }
  }

  .dzu-dropzoneActive {
    .btn-upload {
      border: 2px dashed #150010 !important;
      background-color: #382e34;
    }
  }
}
.drag-over-breadcrumb {
  position: relative;
  z-index: 0;
}
.mega-drop-zone.mega-empty {
  height: 100% !important;
  padding-top: 130px !important;
  z-index: 0 !important;
  .dzu-dropzone {
    .btn-upload {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
}
.mega-drop-zone.full-height .dzu-dropzone {
  height: 100% !important;
}