.connectorList__wrapper {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .connectorList__wrapper {
    flex-direction: row;
  }
}
