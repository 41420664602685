.dpiaShell-loading {
  display: flex;
  justify-content: center;
  margin: 140px auto;
}

//Admin page styles

.admin-page {
  height: 100%;

  .ant-layout {
    background-color: #fafafa;
  }
  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-toggle {
      margin-right: 7px;
    }
    .header-logo {
      display: flex;
      margin-left: 10px;
      align-items: center;
      .header-logo-text {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        span {
          line-height: 12px;
        }
      }
    }

    .header-infoContainer {
      .header-userInfo {
        line-height: 15px;

        &:after {
          bottom: 15px;
        }
      }

      .header-menu {
        .header-listItem {
          a, button {
            padding: 0 15px 0 20px;
          }
        }
      }
    }

  }
}

.ant-menu-item {

  a {
    outline: none !important;
    text-decoration-line: none;
    &:hover {
      color: #03D3D8;
      text-decoration: none;
    }
  }
  &:hover {
    color: #03D3D8;
  }
  &-selected {
    background-color: rgba(3, 211, 216, 0.3) !important;
    color: #03595e;
    span > a {
      color: #03595e;
    }
    &:after {
      border-right: 3px solid #03D3D8;
    }

  }
}

@media all and (max-width: 756px) {
  .mobile-nav-bar {
    .ant-drawer-body {
      width: 100%;
      height: 100%;
      padding: 0;
      .admin-navbar {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        flex: 0 0 100% !important;
        height: 100%;
      }
    }

  }
}