.dpiaDashboard-message {
  background-color: #FFFFFF;
  border-radius: 5px;
p {
  border-top: 1px solid #DCDCDC;
  padding: 20px;
}
}

.dpiaDashboard-page {
  margin: 0 auto;
  max-width: 1200px;
  padding: 128px 20px 0;
}

.dpiaDashboard-learn {
  align-items: center;
  background-color: #F5FAFB;
  border: 1px solid #89d8dc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 14px 15px 14px 25px;
  width: 65%;
  margin-top: -2px;
  margin-right: 20px;
  line-height: 18px;
  width: 100%;
}

.dpiaDashboard-learnLink {
  color: #00B9BD;
  font-size: 14px;
  font-weight: 400;
&:hover {
   font-style: italic;
 }
}

.dpiaDashboard-learnClose {
  color: #00B9BD;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
&:hover {
   font-weight: 700;
 }
}

.dpiaDashboard-separator {
  border-top: 1px solid #CCCCCC;
  padding-top: 30px;
}

.dpiaDashboard-loading {
  display: flex;
  justify-content: center;
}

.dpiaDashboard__block-title {
  display: flex;
  color: #737D87;
  font-weight: 700;
  margin: 30px 0 10px;
  font-family: 'Roboto';
  font-size: 12px;
}

.dpiaDashboard__block-longTitle {
  display: flex;
  color: #737D87;
  font-weight: 300;
  margin-bottom: 10px;
&::before {
   border-bottom: 1px solid #C5C9CD;
   content: "";
   margin-right: 10px;
   transform: translateY(-50%);
   width: 42%;
 }
&::after {
   border-bottom: 1px solid #C5C9CD;
   content: "";
   margin-left: 10px;
   transform: translateY(-50%);
   width: 42%;
 }
}

.dpiaDashboard__block-title {
&::before {
   border-bottom: 1px solid #C5C9CD;
   content: "";
   margin-right: 10px;
   transform: translateY(-50%);
   width: 45%;
 }
&::after {
   border-bottom: 1px solid #C5C9CD;
   content: "";
   margin-left: 10px;
   transform: translateY(-50%);
   width: 52%;
 }
}

.dpiaDashboard-footerContainer {
  display: flex;
  flex-direction: column;
}

.dpiaDashboard-buttonAddContainer {
  margin-bottom: 30px;
}

.dpiaDashboard-limitedFreeAccountContainer {
  flex: 1;
}

.piaActiveAssessment_link {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.dpiaDashboard-page {
#collection-list {
  overflow: visible;
th {
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Roboto';
  font-weight: 400;
}
td {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Roboto';
  font-weight: 400;
}
}
td.collectionList__cellTitle {
  font-weight: 700 !important;
}
}

.dpiaDashboard__box {
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  margin-bottom: 30px;
  position: relative;
}

.dpiaDashboard__boxHeader {
  border-bottom: 1px solid #E6E6E6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
  -ms-align-items: center;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;
> div {
  float: left;
}
}

.dpiaDashboard__boxTitleTotal {
  font-size: 18px;
  font-weight: 300;
  position: relative;
strong {
  font-weight: 700;
}
p {
  display: inline;
  margin-left: 5px;
  font-weight: 100;
}
}

.dpiaDashboard__boxText {
  margin-bottom: 0;
  color: #737E89;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
  margin-top: 10px;
}

.dpiaDashboard-page .pagination__wrapper li:not(.pagination__nav):not(.pagination__nav--disabled) {
  padding: 15px 12px;
}

.sort-column {
  cursor: pointer;
}

.sort-empty {
  background-image: url(../../../assets/icon-sort-empty.png);
  background-repeat: no-repeat;
  display: inline-block;
  width: 7px;
  height: 11px;
  margin-left: 5px;
}

.sort-asc {
  background-image: url(../../../assets/icon-sort-up.png);
  background-repeat: no-repeat;
  display: inline-block;
  width: 7px;
  height: 11px;
  margin-left: 5px;
}

.sort-desc {
  background-image: url(../../../assets/icon-sort-down.png);
  background-repeat: no-repeat;
  display: inline-block;
  width: 7px;
  height: 11px;
  margin-left: 5px;
}

#questionnaireFilter__teams {
  line-height: 20px;
  line-height: 20px;

  .ant-select-selection {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAABGdBT…h0ZGF0ZTptb2RpZnkAMjAxNy0wNi0wNlQxMzo0MjoyMiswMDowMOHjg5cAAAAASUVORK5CYII=) no-repeat 97% 50% #FFFFFF;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    font-size: 16px;
    height: 42px;
    padding: 10px 25px 10px 10px;
    outline: none;
    width: 100%;

    &__rendered {
      display: block;
      margin-right: 11px;
      line-height: 30px;
      margin-top: -5px;
      margin-left: 0;
    }
  }
}
.filterCollectionItem-inputFindFocus {
  width: 200px;
  outline: 2px solid #3DD8DB;
}
#filterStyle {
  width: 185px;
}

@media screen and (min-width: 768px) {
  .dpiaDashboard-page {
    padding: 128px 40px 0;
  }
  .dpiaDashboard-editContainer {
    margin-left: auto;
    margin-right: 50px;
    width: auto;
  }
  .dpiaDashboard-footerContainer {
    flex-direction: row;
  }
  .dpiaDashboard-buttonAddContainer {
    flex: 1;
  }
  .dpiaDashboard-limitedFreeAccountContainer {
    margin-left: 20px;
  }
}
.questionnaireFilter__select {
  margin: 0 20px 0 0;
  max-width: 300px;
  width: 210px;
}
#styleColSpan {
  height: 20px;
}
  @media (max-width: 1000px) and (min-width: 948px) {
    .filterDpia__wrapper .questionnaireFilter__select {
      width: 160px;
      margin-right: 20px;
    }
  }
  @media (max-width: 947px) and (min-width: 768px) {
    .filterDpia__wrapper {
    .questionnaireFilter__select {
      width: 130px;
      margin-right: 7px;
    }
    .questionnaireFilter__button {
      width: 50px;
      margin-right: 0;
    }
  }
}
@media (max-width: 1334px) and (min-width: 1200px) { 
  .filterCollectionItem-inputFind {
    width: 0;
}
#filterStyle {
  width: 185px;
}
.button-newBusiness {
  background-color: #3DD8DB;
  border: 1px solid #3DD8DB;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 15px 8px;
  text-transform: uppercase;
  transition: all .2s;
}
}

.button-download-csv {
  margin-right: 1rem;
  background-color: #FFFFFF;
  width: 100%;
  height: 4rem;

  .button_big_blue {
    float: right;
    margin-right: 30px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1497px) and (min-width: 1200px) {
  .button-newBusiness {
    background-color: #3DD8DB;
    border: 1px solid #3DD8DB;
    border-radius: 30px;
    -webkit-box-shadow: 0 2px 4px 0 #DCDCDC;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 10px;
    padding: 15px 8px;
    text-transform: uppercase;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
  // .button-newBusinessOnFocus{
  //   background-color: #3DD8DB;
  //   border: 1px solid #3DD8DB;
  //   border-radius: 30px;
  //   box-shadow: 0 2px 4px 0 #DCDCDC;
  //   color: #FFFFFF;
  //   cursor: pointer;
  //   font-family: 'Ubuntu', sans-serif;
  //   font-weight: 700;
  //   font-size: 10px;
  //   padding: 12px 45px;
  //   text-transform: uppercase;
  //   transition: all .2s;
  //   margin: 16px 4px;
  //   height: 45px;
  // &:hover {
  //    background-color: #FFFFFF;
  //    color: #3DD8DB;
  //  }
  // }
}

