.backofficeTaxonomies__wrapper {
    margin: 0 auto;
    max-width: 1200px;
    padding: 40px 20px 0;
  }

  .ospreyTaxonomies__loading {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
  }
  