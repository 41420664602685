.data-source {
  background-color: #FFFFFF;
  display: flex;
  width: 100%;
  padding: 160px 40px 0;
  position: relative;
  &__page {
    width: 100%;
    height: 100%;
    .header-data-source {
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      .content-edit-template {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .icon-v19-delete {
          font-size: 18px;
        }
        .icon-v19-delete:disabled {
          font-size: 18px;
          color: #D4D7DA;
        }
        .icon-v19-delete-active {
          font-size: 18px;
          color: var(--generalIconFontHover);
        }
        .select-form {
          width: 20%;
          color: #6e7882;
          font-weight: 400;
          font-size: 14px;
          margin-right: 10px;
          .ant-select-selection {
            border: 1px solid #382e34;
            border-radius: 0;
          }
          .ant-select-selection:hover {
            border: 1px solid #EDA41E !important;
            border-right-width: 1px !important;
          }
        }
      }
    }
    .action-header-data-source {
      margin-top: 40px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      display: flex;
      .content-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .input-form-name {
        border: 1px solid #6e7882;
        color: #6e7882;
        background-color: #FFFFFF;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        height: 35px;
        border-radius: 0px;
        width: 30%;
        &:hover {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:focus {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
        &:active {
          border: 1px solid #EDA41E;
          box-shadow: none;
        }
      }
      .button-save-source {
        background-color: var(--generalButton);
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: none;
        color: var(--generalButtonFont);
        cursor: pointer;
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        padding: 3px !important;
        text-transform: none;
        transition: none;
        line-height: 18.75px;
        width: 20%;
        margin-left: 40px;
        height: 26px;
        &:hover {
          background-color: var(--generalButtonHover);
          border: 1px solid var(--generalButtonHover);
          //color: #FFFFFF;
        }
        &:active {
          background-color: var(--generalButtonActive);
          border: 1px solid var(--generalButtonActive);
          //color: #FFFFFF;
        }
        &:disabled {
          cursor: default;
          background: #D4D7DA !important;
        }
      }
      .button-cancel-source {
        background-color: #FFFFFF;
        border: 1px solid #6e7882;
        border-radius: 20px;
        box-shadow: none;
        color: #6e7882;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        padding: 3px !important;
        text-transform: none;
        transition: none;
        line-height: 18.75px;
        width: 20%;
        height: 26px;
        &:hover {
          background-color: #FFFFFF;
          border: 1px solid var(--generalButtonHover);
          color: var(--generalButtonHover);
        }
        &:active {
          background-color: #FFFFFF;
          border: 1px solid var(--generalButtonActive);
          color: var(--generalButtonActive);
        }
      }
    }

    .data-source-transfer-content {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .content-caret {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        .anticon {
          font-size: 40px;
        }
      }
      .data-source-transfer {
        .transfer-data {
          .ant-transfer-list {
            border: 1px solid #6e7882;
            //width: 60%;
            .ant-transfer-list-header {
              height: 30px;
              padding: 4px 5px 2px;
              overflow: hidden;
              color: #382e34;
              background: #fff;
              border-bottom: 1px solid #6e7882;
              border-radius: 4px 4px 0 0;
              .ant-transfer-list-header-selected {
                span {
                  display: none;
                }
                .ant-transfer-list-header-title {
                  display: flex !important;
                  width: 90%;
                  .header-list-source {
                    display: flex !important;
                    color: #382e34;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 14px;
                  }
                }
              }
            }
            .ant-transfer-list-body {
              .ant-transfer-list-content {
                .ant-transfer-list-content-item {
                  .ant-checkbox-wrapper {
                    .ant-checkbox{
                      .ant-checkbox-input {
                        visibility: visible;
                      }
                    }
                  }
                }
                .ant-checkbox-wrapper {
                  margin-left: 5px;
                }
                .ant-checkbox + span {
                  font-size: 14px;
                  font-family: Roboto;
                  color: #382e34;
                  font-weight: 400;
                  padding-right:0;
                }
                .ant-checkbox-wrapper .ant-checkbox-inner {
                  border-radius: 0% !important;
                  border: 1px solid #6e7882;
                }
                .ant-checkbox-checked .ant-checkbox-inner::after {
                  border: 1px solid #fff;
                  border-top: 0;
                  border-left: 0;
                  top: 46%;
                  left: 19%;
                }
                .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner {
                  border-color: var(--generalIconFontHover);
                }
                .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
                  border-color: var(--generalIconFontHover);
                }
                .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
                  border-color: #FFFFFF;
                  background-color: #FFFFFF;
                  color: #FFFFFF;
                  border: none !important;
                  border-top: 0;
                  border-left: 0;
                  -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  transform: rotate(0deg) scale(1) translate(-50%, -50%);
                  opacity: 1;
                  -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
                  content: '\e8ce' !important;
                  top: 7px !important;
                  left: 7px !important;
                  font-size: 12px !important;
                  font-family: 'smartbox_icons_v19' !important;
                }
                .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: var(--generalIconFontActive) !important;
                  border: 1px solid #6e7882;
                }
              }
            }
          }

          .ant-transfer-operation {
            margin: 0 15px;
            .ant-btn-icon-only.ant-btn-sm {
              width: 24px;
              height: 24px;
              padding: 0;
              font-size: 14px;
              border-radius: 50px;
            }
            .ant-btn-primary {
              color: var(--generalIconFontHover);
              background-color: #FFFFFF;
              border-color:var(--generalIconFontHover);
            }
            button:disabled {
              color: #6e7882;
              background-color: #FFFFFF;
              border-color: #6e7882 !important;
              border: 1px solid #6e7882 !important;
            }
          }
        }
      }
    }

    .save-new-template {
        display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #382e34;
      border-top: 1px solid #382e34;
      margin-top: 40px;
      margin-bottom: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      .ant-select-selection {
        border-radius: 0px;
        border: 1px solid #382e34;
        .ant-select-arrow {
          color: #382e34;
        }
      }
      .select-form {
        width: 80%;
        margin-bottom: 5px;
        color: #6e7882;
        font-weight: 400;
        font-size: 14px;
        border-radius: 0px;
        .ant-select-selection--single:hover {
          border: 1px solid #EDA41E !important;
          border-right-width: 1px !important;
        }
        .ant-select-selection--single:focus {
          border: 1px solid #EDA41E !important;
          border-right-width: 1px !important;
        }

      }
      .save-template {
        font-size: 14px;
        font-weight: 400;
        color: #382e34;
      }
      .icon-v17-add {
        font-size: 16px;
        color: var(--generalButton);
      }
      .icon-v17-add-active {
        font-size: 16px;
        color: var(--generalIconFontHover);
      }
    }

    .page-source-content {
      display: flex;
      margin-top: 10px;
      .form-source-content {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
    }
    .component-form {
      .component-content {
        .input-form-name {
          border: 1px solid #382e34;
          color: #6e7882;
          &:hover {
            border: 1px solid #382e34;
          }
          &:focus {
            border: 1px solid #382e34;
          }
        }
        .label-input {
          color: #6e7882;
        }
      }

    }
  }
}
.select-dropdown {
  border: 1px solid #eda41e !important;
  z-index:1 !important;
}