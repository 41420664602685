.form-workflow-page {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  &__header{
    display: flex;
    flex-direction: column;
    .name-former {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
    }
    .details-former {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
      margin-top: 30px;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .full-name-former-update {
      margin-bottom: 10px;
    }
    .full-name-former {
      margin-top: 20px;
      .header-former-name {
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 20px !important;
        letter-spacing: 0.2px;
        color: #382e34 !important;
      }
      .input-content-upload {
        .anticon-spin {
          color: #3DD8DB;
          font-size: 30px;
        }
        .cloud-upload {
          font-size: 20px;
        }
        .anticon {
          font-size: 20px;
        }
        .ant-upload-text {
          margin: 0 0 4px;
          color: #382e34;
          font-size: 16px;
          font-weight: 600;
        }
        .ant-upload-hint {
          color: #D4D7DA;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .ant-upload.ant-upload-select-picture-card {
        width: 100% !important;
        margin-top: 36px;
      }
      .ant-upload.ant-upload-select-picture-card {
        width: 100% !important;
        margin-top: 36px;
      }
       .header-former {
         font-size: 16px;
         font-weight: 600;
         line-height: 20px;
         letter-spacing: 0.2px;
         color: #382e34;
       }
    .subheader-title {
        font-size: 14px;
        font-family: Roboto;
        color: #382e34;
        font-weight: 400;
        margin-bottom: 10px;
      }
      .header-former-address {
        //margin-top: 20px;
        display: flex;
        margin-bottom: 10px;
      }
      .full-name-component {
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        .input-content {
          width: 49%;
          .input-form-name {
            border: 1px solid #D4D7DA;
            color: #6e7882;
            background-color: #ffffff40;
            padding: 0 10px;
            font-size: 16px;
            font-weight: 400;
            height: 30px;
            border-radius: 0px;
            cursor:default;
            &:hover {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
            &:focus {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
            &:active {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
          }
          .label-input {
            font-size: 12px;
            font-family: Roboto;
            color: #382e34;
            font-weight: 400;
            margin-top: 5px;
            margin-bottom: 5px;
            display: flex;
          }
        }
      }
      .input-content {
        width: 49%;
        .input-form-name {
          border: 1px solid #D4D7DA;
          color: #6e7882;
          background-color: #ffffff40;
          padding: 0 10px;
          font-size: 16px;
          font-weight: 400;
          height: 30px;
          border-radius: 0px;
          cursor:default;
          &:hover {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }
          &:focus {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }
          &:active {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }
        }
        .input-form-name.long-text-input {
          height: 60px;
        }
        .label-input {
          font-size: 12px;
          font-family: Roboto;
          color: #382e34;
          font-weight: 400;
          margin-top: 5px;
          margin-bottom: 10px;
          display: flex;
        }
        .select-data-form {
          width: 20%;
          .ant-select-selection {
            border-radius: 0;
            height: 30px;
            border: 1px solid #D4D7DA;

          }
        }
        .ant-radio-group {
          .ant-radio {
            .ant-radio-inner {
              border: 1px solid #D4D7DA;
            }
          }
          .ant-radio-checked {
            .ant-radio-inner::after {
              background-color: #d4d7da;
            }
          }
        }
        .ant-checkbox-wrapper .ant-checkbox-inner {
          border-radius: 0% !important;
          border: 1px solid #d4d7da;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
          border: 1px solid #fff;
          border-top: 0;
          border-left: 0;
          top: 46%;
          left: 19%;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
          border-color: #d4d7da;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
          border-color: #FFFFFF;
          background-color: #FFFFFF;
          color: #FFFFFF;
          border: none !important;
          border-top: 0;
          border-left: 0;
          -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
          transform: rotate(0deg) scale(1) translate(-50%, -50%);
          opacity: 1;
          -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
          content: '\e8ce' !important;
          top: 7px !important;
          left: 7px !important;
          font-size: 12px !important;
          font-family: 'smartbox_icons_v19' !important;
        }
        .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #d4d7da !important;
          border: 1px solid #d4d7da;
        }
        .ant-upload.ant-upload-drag {
          border: 1px solid #d4d7da;
          border-radius: 0px;
        }
        .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
          color: #d4d7da;
          font-size: 48px;
        }
        .ant-upload.ant-upload-drag p.ant-upload-text {
          margin: 0 0 4px;
          color: #382e34;
          font-size: 16px;
          font-weight: 600;
        }
        .ant-upload.ant-upload-drag p.ant-upload-hint {
          color: #382e34;
          font-size: 14px;
        }
      }
      .input-header-component {
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        .input-content {
          width: 49%;
          .shortText-character-count {
            font-size: 12px;
            font-family: Roboto;
            color: #382e34;
            font-weight: 400;
            margin-top: 5px;
            margin-bottom: 5px;
            display: flex;
            justify-content: flex-end;
          }
          .input-form-name {
            border: 1px solid #D4D7DA;
            color: #6e7882;
            background-color: #ffffff40;
            padding: 0 10px;
            font-size: 16px;
            font-weight: 400;
            height: 30px;
            border-radius: 0px;
            cursor:default;
            &:hover {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
            &:focus {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
            &:active {
              border: 1px solid #D4D7DA;
              box-shadow: none;
            }
          }
          .label-input {
            font-size: 12px;
            font-family: Roboto;
            color: #382e34;
            font-weight: 400;
            margin-top: 5px;
            margin-bottom: 5px;
            display: flex;
          }
        }
      }
      .signature-content {
        height: 100px;
        padding: 10px;
        border: 1px solid #d4d7da;
        background-color: #fafafa;;
        width: 50%;
        margin-bottom: 0;
        .signature {
          border: 1px solid #d4d7da;
          background-color: #ffffff;
          width: 100%;
          height: 70%;
        }
        .button-add-signature:disabled {
          cursor: not-allowed;
        }
        .button-add-signature {
          background-color: #fff;
          border: 1px solid #eda41e;
          color: #eda41e;
          border-radius: 20px;
          width: auto;
          cursor: pointer;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 14px;
          padding: 4px 20px;
          margin: 5px 20px
        }
        .button-small-red-empty {
          border-radius: 20px;
          width: auto;
          cursor: pointer;
          font-family: "Roboto";
          text-transform: capitalize;
          font-weight: 400;
          font-size: 14px;
          padding: 4px 20px;
          margin: 5px 0px
        }
      }
    }
  }
}
.select-data-form-option {
  border: 1px solid #D4D7DA;
}