.subjectsList-name {
  font-size: 13px;
  font-family: 'Ubuntu';
  font-weight: 300;
  line-height: 18px;
  width: 100%;
  margin-right: 10px;
}

.subjectsList-date {
  font-size: 14px;
  margin-right: 10%;
  font-family: 'Roboto';
  line-height: 18px;
  width: 40%;
  font-weight: 400;
  color: #7f7f7f;
}

.subjectsList-found {
  font-size: 14px;
  margin-right: 10px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 18px;
  width: 27%;
}

.subjectsList-step {
  font-size: 14px;
  margin-right: 10%;
  font-family: 'Roboto';
  line-height: 18px;
  width: 27%;
  font-weight: 400;
  color: #7f7f7f;
}

.subjectsList-time {
  font-size: 13px;
  font-family: 'Ubuntu';
  font-weight: 300;
  line-height: 18px;
  width: 21%;
  margin-right: 10px;
  text-align: center;
}

.subjectsList-span {
  flex: none;
  width: 85%;
  color: #111C26;
  cursor: pointer;
  -webkit-flex: 1 1;
  flex: 1 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
  font-size: 16px;
  max-width: 50%;
}
.check-step-state {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.subjectsList-spanRedacting {
  flex: none;
  width: 85%;
  color: #111C26;
  cursor: pointer;
  -webkit-flex: 1 1;
  flex: 1 1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-right: 15px;
  padding: 15px 0;
  font-size: 16px;
  max-width: 50%;
}

.time-span-color {
  color: #C70039;
}
.time-span-color-yellow {
  color: #FFc300;
}

.SubjectList-infoContainer {
  -webkit-align-items: center;
  align-items: center;
  border-right: 1px solid #DCDCDC;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 70px 0 15px;
  min-height: 70px;
  padding-right: 20px;
  width: 100%;
  cursor: pointer;
}

.subjectList__block-title {
  display: -webkit-flex;
  display: flex;
  color: #737D87;
  font-weight: 700;
  margin: 30px 0 10px;
  font-family: 'Roboto';
  font-size: 12px;
}
.subjectList__block-title::before {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.subjectList__block-title::after {
  border-bottom: 1px solid #C5C9CD;
  content: "";
  margin-left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.styleAccordion {
  width: 100%
}

p.nowrap{
  white-space: nowrap;
}

.subject-list-info {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  font-size: 14px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px 40px 20px 20px;
  position: relative;
  .emailAccordion {
    width: 30%;
    margin-left: 40px;
  }
  .detailsAccordion {
    width: 70%;
  }
  .archiveButton {
    margin-right: 50px;
  }
}

.noDashboard {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #DCDCDC;
  position: relative;
  height: 7rem;
  align: center;
  justify-content: center;
  padding-top: 47px;
  display: flex;
}


.sar-dashboard-table.sar-dashboard-table-new {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background: white;
  .content-delete-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    .icon-v19-delete {
      font-size: 40px;
      color: #382e34;
    }
  }
  .icon-v14-options-small {
    padding-top: 2px;
  }
  .icon-v14-options-small-active {
    color: #eda41e;
    font-size: 20px;
  }
  .options-small {
    color: #382E34;
  }
  .ellipsis-v2 {
    writing-mode: horizontal-tb;
    font-size: 24px;
    color: #6e7882;
    margin-right: 15px;
    padding-right: 10px;
    display: flex;
    position: absolute;
    right: 10px;
    bottom: 0px;
  }
  .actionsDotsCell {
    button {
      color: black !important;
      padding: 0 !important;
      background-color: transparent !important;
      border: none !important;
      font-size: 30px;
      line-height: 22px;
      outline: none;
      margin-top: 0px;

      &:focus {
        box-shadow: none;
      }
    }


    .dropdown {
      font-family: 'Roboto';
      font-weight: 400;

      .ellipsis-v {
        -webkit-writing-mode: horizontal-tb;
        writing-mode: horizontal-tb;
        font-size: 26px;
        font-weight: 700;
        float: right;
        cursor: pointer;
        padding-top: 13px;
        color: #000000;

        &:hover {
          color: #00d3d8;
        }
         .icon-v14-options-small {
           padding-top: 2px;
         }

      }

      &.open .ellipsis-v {
        color: #00d3d8;
      }

      .dropdown-menu {
        left: -153px !important;
        padding: 0;
        top: 30px;
        border: 1px solid #00d3d8;

        & > li {
          border-bottom: none;
        }
      }
    }
  }
  .action-drop-cell {
    .dropdown-menu {
      top: 5px !important;
    }
  }
  .menu-left-item-directory {
    padding: 0px 10px !important;
  }
  .menu-duplicate {
    .dropdown-item {
      color: #382e34;
      letter-spacing: 0.2px;
      border: 0px;
      font-size: 14px;
      font-family: Roboto;
      line-height: 30px;
    }
    .dropdown a:hover {
      background-color: #9AE0FC50;
      color: #382e34;
      border: 1px solid #eda41e !important;
    }
    .dropdown:hover .dropdown-menu {
      border: 1px solid #eda41e !important;
    }
    .dropdown .dropdown-menu {
      background-color: #FFFFFF;
      position: absolute;
      top: -21px !important;
      left: -93px !important;
      width: 120px;
      height: 30px;
      border: 1px solid #382e34;
      box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3);
      margin-top: 5px;
      font-size: 14px;
      color: #382e34;
      border-radius: 0rem;
    }
  }

  .column-box {
    .ant-table-header-column {
      width: 50%;
    }
  }
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: 2px !important;
  }
  .row-selected-table {
    background-color: #DCF1F9;
    border-bottom: 1px solid #9AE0FC;
    .ant-table-tbody > tr > td {
      border: 1px solid #9AE0FC;
      background-color: #FFFFFF;
    }
  }
  .style-actions {
    width: 8%;
  }
  .resize-icon-v10-data {
    font-size: 30px;
  }

  .ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-table-expand-icon-th {
    display: none;
  }
  .ant-table-thead > tr > th {
    padding: 0px 5px;
  }
  .ant-table-thead > tr > th .ant-table-header-column {
    vertical-align: middle;
  }
  .ant-table-thead > tr > th:nth-child(2) > .anticon-filter {
    //position: relative;
    //top:-6px;
    left:33%;
    margin-left: 5px;
    width:10px;
  }
  .ant-table-thead > tr > th:nth-child(3) > .anticon-filter {
    left: 42%;
    margin-left: 5px;
    width:10px;
  }
  .ant-table-thead > tr > th:nth-child(5) > .anticon-filter {
    left: 85%;
    margin-left: 5px;
    width:10px;
  }
  .ant-table-thead > tr > th:nth-child(6) > .anticon-filter {
    left: 40%;
    margin-left: 5px;
    width:10px;
  }
  .ant-table-thead > tr > th:nth-child(7) > .anticon-filter {
    position: relative;
    top:-8px;
    right: 0px;
  }
  .ant-table-column-title  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #382e34;
  }
  .ant-table-tbody > tr > td {
    padding: 0px 5px;
    background-color: #FFFFFF;
  }
  .ant-table-row {
    td:last-child {
      text-align: center;
      font-size: 24px;
      .expand-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .anticon {
          color: #a9b0b5;
          opacity: 0.4;
        }
      }
    }

    td > .text-container--start {
    }
    td {
      //height: 61px;
    }
  }
  .ant-table-expanded-row {
    background: white;
    td:first-child {
      display: none;
    }

    td {
      padding: 0;
      &:hover {
        background: white;
      }
    }
  }

  .ant-table-thead {
    border-radius: 3px;
    box-shadow: none;
    background-color: rgb(230, 230, 230);
    height: 30px;
    .ant-table-column-title {
      .icon-v10-arrow {
        &::before {
          font-weight: bold;
          font-size: 18px;
        }
      }
      .black-title {
        color: #000;
      }
    }
    th {
      background: transparent;
      font-size: 16px;
      font-weight: bold;
      color: #a9b0b5;
      text-align: start !important;
    }
  }

  .text-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    //text-transform: capitalize;
    .sar-completed {
      .icon-v2-finished:before {
        opacity: 0.4;
      }
    }
    .step-icon {
      display: flex;
      justify-content: flex-start;
      position: relative;
      .icon-step {
        font-size: 28px;
        display: flex;
        align-items: center;

        .name-step {
          font-size: 14px;
        }
      }
      .exclamation-mark {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        color: #fafafa;
        background-color: #DD448E;
        border-radius: 16px;
      }
    }
    .time-span-color {
      color: #C70039;
    }
    .time-span-color-yellow {
      color: #FFc300;
    }
    &--center {
      justify-content: flex-start;
      .tiketId {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #eda41e;
      }
      .ticketName {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        margin-left: 5px;
      }
      .ticket-id-name-container {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 241px;
        text-align: start;
      }
      .ticket-id {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #eda41e;
      }
      .ticket-name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        margin-left: 5px;
      }
      .text-container-data {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
      .text-container-data.ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 205px;
        line-height: 16px;
      }
      .estimation-of-workflow {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
      .icon-v14-arrow-bold {
        transform: rotate(270deg);
        font-size: 18px;
        padding-right: 3px;
        padding-left: 10px;
      }
      .box-icon-empty {
        img {
          opacity: 0.3;
        }
      }
      .box-icon {
        img {
          position: relative;
          top: 5px;
        }
      }
    }
    &--center-update {
      padding-left: 6px;
      .ant-select-selection--single {
        width: 105px;
        height:20px;
        border: 1px solid #382e34;
        border-radius: 0;
        //margin-bottom: 3px;
        &:hover{
          border: 1px solid var(--generalButton);
        }
        .ant-select-selection__rendered {
          position: relative !important;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #382e34;
        }
      }
    }
    &--transform {
      text-transform: lowercase;
    }
    &--icons {
      font-size: 22px;
      justify-content: flex-start;
    }
    &--days {
      font-weight: bold;
      .process-time-yellow {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        background-color: #FFB803;
        padding: 5px 10px 5px 5px;
        width: 105px;
      }
      .process-time-orange {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        background-color: #F74006;
        padding: 5px 10px 5px 5px;
        width: 105px;
      }

      .process-time-black {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #ffffff;
        background-color: #000000;
        padding: 5px 10px 5px 5px;
        width: 105px;
      }
      .process-time {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        background-color: #FFFFFF;
        padding: 5px 10px 5px 5px;
        width: 105px;
      }
      .process-time-days-black {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
      }
      .process-time-disclosed {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        background-color: #FFFFFF;
        padding: 5px 10px 5px 5px;
        width: 105px;
      }
      .process-time-days {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
      }
    }
    &--start {
      justify-content: flex-start;
    }
    .icon-blue {
      color: #00D3D8;
      font-size: 22px;
      cursor: pointer;
    }
    .icon-grey {
      color: #aaaaaa;
      font-size: 22px;
    }
  }
}
.ant-select-dropdown.select-status {
  width: 80px !important;
  //top: 540px !important;
  ul {
    padding: 0;
    li {
      padding: 5px 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
    }
  }
}
.ant-select .ant-select-selection .ant-select-arrow {
  margin-right: 0;
  //color: #382e34;
  right: 7px;
}
.ellipsis-v2 {
  writing-mode: horizontal-tb;
  font-size: 24px;
  color: #6e7882;
  margin-right: 15px;
  padding-right: 10px;
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.ellipsis-v3 {
  writing-mode: horizontal-tb;
  font-size: 24px;
  color: #6e7882;
  display: none;
  width: 20px;
}
.parent-delete-sar {
  width: 20px;
  padding: 0px 0px !important;
  //display: inline-block;
  .actionsDotsCell button {
    font-size: 0px;
    line-height: 0px;
    margin-top: 5px;
    margin-left: -80px;
  }
}
  .dots-menu-sar {
      .dropdown-menu {
      width: 120px;
      border: 1px solid #000000;
        margin: 0;
        border-radius: 0;
    }
    .btn-primary.focus, .btn-primary:focus {
      box-shadow: 0 0 0 0.2rem transparent;
    }
  }
.sar-dashboard-filter-menu.ant-dropdown-menu {
  margin: 0;
  padding: 0;
  width: 240px;
  .ant-dropdown-menu-item {
    border: 1px solid #a9b0b5;
    border-bottom: none;
    font-family: Roboto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 9px 8px;
    //width: 250px;
    &:hover {
      background-color: #00D3D8;
      color: #ffffff;
    }
    &:last-child {
      border-bottom: 2px solid #a9b0b5;
    }
    .date-picker {
      border: 1px solid #a9b0b5;
      border-radius: 5px;
      width: 44%;
      padding: 0 3px;
      cursor: pointer;
      &:focus-within {
        border: 1px solid #00D3D8;
        border-radius: 5px;
      }
      .ant-calendar-picker-input {
        border-bottom: none;
        &::placeholder {
          color: #cccccc;
        }
      }
      .ant-calendar-picker-clear {
        margin-right: 6px;
      }
      .icon-calendar-b {
        font-size: 10px;
        top: 18px;
      }
    }
    .date-picker-to {
      margin-left: 5px;
      margin-right: 5px;
      color: #6E7882;
      &:hover {
        color: #6E7882;
      }
    }
    &-selected {
      background-color: #3DD8DB;
    }
  }
  .ant-dropdown-menu-submenu {
    border: 1px solid #a9b0b5;
    font-family: Roboto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    &:hover {
      background-color: #00D3D8 !important;
      color: #ffffff;
    }
    &-title {
      margin: 5px 0;
      color: rgba(0, 0, 0, 0.65);
      &:hover {
        background-color: #00D3D8 !important;
        color: #ffffff;
      }
    }
  }
  .date-picker-input-wrapper{
    padding: 5px 5px;
    &:hover {
      background-color: #ffffff;
    }
  }
  .search-input-wrapper{
    &:hover {
      background-color: #ffffff;
    }
  }
}

.date-picker-selector {
  padding-top: 43px !important;
  .ant-calendar-input-wrap {
    display: none;
  }
}

.steps-filters-popup-position {
  margin-left: 1181px;
}
.column-tickets {
  .ant-dropdown-menu-item {
    color: #6e7882 !important;
  }
  .ant-table-filter-dropdown .ant-dropdown-menu-item {
    overflow: hidden !important;
  }
}
.content-header-column {
  display: flex;
  align-items: center;

  .column-tickets {
    .content-header-column {
      display: flex;
      align-items: center;
      .content-buttons-sorter {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 20px;
        line-height: 12px;
        .icon-v16-arrow-bold:before {
          line-height: 9px;
        }
        .active-sort {
          color: #EDA41E;
        }
        .rotate-arrow {
          -webkit-transform: rotate(180deg);
          display: flex;
        }
      }
    }
  }
  .content-buttons-sorter {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 15px;
    line-height: 0px;

    .icon-v16-arrow-bold:before {
      line-height: 9px;
    }

    .active-sort {
      color: #EDA41E;
    }

    .rotate-arrow {
      -webkit-transform: rotate(180deg);
      display: flex;
    }
  }
}

@media screen and (max-width: 1900px) and (min-width: 1800px) {
  .steps-filters-popup-position {
    margin-left: 1133px;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1700px) {
  .steps-filters-popup-position {
    margin-left: 1068px;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .steps-filters-popup-position {
    margin-left: 991px;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1500px) {
  .steps-filters-popup-position {
    margin-left: 941px;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .steps-filters-popup-position {
    margin-left: 923px;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .steps-filters-popup-position {
    margin-left: 758px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .steps-filters-popup-position {
    margin-left: 627px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 800px) {
  .steps-filters-popup-position {
    margin-left: 539px;
  }
}
@media screen and (min-width: 1900px) {
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(2) > .anticon-filter {
    left: 25%;
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(3) > .anticon-filter {
    left: 32%;
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(5) > .anticon-filter {
    left: 53%;
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(6) > .anticon-filter {
    left: 27%;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .sar-dashboard-table.sar-dashboard-table-new .text-container--center .ticket-id-name-container {
    width: 194px;
  }
  .sar-dashboard-table.sar-dashboard-table-new .text-container--center .text-container-data.ellipsis {
    width: 196px;
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(5) > .anticon-filter {
    left: 82%;
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(6) > .anticon-filter {
    left: 38%;
  }
}
@media screen and (max-width: 1440px) and (min-height: 899px) {
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(5) > .anticon-filter {
        left: 82%;
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(6) > .anticon-filter {
    left: 38%;
  }
}
@media screen and (max-width: 1280px) and (min-height: 720px) {
  .sar-dashboard-table.sar-dashboard-table-new .text-container--center .ticket-id-name-container {
    width: 194px;
  }
  .sar-dashboard-table.sar-dashboard-table-new .text-container--center .text-container-data.ellipsis {
    width: 187px;
  }
  .sar-dashboard-table.sar-dashboard-table-new .text-container--center .estimation-of-workflow {
    width:53px
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(5) > .anticon-filter {
    left: 81%;
  }
  .sar-dashboard-table.sar-dashboard-table-new .ant-table-thead > tr > th:nth-child(6) > .anticon-filter {
    left: 38%;
  }
}


.popup-position {
  /*left: 612px !important;*/
}

.modal-delete-box-archive {
  .ant-modal-content {
    box-shadow: 5px 5px 10px -5px rgba(21, 0, 16, 0.3) !important;
    width: 450px;
    border-radius: 0px;
    .ant-modal-body {
      padding: 30px;
      .content-delete-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        .icon-v19-delete {
          font-size: 40px;
          color: #382e34;
        }
      }
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 10px;

          .ant-modal-confirm-title {
            font-weight: 700;
            font-size: 14px;
            color: #382e34;
            line-height: 20px;
          }

          .ant-modal-confirm-content {
            margin-left: 0;
            margin-top: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #6e7882;
          }
        }
        .ant-modal-confirm-btns {
          margin-top: 40px !important;
          .cancel-button {
            background-color: #FFFFFF;
            color: #6e7882;
            width: 100px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: ROBOTO;
            font-weight: 400;
            border-radius: 25px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            span {
              padding: 0px;
            }
          }
          .cancel-button:hover {
            color: var(--generalButtonHover);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
          }
          .cancel-button:active {
            color: var(--generalButtonActive);
            background: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
          }
          .confirm-button {
            background-color: var(--generalButton);
            color:var(--generalButtonFont) ;
            width: 200px;
            border: 1px solid #6e7882;
            box-shadow: none;
            cursor: pointer;
            font-family: ROBOTO;
            font-weight: 400;
            border-radius: 25px;
            font-size: 16px;
            text-transform: none;
            transition: none;
            height: 25px;
            span {
              padding: 0px;
            }
          }
          .confirm-button:hover {
            color: #FFFFFF;
            border: 1px solid var(--generalButtonHover);
            background-color: var(--generalButtonHover);
          }
          .confirm-button:active {
            color: #FFFFFF;
            border: 1px solid var(--generalButtonActive);
            background-color: var(--generalButtonActive);
          }
        }
      }
    }
  }
}

.select-status-dashboard-option {
  border: 1px solid var(--generalButton) !important;
}
