.additional-information-page {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  &__header{
    display: flex;
    flex-direction: column;
    .name-additional {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
    }
    .details-additional {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #382e34;
      margin-top: 30px;
    }
  }
  &__content {
    margin-top: 30px;
    .notes-form {
      .header-notes {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #382e34;
        margin-bottom: 5px;
        margin-top: 5px;
        display: flex;
      }
      .input-content {

        .input-form-name {
          border: 1px solid #D4D7DA;
          color: #6e7882;
          background-color: #ffffff40;
          padding: 0 10px;
          font-size: 16px;
          font-weight: 400;
          height: 30px;
          border-radius: 0px;
          cursor: default;

          &:hover {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }

          &:focus {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }

          &:active {
            border: 1px solid #D4D7DA;
            box-shadow: none;
          }
        }
      }
    }

  }
}