.filePreview__loading-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.filePreview__header {
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 2px solid #D6D3D3;
  display: flex;
  height: 69px;
  justify-content: space-between;
  padding-left: 20px;
  position: fixed;
  width: 100%;
}

.filePreview__header h2 {
  color: #6E7882;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.filePreview__header span {
  color: #111C26;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.filePreview__header div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filePreview__header-connectorName {
  padding-right: 25px;
}

.filePreview__header-location {
  background: url(../../../assets/icon-error.png) no-repeat 10px center;
  flex: 1;
  height: 70%;
  padding-left: 40px;
  border-left: 1px solid #DCDCDC;
}

.filePreview__header-lastModified {
  border-right: 1px solid #DCDCDC;
  margin-right: 95px;
  padding: 0 15px;
  height: 100%;
}

.filePreview__body {
  height: calc(100% - 69px);
  margin-top: 69px;
  overflow-x: hidden;
  padding: 20px;
}