.settings-page {
  margin: 120px 20px 20px;
  font-family: Roboto;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  &__action-container {
    background-color: #FFFFFF;
    position: relative;
    margin-top: 40px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;
      border-bottom: 1px solid rgba(110, 120, 130, 0.3);

      .title {
        margin-left: 16px;
        margin-top: 8px;
        cursor: pointer;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 300;
        text-align: center;


        .add-tab {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 50px 25px 0;
          cursor: pointer;
          position: relative;

          .icon-add-b {
            font-size: 32px;
            padding-bottom: 15px;
          }

          .custom-caret {
            border: solid rgba(110, 120, 130, 0.3);
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 8px;
            position: absolute;
            bottom: -8.4px;
            background: white;
          }

          .up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
          }
        }

        .import-export {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 0 10px 50px;
          cursor: pointer;

          .icon-download-b {
            font-size: 32px;
            padding-bottom: 15px;
          }
        }
      }
    }

    &__content {
      padding: 31px;

      .add-library {
        &__input-section {
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;

          input {
            width: 70%;
            border: none;
            background: white;
            border-radius: 0;
            border-bottom: 1px solid #00d3d8;

            &:focus {
              outline: none;
            }
          }
        }

        .options-container {
          width: 100%;
          max-width: 100%;
          padding-top: 25px;
          min-height: 98px;
          margin-bottom: 10px;

          .circle-fill {
            position: relative;
          }

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 300;
            box-shadow: none !important;
            padding: 0 !important;
            padding-bottom: 10px !important;

            &__option-text {
              padding-left: 0;
              width: 85%;
            }

            button[class^="icon-"] {
              margin-top: -3px;
              width: 15px;
              height: 15px;
              border-radius: 50%;
            }
          }
        }

        .options-input {
          margin-bottom: 20px;

          input {
            width: 90%;
          }
        }

        .select-caret {
          font-size: 10px;
          margin-top: -4px;
          color: #00d3d8;
        }

        .columns-section {
          &__header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 45px 0;

            .text {
              font-size: 16px;
              font-weight: bold;
              color: #00d3d8;
              margin-right: 24px;
            }

            .actions {
              font-size: 31px;
              color: #00d3d8;

              button {
                padding: 0 7.5px;
              }
            }
          }

          &__content {
            .columns-container {
              display: flex;
              justify-content: space-around;

              .column {
                width: auto;
                max-width: 35%;
                min-width: 16.75%;
                background: transparent !important;
                outline: none;
                padding: 5px !important;

                &__header {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  border-radius: 3px;
                  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
                  background-color: rgb(230, 230, 230);
                  padding: 7px 20px;
                  position: relative;
                  z-index: 2;

                  &:after {
                    top: 100%;
                    left: 50%;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border: 15px solid rgba(136, 183, 213, 0);
                    border-top-color: #e6e6e6;
                    margin-left: -15px;
                  }

                  .icon-dragpad {
                    display: block;
                    font-size: 12px;
                    height: 15px;
                    width: 27px;
                    color: #6e7882;
                    opacity: 0.6;
                  }

                  .fields-container {
                    padding-bottom: 12px;
                    z-index: 4;

                    &__head__input {
                      width: 89%;
                    }

                    &__popup {
                      border: 1px solid #00d3d8;
                      box-shadow: none;
                    }
                  }
                }

                &__content {
                  min-height: 226px;
                  border-radius: 3px;
                  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  position: relative;
                  padding: 0 20px;
                  background: white;

                  .header-caret {
                    position: absolute;
                    font-size: 40px;
                    top: -16px;
                    color: #e6e6e6;
                    z-index: 3;
                  }

                  .custom-select {
                    width: 100%;
                    margin-top: 36px;
                    font-size: 14px;
                    font-weight: 300;
                  }

                  .ant-select-selection__rendered {
                    margin-left: 0;
                  }

                  .ant-select-arrow {
                    right: 1%;
                  }
                }
              }
            }

          }
        }

        &__history-update-section {
          .content {
            outline: none;

            .item {
              border-radius: 3px;
              box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
              padding: 20px 17px;

              .item-container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .name {
                  width: 20%;
                  font-size: 16px;
                  font-weight: bold;
                }

                .custom-select-container {
                  width: 40%;
                  display: flex;
                  flex-direction: column;

                  .custom-select {
                    width: 100%;
                    border-color: rgba(151, 151, 151, 0.5);

                    .ant-select-arrow {
                      display: none;
                    }

                    .ant-select-selection__rendered {
                      margin-left: 0;
                    }
                  }

                  .select-caret {
                    color: rgba(151, 151, 151, 0.5);
                  }
                }

                .icon-dragpad {
                  transform: rotate(90deg);
                  display: block;
                  font-size: 12px;
                  height: 15px;
                  width: 27px;
                  color: #bfbfbf;
                  padding: 0;
                }
              }
            }
          }
        }

        &__fields-section {
          .header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 45px 0;

            .text {
              font-size: 16px;
              font-weight: bold;
              color: #00d3d8;
              margin-right: 24px;
            }

            .actions {
              font-size: 31px;
              color: #00d3d8;

              button {
                padding: 0 7.5px;
              }
            }
          }

          .content {
            outline: none;

            .item {
              border-radius: 3px;
              box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
              padding: 20px 17px;

              .item-container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__input {
                  border: none;
                  background: white;
                  border-radius: 0;
                  border-bottom: 1px solid #00d3d8;
                  width: 40%;

                  &:focus {
                    outline: none;
                  }
                }

                .custom-select-container {
                  width: 40%;
                  display: flex;
                  flex-direction: column;

                  .custom-select {
                    width: 100%;
                    height: 29px !important;
                  }
                }

                .icon-dragpad {
                  transform: rotate(90deg);
                  display: block;
                  font-size: 12px;
                  height: 15px;
                  width: 27px;
                  color: #bfbfbf;
                  padding: 0;
                }
              }
            }
          }
        }

        &__buttons-section {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 40px;

          button {
            margin: 10px;
            width: 110px;
            height: 55px;
            padding: 0;
            border-radius: 30px;
            font-size: 20px;
            font-weight: 400;
          }

          .cancel-button {
            background-color: #FFFFFF;
            color: #3DD8DB;

            &:hover {
              background-color: #3DD8DB;
              color: white;
            }
          }
        }
      }
    }
  }

  .library-card {
    &__name {
      padding: 30px 0 10px 0;
      font-size: 12px;
      font-weight: 300;
    }

    &__header {
      border-radius: 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      background-color: rgb(230, 230, 230);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      padding: 0 20px;
      color: #6e7882;

      &__columns {
        display: flex;
        justify-content: space-between;
        width: 90%;
        padding: 15px 0;
      }

      &__actions {
        display: flex;
        justify-content: center;
        width: 5%;
        color: #000000;
        font-size: 18px;
        cursor: pointer;

        span {
          padding: 15px 0;
          margin: 0 7px;
        }
      }
    }

    &__content {
      background-color: #FFFFFF;
      position: relative;
      border-radius: 0 0 3px 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      font-size: 16px;
      font-weight: bold;
      color: #6e7882;

      &__field {
        border-bottom: solid 1px rgba(151, 151, 151, 0.5);

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 25px 37px;
          margin-bottom: 13px;

          :last-child {
            font-weight: 300;
          }

          &__name {
            font-weight: bold !important;
          }
        }

        &__content {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 37px 49px 37px;
        }
      }
    }
  }

  .ant-skeleton {
    margin-top: 4vh;
    margin-bottom: 10vh;
  }

  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title, .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: linear-gradient(90deg, #a8d7d8ad 25%, #cef6f7 37%, #a8d7d8ad 63%);
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
}

.ant-select-dropdown {
  border: 1px solid #00d3d8;
  border-radius: 0 !important;
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1440px) {
  .custom-radio-buttons {
    .ant-radio-wrapper {
      font-size: 0.7em;

      .ant-radio {
      }
    }
  }

  .settings-page {
    &__action-container{
      font-size: 0.8em;
      &__header {
        .title {
          font-size: 1em;
        }
        .actions {
          font-size: 1em;
        }
      }
    }

    .library-card {
     &__header {
       font-size: 0.8em;
       &__actions {
         font-size: 1em;
       }
     }

      &__content {
        &__field__header {
          font-size: 0.8em;
          padding: 20px 20px;
        }
        &__field__content {
          padding: 0 20px 49px 20px;
          .history-table-view {
            font-size: 0.7em;
          }
          .radio-view__item {
            font-size: 0.7em;
          }

          .text-component__input {
            font-size: 0.7em;
          }

          .check-box-view__item {
            font-size: 0.7em;
          }
        }
      }
    }

    &__action-container__content .add-library {
      &__input-section {
        font-size: 1em;
      }
      &__fields-section {
        .header {
          padding: 25px 0;
          .text {
            font-size: 1.1em;
            margin-right: 15px;
          }

          .actions {
            font-size: 2em;
          }
        }

        .custom-select {
          font-size: 0.8em;
        }
      }

      &__buttons-section {
        button {
          font-size: 1.2em;
          width: 90px;
          height: 45px;
        }
      }
      &__history-update-section {
        .item {
          .item-container {
            .name {
              font-size: 1em !important;
            }
          }

          .custom-select {
            font-size: 0.8em;
          }
        }
      }
      .columns-section {
        &__header {
          padding: 25px 0;
          .text {
            font-size: 1.1em;
            margin-right: 15px;
          }

          .actions {
            font-size: 2em;
          }
        }
        &__content .columns-container {
          .column__header .fields-container__head__input {
            font-size: 0.8em;
            width: 86%;
          }

          .column__content {
            min-height: 190px;
            .ant-select-selection__rendered {
              margin-right: 11px;
            }
            .custom-select {
              font-size: 0.8em;
            }
          }
        }
      }

      .options-input {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 0.8em;
        margin-bottom: 5px;
        .add {
          width: 12px;
          height: 12px;
          margin-bottom: 2px;
        }
      }

      .options-container {
        padding-top: 10px;
        min-height: 70px;
        .item__option-text {
          font-size: 0.8em;
        }

        .item {
          padding-bottom: 5px !important;
        }

        .minus {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .ant-select-dropdown-menu-item {
    font-size: 11px;
    overflow: visible !important;
    white-space: inherit !important;
    line-height: 18px !important;
  }

}