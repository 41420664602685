.connectorItem-wrapper {
  align-items: center;
  background-color: #FFFFFF;
  color: #737E89;
  border: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 140px;
  justify-content: center;
}