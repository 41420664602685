.warning-report-page {
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  width: 100%;
  background: #FFFFFF;
  margin-top: 34px !important;
  padding: 30px 40px 0;
 &__content {
   min-height: 445px;
  .header-warning-page {
   display: flex;
   color: #382e34;
   align-items: center;
   margin-bottom: 40px;
   .title-header-warning-report {
     display: flex;
     align-items: center;
    .title-page {
     font-weight: 700;
     font-size: 20px;
     letter-spacing: 0.2px;
     line-height: 20px;
     vertical-align: middle;
     margin-right: 10px;
    }
     .help-message {
       font-family: Roboto;
       font-style: normal;
       font-weight: 400;
       line-height: 20px;
       font-size: 14px;
       color: #D4D7DA;;
       vertical-align: middle;
       margin-right: 5px;
       margin-top: 2px;
     }
     .learn-more-link {
       font-family: Roboto;
       font-style: normal;
       font-weight: 400;
       line-height: 20px;
       font-size: 14px;
       color: #eda41e;
       margin-top: 2px;
       cursor: pointer;
     }
   }
  }
   .content-warning-report {
     .warning-report-pagination {
       display: flex;
       justify-content: space-between;
       align-items: center;
       border-bottom: 1px solid #382e34;
       padding-bottom: 10px;
       .per-page-warning-disabled {
         color: #D4D7DA !important;
         .ant-select-disabled .ant-select-selection {
           background: #FFFFFF;
           cursor: default;
         }
       }
       .per-page-warning-disabled:hover {
         border: 1px solid transparent !important;
         .ant-select-selection {
           color: #D4D7DA !important;
           height: 27px;
         }
       }
       .per-page-warning {
         border: 1px solid transparent;
         height: 30px;
         font-family: Roboto;
         font-size: 14px;
         color: #382e34;
         font-weight: 400;
         //padding-left: 3px;
         display: flex;
         align-content: center;
         letter-spacing: -0.2px;
         width: 88px;
         //padding: 5px;
         flex-direction: row;
         align-items: center;
         .ant-select-selection__rendered {
           line-height: 20px;
         }
         .ant-select {
           width: 88px;
         }
         .ant-select-selection--single .ant-select-selection__rendered {
           margin-right: 20px;
         }
         .icon-v19-arrow-medium {
           font-size: 20px;
           color: #382e34;
           position: absolute;
           left: 60px;
         }
         .icon-v19-arrow-medium:before {
           margin-right: 0px;
           margin-left: 0px;
         }
         .ant-select-arrow .ant-select-arrow-icon {
           display: none !important;
         }
         .ant-select-selection {
           border: none;
         }
         .ant-select-selection--single {
           border: none;
           height: 20px;
         }
         .ant-select .ant-select-selection .ant-select-arrow {
           margin-right: 0;
           top: 57%;
           right: 7px;
         }
         .ant-select-selection {
           background-color: transparent;
         }
         &:hover {
           border: 1px solid #EDA41E;
           padding: 5px 0px;
           color: #EDA41E;
           border-radius: 2px;
           height: 20px;
           .ant-select-selection {
             color: #EDA41E !important;
             height: 20px;
           }
           .icon-v19-arrow-medium {
             color: #EDA41E !important;
           }
           .ant-select-arrow {
             color: #EDA41E !important;
           }
         }
       }
       .per-page-warning.per-page-warning-disabled {
         .icon-v19-arrow-medium {
           color:#d4d7da;
         }
         &:hover {
           border: 1px solid transparent;
           color: #d4d7da;
           cursor: default;

           .ant-select-selection {
             color: #d4d7da !important;
             cursor: default !important;
           }
           .icon-v19-arrow-medium {
             color:#d4d7da !important;
           }
         }
       }
       .warnings-found-content {
         display: flex;
         align-items: center;
         cursor: default;
         .count-warnings {
           margin-right: 10px;
           display: flex;
           align-items: center;
           .count-warning {
             font-size: 14px;
             color: #382e34;
             font-weight: 700;
             letter-spacing: 0.2px;
             padding-right: 5px;
             alignment: left;
             vertical-align: middle;
           }
           .error-found {
             font-size: 14px;
             color: #382e34;
             font-weight: 400;
             letter-spacing: 0.2px;
             alignment: left;
             vertical-align: middle;
             line-height: 20px;
           }
         }
         .deleted-error {
           display: flex;
           align-items: center;
           .count-error {
             font-size: 14px;
             color: #D4D7DA;
             font-weight: 700;
             letter-spacing: 0.2px;
             padding-right: 5px;
             alignment: left;
             vertical-align: middle;
           }
           .error-found {
             font-size: 14px;
             color: #D4D7DA;
             font-weight: 400;
             letter-spacing: 0.2px;
             alignment: left;
             vertical-align: middle;
             line-height: 20px;
           }
         }
       }
       .buttons-control-pagination {
         float: left;
         display: flex;
         align-items: center;
         font-family: Roboto;
         font-size: 14px;
         color: #382e34;
         font-weight: 400;
         letter-spacing: 0.2px;
         line-height: 20px;
         &__text {
           margin-left: 10px;
           margin-right: 10px;
         }
         .disabled-text {
           color: #D4D7DA;
         }
         .insert-page {
           text-align: center;
           width: 42px;
           padding: 7px 2px;
           margin-right: 5px;
           font-weight: 400;
           height: 20px;
           border: 1px solid #382e34;
           font-size: 14px;
           letter-spacing: 0.2px;
           line-height: 20px;
           border-radius: 2px;
           background-color: #FFFFFF;
           color: #382e34;
           &:focus {
             border-color: #EDA41E;
             outline: none;
           }
         }
         .insert-page:focus-visible {
           border: 1px solid #EDA41E;
         }
         .insert-page:focus {
           border: 1px solid #EDA41E;
         }
         .insert-page:hover {
           border: 1px solid #EDA41E;
         }
         .icon-v14-start-1 {
           font-size: 20px;
         }
         .icon-v14-start-active {
           font-size: 20px;
           color: #EDA41E;
           cursor: pointer;
         }
         .icon-v14-end-active {
           font-size: 20px;
           color: #EDA41E;
           cursor: pointer;
         }
         .icon-v21-back-1 {
           font-size: 20px;
           color: #382e34;
         }
         .icon-v21-forth-1 {
           font-size: 20px;
           color: #382e34;
         }
         .back-active {
           color: #EDA41E;
         }
         .disabled {
           color: #D4D7DA !important;
         }
         .forth-1-active {
           color: #EDA41E;
         }
         .icon-v21-back-active {
           color: #EDA41E;
           font-size: 20px;
           cursor: pointer;
         }
         .icon-v21-forth-active {
           color: #EDA41E;
           font-size: 20px;
           cursor: pointer;
         }
         .back-active {
           font-size: 20px;
           //color: #FFFFFF;
         }
         .forth-1-active {
           font-size: 20px;
           //color: #FFFFFF;
         }
         .icon-v14-back-1 {
           font-size: 20px;
         }
         .icon-v14-forth-1 {
           font-size: 20px;
         }
         .disabled-pagination {
           cursor: not-allowed;
           font-weight: 400;
           color: #D4D7DA;
         }
         .icon-v14-end-1 {
           font-size: 20px;
         }
       }
       .content-delete-refresh {
         display: flex;
         align-items: center;
         .ant-tabs {
           color: #382e34;
         }
         .icon-v14-delete {
           font-size: 20px;
           margin-right: 10px;
           color: #382e34;
         }
         .icon-v14-delete-active {
           font-size: 20px;
           margin-right: 10px;
           color: var(--generalIconFontHover);
           cursor: pointer;
         }
         .icon-v211-label-dropdown {
           font-size: 20px;
           margin-right: 20px;
           color: #382e34;
         }
         .icon-v211-label-dropdown-active {
           font-size: 20px;
           margin-right: 20px;
           color: var(--generalIconFontHover);
           cursor: pointer;
         }
         .icon-v211-label-dropdown-disabled {
           font-size: 20px;
           color: #D9D9D9 !important;
           pointer-events: none;
         }
         .disabled-delete {
           color: #D4D7DA;
           cursor: default;
         }
         .icon-v14-refresh-1 {
           font-size: 20px;
           color: #382e34;
         }
         .icon-v21-download-1 {
           font-size: 20px;
           color: #382e34;
           margin-left: 10px;
         }
         .icon-v21-download-active-1 {
           font-size: 20px;
           color: var(--generalIconFontHover);
           margin-left: 10px;
         }
         .disabled-download {
           color: #D4D7DA;
           cursor: default;
         }
       }
       .input-search-warning {
         .ant-input {
           border: 1px solid #382e34;
           font-size: 14px;
           letter-spacing: 0.2px;
           width: 200px;
           height: 30px;
           padding: 10px 7px 10px 7px;
           font-family: Roboto;
           font-weight: 400;
           color: #150010;
           alignment: left !important;
           vertical-align: middle;
           border-radius: 0px;
         }
         .ant-input-affix-wrapper .ant-input-suffix {
           right: 10px;
         }
         .ant-input-search-icon {
           font-size: 20px;
           border-left: 1px solid #382e34;
           padding-left: 7px;
           color: #382e34;
         }
         .ant-input-clear-icon {
           color: #382e34;
           font-size: 16px;
           padding-right: 7px;
         }
         .ant-input:placeholder-shown {
           font-family: Roboto;
           font-weight: 400;
           color: #a6acb2;
           letter-spacing: 0.2px;
           alignment: left;
           vertical-align: middle;
         }
         .search-warning {
           display: flex;
           justify-content: flex-end;
           .ant-input {
             font-weight: 400;
             line-height: 20px;
             letter-spacing: 0.2px;
           }
           .ant-input:placeholder-shown {
             //width: 150px;
             height: 30px;
             //padding: 10px 5px 5px 5px;
             font-size: 14px;
             color: #a6acb2;
           }
           .ant-input:hover {
             border: 1px solid #EDA41E !important;
           }
           .ant-input:focus {
             border: 1px solid #EDA41E !important;
           }
         }
         input[type=text]:disabled {
           color: #D4D7DA !important;
           background: #FFFFFF;
           border: 1px solid #D4D7DA;
         }
         input[type=text]:disabled:hover {
           color: #D4D7DA !important;
           background: #FFFFFF;
           border: 1px solid #D4D7DA !important;
         }
         .ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
           font-size: 20px;
           border-left: none;
           padding-left: 5px;
           color: #D4D7DA !important;
         }
         .ant-input-search-icon {
           font-size: 20px;
           border-left: 1px solid #382e34;
           padding-left: 5px;
           color: #150010 !important;
         }
         .ant-input:focus {
           box-shadow: none;
         }
         .ant-input-clear-icon + i {
           margin-left: 0px;
         }
       }
     }

     .table-warning-report-content {
       margin-top: 25px;
       margin-bottom: 25px;
       .table-error-files-sorter {
         .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up {
           color: #EDA41E;
         }
       }
       .table-error-files-disabled {
         opacity: 0.4;
       }
       .table-error-files {
         .row-deleted {
           .text-error-report {
             color: #DAD7DA !important;
           }
           .text-error-report-location {
             cursor: pointer;
           }
           .text-error-report.icon-v16-arrow-medium.rotate-arrow-medium {
             display: none;
           }
           .ant-checkbox-inner {
             opacity: 0.3;
           }
           .ant-checkbox-wrapper .ant-checkbox-inner {
             display: none;
           }
         }
         .text-error-report-location {
           cursor: pointer;
         }
         .text-error-report-location-active {
           cursor: pointer;
           color: #EDA41E !important;
         }
         .icon-color {
           width: 12px;
           height: 12px;
           border-radius: 50%;
           margin-left: 4px;
           margin-right: 4px;
           display: flex;
         }
         .text-tag-report {
           //display: flex;
           width: 200px;
           align-items: center;
           .icon-color {
             width: 12px;
             height: 12px;
             border-radius: 50%;
             margin-left: 4px;
             margin-right: 4px;
             display: inline-flex;
             align-items: center;
           }
         }
         .text-warning-report {
           color: #DAD7DA;
           //margin-bottom: 10px;
           //display: grid;
           .title-warning {
             display: flex;
           }
         .row-deleted {
             .text-error-report {
               color: #DAD7DA;
             }
             .text-error-report-location {
               cursor: pointer;
             }
             .text-error-report.icon-v16-arrow-medium.rotate-arrow-medium {
               display: none;
             }
           }
           .ant-checkbox-wrapper .ant-checkbox-inner {
             display: none;
           }
         }
         .ant-table-tbody > tr > td.ant-table-selection-column {
           text-align: left;
           padding-left: 5px;
           //height: 80px;
           //align-content: center;
           display: grid;
           vertical-align: middle;
         }
         .ant-table-tbody > tr > td {
           border-bottom: none;
           padding-right: 10px !important;
         }
         .ant-checkbox-checked:hover .ant-checkbox-inner {
           background-color: var( --generalIconFontActive) !important;
           border: 1px solid var(--generalIconFontHover);
         }
         .ant-table-thead > tr > th.ant-table-selection-column {
           text-align: left;
           padding-left: 5px;
         }
         .ant-table-row:hover {
           background: #e6f7ff;
           background: rgba(154, 224, 252, 0.3137254902);
           cursor: pointer;
         }
         .ant-table-thead > tr > th {
           background: #FFFFFF;
           border-bottom: none;
         }
         .ant-checkbox-wrapper .ant-checkbox-inner {
           border-radius: 3% !important;
           border: 1px solid #6e7882;
           &:hover {
             border: 1px solid var(--generalIconFontHover);
           }
         }
         .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
           padding: 0px;
           height: 30px;
         }
         .ant-checkbox-inner:after {
           border-color: #ffffff !important;
         }
         .ant-checkbox-checked .ant-checkbox-inner {
           background-color: var( --generalIconFontActive) !important;
         }
         .ant-checkbox-checked .ant-checkbox-inner::after {
           position: absolute !important;
           display: table;
           color: #FFFFFF !important;
           border: none !important;
           border-top: 0;
           border-left: 0;
           background-color: #EDA41E;
           -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
           -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
           transform: rotate(0deg) scale(1) translate(-50%, -50%);
           opacity: 1;
           -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
           transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
           content: '\e8ce' !important;
           top: 7px !important;
           left: 7px !important;
           font-size: 12px !important;
           font-family: 'smartbox_icons_v19' !important;
         }
         .ant-checkbox-indeterminate .ant-checkbox-inner::after {
           position: absolute !important;
           display: table;
           color: #6e7882 !important;
           border: none !important;
           border-top: 0;
           border-left: 0;
           background-color: #FFFFFF;
           -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
           -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
           transform: rotate(0deg) scale(1) translate(-50%, -50%);
           opacity: 1;
           -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
           transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
           content: '\e8ce' !important;
           top: 7px !important;
           left: 7px !important;
           font-size: 12px !important;
           font-family: 'smartbox_icons_v19' !important;
         }
         .ant-checkbox-wrapper {
           vertical-align: sub;
         }
         .column-error-report {
           font-weight: 500;
           color: #382e34;
           alignment: left;
           letter-spacing: 0.2px;
           vertical-align: middle;
           font-size: 14px;
           padding: 0px;
           height: 30px;
           line-height: 20px;
           .text-error-report {
             font-weight: 400;
             color: #6e7882;
             alignment: left;
             letter-spacing: 0.2px;
             vertical-align: middle;
             font-size: 14px;
             line-height: 20px;
           }
           .text-error-report.icon-v16-arrow-medium.rotate-arrow-medium {
             -webkit-transform: rotate(270deg);
             writing-mode: vertical-rl;
             font-size: 20px !important;
           }
           .content-header-column {
             display: flex;
             align-items: center;
             .content-buttons-sorter {
               display: flex;
               flex-direction: column;
               align-items: center;
               height: 20px;
               line-height: 12px;
               .icon-v16-arrow-bold:before {
                 line-height: 9px;
               }
               .active-sort {
                 color: #EDA41E;
               }
               .rotate-arrow {
                 -webkit-transform: rotate(180deg);
                 display: flex;
               }
             }
           }
         }
       }
     }
   }
 }
}
.dropdown-status-warning-report {
  z-index:1 !important;
  border: 1px solid var(--generalIconFontHover);
  width: 200px;
  height: auto;
  background-color: #FFFFFF;
  position: absolute !important;
  .ant-select-open .ant-select-selection {
    border: 1px solid #d9d9d9;
  }
  .ant-select-selection:hover {
    border-color: var(--generalIconFontHover);
  }
  .ant-select-selection:focus {
    border-color: var(--generalIconFontHover);
  }
  .dropdown-status {
    display: flex;
    flex-direction: column;
    padding: 0px 5px 0px 5px;
    .icon-none {
      margin-left: 15px;
    }
    .item-options-status {
      .icon-none {
        margin-left: 15px !important;
      }
    }
    .content-value-status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .anticon {
        margin-right: 2px;
      }
      .icon-v19-arrow-medium {
        font-size: 20px;
        color: #382e34;
      }
      .icon-v19-arrow-medium-rotate {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
      }
    }
    .content-value-status > span:nth-child(2) {
      display: inline-block;
      width: 129px;
    }
    .title-status {
      font-family: Roboto;
      font-size: 14px;
      color: #6E7882;
      margin-bottom: 10px;
      overflow-wrap: break-word;
      font-weight: bold;
    }

    .selected-icon {
      position: absolute;
      top: 30px;
      left: 7px;
      z-index: 1;
    }

    .custom-status-select {
      margin-top: 5px;
      margin-bottom: 5px;
      .ant-select-arrow {
        margin-right: 0px !important;
      }
      .css-1uccc91-singleValue {
        margin-left: 18px;
      }
      .status-circle {
        width: 15px !important;
        height: 15px !important;
        border-radius: 50px;
        float: left;
        margin-right: 10px;
      }
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
      .css-1dimb5e-singleValue {
        margin-left: 15px;
      }
    }
    .button-green:disabled {
      color: #D9D9D9;
      border: 1px solid #D9D9D9 !important;
      background-color: #fff;
      &:hover {
        color: #D9D9D9;
        border: 1px solid #D9D9D9 !important;
        background-color: #fff;
      }
      &:active {
        color: #D9D9D9;
        border: 1px solid #D9D9D9 !important;
        background-color: #fff;
      }
    }
    .button-green {
      color: #637882;
      border: 1px solid #637882;
      background-color: #fff;
      width: 100%;
      margin: 10px 0;
      font-family: ROBOTO;
      font-weight: 400;
      font-size: 16px;
      height: 25px;
      padding: 0px;
      text-transform: capitalize;
      border-radius: 20px;
      &:hover {
        color: var(--generalButtonHover);
        border: 1px solid var(--generalButtonHover);
        background-color: #FFFFFF;
      }
      &:active {
        color: var(--generalButtonActive);
        border: 1px solid var(--generalButtonActive);
        background-color: #FFFFFF;
      }
    }
    .button-green-loading {
      color: #FFC457;
      border: 1px solid #FFC457;
      background-color: #fff;
      width: 100%;
      margin: 10px 0;
      font-family: ROBOTO;
      font-weight: 400;
      font-size: 16px;
      height: 25px;
      padding: 0px;
      text-transform: capitalize;
      border-radius: 20px;
      .anticon-loading{
        margin-right: 15px !important;
      }
    }

  }
}
.icon-v23-warning-1 {
  color: #eda41e;
  display: flex;
  margin-left: 3px;
}
.tooltip-warning-report {
  .text-warning-report-tooltip {
    display: flex;
    flex-direction: column;
    .title-warning {

    }
  }
}
.custom-status-select-options-warning-report {
  z-index: 1 !important;
}
