
.ant-collapse-header {
  .ant-collapse-extra {
    .regular-expression-edit {
      font-size: 16px;
    }
  }
}
.regexp-close-collapse {
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px 0px 25px;
    margin-bottom: 0px !important;
  }
}
.regexp-radio-check-update {
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    .ant-checkbox-disabled {
     color: #382e34;
      opacity: 0.7;
    }
    .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after {
      border-color: #FFFFFF;
    }
    .ant-checkbox-disabled + span {
      color: #382e34;
      cursor: not-allowed;
    }
    .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
      border-color: #6e7882 !important;
      background-color: #EDA41E !important;
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #FFFFFF;
      border-color: #6e7882 !important;
    }
  }
}
.regexp-radio-check {
  .ant-collapse-borderless {
    background-color: #FFFFFF !important;
  }
  .ant-collapse {
    background-color: #FFFFFF;
  }
  .collapse-reg-ex-single {
    display: flex;
    margin-left: 10px;
    .radio-style {
      //margin-top: 5px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px 0px 0px 0px;
    }
    .collapse-content-update {
      .rotate-arrow-medium:before {
        margin-left: 0.55em;
        margin-top: -3px;
      }
      .icon-v16-arrow-medium:before {
        width: 20px;
        margin-left: 5px;
      }
    }
  }
  .icon-v16-arrow-medium {
    font-size: 20px;
    //padding-top: 3px;
  }
  .content-collapse-reg-ex {
    margin-left: 10px;
  }
  .icon-v17-edit {
    color: #352a31;
    cursor: pointer;
    font-size: 20px;
  }
  .icon-v17-edit-active {
    color: #352a31;
    cursor: pointer;
    font-size: 20px;
  }
  .icon-v10-edit-b:before {
    margin-right: 10px;
  }
  .icon-v16-arrow-medium:before {
    margin-top: -5px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: var(--generalIconFontHover) !important;
  }
  .ant-collapse-header {
    padding-left: 10px !important;
  }
  .ant-checkbox-wrapper {
    color: #6e7882;
  }
  .ant-collapse-content {
    color: #6e7882;
  }
  .rotate-arrow-medium {

    -webkit-transform: rotate(180deg);
    writing-mode: vertical-rl;
  }
  .ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    padding: 0px 0px 0px 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    line-height: 20px;
  }
  .font-weight-bold {
    font-weight: 400!important;
  }
  .ant-collapse {
    line-height: 20px;
  }
  .ant-checkbox {
    padding: 7px 0px 7px 0px;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px 0px 20px;
    margin-bottom: 10px;
  }
  .ant-checkbox-inner {
    border: 1px solid #6e7882;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #FFFFFF;
    position: absolute !important;
    display: table;
    color: #FFFFFF !important;
    border: none !important;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    transform: rotate(0deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
    transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
    content: '\e8ce' !important;
    top: 7px !important;
    left: 7px !important;
    font-size: 12px !important;
    font-family: 'smartbox_icons_v19' !important;
  }
  .ant-checkbox-wrapper {
    .ant-checkbox {
      ant-checkbox-inner {
        border-color: #3DD8DB;
        &:hover {
          border-color: #3DD8DB;
        }
      }
      &:hover {
        .ant-checkbox-inner {
          border-color:var(--generalIconFontHover) ;
        }
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--generalIconFontActive) !important;
        border: 1px solid #6e7882;
        &:after {
          border-color: #FFFFFF;
          background-color: transparent !important;
        }
      }
      &:after {
        border: none;
      }
      &:hover {
        .ant-checkbox-inner {
          border-color: var(--generalIconFontHover);
        }
      }
    }
    .ant-checkbox-checked.ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: #D9D9D9 !important;
        background-color: #FFF !important;
        &:after {
          border-color: #D9D9D9;
        //  margin-top: -1px;
        //  background-color: transparent !important;
        }
      }
    }
    .ant-checkbox-inner {
      border-radius: 0%;
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #3DD8DB;
      }
    }
  }
  .select-all-active {
    .ant-checkbox-inner {
      background-color: var(--generalIconFontActive);
    }
    .ant-checkbox-inner::before {
      border-color: #FFFFFF;
      position: absolute !important;
      display: table;
      color: #FFFFFF !important;
      border: none !important;
      background-color: var(--generalIconFontActive);
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      top: 7px !important;
      left: 7px !important;
      font-size: 12px !important;
      font-family: 'smartbox_icons_v19' !important;
    }
    button:disabled {
      background-color: #E4C801;
    }
    .icon-v10-edit-b:after {
      margin-right: 10px;
    }
  }
  .select-children {
    .ant-checkbox-inner::before {
      border-color: #FFFFFF;
      position: absolute !important;
      display: table;
      color: #6e7882 !important;
      border: none !important;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
      transform: rotate(0deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
      content: '\e8ce' !important;
      top: 7px !important;
      left: 7px !important;
      font-size: 12px !important;
      font-family: 'smartbox_icons_v19' !important;
    }
    button:disabled {
      background-color: #E4C801;
    }
    .icon-v10-edit-b:after {
      margin-right: 10px;
    }
  }
  .select-all {
    border-bottom: none;
    padding: 0px 0px;
    background-color: #E4C801;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-checkbox-checked {
      .ant-checkbox-inner::before {
        border-color: #FFFFFF;
        position: absolute !important;
        display: table;
        color: #FFFFFF !important;
        border: none !important;
        border-top: 0;
        border-left: 0;
        -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
        transform: rotate(0deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
        content: '\e8ce' !important;
        top: 7px !important;
        left: 7px !important;
        font-size: 12px !important;
        font-family: 'smartbox_icons_v19' !important;
      }
    }
    .ant-checkbox+span {
      padding-left: 10px;
      color: #382e34;
    }
    .ant-checkbox {
      top: 0px;
    }
    &__label {
      font-size: 14px;
      font-weight: 700;
    margin-left: 10px;
    }
    &__delete {
      float: right;
      margin-right: 0;
    }
  }
}
.scan-now-container {
  .button-green {
    float: right;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .btn-grey {
    background-color: #ccc;
    border-color: #555;
    color: #555;
    cursor: not-allowed;
    &:hover {
      background-color: #ccc;
      color: #555;
    }
  }
  .btn-green {
    background-color: #FFF;
    border-color: #3DD8DB;
    color: #3DD8DB;
    cursor: pointer;
    &:hover {
      background-color: #3DD8DB;
      color: #FFF;
    }
  }
  .btn-green-fill {
    background-color: #3DD8DB;
    border-color: #3DD8DB;
    color: #FFF;
    cursor: pointer;
    &:hover {
      background-color: #FFF;
      color: #3DD8DB;
    }
  }
}

.regex-all {
  margin: 134px 82px 0;
  padding-top: 30px;
  .box-title {
    margin-bottom: 40px;
    .icon-v14-question {
      margin-left: 10px;
      font-size: 20px;
      color: #382e34;
    }
  }
  .box-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    color: rgba(0,0,0,0.85);
  }
  .box-title + h3 {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .insert-regular-expression {
    margin-bottom: 10px;
    position: relative;
    input {
      width: 100%;
      height: 40px;
      border-radius: 0px;
      border: solid 1px #6E7882;
      background-color: white;
      color: #000000;
      font-weight: 300;
      font-size: 14px;
      &:focus-visible {
        border: solid 1px #EDA41E;
        box-shadow: none;
      }
    }
    .add-regular-expression {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;

      .icon-add-b {
        color: #6e7882;
        margin-left: 10px;
        cursor: pointer;
        font-size: 20px;
        margin-top:5px;
      }
      .icon-v19-add-active {
          color: var(--generalIconFontHover);
          margin-left: 10px;
          cursor: pointer;
          font-size: 20px;
          margin-top:5px;
      }
      .icon-v19-add {
        color: #382e34;
        margin-left: 10px;
        cursor: pointer;
        font-size: 20px;
        margin-top:5px;
      }
      button:disabled {
        cursor: default !important;
        color: #D4D7DA;
      }
    }
  }
  strong {
    font-weight: 700;
  }
  .panel-container {
    margin-bottom: 30px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: rgba(154, 224, 252, 0.5);
    }
    .pull-right {
      .icon-v14-delete {
        font-size: 22px;
        color:#352a31;
      }
      .icon-v14-delete-active {
        font-size: 22px;
        color: var(--generalIconFontHover);
        cursor:pointer;
      }
    }
  }
  .access-expressions__content-radio {
    margin-top: 40px;
  }
}

.modal-delete-regex-ex {
  .ant-modal-body {
    padding: 30px !important;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    margin-top: 40px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 32px;
    font-weight: 700;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.2px;
    vertical-align: middle;
    color: #382e34;
    margin-top: 0px;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: 16px;
    font-weight: 700;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.2px;
    vertical-align: middle;
    color: #382e34;
    margin-top: 10px;
    height: auto;
    text-align: center;
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .cancel-button {
    background-color: #FFFFFF;
    color: #6e7882;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.5px;
    vertical-align: middle;
    margin-top: 0px;
    border: 1px solid  #6e7882;
    height: 25px;
    width: 130px;
    padding-top: 2px;
    &:hover {
      color: var(--generalButtonHover);
      border: 1px solid var(--generalButtonHover);
      background-color: #FFFFFF;
    }
    &:active {
      color: var(--generalButtonActive);
      border: 1px solid var(--generalButtonActive);
      background-color: #FFFFFF;
    }
  }
  .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .confirm-button {
    background-color: var(--generalButton);
    color: var(--generalButtonFont);
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;
    line-height: 20px;
    letter-spacing: 0.5px;
    vertical-align: middle;
    margin-top: 0px;
    border: 1px solid #6e7882;
    height: 25px;
    width: 130px;
    padding-top: 2px;
    .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
      margin-top: 0px;
    }
    &:hover {
      color: var(--generalButtonFont);
      border: 1px solid var(--generalButtonHover);
      background-color: var(--generalButtonHover);
    }
    &:active {
      color: var(--generalButtonFont);
      border: 1px solid var(--generalButtonActive);
      background-color: var(--generalButtonActive);
    }
  }
}