.backOffice_stackWrapper{
  display:inline-block;
}

.backOffice_queueWrapper{
  display:inline-block;
  padding:20px;
}

.backOffice_processorWrapper{
  display:inline-block;
  padding:20px;
}