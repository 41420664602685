.backOffice_documentPreview{
  max-height: 300px;
  overflow-x: auto;
  overflow-y: scroll;
  width:100%;
  min-width: 100%;
  min-height: 300px;
  border: 1px #000000 solid;
}

.backOffice_documentTitle{
  background-color: lightgrey;
}

.backOffice_documentPreview{
  background-color: lightgrey;
}

.backOffice_documentContent{
  background-color: #FFFFFF;
  margin:10px;
  min-height: 250px;
  border: 1px #000000 solid;
}

.backOffice_documentTitle{
  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: 600px; 
  white-space: nowrap;
  display:inline-block;
  padding-top: 10px;
  padding-left: 10px;
}

.backOffice_documentMetadata_title{
  font-weight: bold;
  padding-bottom: 10px;
}

.backOffice_documentMetadata_expand{
  float:right;
  padding-top:10px;
  padding-right: 15px;
  padding-left: 10px;
  cursor: pointer;
}

.backOffice_documentMetadata{
  width:330px;
  float:right;
  border: 1px solid #000000;
  padding:20px;
  background-color: #FFFFFF;
}

.backOffice_documentMetadata_field{
  width:300px;
  vertical-align: top;
}

.backOffice_documentMetadata_field_name{
  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: 150px; 
  white-space: nowrap;
  width:150px;
  display:inline-block;
  vertical-align: top;
}

.backOffice_documentMetadata_field_value{
  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: 150px; 
  white-space: nowrap;
  width:150px;
  display:inline-block;
}

.backOffice_documentMetadata_field_listvalue{
  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: 150px; 
  white-space: nowrap;
  width:150px;
}