.external-workflow-ticket {
  width: 100%;
  height: auto;
  //background: url("../../../assets/background.png") no-repeat top right #efefef;
  background-color: #a6acb2;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  .external-workflow-ticket-page {
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 40px;
    .external-workflow-ticket-content {
      background-color: #FFFFFF;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #DCDCDC;
      position: relative;
      width: 1000px;
      padding: 10px;
      .external-workflow-ticket-completed {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        .message-info {
          color: #382e34;
          font-size: 30px;
          font-weight: 600;
          font-family: Roboto;
        }
      }
      .external-save-button-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: 45px;
        align-items: center;
        padding: 10px;
        //border-top: 1px solid #382E34;
        .external-save-button {
          background-color: var(--generalButton);
          border: 1px solid #6e7882;
          border-radius: 20px;
          box-shadow: none;
          color: #FFFFFF;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          padding: 3px !important;
          text-transform: none;
          transition: none;
          line-height: 18.75px;
          width: 180px;
          margin-left: 40px;
          height: 26px;

          &:hover {
            background-color: var(--generalButtonHover);
            border: 1px solid var(--generalButtonHover);
            color: #FFFFFF;
          }

          &:active {
            background-color: var(--generalButtonActive);
            border: 1px solid var(--generalButtonActive);
            color: #FFFFFF;
          }

          &:disabled {
            cursor: default;
            background: #D4D7DA !important;
          }
        }
      }
      .external-workflow-ticket-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        //margin-top: 20px;
        .full-name-former-update {
          margin-bottom: 10px;
        }
        .full-name-former {
          margin-top: 20px;
          .header-former-name {
            font-size: 18px !important;
            font-weight: 700 !important;
            line-height: 20px !important;
            letter-spacing: 0.2px;
            color: #382e34 !important;
          }
          .input-content-upload {
            .anticon-spin {
              color: #3DD8DB;
              font-size: 30px;
            }
            .cloud-upload {
              font-size: 20px;
            }
            .anticon {
              font-size: 20px;
            }
            .ant-upload-text {
              margin: 0 0 4px;
              color: #382e34;
              font-size: 16px;
              font-weight: 600;
            }
            .ant-upload-hint {
              color: #D4D7DA;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .ant-upload.ant-upload-select-picture-card {
            width: 100% !important;
            margin-top: 36px;
          }
          .ant-upload.ant-upload-select-picture-card {
            width: 100% !important;
            margin-top: 36px;
          }
          .header-former {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #382e34;
          }
          .subheader-title {
            font-size: 14px;
            font-family: Roboto;
            color: #382e34;
            font-weight: 400;
            margin-bottom: 10px;
          }
          .header-former-address {
            //margin-top: 20px;
            display: flex;
            margin-bottom: 10px;
          }
          .full-name-component {
            margin-bottom: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 5px;
            .input-content {
              width: 49%;
              .input-form-name {
                border: 1px solid #D4D7DA;
                color: #6e7882;
                background-color: #ffffff40;
                padding: 0 10px;
                font-size: 16px;
                font-weight: 400;
                height: 30px;
                border-radius: 0px;
                cursor:default;
                &:hover {
                  border: 1px solid #D4D7DA;
                  box-shadow: none;
                }
                &:focus {
                  border: 1px solid #D4D7DA;
                  box-shadow: none;
                }
                &:active {
                  border: 1px solid #D4D7DA;
                  box-shadow: none;
                }
              }
              .label-input {
                font-size: 12px;
                font-family: Roboto;
                color: #382e34;
                font-weight: 400;
                margin-top: 5px;
                margin-bottom: 5px;
                display: flex;
              }
            }
          }
          .input-content {
            width: 49%;
            .input-form-name {
              border: 1px solid #D4D7DA;
              color: #6e7882;
              background-color: #ffffff40;
              padding: 0 10px;
              font-size: 16px;
              font-weight: 400;
              height: 30px;
              border-radius: 0px;
              cursor:default;
              &:hover {
                border: 1px solid #D4D7DA;
                box-shadow: none;
              }
              &:focus {
                border: 1px solid #D4D7DA;
                box-shadow: none;
              }
              &:active {
                border: 1px solid #D4D7DA;
                box-shadow: none;
              }
            }
            .input-form-name.long-text-input {
              height: 60px;
            }
            .label-input {
              font-size: 12px;
              font-family: Roboto;
              color: #382e34;
              font-weight: 400;
              margin-top: 5px;
              margin-bottom: 10px;
              display: flex;
            }
            .select-data-form {
              width: 20%;
              .ant-select-selection {
                border-radius: 0;
                height: 30px;
                border: 1px solid #D4D7DA;

              }
            }
            .ant-radio-group {
              .ant-radio {
                .ant-radio-inner {
                  border: 1px solid #D4D7DA;
                }
              }
              .ant-radio-checked {
                .ant-radio-inner::after {
                  background-color: #d4d7da;
                }
              }
            }
            .ant-checkbox-wrapper .ant-checkbox-inner {
              border-radius: 0% !important;
              border: 1px solid #d4d7da;
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
              border: 1px solid #fff;
              border-top: 0;
              border-left: 0;
              top: 46%;
              left: 19%;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner {
              border-color: #d4d7da;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
              border-color: #FFFFFF;
              background-color: #FFFFFF;
              color: #FFFFFF;
              border: none !important;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);
              transform: rotate(0deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              transition: all 0s cubic-bezier(1, -0.87, 0.96, 0.87), opacity 1s !important;
              content: '\e8ce' !important;
              top: 7px !important;
              left: 7px !important;
              font-size: 12px !important;
              font-family: 'smartbox_icons_v19' !important;
            }
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #d4d7da !important;
              border: 1px solid #d4d7da;
            }
            .ant-upload.ant-upload-drag {
              border: 1px solid #d4d7da;
              border-radius: 0px;
            }
            .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
              color: #d4d7da;
              font-size: 48px;
            }
            .ant-upload.ant-upload-drag p.ant-upload-text {
              margin: 0 0 4px;
              color: #382e34;
              font-size: 16px;
              font-weight: 600;
            }
            .ant-upload.ant-upload-drag p.ant-upload-hint {
              color: #382e34;
              font-size: 14px;
            }
          }
          .input-header-component {
            margin-bottom: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            .input-content {
              width: 49%;
              .shortText-character-count {
                font-size: 12px;
                font-family: Roboto;
                color: #382e34;
                font-weight: 400;
                margin-top: 5px;
                margin-bottom: 5px;
                display: flex;
                justify-content: flex-end;
              }
              .input-form-name {
                border: 1px solid #D4D7DA;
                color: #6e7882;
                background-color: #ffffff40;
                padding: 0 10px;
                font-size: 16px;
                font-weight: 400;
                height: 30px;
                border-radius: 0px;
                cursor:default;
                &:hover {
                  border: 1px solid #D4D7DA;
                  box-shadow: none;
                }
                &:focus {
                  border: 1px solid #D4D7DA;
                  box-shadow: none;
                }
                &:active {
                  border: 1px solid #D4D7DA;
                  box-shadow: none;
                }
              }
              .label-input {
                font-size: 12px;
                font-family: Roboto;
                color: #382e34;
                font-weight: 400;
                margin-top: 5px;
                margin-bottom: 5px;
                display: flex;
              }
            }
          }
          .signature-content {
            height: 100px;
            padding: 10px;
            border: 1px solid #d4d7da;
            background-color: #fafafa;;
            width: 50%;
            margin-bottom: 0;
            .signature {
              border: 1px solid #d4d7da;
              background-color: #ffffff;
              width: 100%;
              height: 70%;
            }
            .button-add-signature {
              background-color: #fff;
              border: 1px solid #eda41e;
              color: #eda41e;
              border-radius: 20px;
              width: auto;
              cursor: pointer;
              font-family: "Roboto";
              font-weight: 400;
              font-size: 14px;
              padding: 4px 20px;
              margin: 5px 20px
            }
            .button-small-red-empty {
              border-radius: 20px;
              width: auto;
              cursor: pointer;
              font-family: "Roboto";
              text-transform: capitalize;
              font-weight: 400;
              font-size: 14px;
              padding: 4px 20px;
              margin: 5px 0px
            }
          }
        }

      }
    }
  }
}