.metadata-page {
  margin-top: 40px;
  padding-right: 7%;
  .pie-chart {
    position: relative;
    &__total {
      position: absolute;
      top: 28%;
      font-family: Roboto;
      font-size: 29px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #6e7882;
      width: 100%;
    }
    &__chart {
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 30%;
      margin-bottom: 16%;
      svg {
        overflow: visible;
        path:nth-child(1) {
          stroke-width: 19;
        }
        path:nth-child(2) {
          stroke-width: 15;
        }
        path:nth-child(3) {
          stroke-width: 11;
        }
      }
    }
    &__legend {
      margin-top: 20px;
      margin-bottom: 20px;
      &__item {
        color: #6e7882;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        margin-top: 10px;
      }
      #regular {
        color: #38e000;
      }
      #manual {
        color: #00d3d8;
      }
      #business {
        color: #feb236;
      }
    }
  }
  //padding-left: 7%;
  &__img-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 120px;
    margin: auto;
    margin-top: 17%;
    margin-bottom: 5%;
    .analysed-file {
      height: 25px;
      top: 7px;
      left: 27px;
      position: absolute;
    }
  }
  .title-metadata {
    height: 19px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    overflow-wrap: break-word;
    height: auto;
  }
  .subtitle-metadata {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 17%;
  }
  .item-metadata {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    color: #4a4a4a;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    position: relative;
    &__title {
      font-weight: bold;
    }
    .add-custom-status {
      float:right;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: #3ED8DB;
      }
    }
    .button-green {
      color: #637882;
      border: 1px solid #637882;
      background-color: #fff;
      width: 100%;
      margin: 10px 0;
      font-family: ROBOTO;
      font-weight: 400;
      font-size: 16px;
      height: 25px;
      padding: 0px;
      text-transform: capitalize;
      border-radius: 20px;
      &:hover {
        color: #FFC457;
        border: 1px solid #FFC457;
        background-color: #FFFFFF;
      }
      &:active {
        color: #EDA41E;
        border: 1px solid #EDA41E;
        background-color: #FFFFFF;
      }
    }
    .button-green-loading {
      color: #FFC457;
      border: 1px solid #FFC457;
      background-color: #fff;
      width: 100%;
      margin: 10px 0;
      font-family: ROBOTO;
      font-weight: 400;
      font-size: 16px;
      height: 25px;
      padding: 0px;
      text-transform: capitalize;
      border-radius: 20px;
      .anticon-loading{
        margin-right: 15px !important;
      }
    }
    .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
       padding-left: 0px;
    }

  }
  .custom-status-line {
    width: 100%;
    display: inline-block;
    height: 22px;
    .square {
      width: 15px;
      height: 15px;
      float: left;
      margin-right: 5px;
    }
    .label {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
    }
    .delete {
      float:right;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: #3ED8DB;
      }
    }
    .edit {
      float:right;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: #3ED8DB;
      }
    }
  }
  #availability {
    margin-top: 15%;
  }
  p.status-item {
    font-size: 14px;
    font-family: 'ROBOTO';
    font-weight: 100;
    margin-bottom: 3px;
  }
  .custom-status-select {
    margin-top: 5px;
    margin-bottom: 5px;
    .css-1uccc91-singleValue {
      margin-left: 18px;
    }
    .status-circle {
      width: 15px !important;
      height: 15px !important;
      border-radius: 50px;
      float: left;
      margin-right: 10px;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1dimb5e-singleValue {
      margin-left: 15px;
    }
  }
  .selected-icon {
    position: absolute;
    top: 30px;
    left: 7px;
    z-index: 1;
  }
  &__icon {
    width: 100%;
    text-align: center;
    .icon-status {
      position: relative;
      margin: 0 auto;
      width: 15px;
      height: 15px;
      margin-bottom: 8px;
    }
  }
}